import { Sort$1$reflection, PowerProgrammeSortColumn$reflection, PowerProgrammeSortColumn, Sort$1 } from "../../../../shared/Shared.Api.fs.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { ProgrammeSummary$reflection } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { union_type, class_type, record_type, bool_type, int32_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Page$1$reflection } from "../../../../shared/Shared.Models.fs.js";

export function sortFromIndexes(sortOrderIndex, sortColumnIndex) {
    return ((sortOrderIndex === 0) ? ((arg0) => (new Sort$1(0, arg0))) : ((arg0_1) => (new Sort$1(1, arg0_1))))((sortColumnIndex === 0) ? (new PowerProgrammeSortColumn(0)) : ((sortColumnIndex === 1) ? (new PowerProgrammeSortColumn(1)) : ((sortColumnIndex === 2) ? (new PowerProgrammeSortColumn(2)) : ((sortColumnIndex === 3) ? (new PowerProgrammeSortColumn(3)) : (new PowerProgrammeSortColumn(0))))));
}

export function sortColumnFromType(sort) {
    const sortColumn = (column) => {
        switch (column.tag) {
            case 1: {
                return 1;
            }
            case 2: {
                return 2;
            }
            case 3: {
                return 3;
            }
            default: {
                return 0;
            }
        }
    };
    if (sort.tag === 1) {
        return sortColumn(sort.fields[0]) | 0;
    }
    else {
        return sortColumn(sort.fields[0]) | 0;
    }
}

export function sortOrderFromType(sort) {
    if (sort.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export class Model extends Record {
    constructor(Items, Page, NumberOfPages, NumberOfRows, PageSize, SortOrder, IsLoading) {
        super();
        this.Items = Items;
        this.Page = (Page | 0);
        this.NumberOfPages = (NumberOfPages | 0);
        this.NumberOfRows = (NumberOfRows | 0);
        this.PageSize = (PageSize | 0);
        this.SortOrder = SortOrder;
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("PowerProgramme.Index.Types.Model", [], Model, () => [["Items", array_type(ProgrammeSummary$reflection())], ["Page", int32_type], ["NumberOfPages", int32_type], ["NumberOfRows", int32_type], ["PageSize", int32_type], ["SortOrder", Sort$1$reflection(PowerProgrammeSortColumn$reflection())], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    return new Model(new Array(0), 0, 0, 0, 0, new Sort$1(1, new PowerProgrammeSortColumn(0)), true);
}

export class GetIntervalProgrammesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetIntervalProgrammesRes$reflection() {
    return union_type("PowerProgramme.Index.Types.GetIntervalProgrammesRes", [], GetIntervalProgrammesRes, () => [[["Item", Page$1$reflection(ProgrammeSummary$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SortBy", "GotoPage", "GetIntervalProgrammes", "GetIntervalProgrammesResult"];
    }
}

export function Msg$reflection() {
    return union_type("PowerProgramme.Index.Types.Msg", [], Msg, () => [[["Item", PowerProgrammeSortColumn$reflection()]], [["Item", int32_type]], [], [["Item", GetIntervalProgrammesRes$reflection()]]]);
}

