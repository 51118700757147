import { eachItemWith, isGreaterThan, isGreaterThanOrEqualTo, isNotEmpty, validate as validate_1, isNotEmptyOrWhitespace, hasMaxLengthOf, validateRequired, createValidatorFor, ValidationItem, ValidationState } from "../../../../shared/SimpleValidation.fs.js";
import { singleton, ofArray } from "../../.fable/fable-library.3.0.0/List.js";
import { SubmitIntervalProgrammeRes, GetUserPowerZonesRes, GetProgrammeRes, Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_batch, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgrammeId_CreateFromString_Z721C83C5 } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { FSharpResult$2 } from "../../.fable/fable-library.3.0.0/Choice.js";
import { parse } from "../../.fable/fable-library.3.0.0/Int32.js";
import { Types_EditableProgramme__ToProgramme, Types_EditableInterval, Types_EditableMinMax, Types_EditableProgramme_FromProgramme_41BF7BF3, Types_EditableInterval_get_DefaultInterval, Types_EditableBlock, Types_EditableProgramme, calculateTrainingStress as calculateTrainingStress_1 } from "../Common.fs.js";
import { filter, mapIndexed, concat, append, length, isEmpty } from "../../.fable/fable-library.3.0.0/Seq.js";
import { append as append_1, tryFindIndex, map } from "../../.fable/fable-library.3.0.0/Array.js";
import { equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { submitIntervalProgramme, getUserPowerZones, getProgramme } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { successToast, errorToast } from "../../Notifications.fs.js";
import { PowerProgrammePage_FromIndexDefault, Page, modifyLocation } from "../../Router.fs.js";

function maxIsGreaterThanEqualToMin(_arg1, value) {
    const matchValue = [value.Min, value.Max];
    let pattern_matching_result, max, min;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            max = matchValue[1];
            min = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            if (max >= min) {
                return new ValidationState(1);
            }
            else {
                return new ValidationState(0, ofArray([new ValidationItem("Max must be greater than min", "Max", "maxIsGreaterThanEqualToMin"), new ValidationItem("Min must be less than max", "Min", "maxIsGreaterThanEqualToMin")]));
            }
        }
        case 1: {
            return new ValidationState(1);
        }
    }
}

function isOptionalOrGreaterThanOne(model, value) {
    if (model.DoesRepeat) {
        if (value != null) {
            if (value > 0) {
                return new ValidationState(1);
            }
            else {
                return new ValidationState(0, singleton(new ValidationItem("Must be greater than zero", "", "isOptionalOrGreaterThanOne")));
            }
        }
        else {
            return new ValidationState(0, singleton(new ValidationItem("Must be specified if repeats are set", "", "isOptionalOrGreaterThanOne")));
        }
    }
    else {
        return new ValidationState(1);
    }
}

const programmeValidator = createValidatorFor(ofArray([validateRequired("Name", (p) => p.Name, ofArray([(() => {
    const clo1 = hasMaxLengthOf(64);
    return (arg10) => {
        const clo2 = clo1(arg10);
        return (arg20) => clo2(arg20.split(""));
    };
})(), (arg00$0040) => ((value) => isNotEmptyOrWhitespace(arg00$0040, value))])), validate_1("Blocks", (p_1) => p_1.Blocks, ofArray([(arg00$0040_1) => ((value_1) => isNotEmpty(arg00$0040_1, value_1)), (() => {
    let validatorFunc;
    const validatorFunc_1 = createValidatorFor(ofArray([validate_1("Repeats", (b) => b.Repeats, singleton((model) => ((value_2) => isOptionalOrGreaterThanOne(model, value_2)))), validate_1("Intervals", (b_1) => b_1.Intervals, ofArray([(arg00$0040_2) => ((value_3) => isNotEmpty(arg00$0040_2, value_3)), (validatorFunc = createValidatorFor(ofArray([validate_1("Power", (i) => i.Power, ofArray([(arg00$0040_3) => ((value_4) => maxIsGreaterThanEqualToMin(arg00$0040_3, value_4)), validateRequired("Min", (p_2) => p_2.Min, singleton(isGreaterThanOrEqualTo(0))), validateRequired("Max", (p_3) => p_3.Max, singleton(isGreaterThanOrEqualTo(0)))])), validate_1("LengthInSeconds", (i_1) => i_1.LengthInSeconds, singleton(isGreaterThan(0)))])), (arg10$0040) => ((value_5) => eachItemWith(validatorFunc, arg10$0040, value_5)))]))]));
    return (arg10$0040_1) => ((value_6) => eachItemWith(validatorFunc_1, arg10$0040_1, value_6));
})()]))]));

export function init() {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.IsSaving, true, inputRecord.UserPowerZones, inputRecord.Programme, inputRecord.MovingBlockId, inputRecord.SelectedBlockId, inputRecord.ValidationState, inputRecord.CalculatedTss)), Cmd_OfFunc_result(new Msg(2))];
}

export function initForEdit(programmeIdAsString) {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.IsSaving, true, inputRecord.UserPowerZones, inputRecord.Programme, inputRecord.MovingBlockId, inputRecord.SelectedBlockId, inputRecord.ValidationState, inputRecord.CalculatedTss)), Cmd_OfFunc_result(new Msg(0, ProgrammeId_CreateFromString_Z721C83C5(programmeIdAsString)))];
}

export function convertDurationToSeconds(durationString) {
    try {
        if (durationString.length < 8) {
            return new FSharpResult$2(1, "Unable to convert duration to seconds");
        }
        else {
            const parts = durationString.split(":");
            return new FSharpResult$2(0, (((parse(parts[0], 511, false, 32) * 60) * 60) + (parse(parts[1], 511, false, 32) * 60)) + parse(parts[2], 511, false, 32));
        }
    }
    catch (matchValue_1) {
        return new FSharpResult$2(1, "Unable to convert duration to seconds");
    }
}

export function calculateTrainingStress(programme) {
    return calculateTrainingStress_1(programme, 200);
}

export function insertBlock(blocks, index, newBlock) {
    if (isEmpty(blocks)) {
        return [newBlock];
    }
    else if (index >= length(blocks)) {
        return Array.from(append(blocks, [newBlock]));
    }
    else {
        return Array.from(concat(mapIndexed((i, el) => {
            if (i === index) {
                return ofArray([newBlock, el]);
            }
            else {
                return singleton(el);
            }
        }, blocks)));
    }
}

export function moveBlock(fromIndex, toIndex, blocks) {
    const fromBlock = blocks[fromIndex];
    const toBlock = blocks[toIndex];
    return Array.from(mapIndexed((i, el) => {
        if (i === fromIndex) {
            return toBlock;
        }
        else if (i === toIndex) {
            return fromBlock;
        }
        else {
            return el;
        }
    }, blocks));
}

export function updateModelWithBlock(model, updatedBlock) {
    let inputRecord;
    return new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord = model.Programme, new Types_EditableProgramme(inputRecord.Id, inputRecord.Name, map((b) => {
        if (equalsSafe(b.BlockId, updatedBlock.BlockId)) {
            return updatedBlock;
        }
        else {
            return b;
        }
    }, model.Programme.Blocks))), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss);
}

export function updateModelWithInterval(model, block, interval) {
    return updateModelWithBlock(model, new Types_EditableBlock(block.BlockId, block.Name, map((i) => {
        if (equalsSafe(i.IntervalId, interval.IntervalId)) {
            return interval;
        }
        else {
            return i;
        }
    }, block.Intervals), block.DoesRepeat, block.Repeats));
}

export function validate(model) {
    return new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, programmeValidator(model.Programme), model.CalculatedTss);
}

export function update(context, msg, model) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, matchValue_2;
    const updateTss = Cmd_OfFunc_result(new Msg(20));
    let patternInput;
    switch (msg.tag) {
        case 10: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, msg.fields[0].BlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            break;
        }
        case 18: {
            const block_1 = msg.fields[0];
            patternInput = [updateModelWithBlock(model, new Types_EditableBlock(block_1.BlockId, block_1.Name, Array.from(append(block_1.Intervals, [Types_EditableInterval_get_DefaultInterval()])), block_1.DoesRepeat, block_1.Repeats)), updateTss];
            break;
        }
        case 19: {
            const block_2 = msg.fields[0];
            patternInput = [updateModelWithBlock(model, new Types_EditableBlock(block_2.BlockId, block_2.Name, block_2.Intervals.filter((i) => (!equalsSafe(i.IntervalId, msg.fields[1].IntervalId))), block_2.DoesRepeat, block_2.Repeats)), updateTss];
            break;
        }
        case 12: {
            const block_3 = msg.fields[0];
            patternInput = [updateModelWithBlock(model, new Types_EditableBlock(block_3.BlockId, block_3.Name, block_3.Intervals, !block_3.DoesRepeat, block_3.Repeats)), updateTss];
            break;
        }
        case 13: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord = model.Programme, new Types_EditableProgramme(inputRecord.Id, msg.fields[0], inputRecord.Blocks)), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            break;
        }
        case 14: {
            const block_4 = msg.fields[0];
            patternInput = [updateModelWithBlock(model, new Types_EditableBlock(block_4.BlockId, block_4.Name, block_4.Intervals, block_4.DoesRepeat, msg.fields[1])), updateTss];
            break;
        }
        case 11: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_1 = model.Programme, new Types_EditableProgramme(inputRecord_1.Id, inputRecord_1.Name, map((b) => {
                if (equalsSafe(b.BlockId, msg.fields[0].BlockId)) {
                    return new Types_EditableBlock(b.BlockId, msg.fields[1], b.Intervals, b.DoesRepeat, b.Repeats);
                }
                else {
                    return b;
                }
            }, model.Programme.Blocks))), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            break;
        }
        case 6: {
            const block_6 = msg.fields[1];
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_2 = model.Programme, new Types_EditableProgramme(inputRecord_2.Id, inputRecord_2.Name, insertBlock(model.Programme.Blocks, msg.fields[0], block_6))), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(10, block_6)), updateTss]))];
            break;
        }
        case 8: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, model.Programme, msg.fields[0].BlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            break;
        }
        case 22: {
            const blockToRemove = msg.fields[0];
            const indexOfBlockOption = tryFindIndex((block_8) => equalsSafe(blockToRemove, block_8), model.Programme.Blocks);
            if (indexOfBlockOption == null) {
                patternInput = [model, Cmd_none()];
            }
            else {
                const indexOfBlock = indexOfBlockOption | 0;
                const newSelectedBlockId = (indexOfBlock > 0) ? model.Programme.Blocks[indexOfBlock - 1].BlockId : ((indexOfBlock < (model.Programme.Blocks.length - 1)) ? model.Programme.Blocks[indexOfBlock + 1].BlockId : (void 0));
                patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_3 = model.Programme, new Types_EditableProgramme(inputRecord_3.Id, inputRecord_3.Name, model.Programme.Blocks.filter((block_9) => (!equalsSafe(block_9, blockToRemove))))), model.MovingBlockId, newSelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            }
            break;
        }
        case 9: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_4 = model.Programme, new Types_EditableProgramme(inputRecord_4.Id, inputRecord_4.Name, msg.fields[0] ? model.Programme.Blocks : Array.from(filter((b_1) => {
                const matchValue_1 = model.MovingBlockId;
                if (matchValue_1 != null) {
                    return !equalsSafe(b_1.BlockId, matchValue_1);
                }
                else {
                    return true;
                }
            }, model.Programme.Blocks)))), void 0, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()];
            break;
        }
        case 7: {
            const fromIndex = msg.fields[0] | 0;
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_5 = model.Programme, new Types_EditableProgramme(inputRecord_5.Id, inputRecord_5.Name, moveBlock(fromIndex, msg.fields[1], model.Programme.Blocks))), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_OfFunc_result(new Msg(10, model.Programme.Blocks[fromIndex]))];
            break;
        }
        case 21: {
            const paletteBlock = msg.fields[0];
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, (inputRecord_6 = model.Programme, new Types_EditableProgramme(inputRecord_6.Id, inputRecord_6.Name, append_1(model.Programme.Blocks, [paletteBlock]))), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_OfFunc_result(new Msg(10, paletteBlock))];
            break;
        }
        case 0: {
            patternInput = [new Model(model.IsSaving, true, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getProgramme(msg.fields[0], context_1), context, (arg0_6) => (new Msg(1, arg0_6)), (arg) => (new Msg(1, new GetProgrammeRes(1, arg))))];
            break;
        }
        case 1: {
            const res = msg.fields[0];
            patternInput = ((res.tag === 0) ? [new Model(model.IsSaving, false, res.fields[1], Types_EditableProgramme_FromProgramme_41BF7BF3(res.fields[0]), model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), updateTss] : [model, Cmd_none()]);
            break;
        }
        case 2: {
            patternInput = [new Model(model.IsSaving, true, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, getUserPowerZones, context, (arg0_9) => (new Msg(3, arg0_9)), (arg_2) => (new Msg(3, new GetUserPowerZonesRes(1, arg_2))))];
            break;
        }
        case 3: {
            const res_1 = msg.fields[0];
            patternInput = ((res_1.tag === 0) ? [new Model(model.IsSaving, false, res_1.fields[0], model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_none()] : [model, Cmd_none()]);
            break;
        }
        case 16: {
            const interval_1 = msg.fields[1];
            patternInput = [updateModelWithInterval(model, msg.fields[0], new Types_EditableInterval(interval_1.Name, interval_1.IntervalId, new Types_EditableMinMax(msg.fields[2], interval_1.Power.Max), interval_1.Length, interval_1.LengthInSeconds, interval_1.Cadence)), updateTss];
            break;
        }
        case 17: {
            const interval_2 = msg.fields[1];
            patternInput = [updateModelWithInterval(model, msg.fields[0], new Types_EditableInterval(interval_2.Name, interval_2.IntervalId, new Types_EditableMinMax(interval_2.Power.Min, msg.fields[2]), interval_2.Length, interval_2.LengthInSeconds, interval_2.Cadence)), updateTss];
            break;
        }
        case 15: {
            const newValue_4 = msg.fields[2];
            const interval_3 = msg.fields[1];
            patternInput = [updateModelWithInterval(model, msg.fields[0], new Types_EditableInterval(interval_3.Name, interval_3.IntervalId, interval_3.Power, newValue_4, (matchValue_2 = convertDurationToSeconds(newValue_4), (matchValue_2.tag === 0) ? matchValue_2.fields[0] : interval_3.LengthInSeconds), interval_3.Cadence)), updateTss];
            break;
        }
        case 4: {
            const rp = Types_EditableProgramme__ToProgramme(model.Programme);
            if (rp.tag === 1) {
                console.log(some("Error converting model - validation not in sync with model requirements"));
                patternInput = [model, Cmd_none()];
            }
            else {
                patternInput = [new Model(true, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, (context_3) => submitIntervalProgramme(rp.fields[0], context_3), (x_2) => x_2, (arg0_12) => (new Msg(5, arg0_12)), (arg_4) => (new Msg(5, new SubmitIntervalProgrammeRes(1, arg_4))))];
            }
            break;
        }
        case 5: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                patternInput = [new Model(false, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), errorToast("An unexpected error occurred saving your workout")];
            }
            else {
                modifyLocation(new Page(0, PowerProgrammePage_FromIndexDefault()));
                patternInput = [new Model(false, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, model.CalculatedTss), successToast("Your workout has been saved")];
            }
            break;
        }
        default: {
            patternInput = [new Model(model.IsSaving, model.IsLoading, model.UserPowerZones, model.Programme, model.MovingBlockId, model.SelectedBlockId, model.ValidationState, calculateTrainingStress(model.Programme)), Cmd_none()];
        }
    }
    return [validate(patternInput[0]), patternInput[1]];
}

