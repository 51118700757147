import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { zones as zones_1, powerProgramme } from "../../Server.fs.js";
import { GetProgrammeRes, GetUserPowerZonesRes, SubmitIntervalProgrammeRes } from "./Types.fs.js";

export class ErrorGettingPowerZonesException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorGettingPowerZonesException$reflection() {
    return class_type("PowerProgramme.Create.Api.ErrorGettingPowerZonesException", void 0, ErrorGettingPowerZonesException, class_type("System.Exception"));
}

function ErrorGettingPowerZonesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorGettingPowerZonesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class ErrorGettingProgrammeException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorGettingProgrammeException$reflection() {
    return class_type("PowerProgramme.Create.Api.ErrorGettingProgrammeException", void 0, ErrorGettingProgrammeException, class_type("System.Exception"));
}

function ErrorGettingProgrammeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorGettingProgrammeException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class ErrorCreatingPowerProgrammeException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorCreatingPowerProgrammeException$reflection() {
    return class_type("PowerProgramme.Create.Api.ErrorCreatingPowerProgrammeException", void 0, ErrorCreatingPowerProgrammeException, class_type("System.Exception"));
}

function ErrorCreatingPowerProgrammeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorCreatingPowerProgrammeException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function submitIntervalProgramme(programme, context) {
    return singleton.Delay(() => singleton.Bind(powerProgramme((api) => api.save(programme)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new SubmitIntervalProgrammeRes(0)) : (new SubmitIntervalProgrammeRes(1, new ErrorCreatingPowerProgrammeException())))));
}

export function getUserPowerZones(context) {
    return singleton.Delay(() => singleton.Bind(zones_1((api) => api.getPowerZones()), (_arg1) => {
        const apiResponse = _arg1;
        return singleton.Return((apiResponse.tag === 0) ? (new GetUserPowerZonesRes(0, apiResponse.fields[0])) : ((apiResponse.tag === 1) ? (new GetUserPowerZonesRes(0, void 0)) : (new GetUserPowerZonesRes(1, new ErrorGettingPowerZonesException()))));
    }));
}

export function getProgramme(programmeId, context) {
    return singleton.Delay(() => singleton.Bind(powerProgramme((api) => api.get(programmeId)), (_arg1) => {
        const result = _arg1;
        return singleton.Bind(zones_1((api_1) => api_1.getPowerZones()), (_arg2) => {
            let tupledArg;
            const upzResponse = _arg2;
            return singleton.Return((result.tag === 0) ? (tupledArg = [result.fields[0][0], (upzResponse.tag === 0) ? upzResponse.fields[0] : (void 0)], new GetProgrammeRes(0, tupledArg[0], tupledArg[1])) : (new GetProgrammeRes(1, new ErrorGettingProgrammeException())));
        });
    }));
}

