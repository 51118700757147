import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { PowerProgrammePage_FromIndexDefault, PowerProgrammePage$reflection } from "../../Router.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "../Index/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "../Show/Types.fs.js";
import { Msg$reflection as Msg$reflection_3, Model$reflection as Model$reflection_3 } from "../Create/Types.fs.js";

export class Model extends Record {
    constructor(CurrentPage, IndexModel, ShowModel, CreateModel, UpdateModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.IndexModel = IndexModel;
        this.ShowModel = ShowModel;
        this.CreateModel = CreateModel;
        this.UpdateModel = UpdateModel;
    }
}

export function Model$reflection() {
    return record_type("PowerProgramme.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", PowerProgrammePage$reflection()], ["IndexModel", option_type(Model$reflection_1())], ["ShowModel", option_type(Model$reflection_2())], ["CreateModel", option_type(Model$reflection_3())], ["UpdateModel", option_type(Model$reflection_3())]]);
}

export function Model_get_Empty() {
    return new Model(PowerProgrammePage_FromIndexDefault(), void 0, void 0, void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IndexMsg", "ShowMsg", "CreateMsg", "UpdateMsg"];
    }
}

export function Msg$reflection() {
    return union_type("PowerProgramme.Dispatcher.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_3()]]]);
}

