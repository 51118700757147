import { root as root_1 } from "../Show/View.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import * as react from "react";

export function root(context, model, dispatch) {
    if (model.ShowModel != null) {
        return root_1(context, model.ShowModel, dispatch);
    }
    else {
        console.log(some("[Help.Dispatcher.View] Page not found"));
        return react.createElement("div", {}, "Page not found");
    }
}

