import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Id__Id$reflection } from "../../../../shared/Shared.Types.fs.js";
import { union_type, class_type, bool_type, list_type, record_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { empty } from "../../.fable/fable-library.3.0.0/List.js";

export class Item extends Record {
    constructor(Id, Title) {
        super();
        this.Id = Id;
        this.Title = Title;
    }
}

export function Item$reflection() {
    return record_type("CardioActivity.Index.Types.Item", [], Item, () => [["Id", Id__Id$reflection()], ["Title", string_type]]);
}

export class Model extends Record {
    constructor(Items, IsLoading) {
        super();
        this.Items = Items;
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("CardioActivity.Index.Types.Model", [], Model, () => [["Items", list_type(Item$reflection())], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    return new Model(empty(), true);
}

export class GetCardioActivitiesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetCardioActivitiesRes$reflection() {
    return union_type("CardioActivity.Index.Types.GetCardioActivitiesRes", [], GetCardioActivitiesRes, () => [[["Item", list_type(Item$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetCardioActivities", "GetCardioActivitiesResult"];
    }
}

export function Msg$reflection() {
    return union_type("CardioActivity.Index.Types.Msg", [], Msg, () => [[], [["Item", GetCardioActivitiesRes$reflection()]]]);
}

