import * as react from "react";
import { isEmpty, singleton, append, delay, map, find } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { metricTypeFromString, dateRangeTypeLookup } from "../Types.fs.js";
import { Msg$reflection, Model$reflection, Msg } from "./Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { ClassName, OnChange, Value, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { panelTitle } from "../../Typography.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { analysisPage, panel } from "../../Layout.fs.js";
import { metricTypeEditor } from "../Helpers.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { warningPanel } from "../../Notifications.fs.js";
import { view } from "./Graph.fs.js";

function embeddedDateRangeTypeDropdown(model, dispatch) {
    const roundedClass = (model.DateRangeType.tag === 8) ? "rounded-r-none border-l" : "border-l";
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: find((tupledArg_1) => equalsSafe(tupledArg_1[0], model.DateRangeType), dateRangeTypeLookup)[1],
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let text;
            dispatch(new Msg(1, (text = Browser_Types_Event__Event_get_Value(ev), find((tupledArg) => (tupledArg[1] === text), dateRangeTypeLookup)[0])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), dateRangeTypeLookup)));
}

function dateRangeEditor(model, context, dispatch) {
    const datePickers = react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, ...ofSeq(delay(() => append(singleton(embeddedDateRangeTypeDropdown(model, dispatch)), delay(() => ((model.DateRangeType.tag === 8) ? append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "from")), delay(() => append(singleton(flatpickr(ofArray([Value(model.DateRange.StartDate), OnChange((arg) => {
        dispatch(new Msg(2, arg));
    }), ClassName("form-input rounded-none block  transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "to")), delay(() => singleton(flatpickr(ofArray([Value(model.DateRange.EndDate), OnChange((arg_1) => {
        dispatch(new Msg(3, arg_1));
    }), ClassName("form-input rounded-l-none block transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))))))))) : singleton(react.createElement(react.Fragment, {})))))))));
    return react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Date range"), react.createElement("div", {}, datePickers));
}

export class IMetricHistoryProps extends Record {
    constructor(Context, Model, Dispatch) {
        super();
        this.Context = Context;
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function IMetricHistoryProps$reflection() {
    return record_type("Analysis.MetricHistory.View.IMetricHistoryProps", [], IMetricHistoryProps, () => [["Context", Context$reflection()], ["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

export function filterPanel(model, context, dispatch) {
    return panel([metricTypeEditor(model.MetricType, (arg_1) => {
        dispatch(new Msg(0, metricTypeFromString(arg_1)));
    }, false), dateRangeEditor(model, context, dispatch)]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    const leftTools = react.createElement("div", {});
    const rightTools = react.createElement("div", {});
    const selectedTabIndex = react.useState(0);
    return analysisPage(props.Model.IsLoading, false, leftTools, rightTools, [filterPanel(props.Model, props.Context, props.Dispatch), react.createElement("div", {
        className: "mt-6",
    }, panel([react.createElement("div", {
        className: "-mt-2",
    }, isEmpty(props.Model.Metrics) ? warningPanel("No data available", "There are no metrics available in those date ranges") : view({
        Context: props.Context,
        Height: 320,
        MetricType: props.Model.MetricType,
        Metrics: props.Model.Metrics,
    }))]))]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/MetricHistory/View.fs", 75);

