import * as react from "react";
import { mapIndexed, append, map, exists } from "../../.fable/fable-library.3.0.0/Seq.js";
import { cadenceUnits, cadence, heartRateUnits, heartRate, powerUnits, power, ascentUnits, ascent, speedUnits, speed, distanceUnits, distance, weightUnits, weight as weight_1, caloriesUnits, calories, optionalDurationFromSeconds } from "../../Units.fs.js";
import { OptionHelpers_optionalFloatFromInt } from "../../../../shared/Shared.fs.js";
import { ofArray } from "../../.fable/fable-library.3.0.0/List.js";

export function view(context, summaries, seriesIndicator) {
    let source2_2, arg10, weights, source2, arg10_3, arg10_7, arg10_11, arg10_15, arg10_18;
    const headerRow = (title, content) => react.createElement(react.Fragment, {}, react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("th", {
        colSpan: summaries.length + 1,
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, title))), react.createElement("tbody", {}, ...content));
    const propertyRow = (label, values_1, valueGetter_1, transformer, units) => {
        let source2_1;
        if (exists((option) => (option != null), map(valueGetter_1, values_1))) {
            return react.createElement("tr", {}, ...(source2_1 = map((metric) => react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, transformer(context, valueGetter_1(metric)), " ", units(context)), values_1), append([react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, label)], source2_1)));
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    };
    const metrics = Array.from(map((s_10) => s_10.Metrics, summaries));
    const weights_1 = Array.from(map((s_11) => s_11.WeightAtTimeOfRideKg, summaries));
    return react.createElement("div", {}, react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200",
    }, react.createElement("table", {
        className: "min-w-full",
    }, react.createElement("thead", {}, react.createElement("tr", {}, ...(source2_2 = mapIndexed((i, m) => react.createElement("td", {
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, seriesIndicator(i)), metrics), append([react.createElement("td", {
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    })], source2_2)))), (arg10 = ofArray([propertyRow("Duration", metrics, (m_1) => m_1.TimeSeconds, (_arg1, v) => optionalDurationFromSeconds(OptionHelpers_optionalFloatFromInt(v)), (_arg2) => ""), propertyRow("Calories", metrics, (m_2) => m_2.Calories, calories, caloriesUnits), (weights = weights_1, exists((option_1) => (option_1 != null), weights) ? react.createElement("tr", {}, ...(source2 = map((weight) => {
        if (weight == null) {
            return react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, "");
        }
        else {
            return react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, weight_1(context, weight), " ", weightUnits(context));
        }
    }, weights), append([react.createElement("td", {
        className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
    }, "Weight")], source2))) : react.createElement(react.Fragment, {}))]), headerRow("Miscellaneous", arg10)), (arg10_3 = ofArray([propertyRow("Distance", metrics, (m_3) => m_3.DistanceMeters, distance, distanceUnits), propertyRow("Average Speed", metrics, (m_4) => m_4.AverageSpeedMetersPerSecond, speed, speedUnits), propertyRow("Maximum Speed", metrics, (m_5) => m_5.MaximumSpeedMetersPerSecond, speed, speedUnits)]), headerRow("Distance and speed", arg10_3)), (arg10_7 = ofArray([propertyRow("Elevation Gained", metrics, (m_6) => m_6.ElevationGainedMeters, ascent, ascentUnits), propertyRow("Maximum Altitude", metrics, (m_7) => m_7.MaximumAltitudeMeters, ascent, ascentUnits), propertyRow("Minimum Altitude", metrics, (m_8) => m_8.MinimumAltitudeMeters, ascent, ascentUnits)]), headerRow("Altitude", arg10_7)), (arg10_11 = ofArray([propertyRow("Normalised Power", metrics, (m_9) => m_9.NormalisedPower, power, powerUnits), propertyRow("Average Power", metrics, (m_10) => m_10.AveragePower, power, powerUnits), propertyRow("Maximum Power", metrics, (m_11) => m_11.MaximumPower, power, powerUnits)]), headerRow("Power", arg10_11)), (arg10_15 = ofArray([propertyRow("Average Heart Rate", metrics, (m_12) => m_12.AverageHeartRate, heartRate, heartRateUnits), propertyRow("Maximum Heart Rate", metrics, (m_13) => m_13.MaximumHeartRate, heartRate, heartRateUnits)]), headerRow("Heart Rate", arg10_15)), (arg10_18 = ofArray([propertyRow("Average Cadence", metrics, (m_14) => m_14.AverageCadence, cadence, cadenceUnits), propertyRow("Maximum Cadence", metrics, (m_15) => m_15.MaximumCadence, cadence, cadenceUnits)]), headerRow("Cadence", arg10_18)))))));
}

