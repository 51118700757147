import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { uncurry, equalsSafe, equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { Sort$1__WithNewColumn_1505, Sort$1__get_InvertSortOrder, Sort$1__get_SortColumn, PowerProgrammeSortColumn, Sort$1 } from "../../../../shared/Shared.Api.fs.js";
import { lengthAsString } from "../Common.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { startImmediate } from "../../.fable/fable-library.3.0.0/Async.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { powerProgramme } from "../../Server.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { scrollingSortableGrid } from "../../Grids.fs.js";
import { panelTitle } from "../../Typography.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton as singleton_1, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { view } from "../../Spinner.fs.js";
import { plain } from "../../Buttons.fs.js";

export class UnableToGetPageException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToGetPageException$reflection() {
    return class_type("PowerProgramme.Grid.View.UnableToGetPageException", void 0, UnableToGetPageException, class_type("System.Exception"));
}

function UnableToGetPageException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToGetPageException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function sortFromIndexes(sortOrderIndex, sortColumnIndex) {
    return ((sortOrderIndex === 0) ? ((arg0) => (new Sort$1(0, arg0))) : ((arg0_1) => (new Sort$1(1, arg0_1))))((sortColumnIndex === 0) ? (new PowerProgrammeSortColumn(0)) : ((sortColumnIndex === 1) ? (new PowerProgrammeSortColumn(1)) : ((sortColumnIndex === 2) ? (new PowerProgrammeSortColumn(2)) : ((sortColumnIndex === 3) ? (new PowerProgrammeSortColumn(3)) : (new PowerProgrammeSortColumn(0))))));
}

export function sortColumnFromType(sort) {
    const sortColumn = (column) => {
        switch (column.tag) {
            case 1: {
                return 1;
            }
            case 2: {
                return 2;
            }
            case 3: {
                return 3;
            }
            default: {
                return 0;
            }
        }
    };
    if (sort.tag === 1) {
        return sortColumn(sort.fields[0]) | 0;
    }
    else {
        return sortColumn(sort.fields[0]) | 0;
    }
}

export function sortOrderFromType(sort) {
    if (sort.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export function convertLengthToGridString(context, length) {
    return lengthAsString(context, length);
}

export const grid = FunctionComponent_Of_Z5A158BBF((props) => {
    const items = react.useState(new Array(0));
    const isLoading = react.useState(true);
    const pageNumber = react.useState(0);
    const sortOrder = react.useState(new Sort$1(1, new PowerProgrammeSortColumn(0)));
    const numberOfPages = react.useState(0);
    const numberOfRows = react.useState(0);
    const pageSize = react.useState(0);
    const refresh = (order, page) => {
        const value = startImmediate(singleton.Delay(() => singleton.Bind(powerProgramme((api) => api.getForUser(order, page, true)), (_arg1) => {
            let result;
            const apiResponse = _arg1;
            return singleton.Return((apiResponse.tag === 0) ? (result = apiResponse.fields[0], (pageNumber[1](result.PageNumber), (pageSize[1](result.PageSize), (numberOfPages[1](result.NumberOfPages), (numberOfRows[1](result.NumberOfRows), (items[1](result.Items), (isLoading[1](false), void 0))))))) : (void 0));
        })));
        void undefined;
    };
    const haveProgrammes = (items[0]).length > 0;
    const columns = [["Name", (pp) => pp.Name, new PowerProgrammeSortColumn(2)], ["Duration", (pp_1) => convertLengthToGridString(props.Context, pp_1.Duration), new PowerProgrammeSortColumn(1)], ["Training Stress", (pp_2) => toText(printf("%d"))(pp_2.TrainingStress), new PowerProgrammeSortColumn(3)], ["Created On", (pp_3) => toShortDateString(pp_3.CreatedAtUtc), new PowerProgrammeSortColumn(0)]];
    react.useEffect(() => {
        refresh(sortOrder[0], pageNumber[0]);
    }, []);
    return haveProgrammes ? react.createElement("div", {}, scrollingSortableGrid("50vh", (itemIndex) => {
        props.OnSelected((items[0])[itemIndex].Id);
    }, (sortColumn) => {
        const newSort = equalsSafe(sortColumn, Sort$1__get_SortColumn(sortOrder[0])) ? Sort$1__get_InvertSortOrder(sortOrder[0]) : Sort$1__WithNewColumn_1505(sortOrder[0], sortColumn);
        sortOrder[1](newSort);
        refresh(newSort, pageNumber[0]);
    }, columns, Sort$1__get_SortColumn(sortOrder[0]), items[0], pageNumber[0], numberOfPages[0], numberOfRows[0], pageSize[0], (gotoPageNumber) => {
        pageNumber[1](gotoPageNumber);
        refresh(sortOrder[0], gotoPageNumber);
    })) : props.NoProgrammes;
}, void 0, uncurry(2, void 0), void 0, "grid", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Grid/View.fs", 49);

export const chooser = FunctionComponent_Of_Z5A158BBF((props) => react.createElement("div", {
    className: "fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-1200",
}, react.createElement("div", {
    className: "fixed inset-0 transition-opacity",
}, react.createElement("div", {
    className: "absolute inset-0 bg-gray-500 opacity-75",
    onClick: (_arg1) => {
        props.OnHide();
    },
})), react.createElement("div", {
    className: "bg-white rounded-lg pt-5 pb-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-5/6 sm:w-5/6",
}, react.createElement("div", {
    className: "max-h-1/2",
}, react.createElement("div", {
    className: "mx-auto flex items-center justify-center mb-4",
}, panelTitle("Choose workout")), react.createElement("div", {
    className: "relative",
}, ...ofSeq(delay(() => append(singleton_1(grid({
    Context: props.Context,
    NoProgrammes: "No workouts to choose from",
    OnSelected: props.OnChosen,
})), delay(() => (props.IsSaving ? singleton_1(react.createElement("div", {
    className: "absolute z-40 top-0 bottom-0 left-0 right-0 bg-white flex flex-row items-center justify-center",
}, view)) : singleton_1(react.createElement(react.Fragment, {})))))))), react.createElement("div", {
    className: "grid grid-cols-3 gap-4 mx-4 mt-4",
}, react.createElement("div", {}), react.createElement("div", {}), plain("Cancel", props.OnHide, props.IsSaving))))), void 0, uncurry(2, void 0), void 0, "chooser", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Grid/View.fs", 124);

