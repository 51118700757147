import * as react from "react";
import { PowerZonesPage, Page, modifyLocation } from "./Router.fs.js";
import { empty, length, item, ofArray, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { singleton, delay, head, tryFindBack, findBack, map, last, tryFindIndex, append, mapIndexed, concat } from "./.fable/fable-library.3.0.0/Seq.js";
import { boldContentLabel, colouredContent, content, subtleLabel } from "./Typography.fs.js";
import { UserHeartRateZones_get_ColorCodes, UserPowerZones__AsWattsKg_5E38073B, PeakPerformance, UserPowerZones_get_ColorCodes, UserHeartRateZones_get_Colors, UserHeartRateZones__ToList, UserPowerZones_get_Colors, UserPowerZones__ToList } from "../../shared/Shared.Models.fs.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { IBarGraphProps, XAxisConfiguration, GraphAnnotation, GraphColor, GraphDataPoint, bar } from "./CartesianGraph.fs.js";
import { shortDurationAsString } from "./Units.fs.js";
import { GraphValueTransformer } from "./GraphShared.fs.js";
import { bind } from "./.fable/fable-library.3.0.0/Option.js";
import { comparePrimitives, max as max_1 } from "./.fable/fable-library.3.0.0/Util.js";

export function modalWrapper(onClick, modal) {
    return react.createElement("div", {
        className: "fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-1200",
    }, react.createElement("div", {
        className: "fixed inset-0 transition-opacity",
    }, react.createElement("div", {
        className: "absolute inset-0 bg-gray-500 opacity-75",
        onClick: onClick,
    })), react.createElement("div", {
        className: "bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6",
    }, modal));
}

function setPowerZones() {
    modifyLocation(new Page(6, new PowerZonesPage(0)));
}

export function condensedPropValueTable(propertyValues) {
    return react.createElement("div", {
        className: "grid grid-cols-2",
    }, ...ofSeq(concat(mapIndexed((index, tupledArg) => ofArray([react.createElement("div", {
        className: "text-left",
    }, subtleLabel(tupledArg[0])), react.createElement("div", {
        className: "text-right",
    }, content(tupledArg[1]))]), propertyValues))));
}

export function zonesTable(zones, ftpInBold) {
    const zonesList = UserPowerZones__ToList(zones);
    const zonesOutput = concat(mapIndexed((index, tupledArg) => {
        const label_2 = tupledArg[0];
        const previousValue = (index === 0) ? -1 : item(index - 1, zonesList)[1];
        const color_2 = item(index, UserPowerZones_get_Colors());
        if (index === (length(zonesList) - 1)) {
            return ofArray([react.createElement("div", {
                className: "text-left truncate",
            }, subtleLabel(label_2)), react.createElement("div", {
                className: "text-right",
            }, colouredContent(toText(printf("\u003e %.0f"))(previousValue), color_2), content(" watts"))]);
        }
        else {
            const fromValue = previousValue + 1;
            return ofArray([react.createElement("div", {
                className: "text-left truncate",
            }, subtleLabel(label_2)), react.createElement("div", {
                className: "text-right",
            }, colouredContent(toText(printf("%.0f - %.0f"))(fromValue)(tupledArg[1]), color_2), content(" watts"))]);
        }
    }, zonesList));
    return react.createElement("div", {
        className: "grid grid-cols-2",
    }, ...(ftpInBold ? append(zonesOutput, [react.createElement("div", {
        className: "text-left",
    }, boldContentLabel("FTP")), react.createElement("div", {
        className: "text-right",
    }, boldContentLabel(toText(printf("%.0f"))(zones.CurrentFtp)), boldContentLabel(" watts"))]) : append(zonesOutput, [react.createElement("div", {
        className: "text-left",
    }, subtleLabel("FTP")), react.createElement("div", {
        className: "text-right",
    }, content(toText(printf("%.0f"))(zones.CurrentFtp)), content(" watts"))])));
}

export function heartRateConesTable(zones, maxInBold) {
    const zonesList = UserHeartRateZones__ToList(zones);
    const zonesOutput = concat(mapIndexed((index, tupledArg) => {
        const label_2 = tupledArg[0];
        const previousValue = ((index === 0) ? -1 : item(index - 1, zonesList)[1]) | 0;
        const color_2 = item(index, UserHeartRateZones_get_Colors());
        if (index === (length(zonesList) - 1)) {
            return ofArray([react.createElement("div", {
                className: "text-left",
            }, subtleLabel(label_2)), react.createElement("div", {
                className: "text-right",
            }, colouredContent(toText(printf("\u003e %d"))(previousValue), color_2), content(" bpm"))]);
        }
        else {
            const fromValue = (previousValue + 1) | 0;
            return ofArray([react.createElement("div", {
                className: "text-left",
            }, subtleLabel(label_2)), react.createElement("div", {
                className: "text-right",
            }, colouredContent(toText(printf("%d - %d"))(fromValue)(tupledArg[1]), color_2), content(" bpm"))]);
        }
    }, zonesList));
    return react.createElement("div", {
        className: "grid grid-cols-2",
    }, ...(maxInBold ? append(zonesOutput, [react.createElement("div", {
        className: "text-left",
    }, boldContentLabel("Max Heart Rate")), react.createElement("div", {
        className: "text-right",
    }, boldContentLabel(toText(printf("%d"))(zones.MaximumHeartRate)), boldContentLabel(" bpm"))]) : append(zonesOutput, [react.createElement("div", {
        className: "text-left",
    }, subtleLabel("Max Heart Rate")), react.createElement("div", {
        className: "text-right",
    }, content(toText(printf("%d"))(zones.MaximumHeartRate)), content(" bpm"))])));
}

export function Peaks_colorForPowerZone(powerZones, _arg2, power) {
    if (powerZones == null) {
        return "#4A5568";
    }
    else {
        const possibleIndex = tryFindIndex((tupledArg) => (power <= tupledArg[1]), UserPowerZones__ToList(powerZones));
        if (possibleIndex == null) {
            return last(UserPowerZones_get_ColorCodes());
        }
        else {
            return item(possibleIndex, UserPowerZones_get_ColorCodes());
        }
    }
}

export function Peaks_speedGraph(speedPeaks) {
    return react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p) => (new GraphDataPoint(p.PeakValue, shortDurationAsString(p.PeakDurationSeconds))), speedPeaks)), new GraphColor(1, (_arg1, speed) => findBack((tupledArg) => (speed > tupledArg[0]), mapIndexed((i, color) => [item(i, ofArray([0, 5, 10, 15, 30, 60, 90])), color], UserPowerZones_get_ColorCodes()))[1]), "#718096", new GraphAnnotation(0), (tupledArg_1) => [0, Math.ceil(tupledArg_1[1] / 5) * 5], true, false, new XAxisConfiguration(0), new GraphValueTransformer(1, (v) => toText(printf("%.1f"))(v)))));
}

export function Peaks_ascentGraph(ascentPeaks) {
    return react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p) => (new GraphDataPoint(p.PeakValue, shortDurationAsString(p.PeakDurationSeconds))), ascentPeaks)), new GraphColor(1, (_arg1, ascent) => {
        const colorCodes = mapIndexed((i, color) => [item(i, ofArray([0, 10, 15, 20, 35, 50, 65])), color], UserPowerZones_get_ColorCodes());
        const foundColor = tryFindBack((tupledArg) => (ascent > tupledArg[0]), colorCodes);
        if (foundColor == null) {
            return head(colorCodes)[1];
        }
        else {
            return foundColor[1];
        }
    }), "#718096", new GraphAnnotation(0), (tupledArg_1) => [0, Math.ceil(tupledArg_1[1] / 5) * 5], true, false, new XAxisConfiguration(0), new GraphValueTransformer(1, (v) => toText(printf("%.1f"))(v)))));
}

export function Peaks_peakPowerGraph(powerPeaks, userPowerZones, optionalWeightKg) {
    let patternInput;
    if (optionalWeightKg == null) {
        patternInput = [powerPeaks, userPowerZones, (v_1) => toText(printf("%.0f"))(v_1)];
    }
    else {
        const weightKg = optionalWeightKg;
        patternInput = [Array.from(map((p) => (new PeakPerformance(p.StartDateTimeUtc, p.PeakDurationSeconds, p.PeakValue / weightKg, p.PeakType, p.Lap, p.CardioActivitySummaryId, p.Weight)), powerPeaks)), bind((upz) => UserPowerZones__AsWattsKg_5E38073B(upz, weightKg), userPowerZones), (v) => toText(printf("%.2f"))(v)];
    }
    const calculatedPowerZones = patternInput[1];
    const calculatedPeaks = patternInput[0];
    return react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p_1) => (new GraphDataPoint(p_1.PeakValue, shortDurationAsString(p_1.PeakDurationSeconds))), calculatedPeaks)), new GraphColor(1, (_arg2, power) => Peaks_colorForPowerZone(calculatedPowerZones, _arg2, power)), "#718096", new GraphAnnotation(1, (helpers_1) => {
        const helpers = helpers_1;
        if (calculatedPowerZones == null) {
            return empty();
        }
        else {
            const upz_1 = calculatedPowerZones;
            return ofSeq(delay(() => {
                const y = helpers.CalcY(upz_1.CurrentFtp) | 0;
                return append(singleton(react.createElement("line", {
                    x1: helpers.CalcX(0),
                    y1: y,
                    x2: helpers.CalcX(calculatedPeaks.length),
                    y2: y,
                    stroke: "#718096",
                    strokeDasharray: "4 2",
                    strokeWidth: 2,
                })), delay(() => singleton(react.createElement("text", {
                    x: helpers.CalcX(calculatedPeaks.length),
                    y: y - 4,
                    fill: "#718096",
                    textAnchor: "end",
                    alignmentBaseline: "bottom",
                }, (optionalWeightKg == null) ? toText(printf("%.0f watts"))(upz_1.CurrentFtp) : toText(printf("%.02f watts/kg"))(upz_1.CurrentFtp)))));
            }));
        }
    }), (tupledArg) => {
        const max = tupledArg[1];
        return (optionalWeightKg == null) ? [0, Math.ceil(max / 10) * 10] : [0, max];
    }, true, false, new XAxisConfiguration(0), new GraphValueTransformer(1, patternInput[2]))));
}

export function Peaks_colorForBpm(heartRateZones, _arg2, bpm) {
    if (heartRateZones == null) {
        return "#4A5568";
    }
    else {
        const bpm2 = (~(~bpm)) | 0;
        const possibleIndex = tryFindIndex((tupledArg) => (bpm2 <= tupledArg[1]), UserHeartRateZones__ToList(heartRateZones));
        if (possibleIndex == null) {
            return last(UserHeartRateZones_get_ColorCodes());
        }
        else {
            return item(possibleIndex, UserHeartRateZones_get_ColorCodes());
        }
    }
}

export function Peaks_peakHeartRateGraph(heartRateZones, heartPeaks) {
    return react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p) => (new GraphDataPoint(p.PeakValue, shortDurationAsString(p.PeakDurationSeconds))), heartPeaks)), new GraphColor(1, (_arg2, bpm) => Peaks_colorForBpm(heartRateZones, _arg2, bpm)), "#718096", new GraphAnnotation(0), (tupledArg) => [Math.floor(max_1(comparePrimitives, 0, tupledArg[0] - 10) / 10) * 10, Math.ceil(tupledArg[1] / 10) * 10], true, false, new XAxisConfiguration(0), new GraphValueTransformer(0))));
}

export const Peaks_noHeartAndPowerMessage = react.createElement("div", {
    className: "rounded-md bg-yellow-50 p-4",
}, react.createElement("div", {
    className: "flex",
}, react.createElement("div", {
    className: "flex-shrink-0",
}, react.createElement("svg", {
    className: "h-5 w-5 text-yellow-400",
    fill: "currentColor",
    viewbox: "0 0 20 20",
}, react.createElement("path", {
    ["fill-rule"]: "evenodd",
    d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
    ["clip-rule"]: "evenodd",
}))), react.createElement("div", {
    className: "ml-3",
}, react.createElement("h3", {
    className: "text-sm leading-5 font-medium text-yellow-800",
}, "No power or heart rate measurements"), react.createElement("div", {
    className: "mt-2 text-sm leading-5 text-yellow-700",
}, react.createElement("p", {}, "Try using a heart rate monitor and, if you\u0027re cycling, a power meter to help you focus your training.")))));

export const Peaks_stillCalculatingZones = react.createElement("div", {
    className: "rounded-md bg-yellow-50 p-4",
}, react.createElement("div", {
    className: "flex",
}, react.createElement("div", {
    className: "flex-shrink-0",
}, react.createElement("svg", {
    className: "h-5 w-5 text-yellow-400",
    fill: "currentColor",
    viewbox: "0 0 20 20",
}, react.createElement("path", {
    ["fill-rule"]: "evenodd",
    d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
    ["clip-rule"]: "evenodd",
}))), react.createElement("div", {
    className: "ml-3",
}, react.createElement("h3", {
    className: "text-sm leading-5 font-medium text-yellow-800",
}, "Calculating power zones"), react.createElement("div", {
    className: "mt-2 text-sm leading-5 text-yellow-700",
}, react.createElement("p", {}, "We are still calculating your zone information, check back in a little while.")))));

export const Peaks_stillCalculatingPeaks = react.createElement("div", {
    className: "rounded-md bg-yellow-50 p-4",
}, react.createElement("div", {
    className: "flex",
}, react.createElement("div", {
    className: "flex-shrink-0",
}, react.createElement("svg", {
    className: "h-5 w-5 text-yellow-400",
    fill: "currentColor",
    viewbox: "0 0 20 20",
}, react.createElement("path", {
    ["fill-rule"]: "evenodd",
    d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
    ["clip-rule"]: "evenodd",
}))), react.createElement("div", {
    className: "ml-3",
}, react.createElement("h3", {
    className: "text-sm leading-5 font-medium text-yellow-800",
}, "Calculating peaks"), react.createElement("div", {
    className: "mt-2 text-sm leading-5 text-yellow-700",
}, react.createElement("p", {}, "We are still calculating performance peak information, check back in a little while.")))));

