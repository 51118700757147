import * as react from "react";
import { boldLabel, subtleLabel, tinyColouredContent, tinyLabel } from "../../Typography.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { IconSize, strengthIcon, cyclingIcon, heartBeatIcon, swimmingIcon, walkingIcon, runningIcon } from "../../Icons.fs.js";
import { Id_value, Id_create } from "../../../../shared/Shared.Types.fs.js";
import { WeeklyTrainingLogPage, CardioActivityPage, Page, modifyLocation } from "../../Router.fs.js";
import { trainingStress, trainingStressUnits, calories, caloriesUnits, ascent, ascentUnits, normalisedPower, normalisedPowerUnits, distance, distanceUnits, elapsedTimeFromSeconds, elapsedTimeFromSecondsUnits } from "../../Units.fs.js";
import { months, abbreviatedMonths, days, viewableDateRange } from "../Helpers.fs.js";
import { length, ofArray, empty, ofSeq, item as item_1, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { sortWith, concat, takeWhile, rangeNumber, singleton as singleton_1, delay, sumBy, isEmpty, filter, collect, map, append } from "../../.fable/fable-library.3.0.0/Seq.js";
import { dayOfWeek as dayOfWeek_1, create, now, equals, compare, day, month as month_2, addDays, date as date_1 } from "../../.fable/fable-library.3.0.0/Date.js";
import { TrainingLogSummary__get_RecordedAtUtc } from "../../../../shared/Shared.Models.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Msg, Model$reflection, Msg$reflection } from "./Types.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { uncurry, equalsSafe, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { toggleSwitch, prevNextButtons, configurableRoundedDropdown, roundedDropdown } from "../../Form.fs.js";
import { inlineTabs, toolbarPage } from "../../Layout.fs.js";

export function columnHeader(name) {
    return react.createElement("div", {
        className: "px-6 py-3 border-b border-r border-gray-100 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, name);
}

export function innerRow(content) {
    return react.createElement("div", {
        className: "flex flex-row justify-between items-center align-center",
    }, ...content);
}

export function cellContentBox(onClick, isInCart, isFirstContentOnDay, content) {
    const bgColor = isInCart ? " bg-indigo-50 " : " bg-white ";
    return react.createElement("div", {
        onClick: (_arg1) => {
            onClick();
        },
        className: ("border-gray-200 border px-2 py-2 cursor-pointer" + bgColor) + (isFirstContentOnDay ? "" : " mt-2"),
    }, ...content);
}

export function noItemsAlert(context) {
    return react.createElement("div", {
        className: "rounded-md bg-blue-100 p-4",
    }, react.createElement("div", {
        className: "flex",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, react.createElement("svg", {
        className: "h-5 w-5 text-blue-400",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        ["fill-rule"]: "evenodd",
        d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
        ["clip-rule"]: "evenodd",
    }))), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("div", {
        className: "text-sm leading-5 text-blue-700",
    }, react.createElement("p", {}, "No workouts recorded or planned this month.")), react.createElement("div", {
        className: "mt-4",
    }, react.createElement("div", {
        className: "-mx-2 -my-1.5 flex",
    }, react.createElement("button", {
        onClick: (_arg1) => {
            context.ModalManager.openUploadModal();
        },
        className: "ml-3 px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:bg-blue-100 transition ease-in-out duration-150",
    }, "Upload a workout"))))));
}

export function optionalRowValue(context, unitsFunc, valueFunc, label, value) {
    let arg20, arg10;
    if (value == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        return innerRow([tinyLabel(label), tinyColouredContent((arg20 = unitsFunc(context), (arg10 = valueFunc(context, value), toText(printf("%s %s"))(arg10)(arg20))))]);
    }
}

export function cell(isToday, content) {
    return react.createElement("div", {
        className: "px-2 py-2 border-gray-100 border-r border-b" + (isToday ? " bg-red-50" : "bg-white"),
    }, ...content);
}

export function cellContentTitle(trainingLogActivity) {
    let patternInput;
    switch (trainingLogActivity.tag) {
        case 0: {
            const matchValue = trainingLogActivity.fields[0].ActivityType;
            switch (matchValue.tag) {
                case 0: {
                    patternInput = [runningIcon, "Run"];
                    break;
                }
                case 3: {
                    patternInput = [walkingIcon, "Walk"];
                    break;
                }
                case 2: {
                    patternInput = [swimmingIcon, "Swim"];
                    break;
                }
                case 4: {
                    patternInput = [heartBeatIcon, "Mixed"];
                    break;
                }
                case 5: {
                    patternInput = [heartBeatIcon, "Unknown"];
                    break;
                }
                default: {
                    patternInput = [cyclingIcon, "Cycle"];
                }
            }
            break;
        }
        case 1: {
            patternInput = [strengthIcon, "Strength"];
            break;
        }
        default: {
            throw (new Error("The match cases were incomplete against type of \u0027TrainingLogSummary\u0027 at /home/runner/work/strengthPlus/strengthPlus/client/src/WeeklyTrainingLog/Index/View.fs"));
        }
    }
    return react.createElement("div", {
        className: "mb-2",
    }, innerRow([patternInput[0](new IconSize(2)), subtleLabel(patternInput[1])]));
}

export function cellContent(context, isCompareMode, trainingLogActivity) {
    const handleClick = isCompareMode ? ((c) => {
        if (context.CartManager.isInCart(c)) {
            context.CartManager.removeItemWithActivityId(Id_create(c.Id));
        }
        else {
            context.CartManager.addItem(c);
        }
    }) : ((c_1) => {
        modifyLocation(new Page(8, new CardioActivityPage(1, c_1.Id)));
    });
    switch (trainingLogActivity.tag) {
        case 0: {
            const c_2 = trainingLogActivity.fields[0];
            return cellContentBox(() => {
                handleClick(c_2);
            }, context.CartManager.isInCart(c_2), true, [cellContentTitle(trainingLogActivity), optionalRowValue(context, elapsedTimeFromSecondsUnits, elapsedTimeFromSeconds, "Duration", c_2.Metrics.TimeSeconds), optionalRowValue(context, distanceUnits, distance, "Distance", c_2.Metrics.DistanceMeters), optionalRowValue(context, normalisedPowerUnits, normalisedPower, "NP", c_2.Metrics.NormalisedPower), optionalRowValue(context, ascentUnits, ascent, "Ascent", c_2.Metrics.ElevationGainedMeters), optionalRowValue(context, caloriesUnits, calories, "Calories", c_2.Metrics.Calories)]);
        }
        case 1: {
            return react.createElement("div", {}, "strengh");
        }
        default: {
            throw (new Error("The match cases were incomplete against type of \u0027TrainingLogSummary\u0027 at /home/runner/work/strengthPlus/strengthPlus/client/src/WeeklyTrainingLog/Index/View.fs"));
        }
    }
}

export function calendar(context, isLoading, month, year, items, isCompareMode) {
    const patternInput = viewableDateRange(year, month);
    let header;
    const source2 = singleton(columnHeader(""));
    header = append(map(columnHeader, days), source2);
    const body = collect((weekStartDate_1) => {
        let endDate, copyOfStruct_5, startDate, month_1, secondMonth, arg10, label, arg30, arg20, weekItems, totalElapsedTimeValue, filteredValues, matchValue_1, totalDistance, filteredValues_1, matchValue_2, totalAscent, filteredValues_2, matchValue_3, totalCalories, filteredValues_3, matchValue_4, totalTrainingStress, filteredValues_4, matchValue_5;
        const source2_1 = singleton((endDate = (copyOfStruct_5 = date_1(weekStartDate_1), addDays(copyOfStruct_5, 6)), (startDate = weekStartDate_1, (month_1 = item_1(month_2(startDate) - 1, abbreviatedMonths).toLocaleUpperCase(), (secondMonth = ((month_2(startDate) === month_2(endDate)) ? "" : (arg10 = item_1(month_2(endDate) - 1, abbreviatedMonths).toLocaleUpperCase(), toText(printf(" %s"))(arg10))), (label = (arg30 = (day(endDate) | 0), (arg20 = (day(startDate) | 0), toText(printf("%s %d-%d%s"))(month_1)(arg20)(arg30)(secondMonth))), (weekItems = ofSeq(filter((i) => {
            let copyOfStruct, copyOfStruct_1;
            if (compare((copyOfStruct = TrainingLogSummary__get_RecordedAtUtc(i), date_1(copyOfStruct)), date_1(startDate)) >= 0) {
                return compare((copyOfStruct_1 = TrainingLogSummary__get_RecordedAtUtc(i), date_1(copyOfStruct_1)), date_1(endDate)) <= 0;
            }
            else {
                return false;
            }
        }, items)), (totalElapsedTimeValue = (filteredValues = filter((option) => (option != null), map((i_1) => {
            if (i_1.tag === 0) {
                return i_1.fields[0].Metrics.TimeSeconds;
            }
            else {
                return void 0;
            }
        }, weekItems)), (matchValue_1 = isEmpty(filteredValues), matchValue_1 ? (void 0) : sumBy((v) => v, filteredValues, {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        }))), (totalDistance = (filteredValues_1 = filter((option_1) => (option_1 != null), map((i_2) => {
            if (i_2.tag === 0) {
                return i_2.fields[0].Metrics.DistanceMeters;
            }
            else {
                return void 0;
            }
        }, weekItems)), (matchValue_2 = isEmpty(filteredValues_1), matchValue_2 ? (void 0) : sumBy((v_1) => v_1, filteredValues_1, {
            GetZero: () => 0,
            Add: (x_1, y_1) => (x_1 + y_1),
        }))), (totalAscent = (filteredValues_2 = filter((option_2) => (option_2 != null), map((i_3) => {
            if (i_3.tag === 0) {
                return i_3.fields[0].Metrics.ElevationGainedMeters;
            }
            else {
                return void 0;
            }
        }, weekItems)), (matchValue_3 = isEmpty(filteredValues_2), matchValue_3 ? (void 0) : sumBy((v_2) => v_2, filteredValues_2, {
            GetZero: () => 0,
            Add: (x_2, y_2) => (x_2 + y_2),
        }))), (totalCalories = (filteredValues_3 = filter((option_3) => (option_3 != null), map((i_4) => {
            if (i_4.tag === 0) {
                return i_4.fields[0].Metrics.Calories;
            }
            else {
                return void 0;
            }
        }, weekItems)), (matchValue_4 = isEmpty(filteredValues_3), matchValue_4 ? (void 0) : sumBy((v_3) => v_3, filteredValues_3, {
            GetZero: () => 0,
            Add: (x_3, y_3) => (x_3 + y_3),
        }))), (totalTrainingStress = (filteredValues_4 = filter((option_4) => (option_4 != null), map((i_5) => {
            if (i_5.tag === 0) {
                return i_5.fields[0].Metrics.TrainingStressScore;
            }
            else {
                return void 0;
            }
        }, weekItems)), (matchValue_5 = isEmpty(filteredValues_4), matchValue_5 ? (void 0) : sumBy((v_4) => v_4, filteredValues_4, {
            GetZero: () => 0,
            Add: (x_4, y_4) => (x_4 + y_4),
        }))), cell(false, [react.createElement("div", {}, ...ofSeq(delay(() => append(singleton_1(boldLabel(label)), delay(() => (isEmpty(weekItems) ? singleton_1(react.createElement("div", {}, tinyLabel("No activity"))) : singleton_1(react.createElement(react.Fragment, {}, optionalRowValue(context, elapsedTimeFromSecondsUnits, elapsedTimeFromSeconds, "Duration", totalElapsedTimeValue), optionalRowValue(context, distanceUnits, distance, "Distance", totalDistance), optionalRowValue(context, ascentUnits, ascent, "Ascent", totalAscent), optionalRowValue(context, caloriesUnits, calories, "Calories", totalCalories), optionalRowValue(context, trainingStressUnits, trainingStress, "Training stress", totalTrainingStress)))))))))])))))))))))));
        return append(map((dayOffset) => {
            let copyOfStruct_8;
            let date;
            let copyOfStruct_6 = date_1(weekStartDate_1);
            date = addDays(copyOfStruct_6, dayOffset);
            const content = map((i_7) => cellContent(context, isCompareMode, i_7), filter((i_6) => {
                let copyOfStruct_7;
                return equals((copyOfStruct_7 = TrainingLogSummary__get_RecordedAtUtc(i_6), date_1(copyOfStruct_7)), date);
            }, items));
            return cell(equals(date, (copyOfStruct_8 = now(), date_1(copyOfStruct_8))), content);
        }, rangeNumber(0, 1, 6)), source2_1);
    }, takeWhile((weekStartDate) => {
        let copyOfStruct_4, copyOfStruct_3;
        if (month_2(weekStartDate) <= month) {
            return true;
        }
        else {
            return (copyOfStruct_4 = (copyOfStruct_3 = date_1(weekStartDate), addDays(copyOfStruct_3, 7)), month_2(copyOfStruct_4)) <= month;
        }
    }, map((weekIndex) => {
        let copyOfStruct_2 = date_1(patternInput[0]);
        return addDays(copyOfStruct_2, 7 * weekIndex);
    }, rangeNumber(0, 1, 6))));
    return react.createElement("div", {
        className: "overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 h-full",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full overflow-hidden bg-white border-t border-gray-100",
    }, react.createElement("div", {
        className: "grid grid-cols-1 md:grid-cols-8",
    }, ...concat(ofSeq(delay(() => append(singleton_1(header), delay(() => append(singleton_1(body), delay(() => {
        const matchValue_7 = [isEmpty(items), isLoading];
        let pattern_matching_result;
        if (matchValue_7[0]) {
            if (matchValue_7[1]) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton_1(delay(() => singleton_1(react.createElement("div", {
                    className: "col-span-8 px-6 py-6",
                }, noItemsAlert(context)))));
            }
            case 1: {
                return singleton_1([]);
            }
        }
    }))))))))));
}

export function calendarAsList(context, month, year, items) {
    const calendarStartDate = create(year, month, 1);
    return react.createElement("div", {}, ...ofSeq(map((offset) => {
        let tht, matchValue, matchValue_1, arg20, arg10, dw, copyOfStruct_2;
        let date;
        let copyOfStruct = addDays(calendarStartDate, offset);
        date = date_1(copyOfStruct);
        const dayItems = filter((i) => {
            let copyOfStruct_1;
            return equals((copyOfStruct_1 = TrainingLogSummary__get_RecordedAtUtc(i), date_1(copyOfStruct_1)), date);
        }, items);
        return react.createElement("div", {
            className: "bg-white",
        }, ...(tht = (matchValue = (day(date) | 0), (matchValue === 1) ? "st" : ((matchValue === 2) ? "nd" : ((matchValue === 3) ? "rd" : "th"))), (matchValue_1 = isEmpty(dayItems), matchValue_1 ? empty() : ofArray([columnHeader((arg20 = (day(date) | 0), (arg10 = item_1((dw = (((7 + dayOfWeek_1(date)) - 1) | 0), (dw > 6) ? (dw - 7) : dw), days), toText(printf("%s %d%s"))(arg10)(arg20)(tht)))), cell(equals(date, (copyOfStruct_2 = now(), date_1(copyOfStruct_2))), map((i_1) => cellContent(context, false, i_1), dayItems))]))));
    }, rangeNumber(0, 1, 31))));
}

export class ITrainingLogProps extends Record {
    constructor(Context, Dispatch, Model) {
        super();
        this.Context = Context;
        this.Dispatch = Dispatch;
        this.Model = Model;
    }
}

export function ITrainingLogProps$reflection() {
    return record_type("WeeklyTrainingLog.Index.View.ITrainingLogProps", [], ITrainingLogProps, () => [["Context", Context$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)], ["Model", Model$reflection()]]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    let projection;
    const model = props.Model;
    const dispatch = props.Dispatch;
    const context = props.Context;
    const years = ofSeq(map((y) => toText(printf("%d"))(y), model.Years));
    const isPreviousDisabled = (model.SelectedMonthIndex === 0) ? (model.SelectedYearIndex === 0) : false;
    const isNextDisabled = (model.SelectedMonthIndex === 11) ? (model.SelectedYearIndex === (length(model.Years) - 1)) : false;
    const isCompareMode = react.useState(false);
    const allId = Id_create("all");
    const selectedCategoryIndex = react.useState(0);
    const categories = Array.from(append(["All"], sortWith(comparePrimitives, map((c) => c.DisplayText, context.Lookups.CardioCategories))));
    const categoryIds = Array.from(append([allId], map((c_2) => c_2.Id, (projection = ((c_1) => c_1.DisplayText), sortWith((x_1, y_2) => comparePrimitives(projection(x_1), projection(y_2)), context.Lookups.CardioCategories)))));
    const toolbar = react.createElement("div", {
        className: "flex flex-row items-center",
    }, react.createElement("div", {
        className: "mr-4",
    }, roundedDropdown(categories, selectedCategoryIndex[0], (index, _arg1) => {
        selectedCategoryIndex[1](index);
    })()), react.createElement("div", {
        className: "mr-4 flex flex-row",
    }, react.createElement("div", {
        className: "w-32",
    }, configurableRoundedDropdown(true, false, true, true, months, model.SelectedMonthIndex, (index_1, _arg2) => {
        dispatch(new Msg(2, index_1));
    })()), react.createElement("div", {
        className: "w-32",
    }, configurableRoundedDropdown(false, true, false, true, years, model.SelectedYearIndex, (index_2, _arg3) => {
        dispatch(new Msg(3, index_2));
    })())), react.createElement("div", {}, prevNextButtons(isPreviousDisabled, isNextDisabled, () => {
        dispatch(new Msg(4));
    }, () => {
        dispatch(new Msg(5));
    })));
    const selectedCategoryId = categoryIds[selectedCategoryIndex[0]];
    const filteredItems = equalsSafe(selectedCategoryId, allId) ? model.Items : ofSeq(filter((item) => {
        switch (item.tag) {
            case 0: {
                return Id_value(selectedCategoryId) === item.fields[0].CategoryId;
            }
            case 1: {
                return true;
            }
            default: {
                throw (new Error("The match cases were incomplete against type of \u0027TrainingLogSummary\u0027 at /home/runner/work/strengthPlus/strengthPlus/client/src/WeeklyTrainingLog/Index/View.fs"));
            }
        }
    }, model.Items));
    return toolbarPage("Training Log", void 0, model.IsLoading, false, [toolbar], [react.createElement("div", {
        className: "bg-white rounded-t-md",
    }, react.createElement("div", {
        className: "flex flex-row justify-between items-center",
    }, react.createElement("div", {
        className: "px-5",
    }, inlineTabs(["Calendar", "Bubble"], 0, (i) => {
        if (i === 1) {
            modifyLocation(new Page(7, new WeeklyTrainingLogPage(0)));
        }
    })), react.createElement("div", {
        className: "flex flex-row justify-end mr-5",
    }, react.createElement("div", {
        className: "mr-4",
    }, subtleLabel("Select multiple activities")), toggleSwitch(isCompareMode[0], () => {
        isCompareMode[1]((!(isCompareMode[0])));
    })))), react.createElement("div", {
        className: "hidden md:block",
    }, calendar(context, model.IsLoading, model.SelectedMonthIndex + 1, item_1(model.SelectedYearIndex, model.Years), filteredItems, isCompareMode[0])), react.createElement("div", {
        className: "md:hidden",
    }, calendarAsList(context, model.SelectedMonthIndex + 1, item_1(model.SelectedYearIndex, model.Years), filteredItems))]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/WeeklyTrainingLog/Index/View.fs", 214);

