import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Msg, Msg$reflection, Model$reflection } from "./Types.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { analysisPage, panel } from "../../Layout.fs.js";
import { analysisDateRangeEditor, activityTypeEditor } from "../Helpers.fs.js";
import { cardioTypeFromString } from "../Types.fs.js";
import * as react from "react";
import { dashboardTitle, subtleLabel } from "../../Typography.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { ISpiderChartProps, chart, SpiderChartSeries } from "../../SpiderChart.fs.js";
import { ofSeq, empty } from "../../.fable/fable-library.3.0.0/List.js";
import { isEmpty, singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry } from "../../.fable/fable-library.3.0.0/Util.js";

export class ICompetitivenessProps extends Record {
    constructor(Context, Model, Dispatch) {
        super();
        this.Context = Context;
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function ICompetitivenessProps$reflection() {
    return record_type("Analysis.Competitiveness.View.ICompetitivenessProps", [], ICompetitivenessProps, () => [["Context", Context$reflection()], ["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

export function filterPanel(model, context, dispatch) {
    return panel([activityTypeEditor(model.AnalysisCardioType, (arg_1) => {
        dispatch(new Msg(0, cardioTypeFromString(arg_1)));
    }, true), react.createElement("div", {
        className: "italic",
    }, subtleLabel("Only cycling can currently be evaluated for competitiveness")), react.createElement("div", {
        className: "mt-4",
    }, dashboardTitle("Date range")), analysisDateRangeEditor(model.AnalysisDateRange, context.Lookups.CardioCategories, (arg_2) => {
        dispatch(new Msg(1, arg_2));
    })]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    const selectedTabIndex = react.useState(0);
    const femaleLevels = [new SpiderChartSeries(new Float64Array([0, 9.950652, 11.27195, 12.59326, 13.91456, 15.23587, 16.55717, 17.87847]), "5s"), new SpiderChartSeries(new Float64Array([0, 5.307826, 5.863478, 6.41913, 6.974783, 7.530435, 8.086087, 8.641739]), "1m"), new SpiderChartSeries(new Float64Array([0, 2.552174, 3.136522, 3.72087, 4.305217, 4.889565, 5.473913, 6.058261]), "5m"), new SpiderChartSeries(new Float64Array([0, 2.081739, 2.585217, 3.088696, 3.592174, 4.095652, 4.59913, 5.102609]), "20m"), new SpiderChartSeries(new Float64Array([0, 2.081739, 2.585217, 3.088696, 3.592174, 4.095652, 4.59913, 5.102609]), "1h")];
    const tabs = react.createElement("div", {
        className: "mt-6",
    }, panel([react.createElement("div", {
        className: "-mt-2",
    }, react.createElement("div", {
        className: "mb-4",
        style: {
            height: 640,
        },
    }, chart(new ISpiderChartProps([new SpiderChartSeries(new Float64Array([0, 12.167826, 13.983478, 15.79913, 17.614783, 19.430435, 21.246087, 23.061739]), "5s"), new SpiderChartSeries(new Float64Array([0, 6.452174, 7.156522, 7.86087, 8.565217, 9.269565, 9.973913, 10.67826]), "1m"), new SpiderChartSeries(new Float64Array([0, 3.056957, 3.69087, 4.324783, 4.958696, 5.592609, 6.226522, 6.860435]), "5m"), new SpiderChartSeries(new Float64Array([0, 2.505652, 3.076957, 3.648261, 4.219565, 4.79087, 5.362174, 5.933478]), "20m"), new SpiderChartSeries(new Float64Array([0, 2.505652, 3.076957, 3.648261, 4.219565, 4.79087, 5.362174, 5.933478]), "1h")], ["Untrained", "Fair - Cat 5", "Moderate - Cat 4", "Good - Cat 3", "Very good - Cat 2", "Excellent - Cat 1", "Exceptional - Domestic Pro", "World class - International Pro"], new Float64Array([14.1, 7.02, 4.33, 3.62]), "red"))))]));
    return analysisPage(props.Model.IsLoading, false, empty(), empty(), ofSeq(delay(() => append(singleton(filterPanel(props.Model, props.Context, props.Dispatch)), delay(() => (isEmpty(props.Model.Peaks) ? singleton(tabs) : singleton(tabs)))))));
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/Competitiveness/View.fs", 30);

