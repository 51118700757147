import { Union, Record } from "./.fable/fable-library.3.0.0/Types.js";
import { class_type, bool_type, option_type, tuple_type, union_type, string_type, array_type, lambda_type, int32_type, record_type, float64_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { GraphValueTransformer$reflection } from "./GraphShared.fs.js";
import { singleton, delay, fold, empty as empty_1, isEmpty, mapIndexed, concat, append, map, max, averageBy, maxBy, minBy, collect } from "./.fable/fable-library.3.0.0/Seq.js";
import { uncurry, curry, round, comparePrimitives } from "./.fable/fable-library.3.0.0/Util.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import * as react from "react";
import { ofSeq, empty, ofArray } from "./.fable/fable-library.3.0.0/List.js";
import { CSSProp } from "./.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { tinyContentClass, tinyLabel } from "./Typography.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { attachWindowEventWithDisposer } from "./BrowserHelpers.fs.js";

export class ScatterGraphDataPoint extends Record {
    constructor(YAxisValue, XAxisValue) {
        super();
        this.YAxisValue = YAxisValue;
        this.XAxisValue = XAxisValue;
    }
}

export function ScatterGraphDataPoint$reflection() {
    return record_type("ScatterGraph.ScatterGraphDataPoint", [], ScatterGraphDataPoint, () => [["YAxisValue", float64_type], ["XAxisValue", float64_type]]);
}

export class ScatterGraphAnnotationHelpers extends Record {
    constructor(CalcX, CalcY, Width, Height) {
        super();
        this.CalcX = CalcX;
        this.CalcY = CalcY;
        this.Width = (Width | 0);
        this.Height = (Height | 0);
    }
}

export function ScatterGraphAnnotationHelpers$reflection() {
    return record_type("ScatterGraph.ScatterGraphAnnotationHelpers", [], ScatterGraphAnnotationHelpers, () => [["CalcX", lambda_type(float64_type, int32_type)], ["CalcY", lambda_type(float64_type, int32_type)], ["Width", int32_type], ["Height", int32_type]]);
}

export class ScatterGraphSeries extends Record {
    constructor(DataPoints, Color) {
        super();
        this.DataPoints = DataPoints;
        this.Color = Color;
    }
}

export function ScatterGraphSeries$reflection() {
    return record_type("ScatterGraph.ScatterGraphSeries", [], ScatterGraphSeries, () => [["DataPoints", array_type(ScatterGraphDataPoint$reflection())], ["Color", ScatterGraphColor$reflection()]]);
}

export class ScatterGraphColor extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ScatterGraphConstantColor", "ScatterGraphCalculatedColor"];
    }
}

export function ScatterGraphColor$reflection() {
    return union_type("ScatterGraph.ScatterGraphColor", [], ScatterGraphColor, () => [[["Item", string_type]], [["Item", lambda_type(ScatterGraphSeries$reflection(), lambda_type(int32_type, lambda_type(ScatterGraphDataPoint$reflection(), lambda_type(float64_type, string_type))))]]]);
}

export class IScatterGraphProps extends Record {
    constructor(Series, YAxisValueRange, XAxisValueRange, ShowYAxis, ShowXAxis, LogarithmicY, LogarithmicX, StrokeWidth, LegendTextColor, GridColor, YAxisValueTransformer, XAxisValueTransformer, XAxisAverageTransformer, YAxisAverageTransformer, PopoverRows, PopoverAnnotation) {
        super();
        this.Series = Series;
        this.YAxisValueRange = YAxisValueRange;
        this.XAxisValueRange = XAxisValueRange;
        this.ShowYAxis = ShowYAxis;
        this.ShowXAxis = ShowXAxis;
        this.LogarithmicY = LogarithmicY;
        this.LogarithmicX = LogarithmicX;
        this.StrokeWidth = (StrokeWidth | 0);
        this.LegendTextColor = LegendTextColor;
        this.GridColor = GridColor;
        this.YAxisValueTransformer = YAxisValueTransformer;
        this.XAxisValueTransformer = XAxisValueTransformer;
        this.XAxisAverageTransformer = XAxisAverageTransformer;
        this.YAxisAverageTransformer = YAxisAverageTransformer;
        this.PopoverRows = PopoverRows;
        this.PopoverAnnotation = PopoverAnnotation;
    }
}

export function IScatterGraphProps$reflection() {
    return record_type("ScatterGraph.IScatterGraphProps", [], IScatterGraphProps, () => [["Series", array_type(ScatterGraphSeries$reflection())], ["YAxisValueRange", option_type(lambda_type(tuple_type(float64_type, float64_type), tuple_type(float64_type, float64_type)))], ["XAxisValueRange", option_type(lambda_type(tuple_type(float64_type, float64_type), tuple_type(float64_type, float64_type)))], ["ShowYAxis", bool_type], ["ShowXAxis", bool_type], ["LogarithmicY", bool_type], ["LogarithmicX", bool_type], ["StrokeWidth", int32_type], ["LegendTextColor", string_type], ["GridColor", string_type], ["YAxisValueTransformer", GraphValueTransformer$reflection()], ["XAxisValueTransformer", GraphValueTransformer$reflection()], ["XAxisAverageTransformer", option_type(lambda_type(float64_type, float64_type))], ["YAxisAverageTransformer", option_type(lambda_type(float64_type, float64_type))], ["PopoverRows", lambda_type(ScatterGraphSeries$reflection(), lambda_type(int32_type, lambda_type(ScatterGraphDataPoint$reflection(), array_type(tuple_type(string_type, string_type)))))], ["PopoverAnnotation", option_type(lambda_type(string_type, lambda_type(int32_type, lambda_type(ScatterGraphDataPoint$reflection(), class_type("Fable.React.ReactElement")))))]]);
}

const defaultTextHeight = 15;

const defaultTextWidth = 10;

const xAxisSpacing = 3;

const dataPointRadius = 3;

const clickableDataPointRadius = 2 * dataPointRadius;

export function calculateConstraints(width, height, props) {
    let logBase, range;
    const dataPoints = Array.from(collect((s) => s.DataPoints, props.Series));
    const heightAllowingForLegend = ((height - defaultTextHeight) - (~(~(defaultTextHeight / 2)))) | 0;
    const yDataPointMinValue = minBy((ldp) => ldp.YAxisValue, dataPoints, {
        Compare: comparePrimitives,
    }).YAxisValue;
    const yDataPointMaxValue = maxBy((ldp_1) => ldp_1.YAxisValue, dataPoints, {
        Compare: comparePrimitives,
    }).YAxisValue;
    let patternInput;
    const matchValue = props.YAxisValueRange;
    patternInput = ((matchValue != null) ? matchValue([yDataPointMinValue, yDataPointMaxValue]) : [yDataPointMinValue, yDataPointMaxValue]);
    const yAxisMinValue = patternInput[0];
    const yAxisMaxValue = patternInput[1];
    let percentage;
    const rangeValue = yAxisMaxValue - yAxisMinValue;
    percentage = ((value) => ((value - yAxisMinValue) / rangeValue));
    const yAxisWidth = (props.ShowYAxis ? (toText(printf("%.0f"))(yAxisMaxValue).length * defaultTextWidth) : 0) | 0;
    const xDataPointMinValue = minBy((ldp_2) => ldp_2.XAxisValue, dataPoints, {
        Compare: comparePrimitives,
    }).XAxisValue;
    const xDataPointMaxValue = maxBy((ldp_3) => ldp_3.XAxisValue, dataPoints, {
        Compare: comparePrimitives,
    }).XAxisValue;
    const xAxisLeft = yAxisWidth | 0;
    const xAxisWidth = (width - xAxisLeft) | 0;
    return [props.LogarithmicX ? (logBase = (xDataPointMaxValue - xDataPointMinValue), (timeInSeconds) => {
        const valueAdjustedForMinValue = timeInSeconds - xDataPointMinValue;
        return round((xAxisWidth * ((valueAdjustedForMinValue <= 0) ? 0 : (Math.log(valueAdjustedForMinValue) / Math.log(logBase)))) + xAxisLeft);
    }) : (range = (xDataPointMaxValue - xDataPointMinValue), (timeInSeconds_1) => {
        const valueAdjustedForMinValue_1 = timeInSeconds_1 - xDataPointMinValue;
        return round((xAxisWidth * ((valueAdjustedForMinValue_1 <= 0) ? 0 : (valueAdjustedForMinValue_1 / range))) + xAxisLeft);
    }), (value_1) => ((heightAllowingForLegend - (percentage(value_1) * heightAllowingForLegend)) + (defaultTextHeight / 2)), yAxisMinValue, yAxisMaxValue, xDataPointMinValue, xDataPointMaxValue];
}

export function renderXAxis(calcX, calcY, xMinValue, xMaxValue, yMinValue, yMaxValue, width, height, props) {
    let valueOutput;
    const matchValue = props.XAxisValueTransformer;
    valueOutput = ((matchValue.tag === 1) ? matchValue.fields[0] : ((v) => toText(printf("%.0f"))(v)));
    if (props.ShowXAxis) {
        return [react.createElement("line", {
            x1: calcX(xMinValue),
            y1: calcY(yMinValue),
            x2: calcX(xMaxValue),
            y2: calcY(yMinValue),
            stroke: props.LegendTextColor,
        }), react.createElement("text", {
            x: calcX(xMinValue),
            y: calcY(yMinValue) + xAxisSpacing,
            fill: props.LegendTextColor,
            textAnchor: "start",
            dominantBaseline: "hanging",
        }, valueOutput(xMinValue)), react.createElement("text", {
            x: calcX(xMaxValue),
            y: calcY(yMinValue) + xAxisSpacing,
            fill: props.LegendTextColor,
            textAnchor: "end",
            dominantBaseline: "hanging",
        }, valueOutput(xMaxValue))];
    }
    else {
        return new Array(0);
    }
}

export function renderYAxis(calcX, calcY, xMinValue, xMaxValue, yMinValue, yMaxValue, width, height, props) {
    let valueOutput;
    const matchValue = props.YAxisValueTransformer;
    valueOutput = ((matchValue.tag === 1) ? matchValue.fields[0] : ((v) => toText(printf("%.0f"))(v)));
    if (props.ShowYAxis) {
        return ofArray([react.createElement("line", {
            x1: calcX(xMinValue),
            y1: calcY(yMaxValue),
            x2: calcX(xMinValue),
            y2: calcY(yMinValue),
            stroke: props.LegendTextColor,
        }), react.createElement("text", {
            x: calcX(xMinValue) - xAxisSpacing,
            y: height - defaultTextHeight,
            fill: props.LegendTextColor,
            textAnchor: "end",
            dominantBaseline: "bottom",
        }, valueOutput(yMinValue)), react.createElement("text", {
            x: calcX(xMinValue) - xAxisSpacing,
            y: calcY(yMaxValue) - xAxisSpacing,
            fill: props.LegendTextColor,
            textAnchor: "end",
            dominantBaseline: "hanging",
        }, valueOutput(yMaxValue)), react.createElement("text", {
            x: calcX(xMinValue) - xAxisSpacing,
            y: calcY(yMinValue + ((yMaxValue - yMinValue) / 2)),
            fill: props.LegendTextColor,
            textAnchor: "end",
            dominantBaseline: "central",
        }, valueOutput(yMinValue + ((yMaxValue - yMinValue) / 2)))]);
    }
    else {
        return empty();
    }
}

export class ScatterGraphDataPointPopover extends Record {
    constructor(Series, SeriesIndex, Color, DataPoint, X, Y) {
        super();
        this.Series = Series;
        this.SeriesIndex = (SeriesIndex | 0);
        this.Color = Color;
        this.DataPoint = DataPoint;
        this.X = X;
        this.Y = Y;
    }
}

export function ScatterGraphDataPointPopover$reflection() {
    return record_type("ScatterGraph.ScatterGraphDataPointPopover", [], ScatterGraphDataPointPopover, () => [["Series", ScatterGraphSeries$reflection()], ["SeriesIndex", int32_type], ["Color", string_type], ["DataPoint", ScatterGraphDataPoint$reflection()], ["X", float64_type], ["Y", float64_type]]);
}

function renderer(width, height, showDataPoint, hideDataPoint, props) {
    let matchValue, matchValue_1;
    const viewBoxString = toText(printf("0 0 %d %d"))(width)(height);
    const patternInput = calculateConstraints(width, height, props);
    const yMinValue = patternInput[2];
    const yMaxValue = patternInput[3];
    const xMinValue = patternInput[4];
    const xMaxValue = patternInput[5];
    const calcY = patternInput[1];
    const calcX = patternInput[0];
    const normalisedDistance = (x1, y1, x2, y2) => {
        const py1 = (y1 - yMinValue) / (yMaxValue - yMinValue);
        const py2 = (y2 - yMinValue) / (yMaxValue - yMinValue);
        return Math.sqrt(Math.pow(((x2 - xMinValue) / (xMaxValue - xMinValue)) - ((x1 - xMinValue) / (xMaxValue - xMinValue)), 2) + Math.pow(py2 - py1, 2));
    };
    const yAxis = renderYAxis(calcX, calcY, xMinValue, xMaxValue, yMinValue, yMaxValue, width, height, props);
    const xAxis = renderXAxis(calcX, calcY, xMinValue, xMaxValue, yMinValue, yMaxValue, width, height, props);
    const allDataPoints = Array.from(collect((s) => s.DataPoints, props.Series));
    const averageX = (matchValue = props.XAxisAverageTransformer, (matchValue == null) ? ((x_2) => x_2) : matchValue)(averageBy((dp) => dp.XAxisValue, allDataPoints, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
        DivideByInt: (x, i) => (x / i),
    }));
    const averageY = (matchValue_1 = props.YAxisAverageTransformer, (matchValue_1 == null) ? ((x_5) => x_5) : matchValue_1)(averageBy((dp_1) => dp_1.YAxisValue, allDataPoints, {
        GetZero: () => 0,
        Add: (x_4, y_1) => (x_4 + y_1),
        DivideByInt: (x_3, i_1) => (x_3 / i_1),
    }));
    const maxDistanceFromAverage = max(map((dp_2) => normalisedDistance(dp_2.XAxisValue, dp_2.YAxisValue, averageX, averageY), allDataPoints), {
        Compare: comparePrimitives,
    });
    let valueOutput;
    const matchValue_2 = props.XAxisValueTransformer;
    valueOutput = ((matchValue_2.tag === 1) ? matchValue_2.fields[0] : ((v) => toText(printf("%.0f"))(v)));
    const averageLines = ofArray([react.createElement("line", {
        x1: calcX(averageX),
        y1: calcY(yMinValue),
        x2: calcX(averageX),
        y2: calcY(yMaxValue),
        stroke: props.LegendTextColor,
    }), react.createElement("text", {
        x: calcX(averageX),
        y: calcY(yMinValue) + xAxisSpacing,
        fill: props.LegendTextColor,
        textAnchor: "middle",
        dominantBaseline: "hanging",
    }, valueOutput(averageX)), react.createElement("line", {
        x1: calcX(xMinValue),
        y1: calcY(averageY),
        x2: calcX(xMaxValue),
        y2: calcY(averageY),
        stroke: props.LegendTextColor,
    }), react.createElement("text", {
        x: calcX(0) - xAxisSpacing,
        y: calcY(averageY),
        fill: props.LegendTextColor,
        textAnchor: "end",
        dominantBaseline: "middle",
    }, valueOutput(averageY))]);
    const createCircles = (thisX, thisY, theSeries, seriesIndex, dataPoint) => {
        const percentDistanceFromAverage = normalisedDistance(dataPoint.XAxisValue, dataPoint.YAxisValue, averageX, averageY) / maxDistanceFromAverage;
        let color;
        const matchValue_3 = theSeries.Color;
        color = ((matchValue_3.tag === 1) ? matchValue_3.fields[0](theSeries, seriesIndex, dataPoint, percentDistanceFromAverage) : matchValue_3.fields[0]);
        return [react.createElement("circle", {
            cx: thisX,
            cy: thisY,
            r: dataPointRadius,
            stroke: color,
            fill: color,
        }), react.createElement("circle", {
            className: "cursor-pointer",
            cx: thisX,
            cy: thisY,
            r: clickableDataPointRadius,
            stroke: "rgba(0,0,0,0)",
            fill: "rgba(0,0,0,0)",
            onClick: (ev) => {
                ev.stopPropagation();
                const targetElement = ev.target;
                showDataPoint(new ScatterGraphDataPointPopover(theSeries, seriesIndex, color, dataPoint, ev.clientX, ev.clientY));
            },
        })];
    };
    return react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        className: "text-xs",
        onClick: (_arg1) => {
            hideDataPoint();
        },
    }, ...append(xAxis, append(yAxis, append(Array.from(concat(mapIndexed((seriesIndex_1, theSeries_1) => {
        let arg;
        if (isEmpty(theSeries_1.DataPoints)) {
            return empty_1();
        }
        else {
            const initialX = calcX(theSeries_1.DataPoints[0].XAxisValue);
            const initialY = calcY(theSeries_1.DataPoints[0].YAxisValue);
            const patternInput_1 = createCircles(initialX, initialY, theSeries_1, seriesIndex_1, theSeries_1.DataPoints[0]);
            return (arg = [initialX, initialY, [patternInput_1[0], patternInput_1[1]]], fold((tupledArg, dataPoint_1) => {
                const thisX_1 = calcX(dataPoint_1.XAxisValue);
                const thisY_1 = calcY(dataPoint_1.YAxisValue);
                const patternInput_2 = createCircles(thisX_1, thisY_1, theSeries_1, seriesIndex_1, dataPoint_1);
                return [thisX_1, thisY_1, append(tupledArg[2], [patternInput_2[0], patternInput_2[1]])];
            }, [arg[0], arg[1], arg[2]], theSeries_1.DataPoints))[2];
        }
    }, Array.from(props.Series)))), append(averageLines, [])))));
}

export function renderPopOver(props, container, dataPointPopover) {
    if (dataPointPopover != null) {
        const popOver = dataPointPopover;
        const boundingRect = container.getBoundingClientRect();
        const x = popOver.X - boundingRect.left;
        const y = popOver.Y - boundingRect.top;
        const xProp = (x > (boundingRect.width / 2)) ? (new CSSProp(298, boundingRect.width - x)) : (new CSSProp(208, x));
        const propertyValuePairs = props.PopoverRows(popOver.Series, popOver.SeriesIndex, popOver.DataPoint);
        return react.createElement("div", {
            className: "absolute z-40 rounded border shadow bg-white",
            style: keyValueList([xProp, new CSSProp(365, y)], 1),
        }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
            className: "py-1 px-2",
        }, ...map((tupledArg) => react.createElement("div", {
            className: "flex flex-row justify-between w-full items-center",
        }, tinyLabel(tupledArg[0]), react.createElement("span", {
            className: tinyContentClass + " ml-4",
            style: {
                color: popOver.Color,
            },
        }, react.createElement("span", {}, tupledArg[1]))), propertyValuePairs))), delay(() => {
            const matchValue_1 = props.PopoverAnnotation;
            return (curry(3, matchValue_1) == null) ? singleton(react.createElement(react.Fragment, {})) : singleton(matchValue_1(popOver.Color, popOver.SeriesIndex, popOver.DataPoint));
        })))));
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

export const scatter = FunctionComponent_Of_Z5A158BBF((props) => {
    const containerWidth = react.useState(0);
    const containerHeight = react.useState(0);
    const dataPointPopover = react.useState(void 0);
    const containerRef = react.useRef(void 0);
    react.useEffect(() => {
    const disp = (() => {
        let timeoutId = void 0;
        const setWidth = () => {
            const matchValue = containerRef.current;
            if (matchValue == null) {
            }
            else {
                const element = matchValue;
                const width = (~(~element.clientWidth)) | 0;
                const height = (~(~element.clientHeight)) | 0;
                dataPointPopover[1]((void 0));
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout((_arg1) => {
                    timeoutId = (void 0);
                    containerWidth[1](((s) => width));
                    containerHeight[1](((s_1) => height));
                }, 150, empty());
            }
        };
        setWidth();
        return attachWindowEventWithDisposer((_arg2) => {
            setWidth();
        }, window, "resize", () => {
            if (timeoutId == null) {
            }
            else {
                window.clearTimeout(timeoutId);
            }
        });
    })();
    return () => disp.Dispose();
    }, []);
    let patternInput;
    const matchValue_1 = containerRef.current;
    if (matchValue_1 == null) {
        patternInput = [react.createElement(react.Fragment, {}), react.createElement(react.Fragment, {})];
    }
    else {
        const container = matchValue_1;
        patternInput = [isEmpty(collect((s_2) => s_2.DataPoints, props.Series)) ? react.createElement(react.Fragment, {}) : renderer(containerWidth[0], containerHeight[0], (arg) => {
            dataPointPopover[1](arg);
        }, () => {
            dataPointPopover[1]((void 0));
        }, props), renderPopOver(props, container, dataPointPopover[0])];
    }
    return react.createElement("div", {
        className: "h-full relative",
        ref: containerRef,
    }, patternInput[1], patternInput[0]);
}, void 0, uncurry(2, void 0), void 0, "scatter", "/home/runner/work/strengthPlus/strengthPlus/client/src/ScatterGraph.fs", 353);

