import * as react from "react";
import { errorPanel } from "../Notifications.fs.js";
import { hours, minutes as minutes_1, seconds as seconds_2, fromSeconds } from "../.fable/fable-library.3.0.0/TimeSpan.js";
import { printf, toText } from "../.fable/fable-library.3.0.0/String.js";
import { distanceUnits, distance as distance_1 } from "../Units.fs.js";
import { Record } from "../.fable/fable-library.3.0.0/Types.js";
import { bool_type, array_type, string_type, record_type, option_type, int32_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { FSharpResult$2 } from "../.fable/fable-library.3.0.0/Choice.js";
import { Block__get_Length, Programme, ProgrammeId$reflection, BlockId_Create, Block, BlockId$reflection, Length__get_Value, IntervalType__get_Max, IntervalType__get_Min, IntervalId_Create, Interval, Length as Length_4, IntervalType, IntervalId$reflection, MinMax$1 } from "../../../shared/Shared.PowerProgramme.fs.js";
import { defaultArg, bind } from "../.fable/fable-library.3.0.0/Option.js";
import { RollingArray_create, RollingArray_removeHead, RollingArray_push, elevate } from "../../../shared/Shared.Collections.fs.js";
import { average, append, map } from "../.fable/fable-library.3.0.0/Array.js";
import { mapIndexed, max as max_2, empty, append as append_1, reverse, map as map_1, fold, rangeNumber, singleton, collect, delay, sumBy } from "../.fable/fable-library.3.0.0/Seq.js";
import { comparePrimitives, max as max_1, uncurry } from "../.fable/fable-library.3.0.0/Util.js";
import { tinyContentClass } from "../Typography.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { empty as empty_1 } from "../.fable/fable-library.3.0.0/List.js";
import { attachWindowEvent } from "../BrowserHelpers.fs.js";

export const warning = react.createElement("div", {
    className: "mb-6",
}, errorPanel("Feature in beta", "The workout designer is currently in beta - I\u0027m riding designed power programmes myself but would super appreciate feedback in the event of any problems."));

export function minutes(value) {
    return value * 60;
}

export function secondsAsDurationString(seconds) {
    const ts = fromSeconds(seconds);
    const arg30 = seconds_2(ts) | 0;
    const arg20 = minutes_1(ts) | 0;
    const arg10 = hours(ts) | 0;
    return toText(printf("%02d:%02d:%02d"))(arg10)(arg20)(arg30);
}

export function secondsAsFriendlyDurationString(seconds) {
    const ts = fromSeconds(seconds);
    if (((hours(ts) === 0) ? (minutes_1(ts) === 0) : false) ? (seconds_2(ts) !== 0) : false) {
        const arg10 = seconds_2(ts) | 0;
        return toText(printf("%d seconds"))(arg10);
    }
    else if (((hours(ts) === 0) ? (minutes_1(ts) !== 0) : false) ? (seconds_2(ts) === 0) : false) {
        const arg10_1 = minutes_1(ts) | 0;
        return toText(printf("%d minutes"))(arg10_1);
    }
    else if (((hours(ts) !== 0) ? (minutes_1(ts) === 0) : false) ? (seconds_2(ts) === 0) : false) {
        const arg10_2 = hours(ts) | 0;
        return toText(printf("%d hours"))(arg10_2);
    }
    else {
        const arg30 = seconds_2(ts) | 0;
        const arg20 = minutes_1(ts) | 0;
        const arg10_3 = hours(ts) | 0;
        return toText(printf("%02d:%02d:%02d"))(arg10_3)(arg20)(arg30);
    }
}

export function lengthAsString(context, length) {
    if (length.tag === 1) {
        const distance = distance_1(context, length.fields[0]);
        const arg20 = distanceUnits(context);
        return toText(printf("%s %s"))(distance)(arg20);
    }
    else {
        return secondsAsDurationString(length.fields[0]);
    }
}

export function lengthAsFriendlyString(context, length) {
    if (length.tag === 1) {
        const distance = distance_1(context, length.fields[0]);
        const arg20 = distanceUnits(context);
        return toText(printf("%s %s"))(distance)(arg20);
    }
    else {
        return secondsAsFriendlyDurationString(length.fields[0]);
    }
}

export function calculateIntensityFactor(normalisdPower, ftp) {
    return normalisdPower / ftp;
}

export class Types_EditableMinMax extends Record {
    constructor(Min, Max) {
        super();
        this.Min = Min;
        this.Max = Max;
    }
}

export function Types_EditableMinMax$reflection() {
    return record_type("PowerProgramme.Common.Types.EditableMinMax", [], Types_EditableMinMax, () => [["Min", option_type(int32_type)], ["Max", option_type(int32_type)]]);
}

export function Types_EditableMinMax__get_Mid(x) {
    const matchValue = [x.Min, x.Max];
    let pattern_matching_result, max, min;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            max = matchValue[1];
            min = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return min + (~(~((max - min) / 2)));
        }
        case 1: {
            return void 0;
        }
    }
}

export function Types_EditableMinMax__ToMinMax(x) {
    const matchValue = [x.Min, x.Max];
    let pattern_matching_result, max, min;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            max = matchValue[1];
            min = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, new MinMax$1(min, max));
        }
        case 1: {
            return new FSharpResult$2(1, "Invalid min / max");
        }
    }
}

export function Types_EditableMinMax_FromMinMax_Z38099DC1(minMax) {
    return new Types_EditableMinMax(minMax.Min, minMax.Max);
}

export class Types_EditableInterval extends Record {
    constructor(Name, IntervalId, Power, Length, LengthInSeconds, Cadence) {
        super();
        this.Name = Name;
        this.IntervalId = IntervalId;
        this.Power = Power;
        this.Length = Length;
        this.LengthInSeconds = (LengthInSeconds | 0);
        this.Cadence = Cadence;
    }
}

export function Types_EditableInterval$reflection() {
    return record_type("PowerProgramme.Common.Types.EditableInterval", [], Types_EditableInterval, () => [["Name", option_type(string_type)], ["IntervalId", IntervalId$reflection()], ["Power", Types_EditableMinMax$reflection()], ["Length", option_type(string_type)], ["LengthInSeconds", int32_type], ["Cadence", option_type(Types_EditableMinMax$reflection())]]);
}

export function Types_EditableInterval__ToInterval(x) {
    let matchValue, matchValue_1;
    const matchValue_2 = [Types_EditableMinMax__ToMinMax(x.Power), (matchValue = x.Cadence, (matchValue == null) ? (new FSharpResult$2(0, void 0)) : (matchValue_1 = Types_EditableMinMax__ToMinMax(matchValue), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, matchValue_1.fields[0])) : (new FSharpResult$2(0, matchValue_1.fields[0]))))];
    let pattern_matching_result, c_1, pv;
    const copyOfStruct = matchValue_2[0];
    if (copyOfStruct.tag === 0) {
        const copyOfStruct_1 = matchValue_2[1];
        if (copyOfStruct_1.tag === 0) {
            pattern_matching_result = 0;
            c_1 = copyOfStruct_1.fields[0];
            pv = copyOfStruct.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, new Interval(x.IntervalId, x.Name, new IntervalType(0, pv), new Length_4(0, x.LengthInSeconds), c_1));
        }
        case 1: {
            return new FSharpResult$2(1, "Unable to convert interval");
        }
    }
}

export function Types_EditableInterval_LengthFromSeconds_Z524259A4(seconds) {
    return secondsAsDurationString(seconds);
}

export function Types_EditableInterval_get_DefaultInterval() {
    return new Types_EditableInterval(void 0, IntervalId_Create(), new Types_EditableMinMax(80, 100), Types_EditableInterval_LengthFromSeconds_Z524259A4(60), 60, void 0);
}

export function Types_EditableInterval_FromInterval_Z27DC1530(i) {
    return new Types_EditableInterval(i.Name, i.IntervalId, new Types_EditableMinMax(IntervalType__get_Min(i.Power), IntervalType__get_Max(i.Power)), Types_EditableInterval_LengthFromSeconds_Z524259A4(Length__get_Value(i.Length)), Length__get_Value(i.Length), bind(Types_EditableMinMax_FromMinMax_Z38099DC1, i.Cadence));
}

export class Types_EditableBlock extends Record {
    constructor(BlockId, Name, Intervals, DoesRepeat, Repeats) {
        super();
        this.BlockId = BlockId;
        this.Name = Name;
        this.Intervals = Intervals;
        this.DoesRepeat = DoesRepeat;
        this.Repeats = Repeats;
    }
}

export function Types_EditableBlock$reflection() {
    return record_type("PowerProgramme.Common.Types.EditableBlock", [], Types_EditableBlock, () => [["BlockId", BlockId$reflection()], ["Name", option_type(string_type)], ["Intervals", array_type(Types_EditableInterval$reflection())], ["DoesRepeat", bool_type], ["Repeats", option_type(int32_type)]]);
}

export function Types_EditableBlock__ToBlock(x) {
    let matchValue;
    const matchValue_1 = [elevate(map(Types_EditableInterval__ToInterval, x.Intervals)), (matchValue = [x.DoesRepeat, x.Repeats], matchValue[0] ? ((matchValue[1] != null) ? (new FSharpResult$2(0, matchValue[1])) : (new FSharpResult$2(1, "Cannot convert repeats"))) : (new FSharpResult$2(0, void 0)))];
    let pattern_matching_result, i_1, r_1;
    const copyOfStruct = matchValue_1[0];
    if (copyOfStruct.tag === 0) {
        const copyOfStruct_1 = matchValue_1[1];
        if (copyOfStruct_1.tag === 0) {
            pattern_matching_result = 0;
            i_1 = copyOfStruct.fields[0];
            r_1 = copyOfStruct_1.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, new Block(x.BlockId, x.Name, Array.from(i_1), r_1));
        }
        case 1: {
            return new FSharpResult$2(1, "Unable to convert block");
        }
    }
}

export function Types_EditableBlock__get_Length(x) {
    return sumBy((i) => i.LengthInSeconds, x.Intervals, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) * (x.DoesRepeat ? defaultArg(x.Repeats, 1) : 1);
}

export function Types_EditableBlock_CreateSingle_ZA1C41F9(name, duration, minPower, maxPower) {
    let Power, Length;
    return new Types_EditableBlock(BlockId_Create(), name, [(Power = (new Types_EditableMinMax(minPower, maxPower)), (Length = Types_EditableInterval_LengthFromSeconds_Z524259A4(duration), new Types_EditableInterval(void 0, IntervalId_Create(), Power, Length, duration, void 0)))], false, 1);
}

export function Types_EditableBlock_CreateRepeats_49AC4E87(name, repeats, hardDuration, easyDuration, hardMinPower, hardMaxPower, easyMinPower, easyMaxPower) {
    let Power, Length, Power_1, Length_1;
    return new Types_EditableBlock(BlockId_Create(), name, [(Power = (new Types_EditableMinMax(hardMinPower, hardMaxPower)), (Length = Types_EditableInterval_LengthFromSeconds_Z524259A4(hardDuration), new Types_EditableInterval("Hard", IntervalId_Create(), Power, Length, hardDuration, void 0))), (Power_1 = (new Types_EditableMinMax(easyMinPower, easyMaxPower)), (Length_1 = Types_EditableInterval_LengthFromSeconds_Z524259A4(easyDuration), new Types_EditableInterval("Easy", IntervalId_Create(), Power_1, Length_1, easyDuration, void 0)))], true, repeats);
}

export function Types_EditableBlock_FromBlock_6E39F6E6(b) {
    return new Types_EditableBlock(b.BlockId, b.Name, map(Types_EditableInterval_FromInterval_Z27DC1530, b.Intervals), b.Repeats != null, b.Repeats);
}

export class Types_EditableProgramme extends Record {
    constructor(Id, Name, Blocks) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Blocks = Blocks;
    }
}

export function Types_EditableProgramme$reflection() {
    return record_type("PowerProgramme.Common.Types.EditableProgramme", [], Types_EditableProgramme, () => [["Id", ProgrammeId$reflection()], ["Name", option_type(string_type)], ["Blocks", array_type(Types_EditableBlock$reflection())]]);
}

export function Types_EditableProgramme__CalculateNormalisedPower_Z524259A4(x, ftp) {
    const power = delay(() => collect((block) => collect((interval) => collect((matchValue) => singleton(~(~((defaultArg(Types_EditableMinMax__get_Mid(interval.Power), 0) * ftp) / 100))), rangeNumber(1, 1, interval.LengthInSeconds)), block.Intervals), x.Blocks));
    const rollingPow4Averages = fold(uncurry(2, (tupledArg) => {
        const previousRollingArray = tupledArg[0];
        const previousPow4Averages = tupledArg[1];
        return (currentDataPoint) => {
            const rollingArrayWithNewDataPoint = RollingArray_push(currentDataPoint, previousRollingArray);
            if (rollingArrayWithNewDataPoint.count === 31) {
                const trimmedRollingArray = RollingArray_removeHead(previousRollingArray);
                return [trimmedRollingArray, append(new Float64Array([Math.pow(trimmedRollingArray.total / trimmedRollingArray.count, 4)]), previousPow4Averages, Float64Array)];
            }
            else {
                return [rollingArrayWithNewDataPoint, previousPow4Averages];
            }
        };
    }), [RollingArray_create(30 + 10), new Float64Array(0)], power)[1];
    if (rollingPow4Averages.length === 0) {
        return void 0;
    }
    else {
        return ~(~Math.pow(average(rollingPow4Averages, {
            GetZero: () => 0,
            Add: (x_2, y) => (x_2 + y),
            DivideByInt: (x_1, i) => (x_1 / i),
        }), 0.25));
    }
}

export function Types_EditableProgramme__CalculateTrainingStress_Z524259A4(x, ftp) {
    const timeInSeconds = Types_EditableProgramme__get_Length(x) | 0;
    const normalisedPower = defaultArg(Types_EditableProgramme__CalculateNormalisedPower_Z524259A4(x, ftp), 0) | 0;
    return (~(~(((timeInSeconds * normalisedPower) * calculateIntensityFactor(normalisedPower, ftp)) / (ftp * 36)))) | 0;
}

export function Types_EditableProgramme__ToProgramme(x) {
    const matchValue = [x.Name, elevate(map_1(Types_EditableBlock__ToBlock, x.Blocks))];
    let pattern_matching_result, blocks, name;
    if (matchValue[0] != null) {
        const copyOfStruct = matchValue[1];
        if (copyOfStruct.tag === 0) {
            pattern_matching_result = 0;
            blocks = copyOfStruct.fields[0];
            name = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpResult$2(0, new Programme(x.Id, name, void 0, Array.from(blocks), Types_EditableProgramme__CalculateTrainingStress_Z524259A4(x, 100)));
        }
        case 1: {
            return new FSharpResult$2(1, "Unable to convert to blocks");
        }
    }
}

export function Types_EditableProgramme__get_Length(x) {
    return sumBy(Types_EditableBlock__get_Length, x.Blocks, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    });
}

export function Types_EditableProgramme_FromProgramme_41BF7BF3(p) {
    return new Types_EditableProgramme(p.Id, p.Name, map(Types_EditableBlock_FromBlock_6E39F6E6, p.Blocks));
}

export function calculateNormalisedPower(programme, ftp) {
    return Types_EditableProgramme__CalculateNormalisedPower_Z524259A4(programme, ftp);
}

export function calculateTrainingStress(programme, ftp) {
    return Types_EditableProgramme__CalculateTrainingStress_Z524259A4(programme, ftp);
}

const rampUp = new Types_EditableBlock(BlockId_Create(), "Ramp up", [(() => {
    const Power = new Types_EditableMinMax(55, 65);
    const Length = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power, Length, LengthInSeconds, void 0);
})(), (() => {
    const Power_1 = new Types_EditableMinMax(65, 75);
    const Length_1 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_1 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_1, Length_1, LengthInSeconds_1, void 0);
})(), (() => {
    const Power_2 = new Types_EditableMinMax(75, 85);
    const Length_2 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_2 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_2, Length_2, LengthInSeconds_2, void 0);
})(), (() => {
    const Power_3 = new Types_EditableMinMax(85, 95);
    const Length_3 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_3 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_3, Length_3, LengthInSeconds_3, void 0);
})()], false, 1);

const rampDown = new Types_EditableBlock(BlockId_Create(), "Ramp down", [(() => {
    const Power = new Types_EditableMinMax(85, 95);
    const Length = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power, Length, LengthInSeconds, void 0);
})(), (() => {
    const Power_1 = new Types_EditableMinMax(75, 85);
    const Length_1 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_1 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_1, Length_1, LengthInSeconds_1, void 0);
})(), (() => {
    const Power_2 = new Types_EditableMinMax(65, 75);
    const Length_2 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_2 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_2, Length_2, LengthInSeconds_2, void 0);
})(), (() => {
    const Power_3 = new Types_EditableMinMax(55, 65);
    const Length_3 = Types_EditableInterval_LengthFromSeconds_Z524259A4(minutes(2));
    const LengthInSeconds_3 = minutes(2) | 0;
    return new Types_EditableInterval(void 0, IntervalId_Create(), Power_3, Length_3, LengthInSeconds_3, void 0);
})()], false, 1);

export const palette = [Types_EditableBlock_CreateSingle_ZA1C41F9("Warmup", minutes(20), 40, 50), Types_EditableBlock_CreateSingle_ZA1C41F9("Active", minutes(10), 70, 80), Types_EditableBlock_CreateSingle_ZA1C41F9("Sweet Spot", minutes(20), 85, 95), rampUp, rampDown, Types_EditableBlock_CreateRepeats_49AC4E87("Over / Unders", 5, minutes(2), minutes(2), 115, 135, 75, 85), Types_EditableBlock_CreateRepeats_49AC4E87("Bursts", 8, minutes(1), minutes(1), 130, 140, 35, 45), Types_EditableBlock_CreateRepeats_49AC4E87("Sprints", 8, 10, 50, 190, 210, 35, 45), Types_EditableBlock_CreateSingle_ZA1C41F9("Recovery", minutes(5), 35, 45)];

export function colorFromPercentage(percentage) {
    if (percentage <= 70) {
        return "#48BB78";
    }
    else if (percentage <= 80) {
        return "#F6E05E";
    }
    else {
        return "#E53E3E";
    }
}

export function renderBlock(showName, height, pixelsPerSecond, maxPercentage, optionalUpz, isHighlighted, isValidOption, block) {
    let arg10_1, matchValue_1;
    const width = (pixelsPerSecond * Block__get_Length(block)) + (block.Intervals.length * 0);
    const viewBoxString = toText(printf("0 0 %.0f %d"))(width)(height);
    const repeatedIntervals = reverse(collect((_arg1) => block.Intervals, rangeNumber(0, 1, defaultArg(block.Repeats, 1) - 1)));
    const patternInput = fold(uncurry(2, (tupledArg) => {
        const xoffset = tupledArg[0];
        return (current) => {
            const elementWidth = pixelsPerSecond * Length__get_Value(current.Length);
            const elementHeight = (IntervalType__get_Max(current.Power) / maxPercentage) * height;
            const color = colorFromPercentage(IntervalType__get_Max(current.Power));
            return [xoffset + elementWidth, append_1(tupledArg[1], [(Length__get_Value(current.Length) > 0) ? react.createElement("rect", {
                x: xoffset,
                y: 0,
                width: elementWidth,
                height: elementHeight,
                stroke: "white",
                fill: color,
            }) : react.createElement(react.Fragment, {})])];
        };
    }), [0, empty()], repeatedIntervals);
    const midX = width / 2;
    const midY = (~(~(height / 2))) | 0;
    const visibleValidationState = (isValidOption != null) ? (isValidOption ? react.createElement(react.Fragment, {}) : react.createElement("g", {
        transform: (arg10_1 = (width - 36), toText(printf("translate(%.0f %d)"))(arg10_1)(12)),
    }, react.createElement("circle", {
        fill: "white",
        cx: 12,
        cy: 12,
        r: 12,
    }), react.createElement("line", {
        stroke: "red",
        strokeWidth: 2,
        x1: 8,
        y1: 8,
        x2: 16,
        y2: 16,
    }), react.createElement("line", {
        stroke: "red",
        strokeWidth: 2,
        x1: 8,
        y1: 16,
        x2: 16,
        y2: 8,
    }))) : react.createElement(react.Fragment, {});
    const renderedBlock = react.createElement("div", {
        className: "bg-opacity-0",
        style: {
            height: height,
            width: width,
        },
    }, react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        className: "bg-opacity-0",
    }, react.createElement("g", {
        transform: toText(printf("rotate(180 %.0f %d)"))(midX)(midY),
    }, ...patternInput[1]), isHighlighted ? react.createElement("rect", {
        x: 0,
        y: 0,
        width: width,
        height: height,
        fill: "transparent",
        stroke: "#63B3ED",
        strokeWidth: 8,
    }) : react.createElement(react.Fragment, {}), visibleValidationState));
    if (showName) {
        return react.createElement("div", {
            className: "flex flex-col items-center",
        }, renderedBlock, react.createElement("div", {
            className: tinyContentClass,
        }, (matchValue_1 = block.Name, (matchValue_1 == null) ? " " : matchValue_1)));
    }
    else {
        return renderedBlock;
    }
}

export function renderBlockFixedWidth(showName, width, height, maxPercentage, upz, isHighlighted, isValidOption, block) {
    return renderBlock(showName, height, width / Block__get_Length(block), maxPercentage, upz, isHighlighted, isValidOption, block);
}

export const visualTimeline = FunctionComponent_Of_Z5A158BBF((props) => {
    let totalLength, pixelsPerSecond, maxPercentage;
    const blocks = props.Blocks;
    const containerWidth = react.useState(0);
    const containerRef = react.useRef(void 0);
    react.useEffect(() => {
    const disp = (() => {
        let timeoutId = void 0;
        const setWidth = () => {
            const matchValue = containerRef.current;
            if (matchValue == null) {
            }
            else {
                const width = (~(~matchValue.clientWidth)) | 0;
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout((_arg1) => {
                    containerWidth[1](((s) => width));
                }, 30, empty_1());
            }
        };
        setWidth();
        return attachWindowEvent((_arg2) => {
            setWidth();
        }, window, "resize");
    })();
    return () => disp.Dispose();
    }, []);
    return react.createElement("div", {
        className: "flex flex-row justify-start",
        ref: containerRef,
    }, ...(((containerWidth[0]) > 0) ? (totalLength = sumBy(Block__get_Length, blocks, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }), (totalLength > 0) ? (pixelsPerSecond = ((containerWidth[0]) / totalLength), (maxPercentage = (max_1(comparePrimitives, max_2(collect((b_1) => map_1((i) => IntervalType__get_Max(i.Power), b_1.Intervals), blocks), {
        Compare: comparePrimitives,
    }), 100) | 0), Array.from(mapIndexed((i_1, block) => renderBlock(false, props.Height, pixelsPerSecond, maxPercentage, void 0, false, void 0, block), blocks)))) : (new Array(0))) : (new Array(0))));
}, void 0, uncurry(2, void 0), void 0, "visualTimeline", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Common.fs", 324);

