import { GetLookupsRes, Model_get_Empty, Msg, Model } from "./AppTypes.fs.js";
import { update as update_1, init as init_1 } from "./Metrics/Dispatcher/State.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result, Cmd_none, Cmd_map } from "./.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_2, init as init_2 } from "./Welcome/Dispatcher/State.fs.js";
import { update as update_3, init as init_3 } from "./Help/Dispatcher/State.fs.js";
import { update as update_4, init as init_4 } from "./Analysis/Dispatcher/State.fs.js";
import { update as update_5, init as init_5 } from "./HeartRateZones/Dispatcher/State.fs.js";
import { update as update_6, init as init_6 } from "./PowerZones/Dispatcher/State.fs.js";
import { update as update_7, init as init_7 } from "./WeeklyTrainingLog/Dispatcher/State.fs.js";
import { update as update_8, init as init_8 } from "./CardioActivity/Dispatcher/State.fs.js";
import { update as update_9, init as init_9 } from "./Dashboard/Dispatcher/State.fs.js";
import { update as update_10, init as init_10 } from "./Programme/Dispatcher/State.fs.js";
import { update as update_12, init as init_11 } from "./PowerProgramme/Dispatcher/State.fs.js";
import { defaultArg, some } from "./.fable/fable-library.3.0.0/Option.js";
import { DashboardPage, WelcomePage, Page, modifyUrl } from "./Router.fs.js";
import { isNewUser } from "./Server.fs.js";
import { update as update_11 } from "./Modal/Manager.fs.js";
import { getLookups } from "./AppApi.fs.js";
import { Context as Context_1 } from "./Context.fs.js";
import { empty, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { filter, append } from "./.fable/fable-library.3.0.0/Seq.js";
import { cartItemFromSummary } from "./ComparisonCart/Manager.fs.js";
import { equalsSafe } from "./.fable/fable-library.3.0.0/Util.js";
import { append as append_1 } from "./.fable/fable-library.3.0.0/Array.js";
import { calculateAggregates } from "./Dashboard/Index/State.fs.js";
import { Model as Model_1 } from "./Dashboard/Index/Types.fs.js";
import { Model as Model_2 } from "./Dashboard/Dispatcher/Types.fs.js";

export function urlUpdate(result, model) {
    if (result != null) {
        const page = result;
        const model_1 = new Model(page, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart);
        switch (page.tag) {
            case 1: {
                const patternInput_1 = init_1(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, patternInput_1[0], model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_1) => (new Msg(1, arg0_1)), patternInput_1[1])];
            }
            case 3: {
                const patternInput_2 = init_2(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, patternInput_2[0], model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1])];
            }
            case 2: {
                const patternInput_3 = init_3(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, patternInput_3[0], model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_3) => (new Msg(2, arg0_3)), patternInput_3[1])];
            }
            case 4: {
                const patternInput_4 = init_4(page.fields[0], Array.from(model_1.ComparisonCart));
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, patternInput_4[0], model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_4) => (new Msg(4, arg0_4)), patternInput_4[1])];
            }
            case 5: {
                const patternInput_5 = init_5(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, patternInput_5[0], model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_5) => (new Msg(5, arg0_5)), patternInput_5[1])];
            }
            case 6: {
                const patternInput_6 = init_6(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, patternInput_6[0], model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_6) => (new Msg(6, arg0_6)), patternInput_6[1])];
            }
            case 7: {
                const patternInput_7 = init_7(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, patternInput_7[0], model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_7) => (new Msg(7, arg0_7)), patternInput_7[1])];
            }
            case 8: {
                const patternInput_8 = init_8(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, patternInput_8[0], model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_8) => (new Msg(8, arg0_8)), patternInput_8[1])];
            }
            case 9: {
                const patternInput_9 = init_9(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, patternInput_9[0], model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_9) => (new Msg(9, arg0_9)), patternInput_9[1])];
            }
            case 10: {
                const patternInput_10 = init_10(model_1.Context, page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, model_1.IntervalProgrammeDispatcher, model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, patternInput_10[0], model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0_10) => (new Msg(10, arg0_10)), patternInput_10[1])];
            }
            case 11: {
                return [model_1, Cmd_none()];
            }
            default: {
                const patternInput = init_11(page.fields[0]);
                return [new Model(model_1.CurrentPage, model_1.Context, patternInput[0], model_1.MetricsDispatcher, model_1.HelpDispatcher, model_1.WelcomeDispatcher, model_1.AnalysisDispatcher, model_1.HeartRateZonesDispatcher, model_1.PowerZonesDispatcher, model_1.WeeklyTrainingLogDispatcher, model_1.CardioActivityDispatcher, model_1.DashboardDispatcher, model_1.ProgrammeDispatcher, model_1.IsNavigationDropdownOpen, model_1.IsGlobalLoading, model_1.ModalManager, model_1.ComparisonCart), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
            }
        }
    }
    else {
        console.error(some("Error parsing url: " + window.location.href));
        return [model, modifyUrl(model.CurrentPage)];
    }
}

export function init(result) {
    let inputRecord;
    const startupPage = isNewUser() ? (new Page(3, new WelcomePage(0))) : defaultArg(result, new Page(9, new DashboardPage(0)));
    return [(inputRecord = Model_get_Empty(), new Model(startupPage, inputRecord.Context, inputRecord.IntervalProgrammeDispatcher, inputRecord.MetricsDispatcher, inputRecord.HelpDispatcher, inputRecord.WelcomeDispatcher, inputRecord.AnalysisDispatcher, inputRecord.HeartRateZonesDispatcher, inputRecord.PowerZonesDispatcher, inputRecord.WeeklyTrainingLogDispatcher, inputRecord.CardioActivityDispatcher, inputRecord.DashboardDispatcher, inputRecord.ProgrammeDispatcher, inputRecord.IsNavigationDropdownOpen, inputRecord.IsGlobalLoading, inputRecord.ModalManager, inputRecord.ComparisonCart)), Cmd_OfFunc_result(new Msg(13))];
}

function rootUpdate(msg, model) {
    let inputRecord;
    const matchValue = [msg, model];
    if (matchValue[0].tag === 1) {
        if (matchValue[1].MetricsDispatcher != null) {
            const extractedModel_1 = matchValue[1].MetricsDispatcher;
            const patternInput_1 = update_1(model.Context, matchValue[0].fields[0], extractedModel_1);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, patternInput_1[0], model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_1) => (new Msg(1, arg0_1)), patternInput_1[1])];
        }
        else {
            console.log(some("[App.State] Discarded Metrics dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].WelcomeDispatcher != null) {
            const extractedModel_2 = matchValue[1].WelcomeDispatcher;
            const patternInput_2 = update_2(model.Context, matchValue[0].fields[0], extractedModel_2);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, patternInput_2[0], model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_2) => (new Msg(3, arg0_2)), patternInput_2[1])];
        }
        else {
            console.log(some("[App.State] Discarded Welcome dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].HelpDispatcher != null) {
            const extractedModel_3 = matchValue[1].HelpDispatcher;
            const patternInput_3 = update_3(model.Context, matchValue[0].fields[0], extractedModel_3);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, patternInput_3[0], model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_3) => (new Msg(2, arg0_3)), patternInput_3[1])];
        }
        else {
            console.log(some("[App.State] Discarded Help dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].AnalysisDispatcher != null) {
            const extractedModel_4 = matchValue[1].AnalysisDispatcher;
            const patternInput_4 = update_4(model.Context, matchValue[0].fields[0], extractedModel_4);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, patternInput_4[0], model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_4) => (new Msg(4, arg0_4)), patternInput_4[1])];
        }
        else {
            console.log(some("[App.State] Discarded Analysis dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].HeartRateZonesDispatcher != null) {
            const extractedModel_5 = matchValue[1].HeartRateZonesDispatcher;
            const patternInput_5 = update_5(model.Context, matchValue[0].fields[0], extractedModel_5);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, patternInput_5[0], model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_5) => (new Msg(5, arg0_5)), patternInput_5[1])];
        }
        else {
            console.log(some("[App.State] Discarded HeartRateZones dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].PowerZonesDispatcher != null) {
            const extractedModel_6 = matchValue[1].PowerZonesDispatcher;
            const patternInput_6 = update_6(model.Context, matchValue[0].fields[0], extractedModel_6);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, patternInput_6[0], model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_6) => (new Msg(6, arg0_6)), patternInput_6[1])];
        }
        else {
            console.log(some("[App.State] Discarded PowerZones dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].WeeklyTrainingLogDispatcher != null) {
            const extractedModel_7 = matchValue[1].WeeklyTrainingLogDispatcher;
            const patternInput_7 = update_7(model.Context, matchValue[0].fields[0], extractedModel_7);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, patternInput_7[0], model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_7) => (new Msg(7, arg0_7)), patternInput_7[1])];
        }
        else {
            console.log(some("[App.State] Discarded WeeklyTrainingLog dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].CardioActivityDispatcher != null) {
            const extractedModel_8 = matchValue[1].CardioActivityDispatcher;
            const patternInput_8 = update_8(model.Context, matchValue[0].fields[0], extractedModel_8);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, patternInput_8[0], model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_8) => (new Msg(8, arg0_8)), patternInput_8[1])];
        }
        else {
            console.log(some("[App.State] Discarded CardioActivity dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 9) {
        if (matchValue[1].DashboardDispatcher != null) {
            const extractedModel_9 = matchValue[1].DashboardDispatcher;
            const patternInput_9 = update_9(model.Context, matchValue[0].fields[0], extractedModel_9);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, patternInput_9[0], model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_9) => (new Msg(9, arg0_9)), patternInput_9[1])];
        }
        else {
            console.log(some("[App.State] Discarded Dashboard dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 10) {
        if (matchValue[1].ProgrammeDispatcher != null) {
            const extractedModel_10 = matchValue[1].ProgrammeDispatcher;
            const patternInput_10 = update_10(model.Context, matchValue[0].fields[0], extractedModel_10);
            return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, patternInput_10[0], model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0_10) => (new Msg(10, arg0_10)), patternInput_10[1])];
        }
        else {
            console.log(some("[App.State] Discarded Programme dispatcher message"));
            return [model, Cmd_none()];
        }
    }
    else if (matchValue[0].tag === 15) {
        const patternInput_11 = update_11(model.Context, matchValue[0].fields[0], model.ModalManager);
        return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, patternInput_11[0], model.ComparisonCart), Cmd_map((arg0_11) => (new Msg(15, arg0_11)), patternInput_11[1])];
    }
    else if (matchValue[0].tag === 11) {
        return [model, Cmd_none()];
    }
    else if (matchValue[0].tag === 12) {
        return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, matchValue[0].fields[0], model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_none()];
    }
    else if (matchValue[0].tag === 13) {
        return [model, Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, getLookups, model.Context, (arg0_12) => (new Msg(14, arg0_12)), (arg) => (new Msg(14, new GetLookupsRes(1, arg))))];
    }
    else if (matchValue[0].tag === 14) {
        if (matchValue[0].fields[0].tag === 1) {
            console.error(some("Unable to load lookups"));
            return urlUpdate(void 0, Model_get_Empty());
        }
        else {
            return urlUpdate(model.CurrentPage, new Model(model.CurrentPage, (inputRecord = model.Context, new Context_1(matchValue[0].fields[0].fields[0], inputRecord.ModalManager, inputRecord.CartManager)), model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, false, model.ModalManager, model.ComparisonCart));
        }
    }
    else if (matchValue[0].tag === 16) {
        return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, ofSeq(append(model.ComparisonCart, [cartItemFromSummary(matchValue[0].fields[0])]))), Cmd_none()];
    }
    else if (matchValue[0].tag === 17) {
        return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, ofSeq(filter((ci) => (!equalsSafe(ci, matchValue[0].fields[0])), model.ComparisonCart))), Cmd_none()];
    }
    else if (matchValue[0].tag === 18) {
        return [new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, empty()), Cmd_none()];
    }
    else if (matchValue[1].IntervalProgrammeDispatcher != null) {
        const extractedModel = matchValue[1].IntervalProgrammeDispatcher;
        const patternInput = update_12(model.Context, matchValue[0].fields[0], extractedModel);
        return [new Model(model.CurrentPage, model.Context, patternInput[0], model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, model.DashboardDispatcher, model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
    }
    else {
        console.log(some("[App.State] Discarded IntervalProgramme dispatcher message"));
        return [model, Cmd_none()];
    }
}

function crossDomainUpdate(msg, model) {
    const matchValue = [msg, model];
    let pattern_matching_result, extractedModel, newMetric;
    if (matchValue[0].tag === 15) {
        if (matchValue[0].fields[0].tag === 3) {
            if (matchValue[0].fields[0].fields[0].tag === 2) {
                if (matchValue[0].fields[0].fields[0].fields[0].tag === 0) {
                    if (matchValue[1].DashboardDispatcher != null) {
                        if (matchValue[1].DashboardDispatcher.IndexModel != null) {
                            pattern_matching_result = 0;
                            extractedModel = matchValue[1].DashboardDispatcher.IndexModel;
                            newMetric = matchValue[0].fields[0].fields[0].fields[0].fields[0];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Model(model.CurrentPage, model.Context, model.IntervalProgrammeDispatcher, model.MetricsDispatcher, model.HelpDispatcher, model.WelcomeDispatcher, model.AnalysisDispatcher, model.HeartRateZonesDispatcher, model.PowerZonesDispatcher, model.WeeklyTrainingLogDispatcher, model.CardioActivityDispatcher, new Model_2(model.DashboardDispatcher.CurrentPage, new Model_1(append_1([newMetric], extractedModel.RecentWeight), extractedModel.RecentRestingHeartRate, extractedModel.IsLoading, extractedModel.IsSaving, Array.from(calculateAggregates(append_1([newMetric], extractedModel.RecentWeight))), extractedModel.Today, extractedModel.UserPowerZones, extractedModel.HeartRateZones, extractedModel.RecentWorkouts, extractedModel.RecentPowerPeaks, extractedModel.RecentHeartPeaks, extractedModel.RecentDistances, extractedModel.TrainingLoad, extractedModel.TrainsWithPower, extractedModel.ShowWorkoutChooser)), model.ProgrammeDispatcher, model.IsNavigationDropdownOpen, model.IsGlobalLoading, model.ModalManager, model.ComparisonCart);
        }
        case 1: {
            return model;
        }
    }
}

export function update(msg, model) {
    const patternInput = rootUpdate(msg, model);
    return [crossDomainUpdate(msg, patternInput[0]), patternInput[1]];
}

