import { power as power_1 } from "../../Units.fs.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { toolbarPage } from "../../Layout.fs.js";
import * as react from "react";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { sortableGrid } from "../../Grids.fs.js";
import { Msg } from "./Types.fs.js";

export function root(context, model, dispatch) {
    const power = (fromValue) => power_1(context, ~(~fromValue));
    const columns = [["Date", (cs) => toShortDateString(cs.RecordedAtUtc), 0], ["FTP", (cs_1) => power_1(context, ~(~cs_1.CurrentFtp)), 1], ["Active Recovery", (cs_2) => {
        let arg20;
        return arg20 = power(cs_2.ActiveRecovery), toText(printf("%s-%s"))("0")(arg20);
    }, 2], ["Endurance", (cs_3) => {
        let arg20_1, arg10_1;
        return arg20_1 = power(cs_3.Endurance), (arg10_1 = power(cs_3.ActiveRecovery + 1), toText(printf("%s-%s"))(arg10_1)(arg20_1));
    }, 3], ["Tempo", (cs_4) => {
        let arg20_2, arg10_2;
        return arg20_2 = power(cs_4.Tempo), (arg10_2 = power(cs_4.Endurance + 1), toText(printf("%s-%s"))(arg10_2)(arg20_2));
    }, 4], ["Lactate Threshold", (cs_5) => {
        let arg20_3, arg10_3;
        return arg20_3 = power(cs_5.LactateThreshold), (arg10_3 = power(cs_5.Tempo + 1), toText(printf("%s-%s"))(arg10_3)(arg20_3));
    }, 5], ["VO2 Max", (cs_6) => {
        let arg20_4, arg10_4;
        return arg20_4 = power(cs_6.VO2Max), (arg10_4 = power(cs_6.LactateThreshold + 1), toText(printf("%s-%s"))(arg10_4)(arg20_4));
    }, 6], ["Anaerobic Capacity", (cs_7) => {
        let arg20_5, arg10_5;
        return arg20_5 = power(cs_7.AnaerobicCapacity), (arg10_5 = power(cs_7.VO2Max + 1), toText(printf("%s-%s"))(arg10_5)(arg20_5));
    }, 7], ["Neuromuscular Power", (cs_8) => {
        let arg10_6;
        return arg10_6 = power(cs_8.AnaerobicCapacity), toText(printf("\u003e %s"))(arg10_6);
    }, 8]];
    return toolbarPage("Power Zones", void 0, model.IsLoading, false, [], [react.createElement("div", {
        className: "mb-6",
    }, ...ofSeq(delay(() => {
        const matchValue = model.Page;
        if (matchValue == null) {
            return singleton(react.createElement(react.Fragment, {}));
        }
        else {
            const page = matchValue;
            return singleton(sortableGrid(void 0, (value_2) => {
                void value_2;
            }, columns, 0, page.Items, page.PageNumber, page.NumberOfPages, page.NumberOfRows, page.PageSize, (arg) => {
                dispatch(new Msg(2, arg));
            }));
        }
    })))]);
}

