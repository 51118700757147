import { Record } from "../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../Context.fs.js";
import { CardioSummary__get_CalculatedTitle, LapType, PeakPerformanceType$reflection, CardioSummary$reflection, PeakPerformance$reflection } from "../../../shared/Shared.Models.fs.js";
import { record_type, option_type, lambda_type, class_type, array_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { append, tryFind, sortWith, mapIndexed, maxBy, max as max_1, collect, filter, map, isEmpty } from "../.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { uncurry, curry, comparePrimitives, equalsSafe } from "../.fable/fable-library.3.0.0/Util.js";
import { line, ITimeGraphProps, TimeGraphColor, TimeGraphDataPoint, TimeGraphTick } from "../TimeGraph.fs.js";
import { peakTypeAsUnits, peakTypeAsLabel, seriesColors, possibleTicks } from "./Types.fs.js";
import { Series_Time_Z1F763A16 } from "../Graph.fs.js";
import { GraphValueTransformer } from "../GraphShared.fs.js";
import { Id_value } from "../../../shared/Shared.Types.fs.js";
import { singleton, empty } from "../.fable/fable-library.3.0.0/List.js";
import { shortDurationAsString, weight, weightUnits } from "../Units.fs.js";
import { printf, toText } from "../.fable/fable-library.3.0.0/String.js";
import { toShortDateString, toShortTimeString } from "../.fable/fable-library.3.0.0/Date.js";
import { PopoverButton, popoverButtonBar } from "../Buttons.fs.js";
import { addToComparisonCartIcon, IconSize, gotoIcon } from "../Icons.fs.js";
import { CardioActivityPage, Page, modifyLocation } from "../Router.fs.js";

export class IRenderPeakPerformancesProps extends Record {
    constructor(Context, PeakPerformances, Summaries, PeakType, RenderTable) {
        super();
        this.Context = Context;
        this.PeakPerformances = PeakPerformances;
        this.Summaries = Summaries;
        this.PeakType = PeakType;
        this.RenderTable = RenderTable;
    }
}

export function IRenderPeakPerformancesProps$reflection() {
    return record_type("Analysis.RenderMax.IRenderPeakPerformancesProps", [], IRenderPeakPerformancesProps, () => [["Context", Context$reflection()], ["PeakPerformances", array_type(array_type(PeakPerformance$reflection()))], ["Summaries", array_type(CardioSummary$reflection())], ["PeakType", PeakPerformanceType$reflection()], ["RenderTable", option_type(lambda_type(array_type(array_type(PeakPerformance$reflection())), lambda_type(PeakPerformanceType$reflection(), class_type("Fable.React.ReactElement"))))]]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const peakPerformances = props.PeakPerformances;
    const context = props.Context;
    const summaries = props.Summaries;
    const peakType = props.PeakType;
    if (isEmpty(peakPerformances)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const peaksForLapAndPeakType = Array.from(map((routePeaks) => Array.from(filter((p) => {
            if (equalsSafe(p.PeakType, peakType)) {
                return equalsSafe(p.Lap, new LapType(0));
            }
            else {
                return false;
            }
        }, routePeaks)), peakPerformances));
        const collectedPeaks = Array.from(collect((x) => x, peaksForLapAndPeakType));
        if (isEmpty(collectedPeaks)) {
            return react.createElement(react.Fragment, {});
        }
        else {
            const largestTimeInSeconds = max_1(map((dp) => dp.PeakDurationSeconds, collectedPeaks), {
                Compare: comparePrimitives,
            }) | 0;
            const ticks = Array.from(map((t_1) => (new TimeGraphTick(t_1, void 0)), filter((t) => (t <= largestTimeInSeconds), possibleTicks)));
            const largestTick = maxBy((t_2) => t_2.TimeInSeconds, ticks, {
                Compare: comparePrimitives,
            }).TimeInSeconds | 0;
            const graphProps = new ITimeGraphProps(Array.from(mapIndexed((index, seriesSource) => {
                let projection_1;
                return Series_Time_Z1F763A16(Array.from((projection_1 = ((dp_1) => dp_1.TimeInSeconds), sortWith((x_3, y_2) => comparePrimitives(projection_1(x_3), projection_1(y_2)), map((peak_1) => (new TimeGraphDataPoint(peak_1.PeakValue, peak_1.PeakDurationSeconds, true)), filter((peak) => (peak.PeakDurationSeconds <= largestTick), seriesSource))))), new TimeGraphColor(0, seriesColors[index % seriesColors.length]));
            }, peaksForLapAndPeakType)), ticks, void 0, (tupledArg) => {
                const max = tupledArg[1];
                switch (peakType.tag) {
                    case 2: {
                        return [0, Math.ceil(max)];
                    }
                    case 3:
                    case 4:
                    case 1: {
                        return [0, Math.ceil(max / 10) * 10];
                    }
                    default: {
                        return [Math.floor((tupledArg[0] - 5) / 5) * 5, Math.ceil(max / 5) * 5];
                    }
                }
            }, void 0, true, true, true, "#718096", "#CBD5E0", 0, false, void 0, new GraphValueTransformer(0), (series_1, seriesIndex_1, dataPoint_1) => {
                let source2, peak_4, arg20, arg10, arg20_4, arg20_5, s_4, arg20_1, arg20_2, arg20_3, arg10_3;
                const peak_3 = tryFind((p_3) => {
                    if (p_3.PeakValue === dataPoint_1.Value) {
                        return equalsSafe(p_3.PeakType, peakType);
                    }
                    else {
                        return false;
                    }
                }, peakPerformances[seriesIndex_1]);
                let summary_1;
                if (peak_3 == null) {
                    summary_1 = (void 0);
                }
                else {
                    const p_4 = peak_3;
                    summary_1 = tryFind((s_3) => (s_3.Id === Id_value(p_4.CardioActivitySummaryId)), summaries);
                }
                return Array.from((source2 = ((peak_3 == null) ? empty() : (peak_4 = peak_3, singleton(["Weight", (arg20 = weightUnits(context), (arg10 = weight(context, peak_4.Weight), toText(printf("%s %s"))(arg10)(arg20)))]))), append((summary_1 == null) ? [["Peak duration", shortDurationAsString(dataPoint_1.TimeInSeconds)], [peakTypeAsLabel(peakType), (peakType.tag === 2) ? (arg20_4 = peakTypeAsUnits(peakType, context), toText(printf("%.2f %s"))(dataPoint_1.Value)(arg20_4)) : (arg20_5 = peakTypeAsUnits(peakType, context), toText(printf("%.0f %s"))(dataPoint_1.Value)(arg20_5))]] : (s_4 = summary_1, [["Peak duration", shortDurationAsString(dataPoint_1.TimeInSeconds)], [peakTypeAsLabel(peakType), (peakType.tag === 2) ? (arg20_1 = peakTypeAsUnits(peakType, context), toText(printf("%.2f %s"))(dataPoint_1.Value)(arg20_1)) : (arg20_2 = peakTypeAsUnits(peakType, context), toText(printf("%.0f %s"))(dataPoint_1.Value)(arg20_2))], ["Route", CardioSummary__get_CalculatedTitle(s_4)], ["Date", (arg20_3 = toShortTimeString(s_4.Metrics.RecordedAtUtc), (arg10_3 = toShortDateString(s_4.Metrics.RecordedAtUtc), toText(printf("%s %s"))(arg10_3)(arg20_3)))]]), source2)));
            }, (color, seriesIndex, dataPoint) => {
                const peak_2 = tryFind((p_1) => {
                    if (p_1.PeakValue === dataPoint.Value) {
                        return equalsSafe(p_1.PeakType, peakType);
                    }
                    else {
                        return false;
                    }
                }, peakPerformances[seriesIndex]);
                let summary;
                if (peak_2 == null) {
                    summary = (void 0);
                }
                else {
                    const p_2 = peak_2;
                    summary = tryFind((s) => (s.Id === Id_value(p_2.CardioActivitySummaryId)), summaries);
                }
                return react.createElement("div", {}, popoverButtonBar(new PopoverButton(react.createElement("span", {
                    className: "flex justify-center text-gray-500",
                }, gotoIcon(new IconSize(4))), () => {
                    if (summary == null) {
                    }
                    else {
                        modifyLocation(new Page(8, new CardioActivityPage(1, summary.Id)));
                    }
                }, summary == null), new PopoverButton(react.createElement("span", {
                    className: "flex justify-center text-gray-500",
                }, addToComparisonCartIcon(new IconSize(4))), () => {
                    if (summary == null) {
                    }
                    else {
                        context.CartManager.addItem(summary);
                    }
                }, summary == null)));
            }, void 0);
            let peakTable;
            const matchValue_2 = props.RenderTable;
            peakTable = ((curry(2, matchValue_2) == null) ? react.createElement(react.Fragment, {}) : matchValue_2(peaksForLapAndPeakType, peakType));
            return react.createElement("div", {}, react.createElement("div", {
                style: {
                    height: 320,
                },
            }, line(graphProps)), react.createElement("div", {
                className: "mt-6",
            }, peakTable));
        }
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/RenderMax.fs", 21);

