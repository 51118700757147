import { root as root_1 } from "../Index/View.fs.js";
import { Msg } from "./Types.fs.js";
import { root as root_2 } from "../History/View.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import * as react from "react";

export function root(context, model, dispatch) {
    let pattern_matching_result, extractedModel, extractedModel_1;
    if (model.CurrentPage.tag === 1) {
        if (model.HistoryModel != null) {
            pattern_matching_result = 1;
            extractedModel_1 = model.HistoryModel;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else if (model.IndexModel != null) {
        pattern_matching_result = 0;
        extractedModel = model.IndexModel;
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            return root_1(context, extractedModel, (arg) => {
                dispatch(new Msg(0, arg));
            });
        }
        case 1: {
            return root_2(context, extractedModel_1, (arg_1) => {
                dispatch(new Msg(1, arg_1));
            });
        }
        case 2: {
            console.log(some("[PowerZones.Dispatcher.View] Page not found"));
            return react.createElement("div", {}, "Page not found");
        }
    }
}

