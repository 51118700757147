import { isGreaterThan, validateRequired, isNotEmpty, validate as validate_1, createValidatorFor } from "../../../../shared/SimpleValidation.fs.js";
import { ofArray, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { Converters_ensureFloatFromInput, Converters_parseFloatFromInput } from "../../Form.fs.js";
import { Item, SubmitRecordMetricRes, RecordMetricModalMsg, Model, Model_Empty } from "./Types.fs.js";
import { Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some, defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { submitMetric } from "./Api.fs.js";
import { successToast, errorToast } from "../../Notifications.fs.js";

const itemValidator = createValidatorFor(ofArray([validate_1("Value", (v) => {
    const matchValue = v.Value;
    return (matchValue == null) ? "" : matchValue;
}, singleton((arg00$0040) => ((value) => isNotEmpty(arg00$0040, value.split(""))))), validateRequired("Value", (v_2) => {
    const matchValue_1 = v_2.Value;
    return (matchValue_1 == null) ? (void 0) : Converters_parseFloatFromInput(matchValue_1);
}, singleton(isGreaterThan(0)))]));

export function init() {
    return [(arg00) => ((arg10) => Model_Empty(arg00, arg10)), Cmd_none()];
}

function validate(model) {
    return new Model(model.Item, model.IsSaving, itemValidator(model.Item));
}

export function update(context, msg, model) {
    let value, inputRecord;
    switch (msg.tag) {
        case 1: {
            return [new Model(model.Item, true, model.ValidationState), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (value = defaultArg(Converters_parseFloatFromInput(defaultArg(model.Item.Value, "")), 0), (context_1) => submitMetric(model.Item.Type, value, context_1)), context, (arg0) => (new RecordMetricModalMsg(2, arg0)), (arg) => (new RecordMetricModalMsg(2, new SubmitRecordMetricRes(1, arg)))), false];
        }
        case 2: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Item, false, model.ValidationState), errorToast("Unable to save metric"), false];
            }
            else {
                return [new Model(model.Item, false, model.ValidationState), Cmd_batch(singleton(successToast("Your metric has been saved"))), true];
            }
        }
        default: {
            return [validate(new Model((inputRecord = model.Item, new Item(inputRecord.Type, inputRecord.Label, inputRecord.Unit, Converters_ensureFloatFromInput(msg.fields[0]))), model.IsSaving, model.ValidationState)), Cmd_none(), false];
        }
    }
}

