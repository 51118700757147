import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, option_type, int32_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Page$1$reflection, UserPowerZones$reflection } from "../../../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(IsLoading, PageNumber, Page) {
        super();
        this.IsLoading = IsLoading;
        this.PageNumber = (PageNumber | 0);
        this.Page = Page;
    }
}

export function Model$reflection() {
    return record_type("PowerZones.History.Types.Model", [], Model, () => [["IsLoading", bool_type], ["PageNumber", int32_type], ["Page", option_type(Page$1$reflection(UserPowerZones$reflection()))]]);
}

export function Model_get_Empty() {
    return new Model(true, 0, void 0);
}

export class GetHistoryRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetHistoryRes$reflection() {
    return union_type("PowerZones.History.Types.GetHistoryRes", [], GetHistoryRes, () => [[["Item", Page$1$reflection(UserPowerZones$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetHistory", "GetHistoryResult", "GotoPage"];
    }
}

export function Msg$reflection() {
    return union_type("PowerZones.History.Types.Msg", [], Msg, () => [[], [["Item", GetHistoryRes$reflection()]], [["Item", int32_type]]]);
}

