import { GetWeeklyTrainingLogsRes, Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { tryFindIndex } from "../../.fable/fable-library.3.0.0/Seq.js";
import { item, length } from "../../.fable/fable-library.3.0.0/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { WeeklyTrainingLogPage_FromRoute, Page, modifyLocation } from "../../Router.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { getWeeklyTrainingLogs } from "./Api.fs.js";

export function init(year, month) {
    let inputRecord;
    const model = Model_get_Empty();
    let selectedYearIndex;
    const matchValue = tryFindIndex((y) => (y === year), model.Years);
    selectedYearIndex = ((matchValue == null) ? (length(model.Years) - 1) : matchValue);
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.Items, month - 1, selectedYearIndex, inputRecord.Years, inputRecord.IsLoading)), Cmd_OfFunc_result(new Msg(0))];
}

function setRoute(years, seletedYearIndex, selectedMonthIndex) {
    let arg10;
    modifyLocation(new Page(7, (arg10 = ((selectedMonthIndex + 1) | 0), WeeklyTrainingLogPage_FromRoute(item(seletedYearIndex, years), arg10))));
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 2: {
            setRoute(model.Years, model.SelectedYearIndex, msg.fields[0]);
            return [model, Cmd_none()];
        }
        case 3: {
            setRoute(model.Years, msg.fields[0], model.SelectedMonthIndex);
            return [model, Cmd_none()];
        }
        case 4: {
            const newYear = (model.SelectedYearIndex + ((model.SelectedMonthIndex === 0) ? -1 : 0)) | 0;
            if (newYear < 0) {
                return [model, Cmd_none()];
            }
            else {
                setRoute(model.Years, newYear, ((model.SelectedMonthIndex - 1) < 0) ? 11 : (model.SelectedMonthIndex - 1));
                return [model, Cmd_none()];
            }
        }
        case 5: {
            const newYear_1 = (model.SelectedYearIndex + ((model.SelectedMonthIndex === 11) ? 1 : 0)) | 0;
            if (newYear_1 === length(model.Years)) {
                return [model, Cmd_none()];
            }
            else {
                setRoute(model.Years, newYear_1, (model.SelectedMonthIndex === 11) ? 0 : (model.SelectedMonthIndex + 1));
                return [model, Cmd_none()];
            }
        }
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Items, model.SelectedMonthIndex, model.SelectedYearIndex, model.Years, false), Cmd_none()];
            }
            else {
                return [new Model(result.fields[0], model.SelectedMonthIndex, model.SelectedYearIndex, model.Years, false), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.Items, model.SelectedMonthIndex, model.SelectedYearIndex, model.Years, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getWeeklyTrainingLogs(model, context_1), context, (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetWeeklyTrainingLogsRes(1, arg))))];
        }
    }
}

