import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { CardioActivityCategory$reflection, Lift$reflection } from "../../shared/Shared.Models.fs.js";
import { record_type, list_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { IModalManager_get_NullManager, IModalManager$reflection } from "./Modal/Manager.fs.js";
import { ICartmanager_get_NullManager, ICartmanager$reflection } from "./ComparisonCart/Manager.fs.js";
import { empty } from "./.fable/fable-library.3.0.0/List.js";

export class Lookups extends Record {
    constructor(Lifts, CardioCategories) {
        super();
        this.Lifts = Lifts;
        this.CardioCategories = CardioCategories;
    }
}

export function Lookups$reflection() {
    return record_type("ClientApp.Context.Lookups", [], Lookups, () => [["Lifts", list_type(Lift$reflection())], ["CardioCategories", list_type(CardioActivityCategory$reflection())]]);
}

export class Context extends Record {
    constructor(Lookups, ModalManager, CartManager) {
        super();
        this.Lookups = Lookups;
        this.ModalManager = ModalManager;
        this.CartManager = CartManager;
    }
}

export function Context$reflection() {
    return record_type("ClientApp.Context.Context", [], Context, () => [["Lookups", Lookups$reflection()], ["ModalManager", IModalManager$reflection()], ["CartManager", ICartmanager$reflection()]]);
}

export function Context_get_Empty() {
    return new Context(new Lookups(empty(), empty()), IModalManager_get_NullManager(), ICartmanager_get_NullManager());
}

