import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { TimeInZones$reflection, UserHeartRateZones$reflection, UserPowerZones$reflection, PeakPerformance$reflection, CardioLap$reflection, CardioSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { string_type, union_type, class_type, tuple_type, bool_type, record_type, option_type, list_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Id__Id$reflection } from "../../../../shared/Shared.Types.fs.js";

export class Item extends Record {
    constructor(Summary, Laps, HeartPeaks, PowerPeaks, SpeedPeaks, AscentPeaks, PowerZones, HeartRateZones, TimeInZones) {
        super();
        this.Summary = Summary;
        this.Laps = Laps;
        this.HeartPeaks = HeartPeaks;
        this.PowerPeaks = PowerPeaks;
        this.SpeedPeaks = SpeedPeaks;
        this.AscentPeaks = AscentPeaks;
        this.PowerZones = PowerZones;
        this.HeartRateZones = HeartRateZones;
        this.TimeInZones = TimeInZones;
    }
}

export function Item$reflection() {
    return record_type("CardioActivity.Show.Types.Item", [], Item, () => [["Summary", CardioSummary$reflection()], ["Laps", list_type(CardioLap$reflection())], ["HeartPeaks", list_type(PeakPerformance$reflection())], ["PowerPeaks", list_type(PeakPerformance$reflection())], ["SpeedPeaks", list_type(PeakPerformance$reflection())], ["AscentPeaks", list_type(PeakPerformance$reflection())], ["PowerZones", option_type(UserPowerZones$reflection())], ["HeartRateZones", option_type(UserHeartRateZones$reflection())], ["TimeInZones", option_type(TimeInZones$reflection())]]);
}

export class Model extends Record {
    constructor(IsLoading, IsDeleteModalShowing, IsDeleting, Item) {
        super();
        this.IsLoading = IsLoading;
        this.IsDeleteModalShowing = IsDeleteModalShowing;
        this.IsDeleting = IsDeleting;
        this.Item = Item;
    }
}

export function Model$reflection() {
    return record_type("CardioActivity.Show.Types.Model", [], Model, () => [["IsLoading", bool_type], ["IsDeleteModalShowing", bool_type], ["IsDeleting", bool_type], ["Item", option_type(Item$reflection())]]);
}

export function Model_Empty_1505(id) {
    return new Model(true, false, false, void 0);
}

export class GetCardioActivityRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "NoContent", "Error"];
    }
}

export function GetCardioActivityRes$reflection() {
    return union_type("CardioActivity.Show.Types.GetCardioActivityRes", [], GetCardioActivityRes, () => [[["Item", tuple_type(CardioSummary$reflection(), list_type(CardioLap$reflection()), list_type(PeakPerformance$reflection()), list_type(PeakPerformance$reflection()), list_type(PeakPerformance$reflection()), list_type(PeakPerformance$reflection()), option_type(UserPowerZones$reflection()), option_type(UserHeartRateZones$reflection()), option_type(TimeInZones$reflection()))]], [], [["Item", class_type("System.Exception")]]]);
}

export class DeleteCardioActivityRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function DeleteCardioActivityRes$reflection() {
    return union_type("CardioActivity.Show.Types.DeleteCardioActivityRes", [], DeleteCardioActivityRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class UpdateCategoryRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function UpdateCategoryRes$reflection() {
    return union_type("CardioActivity.Show.Types.UpdateCategoryRes", [], UpdateCategoryRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class GetActivityIdRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetActivityIdRes$reflection() {
    return union_type("CardioActivity.Show.Types.GetActivityIdRes", [], GetActivityIdRes, () => [[["Item", string_type]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetCardioActivity", "GetCardioActivityResult", "GotoPreviousActivity", "GotoNextActivity", "ShowDeleteModal", "HideDeleteModal", "DeleteCardioActivity", "DeleteCardioActivityResult", "UpdateCategory", "UpdateCategoryResult", "GetActivityIdResult"];
    }
}

export function Msg$reflection() {
    return union_type("CardioActivity.Show.Types.Msg", [], Msg, () => [[["Item", Id__Id$reflection()]], [["Item", GetCardioActivityRes$reflection()]], [["Item", Id__Id$reflection()]], [["Item", Id__Id$reflection()]], [], [], [["Item", Id__Id$reflection()]], [["Item", DeleteCardioActivityRes$reflection()]], [["Item1", Id__Id$reflection()], ["Item2", Id__Id$reflection()]], [["Item", UpdateCategoryRes$reflection()]], [["Item", GetActivityIdRes$reflection()]]]);
}

