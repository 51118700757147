import { GetMetricsRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { DateRange as DateRange_3 } from "../../../../shared/Shared.Models.fs.js";
import { dateRangeFromType } from "../Types.fs.js";
import { getAnalysis } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(4))];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 2: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(msg.fields[0], model.DateRange.EndDate), model.MetricType, model.Metrics), Cmd_OfFunc_result(new Msg(4))];
        }
        case 3: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(model.DateRange.StartDate, msg.fields[0]), model.MetricType, model.Metrics), Cmd_OfFunc_result(new Msg(4))];
        }
        case 1: {
            const newValue_1 = msg.fields[0];
            return [new Model(model.IsLoading, newValue_1, dateRangeFromType(newValue_1), model.MetricType, model.Metrics), Cmd_OfFunc_result(new Msg(4))];
        }
        case 4: {
            return [new Model(true, model.DateRangeType, model.DateRange, model.MetricType, model.Metrics), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getAnalysis(model.DateRange, model.MetricType, context_1), context, (arg0) => (new Msg(5, arg0)), (arg) => (new Msg(5, new GetMetricsRes(1, arg))))];
        }
        case 5: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.DateRangeType, model.DateRange, model.MetricType, model.Metrics), Cmd_none()];
            }
            else {
                return [new Model(false, model.DateRangeType, model.DateRange, model.MetricType, result.fields[0]), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, msg.fields[0], model.Metrics), Cmd_OfFunc_result(new Msg(4))];
        }
    }
}

