import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { metrics } from "../../Server.fs.js";
import { SubmitRecordMetricRes } from "./Types.fs.js";

export class UnableToSubmitException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToSubmitException$reflection() {
    return class_type("Modal.RecordMetric.Api.UnableToSubmitException", void 0, UnableToSubmitException, class_type("System.Exception"));
}

function UnableToSubmitException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToSubmitException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function submitMetric(metricType, value, context) {
    return singleton.Delay(() => singleton.Bind(metrics((api) => api.record(metricType, value)), (_arg1) => {
        const newMetricResponse = _arg1;
        return (newMetricResponse.tag === 0) ? singleton.Return(new SubmitRecordMetricRes(0, newMetricResponse.fields[0])) : singleton.Return(new SubmitRecordMetricRes(1, new UnableToSubmitException()));
    }));
}

