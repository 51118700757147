import { class_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { ITimeGraphProps, line, TimeGraphSeries, TimeGraphAxis } from "./TimeGraph.fs.js";
import { value } from "./.fable/fable-library.3.0.0/Option.js";
import { GraphValueTransformer } from "./GraphShared.fs.js";
import { curry, uncurry } from "./.fable/fable-library.3.0.0/Util.js";

export class Series {
    constructor() {
    }
}

export function Series$reflection() {
    return class_type("GraphView.Series", void 0, Series);
}

export function Series_$ctor() {
    return new Series();
}

export function Series_Time_Z1F763A16(dataPoints, color, backgroundColor, axisPosition, dashArray, prefix, postfix, strokeWidth, showLine) {
    const Axis = (axisPosition == null) ? (new TimeGraphAxis(0)) : axisPosition;
    const Prefix = (prefix == null) ? (void 0) : value(prefix);
    const Postfix = (postfix == null) ? (void 0) : value(postfix);
    return new TimeGraphSeries(dataPoints, color, backgroundColor, Axis, dashArray, (strokeWidth == null) ? 1 : strokeWidth, Prefix, Postfix, (showLine == null) ? true : showLine);
}

export class Graph {
    constructor() {
    }
}

export function Graph$reflection() {
    return class_type("GraphView.Graph", void 0, Graph);
}

export function Graph_$ctor() {
    return new Graph();
}

export function Graph_Time_7CAA9E5B(series, xAxisLabels, rightAxisValueRange, leftAxisValueRange, xAxisValueRange, showXAxis, showYAxis, gridLines, legendTextColor, gridColor, rightAxisColor, yAxisValueTransformer, popOverAnnotation, logarithmicX, popoverRows, useMouseOver, mouseOverPopoverRows) {
    let ShowYAxis, ShowXAxis, GridLines, UseMouseOver, LegendTextColor, GridColor, YAxisValueTransformer;
    const xAxisTicks = (xAxisLabels == null) ? [] : xAxisLabels;
    return line((ShowYAxis = ((showYAxis == null) ? true : showYAxis), (ShowXAxis = ((showXAxis == null) ? true : showXAxis), (GridLines = (((gridLines == null) ? 0 : gridLines) | 0), (UseMouseOver = ((useMouseOver == null) ? false : useMouseOver), (LegendTextColor = ((legendTextColor == null) ? "#718096" : legendTextColor), (GridColor = ((gridColor == null) ? "#A0AEC0" : gridColor), (YAxisValueTransformer = ((yAxisValueTransformer == null) ? (new GraphValueTransformer(0)) : yAxisValueTransformer), new ITimeGraphProps(series, xAxisTicks, rightAxisValueRange, leftAxisValueRange, xAxisValueRange, ShowYAxis, ShowXAxis, (logarithmicX == null) ? false : logarithmicX, LegendTextColor, GridColor, GridLines, UseMouseOver, rightAxisColor, YAxisValueTransformer, uncurry(3, (curry(3, popoverRows) == null) ? ((_arg3) => ((_arg2) => ((_arg1) => (new Array(0))))) : curry(3, popoverRows)), popOverAnnotation, mouseOverPopoverRows)))))))));
}

