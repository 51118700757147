import { Union, toString, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Metric$reflection, MetricType$reflection } from "../../../../shared/Shared.Models.fs.js";
import { union_type, class_type, bool_type, record_type, option_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ValidationState, ValidationState$reflection } from "../../../../shared/SimpleValidation.fs.js";

export class Item extends Record {
    constructor(Type, Label, Unit, Value) {
        super();
        this.Type = Type;
        this.Label = Label;
        this.Unit = Unit;
        this.Value = Value;
    }
}

export function Item$reflection() {
    return record_type("Modal.RecordMetric.Types.Item", [], Item, () => [["Type", MetricType$reflection()], ["Label", string_type], ["Unit", string_type], ["Value", option_type(string_type)]]);
}

export class Model extends Record {
    constructor(Item, IsSaving, ValidationState) {
        super();
        this.Item = Item;
        this.IsSaving = IsSaving;
        this.ValidationState = ValidationState;
    }
}

export function Model$reflection() {
    return record_type("Modal.RecordMetric.Types.Model", [], Model, () => [["Item", Item$reflection()], ["IsSaving", bool_type], ["ValidationState", ValidationState$reflection()]]);
}

export function Model_Empty(metricType, initialValue) {
    return new Model(new Item(metricType, "Record your weight", "kg", toString(initialValue)), false, new ValidationState(1));
}

export class SubmitRecordMetricRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitRecordMetricRes$reflection() {
    return union_type("Modal.RecordMetric.Types.SubmitRecordMetricRes", [], SubmitRecordMetricRes, () => [[["Item", Metric$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class RecordMetricModalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeWeight", "SubmitRecordMetric", "SubmitRecordMetricResult"];
    }
}

export function RecordMetricModalMsg$reflection() {
    return union_type("Modal.RecordMetric.Types.RecordMetricModalMsg", [], RecordMetricModalMsg, () => [[["Item", string_type]], [], [["Item", SubmitRecordMetricRes$reflection()]]]);
}

