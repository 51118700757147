import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, option_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ProgrammeId$reflection, ProgrammeSummary$reflection, Programme$reflection } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { UserPowerZones$reflection } from "../../../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(IsLoading, Programme, Summary, UseFtp, UserPowerZones, ShowDeleteModal, IsDeleting) {
        super();
        this.IsLoading = IsLoading;
        this.Programme = Programme;
        this.Summary = Summary;
        this.UseFtp = UseFtp;
        this.UserPowerZones = UserPowerZones;
        this.ShowDeleteModal = ShowDeleteModal;
        this.IsDeleting = IsDeleting;
    }
}

export function Model$reflection() {
    return record_type("PowerProgramme.Show.Types.Model", [], Model, () => [["IsLoading", bool_type], ["Programme", option_type(Programme$reflection())], ["Summary", option_type(ProgrammeSummary$reflection())], ["UseFtp", bool_type], ["UserPowerZones", option_type(UserPowerZones$reflection())], ["ShowDeleteModal", bool_type], ["IsDeleting", bool_type]]);
}

export function Model_Empty_1505(id) {
    return new Model(true, void 0, void 0, false, void 0, false, false);
}

export class GetIntervalProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetIntervalProgrammeRes$reflection() {
    return union_type("PowerProgramme.Show.Types.GetIntervalProgrammeRes", [], GetIntervalProgrammeRes, () => [[["Item1", Programme$reflection()], ["Item2", ProgrammeSummary$reflection()], ["Item3", option_type(UserPowerZones$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class DeleteRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function DeleteRes$reflection() {
    return union_type("PowerProgramme.Show.Types.DeleteRes", [], DeleteRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ToggleUseFtp", "ShowDeleteModal", "HideDeleteModal", "Delete", "DeleteResult", "GetIntervalProgramme", "GetIntervalProgrammeResult"];
    }
}

export function Msg$reflection() {
    return union_type("PowerProgramme.Show.Types.Msg", [], Msg, () => [[], [], [], [["Item", ProgrammeId$reflection()]], [["Item", DeleteRes$reflection()]], [["Item", ProgrammeId$reflection()]], [["Item", GetIntervalProgrammeRes$reflection()]]]);
}

