import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { GetPeaksRes, Model, Msg, Model_Empty_1505 } from "./Types.fs.js";
import { ofArray, empty } from "../../.fable/fable-library.3.0.0/List.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_batch, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { mapIndexed, take, skip, tryLast, append, map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { dateRangeFromType, last30Days, DateRangeType } from "../Types.fs.js";
import { DateRange, CategoryIdIncludingAll } from "../../../../shared/Shared.Models.fs.js";
import { getAnalysis as getAnalysis_1 } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export class NoAutoRangeForType extends FSharpException {
    constructor() {
        super();
    }
}

export function NoAutoRangeForType$reflection() {
    return class_type("Analysis.PeakPerformances.State.NoAutoRangeForType", void 0, NoAutoRangeForType, class_type("System.Exception"));
}

function NoAutoRangeForType__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof NoAutoRangeForType) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function init() {
    return [Model_Empty_1505(empty()), Cmd_OfFunc_result(new Msg(7))];
}

export function update(context, msg, model) {
    let Ranges, matchValue, Ranges_1, source2_3, source2_4, source2_5;
    switch (msg.tag) {
        case 10: {
            return [new Model(model.IsLoading, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, false), Cmd_none()];
        }
        case 11: {
            const dateRanges = msg.fields[0];
            return [new Model(model.IsLoading, Array.from(map((_arg1) => (new DateRangeType(8)), dateRanges)), dateRanges, msg.fields[1], model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(7)), Cmd_OfFunc_result(new Msg(10))]))];
        }
        case 0: {
            return [new Model(model.IsLoading, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, msg.fields[0], model.ShowRollingAnalysisModal), Cmd_OfFunc_result(new Msg(7))];
        }
        case 1: {
            return [(Ranges = Array.from(append(model.Ranges, [last30Days()])), new Model(model.IsLoading, Array.from(append(model.DateRangeTypes, [new DateRangeType(0)])), Ranges, Array.from(append(model.Categories, [(matchValue = tryLast(model.Categories), (matchValue != null) ? matchValue : (new CategoryIdIncludingAll(0)))])), model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal)), Cmd_OfFunc_result(new Msg(7))];
        }
        case 6: {
            const index = msg.fields[0] | 0;
            return [(Ranges_1 = Array.from((source2_3 = skip(index + 1, model.Ranges), append(take(index, model.Ranges), source2_3))), new Model(model.IsLoading, Array.from((source2_4 = skip(index + 1, model.DateRangeTypes), append(take(index, model.DateRangeTypes), source2_4))), Ranges_1, Array.from((source2_5 = skip(index + 1, model.Categories), append(take(index, model.Categories), source2_5))), model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal)), Cmd_OfFunc_result(new Msg(7))];
        }
        case 2: {
            return [new Model(model.IsLoading, model.DateRangeTypes, Array.from(mapIndexed((i, r) => {
                if (i === msg.fields[0]) {
                    return new DateRange(msg.fields[1], r.EndDate);
                }
                else {
                    return r;
                }
            }, model.Ranges)), model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_OfFunc_result(new Msg(7))];
        }
        case 3: {
            return [new Model(model.IsLoading, model.DateRangeTypes, Array.from(mapIndexed((i_1, r_1) => {
                if (i_1 === msg.fields[0]) {
                    return new DateRange(r_1.StartDate, msg.fields[1]);
                }
                else {
                    return r_1;
                }
            }, model.Ranges)), model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_OfFunc_result(new Msg(7))];
        }
        case 5: {
            return [new Model(model.IsLoading, model.DateRangeTypes, model.Ranges, Array.from(mapIndexed((i_2, v) => {
                if (i_2 === msg.fields[0]) {
                    return msg.fields[1];
                }
                else {
                    return v;
                }
            }, model.Categories)), model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_OfFunc_result(new Msg(7))];
        }
        case 4: {
            const newValue_2 = msg.fields[1];
            const index_4 = msg.fields[0] | 0;
            return [new Model(model.IsLoading, Array.from(mapIndexed((i_3, r_2) => {
                if (i_3 === index_4) {
                    return newValue_2;
                }
                else {
                    return r_2;
                }
            }, model.DateRangeTypes)), (newValue_2.tag === 8) ? model.Ranges : Array.from(mapIndexed((i_4, r_3) => {
                if (i_4 === index_4) {
                    return dateRangeFromType(newValue_2);
                }
                else {
                    return r_3;
                }
            }, model.Ranges)), model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_OfFunc_result(new Msg(7))];
        }
        case 7: {
            if (model.Ranges.length < 1) {
                return [new Model(false, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_none()];
            }
            else {
                return [new Model(true, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, (context_1) => getAnalysis_1(model.Ranges, model.Categories, model.AnalysisCardioType, context_1), context, (arg0) => (new Msg(8, arg0)), (arg) => (new Msg(8, new GetPeaksRes(1, arg))))];
            }
        }
        case 8: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_none()];
            }
            else {
                return [new Model(false, model.DateRangeTypes, model.Ranges, model.Categories, result.fields[0], result.fields[1], result.fields[2], model.AnalysisCardioType, model.ShowRollingAnalysisModal), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.DateRangeTypes, model.Ranges, model.Categories, model.AvgPeaks, model.MaxPeaks, model.MaxSummaries, model.AnalysisCardioType, true), Cmd_none()];
        }
    }
}

