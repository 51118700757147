import { trimEnd, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { mapIndexed, skip, head as head_1, singleton as singleton_1, delay, append, tryFindIndex, contains, fold } from "./.fable/fable-library.3.0.0/Seq.js";
import { parse } from "./.fable/fable-library.3.0.0/Int32.js";
import { some, defaultArg } from "./.fable/fable-library.3.0.0/Option.js";
import { uncurry } from "./.fable/fable-library.3.0.0/Util.js";
import { parse as parse_1 } from "./.fable/fable-library.3.0.0/Double.js";
import * as react from "react";
import { ofSeq, singleton, empty } from "./.fable/fable-library.3.0.0/List.js";
import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { record_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { match } from "./.fable/fable-library.3.0.0/RegExp.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { transparentOverlay } from "./Layout.fs.js";
import { subtleLabelClass } from "./Typography.fs.js";

export function Converters_floatWithoutTrailingZeroes(v) {
    const text = toText(printf("%f"))(v);
    if (text.indexOf(".") > -1) {
        return trimEnd(trimEnd(text, "0"), ".");
    }
    else {
        return text;
    }
}

export function Converters_parseIntFromInput(str) {
    const strippedString = fold((s, chr_1) => {
        let arg20;
        const chrStr = chr_1;
        arg20 = (contains(chrStr, "0123456789".split("")) ? chrStr : "");
        return toText(printf("%s%s"))(s)(arg20);
    }, "", str.split(""));
    if (strippedString.length === 0) {
        return void 0;
    }
    else {
        return parse(strippedString, 511, false, 32);
    }
}

export function Converters_ensureFloatFromInput(str) {
    let arg;
    const dotIndex = defaultArg(tryFindIndex((c) => (c === "."), str.split("")), 2147483647) | 0;
    const strippedString = (arg = ["", 0], fold(uncurry(2, (tupledArg) => {
        const i_1 = tupledArg[1] | 0;
        return (chr_1) => {
            let arg20, chr, chrStr, matchValue;
            return [(arg20 = (chr = chr_1, (chrStr = chr, (matchValue = [chr === ".", i_1 > dotIndex], matchValue[0] ? (matchValue[1] ? "" : (contains(chrStr, "0123456789.".split("")) ? chrStr : "")) : (contains(chrStr, "0123456789.".split("")) ? chrStr : "")))), toText(printf("%s%s"))(tupledArg[0])(arg20)), i_1 + 1];
        };
    }), [arg[0], arg[1]], str.split("")))[0];
    if (strippedString.length === 0) {
        return void 0;
    }
    else {
        return strippedString;
    }
}

export function Converters_parseFloatFromInput(str) {
    const strippedString = fold((s, chr_1) => {
        let arg20;
        const chrStr = chr_1;
        arg20 = (contains(chrStr, "0123456789.".split("")) ? chrStr : "");
        return toText(printf("%s%s"))(s)(arg20);
    }, "", str.split(""));
    if (strippedString.length === 0) {
        return void 0;
    }
    else {
        return parse_1(strippedString);
    }
}

export function Converters_optionalIntValue(ivalue) {
    if (ivalue == null) {
        return "";
    }
    else {
        const i = ivalue | 0;
        return toText(printf("%d"))(i);
    }
}

export function Converters_optionalFloatValue(ivalue) {
    if (ivalue == null) {
        return "";
    }
    else {
        const i = ivalue;
        return toText(printf("%f"))(i);
    }
}

export function header(title, description) {
    return react.createElement("div", {}, ...append([react.createElement("h3", {
        className: "text-lg leading-6 font-medium text-gray-900",
    }, title)], (description == null) ? empty() : singleton(react.createElement("p", {
        className: "mt-1 text-sm leading-5 text-gray-500",
    }, description))));
}

export function block(content) {
    return react.createElement("div", {}, react.createElement("div", {
        className: "mt-6 sm:mt-5",
    }, ...content));
}

export class Option extends Record {
    constructor(value, text) {
        super();
        this.value = value;
        this.text = text;
    }
}

export function Option$reflection() {
    return record_type("Form.Option", [], Option, () => [["value", string_type], ["text", string_type]]);
}

export function embeddedDropDown(onChange, currentValue) {
    return react.createElement("div", {
        className: "rounded-md shadow-sm",
    }, react.createElement("select", {
        id: "country",
        className: "block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
    }, react.createElement("option", {}, "Bench Press"), react.createElement("option", {}, "Shoulder Press"), react.createElement("option", {}, "Exterior Tricep")));
}

function baseDropDown(sizeSelector, labelText, onChange, currentValue) {
    return react.createElement("div", {
        className: "mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, labelText), react.createElement("div", {
        className: "mt-1 sm:mt-0 sm:col-span-2",
    }, react.createElement("div", {
        className: "max-w-xs rounded-md shadow-sm",
    }, react.createElement("select", {
        id: "country",
        className: "block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",
    }, react.createElement("option", {}, "United States"), react.createElement("option", {}, "Canada"), react.createElement("option", {}, "Mexico")))));
}

function baseTextInput(sizeSelector, labelText, onChange, isInvalid, value) {
    const validationBasedClass = isInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, labelText), react.createElement("div", {
        className: "mt-1 sm:mt-0 sm:col-span-2",
    }, react.createElement("div", {
        className: toText(printf("rounded-md shadow-sm %s"))(sizeSelector),
    }, react.createElement("input", {
        className: validationBasedClass,
        value: value,
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
    }))));
}

export function simpleTextInput(onChange, isInvalid, value, extraClass) {
    const validationBasedClass = isInvalid ? toText(printf("rounded-md shadow-sm form-input transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400 %s"))(extraClass) : toText(printf("rounded-md shadow-sm form-input transition duration-150 ease-in-out sm:text-sm sm:leading-5 %s"))(extraClass);
    return react.createElement("input", {
        className: validationBasedClass,
        value: value,
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
    });
}

export function $007CRegex$007C_$007C(pattern, input) {
    if (match(input, pattern) != null) {
        return some(void 0);
    }
    else {
        return void 0;
    }
}

export const floatInput = FunctionComponent_Of_Z5A158BBF((props) => {
    const displayText = react.useState(props.InitialFormatter(props.Value));
    const validationBasedClass = props.IsInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, props.Label), react.createElement("div", {
        className: "mt-1 sm:mt-0 sm:col-span-2",
    }, react.createElement("div", {
        className: toText(printf("rounded-md shadow-sm %s"))(props.SizeSelector),
    }, react.createElement("input", {
        className: validationBasedClass,
        value: displayText[0],
        onChange: (ev) => {
            const newValue = Browser_Types_Event__Event_get_Value(ev).trim();
            if (newValue.length === 0) {
                props.OnChange(void 0);
                displayText[1](newValue);
            }
            else if ($007CRegex$007C_$007C("^[0-9]*(?:\\.[0-9]*)?$", newValue) != null) {
                props.OnChange(Converters_parseFloatFromInput(newValue));
                displayText[1](newValue);
            }
        },
    }))));
}, void 0, uncurry(2, void 0), void 0, "floatInput", "/home/runner/work/strengthPlus/strengthPlus/client/src/Form.fs", 138);

export const unstyledIntInput = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_1, v;
    return react.createElement("input", {
        className: props.Class,
        value: (matchValue_1 = props.Value, (matchValue_1 == null) ? "" : (v = (matchValue_1 | 0), toText(printf("%d"))(v))),
        onChange: (ev) => {
            const newValue = Browser_Types_Event__Event_get_Value(ev).trim();
            if (newValue.length === 0) {
                props.OnChange(void 0);
            }
            else if ($007CRegex$007C_$007C("^[0-9]*", newValue) != null) {
                props.OnChange(Converters_parseIntFromInput(newValue));
            }
        },
        onBlur: (_arg1) => {
            props.OnBlur();
        },
        disabled: props.IsDisabled,
    });
}, void 0, uncurry(2, void 0), void 0, "unstyledIntInput", "/home/runner/work/strengthPlus/strengthPlus/client/src/Form.fs", 170);

export const titleInput = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_2, matchValue_3;
    const classText = props.IsValid ? "appearance-none border-b-2 border-gray-300 text-gray-500 text-2xl outline-none focus:border-none w-full" : "appearance-none border-b-2 border-red-500 text-red-500 text-2xl outline-none focus:border-none w-full";
    return react.createElement("input", {
        className: classText,
        placeholder: (matchValue_2 = props.Placeholder, (matchValue_2 == null) ? "" : matchValue_2),
        value: (matchValue_3 = props.Value, (matchValue_3 == null) ? "" : matchValue_3),
        onChange: (ev) => {
            const newValue = Browser_Types_Event__Event_get_Value(ev);
            if (newValue.length === 0) {
                props.OnChange(void 0);
            }
            else {
                props.OnChange(newValue);
            }
        },
    });
}, void 0, uncurry(2, void 0), void 0, "titleInput", "/home/runner/work/strengthPlus/strengthPlus/client/src/Form.fs", 190);

export const intInput = FunctionComponent_Of_Z5A158BBF((props) => {
    const displayText = react.useState(props.InitialFormatter(props.Value));
    const validationBasedClass = props.IsInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5",
    }, ...ofSeq(delay(() => {
        let matchValue_2;
        return append((matchValue_2 = props.Label, (matchValue_2 == null) ? singleton_1(react.createElement(react.Fragment, {})) : singleton_1(react.createElement("label", {
            className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
        }, matchValue_2))), delay(() => singleton_1(react.createElement("div", {
            className: "mt-1 sm:mt-0 " + ((props.Label != null) ? "sm:col-span-2" : "sm:col-span-3"),
        }, react.createElement("div", {
            className: toText(printf("rounded-md shadow-sm %s"))(props.SizeSelector),
        }, react.createElement("input", {
            className: validationBasedClass,
            value: displayText[0],
            onChange: (ev) => {
                const newValue = Browser_Types_Event__Event_get_Value(ev).trim();
                if (newValue.length === 0) {
                    props.OnChange(void 0);
                    displayText[1](newValue);
                }
                else if ($007CRegex$007C_$007C("^[0-9]*(?:\\.[0-9]*)?$", newValue) != null) {
                    props.OnChange(Converters_parseIntFromInput(newValue));
                    displayText[1](newValue);
                }
            },
        }))))));
    })));
}, void 0, uncurry(2, void 0), void 0, "intInput", "/home/runner/work/strengthPlus/strengthPlus/client/src/Form.fs", 210);

function baseTextArea(sizeSelector, labelText, onChange, isInvalid, value) {
    const validationBasedClass = isInvalid ? "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, labelText), react.createElement("div", {
        className: "mt-1 sm:mt-0 sm:col-span-2",
    }, react.createElement("div", {
        className: toText(printf("%s flex rounded-md shadow-sm"))(sizeSelector),
    }, react.createElement("textarea", {
        value: value,
        rows: 3,
        className: validationBasedClass,
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
    }))));
}

export function textInputWithAddon(labelText, onChange, isInvalid, value) {
    const validationBasedClass = isInvalid ? "form-input flex-1 block w-full px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input flex-1 block w-full px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "w-full",
    }, react.createElement("div", {
        className: "mt-1 flex rounded-md shadow-sm",
    }, react.createElement("input", {
        className: validationBasedClass,
        value: value,
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, labelText)));
}

export function textInputWithAddonElement(sizeSelector, placeholder, onChange, isInvalid, value, addOn) {
    const validationBasedClass = isInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
    return react.createElement("div", {}, react.createElement("div", {
        className: "mt-1 flex rounded-md shadow-sm",
    }, react.createElement("div", {
        className: "relative flex-grow focus-within:z-10",
    }, react.createElement("input", {
        id: "email",
        className: "form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5",
        placeholder: placeholder,
    })), addOn));
}

export function numberInputWithAddonElement(sizeSelector, placeholder, onChange, isInvalid, value, addOn) {
    const validationBasedClass = isInvalid ? "form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full rounded-none rounded-l-md transition ease-in-out duration-150 sm:text-sm sm:leading-5";
    return react.createElement("div", {}, react.createElement("div", {
        className: "mt-1 flex rounded-md shadow-sm",
    }, react.createElement("div", {
        className: "relative flex-grow focus-within:z-10",
    }, react.createElement("input", {
        type: "text",
        inputMode: "numeric",
        pattern: "[0-9]*",
        value: value,
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
        className: validationBasedClass,
        placeholder: placeholder,
    })), addOn));
}

export function configurableRoundedDropdown(roundLeft, roundRight, borderLeft, borderRight, items, selectedItemIndex, onSelected) {
    return FunctionComponent_Of_Z5A158BBF((_arg3) => {
        const isDropdownVisible = react.useState(false);
        let roundedClass;
        const matchValue = [roundLeft, roundRight];
        roundedClass = (matchValue[0] ? (matchValue[1] ? "rounded-md" : "rounded-l-md") : (matchValue[1] ? "rounded-r-md" : ""));
        let borderClass;
        const matchValue_1 = [borderLeft, borderRight];
        borderClass = (matchValue_1[0] ? (matchValue_1[1] ? "border" : "border-l border-t border-b") : (matchValue_1[1] ? "border-r border-t border-b" : ""));
        return react.createElement("div", {
            className: "relative inline-block text-left w-full",
        }, react.createElement("div", {}, react.createElement("span", {
            className: "rounded-md shadow-sm",
        }, react.createElement("button", {
            onClick: (_arg1) => {
                isDropdownVisible[1]((!(isDropdownVisible[0])));
            },
            type: "button",
            className: (("inline-flex justify-between w-full border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 " + roundedClass) + " ") + borderClass,
        }, head_1(skip(selectedItemIndex, items)), react.createElement("svg", {
            className: "-mr-1 ml-2 h-5 w-5",
            fill: "currentColor",
            viewbox: "0 0 20 20",
        }, react.createElement("path", {
            ["fill-rule"]: "evenodd",
            d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
            ["clip-rule"]: "evenodd",
        }))))), (isDropdownVisible[0]) ? react.createElement("div", {}, transparentOverlay(() => {
            isDropdownVisible[1](false);
        }), react.createElement("div", {
            className: "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg",
            style: {
                zIndex: 1400,
            },
        }, react.createElement("div", {
            className: "rounded-md bg-white shadow-xs",
        }, react.createElement("div", {
            className: "py-1",
        }, ...mapIndexed((index, item) => react.createElement("div", {
            onClick: (_arg2) => {
                onSelected(index, item);
                isDropdownVisible[1](false);
            },
            className: "cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
        }, item), items))))) : react.createElement(react.Fragment, {}));
    }, void 0, uncurry(2, void 0), void 0, "configurableRoundedDropdown", "/home/runner/work/strengthPlus/strengthPlus/client/src/Form.fs", 328);
}

export function checkBox(labelText, isChecked, valueSetter) {
    return react.createElement("div", {
        className: "flex items-start",
    }, react.createElement("div", {
        className: "flex items-center h-5",
    }, react.createElement("input", {
        type: "checkbox",
        className: "form-checkbox h-4 text-indigo-600 transition duration-150 ease-in-out",
        checked: isChecked,
        onChange: (ev) => {
            valueSetter(Browser_Types_Event__Event_get_Checked(ev));
        },
    })), react.createElement("div", {
        className: "pl-2 text-sm leading-5",
    }, react.createElement("label", {
        className: "font-medium text-gray-700",
    }, labelText)));
}

export function roundedDropdown(items, selectedItemIndex, onSelected) {
    return configurableRoundedDropdown(true, true, true, true, items, selectedItemIndex, onSelected);
}

export function prevNextButtons(isPreviousDisabled, isNextDisabled, onPrevious, onNext) {
    const enabledClass = "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150";
    const disabledClass = "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-gray-100 text-sm leading-5 font-medium text-gray-300 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150";
    return react.createElement("span", {
        className: "relative z-0 inline-flex shadow-sm",
    }, react.createElement("button", {
        onClick: (_arg1) => {
            onPrevious();
        },
        type: "button",
        disabled: isPreviousDisabled,
        className: (isPreviousDisabled ? disabledClass : enabledClass) + " rounded-l-md",
    }, react.createElement("svg", {
        className: "h-5 w-5",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
        clipRule: "evenodd",
    }))), react.createElement("button", {
        onClick: (_arg2) => {
            onNext();
        },
        type: "button",
        disabled: isNextDisabled,
        className: (isNextDisabled ? disabledClass : enabledClass) + " rounded-r-md border-l-0",
    }, react.createElement("svg", {
        className: "h-5 w-5",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
        clipRule: "evenodd",
    }))));
}

export function toolbarButton(icon, isDisabled, onClick) {
    return react.createElement("span", {
        className: "relative z-0 inline-flex shadow-sm",
    }, react.createElement("button", {
        onClick: (_arg1) => {
            onClick();
        },
        type: "button",
        disabled: isDisabled,
        className: isDisabled ? "relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-gray-100 text-sm leading-5 font-medium text-gray-300 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" : "relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150",
    }, icon));
}

export const textInput = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextInput("", labelText, onChange, isInvalid, value))));

export const smallTextInput = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextInput("max-w-xs", labelText, onChange, isInvalid, value))));

export const mediumTextInput = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextInput("max-w-lg", labelText, onChange, isInvalid, value))));

export const textArea = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextInput("", labelText, onChange, isInvalid, value))));

export const smallTextArea = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextArea("max-w-xs", labelText, onChange, isInvalid, value))));

export const mediumTextArea = (labelText) => ((onChange) => ((isInvalid) => ((value) => baseTextArea("max-w-lg", labelText, onChange, isInvalid, value))));

export const textDropDown = (labelText) => ((onChange) => ((currentValue) => baseDropDown("", labelText, onChange, currentValue)));

export const smallDropDown = (labelText) => ((onChange) => ((currentValue) => baseDropDown("max-w-xs", labelText, onChange, currentValue)));

export const mediumDropDown = (labelText) => ((onChange) => ((currentValue) => baseDropDown("max-w-lg", labelText, onChange, currentValue)));

export function toggleSwitch(isChecked, onToggled) {
    const backgroundColor = isChecked ? "bg-indigo-600" : "bg-gray-200";
    const translation = isChecked ? "translate-x-5" : "translate-x-0";
    return react.createElement("span", {
        role: "checkbox",
        tabIndex: 0,
        ["aria-checked"]: "false",
        className: backgroundColor + " relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline",
        onClick: (_arg1) => {
            onToggled();
        },
    }, react.createElement("span", {
        ["aria-hidden"]: "true",
        className: translation + " inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200",
    }));
}

export function radioButton(radioLabel, name, isChecked, onToggled) {
    return react.createElement("div", {
        className: "flex items-center",
    }, react.createElement("input", {
        name: name,
        type: "radio",
        className: "form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out",
        checked: isChecked,
        onClick: (_arg1) => {
            onToggled();
        },
    }), react.createElement("label", {
        className: "ml-3",
    }, react.createElement("span", {
        className: subtleLabelClass,
    }, radioLabel)));
}

