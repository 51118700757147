import { ValidationItem, isGreaterThan, validateRequired, createValidatorFor, ValidationState as ValidationState_1 } from "../../../../shared/SimpleValidation.fs.js";
import { empty, ofSeq, ofArray, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { isEmpty, append } from "../../.fable/fable-library.3.0.0/Seq.js";
import { compare } from "../../.fable/fable-library.3.0.0/Util.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { SubmitHeartRateZonesRes, GetHeartRateZonessRes, Item, Msg, Model_get_Empty, Model } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { utcNow } from "../../.fable/fable-library.3.0.0/Date.js";
import { some, bind, defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { UserHeartRateZones } from "../../../../shared/Shared.Models.fs.js";
import { Converters_parseIntFromInput } from "../../Form.fs.js";
import { saveHeartRateZones, getHeartRateZoness } from "./Api.fs.js";
import { successToast, errorToast } from "../../Notifications.fs.js";
import { DashboardPage, Page, modifyLocation } from "../../Router.fs.js";

function crossValidate(zones) {
    return new ValidationState_1(1);
}

const propertyValidator = createValidatorFor(ofArray([validateRequired("Endurance", (v) => v.Endurance, singleton(isGreaterThan(0))), validateRequired("Moderate", (v_1) => v_1.Moderate, singleton(isGreaterThan(0))), validateRequired("Tempo", (v_2) => v_2.Tempo, singleton(isGreaterThan(0))), validateRequired("Threshold", (v_3) => v_3.Threshold, singleton(isGreaterThan(0))), validateRequired("MaximumHeartRate", (v_4) => v_4.MaximumHeartRate, singleton(isGreaterThan(0)))]));

function crossValidations(item) {
    let source2_3, source2_2, source2_1;
    const errors = ofSeq((source2_3 = (source2_2 = (source2_1 = append((compare(item.Moderate, item.Endurance) < 0) ? singleton(new ValidationItem(toText(printf("Endurance must be greater than moderate")), "Moderate", "Endurance\u003eActiveRecovery")) : empty(), []), append((compare(item.Tempo, item.Endurance) < 0) ? singleton(new ValidationItem(toText(printf("Tempo must be greater than endurance")), "Tempo", "Tempo\u003eEndurance")) : empty(), source2_1)), append((compare(item.Threshold, item.Tempo) < 0) ? singleton(new ValidationItem(toText(printf("Lactate threshold must be greater than tempo")), "Threshold", "LactateThreshold\u003eTempo")) : empty(), source2_2)), append((compare(item.MaximumHeartRate, item.Threshold) < 0) ? singleton(new ValidationItem(toText(printf("Anaerobic capacity must be greater than threshold")), "Threshold", "AnaerobicCapacity\u003eVO2Max")) : empty(), source2_3)));
    if (isEmpty(errors)) {
        return new ValidationState_1(1);
    }
    else {
        return new ValidationState_1(0, errors);
    }
}

function zonesValidator(item) {
    const propertyResult = propertyValidator(item);
    if (propertyResult.tag === 0) {
        return propertyResult;
    }
    else {
        return crossValidations(item);
    }
}

function validate(model) {
    return new Model(model.Zones, model.IsLoading, model.IsSaving, model.IsDirty, zonesValidator(model.Zones));
}

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(6))];
}

export function toApiModel(zones) {
    return new UserHeartRateZones(utcNow(), defaultArg(zones.Endurance, 0), defaultArg(zones.Moderate, 0), defaultArg(zones.Tempo, 0), defaultArg(zones.Threshold, 0), defaultArg(zones.MaximumHeartRate, 0));
}

export function update(context, msg, model) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord;
    switch (msg.tag) {
        case 0: {
            return [validate(new Model((inputRecord_1 = model.Zones, new Item(Converters_parseIntFromInput(msg.fields[0]), inputRecord_1.Moderate, inputRecord_1.Tempo, inputRecord_1.Threshold, inputRecord_1.MaximumHeartRate)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 1: {
            return [validate(new Model((inputRecord_2 = model.Zones, new Item(inputRecord_2.Endurance, Converters_parseIntFromInput(msg.fields[0]), inputRecord_2.Tempo, inputRecord_2.Threshold, inputRecord_2.MaximumHeartRate)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 2: {
            return [validate(new Model((inputRecord_3 = model.Zones, new Item(inputRecord_3.Endurance, inputRecord_3.Moderate, Converters_parseIntFromInput(msg.fields[0]), inputRecord_3.Threshold, inputRecord_3.MaximumHeartRate)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 3: {
            return [validate(new Model((inputRecord_4 = model.Zones, new Item(inputRecord_4.Endurance, inputRecord_4.Moderate, inputRecord_4.Tempo, Converters_parseIntFromInput(msg.fields[0]), inputRecord_4.MaximumHeartRate)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 5: {
            const percentageOfFtp = (percent) => bind((maxHeartRate) => (~(~((maxHeartRate * percent) / 100))), model.Zones.MaximumHeartRate);
            return [validate(new Model(new Item(percentageOfFtp(65), percentageOfFtp(80), percentageOfFtp(89), percentageOfFtp(97), model.Zones.MaximumHeartRate), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 6: {
            return [new Model(model.Zones, true, model.IsSaving, model.IsDirty, model.ValidationState), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, getHeartRateZoness, context, (arg0_1) => (new Msg(7, arg0_1)), (arg) => (new Msg(7, new GetHeartRateZonessRes(1, arg))))];
        }
        case 7: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Zones, false, model.IsSaving, model.IsDirty, model.ValidationState), Cmd_none()];
            }
            else {
                return [validate(new Model(result.fields[0], false, model.IsSaving, model.IsDirty, model.ValidationState)), Cmd_none()];
            }
        }
        case 8: {
            return [new Model(model.Zones, model.IsLoading, true, model.IsDirty, model.ValidationState), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, saveHeartRateZones, toApiModel(model.Zones), (arg0_4) => (new Msg(9, arg0_4)), (arg_2) => (new Msg(9, new SubmitHeartRateZonesRes(1, arg_2))))];
        }
        case 9: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.Zones, model.IsLoading, false, model.IsDirty, model.ValidationState), errorToast("Unable to save heart rate zones")];
            }
            else {
                modifyLocation(new Page(9, new DashboardPage(0)));
                return [new Model(model.Zones, model.IsLoading, false, false, model.ValidationState), successToast("Your heart rate zones have been saved")];
            }
        }
        default: {
            return [validate(new Model((inputRecord = model.Zones, new Item(inputRecord.Endurance, inputRecord.Moderate, inputRecord.Tempo, inputRecord.Threshold, Converters_parseIntFromInput(msg.fields[0]))), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
    }
}

