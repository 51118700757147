import { Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { update as update_1, init as init_1 } from "../Index/State.fs.js";
import { Cmd_none, Cmd_map } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(page) {
    const model = new Model(page, Model_get_Empty().IndexModel);
    const patternInput = init_1(page.fields[0][0], page.fields[0][1], page.fields[0][2], page.fields[0][3]);
    return [new Model(model.CurrentPage, patternInput[0]), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
}

export function update(context, msg, model) {
    const matchValue = [msg, model];
    if (matchValue[1].IndexModel != null) {
        const patternInput = update_1(context, matchValue[0].fields[0], matchValue[1].IndexModel);
        return [new Model(model.CurrentPage, patternInput[0]), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
    }
    else {
        console.log(some("[Metrics.Dispatcher.State] Discarded message"));
        return [model, Cmd_none()];
    }
}

