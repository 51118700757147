import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { list_type, union_type, option_type, bool_type, array_type, record_type, int32_type, float64_type, class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { DashboardData$reflection, TrainingLoadSummary$reflection, WeeklyAggregate$reflection, PeakPerformance$reflection, WorkoutSummary$reflection, UserHeartRateZones$reflection, UserPowerZones$reflection, CalendarEvent$reflection, Metric$reflection } from "../../../../shared/Shared.Models.fs.js";
import { ProgrammeId$reflection } from "../../../../shared/Shared.PowerProgramme.fs.js";

export const numberOfAggregateWeeks = 7;

export const numberOfAggregateDays = 7;

export class AggregateMetric extends Record {
    constructor(WeekBeginning, Average, Sum, Count) {
        super();
        this.WeekBeginning = WeekBeginning;
        this.Average = Average;
        this.Sum = Sum;
        this.Count = (Count | 0);
    }
}

export function AggregateMetric$reflection() {
    return record_type("Dashboard.Index.Types.AggregateMetric", [], AggregateMetric, () => [["WeekBeginning", class_type("System.DateTime")], ["Average", float64_type], ["Sum", float64_type], ["Count", int32_type]]);
}

export class Model extends Record {
    constructor(RecentWeight, RecentRestingHeartRate, IsLoading, IsSaving, AggregateWeight, Today, UserPowerZones, HeartRateZones, RecentWorkouts, RecentPowerPeaks, RecentHeartPeaks, RecentDistances, TrainingLoad, TrainsWithPower, ShowWorkoutChooser) {
        super();
        this.RecentWeight = RecentWeight;
        this.RecentRestingHeartRate = RecentRestingHeartRate;
        this.IsLoading = IsLoading;
        this.IsSaving = IsSaving;
        this.AggregateWeight = AggregateWeight;
        this.Today = Today;
        this.UserPowerZones = UserPowerZones;
        this.HeartRateZones = HeartRateZones;
        this.RecentWorkouts = RecentWorkouts;
        this.RecentPowerPeaks = RecentPowerPeaks;
        this.RecentHeartPeaks = RecentHeartPeaks;
        this.RecentDistances = RecentDistances;
        this.TrainingLoad = TrainingLoad;
        this.TrainsWithPower = TrainsWithPower;
        this.ShowWorkoutChooser = ShowWorkoutChooser;
    }
}

export function Model$reflection() {
    return record_type("Dashboard.Index.Types.Model", [], Model, () => [["RecentWeight", array_type(Metric$reflection())], ["RecentRestingHeartRate", array_type(Metric$reflection())], ["IsLoading", bool_type], ["IsSaving", bool_type], ["AggregateWeight", array_type(AggregateMetric$reflection())], ["Today", array_type(CalendarEvent$reflection())], ["UserPowerZones", option_type(UserPowerZones$reflection())], ["HeartRateZones", option_type(UserHeartRateZones$reflection())], ["RecentWorkouts", array_type(WorkoutSummary$reflection())], ["RecentPowerPeaks", array_type(PeakPerformance$reflection())], ["RecentHeartPeaks", array_type(PeakPerformance$reflection())], ["RecentDistances", array_type(WeeklyAggregate$reflection())], ["TrainingLoad", option_type(array_type(TrainingLoadSummary$reflection()))], ["TrainsWithPower", bool_type], ["ShowWorkoutChooser", bool_type]]);
}

export function Model_get_Empty() {
    return new Model(new Array(0), new Array(0), true, false, new Array(0), new Array(0), void 0, void 0, new Array(0), new Array(0), new Array(0), new Array(0), void 0, false, false);
}

export class GetDashboardsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetDashboardsRes$reflection() {
    return union_type("Dashboard.Index.Types.GetDashboardsRes", [], GetDashboardsRes, () => [[["Item", DashboardData$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class PlanWorkoutTodayRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function PlanWorkoutTodayRes$reflection() {
    return union_type("Dashboard.Index.Types.PlanWorkoutTodayRes", [], PlanWorkoutTodayRes, () => [[["Item", list_type(CalendarEvent$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PlanWorkoutToday", "PlanWorkoutTodayResult", "GetDashboards", "GetDashboardsResult", "ShowWorkoutChooser", "HideWorkoutChooser"];
    }
}

export function Msg$reflection() {
    return union_type("Dashboard.Index.Types.Msg", [], Msg, () => [[["Item", ProgrammeId$reflection()]], [["Item", PlanWorkoutTodayRes$reflection()]], [], [["Item", GetDashboardsRes$reflection()]], [], []]);
}

