import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { bool_type, string_type, list_type, float64_type, class_type, record_type, int32_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { CardioSegment$reflection } from "../../shared/Shared.Models.fs.js";
import { averageBy, truncate, skip, max as max_1, isEmpty, rangeNumber, minBy, maxBy, delay, filter, sumBy, mapIndexed, map, collect, head } from "./.fable/fable-library.3.0.0/Seq.js";
import { empty, item, length, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { subtract, compare, addSeconds } from "./.fable/fable-library.3.0.0/Date.js";
import { uncurry, round, numberHash, comparePrimitives } from "./.fable/fable-library.3.0.0/Util.js";
import { groupBy } from "./.fable/fable-library.3.0.0/Map.js";
import { totalSeconds as totalSeconds_1 } from "./.fable/fable-library.3.0.0/TimeSpan.js";
import * as react from "react";
import { join, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { attachWindowEvent } from "./BrowserHelpers.fs.js";
import { tinyLabel, label as label_1 } from "./Typography.fs.js";

export class LapDataPoint extends Record {
    constructor(LapIndex, DataPoint) {
        super();
        this.LapIndex = (LapIndex | 0);
        this.DataPoint = DataPoint;
    }
}

export function LapDataPoint$reflection() {
    return record_type("CardioSegmentGraph.LapDataPoint", [], LapDataPoint, () => [["LapIndex", int32_type], ["DataPoint", CardioSegment$reflection()]]);
}

export class LapGraphDataPoint extends Record {
    constructor(LapIndex, DateTime, Value) {
        super();
        this.LapIndex = (LapIndex | 0);
        this.DateTime = DateTime;
        this.Value = Value;
    }
}

export function LapGraphDataPoint$reflection() {
    return record_type("CardioSegmentGraph.LapGraphDataPoint", [], LapGraphDataPoint, () => [["LapIndex", int32_type], ["DateTime", class_type("System.DateTime")], ["Value", float64_type]]);
}

export class ICardioSegmentGraphProps extends Record {
    constructor(Laps, Color, AverageColor, ShowAsInt, Title) {
        super();
        this.Laps = Laps;
        this.Color = Color;
        this.AverageColor = AverageColor;
        this.ShowAsInt = ShowAsInt;
        this.Title = Title;
    }
}

export function ICardioSegmentGraphProps$reflection() {
    return record_type("CardioSegmentGraph.ICardioSegmentGraphProps", [], ICardioSegmentGraphProps, () => [["Laps", list_type(LapGraphDataPoint$reflection())], ["Color", string_type], ["AverageColor", string_type], ["ShowAsInt", bool_type], ["Title", string_type]]);
}

export function createSampledLaps(maximumSize, valueAccessor, laps) {
    const routeStartTime = head(laps).Metrics.RecordedAtUtc;
    const dataPoints = ofSeq(collect((tupledArg) => map((dp) => (new LapDataPoint(tupledArg[0], dp)), tupledArg[1].DataPoints), mapIndexed((i, l) => [i, l], laps)));
    const secondsPerPixel = sumBy((l_2) => l_2.Metrics.TimeSeconds, laps, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) / maximumSize;
    return ofSeq(map((dp_1) => dp_1, filter((option_2) => (option_2 != null), delay(() => map((i_1) => {
        const startDateTime = addSeconds(routeStartTime, i_1 * secondsPerPixel);
        const endDateTime = addSeconds(routeStartTime, (i_1 + 1) * secondsPerPixel);
        const dataPoints_1 = Array.from(filter((ldp_1) => (valueAccessor(ldp_1.DataPoint) != null), filter((ldp) => {
            if (compare(ldp.DataPoint.Time, startDateTime) >= 0) {
                return compare(ldp.DataPoint.Time, endDateTime) < 0;
            }
            else {
                return false;
            }
        }, dataPoints)));
        if (dataPoints_1.length === 0) {
            return void 0;
        }
        else {
            const speed = maxBy((ldp_3) => valueAccessor(ldp_3.DataPoint), filter((ldp_2) => (valueAccessor(ldp_2.DataPoint) != null), dataPoints_1), {
                Compare: comparePrimitives,
            });
            return new LapGraphDataPoint(minBy((ldp_4) => ldp_4.LapIndex, dataPoints_1, {
                Compare: comparePrimitives,
            }).LapIndex, startDateTime, valueAccessor(speed.DataPoint));
        }
    }, rangeNumber(0, 1, maximumSize - 1))))));
}

export function createSampledLapsFast(maximumSize, valueAccessor, laps) {
    const routeStartTime = head(laps).Metrics.RecordedAtUtc;
    const dataPoints = ofSeq(collect((tupledArg) => map((dp) => (new LapDataPoint(tupledArg[0], dp)), tupledArg[1].DataPoints), mapIndexed((i, l) => [i, l], laps)));
    const secondsPerPixel = sumBy((l_2) => l_2.Metrics.TimeSeconds, laps, {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) / maximumSize;
    return ofSeq(map((tupledArg_1) => {
        const groupedDataPoints = tupledArg_1[1];
        const someValues = Array.from(filter((ldp) => (valueAccessor(ldp.DataPoint) != null), groupedDataPoints));
        const maxValue = isEmpty(someValues) ? 0 : max_1(map((ldp_1) => valueAccessor(ldp_1.DataPoint), someValues), {
            Compare: comparePrimitives,
        });
        return new LapGraphDataPoint(minBy((ldp_2) => ldp_2.LapIndex, groupedDataPoints, {
            Compare: comparePrimitives,
        }).LapIndex, head(groupedDataPoints).DataPoint.Time, maxValue);
    }, groupBy((dp_1) => {
        let copyOfStruct;
        return ~(~Math.floor((copyOfStruct = subtract(dp_1.DataPoint.Time, routeStartTime), totalSeconds_1(copyOfStruct)) / secondsPerPixel));
    }, dataPoints, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: numberHash,
    })));
}

function createGraph(width, height, color, averageColor, dataPoints) {
    let sampledDataPoints;
    if (length(dataPoints) >= width) {
        const scale = length(dataPoints) / width;
        sampledDataPoints = ofSeq(delay(() => map((i) => {
            let source_1;
            const startOfRange = (~(~Math.floor(i * scale))) | 0;
            const sampledValue = maxBy((v) => v.Value, (source_1 = skip(startOfRange, dataPoints), truncate(((~(~Math.ceil(i * scale))) - startOfRange) + 1, source_1)), {
                Compare: comparePrimitives,
            });
            const inputRecord = item(startOfRange, dataPoints);
            return new LapGraphDataPoint(inputRecord.LapIndex, inputRecord.DateTime, sampledValue.Value);
        }, rangeNumber(0, 1, width - 1))));
    }
    else {
        sampledDataPoints = dataPoints;
    }
    if (isEmpty(dataPoints)) {
        return [react.createElement(react.Fragment, {}), 0, 0, 0];
    }
    else {
        const maxValue = maxBy((ldp) => ldp.Value, sampledDataPoints, {
            Compare: comparePrimitives,
        }).Value;
        const minValue = minBy((ldp_1) => ldp_1.Value, sampledDataPoints, {
            Compare: comparePrimitives,
        }).Value;
        const rangeValue = maxValue - minValue;
        const averageValue = averageBy((ldp_2) => ldp_2.Value, sampledDataPoints, {
            GetZero: () => 0,
            Add: (x_4, y_3) => (x_4 + y_3),
            DivideByInt: (x_3, i_1) => (x_3 / i_1),
        });
        const viewBoxString = toText(printf("0 0 %d %d"))(width)(height);
        const lineHeight = (value_7) => (height - (~(~(((value_7 - minValue) / rangeValue) * height))));
        const xPixelScale = width / length(sampledDataPoints);
        const fullPath = join("", mapIndexed((i_2, dp) => {
            const operation = (i_2 === 0) ? "M" : "L";
            const lineHeightValue = lineHeight(dp.Value) | 0;
            const px = (~(~round(i_2 * xPixelScale))) | 0;
            return toText(printf("%s%d %d "))(operation)(px)(lineHeightValue);
        }, sampledDataPoints));
        let averagePath;
        const averageY = lineHeight(averageValue) | 0;
        averagePath = toText(printf("M0 %d L%d %d"))(averageY)(width)(averageY);
        return [react.createElement("svg", {
            version: "1.1",
            viewBox: viewBoxString,
            height: height,
        }, react.createElement("path", {
            d: averagePath,
            fill: "transparent",
            stroke: averageColor,
            strokeDasharray: "4 2",
        }), react.createElement("path", {
            d: fullPath,
            fill: "transparent",
            stroke: color,
        })), maxValue, averageValue, minValue];
    }
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const containerWidth = react.useState(0);
    const containerRef = react.useRef(void 0);
    react.useEffect(() => {
    const disp = (() => {
        let timeoutId = void 0;
        const setWidth = () => {
            const matchValue = containerRef.current;
            if (matchValue == null) {
            }
            else {
                const width = (~(~matchValue.clientWidth)) | 0;
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout((_arg1) => {
                    containerWidth[1](((s) => width));
                }, 30, empty());
            }
        };
        setWidth();
        return attachWindowEvent((_arg2) => {
            setWidth();
        }, window, "resize");
    })();
    return () => disp.Dispose();
    }, []);
    let patternInput;
    const matchValue_1 = containerRef.current;
    patternInput = ((matchValue_1 == null) ? [react.createElement("div", {}), 0, 0, 0] : createGraph(~(~matchValue_1.clientWidth), 100, props.Color, props.AverageColor, props.Laps));
    const numericLabelText = (label, value_2) => {
        if (props.ShowAsInt) {
            return toText(printf("%s %.0f"))(label)(value_2);
        }
        else {
            return toText(printf("%s %.1f"))(label)(value_2);
        }
    };
    return react.createElement("div", {
        className: "grid grid-cols-12 gap-2",
    }, react.createElement("div", {
        className: "flex flex-col justify-center items-center border-gray-200 border-r",
    }, label_1(props.Title), tinyLabel(numericLabelText("Max", patternInput[1])), tinyLabel(numericLabelText("Avg", patternInput[2])), tinyLabel(numericLabelText("Min", patternInput[3]))), react.createElement("div", {
        className: "col-span-11",
    }, react.createElement("div", {
        className: "bg-white mb-6",
        style: {
            height: 100,
        },
        ref: containerRef,
    }, patternInput[0])));
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioSegmentGraph.fs", 153);

