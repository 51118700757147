import { Union } from "./.fable/fable-library.3.0.0/Types.js";
import { union_type, bool_type, lambda_type, unit_type, class_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import * as react from "react";
import { skip, tryHead, mapIndexed, isEmpty, singleton, append, delay, map } from "./.fable/fable-library.3.0.0/Seq.js";
import { subtleLabel } from "./Typography.fs.js";
import { ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { parse } from "./.fable/fable-library.3.0.0/Int32.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { uncurry } from "./.fable/fable-library.3.0.0/Util.js";

export class HeaderButton extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Primary", "Secondary"];
    }
}

export function HeaderButton$reflection() {
    return union_type("Layout.HeaderButton", [], HeaderButton, () => [[["Item1", class_type("Fable.React.ReactElement")], ["Item2", lambda_type(unit_type, unit_type)], ["Item3", bool_type]], [["Item1", class_type("Fable.React.ReactElement")], ["Item2", lambda_type(unit_type, unit_type)], ["Item3", bool_type]]]);
}

export function createHeaderLinkButton(isLoading, buttonContent, url, disabled) {
    const style = disabled ? "cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-300 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("span", {
        className: "shadow-sm rounded-md",
    }, react.createElement("a", {
        disabled: isLoading,
        type: "button",
        className: style,
        href: url,
        disabled: disabled,
    }, buttonContent));
}

export function createHeaderButton(isLoading, buttonDefinition) {
    if (buttonDefinition.tag === 1) {
        return react.createElement("span", {
            className: "shadow-sm rounded-md",
        }, react.createElement("button", {
            disabled: isLoading,
            type: "button",
            className: "inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out",
            onClick: (_arg2) => {
                buttonDefinition.fields[1]();
            },
            disabled: buttonDefinition.fields[2],
        }, buttonDefinition.fields[0]));
    }
    else {
        const disabled = buttonDefinition.fields[2];
        const style = disabled ? "inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-300 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
        return react.createElement("span", {
            className: "shadow-sm rounded-md",
        }, react.createElement("button", {
            disabled: isLoading,
            type: "button",
            className: style,
            onClick: (_arg1) => {
                buttonDefinition.fields[1]();
            },
            disabled: disabled,
        }, buttonDefinition.fields[0]));
    }
}

export function buttonBar(isLoading, buttons) {
    return map((buttonDefinition) => createHeaderButton(isLoading, buttonDefinition), buttons);
}

function buttonHeaderBar(icon, title, buttons, isLoading) {
    return react.createElement("div", {
        className: "md:flex md:items-center md:justify-between",
    }, react.createElement("div", {
        className: "flex-1 min-w-0 flex flex-row justify-start items-center",
    }, icon, react.createElement("h2", {
        className: "text-2xl font-bold leading-7 text-primary-header sm:text-3xl sm:leading-9 sm:truncate",
    }, react.createElement("span", {
        className: "text-gray-700",
    }, title))), react.createElement("div", {
        className: "mt-4 flex md:mt-0 md:ml-4",
    }, ...buttonBar(isLoading, buttons)));
}

function headerBar(title, optionalSubtitle, content, isLoading) {
    return react.createElement("div", {
        className: "md:flex md:items-center md:justify-between",
    }, react.createElement("div", {
        className: "flex-1 min-w-0",
    }, react.createElement("h2", {
        className: "text-2xl font-bold leading-7 text-primary-header sm:text-3xl sm:leading-9 sm:truncate",
    }, react.createElement("span", {
        className: "text-gray-700",
    }, title)), (optionalSubtitle == null) ? react.createElement(react.Fragment, {}) : react.createElement("div", {
        className: "absolute z-40 -mt-1",
    }, subtleLabel(optionalSubtitle))), react.createElement("div", {
        className: "mt-4 flex md:mt-0 md:ml-4",
    }, ...content));
}

export function pageWithIcon(icon, title, isLoading, buttons, contents) {
    return react.createElement("div", {}, ...ofSeq(delay(() => append(singleton(react.createElement("header", {
        className: "bg-white shadow",
        style: {
            height: 86,
        },
    }, react.createElement("div", {
        className: "mx-auto py-6 px-4 sm:px-6 lg:px-8",
    }, buttonHeaderBar(icon, title, buttons, isLoading)))), delay(() => append(isLoading ? singleton(react.createElement("div", {
        className: "slidingLoader",
    })) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(react.createElement("main", {}, react.createElement("div", {
        className: "mx-auto py-6 sm:px-6 lg:px-8",
    }, react.createElement("div", {
        className: "px-4 sm:px-0",
    }, ...ofSeq(delay(() => (isLoading ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("div", {
        className: "",
    }, ...contents))))))))))))))));
}

export function page(title, isLoading, buttons, contents) {
    return pageWithIcon(react.createElement(react.Fragment, {}), title, isLoading, buttons, contents);
}

export const overlayPanel = react.createElement("div", {
    className: "absolute bg-white-600 opacity-50 z-1200",
    style: {
        width: "100%",
        height: "100%",
    },
});

export function toolbarPage(title, optionalSubtitle, isLoading, hideWhenLoading, toolbarContent, contents) {
    return react.createElement("div", {}, ...ofSeq(delay(() => append(singleton(react.createElement("header", {
        className: "bg-white shadow",
    }, react.createElement("div", {
        className: "mx-auto py-6 px-4 sm:px-6 lg:px-8",
    }, headerBar(title, optionalSubtitle, toolbarContent, isLoading)))), delay(() => append(isLoading ? singleton(react.createElement("div", {
        className: "slidingLoader",
    })) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(react.createElement("main", {
        disabled: true,
    }, react.createElement("div", {
        className: "mx-auto py-6 sm:px-6 lg:px-8",
    }, react.createElement("div", {
        className: "px-4 sm:px-0",
    }, ...ofSeq(delay(() => ((isLoading ? hideWhenLoading : false) ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("div", {
        className: isLoading ? "pointer-events-none opacity-50 transition-opacity" : "transition-opacity",
    }, ...contents))))))))))))))));
}

export function analysisPage(isLoading, hidePage, leftTools, rightTools, contents) {
    return react.createElement("div", {}, ...ofSeq(delay(() => append(isLoading ? singleton(react.createElement("div", {
        className: "slidingLoader",
    })) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(react.createElement("main", {}, react.createElement("div", {
        className: "mx-auto py-6 sm:px-6 lg:px-8",
    }, react.createElement("div", {
        className: "px-4 sm:px-0",
    }, ...ofSeq(delay(() => ((isLoading ? hidePage : false) ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("div", {
        className: "",
    }, ...contents))))))))))))));
}

export function panel(contents) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white px-6 py-4",
    }, ...contents)));
}

export function noHorizontalPadPanel(contents) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 overflow-x-auto",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white",
    }, ...contents)));
}

export function spacedPanel(contents) {
    return react.createElement("div", {
        className: "mb-6",
    }, panel(contents));
}

export function tightPanel(contents) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white",
    }, ...contents)));
}

export function spacedTightPanel(contents) {
    return react.createElement("div", {
        className: "mb-6",
    }, tightPanel(contents));
}

export function inlineTabs(tabLabels, selectedTabIndex, onSelected) {
    if (isEmpty(tabLabels)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        return react.createElement("div", {}, react.createElement("div", {
            className: "sm:hidden",
        }, react.createElement("select", {
            value: selectedTabIndex,
            onChange: (ev) => {
                onSelected(parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32));
            },
        }, ...mapIndexed((i, label) => react.createElement("option", {
            value: i,
        }, label), tabLabels))), react.createElement("div", {
            className: "hidden sm:block",
        }, react.createElement("div", {
            className: "",
        }, react.createElement("nav", {
            className: "flex",
        }, ...mapIndexed((i_1, label_1) => react.createElement("button", {
            onClick: (_arg1) => {
                onSelected(i_1);
            },
            className: ((i_1 === selectedTabIndex) ? "whitespace-no-wrap py-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700" : "whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300") + ((i_1 === 0) ? "" : " ml-8"),
        }, label_1), tabLabels)))));
    }
}

export function tabs(tabLabels, selectedTabIndex, onSelected) {
    if (isEmpty(tabLabels)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        return react.createElement("div", {}, react.createElement("div", {
            className: "sm:hidden",
        }, react.createElement("select", {
            value: selectedTabIndex,
            onChange: (ev) => {
                onSelected(parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32));
            },
        }, ...mapIndexed((i, label) => react.createElement("option", {
            value: i,
        }, label), tabLabels))), react.createElement("div", {
            className: "hidden sm:block",
        }, react.createElement("div", {
            className: "border-b border-gray-200",
        }, react.createElement("nav", {
            className: "-mb-px flex",
        }, ...mapIndexed((i_1, label_1) => react.createElement("button", {
            onClick: (_arg1) => {
                onSelected(i_1);
            },
            className: ((i_1 === selectedTabIndex) ? "whitespace-no-wrap py-4 px-1 border-b-2 border-indigo-500 font-medium text-sm leading-5 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700" : "whitespace-no-wrap py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300") + ((i_1 === 0) ? "" : " ml-8"),
        }, label_1), tabLabels)))));
    }
}

export function pills(tabLabels, selectedTabIndex, onSelected) {
    return react.createElement("div", {}, react.createElement("div", {
        className: "sm:hidden",
    }, react.createElement("select", {
        value: selectedTabIndex,
        onChange: (ev) => {
            onSelected(parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32));
        },
    }, ...mapIndexed((i, label) => react.createElement("option", {
        value: i,
    }, label), tabLabels))), react.createElement("div", {
        className: "hidden sm:block",
    }, react.createElement("nav", {
        className: "flex",
    }, ...mapIndexed((i_1, label_1) => react.createElement("button", {
        onClick: (_arg1) => {
            onSelected(i_1);
        },
        className: ((i_1 === selectedTabIndex) ? "px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-700 bg-gray-200 focus:outline-none focus:text-gray-700 focus:bg-gray-300" : "px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700 focus:bg-gray-100") + ((i_1 === 0) ? "" : " ml-4"),
    }, label_1), tabLabels))));
}

export function tabsWithPages(tabLabels, pages) {
    return FunctionComponent_Of_Z5A158BBF((_arg1) => {
        const currentTab = react.useState(0);
        return react.createElement(react.Fragment, {}, tabs(tabLabels, currentTab[0], (arg00) => {
            currentTab[1](arg00);
        }), react.createElement("div", {
            className: "mt-4",
        }, ...ofSeq(delay(() => {
            const matchValue = tryHead(skip(currentTab[0], pages));
            return (matchValue == null) ? singleton(react.createElement(react.Fragment, {})) : singleton(matchValue());
        }))));
    }, void 0, uncurry(2, void 0), void 0, "tabsWithPages", "/home/runner/work/strengthPlus/strengthPlus/client/src/Layout.fs", 240);
}

export function transparentOverlay(onClick) {
    return react.createElement("div", {
        className: "fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-1200",
    }, react.createElement("div", {
        className: "fixed inset-0 transition-opacity",
    }, react.createElement("div", {
        className: "absolute inset-0 bg-white opacity-0",
        onClick: (_arg1) => {
            onClick();
        },
    })));
}

