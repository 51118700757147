import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { ActivityMaximums$reflection, TrainingLogSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { union_type, record_type, bool_type, option_type, class_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { System_DateTime__DateTime_WeekBeginningMonday } from "../../../../shared/Shared.fs.js";
import { addDays, date, now } from "../../.fable/fable-library.3.0.0/Date.js";

export const pageSizeWeeks = 8;

export class Model extends Record {
    constructor(Items, FirstRowStartDate, LastRowStartDate, Maximums, IsLoading) {
        super();
        this.Items = Items;
        this.FirstRowStartDate = FirstRowStartDate;
        this.LastRowStartDate = LastRowStartDate;
        this.Maximums = Maximums;
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("WeeklyTrainingLog.Bubble.Types.Model", [], Model, () => [["Items", array_type(TrainingLogSummary$reflection())], ["FirstRowStartDate", class_type("System.DateTime")], ["LastRowStartDate", class_type("System.DateTime")], ["Maximums", option_type(ActivityMaximums$reflection())], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    let copyOfStruct, copyOfStruct_2, copyOfStruct_1;
    return new Model(new Array(0), (copyOfStruct = System_DateTime__DateTime_WeekBeginningMonday(now()), date(copyOfStruct)), (copyOfStruct_2 = (copyOfStruct_1 = System_DateTime__DateTime_WeekBeginningMonday(now()), addDays(copyOfStruct_1, -7 * pageSizeWeeks)), date(copyOfStruct_2)), void 0, true);
}

export class GetPageRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPageRes$reflection() {
    return union_type("WeeklyTrainingLog.Bubble.Types.GetPageRes", [], GetPageRes, () => [[["Item1", array_type(TrainingLogSummary$reflection())], ["Item2", ActivityMaximums$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetNextPage", "GetPage", "GetPageResult"];
    }
}

export function Msg$reflection() {
    return union_type("WeeklyTrainingLog.Bubble.Types.Msg", [], Msg, () => [[], [], [["Item", GetPageRes$reflection()]]]);
}

