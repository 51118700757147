import { IFitnessProps, root as root_1 } from "../Fitness/View.fs.js";
import { Msg } from "./Types.fs.js";
import { IRollingAggregateProps, root as root_2 } from "../RollingAggregates/View.fs.js";
import { IPeakPerformancesProps, root as root_3 } from "../PeakPerformances/View.fs.js";
import { IPeakHistoryProps, root as root_4 } from "../PeakHistory/View.fs.js";
import { IMetricHistoryProps, root as root_5 } from "../MetricHistory/View.fs.js";
import { root as root_6 } from "../CompareRides/View.fs.js";
import { ICompetitivenessProps, root as root_7 } from "../Competitiveness/View.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import * as react from "react";
import { equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { Page, href, AnalysisPage } from "../../Router.fs.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { Analysis_compareActivities, Analysis_metricHistory, Analysis_peakHistory, Analysis_peakPerformances, IconSize, Analysis_fitness } from "../../Icons.fs.js";

export function root(context, model, dispatch) {
    let content;
    let pattern_matching_result;
    if (model.CurrentPage.tag === 8) {
        if (model.FitnessModel != null) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 4) {
        if (model.RollingAggregatesModel != null) {
            pattern_matching_result = 2;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 5) {
        if (model.RollingAggregatesModel != null) {
            pattern_matching_result = 3;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 1) {
        if (model.PeakPerformancesModel != null) {
            pattern_matching_result = 4;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 3) {
        if (model.PeakHistoryModel != null) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 7) {
        if (model.MetricHistoryModel != null) {
            pattern_matching_result = 6;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 0) {
        if (model.IndexModel != null) {
            pattern_matching_result = 7;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.CurrentPage.tag === 6) {
        if (model.CompetitivenessModel != null) {
            pattern_matching_result = 8;
        }
        else {
            pattern_matching_result = 9;
        }
    }
    else if (model.FitnessModel != null) {
        pattern_matching_result = 0;
    }
    else {
        pattern_matching_result = 9;
    }
    switch (pattern_matching_result) {
        case 0: {
            content = root_1(new IFitnessProps(context, model.FitnessModel, (arg) => {
                dispatch(new Msg(5, arg));
            }));
            break;
        }
        case 1: {
            content = root_1(new IFitnessProps(context, model.FitnessModel, (arg_1) => {
                dispatch(new Msg(5, arg_1));
            }));
            break;
        }
        case 2: {
            content = root_2(new IRollingAggregateProps(context, model.RollingAggregatesModel, (arg_2) => {
                dispatch(new Msg(6, arg_2));
            }));
            break;
        }
        case 3: {
            content = root_2(new IRollingAggregateProps(context, model.RollingAggregatesModel, (arg_3) => {
                dispatch(new Msg(6, arg_3));
            }));
            break;
        }
        case 4: {
            content = root_3(new IPeakPerformancesProps(context, model.PeakPerformancesModel, (arg_4) => {
                dispatch(new Msg(1, arg_4));
            }));
            break;
        }
        case 5: {
            content = root_4(new IPeakHistoryProps(context, model.PeakHistoryModel, (arg_5) => {
                dispatch(new Msg(2, arg_5));
            }));
            break;
        }
        case 6: {
            content = root_5(new IMetricHistoryProps(context, model.MetricHistoryModel, (arg_6) => {
                dispatch(new Msg(4, arg_6));
            }));
            break;
        }
        case 7: {
            content = root_6(context, model.IndexModel, (arg_7) => {
                dispatch(new Msg(0, arg_7));
            });
            break;
        }
        case 8: {
            content = root_7(new ICompetitivenessProps(context, model.CompetitivenessModel, (arg_8) => {
                dispatch(new Msg(3, arg_8));
            }));
            break;
        }
        case 9: {
            console.log(some("[Analysis.Dispatcher.View] Page not found"));
            content = react.createElement("div", {}, "Page not found");
            break;
        }
    }
    const classForRoute = (route) => {
        if (equalsSafe(equalsSafe(model.CurrentPage, new AnalysisPage(2)) ? (new AnalysisPage(8)) : model.CurrentPage, route)) {
            return "group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-900 rounded-md bg-gray-100 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150";
        }
        else {
            return "group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:bg-gray-100 transition ease-in-out duration-150";
        }
    };
    const colorForRoute = (route_1) => {
        if (equalsSafe(equalsSafe(model.CurrentPage, new AnalysisPage(2)) ? (new AnalysisPage(8)) : model.CurrentPage, route_1)) {
            return "text-red-400 group-hover:text-red-500 group-focus:text-red-500";
        }
        else {
            return "text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500";
        }
    };
    return react.createElement("div", {
        className: "flex overflow-hidden",
        style: {
            height: "calc(100vh - 64px)",
        },
    }, react.createElement("div", {
        className: "hidden md:flex md:flex-shrink-0",
    }, react.createElement("div", {
        className: "flex flex-col w-64 border-r border-gray-200 bg-white",
    }, react.createElement("div", {
        className: "h-0 flex-1 flex flex-col pb-4 overflow-y-auto",
    }, react.createElement("nav", {
        className: "mt-6 flex-1 px-2 bg-white",
    }, react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(8))), new HTMLAttr(65, classForRoute(new AnalysisPage(8)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(8)),
    }, Analysis_fitness(new IconSize(2))), "Fitness"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(1))), new HTMLAttr(65, classForRoute(new AnalysisPage(1)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(1)),
    }, Analysis_peakPerformances(new IconSize(2))), "Peak Performances"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(3))), new HTMLAttr(65, classForRoute(new AnalysisPage(3)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(3)),
    }, Analysis_peakHistory(new IconSize(2))), "Peak History"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(4))), new HTMLAttr(65, classForRoute(new AnalysisPage(4)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(4)),
    }, Analysis_peakHistory(new IconSize(2))), "Rolling Averages"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(5))), new HTMLAttr(65, classForRoute(new AnalysisPage(5)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(5)),
    }, Analysis_peakHistory(new IconSize(2))), "Rolling Totals"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(7))), new HTMLAttr(65, classForRoute(new AnalysisPage(7)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150 " + colorForRoute(new AnalysisPage(7)),
    }, Analysis_metricHistory(new IconSize(2))), "Metric History"), react.createElement("a", keyValueList([href(new Page(4, new AnalysisPage(0))), new HTMLAttr(65, classForRoute(new AnalysisPage(0)) + " mt-1")], 1), react.createElement("div", {
        className: "mr-3 h-6 w-6 transition ease-in-out duration-150" + colorForRoute(new AnalysisPage(0)),
    }, Analysis_compareActivities(new IconSize(2))), "Compare Activities"))))), react.createElement("div", {
        className: "flex flex-col w-0 flex-1 overflow-auto",
    }, react.createElement("div", {
        className: "md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3",
    }, react.createElement("button", {
        className: "-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150",
        ["aria-label"]: "Open sidebar",
    }, react.createElement("svg", {
        className: "h-6 w-6",
        stroke: "currentColor",
        fill: "none",
        viewbox: "0 0 24 24",
    }, react.createElement("path", {
        ["stroke-linecap"]: "round",
        ["stroke-linejoin"]: "round",
        ["stroke-width"]: "2",
        d: "M4 6h16M4 12h16M4 18h16",
    })))), content));
}

