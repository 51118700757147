import { Sort$1$reflection, MetricSortColumn$reflection, MetricSortColumn, Sort$1 } from "../../../../shared/Shared.Api.fs.js";
import { Page$1$reflection, Metric$reflection, MetricType } from "../../../../shared/Shared.Models.fs.js";
import { filter, map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, record_type, option_type, float64_type, class_type, int32_type, bool_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { now } from "../../.fable/fable-library.3.0.0/Date.js";

export function sortFromIndexes(sortOrderIndex, sortColumnIndex) {
    return ((sortOrderIndex === 0) ? ((arg0) => (new Sort$1(0, arg0))) : ((arg0_1) => (new Sort$1(1, arg0_1))))((sortColumnIndex === 0) ? (new MetricSortColumn(2)) : ((sortColumnIndex === 1) ? (new MetricSortColumn(0)) : ((sortColumnIndex === 2) ? (new MetricSortColumn(1)) : (new MetricSortColumn(2)))));
}

export function sortColumnFromType(sort) {
    const sortColumn = (column) => {
        switch (column.tag) {
            case 0: {
                return 1;
            }
            case 1: {
                return 2;
            }
            default: {
                return 0;
            }
        }
    };
    if (sort.tag === 1) {
        return sortColumn(sort.fields[0]) | 0;
    }
    else {
        return sortColumn(sort.fields[0]) | 0;
    }
}

export function sortOrderFromType(sort) {
    if (sort.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export const metricTypeFilters = [[void 0, "All"], [new MetricType(0), "Weight"], [new MetricType(2), "Calories"], [new MetricType(4), "Resting heart rate"], [new MetricType(1), "Target weight"], [new MetricType(3), "Target calories"]];

export const addModalMetricTypes = Array.from(map((tupledArg_1) => [tupledArg_1[0], tupledArg_1[1]], filter((tupledArg) => (tupledArg[0] != null), metricTypeFilters)));

export class Model extends Record {
    constructor(Items, IsLoading, Sort, Page, NumberOfPages, NumberOfRows, PageSize, MetricTypeFilterIndex, IsSaving, AddModalIsVisible, AddModalMetricTypeIndex, AddModalDateTime, AddModalValue, DeleteModalIsVisible, DeleteModalMetric) {
        super();
        this.Items = Items;
        this.IsLoading = IsLoading;
        this.Sort = Sort;
        this.Page = (Page | 0);
        this.NumberOfPages = (NumberOfPages | 0);
        this.NumberOfRows = (NumberOfRows | 0);
        this.PageSize = (PageSize | 0);
        this.MetricTypeFilterIndex = (MetricTypeFilterIndex | 0);
        this.IsSaving = IsSaving;
        this.AddModalIsVisible = AddModalIsVisible;
        this.AddModalMetricTypeIndex = (AddModalMetricTypeIndex | 0);
        this.AddModalDateTime = AddModalDateTime;
        this.AddModalValue = AddModalValue;
        this.DeleteModalIsVisible = DeleteModalIsVisible;
        this.DeleteModalMetric = DeleteModalMetric;
    }
}

export function Model$reflection() {
    return record_type("Metrics.Index.Types.Model", [], Model, () => [["Items", array_type(Metric$reflection())], ["IsLoading", bool_type], ["Sort", Sort$1$reflection(MetricSortColumn$reflection())], ["Page", int32_type], ["NumberOfPages", int32_type], ["NumberOfRows", int32_type], ["PageSize", int32_type], ["MetricTypeFilterIndex", int32_type], ["IsSaving", bool_type], ["AddModalIsVisible", bool_type], ["AddModalMetricTypeIndex", int32_type], ["AddModalDateTime", class_type("System.DateTime")], ["AddModalValue", option_type(float64_type)], ["DeleteModalIsVisible", bool_type], ["DeleteModalMetric", option_type(Metric$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(new Array(0), true, new Sort$1(1, new MetricSortColumn(2)), 0, 0, 0, 0, 0, false, false, 0, now(), void 0, false, void 0);
}

export class GetMetricsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetMetricsRes$reflection() {
    return union_type("Metrics.Index.Types.GetMetricsRes", [], GetMetricsRes, () => [[["Item", Page$1$reflection(Metric$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class AddMetricRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function AddMetricRes$reflection() {
    return union_type("Metrics.Index.Types.AddMetricRes", [], AddMetricRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class DeleteMetricRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function DeleteMetricRes$reflection() {
    return union_type("Metrics.Index.Types.DeleteMetricRes", [], DeleteMetricRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetMetrics", "GetMetricsResult", "SortBy", "GotoPage", "SetFilterIndex", "AddModalShow", "AddModalHide", "AddModalChangeMetricTypeIndex", "AddModalChangeDateTime", "AddModalChangeValue", "AddMetric", "AddMetricResult", "DeleteModalShow", "DeleteModalHide", "DeleteMetric", "DeleteMetricResult"];
    }
}

export function Msg$reflection() {
    return union_type("Metrics.Index.Types.Msg", [], Msg, () => [[], [["Item", GetMetricsRes$reflection()]], [["Item", MetricSortColumn$reflection()]], [["Item", int32_type]], [["Item", int32_type]], [], [], [["Item", int32_type]], [["Item", class_type("System.DateTime")]], [["Item", option_type(float64_type)]], [], [["Item", AddMetricRes$reflection()]], [["Item", Metric$reflection()]], [], [], [["Item", DeleteMetricRes$reflection()]]]);
}

