import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { addSeconds, addDays } from "../../.fable/fable-library.3.0.0/Date.js";
import { DateRange } from "../../../../shared/Shared.Models.fs.js";
import { metrics as metrics_1 } from "../../Server.fs.js";
import { GetMetricsRes } from "./Types.fs.js";

export class UnableToLoadMetricsException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadMetricsException$reflection() {
    return class_type("Analysis.MetricHistory.Api.UnableToLoadMetricsException", void 0, UnableToLoadMetricsException, class_type("System.Exception"));
}

function UnableToLoadMetricsException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadMetricsException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getAnalysis(dateRange, metricType, context) {
    return singleton.Delay(() => {
        let copyOfStruct;
        const inclusiveDateRange = new DateRange(dateRange.StartDate, (copyOfStruct = addDays(dateRange.EndDate, 1), addSeconds(copyOfStruct, -1)));
        return singleton.Bind(metrics_1((api) => api.getForDateRange(metricType, inclusiveDateRange, false)), (_arg1) => {
            const results = _arg1;
            return singleton.Return((results.tag === 0) ? (new GetMetricsRes(0, results.fields[0])) : (new GetMetricsRes(1, new UnableToLoadMetricsException())));
        });
    });
}

