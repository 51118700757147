import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { int32_type, array_type, record_type, bool_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { empty, singleton as singleton_1, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { tryHead, map, minBy, maxBy, isEmpty, singleton, append, delay } from "./.fable/fable-library.3.0.0/Seq.js";
import { uncurry } from "./.fable/fable-library.3.0.0/Util.js";
import { Context$reflection } from "./Context.fs.js";
import { TrainingLoadSummary$reflection } from "../../shared/Shared.Models.fs.js";
import { addSeconds, toShortDateString, subtract, compare } from "./.fable/fable-library.3.0.0/Date.js";
import { totalSeconds } from "./.fable/fable-library.3.0.0/TimeSpan.js";
import { TimeGraphAxis, TimeGraphColor, TimeGraphDataPoint, TimeGraphTick } from "./TimeGraph.fs.js";
import { Graph_Time_7CAA9E5B, Series_Time_Z1F763A16 } from "./Graph.fs.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { CSSProp } from "./.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";

const tsbColor = "#38A169";

const atlColor = "#3182CE";

const ctlColor = "#E53E3E";

const tssColor = "#718096";

const ctlBackgroundColor = "rgb(255,245,245,0.55)";

export class ITrainingLoadGraphLegendProps extends Record {
    constructor(ShowTsb, ShowAtl, ShowCtl, ShowTss) {
        super();
        this.ShowTsb = ShowTsb;
        this.ShowAtl = ShowAtl;
        this.ShowCtl = ShowCtl;
        this.ShowTss = ShowTss;
    }
}

export function ITrainingLoadGraphLegendProps$reflection() {
    return record_type("TrainingLoadGraph.ITrainingLoadGraphLegendProps", [], ITrainingLoadGraphLegendProps, () => [["ShowTsb", bool_type], ["ShowAtl", bool_type], ["ShowCtl", bool_type], ["ShowTss", bool_type]]);
}

export const legend = FunctionComponent_Of_Z5A158BBF((props) => {
    const legendItem = (label, color) => react.createElement("div", {
        className: "flex flex-row justify-start flex-shrink items-center",
    }, react.createElement("svg", {
        viewBox: "0 0 24 4",
        style: {
            width: 24,
            height: 4,
        },
    }, react.createElement("path", {
        d: "M 0 3 L 24 3",
        stroke: color,
        strokeWidth: 2,
    })), react.createElement("span", {
        className: "ml-2 mr-4",
        style: {
            color: color,
        },
    }, label));
    return react.createElement("div", {
        className: "flex flex-row justify-center items-center text-sm",
    }, ...ofSeq(delay(() => append(props.ShowCtl ? singleton(legendItem("Fitness", ctlColor)) : singleton(react.createElement(react.Fragment, {})), delay(() => append(props.ShowAtl ? singleton(legendItem("Fatigue", atlColor)) : singleton(react.createElement(react.Fragment, {})), delay(() => append(props.ShowTsb ? singleton(legendItem("Form", tsbColor)) : singleton(react.createElement(react.Fragment, {})), delay(() => (props.ShowTss ? singleton(legendItem("Training Stress", tssColor)) : singleton(react.createElement(react.Fragment, {}))))))))))));
}, void 0, uncurry(2, void 0), void 0, "legend", "/home/runner/work/strengthPlus/strengthPlus/client/src/TrainingLoadGraph.fs", 22);

export class ITrainingLoadGraphProps extends Record {
    constructor(Context, Load, Height, GridLines, ShowTsb, ShowAtl, ShowCtl, ShowTss) {
        super();
        this.Context = Context;
        this.Load = Load;
        this.Height = (Height | 0);
        this.GridLines = (GridLines | 0);
        this.ShowTsb = ShowTsb;
        this.ShowAtl = ShowAtl;
        this.ShowCtl = ShowCtl;
        this.ShowTss = ShowTss;
    }
}

export function ITrainingLoadGraphProps$reflection() {
    return record_type("TrainingLoadGraph.ITrainingLoadGraphProps", [], ITrainingLoadGraphProps, () => [["Context", Context$reflection()], ["Load", array_type(TrainingLoadSummary$reflection())], ["Height", int32_type], ["GridLines", int32_type], ["ShowTsb", bool_type], ["ShowAtl", bool_type], ["ShowCtl", bool_type], ["ShowTss", bool_type]]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const load = props.Load;
    if (isEmpty(load)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const latestTime = maxBy((l) => l.Date, load, {
            Compare: compare,
        }).Date;
        const earliestTime = minBy((l_1) => l_1.Date, load, {
            Compare: compare,
        }).Date;
        const ticks = Array.from(map((t) => {
            let copyOfStruct;
            return new TimeGraphTick(~(~(copyOfStruct = subtract(t, earliestTime), totalSeconds(copyOfStruct))), toShortDateString(t));
        }, [earliestTime, latestTime]));
        const tssSeries = Series_Time_Z1F763A16(Array.from(map((l_2) => {
            let copyOfStruct_1;
            return new TimeGraphDataPoint(l_2.TotalTrainingStress, ~(~(copyOfStruct_1 = subtract(l_2.Date, earliestTime), totalSeconds(copyOfStruct_1))), l_2.TotalTrainingStress > 0);
        }, load)), new TimeGraphColor(0, tssColor), void 0, new TimeGraphAxis(0), void 0, void 0, void 0, void 0, false);
        const tsbSeries = Series_Time_Z1F763A16(Array.from(map((l_3) => {
            let copyOfStruct_2;
            return new TimeGraphDataPoint(l_3.Balance, ~(~(copyOfStruct_2 = subtract(l_3.Date, earliestTime), totalSeconds(copyOfStruct_2))), false);
        }, load)), new TimeGraphColor(0, tsbColor), void 0, new TimeGraphAxis(1));
        const ctlSeries = Series_Time_Z1F763A16(Array.from(map((l_4) => {
            let copyOfStruct_3;
            return new TimeGraphDataPoint(l_4.ChronicTrainingLoad, ~(~(copyOfStruct_3 = subtract(l_4.Date, earliestTime), totalSeconds(copyOfStruct_3))), false);
        }, load)), new TimeGraphColor(0, ctlColor), ctlBackgroundColor, new TimeGraphAxis(0), void 0, void 0, void 0, 2);
        const atlSeries = Series_Time_Z1F763A16(Array.from(map((l_5) => {
            let copyOfStruct_4;
            return new TimeGraphDataPoint(l_5.AcuteTrainingLoad, ~(~(copyOfStruct_4 = subtract(l_5.Date, earliestTime), totalSeconds(copyOfStruct_4))), false);
        }, load)), new TimeGraphColor(0, atlColor), void 0, new TimeGraphAxis(0));
        const table = react.createElement(react.Fragment, {});
        const series_1 = Array.from(append(props.ShowCtl ? singleton_1(ctlSeries) : empty(), append(props.ShowAtl ? singleton_1(atlSeries) : empty(), append(props.ShowTsb ? singleton_1(tsbSeries) : empty(), append(props.ShowTss ? singleton_1(tssSeries) : empty(), [])))));
        const seriesLabels = Array.from(append(props.ShowCtl ? singleton_1("Fitness") : empty(), append(props.ShowAtl ? singleton_1("Fatigue") : empty(), append(props.ShowTsb ? singleton_1("Form") : empty(), append(props.ShowTss ? singleton_1("Training Stress") : empty(), [])))));
        const seriesColors = Array.from(append(props.ShowCtl ? singleton_1(ctlColor) : empty(), append(props.ShowAtl ? singleton_1(atlColor) : empty(), append(props.ShowTsb ? singleton_1(tsbColor) : empty(), append(props.ShowTss ? singleton_1(tssColor) : empty(), [])))));
        return react.createElement("div", {
            style: {
                height: props.Height,
            },
        }, Graph_Time_7CAA9E5B(series_1, ticks, (tupledArg) => {
            const min = tupledArg[0];
            const max = tupledArg[1];
            if ((min < 0) ? (max > 0) : false) {
                const patternInput = (Math.abs(min) > max) ? [min, Math.abs(min)] : [-max, max];
                return [Math.floor((patternInput[0] - 5) / 5) * 5, Math.ceil((patternInput[1] + 5) / 5) * 5];
            }
            else {
                return [min, max];
            }
        }, (tupledArg_1) => [Math.floor(tupledArg_1[0] / 10) * 10, Math.ceil(tupledArg_1[1] / 10) * 10], void 0, false, void 0, props.GridLines, void 0, void 0, tsbColor, void 0, uncurry(3, void 0), void 0, (series, seriesIndex, dataPoint) => {
            let arg10_1, copyOfStruct_5;
            return [["Form", toText(printf("%.0f"))(0)], ["Date", (arg10_1 = (copyOfStruct_5 = addSeconds(earliestTime, dataPoint.TimeInSeconds), toShortDateString(copyOfStruct_5)), toText(printf("%s"))(arg10_1))]];
        }, true, (timegraphDatapoints) => {
            let source2_12, copyOfStruct_6;
            const matchValue_15 = tryHead(timegraphDatapoints);
            if (matchValue_15 == null) {
                return new Array(0);
            }
            else {
                return Array.from((source2_12 = map((tdp) => {
                    let css;
                    return [seriesLabels[tdp.SeriesIndex], react.createElement("span", keyValueList([(css = singleton_1(new CSSProp(103, seriesColors[tdp.SeriesIndex])), ["style", keyValueList(css, 1)])], 1), toText(printf("%.0f"))(tdp.DataPoint.Value))];
                }, timegraphDatapoints), append([["Date", (copyOfStruct_6 = addSeconds(earliestTime, matchValue_15.DataPoint.TimeInSeconds), toShortDateString(copyOfStruct_6))]], source2_12)));
            }
        }));
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/TrainingLoadGraph.fs", 51);

