import * as react from "react";
import { modalWrapper } from "../../CommonRendering.fs.js";
import { Msg } from "./Types.fs.js";
import { IconSize, trashIcon } from "../../Icons.fs.js";
import { view as view_1 } from "../../Spinner.fs.js";
import { warning, plain } from "../../Buttons.fs.js";

export function view(model, dispatch) {
    const matchValue = model.Programme;
    if (matchValue == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const programme = matchValue;
        if (model.ShowDeleteModal) {
            const spinnerStyle = model.IsDeleting ? "flex flex-row justify-center items-center" : "hidden flex flex-row justify-center items-center";
            return modalWrapper((_arg1) => {
                dispatch(new Msg(2));
            }, react.createElement("div", {}, react.createElement("div", {
                className: "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100",
            }, react.createElement("span", {
                className: "text-red-600",
            }, trashIcon(new IconSize(2)))), react.createElement("div", {
                className: "min-h-full flex flex-column justify-around",
            }, react.createElement("div", {
                className: spinnerStyle,
            }, view_1), react.createElement("div", {
                className: model.IsDeleting ? "w-full hidden" : "w-full",
            }, react.createElement("label", {
                className: "mt-4 block text-sm font-medium leading-5 text-gray-700",
            }, "Are you sure you want to delete this workout?"))), react.createElement("div", {
                className: "grid grid-cols-2 gap-4 mt-4",
            }, plain("No", () => {
                dispatch(new Msg(2));
            }, false), warning("Yes, delete it", () => {
                dispatch(new Msg(3, programme.Id));
            }, false))));
        }
        else {
            return react.createElement(react.Fragment, {});
        }
    }
}

