import { Union } from "./.fable/fable-library.3.0.0/Types.js";
import { union_type, lambda_type, unit_type, class_type, bool_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";

export class ReactInputMaskProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Mask", "MaskChar", "AlwaysShowMask", "Class", "Value", "OnChange"];
    }
}

export function ReactInputMaskProps$reflection() {
    return union_type("ReactInputMask.ReactInputMaskProps", [], ReactInputMaskProps, () => [[["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", lambda_type(class_type("Browser.Types.Event"), unit_type)]]]);
}

