import * as react from "react";
import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { href as href_1 } from "./Router.fs.js";
import { HTMLAttr } from "./.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";

export const subtleLabelClass = "text-sm leading-5 font-medium text-gray-500 truncate";

export const subtleLabelNoneTruncateClass = "text-sm leading-5 font-medium text-gray-500";

export const labelClass = "text-sm font-bold text-gray-500 truncate";

export const italicLabelClass = "text-sm font-bold text-gray-500 truncate italic";

export const tinyLabelClass = "text-xs font-medium text-gray-500 truncate";

export const boldLabelClass = "text-xs font-bold text-gray-600 truncate";

export const boldContentLabelClass = "text-sm font-bold text-gray-600 truncate";

export const tinyContentClass = "text-xs font-normal text-gray-500 truncate";

export const contentClass = "text-sm font-normal text-gray-500 truncate";

export const colouredContentClass = "text-sm font-normal text-indigo-600 truncate";

export const tinyColouredContentClass = "text-xs font-normal text-indigo-600 truncate";

export const simpleLinkClass = "text-sm leading-5 font-medium text-blue-500 truncate";

export const linkClass = "leading-5 font-medium text-indigo-600 hover:text-indigo-900";

export function link(text, href) {
    return react.createElement("a", keyValueList([href_1(href), new HTMLAttr(65, linkClass)], 1), text);
}

export function bodyText(text) {
    return react.createElement("span", {
        className: "text-sm font-normal text-gray-500",
    }, text);
}

export function subtleLabel(text) {
    return react.createElement("span", {
        className: subtleLabelClass,
    }, text);
}

export function label(text) {
    return react.createElement("span", {
        className: labelClass,
    }, text);
}

export function italicLabel(text) {
    return react.createElement("span", {
        className: italicLabelClass,
    }, text);
}

export function tinyLabel(text) {
    return react.createElement("span", {
        className: tinyLabelClass,
    }, text);
}

export function content(text) {
    return react.createElement("span", {
        className: contentClass,
    }, text);
}

export function colouredContent(text, color) {
    return react.createElement("span", {
        className: toText(printf("text-sm font-normal text-%s truncate"))(color),
    }, text);
}

export function tinyContent(text) {
    return react.createElement("span", {
        className: tinyContentClass,
    }, text);
}

export function tinyColouredContent(text) {
    return react.createElement("span", {
        className: tinyColouredContentClass,
    }, text);
}

export function boldContentLabel(text) {
    return react.createElement("span", {
        className: boldContentLabelClass,
    }, text);
}

export function boldLabel(text) {
    return react.createElement("span", {
        className: boldLabelClass,
    }, text);
}

export function panelTitle(title) {
    return react.createElement("span", {
        className: "font-bold text-gray-700",
    }, title);
}

export function panelSubTitle(title) {
    return react.createElement("span", {
        className: "font-normal text-indigo-600",
    }, title);
}

export function dashboardTitle(title) {
    return react.createElement("span", {
        className: "font-bold text-gray-700",
    }, title);
}

