import { GetFitnessRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { DateRange as DateRange_3 } from "../../../../shared/Shared.Models.fs.js";
import { dateRangeFromType } from "../Types.fs.js";
import { getAnalysis } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(3))];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 2: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(model.DateRange.StartDate, msg.fields[0]), model.Load), Cmd_OfFunc_result(new Msg(3))];
        }
        case 0: {
            const newValue = msg.fields[0];
            return [new Model(model.IsLoading, newValue, dateRangeFromType(newValue), model.Load), Cmd_OfFunc_result(new Msg(3))];
        }
        case 3: {
            return [new Model(true, model.DateRangeType, model.DateRange, model.Load), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getAnalysis(model.DateRange, context_1), context, (arg0) => (new Msg(4, arg0)), (arg) => (new Msg(4, new GetFitnessRes(1, arg))))];
        }
        case 4: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.DateRangeType, model.DateRange, model.Load), Cmd_none()];
            }
            else {
                return [new Model(false, model.DateRangeType, model.DateRange, result.fields[0]), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(msg.fields[0], model.DateRange.EndDate), model.Load), Cmd_OfFunc_result(new Msg(3))];
        }
    }
}

