import { ValidationState__IsPropertyInError_Z721C83C5, ValidationState__IsValid } from "../../../../shared/SimpleValidation.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import * as react from "react";
import { numberInputWithAddonElement, block, header, Converters_optionalIntValue } from "../../Form.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { Msg } from "./Types.fs.js";
import { panel, HeaderButton, page } from "../../Layout.fs.js";
import { IconSize, historyIcon } from "../../Icons.fs.js";
import { PowerZonesPage_FromDefaultHistory, Page, modifyLocation } from "../../Router.fs.js";
import { condensed } from "../../Grids.fs.js";
import { powerUnits } from "../../Units.fs.js";
import { primary } from "../../Buttons.fs.js";

export function root(context, model, dispatch) {
    let fromValue_1;
    const isSaveDisabled = !(ValidationState__IsValid(model.ValidationState) ? model.IsDirty : false);
    const row = (zoneName, fromValue, toValue, toSetter, isInvalid) => {
        const validationBasedClass = isInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
        return ofSeq(delay(() => append(singleton(zoneName), delay(() => {
            let arg10;
            return append((fromValue == null) ? singleton("") : singleton((arg10 = ((fromValue + 1) | 0), toText(printf("%d"))(arg10))), delay(() => singleton(react.createElement("div", {
                className: toText(printf("rounded-md shadow-sm sm")),
            }, react.createElement("input", {
                className: validationBasedClass,
                value: Converters_optionalIntValue(toValue),
                onChange: (ev) => {
                    toSetter(Browser_Types_Event__Event_get_Value(ev));
                },
            })))));
        }))));
    };
    const autoCalculateButton = react.createElement("button", {
        onClick: (_arg1) => {
            dispatch(new Msg(8));
        },
        className: "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-blue-700 bg-blue-50 hover:text-blue-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",
    }, "Auto-calculate");
    return page("Power Zones", model.IsLoading, [new HeaderButton(1, react.createElement("div", {
        className: "flex flex-row justify-start items-center",
    }, react.createElement("div", {
        className: "mr-2",
    }, historyIcon(new IconSize(4))), "History"), () => {
        modifyLocation(new Page(6, PowerZonesPage_FromDefaultHistory()));
    }, false)], [react.createElement("div", {}, panel([header("FTP", "Enter your FTP and use the auto-calculate button to set typical power based training zones."), block([numberInputWithAddonElement("sm", "FTP in watts", (arg) => {
        dispatch(new Msg(7, arg));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "CurrentFTP"), Converters_optionalIntValue(model.Zones.CurrentFTP), autoCalculateButton)])])), react.createElement("div", {
        className: "mt-6",
    }, panel([header("Zones", "Zones for power based training. It is recommended to auto-calculate them based on your FTP but you can edit or enter them manually here."), block([react.createElement("div", {
        className: "w-full",
    }, condensed(["Zone", ("From (" + powerUnits(context)) + ")", ("To (" + powerUnits(context)) + ")"], [row("Active Recovery", -1, model.Zones.ActiveRecovery, (arg_1) => {
        dispatch(new Msg(0, arg_1));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "ActiveRecovery")), row("Endurance", model.Zones.ActiveRecovery, model.Zones.Endurance, (arg_2) => {
        dispatch(new Msg(1, arg_2));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Endurance")), row("Tempo", model.Zones.Endurance, model.Zones.Tempo, (arg_3) => {
        dispatch(new Msg(2, arg_3));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Tempo")), row("Lactate Threshold", model.Zones.Tempo, model.Zones.LactateThreshold, (arg_4) => {
        dispatch(new Msg(3, arg_4));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "LactateThreshold")), row("VO2 Max", model.Zones.LactateThreshold, model.Zones.VO2Max, (arg_5) => {
        dispatch(new Msg(4, arg_5));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "VO2Max")), row("Anaerobic Capacity", model.Zones.VO2Max, model.Zones.AnaerobicCapacity, (arg_6) => {
        dispatch(new Msg(5, arg_6));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "AnaerobicCapacity")), (fromValue_1 = model.Zones.AnaerobicCapacity, ofSeq(delay(() => append(singleton("Neuromuscular Power"), delay(() => {
        let arg10_1;
        return append((fromValue_1 == null) ? singleton("") : singleton((arg10_1 = ((fromValue_1 + 1) | 0), toText(printf("%d"))(arg10_1))), delay(() => singleton("N/A")));
    })))))]))]), react.createElement("div", {
        className: "flex flex-row justify-end mt-4",
    }, react.createElement("div", {}, primary("Save New Zones", () => {
        dispatch(new Msg(11));
    }, isSaveDisabled)))]))]);
}

