import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { addDays } from "../../.fable/fable-library.3.0.0/Date.js";
import { GetPageRes, pageSizeWeeks } from "./Types.fs.js";
import { ApiException, trainingLog, cardioActivity } from "../../Server.fs.js";
import { ApiResponse$1 } from "../../../../shared/Shared.Api.fs.js";

export function getWeeklyTrainingLogs(model, context) {
    return singleton.Delay(() => {
        let matchValue, m;
        const endDate = addDays(model.LastRowStartDate, (7 * pageSizeWeeks) + 7);
        return singleton.Bind((matchValue = model.Maximums, (matchValue == null) ? cardioActivity((api) => api.getMaximums()) : (m = matchValue, singleton.Delay(() => singleton.Return(new ApiResponse$1(0, m))))), (_arg1) => singleton.Bind(trainingLog((api_1) => api_1.getBetween(model.LastRowStartDate, endDate)), (_arg2) => {
            let matchValue_1, tupledArg;
            return singleton.Return((matchValue_1 = [_arg2, _arg1], (matchValue_1[0].tag === 0) ? ((matchValue_1[1].tag === 0) ? (tupledArg = [Array.from(matchValue_1[0].fields[0]), matchValue_1[1].fields[0]], new GetPageRes(0, tupledArg[0], tupledArg[1])) : (new GetPageRes(1, new ApiException()))) : (new GetPageRes(1, new ApiException()))));
        }));
    });
}

