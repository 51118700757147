import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { sortWith, maxBy, min as min_1, map, max as max_1, isEmpty } from "../../.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { addSeconds, toShortDateString, subtract, compare } from "../../.fable/fable-library.3.0.0/Date.js";
import { totalSeconds } from "../../.fable/fable-library.3.0.0/TimeSpan.js";
import { ITimeGraphProps, TimeGraphColor, TimeGraphDataPoint, line, TimeGraphTick } from "../../TimeGraph.fs.js";
import { uncurry, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { Series_Time_Z1F763A16 } from "../../Graph.fs.js";
import { metricValueAsString, metricTypeAsUnits, metricTypeAsLabel, seriesColors } from "../Types.fs.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { summary } from "../../Grids.fs.js";
import { ofArray } from "../../.fable/fable-library.3.0.0/List.js";

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    let projection_1, arg20_1, arg10_1, projection_2;
    const metrics = props.Metrics;
    if (isEmpty(metrics)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const latestTime = max_1(map((dp) => dp.RecordedAtUtc, metrics), {
            Compare: compare,
        });
        const earliestTime = min_1(map((dp_1) => dp_1.RecordedAtUtc, metrics), {
            Compare: compare,
        });
        const ticks = Array.from(map((t) => {
            let copyOfStruct;
            return new TimeGraphTick(~(~(copyOfStruct = subtract(t, earliestTime), totalSeconds(copyOfStruct))), toShortDateString(t));
        }, [earliestTime, latestTime]));
        const largestTick = maxBy((t_1) => t_1.TimeInSeconds, ticks, {
            Compare: comparePrimitives,
        }).TimeInSeconds | 0;
        return react.createElement("div", {
            className: "mt-4",
        }, react.createElement("div", {
            style: {
                height: props.Height,
            },
        }, line(new ITimeGraphProps([Series_Time_Z1F763A16(Array.from((projection_1 = ((dp_2) => dp_2.TimeInSeconds), sortWith((x_3, y_3) => comparePrimitives(projection_1(x_3), projection_1(y_3)), map((metric) => {
            let copyOfStruct_1;
            return new TimeGraphDataPoint(metric.RecordedValue, ~(~(copyOfStruct_1 = subtract(metric.RecordedAtUtc, earliestTime), totalSeconds(copyOfStruct_1))), true);
        }, metrics)))), new TimeGraphColor(0, seriesColors[0]))], ticks, void 0, (tupledArg) => {
            const min = tupledArg[0];
            const max = tupledArg[1];
            const matchValue_1 = props.MetricType;
            switch (matchValue_1.tag) {
                case 1: {
                    return [Math.floor(min / 10) * 10, Math.ceil(max / 10) * 10];
                }
                case 2:
                case 3: {
                    return [Math.floor(min / 100) * 100, Math.ceil(max / 100) * 100];
                }
                case 4: {
                    return [Math.floor(min / 10) * 10, Math.ceil(max / 10) * 10];
                }
                default: {
                    return [Math.floor(min / 10) * 10, Math.ceil(max / 10) * 10];
                }
            }
        }, void 0, true, true, false, "#718096", "#CBD5E0", 0, false, void 0, new GraphValueTransformer(0), (series_1, seriesIndex, dataPoint) => {
            let arg20, copyOfStruct_2;
            return [[metricTypeAsLabel(props.MetricType), (arg20 = metricTypeAsUnits(props.Context, props.MetricType), toText(printf("%.0f %s"))(dataPoint.Value)(arg20))], ["Recorded on", (copyOfStruct_2 = addSeconds(earliestTime, dataPoint.TimeInSeconds), toShortDateString(copyOfStruct_2))]];
        }, uncurry(3, void 0), void 0))), react.createElement("div", {
            className: "mt-4",
        }, summary(true, true, true, ofArray(["Recorded at", (arg20_1 = metricTypeAsUnits(props.Context, props.MetricType), (arg10_1 = metricTypeAsLabel(props.MetricType), toText(printf("%s (%s)"))(arg10_1)(arg20_1)))]), map((metric_2) => ofArray([toShortDateString(metric_2.RecordedAtUtc), metricValueAsString(props.Context, metric_2.MetricType)(metric_2.RecordedValue)]), (projection_2 = ((metric_1) => metric_1.RecordedAtUtc), sortWith((x_4, y_4) => compare(projection_2(x_4), projection_2(y_4)), metrics))))));
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/MetricHistory/Graph.fs", 9);

