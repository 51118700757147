import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { Id_create } from "../../../../shared/Shared.Types.fs.js";
import { GetCardioActivitiesRes, Item } from "./Types.fs.js";
import { singleton as singleton_1 } from "../../.fable/fable-library.3.0.0/List.js";
import { sleep } from "../../.fable/fable-library.3.0.0/Async.js";

export function getCardioActivities(context) {
    return singleton.Delay(() => {
        const result = singleton_1(new Item(Id_create("1"), "Some item"));
        return singleton.Bind(sleep(1500), () => singleton.Return(new GetCardioActivitiesRes(0, result)));
    });
}

