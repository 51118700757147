import { IconSize, iconForCardioType } from "../../Icons.fs.js";
import { Sort$1__get_SortColumn, CardioActivitySortColumn } from "../../../../shared/Shared.Api.fs.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { CardioSummary__get_CalculatedTitle } from "../../../../shared/Shared.Models.fs.js";
import { heartRate, normalisedPower, power, speed, distance, optionalDurationFromSeconds } from "../../Units.fs.js";
import { OptionHelpers_optionalFloatFromInt } from "../../../../shared/Shared.fs.js";
import { toolbarPage } from "../../Layout.fs.js";
import * as react from "react";
import { sortableGrid } from "../../Grids.fs.js";
import { CardioActivityPage, Page, modifyLocation } from "../../Router.fs.js";
import { Msg } from "./Types.fs.js";

export function root(model, context, dispatch) {
    const columns = [["", (cs) => iconForCardioType(cs.ActivityType)(new IconSize(2)), new CardioActivitySortColumn(9)], ["Date", (cs_1) => toShortDateString(cs_1.Metrics.RecordedAtUtc), new CardioActivitySortColumn(7)], ["Name", CardioSummary__get_CalculatedTitle, new CardioActivitySortColumn(6)], ["Duration", (cs_3) => optionalDurationFromSeconds(OptionHelpers_optionalFloatFromInt(cs_3.Metrics.TimeSeconds)), new CardioActivitySortColumn(0)], ["Distance", (cs_4) => distance(context, cs_4.Metrics.DistanceMeters), new CardioActivitySortColumn(1)], ["Average Speed", (cs_5) => speed(context, cs_5.Metrics.AverageSpeedMetersPerSecond), new CardioActivitySortColumn(5)], ["Average Power", (cs_6) => power(context, cs_6.Metrics.AveragePower), new CardioActivitySortColumn(3)], ["Normalised Power", (cs_7) => normalisedPower(context, cs_7.Metrics.NormalisedPower), new CardioActivitySortColumn(4)], ["Average Heart Rate", (cs_8) => heartRate(context, cs_8.Metrics.AverageHeartRate), new CardioActivitySortColumn(2)]];
    return toolbarPage("Activities", void 0, model.IsLoading, false, [react.createElement(react.Fragment, {})], [react.createElement("div", {
        className: "mb-6",
    }, sortableGrid((itemIndex) => {
        modifyLocation(new Page(8, new CardioActivityPage(1, model.Items[itemIndex].Id)));
    }, (arg) => {
        dispatch(new Msg(3, arg));
    }, columns, Sort$1__get_SortColumn(model.Sort), model.Items, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, (arg_1) => {
        dispatch(new Msg(2, arg_1));
    }))]);
}

