import { Union, Record } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { union_type, list_type, record_type, string_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";
import { length as length_2, mapIndexed, map, collect, isEmpty as isEmpty_1, tryFind } from "../client/src/.fable/fable-library.3.0.0/Seq.js";
import { mapCurriedArgs, compare, equals } from "../client/src/.fable/fable-library.3.0.0/Util.js";
import { isNullOrWhiteSpace, isNullOrEmpty, printf, toText } from "../client/src/.fable/fable-library.3.0.0/String.js";
import { length as length_1, empty, ofSeq, singleton } from "../client/src/.fable/fable-library.3.0.0/List.js";
import { value as value_1 } from "../client/src/.fable/fable-library.3.0.0/Option.js";

export class ValidationItem extends Record {
    constructor(message, property, errorCode) {
        super();
        this.message = message;
        this.property = property;
        this.errorCode = errorCode;
    }
}

export function ValidationItem$reflection() {
    return record_type("SimpleValidation.ValidationItem", [], ValidationItem, () => [["message", string_type], ["property", string_type], ["errorCode", string_type]]);
}

export class ValidationState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Errors", "Ok"];
    }
}

export function ValidationState$reflection() {
    return union_type("SimpleValidation.ValidationState", [], ValidationState, () => [[["Item", list_type(ValidationItem$reflection())]], []]);
}

export function ValidationState__IsValid(x) {
    if (x.tag === 1) {
        return true;
    }
    else {
        return false;
    }
}

export function ValidationState__IsInvalid(x) {
    if (x.tag === 1) {
        return false;
    }
    else {
        return true;
    }
}

export function ValidationState__IsPropertyInError_Z721C83C5(x, propertyName) {
    if (x.tag === 0) {
        const propertyError = tryFind((e) => (e.property === propertyName), x.fields[0]);
        if (propertyError == null) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return false;
    }
}

export function ValidationState__IsPropertyValid_Z721C83C5(x, propertyName) {
    return !ValidationState__IsPropertyInError_Z721C83C5(x, propertyName);
}

export function ValidationState__IsPropertyOrDescendantInError_Z721C83C5(x, propertyPath) {
    if (x.tag === 0) {
        const propertyError = tryFind((e) => (e.property.indexOf(propertyPath) === 0), x.fields[0]);
        if (propertyError == null) {
            return false;
        }
        else {
            return true;
        }
    }
    else {
        return false;
    }
}

export function ValidationState__IsPropertyOrDescendantValid_Z721C83C5(x, propertyPath) {
    return !ValidationState__IsPropertyOrDescendantInError_Z721C83C5(x, propertyPath);
}

export class MatchResult$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Unwrapped", "Ignore"];
    }
}

export function MatchResult$1$reflection(gen0) {
    return union_type("SimpleValidation.MatchResult`1", [gen0], MatchResult$1, () => [[["Item", gen0]], []]);
}

export function isEqualTo(comparisonValue) {
    return (_arg1) => ((value) => (equals(value, comparisonValue) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must be equal to %O"))(comparisonValue), "", "isEqualTo"))))));
}

export function isNotEqualTo(comparisonValue) {
    return (_arg1) => ((value) => ((!equals(value, comparisonValue)) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must not be equal to %O"))(comparisonValue), "", "isNotEqualTo"))))));
}

export function isNotNull(_arg1, value) {
    if (value == null) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be null", "", "isNotNull")));
    }
    else {
        return new ValidationState(1);
    }
}

export function isGreaterThanOrEqualTo(minValue) {
    return (_arg1) => ((value) => ((compare(value, minValue) >= 0) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must have a minimum value of %O"))(minValue), "", "isGreaterThanOrEqualTo"))))));
}

export function isGreaterThan(minValue) {
    return (_arg1) => ((value) => ((compare(value, minValue) > 0) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must be greater than %O"))(minValue), "", "isGreaterThan"))))));
}

export function isLessThanOrEqualTo(maxValue) {
    return (_arg1) => ((value) => ((compare(value, maxValue) <= 0) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must have a maximum value of %O"))(maxValue), "", "isLessThanOrEqualTo"))))));
}

export function isLessThan(lessThanValue) {
    return (_arg1) => ((value) => ((compare(value, lessThanValue) < 0) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must be less than %O"))(lessThanValue), "", "isLessThan"))))));
}

export function isNotEmpty(_arg1, value) {
    if (value == null) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be null", "", "isNotEmpty")));
    }
    else if (isEmpty_1(value)) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be empty", "", "isNotEmpty")));
    }
    else {
        return new ValidationState(1);
    }
}

export function isEmpty(_arg1, value) {
    if (!((value == null) ? true : isEmpty_1(value))) {
        return new ValidationState(0, singleton(new ValidationItem("Must be empty", "", "isEmpty")));
    }
    else {
        return new ValidationState(1);
    }
}

export function eachItemWith(validatorFunc, _arg1, value) {
    const failures = ofSeq(collect((r) => {
        if (r[0].tag === 0) {
            return ofSeq(map((error) => (new ValidationItem(error.message, toText(printf("[%d].%s"))(r[1])(error.property), error.errorCode)), r[0].fields[0]));
        }
        else {
            return empty();
        }
    }, mapIndexed((index_1, item) => [validatorFunc(item), index_1], value)));
    if (length_1(failures) === 0) {
        return new ValidationState(1);
    }
    else {
        return new ValidationState(0, failures);
    }
}

export function hasLengthOf(length) {
    return (_arg1) => ((value) => ((length_2(value) === length) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must have a length of %O"))(length), "", "hasLengthOf"))))));
}

export function hasMinLengthOf(length) {
    return (_arg1) => ((value) => ((length_2(value) >= length) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must have a length no less than %O"))(length), "", "hasMinLengthOf"))))));
}

export function hasMaxLengthOf(length) {
    return (_arg1) => ((value) => ((length_2(value) <= length) ? (new ValidationState(1)) : (new ValidationState(0, singleton(new ValidationItem(toText(printf("Must have a length no greater than %O"))(length), "", "hasMaxLengthOf"))))));
}

export function isNotEmptyOrWhitespace(_arg1, value) {
    if (value == null) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be null", "", "isNotEmptyOrWhitespace")));
    }
    else if (isNullOrEmpty(value)) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be empty", "", "isNotEmptyOrWhitespace")));
    }
    else if (isNullOrWhiteSpace(value)) {
        return new ValidationState(0, singleton(new ValidationItem("Must not be whitespace", "", "isNotEmptyOrWhitespace")));
    }
    else {
        return new ValidationState(1);
    }
}

export function runChildValidator(value, validatorFunc) {
    const matchValue = validatorFunc(value);
    if (matchValue.tag === 0) {
        return new ValidationState(0, ofSeq(map((error) => (new ValidationItem(error.message, "", error.errorCode)), matchValue.fields[0])));
    }
    else {
        return new ValidationState(1);
    }
}

export function withValidatorWhen(predicate, validatorFunc) {
    return (propertyPath) => ((value) => (predicate(value) ? runChildValidator(value, validatorFunc) : (new ValidationState(1))));
}

export function withValidator(validatorFunc) {
    return (_arg1) => ((value) => runChildValidator(value, validatorFunc));
}

function patchPropertyNames(propertyName, errors) {
    return ofSeq(map((e) => (new ValidationItem(e.message, isNullOrWhiteSpace(e.property) ? propertyName : toText(printf("%s.%s"))(propertyName)(e.property), e.errorCode)), errors));
}

export function validate(propertyName, propertyGetter, validatorFunctions) {
    return (_arg1) => ((value) => {
        const results = collect((r) => {
            if (r.tag === 0) {
                return patchPropertyNames(propertyName, r.fields[0]);
            }
            else {
                return empty();
            }
        }, map(mapCurriedArgs((f) => f(value, propertyGetter(value)), [[0, 2]]), validatorFunctions));
        return isEmpty_1(results) ? (new ValidationState(1)) : (new ValidationState(0, ofSeq(results)));
    });
}

export function validateRequired(propertyName, propertyGetter, validatorFunctions) {
    return (_arg1) => ((value) => {
        const propertyValueOption = propertyGetter(value);
        if (propertyValueOption != null) {
            const propertyValue = value_1(propertyValueOption);
            const results = ofSeq(collect((r) => {
                if (r.tag === 0) {
                    return patchPropertyNames(propertyName, r.fields[0]);
                }
                else {
                    return empty();
                }
            }, map(mapCurriedArgs((f) => f(value, propertyValue), [[0, 2]]), validatorFunctions)));
            return isEmpty_1(results) ? (new ValidationState(1)) : (new ValidationState(0, results));
        }
        else {
            return new ValidationState(0, singleton(new ValidationItem("Is required", propertyName, "isNotNone")));
        }
    });
}

export function validateWhen(propertyName, propertyGetter, validatorFunctions) {
}

export function validateUnion(propertyName, propertyGetter, unwrapper, validatorFunctions) {
    return (_arg1) => ((value) => {
        const results = collect((r) => {
            if (r.tag === 0) {
                return r.fields[0];
            }
            else {
                return empty();
            }
        }, map(mapCurriedArgs((vf) => {
            const matchValue = unwrapper(propertyGetter(value));
            if (matchValue.tag === 1) {
                return new ValidationState(1);
            }
            else {
                return vf(value, matchValue.fields[0]);
            }
        }, [[0, 2]]), validatorFunctions));
        return isEmpty_1(results) ? (new ValidationState(1)) : (new ValidationState(0, patchPropertyNames(propertyName, results)));
    });
}

export function createValidatorFor(validators) {
    return (arg10) => ((value) => {
        const results = collect(mapCurriedArgs((v) => {
            const matchValue = v(value, value);
            if (matchValue.tag === 0) {
                return matchValue.fields[0];
            }
            else {
                return empty();
            }
        }, [[0, 2]]), validators);
        if (isEmpty_1(results)) {
            return new ValidationState(1);
        }
        else {
            return new ValidationState(0, ofSeq(results));
        }
    })(arg10);
}

