import { substring, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { Convert_fromJson, Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B } from "./.fable/Fable.SimpleJson.3.17.0/Json.Converter.fs.js";
import { escapeDataString } from "./.fable/fable-library.3.0.0/Util.js";
import { api, accessToken, AppAuthenticationState } from "./Server.fs.js";
import { SimpleJson_tryParse } from "./.fable/Fable.SimpleJson.3.17.0/SimpleJson.fs.js";
import { createTypeInfo } from "./.fable/Fable.SimpleJson.3.17.0/TypeInfo.Converter.fs.js";
import { AccessToken$reflection } from "../../shared/Shared.Models.fs.js";
import { utcNow, compare } from "./.fable/fable-library.3.0.0/Date.js";
import { FSharpResult$2 } from "./.fable/fable-library.3.0.0/Choice.js";
import { singleton } from "./.fable/fable-library.3.0.0/AsyncBuilder.js";

export const protocolHostAndPort = (((window.location.host.indexOf("strengthplus") >= 0) ? true : (window.location.host.indexOf("fcbc") >= 0)) ? true : (window.location.host.indexOf("forcyclists") >= 0)) ? (() => {
    const arg10 = window.location.host;
    return toText(printf("https://%s"))(arg10);
})() : (() => {
    const arg20 = window.location.host;
    const arg10_1 = window.location.protocol;
    return toText(printf("%s//%s"))(arg10_1)(arg20);
})();

export const isTokenCodePrefix = toText(printf("%s/?"))(protocolHostAndPort);

export const locationBasedRedirectUri = toText(printf("%s/#applesignin"))(protocolHostAndPort);

function isAccessTokenHash() {
    return window.location.hash.indexOf("#access_token=") === 0;
}

function isTokenCode() {
    return window.location.href.indexOf(isTokenCodePrefix) === 0;
}

function saveAccessToken(token) {
    sessionStorage.setItem("accessToken", Fable_SimpleJson_Json__Json_stringify_Static_4E60E31B(token));
    return token;
}

function hasSessionAccessToken() {
    return !(sessionStorage.getItem("accessToken") == null);
}

function clearAccessToken() {
    sessionStorage.removeItem("accessToken");
}

const domain = "forcyclistsbycyclists.eu.auth0.com";

const clientId = "A1tTyXxHmVAUgZwleupGOksZ9hGGmaJI";

export function logout() {
    clearAccessToken();
    window.location.href = toText(printf("https://%s/v2/logout?returnTo=https%%3A%%2F%%2Fwww.forcyclistsbycyclists.com%%2F\u0026client_id=%s"))(domain)(clientId);
}

function buildAuthUrl() {
    const redirectUri = escapeDataString(locationBasedRedirectUri);
    const audience = escapeDataString("https://performance-api.forcyclistsbycyclists.com");
    return toText(printf("https://%s/authorize?audience=%s\u0026response_type=code\u0026client_id=%s\u0026redirect_uri=%s\u0026state=%s"))(domain)(audience)(clientId)(redirectUri)("123");
}

function loadAccessToken() {
    const json = sessionStorage.getItem("accessToken");
    const matchValue = json == null;
    if (matchValue) {
        sessionStorage.removeItem("accessToken");
        return new AppAuthenticationState(1);
    }
    else {
        let deserializedToken;
        const matchValue_1 = SimpleJson_tryParse(json);
        if (matchValue_1 != null) {
            deserializedToken = Convert_fromJson(matchValue_1, createTypeInfo(AccessToken$reflection()));
        }
        else {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        }
        if (compare(utcNow(), deserializedToken.ExpiresAtUtc) < 0) {
            return new AppAuthenticationState(0, deserializedToken);
        }
        else {
            sessionStorage.removeItem("accessToken");
            return new AppAuthenticationState(1);
        }
    }
}

export function parseCode() {
    if (isTokenCode()) {
        let patternInput;
        const pair = substring(window.location.href, isTokenCodePrefix.length).split("\u0026")[0];
        patternInput = [pair.split("=")[0], pair.split("=")[1]];
        if (patternInput[0] === "code") {
            return new FSharpResult$2(0, patternInput[1]);
        }
        else {
            return new FSharpResult$2(1, "No code");
        }
    }
    else {
        return new FSharpResult$2(1, "No code");
    }
}

export function getAccessTokenOnStartup() {
    return singleton.Delay(() => {
        const matchValue = hasSessionAccessToken();
        if (matchValue) {
            const loadedAccessToken = loadAccessToken();
            accessToken(loadedAccessToken, true);
            return (loadedAccessToken.tag === 0) ? singleton.Return(true) : singleton.Return(false);
        }
        else {
            const code = parseCode();
            return (code.tag === 0) ? singleton.Bind(api.authentication.token(code.fields[0]), (_arg1) => {
                const exchangedToken = _arg1;
                if (exchangedToken.tag === 0) {
                    const token = exchangedToken.fields[0];
                    if (token.RefreshImmediately) {
                        const url = buildAuthUrl();
                        window.location.href = url;
                        return singleton.Return(false);
                    }
                    else {
                        accessToken(new AppAuthenticationState(0, saveAccessToken(token)), true);
                        window.history.replaceState({}, document.title, "/");
                        return singleton.Return(true);
                    }
                }
                else {
                    accessToken(new AppAuthenticationState(1), true);
                    sessionStorage.removeItem("accessToken");
                    window.history.replaceState({}, document.title, "/");
                    return singleton.Return(false);
                }
            }) : singleton.Return(false);
        }
    });
}

export function requireAuthentication() {
    const url = buildAuthUrl();
    sessionStorage.removeItem("previousHash");
    if (isAccessTokenHash()) {
    }
    else {
        sessionStorage.setItem("previousHash", window.location.hash);
    }
    window.location.href = url;
}

export function avatarUrl() {
    let matchValue_1;
    const json = sessionStorage.getItem("accessToken");
    const matchValue = json == null;
    if (matchValue) {
        return void 0;
    }
    else {
        return (matchValue_1 = SimpleJson_tryParse(json), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(AccessToken$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()).AvatarUrl;
    }
}

export function emailAddress() {
    let matchValue_1;
    const json = sessionStorage.getItem("accessToken");
    const matchValue = json == null;
    if (matchValue) {
        return "";
    }
    else {
        return (matchValue_1 = SimpleJson_tryParse(json), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(AccessToken$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()).EmailAddress;
    }
}

export function sessionId() {
    let matchValue_1;
    const json = sessionStorage.getItem("accessToken");
    const matchValue = json == null;
    if (matchValue) {
        return new FSharpResult$2(1, "No token");
    }
    else {
        return new FSharpResult$2(0, (matchValue_1 = SimpleJson_tryParse(json), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(AccessToken$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()).SessionId);
    }
}

export function userId() {
    let matchValue_1;
    const json = sessionStorage.getItem("accessToken");
    const matchValue = json == null;
    if (matchValue) {
        return new FSharpResult$2(1, "No token");
    }
    else {
        return new FSharpResult$2(0, (matchValue_1 = SimpleJson_tryParse(json), (matchValue_1 != null) ? Convert_fromJson(matchValue_1, createTypeInfo(AccessToken$reflection())) : (() => {
            throw (new Error("Couldn\u0027t parse the input JSON string because it seems to be invalid"));
        })()).UserId);
    }
}

