import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { zones as zones_1 } from "../../Server.fs.js";
import { SubmitPowerZonesRes, Item_get_Empty, Item, GetPowerZonessRes } from "./Types.fs.js";

export class ErrorGettingPowerZonesException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorGettingPowerZonesException$reflection() {
    return class_type("PowerZones.Index.Api.ErrorGettingPowerZonesException", void 0, ErrorGettingPowerZonesException, class_type("System.Exception"));
}

function ErrorGettingPowerZonesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorGettingPowerZonesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class ErrorSavingPowerZonesException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorSavingPowerZonesException$reflection() {
    return class_type("PowerZones.Index.Api.ErrorSavingPowerZonesException", void 0, ErrorSavingPowerZonesException, class_type("System.Exception"));
}

function ErrorSavingPowerZonesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorSavingPowerZonesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getPowerZoness(context) {
    return singleton.Delay(() => singleton.Bind(zones_1((api) => api.getPowerZones()), (_arg1) => {
        let zones, exn;
        const apiResponse = _arg1;
        return singleton.Return(new GetPowerZonessRes(0, (apiResponse.tag === 0) ? (zones = apiResponse.fields[0], new Item(~(~zones.ActiveRecovery), ~(~zones.Endurance), ~(~zones.Tempo), ~(~zones.LactateThreshold), ~(~zones.VO2Max), ~(~zones.AnaerobicCapacity), ~(~zones.Neuromuscular), ~(~zones.CurrentFtp))) : ((apiResponse.tag === 1) ? Item_get_Empty() : (exn = (new ErrorGettingPowerZonesException()), (() => {
            throw exn;
        })()))));
    }));
}

export function savePowerZones(zones) {
    return singleton.Delay(() => singleton.Bind(zones_1((api) => api.setPowerZones(zones)), (_arg1) => {
        const apiResponse = _arg1;
        return singleton.Return((apiResponse.tag === 0) ? (new SubmitPowerZonesRes(0)) : ((apiResponse.tag === 1) ? (new SubmitPowerZonesRes(0)) : (new SubmitPowerZonesRes(1, new ErrorSavingPowerZonesException()))));
    }));
}

