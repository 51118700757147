import { GetCardioActivitiesRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { getCardioActivities } from "./Api.fs.js";

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(0))];
}

export function update(context, msg, model) {
    if (msg.tag === 1) {
        const result = msg.fields[0];
        if (result.tag === 1) {
            console.error(some(result.fields[0]));
            return [new Model(model.Items, false), Cmd_none()];
        }
        else {
            return [new Model(result.fields[0], false), Cmd_none()];
        }
    }
    else {
        return [new Model(model.Items, true), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, getCardioActivities, context, (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetCardioActivitiesRes(1, arg))))];
    }
}

