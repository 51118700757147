import * as react from "react";
import { view } from "../../Spinner.fs.js";
import { textInputWithAddon } from "../../Form.fs.js";
import { RecordMetricModalMsg } from "./Types.fs.js";
import { ValidationState__IsValid, ValidationState__IsPropertyInError_Z721C83C5 } from "../../../../shared/SimpleValidation.fs.js";
import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { primary } from "../../Buttons.fs.js";

const weightIcon = react.createElement("svg", {
    className: "h-6 w-6 text-blue-600",
    xmlns: "http://www.w3.org/2000/svg",
    stroke: "currentColor",
    fill: "currentColor",
    viewBox: "0 0 512 512",
}, react.createElement("path", {
    d: "M448 64h-64.81C353.95 25.38 308.07 0 256 0s-97.95 25.38-127.19 64H64C28.71 64 0 92.71 0 128v320c0 35.29 28.71 64 64 64h384c35.29 0 64-28.71 64-64V128c0-35.29-28.71-64-64-64zM256 48c61.86 0 112 50.14 112 112s-50.14 112-112 112-112-50.14-112-112S194.14 48 256 48zm208 400c0 8.84-7.16 16-16 16H64c-8.84 0-16-7.16-16-16V128c0-8.84 7.16-16 16-16h40.17C99.33 127.25 96 143.17 96 160c0 88.22 71.78 160 160 160s160-71.78 160-160c0-16.83-3.33-32.75-8.17-48H448c8.84 0 16 7.16 16 16v320zM256 240c17.67 0 32-14.33 32-32 0-8.06-3.25-15.22-8.18-20.85l23.36-70.09c6.66-20.08-23.63-30.2-30.38-10.12l-23.47 70.41C234.97 180.49 224 192.69 224 208c0 17.67 14.33 32 32 32z",
}));

export function root(context, model, dispatch) {
    const spinnerStyle = model.IsSaving ? "flex flex-row justify-center items-center" : "hidden flex flex-row justify-center items-center";
    return react.createElement("div", {}, react.createElement("div", {
        className: "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100",
    }, weightIcon), react.createElement("div", {
        className: "h-20 min-h-full flex flex-column justify-around",
    }, react.createElement("div", {
        className: spinnerStyle,
    }, view), react.createElement("div", {
        className: model.IsSaving ? "w-full hidden" : "w-full",
    }, react.createElement("label", {
        className: "mt-4 block text-sm font-medium leading-5 text-gray-700",
    }, model.Item.Label), react.createElement("div", {
        className: "",
    }, react.createElement("span", {
        className: "flex w-full rounded-md shadow-sm",
    }, textInputWithAddon("kg", (arg) => {
        dispatch(new RecordMetricModalMsg(0, arg));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Value"), defaultArg(model.Item.Value, "")))))), react.createElement("div", {
        className: "flex flex-row mt-4",
    }, primary("Save", () => {
        dispatch(new RecordMetricModalMsg(1));
    }, !ValidationState__IsValid(model.ValidationState))));
}

