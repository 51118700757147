import { RemoveNewUserStatusRes, Msg, Model, Model_Empty_1505 } from "./Types.fs.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { removeNewUserStatus } from "./Api.fs.js";
import { DashboardPage, Page, modifyLocation } from "../../Router.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(id) {
    return [Model_Empty_1505(id), Cmd_none()];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 1: {
            return [new Model(model.IsSaving, !model.SignupToNewsletter), Cmd_none()];
        }
        case 2: {
            return [new Model(true, model.SignupToNewsletter), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, (context_1) => removeNewUserStatus(model.SignupToNewsletter, context_1), (x) => x, (arg0) => (new Msg(3, arg0)), (arg) => (new Msg(3, new RemoveNewUserStatusRes(1, arg))))];
        }
        case 3: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                modifyLocation(new Page(9, new DashboardPage(0)));
                console.error(some(result.fields[0]));
                return [new Model(false, model.SignupToNewsletter), Cmd_none()];
            }
            else {
                modifyLocation(new Page(9, new DashboardPage(0)));
                return [new Model(false, model.SignupToNewsletter), Cmd_none()];
            }
        }
        default: {
            return [model, Cmd_OfFunc_result(new Msg(2))];
        }
    }
}

