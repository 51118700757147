import { Union, Record } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { bool_type, array_type, option_type, string_type, int32_type, union_type, class_type, record_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";
import { parse, newGuid } from "../client/src/.fable/fable-library.3.0.0/Guid.js";
import { sumBy } from "../client/src/.fable/fable-library.3.0.0/Seq.js";
import { defaultArg } from "../client/src/.fable/fable-library.3.0.0/Option.js";

export class MinMax$1 extends Record {
    constructor(Min, Max) {
        super();
        this.Min = Min;
        this.Max = Max;
    }
}

export function MinMax$1$reflection(gen0) {
    return record_type("Shared.PowerProgramme.MinMax`1", [gen0], MinMax$1, () => [["Min", gen0], ["Max", gen0]]);
}

export class IntervalId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IntervalId"];
    }
}

export function IntervalId$reflection() {
    return union_type("Shared.PowerProgramme.IntervalId", [], IntervalId, () => [[["Item", class_type("System.Guid")]]]);
}

export function IntervalId_Create() {
    return new IntervalId(0, newGuid());
}

export class BlockId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["BlockId"];
    }
}

export function BlockId$reflection() {
    return union_type("Shared.PowerProgramme.BlockId", [], BlockId, () => [[["Item", class_type("System.Guid")]]]);
}

export function BlockId_Create() {
    return new BlockId(0, newGuid());
}

export class ProgrammeId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ProgrammeId"];
    }
}

export function ProgrammeId$reflection() {
    return union_type("Shared.PowerProgramme.ProgrammeId", [], ProgrammeId, () => [[["Item", class_type("System.Guid")]]]);
}

export function ProgrammeId_Create() {
    return new ProgrammeId(0, newGuid());
}

export function ProgrammeId_CreateFromString_Z721C83C5(asString) {
    return new ProgrammeId(0, parse(asString));
}

export function ProgrammeId_CreateFromGuid_244AC511(guid) {
    return new ProgrammeId(0, guid);
}

export function ProgrammeId__AsString(x) {
    return x.fields[0];
}

export function ProgrammeId__AsGuid(x) {
    return x.fields[0];
}

export class IntervalType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PowerRange", "PowerTarget"];
    }
}

export function IntervalType$reflection() {
    return union_type("Shared.PowerProgramme.IntervalType", [], IntervalType, () => [[["Item", MinMax$1$reflection(int32_type)]], [["Item", int32_type]]]);
}

export function IntervalType__get_Min(x) {
    if (x.tag === 1) {
        return x.fields[0] | 0;
    }
    else {
        return x.fields[0].Min | 0;
    }
}

export function IntervalType__get_Max(x) {
    if (x.tag === 1) {
        return x.fields[0] | 0;
    }
    else {
        return x.fields[0].Max | 0;
    }
}

export class Length extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Duration", "Distance"];
    }
}

export function Length$reflection() {
    return union_type("Shared.PowerProgramme.Length", [], Length, () => [[["Item", int32_type]], [["Item", int32_type]]]);
}

export function Length__get_Value(x) {
    if (x.tag === 1) {
        return x.fields[0] | 0;
    }
    else {
        return x.fields[0] | 0;
    }
}

export class Interval extends Record {
    constructor(IntervalId, Name, Power, Length, Cadence) {
        super();
        this.IntervalId = IntervalId;
        this.Name = Name;
        this.Power = Power;
        this.Length = Length;
        this.Cadence = Cadence;
    }
}

export function Interval$reflection() {
    return record_type("Shared.PowerProgramme.Interval", [], Interval, () => [["IntervalId", IntervalId$reflection()], ["Name", option_type(string_type)], ["Power", IntervalType$reflection()], ["Length", Length$reflection()], ["Cadence", option_type(MinMax$1$reflection(int32_type))]]);
}

export class Block extends Record {
    constructor(BlockId, Name, Intervals, Repeats) {
        super();
        this.BlockId = BlockId;
        this.Name = Name;
        this.Intervals = Intervals;
        this.Repeats = Repeats;
    }
}

export function Block$reflection() {
    return record_type("Shared.PowerProgramme.Block", [], Block, () => [["BlockId", BlockId$reflection()], ["Name", option_type(string_type)], ["Intervals", array_type(Interval$reflection())], ["Repeats", option_type(int32_type)]]);
}

export function Block__get_Length(x) {
    return sumBy((i) => Length__get_Value(i.Length), x.Intervals, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    }) * defaultArg(x.Repeats, 1);
}

export class Programme extends Record {
    constructor(Id, Name, Description, Blocks, TrainingStress) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Blocks = Blocks;
        this.TrainingStress = (TrainingStress | 0);
    }
}

export function Programme$reflection() {
    return record_type("Shared.PowerProgramme.Programme", [], Programme, () => [["Id", ProgrammeId$reflection()], ["Name", string_type], ["Description", option_type(string_type)], ["Blocks", array_type(Block$reflection())], ["TrainingStress", int32_type]]);
}

export function Programme_get_Empty() {
    return new Programme(ProgrammeId_Create(), "", void 0, new Array(0), 0);
}

export function Programme__get_Length(x) {
    return sumBy(Block__get_Length, x.Blocks, {
        GetZero: () => 0,
        Add: (x_1, y) => (x_1 + y),
    });
}

export class ProgrammeSummary extends Record {
    constructor(Id, Name, TrainingStress, Duration, CreatedByUser, IsSystem, CreatedAtUtc) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.TrainingStress = (TrainingStress | 0);
        this.Duration = Duration;
        this.CreatedByUser = CreatedByUser;
        this.IsSystem = IsSystem;
        this.CreatedAtUtc = CreatedAtUtc;
    }
}

export function ProgrammeSummary$reflection() {
    return record_type("Shared.PowerProgramme.ProgrammeSummary", [], ProgrammeSummary, () => [["Id", ProgrammeId$reflection()], ["Name", string_type], ["TrainingStress", int32_type], ["Duration", Length$reflection()], ["CreatedByUser", bool_type], ["IsSystem", bool_type], ["CreatedAtUtc", class_type("System.DateTime")]]);
}

