import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { round } from "./.fable/fable-library.3.0.0/Util.js";
import { fromSeconds, hours, days, minutes, seconds as seconds_1 } from "./.fable/fable-library.3.0.0/TimeSpan.js";
import { bind } from "./.fable/fable-library.3.0.0/Option.js";
import { ofArray } from "./.fable/fable-library.3.0.0/List.js";
import { tryFind, filter } from "./.fable/fable-library.3.0.0/Seq.js";

export function distance(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const arg10 = value / 1000;
        return toText(printf("%.1f"))(arg10);
    }
}

export function roundedDistance(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const arg10 = round(value / 1000);
        return toText(printf("%.0f"))(arg10);
    }
}

export function distanceUnits(context) {
    return "km";
}

export function distanceFloat(context, value) {
    return value / 1000;
}

export function speed(context, valueMetersPerSecond) {
    if (valueMetersPerSecond == null) {
        return "";
    }
    else {
        const arg10 = valueMetersPerSecond * 3.6;
        return toText(printf("%.1f"))(arg10);
    }
}

export function speedUnits(context) {
    return "km/h";
}

export function speedFloat(context, valueMetersPerSecond) {
    return valueMetersPerSecond * 3.6;
}

export function ascent(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function ascentUnits(context) {
    return "m";
}

export function ascentFloat(context, value) {
    return value;
}

export function elevation(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value;
        return toText(printf("%.1f"))(v);
    }
}

export function elevationUnits(context) {
    return "m";
}

export function elevationFloat(context, value) {
    return value;
}

export function elapsedTimeFromTimeSpan(context, timeSpan) {
    if (timeSpan == null) {
        return "";
    }
    else {
        const t = timeSpan;
        const arg30 = seconds_1(t) | 0;
        const arg20 = minutes(t) | 0;
        const arg10 = ((days(t) * 24) + hours(t)) | 0;
        return toText(printf("%d:%02d:%02d"))(arg10)(arg20)(arg30);
    }
}

export function elapsedTimeFromSeconds(context, value) {
    return elapsedTimeFromTimeSpan(context, bind(fromSeconds, value));
}

export function elapsedTimeFromSecondsUnits(_arg1) {
    return "";
}

export function normalisedPower(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function normalisedPowerUnits(context) {
    return "watts";
}

export function power(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function powerUnits(context) {
    return "watts";
}

export function powerWeightUnits(context) {
    return "watts/kgs";
}

export function cadence(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function cadenceUnits(context) {
    return "rpm";
}

export function heartRate(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function heartRateUnits(context) {
    return "bpm";
}

export function weight(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value;
        return toText(printf("%.1f"))(v);
    }
}

export function weightUnits(context) {
    return "kg";
}

export function calories(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value | 0;
        return toText(printf("%d"))(v);
    }
}

export function caloriesUnits(context) {
    return "kcal";
}

export function trainingStress(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value;
        return toText(printf("%.0f"))(v);
    }
}

export function trainingStressUnits(context) {
    return "";
}

export function intensityFactor(context, value) {
    if (value == null) {
        return "";
    }
    else {
        const v = value;
        return toText(printf("%.2f"))(v);
    }
}

export function intensityFactorUnits(context) {
    return "";
}

export function durationFromSeconds(seconds) {
    const ts = fromSeconds(seconds);
    const arg30 = seconds_1(ts) | 0;
    const arg20 = minutes(ts) | 0;
    const arg10 = hours(ts) | 0;
    return toText(printf("%02d:%02d:%02d"))(arg10)(arg20)(arg30);
}

export function optionalDurationFromSeconds(seconds) {
    if (seconds == null) {
        return "";
    }
    else {
        const ts = fromSeconds(seconds);
        const arg30 = seconds_1(ts) | 0;
        const arg20 = minutes(ts) | 0;
        const arg10 = hours(ts) | 0;
        return toText(printf("%02d:%02d:%02d"))(arg10)(arg20)(arg30);
    }
}

export function shortDurationAsString(duration) {
    if (duration >= 60) {
        const arg10 = (~(~(duration / 60))) | 0;
        return toText(printf("%dm"))(arg10);
    }
    else {
        return toText(printf("%ds"))(duration);
    }
}

export const Peaks_keyDurationIntervalsSeconds = ofArray([2, 5, 10, 30, 60, 60 * 5, 60 * 10, 60 * 20, 60 * 30, 60 * 60]);

export function Peaks_filterDurationsToKeyDurations(durations) {
    return filter((p) => {
        const matchValue = tryFind((f) => (f === p), Peaks_keyDurationIntervalsSeconds);
        if (matchValue == null) {
            return false;
        }
        else {
            return true;
        }
    }, durations);
}

export function Peaks_filterToKeyDurations(peaks) {
    return filter((p) => {
        const matchValue = tryFind((f) => (f === p.PeakDurationSeconds), Peaks_keyDurationIntervalsSeconds);
        if (matchValue == null) {
            return false;
        }
        else {
            return true;
        }
    }, peaks);
}

