import { Union, Record } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { array_type, union_type, list_type, float64_type, int32_type, record_type, option_type, bool_type, class_type, string_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";
import { Id_value, IndexPosition__IndexPosition$reflection, OptionalId__OptionalId$reflection, Seconds__Seconds$reflection, WeightInKg__WeightInKg$reflection, Description__Description$reflection, Name__Name$reflection, Id__Id$reflection } from "./Shared.Types.fs.js";
import { toShortDateString, utcNow } from "../client/src/.fable/fable-library.3.0.0/Date.js";
import { Programme$reflection, ProgrammeSummary$reflection } from "./Shared.PowerProgramme.fs.js";
import { printf, toText } from "../client/src/.fable/fable-library.3.0.0/String.js";
import { item, ofArray } from "../client/src/.fable/fable-library.3.0.0/List.js";
import { find, skipWhile, head } from "../client/src/.fable/fable-library.3.0.0/Seq.js";

export class AccessToken extends Record {
    constructor(Token, ExpiresAtUtc, TokenType, RefreshImmediately, AvatarUrl, EmailAddress, IsInQueue, IsNewUser, SessionId, UserId) {
        super();
        this.Token = Token;
        this.ExpiresAtUtc = ExpiresAtUtc;
        this.TokenType = TokenType;
        this.RefreshImmediately = RefreshImmediately;
        this.AvatarUrl = AvatarUrl;
        this.EmailAddress = EmailAddress;
        this.IsInQueue = IsInQueue;
        this.IsNewUser = IsNewUser;
        this.SessionId = SessionId;
        this.UserId = UserId;
    }
}

export function AccessToken$reflection() {
    return record_type("Shared.Models.AccessToken", [], AccessToken, () => [["Token", string_type], ["ExpiresAtUtc", class_type("System.DateTime")], ["TokenType", string_type], ["RefreshImmediately", bool_type], ["AvatarUrl", option_type(string_type)], ["EmailAddress", string_type], ["IsInQueue", bool_type], ["IsNewUser", bool_type], ["SessionId", class_type("System.Guid")], ["UserId", string_type]]);
}

export class Lift extends Record {
    constructor(Id, Name, Description, IsActive, CreatedAtUtc) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.IsActive = IsActive;
        this.CreatedAtUtc = CreatedAtUtc;
    }
}

export function Lift$reflection() {
    return record_type("Shared.Models.Lift", [], Lift, () => [["Id", Id__Id$reflection()], ["Name", Name__Name$reflection()], ["Description", Description__Description$reflection()], ["IsActive", bool_type], ["CreatedAtUtc", class_type("System.DateTime")]]);
}

export class ActivityMaximums extends Record {
    constructor(MaxAverageHeartRate, MaxMaximumHeartRate, MaxDistanceMeters, MaxDuration, MaxAverageSpeedMetersPerSecond, MaxMaximumSpeedMetersPerSecond, MaxAveragePower, MaxNormalisedPower, MaxMaximumPower, MaxElevationGainedMeters, MaxTrainingStress, MaxIntensity) {
        super();
        this.MaxAverageHeartRate = MaxAverageHeartRate;
        this.MaxMaximumHeartRate = MaxMaximumHeartRate;
        this.MaxDistanceMeters = MaxDistanceMeters;
        this.MaxDuration = MaxDuration;
        this.MaxAverageSpeedMetersPerSecond = MaxAverageSpeedMetersPerSecond;
        this.MaxMaximumSpeedMetersPerSecond = MaxMaximumSpeedMetersPerSecond;
        this.MaxAveragePower = MaxAveragePower;
        this.MaxNormalisedPower = MaxNormalisedPower;
        this.MaxMaximumPower = MaxMaximumPower;
        this.MaxElevationGainedMeters = MaxElevationGainedMeters;
        this.MaxTrainingStress = MaxTrainingStress;
        this.MaxIntensity = MaxIntensity;
    }
}

export function ActivityMaximums$reflection() {
    return record_type("Shared.Models.ActivityMaximums", [], ActivityMaximums, () => [["MaxAverageHeartRate", option_type(int32_type)], ["MaxMaximumHeartRate", option_type(int32_type)], ["MaxDistanceMeters", option_type(float64_type)], ["MaxDuration", option_type(int32_type)], ["MaxAverageSpeedMetersPerSecond", option_type(float64_type)], ["MaxMaximumSpeedMetersPerSecond", option_type(float64_type)], ["MaxAveragePower", option_type(int32_type)], ["MaxNormalisedPower", option_type(int32_type)], ["MaxMaximumPower", option_type(int32_type)], ["MaxElevationGainedMeters", option_type(int32_type)], ["MaxTrainingStress", option_type(float64_type)], ["MaxIntensity", option_type(float64_type)]]);
}

export class CardioActivityCategory extends Record {
    constructor(Id, Code, DisplayText, IsActive) {
        super();
        this.Id = Id;
        this.Code = Code;
        this.DisplayText = DisplayText;
        this.IsActive = IsActive;
    }
}

export function CardioActivityCategory$reflection() {
    return record_type("Shared.Models.CardioActivityCategory", [], CardioActivityCategory, () => [["Id", Id__Id$reflection()], ["Code", string_type], ["DisplayText", string_type], ["IsActive", bool_type]]);
}

export class Lookups extends Record {
    constructor(Lifts, CardioCategories) {
        super();
        this.Lifts = Lifts;
        this.CardioCategories = CardioCategories;
    }
}

export function Lookups$reflection() {
    return record_type("Shared.Models.Lookups", [], Lookups, () => [["Lifts", list_type(Lift$reflection())], ["CardioCategories", list_type(CardioActivityCategory$reflection())]]);
}

export class CardioType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Run", "Cycle", "Swim", "Walk", "Mixed", "Unknown"];
    }
}

export function CardioType$reflection() {
    return union_type("Shared.Models.CardioType", [], CardioType, () => [[], [], [], [], [], []]);
}

export class GeoPosition extends Record {
    constructor(Latitude, Longitude) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
    }
}

export function GeoPosition$reflection() {
    return record_type("Shared.Models.GeoPosition", [], GeoPosition, () => [["Latitude", float64_type], ["Longitude", float64_type]]);
}

export class CardioMetrics extends Record {
    constructor(ActivityType, RecordedAtUtc, DistanceMeters, TimeSeconds, AverageSpeedMetersPerSecond, MaximumSpeedMetersPerSecond, AverageHeartRate, MaximumHeartRate, Calories, AverageCadence, MaximumCadence, AveragePower, MaximumPower, NormalisedPower, ElevationGainedMeters, MaximumAltitudeMeters, MinimumAltitudeMeters, Center, FtpAtTimeOfRide, IntensityFactor, TrainingStressScore) {
        super();
        this.ActivityType = ActivityType;
        this.RecordedAtUtc = RecordedAtUtc;
        this.DistanceMeters = DistanceMeters;
        this.TimeSeconds = TimeSeconds;
        this.AverageSpeedMetersPerSecond = AverageSpeedMetersPerSecond;
        this.MaximumSpeedMetersPerSecond = MaximumSpeedMetersPerSecond;
        this.AverageHeartRate = AverageHeartRate;
        this.MaximumHeartRate = MaximumHeartRate;
        this.Calories = Calories;
        this.AverageCadence = AverageCadence;
        this.MaximumCadence = MaximumCadence;
        this.AveragePower = AveragePower;
        this.MaximumPower = MaximumPower;
        this.NormalisedPower = NormalisedPower;
        this.ElevationGainedMeters = ElevationGainedMeters;
        this.MaximumAltitudeMeters = MaximumAltitudeMeters;
        this.MinimumAltitudeMeters = MinimumAltitudeMeters;
        this.Center = Center;
        this.FtpAtTimeOfRide = FtpAtTimeOfRide;
        this.IntensityFactor = IntensityFactor;
        this.TrainingStressScore = TrainingStressScore;
    }
}

export function CardioMetrics$reflection() {
    return record_type("Shared.Models.CardioMetrics", [], CardioMetrics, () => [["ActivityType", CardioType$reflection()], ["RecordedAtUtc", class_type("System.DateTime")], ["DistanceMeters", option_type(float64_type)], ["TimeSeconds", option_type(int32_type)], ["AverageSpeedMetersPerSecond", option_type(float64_type)], ["MaximumSpeedMetersPerSecond", option_type(float64_type)], ["AverageHeartRate", option_type(int32_type)], ["MaximumHeartRate", option_type(int32_type)], ["Calories", option_type(int32_type)], ["AverageCadence", option_type(int32_type)], ["MaximumCadence", option_type(int32_type)], ["AveragePower", option_type(int32_type)], ["MaximumPower", option_type(int32_type)], ["NormalisedPower", option_type(int32_type)], ["ElevationGainedMeters", option_type(int32_type)], ["MaximumAltitudeMeters", option_type(int32_type)], ["MinimumAltitudeMeters", option_type(int32_type)], ["Center", option_type(GeoPosition$reflection())], ["FtpAtTimeOfRide", option_type(int32_type)], ["IntensityFactor", option_type(float64_type)], ["TrainingStressScore", option_type(float64_type)]]);
}

export class CardioSegment extends Record {
    constructor(Time, Position, DistanceMeters, DistanceDeltaMeters, HeartRate, SpeedMetersPerSecond, Power, AltitudeMeters, ElevationDeltaMeters, Cadence) {
        super();
        this.Time = Time;
        this.Position = Position;
        this.DistanceMeters = DistanceMeters;
        this.DistanceDeltaMeters = DistanceDeltaMeters;
        this.HeartRate = HeartRate;
        this.SpeedMetersPerSecond = SpeedMetersPerSecond;
        this.Power = Power;
        this.AltitudeMeters = AltitudeMeters;
        this.ElevationDeltaMeters = ElevationDeltaMeters;
        this.Cadence = Cadence;
    }
}

export function CardioSegment$reflection() {
    return record_type("Shared.Models.CardioSegment", [], CardioSegment, () => [["Time", class_type("System.DateTime")], ["Position", option_type(GeoPosition$reflection())], ["DistanceMeters", option_type(float64_type)], ["DistanceDeltaMeters", option_type(float64_type)], ["HeartRate", option_type(int32_type)], ["SpeedMetersPerSecond", option_type(float64_type)], ["Power", option_type(int32_type)], ["AltitudeMeters", option_type(float64_type)], ["ElevationDeltaMeters", option_type(float64_type)], ["Cadence", option_type(int32_type)]]);
}

export function CardioSegment_get_Empty() {
    return new CardioSegment(utcNow(), void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0);
}

export class CardioLap extends Record {
    constructor(Metrics, DataPoints) {
        super();
        this.Metrics = Metrics;
        this.DataPoints = DataPoints;
    }
}

export function CardioLap$reflection() {
    return record_type("Shared.Models.CardioLap", [], CardioLap, () => [["Metrics", CardioMetrics$reflection()], ["DataPoints", list_type(CardioSegment$reflection())]]);
}

export class CategoryIdIncludingAll extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AllCategories", "OfCategoryId"];
    }
}

export function CategoryIdIncludingAll$reflection() {
    return union_type("Shared.Models.CategoryIdIncludingAll", [], CategoryIdIncludingAll, () => [[], [["Item", string_type]]]);
}

export class PlannedPowerWorkoutSummary extends Record {
    constructor(PlannedAtUtc, CalendarEventId, Programme) {
        super();
        this.PlannedAtUtc = PlannedAtUtc;
        this.CalendarEventId = CalendarEventId;
        this.Programme = Programme;
    }
}

export function PlannedPowerWorkoutSummary$reflection() {
    return record_type("Shared.Models.PlannedPowerWorkoutSummary", [], PlannedPowerWorkoutSummary, () => [["PlannedAtUtc", class_type("System.DateTime")], ["CalendarEventId", Id__Id$reflection()], ["Programme", ProgrammeSummary$reflection()]]);
}

export class CardioSummary extends Record {
    constructor(Id, FileId, UploadedAtUtc, HasRoute, ActivityType, Metrics, CategoryId, Title, WeightAtTimeOfRideKg) {
        super();
        this.Id = Id;
        this.FileId = FileId;
        this.UploadedAtUtc = UploadedAtUtc;
        this.HasRoute = HasRoute;
        this.ActivityType = ActivityType;
        this.Metrics = Metrics;
        this.CategoryId = CategoryId;
        this.Title = Title;
        this.WeightAtTimeOfRideKg = WeightAtTimeOfRideKg;
    }
}

export function CardioSummary$reflection() {
    return record_type("Shared.Models.CardioSummary", [], CardioSummary, () => [["Id", string_type], ["FileId", string_type], ["UploadedAtUtc", class_type("System.DateTime")], ["HasRoute", bool_type], ["ActivityType", CardioType$reflection()], ["Metrics", CardioMetrics$reflection()], ["CategoryId", string_type], ["Title", option_type(string_type)], ["WeightAtTimeOfRideKg", option_type(float64_type)]]);
}

export function CardioSummary__get_Sport(x) {
    const matchValue = x.ActivityType;
    switch (matchValue.tag) {
        case 0: {
            return "Run";
        }
        case 3: {
            return "Walk";
        }
        case 2: {
            return "Swim";
        }
        case 4: {
            return "Mixed";
        }
        case 5: {
            return "Unknown";
        }
        default: {
            return "Cycle";
        }
    }
}

export function CardioSummary__get_CalculatedTitle(x) {
    const matchValue = x.Title;
    if (matchValue == null) {
        const arg20 = toShortDateString(x.Metrics.RecordedAtUtc);
        const arg10 = CardioSummary__get_Sport(x);
        return toText(printf("%s on %s"))(arg10)(arg20);
    }
    else {
        return matchValue;
    }
}

export class StrengthSummary extends Record {
    constructor(Id, FileId, RecordedAtUtc, UploadedAtUtc, TimeSeconds, AverageHeartRate, MaximumHeartRate, Calories, TotalWeightLifted) {
        super();
        this.Id = Id;
        this.FileId = FileId;
        this.RecordedAtUtc = RecordedAtUtc;
        this.UploadedAtUtc = UploadedAtUtc;
        this.TimeSeconds = TimeSeconds;
        this.AverageHeartRate = AverageHeartRate;
        this.MaximumHeartRate = MaximumHeartRate;
        this.Calories = Calories;
        this.TotalWeightLifted = TotalWeightLifted;
    }
}

export function StrengthSummary$reflection() {
    return record_type("Shared.Models.StrengthSummary", [], StrengthSummary, () => [["Id", string_type], ["FileId", option_type(string_type)], ["RecordedAtUtc", class_type("System.DateTime")], ["UploadedAtUtc", class_type("System.DateTime")], ["TimeSeconds", option_type(int32_type)], ["AverageHeartRate", option_type(int32_type)], ["MaximumHeartRate", option_type(int32_type)], ["Calories", option_type(int32_type)], ["TotalWeightLifted", option_type(float64_type)]]);
}

export class StrengthExercise extends Record {
    constructor(LiftId, Reps, TargetWeight, Sets) {
        super();
        this.LiftId = LiftId;
        this.Reps = (Reps | 0);
        this.TargetWeight = TargetWeight;
        this.Sets = (Sets | 0);
    }
}

export function StrengthExercise$reflection() {
    return record_type("Shared.Models.StrengthExercise", [], StrengthExercise, () => [["LiftId", Id__Id$reflection()], ["Reps", int32_type], ["TargetWeight", WeightInKg__WeightInKg$reflection()], ["Sets", int32_type]]);
}

export class GymActivityType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StrengthExercise", "Rest"];
    }
}

export function GymActivityType$reflection() {
    return union_type("Shared.Models.GymActivityType", [], GymActivityType, () => [[["Item", StrengthExercise$reflection()]], [["Item", Seconds__Seconds$reflection()]]]);
}

export class CreateUpdateActivity extends Record {
    constructor(Id, PositionInWorkout, Type) {
        super();
        this.Id = Id;
        this.PositionInWorkout = PositionInWorkout;
        this.Type = Type;
    }
}

export function CreateUpdateActivity$reflection() {
    return record_type("Shared.Models.CreateUpdateActivity", [], CreateUpdateActivity, () => [["Id", OptionalId__OptionalId$reflection()], ["PositionInWorkout", IndexPosition__IndexPosition$reflection()], ["Type", GymActivityType$reflection()]]);
}

export class CreateUpdateWorkoutTemplate extends Record {
    constructor(Id, IsPublic, Name, Description, Activities) {
        super();
        this.Id = Id;
        this.IsPublic = IsPublic;
        this.Name = Name;
        this.Description = Description;
        this.Activities = Activities;
    }
}

export function CreateUpdateWorkoutTemplate$reflection() {
    return record_type("Shared.Models.CreateUpdateWorkoutTemplate", [], CreateUpdateWorkoutTemplate, () => [["Id", OptionalId__OptionalId$reflection()], ["IsPublic", bool_type], ["Name", Name__Name$reflection()], ["Description", Description__Description$reflection()], ["Activities", list_type(CreateUpdateActivity$reflection())]]);
}

export class GymActivity extends Record {
    constructor(Id, PositionInWorkout, Type) {
        super();
        this.Id = Id;
        this.PositionInWorkout = PositionInWorkout;
        this.Type = Type;
    }
}

export function GymActivity$reflection() {
    return record_type("Shared.Models.GymActivity", [], GymActivity, () => [["Id", Id__Id$reflection()], ["PositionInWorkout", IndexPosition__IndexPosition$reflection()], ["Type", GymActivityType$reflection()]]);
}

export class WorkoutTemplate extends Record {
    constructor(Id, CreatedByUserId, IsPublic, CreatedAtUtc, Name, Description, Activities) {
        super();
        this.Id = Id;
        this.CreatedByUserId = CreatedByUserId;
        this.IsPublic = IsPublic;
        this.CreatedAtUtc = CreatedAtUtc;
        this.Name = Name;
        this.Description = Description;
        this.Activities = Activities;
    }
}

export function WorkoutTemplate$reflection() {
    return record_type("Shared.Models.WorkoutTemplate", [], WorkoutTemplate, () => [["Id", Id__Id$reflection()], ["CreatedByUserId", OptionalId__OptionalId$reflection()], ["IsPublic", bool_type], ["CreatedAtUtc", class_type("System.DateTime")], ["Name", Name__Name$reflection()], ["Description", Description__Description$reflection()], ["Activities", list_type(GymActivity$reflection())]]);
}

export class WorkoutTemplateSummary extends Record {
    constructor(Id, IsCreatedByCurrentUser, Name, Description) {
        super();
        this.Id = Id;
        this.IsCreatedByCurrentUser = IsCreatedByCurrentUser;
        this.Name = Name;
        this.Description = Description;
    }
}

export function WorkoutTemplateSummary$reflection() {
    return record_type("Shared.Models.WorkoutTemplateSummary", [], WorkoutTemplateSummary, () => [["Id", Id__Id$reflection()], ["IsCreatedByCurrentUser", bool_type], ["Name", Name__Name$reflection()], ["Description", Description__Description$reflection()]]);
}

export class MetricType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Weight", "TargetWeight", "Calories", "TargetCalories", "RestingHeartRate"];
    }
}

export function MetricType$reflection() {
    return union_type("Shared.Models.MetricType", [], MetricType, () => [[], [], [], [], []]);
}

export class Metric extends Record {
    constructor(Id, MetricType, RecordedValue, RecordedAtUtc) {
        super();
        this.Id = Id;
        this.MetricType = MetricType;
        this.RecordedValue = RecordedValue;
        this.RecordedAtUtc = RecordedAtUtc;
    }
}

export function Metric$reflection() {
    return record_type("Shared.Models.Metric", [], Metric, () => [["Id", Id__Id$reflection()], ["MetricType", MetricType$reflection()], ["RecordedValue", float64_type], ["RecordedAtUtc", class_type("System.DateTime")]]);
}

export class AggregateMetric extends Record {
    constructor(MetricType, AggregateValue, WeekBeginning) {
        super();
        this.MetricType = MetricType;
        this.AggregateValue = AggregateValue;
        this.WeekBeginning = WeekBeginning;
    }
}

export function AggregateMetric$reflection() {
    return record_type("Shared.Models.AggregateMetric", [], AggregateMetric, () => [["MetricType", MetricType$reflection()], ["AggregateValue", float64_type], ["WeekBeginning", class_type("System.DateTime")]]);
}

export class CalendarActivity extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CardioCalendarActivity", "PlannedPowerWorkoutCalendarActivity", "StrengthCalendarActivity"];
    }
}

export function CalendarActivity$reflection() {
    return union_type("Shared.Models.CalendarActivity", [], CalendarActivity, () => [[["Item", CardioSummary$reflection()]], [["Item", Programme$reflection()]], [["Item", StrengthSummary$reflection()]]]);
}

export class CalendarEvent extends Record {
    constructor(Id, EventDateTimeUtc, Activity) {
        super();
        this.Id = Id;
        this.EventDateTimeUtc = EventDateTimeUtc;
        this.Activity = Activity;
    }
}

export function CalendarEvent$reflection() {
    return record_type("Shared.Models.CalendarEvent", [], CalendarEvent, () => [["Id", Id__Id$reflection()], ["EventDateTimeUtc", class_type("System.DateTime")], ["Activity", CalendarActivity$reflection()]]);
}

export class Page$1 extends Record {
    constructor(Items, PageNumber, NumberOfRows, NumberOfPages, PageSize) {
        super();
        this.Items = Items;
        this.PageNumber = (PageNumber | 0);
        this.NumberOfRows = (NumberOfRows | 0);
        this.NumberOfPages = (NumberOfPages | 0);
        this.PageSize = (PageSize | 0);
    }
}

export function Page$1$reflection(gen0) {
    return record_type("Shared.Models.Page`1", [gen0], Page$1, () => [["Items", array_type(gen0)], ["PageNumber", int32_type], ["NumberOfRows", int32_type], ["NumberOfPages", int32_type], ["PageSize", int32_type]]);
}

export function Page$1_get_Empty() {
    return new Page$1(new Array(0), 0, 0, 0, 0);
}

export class WorkoutType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Cardio", "Strength", "UnknownWorkoutType"];
    }
}

export function WorkoutType$reflection() {
    return union_type("Shared.Models.WorkoutType", [], WorkoutType, () => [[["Item", CardioType$reflection()]], [], []]);
}

export class WorkoutSummary extends Record {
    constructor(Id, WorkoutType, TimeSeconds, AverageHeartRate, DistanceMeters, Calories, RecordedAtUtc) {
        super();
        this.Id = Id;
        this.WorkoutType = WorkoutType;
        this.TimeSeconds = TimeSeconds;
        this.AverageHeartRate = AverageHeartRate;
        this.DistanceMeters = DistanceMeters;
        this.Calories = Calories;
        this.RecordedAtUtc = RecordedAtUtc;
    }
}

export function WorkoutSummary$reflection() {
    return record_type("Shared.Models.WorkoutSummary", [], WorkoutSummary, () => [["Id", Id__Id$reflection()], ["WorkoutType", WorkoutType$reflection()], ["TimeSeconds", option_type(int32_type)], ["AverageHeartRate", option_type(int32_type)], ["DistanceMeters", option_type(float64_type)], ["Calories", option_type(int32_type)], ["RecordedAtUtc", class_type("System.DateTime")]]);
}

export class TrainingLogSummary extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CardioTrainingLogEntry", "StrengthTrainingLogEntry", "PlannedPowerWorkoutLogEntry"];
    }
}

export function TrainingLogSummary$reflection() {
    return union_type("Shared.Models.TrainingLogSummary", [], TrainingLogSummary, () => [[["Item", CardioSummary$reflection()]], [["Item", StrengthSummary$reflection()]], [["Item", PlannedPowerWorkoutSummary$reflection()]]]);
}

export function TrainingLogSummary__get_RecordedAtUtc(x) {
    switch (x.tag) {
        case 1: {
            return x.fields[0].RecordedAtUtc;
        }
        case 2: {
            return x.fields[0].PlannedAtUtc;
        }
        default: {
            return x.fields[0].Metrics.RecordedAtUtc;
        }
    }
}

export function TrainingLogSummary__get_Id(x) {
    switch (x.tag) {
        case 1: {
            return x.fields[0].Id;
        }
        case 2: {
            return Id_value(x.fields[0].CalendarEventId);
        }
        default: {
            return x.fields[0].Id;
        }
    }
}

export function TrainingLogSummary__get_Title(x) {
    switch (x.tag) {
        case 1: {
            return "Strength";
        }
        case 2: {
            return x.fields[0].Programme.Name;
        }
        default: {
            return CardioSummary__get_CalculatedTitle(x.fields[0]);
        }
    }
}

export function TrainingLogSummary__get_ActivityTypeText(x) {
    switch (x.tag) {
        case 1: {
            return "Strength";
        }
        case 2: {
            return "Planned power workout";
        }
        default: {
            const matchValue = x.fields[0].ActivityType;
            switch (matchValue.tag) {
                case 1: {
                    return "Cycling";
                }
                case 2: {
                    return "Swimming";
                }
                case 3: {
                    return "Walking";
                }
                case 4: {
                    return "Mixed";
                }
                case 5: {
                    return "Unknown";
                }
                default: {
                    return "Running";
                }
            }
        }
    }
}

export class LapType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EntireRoutePeak", "LapPeak"];
    }
}

export function LapType$reflection() {
    return union_type("Shared.Models.LapType", [], LapType, () => [[], [["Item", int32_type]]]);
}

export class PeakPerformanceType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["HeartPeak", "PowerPeak", "PowerPeakWattKg", "SpeedPeak", "AscentPeak"];
    }
}

export function PeakPerformanceType$reflection() {
    return union_type("Shared.Models.PeakPerformanceType", [], PeakPerformanceType, () => [[], [], [], [], []]);
}

export class PeakTarget extends Record {
    constructor(Label, PeakValue, PeakDurationSeconds) {
        super();
        this.Label = Label;
        this.PeakValue = PeakValue;
        this.PeakDurationSeconds = (PeakDurationSeconds | 0);
    }
}

export function PeakTarget$reflection() {
    return record_type("Shared.Models.PeakTarget", [], PeakTarget, () => [["Label", string_type], ["PeakValue", float64_type], ["PeakDurationSeconds", int32_type]]);
}

export class PeakPerformance extends Record {
    constructor(StartDateTimeUtc, PeakDurationSeconds, PeakValue, PeakType, Lap, CardioActivitySummaryId, Weight) {
        super();
        this.StartDateTimeUtc = StartDateTimeUtc;
        this.PeakDurationSeconds = (PeakDurationSeconds | 0);
        this.PeakValue = PeakValue;
        this.PeakType = PeakType;
        this.Lap = Lap;
        this.CardioActivitySummaryId = CardioActivitySummaryId;
        this.Weight = Weight;
    }
}

export function PeakPerformance$reflection() {
    return record_type("Shared.Models.PeakPerformance", [], PeakPerformance, () => [["StartDateTimeUtc", class_type("System.DateTime")], ["PeakDurationSeconds", int32_type], ["PeakValue", float64_type], ["PeakType", PeakPerformanceType$reflection()], ["Lap", LapType$reflection()], ["CardioActivitySummaryId", Id__Id$reflection()], ["Weight", option_type(float64_type)]]);
}

export class AggregatePeakPerformance extends Record {
    constructor(PeakDurationInSeconds, PeakValue, PeakType) {
        super();
        this.PeakDurationInSeconds = (PeakDurationInSeconds | 0);
        this.PeakValue = PeakValue;
        this.PeakType = PeakType;
    }
}

export function AggregatePeakPerformance$reflection() {
    return record_type("Shared.Models.AggregatePeakPerformance", [], AggregatePeakPerformance, () => [["PeakDurationInSeconds", int32_type], ["PeakValue", float64_type], ["PeakType", PeakPerformanceType$reflection()]]);
}

export class DateRange extends Record {
    constructor(StartDate, EndDate) {
        super();
        this.StartDate = StartDate;
        this.EndDate = EndDate;
    }
}

export function DateRange$reflection() {
    return record_type("Shared.Models.DateRange", [], DateRange, () => [["StartDate", class_type("System.DateTime")], ["EndDate", class_type("System.DateTime")]]);
}

export class PeakPerformancesForRange extends Record {
    constructor(DateRange, MaxPerformances, AvgPerformances, MaxSummaries) {
        super();
        this.DateRange = DateRange;
        this.MaxPerformances = MaxPerformances;
        this.AvgPerformances = AvgPerformances;
        this.MaxSummaries = MaxSummaries;
    }
}

export function PeakPerformancesForRange$reflection() {
    return record_type("Shared.Models.PeakPerformancesForRange", [], PeakPerformancesForRange, () => [["DateRange", DateRange$reflection()], ["MaxPerformances", array_type(PeakPerformance$reflection())], ["AvgPerformances", array_type(AggregatePeakPerformance$reflection())], ["MaxSummaries", array_type(CardioSummary$reflection())]]);
}

export class UserPowerZones extends Record {
    constructor(RecordedAtUtc, CurrentFtp, ActiveRecovery, Endurance, Tempo, LactateThreshold, VO2Max, AnaerobicCapacity, Neuromuscular) {
        super();
        this.RecordedAtUtc = RecordedAtUtc;
        this.CurrentFtp = CurrentFtp;
        this.ActiveRecovery = ActiveRecovery;
        this.Endurance = Endurance;
        this.Tempo = Tempo;
        this.LactateThreshold = LactateThreshold;
        this.VO2Max = VO2Max;
        this.AnaerobicCapacity = AnaerobicCapacity;
        this.Neuromuscular = Neuromuscular;
    }
}

export function UserPowerZones$reflection() {
    return record_type("Shared.Models.UserPowerZones", [], UserPowerZones, () => [["RecordedAtUtc", class_type("System.DateTime")], ["CurrentFtp", float64_type], ["ActiveRecovery", float64_type], ["Endurance", float64_type], ["Tempo", float64_type], ["LactateThreshold", float64_type], ["VO2Max", float64_type], ["AnaerobicCapacity", float64_type], ["Neuromuscular", float64_type]]);
}

export function UserPowerZones__AsWattsKg_5E38073B(x, weightKg) {
    return new UserPowerZones(x.RecordedAtUtc, x.CurrentFtp / weightKg, x.ActiveRecovery / weightKg, x.Endurance / weightKg, x.Tempo / weightKg, x.LactateThreshold / weightKg, x.VO2Max / weightKg, x.AnaerobicCapacity / weightKg, x.Neuromuscular / weightKg);
}

export function UserPowerZones_get_Colors() {
    return ofArray(["teal-500", "green-600", "blue-500", "indigo-700", "yellow-500", "orange-500", "red-600"]);
}

export function UserPowerZones_get_ColorCodes() {
    return ofArray(["#38B2AC", "#38A169", "#4299E1", "#4C51BF", "#ECC94B", "#ED8936", "#E53E3E"]);
}

export function UserPowerZones__ToList(x) {
    return ofArray([["Active Recovery", x.ActiveRecovery], ["Endurance", x.Endurance], ["Tempo", x.Tempo], ["Lactate Threshold", x.LactateThreshold], ["VO2 Max", x.VO2Max], ["Anaerobic Capacity", x.AnaerobicCapacity], ["Neuromuscular", x.Neuromuscular]]);
}

export function UserPowerZones__ToColoredArray(x) {
    return [[item(0, UserPowerZones_get_ColorCodes()), x.ActiveRecovery], [item(1, UserPowerZones_get_ColorCodes()), x.Endurance], [item(2, UserPowerZones_get_ColorCodes()), x.Tempo], [item(3, UserPowerZones_get_ColorCodes()), x.LactateThreshold], [item(4, UserPowerZones_get_ColorCodes()), x.VO2Max], [item(5, UserPowerZones_get_ColorCodes()), x.AnaerobicCapacity], [item(6, UserPowerZones_get_ColorCodes()), x.Neuromuscular]];
}

export function UserPowerZones__FromPower_5E38073B(x, watts) {
    if (watts <= x.ActiveRecovery) {
        return item(0, UserPowerZones__ToList(x));
    }
    else {
        return head(skipWhile((p) => (p[1] > watts), UserPowerZones__ToList(x)));
    }
}

export function UserPowerZones__ColorFromPower_5E38073B(x, watts) {
    const coloredList = UserPowerZones__ToColoredArray(x);
    return ((watts <= x.ActiveRecovery) ? coloredList[0] : find((p) => (p[1] > watts), coloredList))[0];
}

export class UserHeartRateZones extends Record {
    constructor(RecordedAtUtc, Endurance, Moderate, Tempo, Threshold, MaximumHeartRate) {
        super();
        this.RecordedAtUtc = RecordedAtUtc;
        this.Endurance = (Endurance | 0);
        this.Moderate = (Moderate | 0);
        this.Tempo = (Tempo | 0);
        this.Threshold = (Threshold | 0);
        this.MaximumHeartRate = (MaximumHeartRate | 0);
    }
}

export function UserHeartRateZones$reflection() {
    return record_type("Shared.Models.UserHeartRateZones", [], UserHeartRateZones, () => [["RecordedAtUtc", class_type("System.DateTime")], ["Endurance", int32_type], ["Moderate", int32_type], ["Tempo", int32_type], ["Threshold", int32_type], ["MaximumHeartRate", int32_type]]);
}

export function UserHeartRateZones__ToList(x) {
    return ofArray([["Endurance", x.Endurance], ["Moderate", x.Moderate], ["Tempo", x.Tempo], ["Threshold", x.Threshold], ["Anaerobic", x.MaximumHeartRate]]);
}

export function UserHeartRateZones_get_Colors() {
    return ofArray(["teal-500", "green-600", "yellow-500", "orange-500", "red-600"]);
}

export function UserHeartRateZones_get_ColorCodes() {
    return ofArray(["#38B2AC", "#38A169", "#ECC94B", "#ED8936", "#E53E3E"]);
}

export class TimeInZone extends Record {
    constructor(Id, Name, TimeInZoneSeconds, MinValue, MaxValue, Lap) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.TimeInZoneSeconds = (TimeInZoneSeconds | 0);
        this.MinValue = (MinValue | 0);
        this.MaxValue = (MaxValue | 0);
        this.Lap = Lap;
    }
}

export function TimeInZone$reflection() {
    return record_type("Shared.Models.TimeInZone", [], TimeInZone, () => [["Id", Id__Id$reflection()], ["Name", string_type], ["TimeInZoneSeconds", int32_type], ["MinValue", int32_type], ["MaxValue", int32_type], ["Lap", LapType$reflection()]]);
}

export class TimeInZones extends Record {
    constructor(Power, Heart) {
        super();
        this.Power = Power;
        this.Heart = Heart;
    }
}

export function TimeInZones$reflection() {
    return record_type("Shared.Models.TimeInZones", [], TimeInZones, () => [["Power", list_type(TimeInZone$reflection())], ["Heart", list_type(TimeInZone$reflection())]]);
}

export class WeeklyAggregate extends Record {
    constructor(Value, Week, Year) {
        super();
        this.Value = Value;
        this.Week = (Week | 0);
        this.Year = (Year | 0);
    }
}

export function WeeklyAggregate$reflection() {
    return record_type("Shared.Models.WeeklyAggregate", [], WeeklyAggregate, () => [["Value", float64_type], ["Week", int32_type], ["Year", int32_type]]);
}

export class TrainingLoadSummary extends Record {
    constructor(Date$, TotalTrainingStress, ChronicTrainingLoad, AcuteTrainingLoad, Balance, Ftp) {
        super();
        this.Date = Date$;
        this.TotalTrainingStress = TotalTrainingStress;
        this.ChronicTrainingLoad = ChronicTrainingLoad;
        this.AcuteTrainingLoad = AcuteTrainingLoad;
        this.Balance = Balance;
        this.Ftp = (Ftp | 0);
    }
}

export function TrainingLoadSummary$reflection() {
    return record_type("Shared.Models.TrainingLoadSummary", [], TrainingLoadSummary, () => [["Date", class_type("System.DateTime")], ["TotalTrainingStress", float64_type], ["ChronicTrainingLoad", float64_type], ["AcuteTrainingLoad", float64_type], ["Balance", float64_type], ["Ftp", int32_type]]);
}

export class DashboardData extends Record {
    constructor(TrainsWithPower, Weight, Calories, Today, PowerZones, HeartRateZones, RecentWorkouts, RecentMaxPeaks, RecentDistances, TrainingLoad) {
        super();
        this.TrainsWithPower = TrainsWithPower;
        this.Weight = Weight;
        this.Calories = Calories;
        this.Today = Today;
        this.PowerZones = PowerZones;
        this.HeartRateZones = HeartRateZones;
        this.RecentWorkouts = RecentWorkouts;
        this.RecentMaxPeaks = RecentMaxPeaks;
        this.RecentDistances = RecentDistances;
        this.TrainingLoad = TrainingLoad;
    }
}

export function DashboardData$reflection() {
    return record_type("Shared.Models.DashboardData", [], DashboardData, () => [["TrainsWithPower", bool_type], ["Weight", array_type(Metric$reflection())], ["Calories", array_type(Metric$reflection())], ["Today", array_type(CalendarEvent$reflection())], ["PowerZones", option_type(UserPowerZones$reflection())], ["HeartRateZones", option_type(UserHeartRateZones$reflection())], ["RecentWorkouts", array_type(WorkoutSummary$reflection())], ["RecentMaxPeaks", array_type(PeakPerformance$reflection())], ["RecentDistances", array_type(WeeklyAggregate$reflection())], ["TrainingLoad", option_type(array_type(TrainingLoadSummary$reflection()))]]);
}

export class RollingAggregate extends Record {
    constructor(DateOfResult, TrainingStress, DistanceMeters, TimeSeconds, AverageHeartRate, MaximumHeartRate, Calories, AverageCadence, MaximumCadence, AverageSpeedMetersPerSecond, MaximumSpeedMetersPerSecond, AveragePower, MaximumPower, NormalisedPower, ElevationGainedMeters) {
        super();
        this.DateOfResult = DateOfResult;
        this.TrainingStress = TrainingStress;
        this.DistanceMeters = DistanceMeters;
        this.TimeSeconds = TimeSeconds;
        this.AverageHeartRate = AverageHeartRate;
        this.MaximumHeartRate = MaximumHeartRate;
        this.Calories = Calories;
        this.AverageCadence = AverageCadence;
        this.MaximumCadence = MaximumCadence;
        this.AverageSpeedMetersPerSecond = AverageSpeedMetersPerSecond;
        this.MaximumSpeedMetersPerSecond = MaximumSpeedMetersPerSecond;
        this.AveragePower = AveragePower;
        this.MaximumPower = MaximumPower;
        this.NormalisedPower = NormalisedPower;
        this.ElevationGainedMeters = ElevationGainedMeters;
    }
}

export function RollingAggregate$reflection() {
    return record_type("Shared.Models.RollingAggregate", [], RollingAggregate, () => [["DateOfResult", class_type("System.DateTime")], ["TrainingStress", float64_type], ["DistanceMeters", float64_type], ["TimeSeconds", float64_type], ["AverageHeartRate", float64_type], ["MaximumHeartRate", float64_type], ["Calories", float64_type], ["AverageCadence", float64_type], ["MaximumCadence", float64_type], ["AverageSpeedMetersPerSecond", float64_type], ["MaximumSpeedMetersPerSecond", float64_type], ["AveragePower", float64_type], ["MaximumPower", float64_type], ["NormalisedPower", float64_type], ["ElevationGainedMeters", float64_type]]);
}

