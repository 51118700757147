import * as react from "react";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { UploadActivityMsg, UploadActivityMsg$reflection, Model$reflection } from "./Types.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { view } from "../../Spinner.fs.js";
import { swimmingIcon, runningIcon, IconSize, cyclingIcon } from "../../Icons.fs.js";
import { bodyText } from "../../Typography.fs.js";
import { uncurry } from "../../.fable/fable-library.3.0.0/Util.js";

export const uploadIcon = react.createElement("svg", {
    width: 20,
    height: 20,
    viewbox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
}, react.createElement("path", {
    ["fill-rule"]: "evenodd",
    ["clip-rule"]: "evenodd",
    d: "M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z",
    fill: "#ffffff",
}));

export class Props extends Record {
    constructor(Model, Dispatch) {
        super();
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function Props$reflection() {
    return record_type("Modal.UploadActivity.View.Props", [], Props, () => [["Model", Model$reflection()], ["Dispatch", lambda_type(UploadActivityMsg$reflection(), unit_type)]]);
}

const rootImpl = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_2;
    const model = props.Model;
    const dispatch = props.Dispatch;
    const spinnerStyle = model.IsSaving ? "flex flex-row justify-center items-center my-4" : "hidden flex flex-row justify-center items-center";
    return react.createElement("div", {}, react.createElement("div", {
        className: spinnerStyle,
    }, view), react.createElement("div", {
        className: model.IsSaving ? "w-full hidden" : "w-full",
    }, react.createElement("div", {
        className: "flex flex-row justify-center",
    }, react.createElement("div", {
        className: "flex flex-row flex-shrink",
    }, react.createElement("div", {
        className: "mr-2 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100",
        onClick: (_arg1) => {
            dispatch(new UploadActivityMsg(0));
        },
    }, cyclingIcon(new IconSize(2))), react.createElement("div", {
        className: "ml-2 mr-2 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100",
        onClick: (_arg2) => {
            dispatch(new UploadActivityMsg(0));
        },
    }, runningIcon(new IconSize(2))), react.createElement("div", {
        className: "ml-2 mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100",
        onClick: (_arg3) => {
            dispatch(new UploadActivityMsg(0));
        },
    }, swimmingIcon(new IconSize(2))))), react.createElement("div", {
        className: "mt-3",
    }, bodyText("We support the upload of FIT (recommended) and TCX files")), react.createElement("div", {}, react.createElement("div", {
        className: "mt-4",
    }, react.createElement("div", {
        className: "flex w-full rounded-md shadow-sm",
    }, react.createElement("input", {
        type: "file",
        name: "uploadFile",
        id: "uploadFile",
        onInput: (ev) => {
            const element = ev.target;
            const file = element.files[0];
            const reader = new FileReader();
            reader.onload = ((evt) => {
                let tupledArg;
                const readerResult = evt.target.result;
                dispatch((tupledArg = [file.name, new Uint8Array(readerResult)], new UploadActivityMsg(1, tupledArg[0], tupledArg[1])));
            });
            reader.onerror = ((evt_1) => {
                dispatch(new UploadActivityMsg(0));
            });
            reader.readAsArrayBuffer(file);
        },
        accept: ".tcx,.fit",
        style: {
            display: "none",
        },
    }), react.createElement("label", {
        type: "button",
        className: "w-full block cursor-pointer flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
        htmlFor: "uploadFile",
    }, react.createElement("div", {
        className: "flex flex-row justify-start",
    }, react.createElement("span", {
        className: "mr-4",
        style: {
            zIndex: 20,
            position: "absolute",
        },
    }, uploadIcon), react.createElement("span", {
        className: "w-full text-center",
    }, "Select an activity file to upload")))))), (matchValue_2 = model.ValidationError, (matchValue_2 == null) ? react.createElement(react.Fragment, {}) : react.createElement("div", {
        className: "flex flex-row mt-4 text-red-600",
    }, matchValue_2))));
}, void 0, uncurry(2, void 0), void 0, "rootImpl", "/home/runner/work/strengthPlus/strengthPlus/client/src/Modal/UploadActivityModal/View.fs", 31);

export function root(model, dispatch) {
    return rootImpl(new Props(model, dispatch));
}

