import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { concat, mapIndexed, map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { speedFloat } from "../../Units.fs.js";
import { DateRange, PeakPerformance, AggregatePeakPerformance } from "../../../../shared/Shared.Models.fs.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { Id_create } from "../../../../shared/Shared.Types.fs.js";
import { addDays } from "../../.fable/fable-library.3.0.0/Date.js";
import { PerformancePeakDateRange } from "../../../../shared/Shared.Api.fs.js";
import { peaks as peaks_3 } from "../../Server.fs.js";
import { GetPeaksRes } from "./Types.fs.js";

export class UnableToLoadPeaksException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadPeaksException$reflection() {
    return class_type("Analysis.PeakPerformances.Api.UnableToLoadPeaksException", void 0, UnableToLoadPeaksException, class_type("System.Exception"));
}

function UnableToLoadPeaksException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadPeaksException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function convertAggregateSpeedsToLocalUnits(context, peaks) {
    return map((p) => {
        if (p.PeakType.tag === 3) {
            return new AggregatePeakPerformance(p.PeakDurationInSeconds, speedFloat(context, p.PeakValue), p.PeakType);
        }
        else {
            return p;
        }
    }, peaks);
}

export function convertSpeedsToLocalUnits(context, peaks) {
    return map((p) => {
        if (p.PeakType.tag === 3) {
            return new PeakPerformance(p.StartDateTimeUtc, p.PeakDurationSeconds, speedFloat(context, p.PeakValue), p.PeakType, p.Lap, p.CardioActivitySummaryId, p.Weight);
        }
        else {
            return p;
        }
    }, peaks);
}

export function getAnalysis(dateRanges, categories, analysisCardioType, context) {
    return singleton.Delay(() => {
        const categoryDateRanges = Array.from(mapIndexed((i, dr) => {
            let categoryId;
            const matchValue = categories[i];
            categoryId = ((matchValue.tag === 1) ? Id_create(matchValue.fields[0]) : (void 0));
            return new PerformancePeakDateRange(new DateRange(dr.StartDate, addDays(dr.EndDate, 1)), categoryId);
        }, dateRanges));
        return singleton.Bind(peaks_3((api) => api.getForRanges(categoryDateRanges, analysisCardioType)), (_arg1) => {
            let peaks, tupledArg;
            const results = _arg1;
            return singleton.Return((results.tag === 0) ? (peaks = results.fields[0], (tupledArg = [Array.from(map((p) => Array.from(convertAggregateSpeedsToLocalUnits(context, p.AvgPerformances)), peaks)), Array.from(map((p_1) => Array.from(convertSpeedsToLocalUnits(context, p_1.MaxPerformances)), peaks)), Array.from(concat(map((p_2) => p_2.MaxSummaries, peaks)))], new GetPeaksRes(0, tupledArg[0], tupledArg[1], tupledArg[2]))) : (new GetPeaksRes(1, new UnableToLoadPeaksException())));
        });
    });
}

