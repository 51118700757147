import { Union } from "./.fable/fable-library.3.0.0/Types.js";
import { union_type, lambda_type, string_type, float64_type } from "./.fable/fable-library.3.0.0/Reflection.js";

export class GraphValueTransformer extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoValueTransformation", "TransformWithFunction"];
    }
}

export function GraphValueTransformer$reflection() {
    return union_type("Graph.Shared.GraphValueTransformer", [], GraphValueTransformer, () => [[], [["Item", lambda_type(float64_type, string_type)]]]);
}

