import { eachItemWith, withValidator, MatchResult$1, validateUnion, isNotEmpty, validate as validate_1, isGreaterThan, validateRequired, createValidatorFor } from "../../../../shared/SimpleValidation.fs.js";
import { filter, append, ofSeq, mapIndexed as mapIndexed_1, ofArray, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { Id_value, Seconds_create, WeightInKg_create, Id_create, IndexPosition_create, Description_create, Name_create, OptionalId_none, OptionalId_create, Description_validator, Name_validator } from "../../../../shared/Shared.Types.fs.js";
import { SubmitProgrammeRes, Item_Empty_796E87A7, GetProgrammeRes, Rest, Exercise, Rest_Empty, Exercise_Empty_Z721C83C5, Item as Item_8, Activity, Msg, Model_Empty_796E87A7, Activity__get_ActivityId, Model } from "./Types.fs.js";
import { map, head, length, findIndex, item as item_1, mapIndexed } from "../../.fable/fable-library.3.0.0/Seq.js";
import { equalsSafe, equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { CreateUpdateWorkoutTemplate, CreateUpdateActivity, StrengthExercise, GymActivityType } from "../../../../shared/Shared.Models.fs.js";
import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result, Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { Converters_parseIntFromInput } from "../../Form.fs.js";
import { submitUpdateProgramme, submitCreateProgramme, getProgramme } from "./Api.fs.js";
import { successToast, errorToast } from "../../Notifications.fs.js";
import { ProgrammePage, Page, modifyLocation } from "../../Router.fs.js";

const exerciseValidator = createValidatorFor(ofArray([validateRequired("Reps", (v) => v.Reps, singleton(isGreaterThan(0))), validateRequired("Sets", (v_1) => v_1.Sets, singleton(isGreaterThan(0)))]));

const restValidator = createValidatorFor(singleton(validateRequired("Seconds", (v) => v.Seconds, singleton(isGreaterThan(0)))));

const itemValidator = createValidatorFor(ofArray([validate_1("Name", (i) => i.Name, Name_validator()), validate_1("Description", (i_1) => i_1.Description, Description_validator()), validate_1("Activities", (i_2) => i_2.Activities, ofArray([(arg00$0040) => ((value) => isNotEmpty(arg00$0040, value)), (() => {
    const validatorFunc = createValidatorFor(ofArray([validateUnion("Strength", (x) => x, (u) => ((u.tag === 0) ? (new MatchResult$1(0, u.fields[0])) : (new MatchResult$1(1))), singleton(withValidator(exerciseValidator))), validateUnion("Rest", (x_1) => x_1, (u_1) => ((u_1.tag === 1) ? (new MatchResult$1(0, u_1.fields[0])) : (new MatchResult$1(1))), singleton(withValidator(restValidator)))]));
    return (arg10$0040) => ((value_1) => eachItemWith(validatorFunc, arg10$0040, value_1));
})()]))]));

function validate(model) {
    return new Model(model.IsSaving, model.IsLoading, model.IsDirty, model.Item, itemValidator(model.Item));
}

export function swap(el1, el2, arr) {
    return mapIndexed((i, v) => {
        if (i === el1) {
            return item_1(el2, arr);
        }
        else if (i === el2) {
            return item_1(el1, arr);
        }
        else {
            return v;
        }
    }, arr);
}

function moveUp(item, items) {
    const index = findIndex((i) => equals(i, item), items) | 0;
    if (index === 0) {
        return items;
    }
    else {
        return swap(index, index - 1, items);
    }
}

function moveDown(item, items) {
    const index = findIndex((i) => equals(i, item), items) | 0;
    if (index === (length(items) - 1)) {
        return items;
    }
    else {
        return swap(index, index + 1, items);
    }
}

export function toApiId(managedId) {
    if (managedId.tag === 1) {
        return OptionalId_create(managedId.fields[0]);
    }
    else {
        return OptionalId_none;
    }
}

function toApiModel(model) {
    return new CreateUpdateWorkoutTemplate(toApiId(model.Item.Id), true, Name_create(model.Item.Name), Description_create(model.Item.Description), mapIndexed_1((position, a) => {
        let e, LiftId, Reps, Sets, matchValue;
        return new CreateUpdateActivity(toApiId(Activity__get_ActivityId(a)), IndexPosition_create(position), (a.tag === 0) ? (e = a.fields[0], new GymActivityType(0, (LiftId = Id_create(e.LiftId), (Reps = (defaultArg(e.Reps, 10) | 0), (Sets = (defaultArg(e.Sets, 12) | 0), new StrengthExercise(LiftId, Reps, (matchValue = e.Weight, (matchValue == null) ? WeightInKg_create(12) : WeightInKg_create(matchValue)), Sets)))))) : (new GymActivityType(1, Seconds_create(defaultArg(a.fields[0].Seconds, 30)))));
    }, model.Item.Activities));
}

export function init(context) {
    return [validate(Model_Empty_796E87A7(head(context.Lookups.Lifts))), Cmd_none()];
}

export function initForEdit(context, id) {
    return [validate(Model_Empty_796E87A7(head(context.Lookups.Lifts))), Cmd_OfFunc_result(new Msg(14, id))];
}

export function updateExercise(model, exerciseId, modifier) {
    let inputRecord;
    return new Model(model.IsSaving, model.IsLoading, true, (inputRecord = model.Item, new Item_8(inputRecord.Id, inputRecord.Name, inputRecord.Description, ofSeq(map((activity) => {
        const matchValue = equalsSafe(Activity__get_ActivityId(activity), exerciseId);
        if (matchValue) {
            if (activity.tag === 0) {
                return new Activity(0, modifier(activity.fields[0]));
            }
            else {
                return activity;
            }
        }
        else {
            return activity;
        }
    }, model.Item.Activities)))), model.ValidationState);
}

export function updateRest(model, exerciseId, modifier) {
    let inputRecord;
    return new Model(model.IsSaving, model.IsLoading, true, (inputRecord = model.Item, new Item_8(inputRecord.Id, inputRecord.Name, inputRecord.Description, ofSeq(map((activity) => {
        const matchValue = equalsSafe(Activity__get_ActivityId(activity), exerciseId);
        if (matchValue) {
            if (activity.tag === 1) {
                return new Activity(1, modifier(activity.fields[0]));
            }
            else {
                return activity;
            }
        }
        else {
            return activity;
        }
    }, model.Item.Activities)))), model.ValidationState);
}

export function update(context, msg, model) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, id_1, inputRecord;
    switch (msg.tag) {
        case 1: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_1 = model.Item, new Item_8(inputRecord_1.Id, inputRecord_1.Name, msg.fields[0], inputRecord_1.Activities)), model.ValidationState)), Cmd_none()];
        }
        case 2: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_2 = model.Item, new Item_8(inputRecord_2.Id, inputRecord_2.Name, inputRecord_2.Description, append(model.Item.Activities, singleton(new Activity(0, Exercise_Empty_Z721C83C5(Id_value(head(context.Lookups.Lifts).Id))))))), model.ValidationState)), Cmd_none()];
        }
        case 7: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_3 = model.Item, new Item_8(inputRecord_3.Id, inputRecord_3.Name, inputRecord_3.Description, append(model.Item.Activities, singleton(new Activity(1, Rest_Empty()))))), model.ValidationState)), Cmd_none()];
        }
        case 9: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_4 = model.Item, new Item_8(inputRecord_4.Id, inputRecord_4.Name, inputRecord_4.Description, filter((a) => (!equalsSafe(Activity__get_ActivityId(a), Activity__get_ActivityId(msg.fields[0]))), model.Item.Activities))), model.ValidationState)), Cmd_none()];
        }
        case 10: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_5 = model.Item, new Item_8(inputRecord_5.Id, inputRecord_5.Name, inputRecord_5.Description, ofSeq(moveUp(msg.fields[0], model.Item.Activities)))), model.ValidationState)), Cmd_none()];
        }
        case 11: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord_6 = model.Item, new Item_8(inputRecord_6.Id, inputRecord_6.Name, inputRecord_6.Description, ofSeq(moveDown(msg.fields[0], model.Item.Activities)))), model.ValidationState)), Cmd_none()];
        }
        case 4: {
            return [validate(updateExercise(model, msg.fields[0][0].Id, (e) => (new Exercise(e.Id, e.Sets, e.Reps, e.LiftId, Converters_parseIntFromInput(msg.fields[0][1]))))), Cmd_none()];
        }
        case 5: {
            return [validate(updateExercise(model, msg.fields[0][0].Id, (e_1) => (new Exercise(e_1.Id, Converters_parseIntFromInput(msg.fields[0][1]), e_1.Reps, e_1.LiftId, e_1.Weight)))), Cmd_none()];
        }
        case 6: {
            return [validate(updateExercise(model, msg.fields[0][0].Id, (e_2) => (new Exercise(e_2.Id, e_2.Sets, Converters_parseIntFromInput(msg.fields[0][1]), e_2.LiftId, e_2.Weight)))), Cmd_none()];
        }
        case 3: {
            return [validate(updateExercise(model, msg.fields[0][0].Id, (e_3) => (new Exercise(e_3.Id, e_3.Sets, e_3.Reps, msg.fields[0][1], e_3.Weight)))), Cmd_none()];
        }
        case 8: {
            return [validate(updateRest(model, msg.fields[0][0].Id, (e_4) => (new Rest(e_4.Id, Converters_parseIntFromInput(msg.fields[0][1]))))), Cmd_none()];
        }
        case 14: {
            return [new Model(model.IsSaving, true, model.IsDirty, model.Item, model.ValidationState), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (id_1 = Id_create(msg.fields[0]), (context_1) => getProgramme(id_1, context_1)), context, (arg0) => (new Msg(15, arg0)), (arg) => (new Msg(15, new GetProgrammeRes(1, arg))))];
        }
        case 15: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                return [validate(new Model(model.IsSaving, false, false, Item_Empty_796E87A7(head(context.Lookups.Lifts)), model.ValidationState)), errorToast("Unable to load programme")];
            }
            else {
                return [validate(new Model(model.IsSaving, false, model.IsDirty, result.fields[0], model.ValidationState)), Cmd_none()];
            }
        }
        case 12: {
            const apiModel = toApiModel(model);
            return [new Model(true, model.IsLoading, model.IsDirty, model.Item, model.ValidationState), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, (model.Item.Id.tag === 0) ? ((context_3) => submitCreateProgramme(apiModel, context_3)) : ((context_2) => submitUpdateProgramme(apiModel, context_2)), context, (arg0_3) => (new Msg(13, arg0_3)), (arg_2) => (new Msg(13, new SubmitProgrammeRes(1, arg_2))))];
        }
        case 13: {
            if (msg.fields[0].tag === 1) {
                return [new Model(false, model.IsLoading, model.IsDirty, model.Item, model.ValidationState), errorToast("Unable to save programme")];
            }
            else {
                modifyLocation(new Page(10, new ProgrammePage(0)));
                return [new Model(false, model.IsLoading, model.IsDirty, model.Item, model.ValidationState), successToast("Your programme has been saved")];
            }
        }
        default: {
            return [validate(new Model(model.IsSaving, model.IsLoading, true, (inputRecord = model.Item, new Item_8(inputRecord.Id, msg.fields[0], inputRecord.Description, inputRecord.Activities)), model.ValidationState)), Cmd_none()];
        }
    }
}

