import * as react from "react";
import { Converters_floatWithoutTrailingZeroes, floatInput, roundedDropdown, toolbarButton } from "../../Form.fs.js";
import { trashIcon, IconSize, addIcon } from "../../Icons.fs.js";
import { addModalMetricTypes, metricTypeFilters, Msg } from "./Types.fs.js";
import { singleton, append, delay, map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { AnalysisPage, Page, modifyLocation } from "../../Router.fs.js";
import { modalWrapper } from "../../CommonRendering.fs.js";
import { dashboardTitle } from "../../Typography.fs.js";
import { warning, plain } from "../../Buttons.fs.js";
import { ClassName, OnChange as OnChange_1, Value as Value_1, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { ofSeq, ofArray } from "../../.fable/fable-library.3.0.0/List.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { Sort$1__get_SortColumn, MetricSortColumn } from "../../../../shared/Shared.Api.fs.js";
import { calories, weight, weightUnits, heartRate, heartRateUnits } from "../../Units.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { toolbarPage } from "../../Layout.fs.js";
import { sortableGrid } from "../../Grids.fs.js";

function toolbar(model, dispatch) {
    return react.createElement("div", {
        className: "flex flex-row",
    }, toolbarButton(addIcon(new IconSize(3)), false, () => {
        dispatch(new Msg(5));
    }), react.createElement("div", {
        className: "ml-4 w-48",
    }, roundedDropdown(map((c) => c[1], metricTypeFilters), model.MetricTypeFilterIndex, (index, _arg1) => {
        dispatch(new Msg(4, index));
    })()), react.createElement("div", {
        className: "ml-4",
    }, toolbarButton("Analyse", false, () => {
        modifyLocation(new Page(4, new AnalysisPage(7)));
    })));
}

function deleteModal(model, dispatch) {
    return modalWrapper((_arg1) => {
        dispatch(new Msg(13));
    }, react.createElement("div", {}, react.createElement("div", {
        className: "min-h-full flex flex-column justify-around",
    }, react.createElement("div", {
        className: "w-full",
    }, dashboardTitle("Are you sure?"), react.createElement("p", {}, "This action cannot be undone."))), react.createElement("div", {
        className: "grid grid-cols-2 gap-4 mt-4",
    }, plain("No", () => {
        dispatch(new Msg(13));
    }, false), warning("Yes", () => {
        dispatch(new Msg(14));
    }, false))));
}

function addModal(model, dispatch) {
    const valueIsinvalid = model.AddModalValue == null;
    return modalWrapper((_arg1) => {
        dispatch(new Msg(6));
    }, react.createElement("div", {}, react.createElement("div", {
        className: "min-h-full flex flex-column justify-around",
    }, react.createElement("div", {
        className: "w-full",
    }, dashboardTitle("Add metric"), react.createElement("div", {
        className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-2",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, "Window"), react.createElement("div", {
        className: "w-full shadow-sm sm:col-span-2",
    }, roundedDropdown(map((c) => c[1], addModalMetricTypes), model.AddModalMetricTypeIndex, (index, _arg2) => {
        dispatch(new Msg(7, index));
    })())), react.createElement("div", {
        className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-4",
    }, react.createElement("label", {
        className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
    }, "Apply from"), react.createElement("div", {
        className: "mt-1 sm:mt-0 sm:col-span-2",
    }, flatpickr(ofArray([Value_1(model.AddModalDateTime), OnChange_1((arg) => {
        dispatch(new Msg(8, arg));
    }), ClassName("form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5")])))), floatInput({
        InitialFormatter: (value) => ((value != null) ? Converters_floatWithoutTrailingZeroes(value) : ""),
        IsInvalid: valueIsinvalid,
        Label: "Value",
        OnChange: (arg_1) => {
            dispatch(new Msg(9, arg_1));
        },
        SizeSelector: "",
        Value: model.AddModalValue,
    }))), react.createElement("div", {
        className: "grid grid-cols-2 gap-4 mt-4",
    }, plain("Cancel", () => {
        dispatch(new Msg(6));
    }, false), plain("Add", () => {
        dispatch(new Msg(10));
    }, valueIsinvalid))));
}

export function root(context, model, dispatch) {
    const toolbar_1 = toolbar(model, dispatch);
    const columns = [[react.createElement("span", {
        className: "ml-8",
    }, "Date"), (cs) => react.createElement("div", {
        className: "flex flex-row justify-start",
    }, react.createElement("button", {
        className: "text-gray-400 mr-4 hover:text-orange-600",
        onClick: (_arg1) => {
            dispatch(new Msg(12, cs));
        },
    }, trashIcon(new IconSize(4))), toShortDateString(cs.RecordedAtUtc)), new MetricSortColumn(2)], ["Value", (cs_1) => {
        let value, metricType, arg20_1, arg10_1, arg20_2, arg10_2, arg20_3, arg10_3, arg20_4, arg10_4, arg20, arg10;
        return value = cs_1.RecordedValue, (metricType = cs_1.MetricType, (metricType.tag === 4) ? (arg20_1 = heartRateUnits(context), (arg10_1 = heartRate(context, ~(~value)), toText(printf("%s %s"))(arg10_1)(arg20_1))) : ((metricType.tag === 1) ? (arg20_2 = weightUnits(context), (arg10_2 = weight(context, value), toText(printf("%s %s"))(arg10_2)(arg20_2))) : ((metricType.tag === 2) ? (arg20_3 = weightUnits(context), (arg10_3 = calories(context, ~(~value)), toText(printf("%s %s"))(arg10_3)(arg20_3))) : ((metricType.tag === 3) ? (arg20_4 = weightUnits(context), (arg10_4 = calories(context, ~(~value)), toText(printf("%s %s"))(arg10_4)(arg20_4))) : (arg20 = weightUnits(context), (arg10 = weight(context, value), toText(printf("%s %s"))(arg10)(arg20)))))));
    }, new MetricSortColumn(0)], ["Type", (cs_2) => {
        let metricType_1;
        return metricType_1 = cs_2.MetricType, (metricType_1.tag === 4) ? "Resting heart rate" : ((metricType_1.tag === 1) ? "Target weight" : ((metricType_1.tag === 2) ? "Calories" : ((metricType_1.tag === 3) ? "Target calories" : "Weight")));
    }, new MetricSortColumn(1)]];
    return toolbarPage("Metrics", void 0, model.IsLoading, false, [toolbar_1], ofSeq(delay(() => append(model.AddModalIsVisible ? singleton(addModal(model, dispatch)) : singleton(react.createElement(react.Fragment, {})), delay(() => append(model.DeleteModalIsVisible ? singleton(deleteModal(model, dispatch)) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(react.createElement("div", {}, sortableGrid(void 0, (arg) => {
        dispatch(new Msg(2, arg));
    }, columns, Sort$1__get_SortColumn(model.Sort), model.Items, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, (arg_1) => {
        dispatch(new Msg(3, arg_1));
    }))))))))));
}

