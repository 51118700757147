import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Item$reflection } from "./Types.fs.js";
import { union_type, record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { filter, map, find, append, collect, exists } from "../../.fable/fable-library.3.0.0/Seq.js";
import { empty, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import * as react from "react";
import { OptionHelpers_optionalFloatFromInt } from "../../../../shared/Shared.fs.js";
import { bind } from "../../.fable/fable-library.3.0.0/Option.js";
import { heartRate, power, cadence, speed, elevation, speedFloat } from "../../Units.fs.js";
import { uncurry, round, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { scatter, IScatterGraphProps, ScatterGraphSeries, ScatterGraphColor, ScatterGraphDataPoint } from "../../ScatterGraph.fs.js";
import { printf, toText as toText_1 } from "../../.fable/fable-library.3.0.0/String.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { subtleLabel } from "../../Typography.fs.js";
import { toggleSwitch } from "../../Form.fs.js";
import { warningPanel } from "../../Notifications.fs.js";

export class IRenderVersusAnalysisProps extends Record {
    constructor(Context, Activity, IsLoading) {
        super();
        this.Context = Context;
        this.Activity = Activity;
        this.IsLoading = IsLoading;
    }
}

export function IRenderVersusAnalysisProps$reflection() {
    return record_type("CardioActivity.Show.VersusAnalysis.IRenderVersusAnalysisProps", [], IRenderVersusAnalysisProps, () => [["Context", Context$reflection()], ["Activity", Item$reflection()], ["IsLoading", bool_type]]);
}

export class VersusComparisonTypes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["VersusHeartRate", "VersusPower", "VersusCadence", "VersusSpeed", "VersusAscent"];
    }
}

export function VersusComparisonTypes$reflection() {
    return union_type("CardioActivity.Show.VersusAnalysis.VersusComparisonTypes", [], VersusComparisonTypes, () => [[], [], [], [], []]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_6, matchValue_7;
    const haveHeartMeasurements = props.Activity.Summary.Metrics.AverageHeartRate != null;
    const havePowerMeasurements = props.Activity.Summary.Metrics.AveragePower != null;
    const haveCadenceMeasurements = props.Activity.Summary.Metrics.AverageCadence != null;
    const haveSpeedMeasurements = exists((f) => (f.SpeedMetersPerSecond != null), collect((dp) => dp.DataPoints, props.Activity.Laps));
    const haveAscentMeasurements = props.Activity.Summary.Metrics.MaximumAltitudeMeters != null;
    const defaults = Array.from(append(havePowerMeasurements ? singleton(new VersusComparisonTypes(1)) : empty(), append(haveCadenceMeasurements ? singleton(new VersusComparisonTypes(2)) : empty(), append(haveSpeedMeasurements ? singleton(new VersusComparisonTypes(3)) : empty(), append(haveHeartMeasurements ? singleton(new VersusComparisonTypes(0)) : empty(), append(haveAscentMeasurements ? singleton(new VersusComparisonTypes(4)) : empty(), []))))));
    const matchValue = defaults.length > 1;
    if (matchValue) {
        const xAxisVersusType = react.useState(defaults[1]);
        const yAxisVersusType = react.useState(defaults[0]);
        const ignoreZeroes = react.useState(true);
        const valueGetters = [[new VersusComparisonTypes(0), (cs) => OptionHelpers_optionalFloatFromInt(cs.HeartRate)], [new VersusComparisonTypes(1), (cs_1) => OptionHelpers_optionalFloatFromInt(cs_1.Power)], [new VersusComparisonTypes(3), (cs_2) => bind((v) => speedFloat(props.Context, v), cs_2.SpeedMetersPerSecond)], [new VersusComparisonTypes(4), (cs_3) => cs_3.ElevationDeltaMeters], [new VersusComparisonTypes(2), (cs_4) => OptionHelpers_optionalFloatFromInt(cs_4.Cadence)]];
        const lookups = append(haveAscentMeasurements ? singleton([new VersusComparisonTypes(4), "Ascent", (v_5) => elevation(props.Context, v_5)]) : empty(), append(haveSpeedMeasurements ? singleton([new VersusComparisonTypes(3), "Speed", (v_4) => speed(props.Context, v_4)]) : empty(), append(haveCadenceMeasurements ? singleton([new VersusComparisonTypes(2), "Cadence", (v_3) => cadence(props.Context, ~(~v_3))]) : empty(), append(havePowerMeasurements ? singleton([new VersusComparisonTypes(1), "Power", (v_2) => power(props.Context, ~(~v_2))]) : empty(), append(haveHeartMeasurements ? singleton([new VersusComparisonTypes(0), "Heart Rate", (v_1) => heartRate(props.Context, ~(~v_1))]) : empty(), [])))));
        const toText = (value_10) => find((tupledArg) => equalsSafe(value_10, tupledArg[0]), lookups)[1];
        const fromText = (text) => find((tupledArg_1) => (text === tupledArg_1[1]), lookups)[0];
        const toUnits = (value_11) => find((tupledArg_2) => equalsSafe(value_11, tupledArg_2[0]), lookups)[2];
        const xGetter = find((f_4) => equalsSafe(xAxisVersusType[0], f_4[0]), valueGetters)[1];
        const yGetter = find((f_5) => equalsSafe(yAxisVersusType[0], f_5[0]), valueGetters)[1];
        const midColor = [214, 158, 46];
        const graphProps = new IScatterGraphProps([new ScatterGraphSeries(Array.from(map((f_6) => f_6, filter((option_2) => (option_2 != null), map((cs_5) => {
            const matchValue_1 = [xGetter(cs_5), yGetter(cs_5)];
            let pattern_matching_result, x, y;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    pattern_matching_result = 0;
                    x = matchValue_1[0];
                    y = matchValue_1[1];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const matchValue_2 = (ignoreZeroes[0]) ? ((x === 0) ? true : (y === 0)) : false;
                    if (matchValue_2) {
                        return void 0;
                    }
                    else {
                        return new ScatterGraphDataPoint(y, x);
                    }
                }
                case 1: {
                    return void 0;
                }
            }
        }, Array.from(collect((l) => l.DataPoints, props.Activity.Laps)))))), new ScatterGraphColor(1, (series_1, seriesIndex_1, dataPoint_1, percentDistanceFromAverage) => {
            const patternInput_3 = (percentDistanceFromAverage <= 0.5) ? [[104, 211, 145], midColor, percentDistanceFromAverage * 2] : [midColor, [229, 62, 62], (percentDistanceFromAverage - 0.5) * 2];
            let patternInput_4;
            const percent = patternInput_3[2];
            const tupledArg_4 = patternInput_3[1];
            const tupledArg_3 = patternInput_3[0];
            const r1 = tupledArg_3[0];
            const g1 = tupledArg_3[1];
            const b1 = tupledArg_3[2];
            patternInput_4 = [r1 + ((tupledArg_4[0] - r1) * percent), g1 + ((tupledArg_4[1] - g1) * percent), b1 + ((tupledArg_4[2] - b1) * percent)];
            return toText_1(printf("rgb(%.0f, %.0f, %.0f)"))(patternInput_4[0])(patternInput_4[1])(patternInput_4[2]);
        }))], (tupledArg_6) => {
            const min_1 = tupledArg_6[0];
            const max_1 = tupledArg_6[1];
            return ((min_1 >= 0) ? ((min_1 - 5) < 0) : false) ? [0, Math.ceil(max_1 / 5) * 5] : [Math.floor((min_1 - 5) / 5) * 5, Math.ceil(max_1 / 5) * 5];
        }, (tupledArg_5) => {
            const min = tupledArg_5[0];
            const max = tupledArg_5[1];
            return ((min >= 0) ? ((min - 5) < 0) : false) ? [0, Math.ceil(max / 5) * 5] : [Math.floor((min - 5) / 5) * 5, Math.ceil(max / 5) * 5];
        }, true, true, false, false, 2, "#718096", "#CBD5E0", new GraphValueTransformer(0), new GraphValueTransformer(0), (matchValue_6 = (xAxisVersusType[0]), (matchValue_6.tag === 2) ? (round) : ((matchValue_6.tag === 0) ? (round) : (void 0))), (matchValue_7 = (yAxisVersusType[0]), (matchValue_7.tag === 2) ? (round) : ((matchValue_7.tag === 0) ? (round) : (void 0))), (series, seriesIndex, dataPoint) => [[toText(xAxisVersusType[0]), toUnits(xAxisVersusType[0])(dataPoint.XAxisValue)], [toText(yAxisVersusType[0]), toUnits(yAxisVersusType[0])(dataPoint.YAxisValue)]], uncurry(3, void 0));
        return react.createElement("div", {}, react.createElement("div", {
            className: "flex justify-between items-center",
        }, react.createElement("div", {
            className: "flex rounded-md shadow-sm mr-4 mt-1",
        }, react.createElement("select", {
            value: toText(yAxisVersusType[0]),
            className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-r-none border-l",
            onChange: (ev) => {
                const arg00 = fromText(Browser_Types_Event__Event_get_Value(ev));
                yAxisVersusType[1](arg00);
            },
        }, ...map((tupledArg_7) => {
            const t_2 = tupledArg_7[1];
            return react.createElement("option", {
                value: t_2,
            }, t_2);
        }, lookups)), react.createElement("span", {
            className: "whitespace-no-wrap inline-flex flex-grow items-center px-3 border rounded-l-none rounded-r-none border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
        }, "against"), react.createElement("select", {
            value: toText(xAxisVersusType[0]),
            className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-l-none border-l",
            onChange: (ev_1) => {
                const arg00_1 = fromText(Browser_Types_Event__Event_get_Value(ev_1));
                xAxisVersusType[1](arg00_1);
            },
        }, ...map((tupledArg_8) => {
            const t_3 = tupledArg_8[1];
            return react.createElement("option", {
                value: t_3,
            }, t_3);
        }, lookups))), react.createElement("div", {
            className: "flex flex-row justify-end",
        }, react.createElement("div", {
            className: "mr-4",
        }, subtleLabel("Ignore zero values")), toggleSwitch(ignoreZeroes[0], () => {
            ignoreZeroes[1]((!(ignoreZeroes[0])));
        }))), react.createElement("div", {
            className: "mt-4",
            style: {
                height: 480,
            },
        }, scatter(graphProps)));
    }
    else {
        return warningPanel("Insufficient measurements", "You need at least two measurement points (heart, ascent, speed, cadence or power) to perform a versus analysis");
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/VersusAnalysis.fs", 25);

