import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, array_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { AnalysisDateRange_get_Empty, AnalysisDateRange$reflection } from "../Types.fs.js";
import { CardioType, PeakPerformance$reflection, PeakTarget$reflection, CardioType$reflection } from "../../../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(IsLoading, AnalysisDateRange, AnalysisCardioType, Targets, Peaks) {
        super();
        this.IsLoading = IsLoading;
        this.AnalysisDateRange = AnalysisDateRange;
        this.AnalysisCardioType = AnalysisCardioType;
        this.Targets = Targets;
        this.Peaks = Peaks;
    }
}

export function Model$reflection() {
    return record_type("Analysis.Competitiveness.Types.Model", [], Model, () => [["IsLoading", bool_type], ["AnalysisDateRange", AnalysisDateRange$reflection()], ["AnalysisCardioType", CardioType$reflection()], ["Targets", array_type(PeakTarget$reflection())], ["Peaks", array_type(PeakPerformance$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(false, AnalysisDateRange_get_Empty(), new CardioType(1), [], []);
}

export class GetPeaksAndTargetsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPeaksAndTargetsRes$reflection() {
    return union_type("Analysis.Competitiveness.Types.GetPeaksAndTargetsRes", [], GetPeaksAndTargetsRes, () => [[["Item1", array_type(PeakPerformance$reflection())], ["Item2", array_type(PeakTarget$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeAnalysisCardioType", "ChangeAnalysisDateRange", "GetPeaks", "GetPeaksResult"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.Competitiveness.Types.Msg", [], Msg, () => [[["Item", CardioType$reflection()]], [["Item", AnalysisDateRange$reflection()]], [], [["Item", GetPeaksAndTargetsRes$reflection()]]]);
}

