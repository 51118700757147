import { DeleteMetricRes, AddMetricRes, GetMetricsRes, sortColumnFromType, sortOrderFromType, Msg, Model, sortFromIndexes, Model_get_Empty } from "./Types.fs.js";
import { Cmd_batch, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { MetricsPage_FromRoute, Page as Page_1, modifyLocation } from "../../Router.fs.js";
import { comparePrimitives, max, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { Sort$1__WithNewColumn_1505, Sort$1__get_InvertSortOrder, Sort$1__get_SortColumn } from "../../../../shared/Shared.Api.fs.js";
import { deleteMetric, addMetric, getPage } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { successToast } from "../../Notifications.fs.js";
import { ofArray } from "../../.fable/fable-library.3.0.0/List.js";

export function init(sortColumn, sortOrder, pageNumber, metricTypeIndex) {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.Items, inputRecord.IsLoading, sortFromIndexes(sortOrder, sortColumn), pageNumber, inputRecord.NumberOfPages, inputRecord.NumberOfRows, inputRecord.PageSize, metricTypeIndex, inputRecord.IsSaving, inputRecord.AddModalIsVisible, inputRecord.AddModalMetricTypeIndex, inputRecord.AddModalDateTime, inputRecord.AddModalValue, inputRecord.DeleteModalIsVisible, inputRecord.DeleteModalMetric)), Cmd_OfFunc_result(new Msg(0))];
}

export function update(context, msg, model) {
    let arg10_1, arg10_2, arg10;
    switch (msg.tag) {
        case 3: {
            modifyLocation(new Page_1(1, (arg10_1 = (sortOrderFromType(model.Sort) | 0), MetricsPage_FromRoute(sortColumnFromType(model.Sort), arg10_1, msg.fields[0], model.MetricTypeFilterIndex))));
            return [model, Cmd_none()];
        }
        case 2: {
            const sortColumn = msg.fields[0];
            const newSort = equalsSafe(sortColumn, Sort$1__get_SortColumn(model.Sort)) ? Sort$1__get_InvertSortOrder(model.Sort) : Sort$1__WithNewColumn_1505(model.Sort, sortColumn);
            modifyLocation(new Page_1(1, (arg10_2 = (sortOrderFromType(newSort) | 0), MetricsPage_FromRoute(sortColumnFromType(newSort), arg10_2, model.Page, model.MetricTypeFilterIndex))));
            return [model, Cmd_none()];
        }
        case 0: {
            return [new Model(model.Items, true, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getPage(model, context_1), context, (arg0_3) => (new Msg(1, arg0_3)), (arg) => (new Msg(1, new GetMetricsRes(1, arg))))];
        }
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Items, false, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
            }
            else {
                const page = result.fields[0];
                return [new Model(page.Items, false, model.Sort, page.PageNumber, page.NumberOfPages, page.NumberOfRows, page.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
            }
        }
        case 5: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, true, max(comparePrimitives, 0, model.MetricTypeFilterIndex - 1), model.AddModalDateTime, void 0, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
        }
        case 6: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, false, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
        }
        case 7: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, msg.fields[0], model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
        }
        case 9: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, msg.fields[0], model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
        }
        case 8: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, msg.fields[0], model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
        }
        case 10: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, true, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, (context_2) => addMetric(model, context_2), context, (arg0_6) => (new Msg(11, arg0_6)), (arg_2) => (new Msg(11, new AddMetricRes(1, arg_2))))];
        }
        case 11: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, false, false, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_none()];
            }
            else {
                return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, false, false, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0)), successToast("Metric added")]))];
            }
        }
        case 12: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, true, msg.fields[0]), Cmd_none()];
        }
        case 13: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, model.IsSaving, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, false, void 0), Cmd_none()];
        }
        case 14: {
            return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, true, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, model.DeleteModalIsVisible, model.DeleteModalMetric), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, (context_3) => deleteMetric(model, context_3), context, (arg0_9) => (new Msg(15, arg0_9)), (arg_4) => (new Msg(15, new DeleteMetricRes(1, arg_4))))];
        }
        case 15: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, false, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, false, void 0), Cmd_none()];
            }
            else {
                return [new Model(model.Items, model.IsLoading, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.MetricTypeFilterIndex, false, model.AddModalIsVisible, model.AddModalMetricTypeIndex, model.AddModalDateTime, model.AddModalValue, false, void 0), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(0)), successToast("Metric deleted")]))];
            }
        }
        default: {
            modifyLocation(new Page_1(1, (arg10 = (sortOrderFromType(model.Sort) | 0), MetricsPage_FromRoute(sortColumnFromType(model.Sort), arg10, model.Page, msg.fields[0]))));
            return [model, Cmd_none()];
        }
    }
}

