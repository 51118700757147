import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { Union, Record } from "./.fable/fable-library.3.0.0/Types.js";
import { string_type, unit_type, lambda_type, option_type, class_type, union_type, obj_type, float64_type, record_type, bool_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import * as react from "react";
import { useDrop as useDrop_1, useDrag as useDrag_1, DndProvider } from "react-dnd";
import react$002Ddnd$002Dhtml5$002Dbackend from "react-dnd-html5-backend";
import { singleton } from "./.fable/fable-library.3.0.0/List.js";

function kvl(xs) {
    return keyValueList(xs, 1);
}

export class CollectedDragProps extends Record {
    constructor(isDragging) {
        super();
        this.isDragging = isDragging;
    }
}

export function CollectedDragProps$reflection() {
    return record_type("ReactDnD.CollectedDragProps", [], CollectedDragProps, () => [["isDragging", bool_type]]);
}

export class CollectedDropProps extends Record {
    constructor(isOver, canDrop) {
        super();
        this.isOver = isOver;
        this.canDrop = canDrop;
    }
}

export function CollectedDropProps$reflection() {
    return record_type("ReactDnD.CollectedDropProps", [], CollectedDropProps, () => [["isOver", bool_type], ["canDrop", bool_type]]);
}

export class Position extends Record {
    constructor(x, y) {
        super();
        this.x = x;
        this.y = y;
    }
}

export function Position$reflection() {
    return record_type("ReactDnD.Position", [], Position, () => [["x", float64_type], ["y", float64_type]]);
}

export class DndProviderProps extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Backend"];
    }
}

export function DndProviderProps$reflection() {
    return union_type("ReactDnD.DndProviderProps", [], DndProviderProps, () => [[["Item", obj_type]]]);
}

export function dndProvider(props) {
    const props_1 = kvl(props);
    return (children) => react.createElement(DndProvider, props_1, ...children);
}

export function dndProviderHtml5(props) {
    return dndProvider(singleton(new DndProviderProps(0, react$002Ddnd$002Dhtml5$002Dbackend)));
}

export class DragSpec$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Item", "Begin", "Collect", "End"];
    }
}

export function DragSpec$1$reflection(gen0) {
    return union_type("ReactDnD.DragSpec`1", [gen0], DragSpec$1, () => [[["Item", DragItem$1$reflection(gen0)]], [["Item", lambda_type(class_type("ReactDnD.IDragMonitor"), option_type(DragItem$1$reflection(gen0)))]], [["Item", lambda_type(class_type("ReactDnD.IDragMonitor"), CollectedDragProps$reflection())]], [["Item", lambda_type(DragItem$1$reflection(gen0), lambda_type(class_type("ReactDnD.IDragMonitor"), unit_type))]]]);
}

export class DragItem$1 extends Record {
    constructor(type, dragSrc) {
        super();
        this.type = type;
        this.dragSrc = dragSrc;
    }
}

export function DragItem$1$reflection(gen0) {
    return record_type("ReactDnD.DragItem`1", [gen0], DragItem$1, () => [["type", string_type], ["dragSrc", gen0]]);
}

const useDragImpl = useDrag_1;

export function useDrag(spec) {
    return useDragImpl(kvl(spec));
}

export class DropSpec$2 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Accept", "Collect", "Drop", "Hover"];
    }
}

export function DropSpec$2$reflection(gen0, gen1) {
    return union_type("ReactDnD.DropSpec`2", [gen0, gen1], DropSpec$2, () => [[["Item", string_type]], [["Item", lambda_type(class_type("ReactDnD.IDropMonitor"), CollectedDropProps$reflection())]], [["Item", lambda_type(DragItem$1$reflection(gen0), lambda_type(class_type("ReactDnD.IDropMonitor"), gen1))]], [["Item", lambda_type(DragItem$1$reflection(gen0), lambda_type(class_type("ReactDnD.IDropMonitor"), unit_type))]]]);
}

const useDropImpl = useDrop_1;

export function useDrop(spec) {
    return useDropImpl(kvl(spec));
}

