import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { PowerZonesPage, PowerZonesPage$reflection } from "../../Router.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "../Index/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "../History/Types.fs.js";

export class Model extends Record {
    constructor(CurrentPage, IndexModel, HistoryModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.IndexModel = IndexModel;
        this.HistoryModel = HistoryModel;
    }
}

export function Model$reflection() {
    return record_type("PowerZones.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", PowerZonesPage$reflection()], ["IndexModel", option_type(Model$reflection_1())], ["HistoryModel", option_type(Model$reflection_2())]]);
}

export function Model_get_Empty() {
    return new Model(new PowerZonesPage(0), void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IndexMsg", "HistoryMsg"];
    }
}

export function Msg$reflection() {
    return union_type("PowerZones.Dispatcher.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]]]);
}

