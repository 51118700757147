import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { string_type, union_type, class_type, bool_type, record_type, option_type, int32_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ValidationState, ValidationState$reflection } from "../../../../shared/SimpleValidation.fs.js";

export class Item extends Record {
    constructor(ActiveRecovery, Endurance, Tempo, LactateThreshold, VO2Max, AnaerobicCapacity, Neuromuscular, CurrentFTP) {
        super();
        this.ActiveRecovery = ActiveRecovery;
        this.Endurance = Endurance;
        this.Tempo = Tempo;
        this.LactateThreshold = LactateThreshold;
        this.VO2Max = VO2Max;
        this.AnaerobicCapacity = AnaerobicCapacity;
        this.Neuromuscular = Neuromuscular;
        this.CurrentFTP = CurrentFTP;
    }
}

export function Item$reflection() {
    return record_type("PowerZones.Index.Types.Item", [], Item, () => [["ActiveRecovery", option_type(int32_type)], ["Endurance", option_type(int32_type)], ["Tempo", option_type(int32_type)], ["LactateThreshold", option_type(int32_type)], ["VO2Max", option_type(int32_type)], ["AnaerobicCapacity", option_type(int32_type)], ["Neuromuscular", option_type(int32_type)], ["CurrentFTP", option_type(int32_type)]]);
}

export function Item_get_Empty() {
    return new Item(void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0);
}

export class Model extends Record {
    constructor(Zones, IsLoading, IsSaving, IsDirty, ValidationState) {
        super();
        this.Zones = Zones;
        this.IsLoading = IsLoading;
        this.IsSaving = IsSaving;
        this.IsDirty = IsDirty;
        this.ValidationState = ValidationState;
    }
}

export function Model$reflection() {
    return record_type("PowerZones.Index.Types.Model", [], Model, () => [["Zones", Item$reflection()], ["IsLoading", bool_type], ["IsSaving", bool_type], ["IsDirty", bool_type], ["ValidationState", ValidationState$reflection()]]);
}

export function Model_get_Empty() {
    return new Model(Item_get_Empty(), true, false, false, new ValidationState(1));
}

export class GetPowerZonessRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPowerZonessRes$reflection() {
    return union_type("PowerZones.Index.Types.GetPowerZonessRes", [], GetPowerZonessRes, () => [[["Item", Item$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class SubmitPowerZonesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitPowerZonesRes$reflection() {
    return union_type("PowerZones.Index.Types.SubmitPowerZonesRes", [], SubmitPowerZonesRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeActiveRecovery", "ChangeEndurance", "ChangeTempo", "ChangeLactateThreshold", "ChangeVO2Max", "ChangeAnaerobicCapacity", "ChangeNeuromuscular", "ChangeFTP", "AutoCalculate", "GetPowerZoness", "GetPowerZonessResult", "SubmitPowerZones", "SubmitPowerZonesResult"];
    }
}

export function Msg$reflection() {
    return union_type("PowerZones.Index.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [], [["Item", GetPowerZonessRes$reflection()]], [], [["Item", SubmitPowerZonesRes$reflection()]]]);
}

