import { GetProgrammeRes, SubmitProgrammeRes, Item as Item_1, Model, Msg, Model_Empty_Z191E29AA } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { getProgramme, submitProgramme } from "./Api.fs.js";

export function init(id) {
    return [Model_Empty_Z191E29AA(id), Cmd_OfFunc_result(new Msg(0, id))];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.IsSaving, model.Item), Cmd_none()];
            }
            else {
                return [new Model(false, model.IsSaving, result.fields[0]), Cmd_none()];
            }
        }
        case 2: {
            return [new Model(model.IsLoading, model.IsSaving, new Item_1(model.Item.Id, msg.fields[0])), Cmd_none()];
        }
        case 3: {
            return [new Model(model.IsLoading, true, model.Item), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, submitProgramme, (x_1) => x_1, (arg0_3) => (new Msg(4, arg0_3)), (arg_2) => (new Msg(4, new SubmitProgrammeRes(1, arg_2))))];
        }
        case 4: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 1) {
                console.error(some(result_1.fields[0]));
                return [new Model(model.IsLoading, false, model.Item), Cmd_none()];
            }
            else {
                return [new Model(model.IsLoading, false, model.Item), Cmd_none()];
            }
        }
        default: {
            return [new Model(true, model.IsSaving, model.Item), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, getProgramme, msg.fields[0], (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetProgrammeRes(1, arg))))];
        }
    }
}

