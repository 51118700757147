import { singleton } from "./.fable/fable-library.3.0.0/AsyncBuilder.js";
import { ApiException, api } from "./Server.fs.js";
import { GetLookupsRes } from "./AppTypes.fs.js";
import { ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { sortWith } from "./.fable/fable-library.3.0.0/Seq.js";
import { comparePrimitives } from "./.fable/fable-library.3.0.0/Util.js";
import { Lookups } from "./Context.fs.js";

export function getLookups(_arg1) {
    return singleton.Delay(() => singleton.Bind(api.lookups.getAll(), (_arg2) => {
        let projection;
        const response = _arg2;
        switch (response.tag) {
            case 0: {
                const lookupsResponse = response.fields[0];
                return singleton.Return(new GetLookupsRes(0, new Lookups(lookupsResponse.Lifts, ofSeq((projection = ((c) => c.DisplayText), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), lookupsResponse.CardioCategories))))));
            }
            case 2: {
                return singleton.Return(new GetLookupsRes(1, new ApiException()));
            }
            case 1: {
                return singleton.Return(new GetLookupsRes(1, new ApiException()));
            }
            default: {
                return singleton.Return(new GetLookupsRes(1, new ApiException()));
            }
        }
    }));
}

