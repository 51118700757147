import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { cardioActivity } from "../../Server.fs.js";
import { SubmitUploadActivityRes } from "./Types.fs.js";

export class UnableToSubmitException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToSubmitException$reflection() {
    return class_type("Modal.UploadActivity.Api.UnableToSubmitException", void 0, UnableToSubmitException, class_type("System.Exception"));
}

function UnableToSubmitException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToSubmitException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function uploadFile(filename, content, context) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.upload(filename, content)), (_arg1) => {
        const response = _arg1;
        if (response.tag === 0) {
            const uploadResponse = response.fields[0];
            switch (uploadResponse.tag) {
                case 1: {
                    return singleton.Return(new SubmitUploadActivityRes(2, uploadResponse.fields[0]));
                }
                case 2: {
                    return singleton.Return(new SubmitUploadActivityRes(1, new UnableToSubmitException()));
                }
                default: {
                    return singleton.Return(new SubmitUploadActivityRes(0, uploadResponse.fields[0]));
                }
            }
        }
        else {
            return singleton.Return(new SubmitUploadActivityRes(1, new UnableToSubmitException()));
        }
    }));
}

