import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { calendar, dashboard } from "../../Server.fs.js";
import { PlanWorkoutTodayRes, GetDashboardsRes, numberOfAggregateDays, numberOfAggregateWeeks } from "./Types.fs.js";
import { UnexpectedNoContentException } from "../../../../shared/Shared.fs.js";
import { utcNow } from "../../.fable/fable-library.3.0.0/Date.js";

export class UnableToPlanworkoutTodayException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToPlanworkoutTodayException$reflection() {
    return class_type("Dashboard.Index.Api.UnableToPlanworkoutTodayException", void 0, UnableToPlanworkoutTodayException, class_type("System.Exception"));
}

function UnableToPlanworkoutTodayException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToPlanworkoutTodayException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getDashboards(context) {
    return singleton.Delay(() => singleton.Bind(dashboard((f) => f.get(numberOfAggregateWeeks + 1, numberOfAggregateDays + 1)), (_arg1) => {
        const dashboardResponse = _arg1;
        return (dashboardResponse.tag === 0) ? singleton.Return(new GetDashboardsRes(0, dashboardResponse.fields[0])) : singleton.Return(new GetDashboardsRes(1, new UnexpectedNoContentException()));
    }));
}

export function planWorkoutToday(programmeId, context) {
    return singleton.Delay(() => singleton.Bind(calendar((f) => f.planPowerBasedWorkout(programmeId, utcNow())), (_arg1) => ((_arg1.tag === 0) ? singleton.Bind(calendar((f_1) => f_1.getToday()), (_arg2) => {
        const todayResponse = _arg2;
        return (todayResponse.tag === 0) ? singleton.Return(new PlanWorkoutTodayRes(0, todayResponse.fields[0])) : singleton.Return(new PlanWorkoutTodayRes(1, new UnableToPlanworkoutTodayException()));
    }) : singleton.Return(new PlanWorkoutTodayRes(1, new UnableToPlanworkoutTodayException())))));
}

