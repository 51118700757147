import { warning, lengthAsString } from "../Common.fs.js";
import { PowerProgrammePage, Page, modifyLocation } from "../../Router.fs.js";
import { Sort$1__get_SortColumn, PowerProgrammeSortColumn } from "../../../../shared/Shared.Api.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { panel, HeaderButton, createHeaderButton, toolbarPage } from "../../Layout.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { sortableGrid } from "../../Grids.fs.js";
import { ProgrammeId__AsString } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { Msg } from "./Types.fs.js";
import { primary } from "../../Buttons.fs.js";

export function convertLengthToGridString(context, length) {
    return lengthAsString(context, length);
}

export function root(context, model, dispatch) {
    const haveProgrammes = model.Items.length > 0;
    const createNewPowerProgramme = () => {
        modifyLocation(new Page(0, new PowerProgrammePage(2)));
    };
    const columns = [["Name", (pp) => pp.Name, new PowerProgrammeSortColumn(2)], ["Duration", (pp_1) => convertLengthToGridString(context, pp_1.Duration), new PowerProgrammeSortColumn(1)], ["Training Stress", (pp_2) => toText(printf("%d"))(pp_2.TrainingStress), new PowerProgrammeSortColumn(3)], ["Created On", (pp_3) => toShortDateString(pp_3.CreatedAtUtc), new PowerProgrammeSortColumn(0)]];
    return toolbarPage("Workouts", void 0, model.IsLoading, true, [createHeaderButton(model.IsLoading ? true : (!haveProgrammes), new HeaderButton(0, "+ New", createNewPowerProgramme, false))], ofSeq(delay(() => append(singleton(warning), delay(() => (haveProgrammes ? singleton(react.createElement("div", {
        className: "mb-6",
    }, sortableGrid((itemIndex) => {
        modifyLocation(new Page(0, new PowerProgrammePage(1, ProgrammeId__AsString(model.Items[itemIndex].Id))));
    }, (arg) => {
        dispatch(new Msg(0, arg));
    }, columns, Sort$1__get_SortColumn(model.SortOrder), model.Items, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, (arg_1) => {
        dispatch(new Msg(1, arg_1));
    }))) : singleton(panel([react.createElement("div", {
        className: "text-center",
    }, react.createElement("p", {}, "You don\u0027t have any workouts, get started by creating one."), react.createElement("div", {
        className: "text-center mt-4",
    }, primary("Create your first workout", createNewPowerProgramme, false)))]))))))));
}

