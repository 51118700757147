import { filter, tryFind, rangeNumber, collect, sortWith, length, mapIndexed, find, singleton, delay, map, append } from "../.fable/fable-library.3.0.0/Seq.js";
import { PeakPerformance, AggregatePeakPerformance, MetricType, CardioType, DateRange as DateRange_2, CategoryIdIncludingAll } from "../../../shared/Shared.Models.fs.js";
import { Id_value } from "../../../shared/Shared.Types.fs.js";
import * as react from "react";
import { singleton as singleton_1, ofArray, ofSeq } from "../.fable/fable-library.3.0.0/List.js";
import { dateHash, numberHash, comparePrimitives, uncurry, equalsSafe } from "../.fable/fable-library.3.0.0/Util.js";
import { formattedPeakValue, seriesColors, metricTypeAsLabel, cardioTypeAsLabel, AnalysisDateRange, dateRangeTypeLookup } from "./Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { ClassName, OnChange, Value, flatpickr } from "../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { panelTitle } from "../Typography.fs.js";
import { shortDurationAsString, speedFloat } from "../Units.fs.js";
import { keyValueList } from "../.fable/fable-library.3.0.0/MapUtil.js";
import { CSSProp, HTMLAttr } from "../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { printf, toText } from "../.fable/fable-library.3.0.0/String.js";
import { Record } from "../.fable/fable-library.3.0.0/Types.js";
import { record_type, class_type, option_type, lambda_type, unit_type, int32_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { expandIcon, IconSize, expandedIcon } from "../Icons.fs.js";
import { distinctBy, distinct } from "../.fable/fable-library.3.0.0/Set.js";
import { toShortDateString, date as date_1, equals, compare } from "../.fable/fable-library.3.0.0/Date.js";
import { IRenderHistoryProps, view } from "./RenderHistory.fs.js";
import { infoPanel } from "../Notifications.fs.js";
import { selectableFlexibleSummary } from "../Grids.fs.js";

export function analysisDateRangeEditor(analysisDateRange, categories, onChange) {
    const categoryLookups = Array.from(append([[new CategoryIdIncludingAll(0), "All"]], map((c) => [new CategoryIdIncludingAll(1, Id_value(c.Id)), c.DisplayText], categories)));
    const categoryLookupAsString = (category) => {
        if (category.tag === 1) {
            return category.fields[0];
        }
        else {
            return "all";
        }
    };
    return react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4 mt-1",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: find((tupledArg_1) => equalsSafe(tupledArg_1[0], analysisDateRange.DateRangeType), dateRangeTypeLookup)[1],
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-r-none border-l",
        onChange: (ev) => {
            let text;
            onChange(new AnalysisDateRange((text = Browser_Types_Event__Event_get_Value(ev), find((tupledArg) => (tupledArg[1] === text), dateRangeTypeLookup)[0]), analysisDateRange.DateRange, analysisDateRange.CategoryId));
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), dateRangeTypeLookup)))), delay(() => append((analysisDateRange.DateRangeType.tag === 8) ? append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "from")), delay(() => append(singleton(flatpickr(ofArray([Value(analysisDateRange.DateRange.StartDate), OnChange((newValue) => {
        onChange(new AnalysisDateRange(analysisDateRange.DateRangeType, new DateRange_2(newValue, analysisDateRange.DateRange.EndDate), analysisDateRange.CategoryId));
    }), ClassName("form-input rounded-none block  transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "to")), delay(() => singleton(flatpickr(ofArray([Value(analysisDateRange.DateRange.StartDate), OnChange((newValue_1) => {
        onChange(new AnalysisDateRange(analysisDateRange.DateRangeType, new DateRange_2(analysisDateRange.DateRange.StartDate, newValue_1), analysisDateRange.CategoryId));
    }), ClassName("form-input rounded-l-none block transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))))))))) : singleton(react.createElement(react.Fragment, {})), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "for")), delay(() => singleton(react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: categoryLookupAsString(analysisDateRange.CategoryId),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-l-none rounded-r-md border-r",
        onChange: (ev_1) => {
            let value;
            onChange(new AnalysisDateRange(analysisDateRange.DateRangeType, analysisDateRange.DateRange, (value = Browser_Types_Event__Event_get_Value(ev_1), (value === "all") ? (new CategoryIdIncludingAll(0)) : (new CategoryIdIncludingAll(1, value)))));
        },
    }, ...map((drt_1) => react.createElement("option", {
        value: categoryLookupAsString(drt_1[0]),
    }, drt_1[1]), categoryLookups)))))))))))));
}

export function activityTypeEditor(analysisCardioType, onChange, isDisabled) {
    return react.createElement("div", {}, panelTitle("Activity type"), react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: cardioTypeAsLabel(analysisCardioType),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md " + (isDisabled ? "bg-gray-200" : ""),
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
        disabled: isDisabled,
    }, ...map((drt) => react.createElement("option", {
        value: cardioTypeAsLabel(drt),
    }, cardioTypeAsLabel(drt)), [new CardioType(0), new CardioType(1), new CardioType(2), new CardioType(3), new CardioType(4), new CardioType(5)]))))));
}

export function metricTypeEditor(analysisMetricType, onChange, isDisabled) {
    return react.createElement("div", {}, panelTitle("Activity type"), react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: metricTypeAsLabel(analysisMetricType),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md " + (isDisabled ? "bg-gray-200" : ""),
        onChange: (ev) => {
            onChange(Browser_Types_Event__Event_get_Value(ev));
        },
        disabled: isDisabled,
    }, ...map((drt) => react.createElement("option", {
        value: metricTypeAsLabel(drt),
    }, metricTypeAsLabel(drt)), [new MetricType(0), new MetricType(1), new MetricType(2), new MetricType(3), new MetricType(4)]))))));
}

export function convertAggregateSpeedsToLocalUnits(context, peaks) {
    return map((p) => {
        if (p.PeakType.tag === 3) {
            return new AggregatePeakPerformance(p.PeakDurationInSeconds, speedFloat(context, p.PeakValue), p.PeakType);
        }
        else {
            return p;
        }
    }, peaks);
}

export function convertSpeedsToLocalUnits(context, peaks) {
    return map((p) => {
        if (p.PeakType.tag === 3) {
            return new PeakPerformance(p.StartDateTimeUtc, p.PeakDurationSeconds, speedFloat(context, p.PeakValue), p.PeakType, p.Lap, p.CardioActivitySummaryId, p.Weight);
        }
        else {
            return p;
        }
    }, peaks);
}

export function seriesIndicator(seriesIndex) {
    let css, arg10;
    return react.createElement("div", keyValueList([new HTMLAttr(65, "mr-4 h-5 w-5 text-xs text-center text-white bg-indigo-600 rounded-full flex flex-col justify-center"), (css = singleton_1(new CSSProp(21, seriesColors[seriesIndex % seriesColors.length])), ["style", keyValueList(css, 1)])], 1), (arg10 = ((seriesIndex + 1) | 0), toText(printf("%d"))(arg10)));
}

export function categoryDropdown(category, context, changeCategory) {
    const lookupAsString = (category_1) => {
        if (category_1.tag === 1) {
            return category_1.fields[0];
        }
        else {
            return "all";
        }
    };
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: lookupAsString(category),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md",
        onChange: (ev) => {
            let value;
            changeCategory((value = Browser_Types_Event__Event_get_Value(ev), (value === "all") ? (new CategoryIdIncludingAll(0)) : (new CategoryIdIncludingAll(1, value))));
        },
    }, ...map((drt) => react.createElement("option", {
        value: lookupAsString(drt[0]),
    }, drt[1]), Array.from(append([[new CategoryIdIncludingAll(0), "All"]], map((c) => [new CategoryIdIncludingAll(1, Id_value(c.Id)), c.DisplayText], context.Lookups.CardioCategories))))));
}

export class IExpandablePeakTableProps extends Record {
    constructor(OnClick, ColumnHeaders, Rows, ExpandedRow) {
        super();
        this.OnClick = OnClick;
        this.ColumnHeaders = ColumnHeaders;
        this.Rows = Rows;
        this.ExpandedRow = ExpandedRow;
    }
}

export function IExpandablePeakTableProps$reflection() {
    return record_type("Analysis.Helpers.IExpandablePeakTableProps", [], IExpandablePeakTableProps, () => [["OnClick", option_type(lambda_type(int32_type, unit_type))], ["ColumnHeaders", class_type("System.Collections.Generic.IEnumerable`1", [class_type("Fable.React.ReactElement")])], ["Rows", class_type("System.Collections.Generic.IEnumerable`1", [class_type("System.Collections.Generic.IEnumerable`1", [class_type("Fable.React.ReactElement")])])], ["ExpandedRow", lambda_type(int32_type, class_type("Fable.React.ReactElement"))]]);
}

export const expandablePeakTable = FunctionComponent_Of_Z5A158BBF((props) => {
    const selectedRow = react.useState(-1);
    const hoveredRow = react.useState(-1);
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200",
    }, react.createElement("table", {
        className: "min-w-full",
    }, react.createElement("thead", {}, react.createElement("tr", {}, ...map((name) => react.createElement("th", {
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, name), props.ColumnHeaders))), react.createElement("tbody", {}, ...mapIndexed((i, row_1) => {
        let baseClass, arg20, showExpandIcon;
        return react.createElement(react.Fragment, {}, react.createElement("tr", {
            onMouseEnter: (_arg1) => {
                hoveredRow[1](i);
            },
            onMouseLeave: (_arg2) => {
                hoveredRow[1](-1);
            },
            onClick: (_arg3) => {
                selectedRow[1](((i === (selectedRow[0])) ? -1 : i));
            },
            className: (baseClass = ((props.OnClick == null) ? "" : "hover:bg-gray-300 cursor-pointer"), (arg20 = ((!((i % 2) === 0)) ? "bg-gray-50" : "bg-white"), toText(printf("%s %s"))(baseClass)(arg20))),
        }, ...(showExpandIcon = ((hoveredRow[0]) === i), mapIndexed((index_1, v) => {
            const showExpandedIcon = (index_1 === 0) ? showExpandIcon : false;
            const spacing = (index_1 === 0) ? "pl-2 pr-6" : "px-6";
            return react.createElement("td", {
                className: "py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 " + spacing,
            }, react.createElement("div", {
                className: "flex flex-row justify-start",
            }, ((i === (selectedRow[0])) ? (index_1 === 0) : false) ? react.createElement("div", {
                className: "text-gray-500 pr-1",
            }, expandedIcon(new IconSize(2))) : (showExpandedIcon ? react.createElement("div", {
                className: "text-gray-500 pr-1",
            }, expandIcon(new IconSize(2))) : react.createElement("div", {
                className: "invisible text-gray-500 pr-1",
            }, expandIcon(new IconSize(2)))), v));
        }, row_1))), (i === (selectedRow[0])) ? react.createElement("tr", {}, react.createElement("td", {
            colSpan: length(props.ColumnHeaders),
        }, props.ExpandedRow(i))) : react.createElement(react.Fragment, {}));
    }, props.Rows))))));
}, void 0, uncurry(2, void 0), void 0, "expandablePeakTable", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/Helpers.fs", 153);

export class RenderPeakPerformanceTableRow_IExpandedRowProps extends Record {
    constructor(RowIndex) {
        super();
        this.RowIndex = (RowIndex | 0);
    }
}

export function RenderPeakPerformanceTableRow_IExpandedRowProps$reflection() {
    return record_type("Analysis.Helpers.RenderPeakPerformanceTableRow.IExpandedRowProps", [], RenderPeakPerformanceTableRow_IExpandedRowProps, () => [["RowIndex", int32_type]]);
}

export function renderPeakPerformanceTable(peakPerformances, peakType, context, summaries) {
    const peakDurations = sortWith(comparePrimitives, distinct(collect((series) => map((s) => s.PeakDurationSeconds, series), peakPerformances), {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    }));
    return expandablePeakTable(new IExpandablePeakTableProps((_arg1) => {
    }, append([""], map(seriesIndicator, rangeNumber(0, 1, peakPerformances.length - 1))), map((duration_1) => {
        const source2_1 = map((series_2) => {
            const foundPeak_1 = tryFind((p_4) => (p_4.PeakDurationSeconds === duration_1), series_2);
            if (foundPeak_1 == null) {
                return react.createElement(react.Fragment, {});
            }
            else {
                return formattedPeakValue(foundPeak_1);
            }
        }, peakPerformances);
        return append([shortDurationAsString(duration_1)], source2_1);
    }, peakDurations), (rowIndex) => {
        let projection_1;
        const localPeaks = Array.from((projection_1 = ((p_3) => p_3.StartDateTimeUtc), sortWith((x_3, y_3) => compare(projection_1(x_3), projection_1(y_3)), distinctBy((p_2) => p_2.StartDateTimeUtc, map((p_1) => p_1, filter((option) => (option != null), Array.from(map((duration) => Array.from(map((series_1) => tryFind((p) => (p.PeakDurationSeconds === duration), series_1), peakPerformances)), peakDurations))[rowIndex])), {
            Equals: equals,
            GetHashCode: dateHash,
        }))));
        if (localPeaks.length > 1) {
            return react.createElement("div", {
                className: "mx-6 mt-4",
            }, view(new IRenderHistoryProps(context, [localPeaks], summaries, peakType, uncurry(4, void 0), 160, false, void 0)));
        }
        else {
            return infoPanel("No history", "The peak performance was achieved on the same date in all selected date ranges");
        }
    }));
}

export function renderPeakHistoryTable(peakPerformances, peakType, summaries, isDividedByWeight) {
    const peakDates = sortWith(compare, distinct(collect((series) => map((s) => date_1(s.StartDateTimeUtc), series), peakPerformances), {
        Equals: equals,
        GetHashCode: dateHash,
    }));
    return selectableFlexibleSummary(true, true, true, (_arg1) => {
    }, append([""], map(seriesIndicator, rangeNumber(0, 1, peakPerformances.length - 1))), map((date) => {
        const source2_1 = map((series_1) => {
            const foundPeak = tryFind((p) => equals(date_1(p.StartDateTimeUtc), date), series_1);
            if (foundPeak == null) {
                return react.createElement(react.Fragment, {});
            }
            else {
                const fp = foundPeak;
                return isDividedByWeight ? toText(printf("%.2f"))(fp.PeakValue) : formattedPeakValue(fp);
            }
        }, peakPerformances);
        return append([toShortDateString(date)], source2_1);
    }, peakDates));
}

export function renderAggregatePeakPerformanceTable(peakPerformances, peakType) {
    const peakDurations = sortWith(comparePrimitives, distinct(collect((series) => map((s) => s.PeakDurationInSeconds, series), peakPerformances), {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    }));
    return selectableFlexibleSummary(true, true, true, (_arg1) => {
    }, append([""], map(seriesIndicator, rangeNumber(0, 1, peakPerformances.length - 1))), map((duration) => {
        const source2_1 = map((series_1) => {
            const foundPeak = tryFind((p) => (p.PeakDurationInSeconds === duration), series_1);
            if (foundPeak == null) {
                return react.createElement(react.Fragment, {});
            }
            else {
                const fp = foundPeak;
                return toText(printf("%.0f"))(fp.PeakValue);
            }
        }, peakPerformances);
        return append([shortDurationAsString(duration)], source2_1);
    }, peakDurations));
}

