import { GetPageRes, sortColumnFromType, sortOrderFromType, Msg, Model, sortFromIndexes, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { Sort$1__WithNewColumn_1505, Sort$1__get_InvertSortOrder, Sort$1__get_SortColumn } from "../../../../shared/Shared.Api.fs.js";
import { WeeklyTrainingLogPage_FromListRoute, Page as Page_1, modifyLocation } from "../../Router.fs.js";
import { getPage as getPage_1 } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(sortColumnIndex, sortOrderIndex, pageNumber) {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.Items, sortFromIndexes(sortOrderIndex, sortColumnIndex), pageNumber, inputRecord.NumberOfPages, inputRecord.NumberOfRows, inputRecord.PageSize, inputRecord.IsLoading)), Cmd_OfFunc_result(new Msg(0))];
}

export function update(context, msg, model) {
    let arg10_1, arg10;
    switch (msg.tag) {
        case 3: {
            const sortColumn = msg.fields[0];
            const newSort = equalsSafe(sortColumn, Sort$1__get_SortColumn(model.Sort)) ? Sort$1__get_InvertSortOrder(model.Sort) : Sort$1__WithNewColumn_1505(model.Sort, sortColumn);
            modifyLocation(new Page_1(7, (arg10_1 = (sortOrderFromType(newSort) | 0), WeeklyTrainingLogPage_FromListRoute(sortColumnFromType(newSort), arg10_1, model.Page))));
            return [model, Cmd_none()];
        }
        case 0: {
            return [new Model(model.Items, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getPage_1(model.Page, model.Sort, context_1), context, (arg0_2) => (new Msg(1, arg0_2)), (arg) => (new Msg(1, new GetPageRes(1, arg))))];
        }
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Items, model.Sort, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, false), Cmd_none()];
            }
            else {
                const page = result.fields[0];
                return [new Model(page.Items, model.Sort, page.PageNumber, page.NumberOfPages, page.NumberOfRows, page.PageSize, false), Cmd_none()];
            }
        }
        default: {
            modifyLocation(new Page_1(7, (arg10 = (sortOrderFromType(model.Sort) | 0), WeeklyTrainingLogPage_FromListRoute(sortColumnFromType(model.Sort), arg10, msg.fields[0]))));
            return [model, Cmd_none()];
        }
    }
}

