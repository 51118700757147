import { Union, FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, list_type, class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { PeakPerformance$reflection, CardioSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { ApiResponse$1$reflection } from "../../../../shared/Shared.Api.fs.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { parallel } from "../../.fable/fable-library.3.0.0/Async.js";
import { peaks as peaks_1, cardioActivity } from "../../Server.fs.js";
import { map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { GetComparisonRideDataRes } from "./Types.fs.js";

export class UnableToLoadSummariesException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadSummariesException$reflection() {
    return class_type("Analysis.CompareRides.Api.UnableToLoadSummariesException", void 0, UnableToLoadSummariesException, class_type("System.Exception"));
}

function UnableToLoadSummariesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadSummariesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class Internal_WrappedResponse extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ApiActivities", "ApiPeaks"];
    }
}

export function Internal_WrappedResponse$reflection() {
    return union_type("Analysis.CompareRides.Api.Internal.WrappedResponse", [], Internal_WrappedResponse, () => [[["Item", ApiResponse$1$reflection(list_type(CardioSummary$reflection()))]], [["Item", ApiResponse$1$reflection(list_type(list_type(PeakPerformance$reflection())))]]]);
}

export function getAnalysis(activityIds, context) {
    return singleton.Delay(() => singleton.Bind(parallel([singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getMany(activityIds)), (_arg1) => singleton.Return(new Internal_WrappedResponse(0, _arg1)))), singleton.Delay(() => singleton.Bind(peaks_1((api_1) => api_1.getForCardioActivities(activityIds)), (_arg2) => singleton.Return(new Internal_WrappedResponse(1, _arg2))))]), (_arg3) => {
        let matchValue, tupledArg;
        const results = _arg3;
        return singleton.Return((matchValue = [results[0], results[1]], (matchValue[0].tag === 0) ? ((matchValue[0].fields[0].tag === 0) ? ((matchValue[1].tag === 1) ? ((matchValue[1].fields[0].tag === 0) ? (tupledArg = [Array.from(matchValue[0].fields[0].fields[0]), Array.from(map((source_1) => Array.from(source_1), matchValue[1].fields[0].fields[0]))], new GetComparisonRideDataRes(0, tupledArg[0], tupledArg[1])) : (new GetComparisonRideDataRes(1, new UnableToLoadSummariesException()))) : (new GetComparisonRideDataRes(1, new UnableToLoadSummariesException()))) : (new GetComparisonRideDataRes(1, new UnableToLoadSummariesException()))) : (new GetComparisonRideDataRes(1, new UnableToLoadSummariesException()))));
    }));
}

