import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { Id_create } from "../../../../shared/Shared.Types.fs.js";
import { GetProgrammeRes, Item } from "./Types.fs.js";
import { sleep } from "../../.fable/fable-library.3.0.0/Async.js";

export function getProgramme(context) {
    return singleton.Delay(() => {
        const result = new Item(Id_create("1"), "Some item");
        return singleton.Bind(sleep(500), () => singleton.Return(new GetProgrammeRes(0, result)));
    });
}

