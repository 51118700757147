import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { zones as zones_1 } from "../../Server.fs.js";
import { SubmitHeartRateZonesRes, Item_get_Empty, Item, GetHeartRateZonessRes } from "./Types.fs.js";

export class ErrorGettingHeartRateZonesException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorGettingHeartRateZonesException$reflection() {
    return class_type("HeartRateZones.Create.Api.ErrorGettingHeartRateZonesException", void 0, ErrorGettingHeartRateZonesException, class_type("System.Exception"));
}

function ErrorGettingHeartRateZonesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorGettingHeartRateZonesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class ErrorSavingHeartRateZonesException extends FSharpException {
    constructor() {
        super();
    }
}

export function ErrorSavingHeartRateZonesException$reflection() {
    return class_type("HeartRateZones.Create.Api.ErrorSavingHeartRateZonesException", void 0, ErrorSavingHeartRateZonesException, class_type("System.Exception"));
}

function ErrorSavingHeartRateZonesException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ErrorSavingHeartRateZonesException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getHeartRateZoness(context) {
    return singleton.Delay(() => singleton.Bind(zones_1((api) => api.getHeartRateZones()), (_arg1) => {
        let zones, exn;
        const apiResponse = _arg1;
        return singleton.Return(new GetHeartRateZonessRes(0, (apiResponse.tag === 0) ? (zones = apiResponse.fields[0], new Item(zones.Endurance, zones.Moderate, zones.Tempo, zones.Threshold, zones.MaximumHeartRate)) : ((apiResponse.tag === 1) ? Item_get_Empty() : (exn = (new ErrorGettingHeartRateZonesException()), (() => {
            throw exn;
        })()))));
    }));
}

export function saveHeartRateZones(zones) {
    return singleton.Delay(() => singleton.Bind(zones_1((api) => api.setHeartRateZones(zones)), (_arg1) => {
        const apiResponse = _arg1;
        return singleton.Return((apiResponse.tag === 0) ? (new SubmitHeartRateZonesRes(0)) : ((apiResponse.tag === 1) ? (new SubmitHeartRateZonesRes(0)) : (new SubmitHeartRateZonesRes(1, new ErrorSavingHeartRateZonesException()))));
    }));
}

