import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equalsSafe, equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { ApiException, workoutTemplate } from "../../Server.fs.js";
import { Item, Exercise, Rest, Activity, ManagedId, GetProgrammeRes, SubmitProgrammeRes } from "./Types.fs.js";
import { WeightInKg_value, Seconds_value, Description_value, Name_value, Id_value } from "../../../../shared/Shared.Types.fs.js";
import { map } from "../../.fable/fable-library.3.0.0/List.js";

export class MissingProgrammeException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function MissingProgrammeException$reflection() {
    return class_type("Programme.Create.Api.MissingProgrammeException", void 0, MissingProgrammeException, class_type("System.Exception"));
}

function MissingProgrammeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof MissingProgrammeException) {
                return equalsSafe(this$.Data0, obj.Data0);
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function submitCreateProgramme(newTemplate, context) {
    return singleton.Delay(() => singleton.Bind(workoutTemplate((f) => f.create(newTemplate)), (_arg1) => ((_arg1.tag === 0) ? singleton.Return(new SubmitProgrammeRes(0)) : singleton.Return(new SubmitProgrammeRes(1, new ApiException())))));
}

export function submitUpdateProgramme(newTemplate, context) {
    return singleton.Delay(() => singleton.Bind(workoutTemplate((f) => f.update(newTemplate)), (_arg1) => ((_arg1.tag === 0) ? singleton.Return(new SubmitProgrammeRes(0)) : singleton.Return(new SubmitProgrammeRes(1, new ApiException())))));
}

export function getProgramme(id, context) {
    return singleton.Delay(() => singleton.Bind(workoutTemplate((f) => f.get(id)), (_arg1) => {
        const response = _arg1;
        switch (response.tag) {
            case 0: {
                const i = response.fields[0];
                return singleton.Return(new GetProgrammeRes(0, new Item(new ManagedId(1, Id_value(id)), Name_value(i.Name), Description_value(i.Description), map((activity) => {
                    const matchValue = activity.Type;
                    if (matchValue.tag === 1) {
                        return new Activity(1, new Rest(new ManagedId(1, Id_value(activity.Id)), Seconds_value(matchValue.fields[0])));
                    }
                    else {
                        const e = matchValue.fields[0];
                        return new Activity(0, new Exercise(new ManagedId(1, Id_value(activity.Id)), e.Sets, e.Reps, Id_value(e.LiftId), WeightInKg_value(e.TargetWeight)));
                    }
                }, i.Activities))));
            }
            case 1: {
                return singleton.Return(new GetProgrammeRes(1, new MissingProgrammeException(id)));
            }
            default: {
                return singleton.Return(new GetProgrammeRes(1, new ApiException()));
            }
        }
    }));
}

