import { addDays, today } from "../../.fable/fable-library.3.0.0/Date.js";
import { CategoryIdIncludingAll, CardioType, Metric$reflection, CardioSummary$reflection, PeakPerformance$reflection, CategoryIdIncludingAll$reflection, MetricType$reflection, CardioType$reflection, DateRange$reflection, DateRange as DateRange_1 } from "../../../../shared/Shared.Models.fs.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, array_type, int32_type, option_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { DateRangeType as DateRangeType_1, DateRangeType$reflection } from "../Types.fs.js";

export function last30Days() {
    let copyOfStruct;
    return new DateRange_1((copyOfStruct = today(), addDays(copyOfStruct, -30)), today());
}

export class Model extends Record {
    constructor(IsLoading, DateRangeType, DateRange, AnalysisCardioType, CompareWith, Durations, Categories, Peaks, Summaries, Metrics) {
        super();
        this.IsLoading = IsLoading;
        this.DateRangeType = DateRangeType;
        this.DateRange = DateRange;
        this.AnalysisCardioType = AnalysisCardioType;
        this.CompareWith = CompareWith;
        this.Durations = Durations;
        this.Categories = Categories;
        this.Peaks = Peaks;
        this.Summaries = Summaries;
        this.Metrics = Metrics;
    }
}

export function Model$reflection() {
    return record_type("Analysis.PeakHistory.Types.Model", [], Model, () => [["IsLoading", bool_type], ["DateRangeType", DateRangeType$reflection()], ["DateRange", DateRange$reflection()], ["AnalysisCardioType", CardioType$reflection()], ["CompareWith", option_type(MetricType$reflection())], ["Durations", array_type(int32_type)], ["Categories", array_type(CategoryIdIncludingAll$reflection())], ["Peaks", array_type(array_type(PeakPerformance$reflection()))], ["Summaries", array_type(CardioSummary$reflection())], ["Metrics", array_type(Metric$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(false, new DateRangeType_1(0), last30Days(), new CardioType(1), void 0, new Int32Array([5 * 60, 20 * 60]), [new CategoryIdIncludingAll(0), new CategoryIdIncludingAll(0)], new Array(0), new Array(0), new Array(0));
}

export class GetPeaksRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPeaksRes$reflection() {
    return union_type("Analysis.PeakHistory.Types.GetPeaksRes", [], GetPeaksRes, () => [[["Item1", array_type(array_type(PeakPerformance$reflection()))], ["Item2", array_type(CardioSummary$reflection())], ["Item3", array_type(Metric$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AddDuration", "RemoveDuration", "ChangeAnalysisCardioType", "ChangeDateRangeType", "ChangeStartDate", "ChangeEndDate", "ChangeDuration", "ChangeCategory", "ChangeCompareWith", "GetPeaks", "GetPeaksResult"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.PeakHistory.Types.Msg", [], Msg, () => [[], [["Item", int32_type]], [["Item", CardioType$reflection()]], [["Item", DateRangeType$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item1", int32_type], ["Item2", int32_type]], [["Item1", int32_type], ["Item2", CategoryIdIncludingAll$reflection()]], [["Item", option_type(MetricType$reflection())]], [], [["Item", GetPeaksRes$reflection()]]]);
}

