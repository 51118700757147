import { GetPeaksRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { tryLast, mapIndexed, take, append, skip, tryFindIndex, max } from "../../.fable/fable-library.3.0.0/Seq.js";
import { comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { Peaks_definedPeakDurations } from "../../../../shared/Shared.fs.js";
import { item, length } from "../../.fable/fable-library.3.0.0/List.js";
import { CategoryIdIncludingAll, LapType, DateRange as DateRange_3 } from "../../../../shared/Shared.Models.fs.js";
import { dateRangeFromType } from "../Types.fs.js";
import { getAnalysis } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(9))];
}

export function getNextDuration(existingDurations) {
    const maxDuration = max(existingDurations, {
        Compare: comparePrimitives,
    }) | 0;
    const possibleExistingIndex = tryFindIndex((d) => (d === maxDuration), Peaks_definedPeakDurations);
    if (possibleExistingIndex == null) {
        return (5 * 60) | 0;
    }
    else {
        const index = possibleExistingIndex | 0;
        if ((index + 1) < (length(Peaks_definedPeakDurations) - 1)) {
            return item(index + 1, Peaks_definedPeakDurations) | 0;
        }
        else {
            return (5 * 60) | 0;
        }
    }
}

export function update(context, msg, model) {
    let source2_2, source2_3, categoryIds, matchValue;
    switch (msg.tag) {
        case 1: {
            const index = msg.fields[0] | 0;
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, Int32Array.from((source2_2 = skip(index + 1, model.Durations), append(take(index, model.Durations), source2_2))), Array.from((source2_3 = skip(index + 1, model.Categories), append(take(index, model.Categories), source2_3))), model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 8: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.AnalysisCardioType, msg.fields[0], model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 2: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, msg.fields[0], model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 4: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(msg.fields[0], model.DateRange.EndDate), model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 5: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(model.DateRange.StartDate, msg.fields[0]), model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 6: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, Int32Array.from(mapIndexed((i, d) => {
                if (i === msg.fields[0]) {
                    return msg.fields[1] | 0;
                }
                else {
                    return d | 0;
                }
            }, model.Durations)), model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 3: {
            const newValue_3 = msg.fields[0];
            return [new Model(model.IsLoading, newValue_3, dateRangeFromType(newValue_3), model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 7: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, model.Durations, Array.from(mapIndexed((i_1, d_1) => {
                if (i_1 === msg.fields[0]) {
                    return msg.fields[1];
                }
                else {
                    return d_1;
                }
            }, model.Categories)), model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
        case 9: {
            return [new Model(true, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (categoryIds = Array.from(model.Categories), (context_1) => getAnalysis(model.DateRange, model.Durations, model.CompareWith, categoryIds, new LapType(0), model.AnalysisCardioType, context_1)), context, (arg0) => (new Msg(10, arg0)), (arg) => (new Msg(10, new GetPeaksRes(1, arg))))];
        }
        case 10: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, model.Peaks, model.Summaries, model.Metrics), Cmd_none()];
            }
            else {
                return [new Model(false, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, model.Durations, model.Categories, result.fields[0], result.fields[1], result.fields[2]), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.AnalysisCardioType, model.CompareWith, Int32Array.from(append(model.Durations, [getNextDuration(model.Durations)])), Array.from(append(model.Categories, [(matchValue = tryLast(model.Categories), (matchValue != null) ? matchValue : (new CategoryIdIncludingAll(0)))])), model.Peaks, model.Summaries, model.Metrics), Cmd_OfFunc_result(new Msg(9))];
        }
    }
}

