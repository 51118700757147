import { panel, toolbarPage } from "../../Layout.fs.js";
import * as react from "react";
import { bodyText } from "../../Typography.fs.js";
import { view } from "../../TutorialVideos.fs.js";

export function root(context, model, dispatch) {
    return toolbarPage("Help \u0026 Support", void 0, model.IsSaving, false, [], [react.createElement("div", {}, panel([react.createElement("div", {
        className: "",
    }, bodyText("We have a range of videos that help with common questions:")), view({
        Message: "",
    }), react.createElement("div", {
        className: "my-4",
    }, bodyText("If you\u0027re still looking for help then please contact our support at "), react.createElement("a", {
        className: "text-sm font-normal text-indigo-500",
        href: "mailto:support@forcyclistsbycyclists.com",
    }, "support@forcyclistsbycyclists.com"), bodyText(" or get in touch on the"), react.createElement("a", {
        className: "text-sm font-normal text-indigo-500",
        href: "https://community.forcyclistsbycyclists.com",
    }, "Discourse community forums."))]))]);
}

