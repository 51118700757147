import { toString, Union } from "../../.fable/fable-library.3.0.0/Types.js";
import { warning, palette, Types_EditableProgramme__get_Length, secondsAsDurationString, Types_EditableBlock__get_Length, renderBlock, renderBlockFixedWidth, Types_EditableBlock, Types_EditableBlock$reflection } from "../Common.fs.js";
import { union_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { DndProviderProps, dndProvider, CollectedDropProps, DropSpec$2, useDrop, CollectedDragProps, DragItem$1, DragSpec$1, useDrag } from "../../ReactDnd.fs.js";
import { BlockId_Create } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { Msg } from "./Types.fs.js";
import { ofSeq, empty, singleton, ofArray } from "../../.fable/fable-library.3.0.0/List.js";
import * as react from "react";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { DOMAttr, HTMLAttr, CSSProp, Prop } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { toBlock } from "./Helpers.fs.js";
import { comparePrimitives, max, equalsSafe, uncurry } from "../../.fable/fable-library.3.0.0/Util.js";
import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { mapIndexed, map, collect, max as max_1, sumBy, singleton as singleton_1, isEmpty, delay, tryFindIndex } from "../../.fable/fable-library.3.0.0/Seq.js";
import { ValidationState__IsInvalid, ValidationState__IsPropertyValid_Z721C83C5, ValidationState__IsPropertyInError_Z721C83C5, ValidationState__IsPropertyOrDescendantValid_Z721C83C5 } from "../../../../shared/SimpleValidation.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { attachWindowEvent } from "../../BrowserHelpers.fs.js";
import { ReactInputMaskProps } from "../../ReactInputMask.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import react$002Dinput$002Dmask from "react-input-mask";
import { titleInput, toggleSwitch, simpleTextInput, unstyledIntInput } from "../../Form.fs.js";
import { nextIcon, previousIcon, IconSize, trashIcon } from "../../Icons.fs.js";
import { page, panel } from "../../Layout.fs.js";
import { subtleLabel } from "../../Typography.fs.js";
import { primary, plainRight, plainMiddle, plainLeft } from "../../Buttons.fs.js";
import react$002Ddnd$002Dhtml5$002Dbackend from "react-dnd-html5-backend";

export class DragBlockType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PaletteItem", "TimelineItem"];
    }
}

export function DragBlockType$reflection() {
    return union_type("PowerProgramme.Create.View.DragBlockType", [], DragBlockType, () => [[["Item", Types_EditableBlock$reflection()]], [["Item", Types_EditableBlock$reflection()]]]);
}

export function DragBlockType__get_BlockId(x) {
    if (x.tag === 1) {
        return x.fields[0].BlockId;
    }
    else {
        return x.fields[0].BlockId;
    }
}

export function DragBlockType__get_Block(x) {
    if (x.tag === 1) {
        return x.fields[0];
    }
    else {
        return x.fields[0];
    }
}

export const draggablePaletteItem = FunctionComponent_Of_Z5A158BBF((props) => {
    let inputRecord_1, css;
    const patternInput = useDrag(ofArray([new DragSpec$1(1, (mon) => {
        let dropBlock;
        const inputRecord = props.PaletteBlock;
        dropBlock = (new Types_EditableBlock(BlockId_Create(), inputRecord.Name, inputRecord.Intervals, inputRecord.DoesRepeat, inputRecord.Repeats));
        props.Dispatch(new Msg(8, dropBlock));
        return new DragItem$1("Block", new DragBlockType(0, dropBlock));
    }), new DragSpec$1(3, (src, mon_1) => {
        props.Dispatch(new Msg(9, mon_1.didDrop()));
    }), new DragSpec$1(0, new DragItem$1("Block", new DragBlockType(0, (inputRecord_1 = props.PaletteBlock, new Types_EditableBlock(BlockId_Create(), inputRecord_1.Name, inputRecord_1.Intervals, inputRecord_1.DoesRepeat, inputRecord_1.Repeats))))), new DragSpec$1(2, (mon_2) => (new CollectedDragProps(mon_2.isDragging())))]));
    return react.createElement("div", keyValueList([new Prop(1, patternInput[1]), (css = singleton(new CSSProp(261, patternInput[0].isDragging ? 0.8 : 1)), ["style", keyValueList(css, 1)]), new Prop(0, toString(props.PaletteBlock.BlockId)), new HTMLAttr(65, "mr-1"), new DOMAttr(40, (_arg1) => {
        let inputRecord_2;
        props.Dispatch(new Msg(21, (inputRecord_2 = props.PaletteBlock, new Types_EditableBlock(BlockId_Create(), inputRecord_2.Name, inputRecord_2.Intervals, inputRecord_2.DoesRepeat, inputRecord_2.Repeats))));
    })], 1), renderBlockFixedWidth(true, 100, 66, props.MaxPercentage, props.UserPowerZones, false, void 0, toBlock(props.PaletteBlock)));
}, void 0, uncurry(2, void 0), void 0, "draggablePaletteItem", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Create/View.fs", 24);

export const draggableTimelineItem = FunctionComponent_Of_Z5A158BBF((props) => {
    let css, matchValue_8, block, matchValue_9;
    const patternInput = useDrag(ofArray([new DragSpec$1(0, new DragItem$1("Block", new DragBlockType(1, props.Block))), new DragSpec$1(2, (mon) => {
        let i;
        const item = mon.getItem();
        return new CollectedDragProps((item == null) ? false : (i = item, mon.isDragging() ? equalsSafe(i.dragSrc.BlockId, props.Block.BlockId) : false));
    })]));
    const ref = react.useRef(void 0);
    const patternInput_1 = useDrop(ofArray([new DropSpec$2(0, "Block"), new DropSpec$2(3, (item_1, mon_1) => {
        let tupledArg, tupledArg_1;
        const dragIndex = defaultArg(tryFindIndex((b) => equalsSafe(b.BlockId, DragBlockType__get_BlockId(item_1.dragSrc)), props.Model.Programme.Blocks), -1) | 0;
        const matchValue = ref.current;
        if (matchValue == null) {
        }
        else {
            const currentRef = matchValue;
            const clientOffset = mon_1.getSourceClientOffset();
            const hoverBoundingRect = currentRef.getBoundingClientRect();
            const hoverMiddleLeftX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const hoverMiddleRightX = (hoverBoundingRect.right - hoverBoundingRect.left) - ((hoverBoundingRect.right - hoverBoundingRect.left) / 2);
            const hoverClientX = clientOffset.x - hoverBoundingRect.left;
            const possibleHoverIndex = tryFindIndex((b_1) => equalsSafe(b_1.BlockId, props.Block.BlockId), props.Model.Programme.Blocks);
            if (possibleHoverIndex != null) {
                const hoverIndex = possibleHoverIndex | 0;
                if (dragIndex === -1) {
                    const insertIndex = ((hoverClientX < hoverMiddleLeftX) ? hoverIndex : ((hoverClientX > hoverMiddleRightX) ? (hoverIndex + 1) : hoverIndex)) | 0;
                    if (insertIndex === hoverIndex) {
                    }
                    else {
                        props.Dispatch((tupledArg = [insertIndex, DragBlockType__get_Block(item_1.dragSrc)], new Msg(6, tupledArg[0], tupledArg[1])));
                    }
                }
                else {
                    const matchValue_5 = dragIndex === hoverIndex;
                    if (matchValue_5) {
                    }
                    else {
                        if ((dragIndex < hoverIndex) ? (hoverClientX < hoverMiddleLeftX) : false) {
                        }
                        else if ((dragIndex > hoverIndex) ? (hoverClientX > hoverMiddleRightX) : false) {
                        }
                        else {
                            props.Dispatch((tupledArg_1 = [dragIndex, hoverIndex], new Msg(7, tupledArg_1[0], tupledArg_1[1])));
                        }
                    }
                }
            }
        }
    })]));
    const isBlockValid = ValidationState__IsPropertyOrDescendantValid_Z721C83C5(props.Model.ValidationState, toText(printf("Blocks.[%d]"))(props.BlockIndex));
    return react.createElement("div", {
        ref: ref,
    }, react.createElement("div", keyValueList([new Prop(1, patternInput[1]), (css = singleton(new CSSProp(261, (matchValue_8 = props.Model.MovingBlockId, (matchValue_8 != null) ? (equalsSafe(matchValue_8, props.Block.BlockId) ? 0.4 : 1) : 1))), ["style", keyValueList(css, 1)]), new Prop(0, toString(props.Block.BlockId))], 1), react.createElement("div", {
        ref: patternInput_1[1],
        onClick: (_arg1) => {
            props.Dispatch(new Msg(10, props.Block));
        },
    }, (block = toBlock(props.Block), renderBlock(false, props.Height, props.PixelsPerSecond, props.MaxPercentage, props.Model.UserPowerZones, (matchValue_9 = props.Model.SelectedBlockId, (matchValue_9 != null) ? equalsSafe(matchValue_9, props.Block.BlockId) : false), isBlockValid, block)))));
}, void 0, uncurry(2, void 0), void 0, "draggableTimelineItem", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Create/View.fs", 52);

export const timelineEditor = FunctionComponent_Of_Z5A158BBF((props) => {
    const blocks = props.Model.Programme.Blocks;
    const patternInput = useDrop(ofArray([new DropSpec$2(0, "Block"), new DropSpec$2(1, (mon) => {
        const canDrop = mon.canDrop();
        return new CollectedDropProps(mon.isOver(), canDrop);
    }), new DropSpec$2(2, (dragItem, mon_1) => {
        let tupledArg;
        const matchValue = dragItem.dragSrc;
        if (matchValue.tag === 1) {
        }
        else {
            props.Dispatch((tupledArg = [blocks.length, matchValue.fields[0]], new Msg(6, tupledArg[0], tupledArg[1])));
        }
    })]));
    const containerWidth = react.useState(0);
    const containerRef = react.useRef(void 0);
    react.useEffect(() => {
    const disp = (() => {
        let timeoutId = void 0;
        const setWidth = () => {
            const matchValue_1 = containerRef.current;
            if (matchValue_1 == null) {
            }
            else {
                const width = (~(~matchValue_1.clientWidth)) | 0;
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout((_arg1) => {
                    containerWidth[1](((s) => width));
                }, 30, empty());
            }
        };
        setWidth();
        return attachWindowEvent((_arg2) => {
            setWidth();
        }, window, "resize");
    })();
    return () => disp.Dispose();
    }, []);
    const designerHeight = 250;
    return react.createElement("div", {
        className: "mt-2 mb-4",
    }, react.createElement("div", {
        className: "w-full h-full",
        ref: containerRef,
    }, ...ofSeq(delay(() => {
        let totalLength, pixelsPerSecond, maxPercentage;
        return isEmpty(blocks) ? singleton_1(react.createElement("div", {
            className: "h-full border-2 border-dashed border-red-300 bg-gray-50",
            ref: patternInput[1],
            style: {
                height: designerHeight,
            },
        }, react.createElement("div", {
            className: "text-red-600 h-full flex flex-row justify-center items-center",
        }, "Drag items from the palette above or click them to append"))) : singleton_1(react.createElement("div", {
            className: "h-full bg-gray-50 flex flex-row justify-start",
            style: {
                height: designerHeight,
            },
        }, ...(((containerWidth[0]) > 0) ? (totalLength = sumBy(Types_EditableBlock__get_Length, blocks, {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        }), (totalLength > 0) ? (pixelsPerSecond = ((containerWidth[0]) / totalLength), (maxPercentage = (max(comparePrimitives, max_1(collect((b_1) => map((i) => defaultArg(i.Power.Max, 100), b_1.Intervals), blocks), {
            Compare: comparePrimitives,
        }), 100) | 0), Array.from(mapIndexed((i_1, b_2) => draggableTimelineItem({
            Block: b_2,
            BlockIndex: i_1,
            Dispatch: props.Dispatch,
            Height: designerHeight,
            MaxPercentage: maxPercentage,
            Model: props.Model,
            PixelsPerSecond: pixelsPerSecond,
        }), blocks)))) : (new Array(0))) : (new Array(0)))));
    }))));
}, void 0, uncurry(2, void 0), void 0, "timelineEditor", "/home/runner/work/strengthPlus/strengthPlus/client/src/PowerProgramme/Create/View.fs", 162);

function intervalInput(block, blockIndex, interval, intervalIndex, validationState, dispatch) {
    let props_2, matchValue_3;
    const durationValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Blocks.[%d].Intervals.[%d].LengthInSeconds"))(blockIndex)(intervalIndex)) ? "form-input block px-3 py-2 rounded-none sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input block px-3 py-2 rounded-none border-r-none sm:text-sm sm:leading-5";
    const minPowerValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Blocks.[%d].Intervals.[%d].Power.Min"))(blockIndex)(intervalIndex)) ? "form-input block w-16 px-3 py-2 rounded-none sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input block w-16 px-3 py-2 rounded-none sm:text-sm sm:leading-5";
    const maxPowerValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Blocks.[%d].Intervals.[%d].Power.Max"))(blockIndex)(intervalIndex)) ? "form-input block w-16 px-3 py-2 rounded-none border sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input block w-16 px-3 py-2 rounded-none border sm:text-sm sm:leading-5";
    const canDelete = block.Intervals.length !== 1;
    const deleteButtonStyle = canDelete ? "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" : "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-gray-50 text-sm leading-5 font-medium text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150";
    const isMinValid = ValidationState__IsPropertyValid_Z721C83C5(validationState, toText(printf("Block.[%d].Interval.[%d].Power.Min"))(blockIndex)(intervalIndex));
    return react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4 mt-1",
    }, react.createElement("span", {
        className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "Ride for"), (props_2 = ofArray([new ReactInputMaskProps(0, "99:99:99"), new ReactInputMaskProps(3, durationValidationStyle), new ReactInputMaskProps(2, true), new ReactInputMaskProps(1, "0"), new ReactInputMaskProps(4, (matchValue_3 = interval.Length, (matchValue_3 == null) ? "00:00:00" : matchValue_3)), new ReactInputMaskProps(5, (newValue) => {
        dispatch(new Msg(15, block, interval, Browser_Types_Event__Event_get_Value(newValue)));
    })]), react.createElement(react$002Dinput$002Dmask, keyValueList(props_2, 1))), react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "at between"), unstyledIntInput({
        Class: minPowerValidationStyle,
        IsDisabled: false,
        OnBlur: () => {
        },
        OnChange: (newValue_1) => {
            dispatch(new Msg(16, block, interval, newValue_1));
        },
        Value: interval.Power.Min,
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "and"), unstyledIntInput({
        Class: maxPowerValidationStyle,
        IsDisabled: false,
        OnBlur: () => {
        },
        OnChange: (newValue_2) => {
            dispatch(new Msg(17, block, interval, newValue_2));
        },
        Value: interval.Power.Max,
    }), react.createElement("span", {
        className: "inline-flex items-center px-3  border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "% of FTP"), react.createElement("button", {
        type: "button",
        className: deleteButtonStyle,
        onClick: (_arg3) => {
            dispatch(new Msg(19, block, interval));
        },
        disabled: !canDelete,
    }, react.createElement("div", {
        className: "text-red-700",
    }, trashIcon(new IconSize(3)))));
}

export function blockEditor(model, dispatch) {
    const optionSelectedBlockIndex = tryFindIndex((b) => {
        const matchValue = model.SelectedBlockId;
        if (matchValue != null) {
            return equalsSafe(b.BlockId, matchValue);
        }
        else {
            return false;
        }
    }, model.Programme.Blocks);
    if (optionSelectedBlockIndex != null) {
        const selectedBlockIndex = optionSelectedBlockIndex | 0;
        const block = model.Programme.Blocks[selectedBlockIndex];
        const repeatsValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, toText(printf("Blocks.[%d].Repeats"))(selectedBlockIndex)) ? "form-input block w-16 px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input block w-16 px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5";
        return panel([react.createElement("div", {
            className: "grid grid-cols-12 ",
        }, react.createElement("div", {
            className: "col-span-2",
        }, subtleLabel("Position")), react.createElement("div", {
            className: "col-span-10",
        }, react.createElement("div", {
            className: "mb-4 flex flex-row justify-start",
        }, plainLeft(previousIcon(new IconSize(3)), () => {
            let tupledArg;
            dispatch((tupledArg = [selectedBlockIndex, selectedBlockIndex - 1], new Msg(7, tupledArg[0], tupledArg[1])));
        }, selectedBlockIndex === 0), plainMiddle(react.createElement("div", {
            className: "text-red-700",
        }, trashIcon(new IconSize(3))), () => {
            dispatch(new Msg(22, block));
        }, false), plainRight(nextIcon(new IconSize(3)), () => {
            let tupledArg_1;
            dispatch((tupledArg_1 = [selectedBlockIndex, selectedBlockIndex + 1], new Msg(7, tupledArg_1[0], tupledArg_1[1])));
        }, selectedBlockIndex === (model.Programme.Blocks.length - 1)))), react.createElement("div", {
            className: "col-span-2",
        }, subtleLabel("Name")), react.createElement("div", {
            className: "col-span-10",
        }, simpleTextInput((newValue) => {
            let tupledArg_2;
            dispatch((tupledArg_2 = [block, newValue], new Msg(11, tupledArg_2[0], tupledArg_2[1])));
        }, false, defaultArg(block.Name, ""), "mb-1 w-64")), react.createElement("div", {
            className: "col-span-2 mt-2",
        }, subtleLabel("Repeats")), react.createElement("div", {
            className: "col-span-10 flex flex-row justify-start items-center mt-2",
            style: {
                height: 36,
            },
        }, toggleSwitch(block.DoesRepeat, () => {
            dispatch(new Msg(12, block));
        }), block.DoesRepeat ? react.createElement("div", {
            className: "ml-2 flex flex-row justify-start",
        }, unstyledIntInput({
            Class: repeatsValidationStyle,
            IsDisabled: false,
            OnBlur: () => {
            },
            OnChange: (newValue_1) => {
                dispatch(new Msg(14, block, newValue_1));
            },
            Value: block.Repeats,
        }), react.createElement("span", {
            className: "inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
        }, "times")) : react.createElement(react.Fragment, {})), react.createElement("div", {
            className: "col-span-2 mt-2",
        }, subtleLabel("Steps")), react.createElement("div", {
            className: "col-span-10 mt-2",
        }, react.createElement(react.Fragment, {}, ...Array.from(mapIndexed((i, interval) => intervalInput(block, selectedBlockIndex, interval, i, model.ValidationState, dispatch), block.Intervals))), react.createElement("div", {
            className: "flex flex-row justify-start",
        }, react.createElement("button", {
            className: "mt-2 -ml-px relative inline-flex items-center px-2 py-2 rounded border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150",
            onClick: (_arg6) => {
                dispatch(new Msg(18, block));
            },
        }, "Add Step"))))]);
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

export function titleEditor(model, dispatch) {
    let matchValue;
    return panel([react.createElement("div", {
        className: "flex flex-row justify-between",
    }, react.createElement("div", {
        className: "sm:w-1/2",
    }, titleInput({
        IsValid: ValidationState__IsPropertyValid_Z721C83C5(model.ValidationState, "Name"),
        OnChange: (nv) => {
            dispatch(new Msg(13, nv));
        },
        Placeholder: "Workout title",
        Value: model.Programme.Name,
    })), (matchValue = [isEmpty(model.Programme.Blocks), model.CalculatedTss > 0], matchValue[0] ? react.createElement(react.Fragment, {}) : (matchValue[1] ? react.createElement("div", {
        className: "flex flex-row justify-end items-baseline",
    }, react.createElement("span", {
        className: "text-2xl text-orange-500",
    }, toText(printf("%d "))(model.CalculatedTss)), subtleLabel("TSS"), react.createElement("span", {
        className: "ml-2 text-2xl text-blue-500",
    }, secondsAsDurationString(Types_EditableProgramme__get_Length(model.Programme)))) : react.createElement(react.Fragment, {}))))]);
}

export function root(context, model, dispatch) {
    let arg10;
    const maxPercentage = max_1(collect((b) => map((i) => defaultArg(i.Power.Max, 100), b.Intervals), palette), {
        Compare: comparePrimitives,
    }) | 0;
    return page("Workout Builder", false, [], [warning, react.createElement("div", {
        className: "max-w-full flex flex-col",
    }, titleEditor(model, dispatch), react.createElement("div", {
        className: "mt-4 -my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block max-w-full min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white px-6 py-4",
    }, (arg10 = ofArray([react.createElement("div", {
        className: "overflow-x-auto mt-2 mb-4",
    }, react.createElement("div", {
        className: "flex flex-row justify-start",
    }, ...mapIndexed((i_1, b_1) => draggablePaletteItem({
        Dispatch: dispatch,
        MaxPercentage: maxPercentage,
        PaletteBlock: b_1,
        UserPowerZones: model.UserPowerZones,
    }), palette))), timelineEditor({
        Dispatch: dispatch,
        MaxPercentage: maxPercentage,
        Model: model,
    })]), dndProvider(singleton(new DndProviderProps(0, react$002Ddnd$002Dhtml5$002Dbackend)))(arg10)))), react.createElement("div", {
        className: "mt-4",
    }, blockEditor(model, dispatch)), react.createElement("div", {
        className: "mt-4",
    }, panel([react.createElement("div", {
        className: "flex flex-row my-2",
    }, primary("Save", () => {
        dispatch(new Msg(4));
    }, (ValidationState__IsInvalid(model.ValidationState) ? true : model.IsSaving) ? true : model.IsLoading))])))]);
}

