import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { ValidationState__IsPropertyInError_Z721C83C5, ValidationState__IsValid } from "../../../../shared/SimpleValidation.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { numberInputWithAddonElement, block, header, Converters_optionalIntValue } from "../../Form.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { Msg } from "./Types.fs.js";
import { panel, page } from "../../Layout.fs.js";
import { condensed } from "../../Grids.fs.js";
import { heartRateUnits } from "../../Units.fs.js";
import { primary } from "../../Buttons.fs.js";
import { uncurry } from "../../.fable/fable-library.3.0.0/Util.js";

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    let fromValue_1;
    const model = props.Model;
    const dispatch = props.Dispatch;
    const context = props.Context;
    const age = react.useState(void 0);
    const isSaveDisabled = !(ValidationState__IsValid(model.ValidationState) ? model.IsDirty : false);
    const row = (zoneName, fromValue, toValue, toSetter, isInvalid) => {
        const validationBasedClass = isInvalid ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";
        return ofSeq(delay(() => append(singleton(zoneName), delay(() => {
            let arg10;
            return append((fromValue == null) ? singleton("") : singleton((arg10 = ((fromValue + 1) | 0), toText(printf("%d"))(arg10))), delay(() => singleton(react.createElement("div", {
                className: toText(printf("rounded-md shadow-sm sm")),
            }, react.createElement("input", {
                className: validationBasedClass,
                value: Converters_optionalIntValue(toValue),
                onChange: (ev) => {
                    toSetter(Browser_Types_Event__Event_get_Value(ev));
                },
            })))));
        }))));
    };
    const autoCalculateButton = react.createElement("button", {
        onClick: (_arg1) => {
            dispatch(new Msg(5));
        },
        className: "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-blue-700 bg-blue-50 hover:text-blue-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",
    }, "Auto-calculate");
    return page("Heart Rate Zones", model.IsLoading, [], [react.createElement("div", {}, panel([header("Maximum Heart Rate", "Enter your maximum heart rate and use the auto-calculate button to set typical training zones."), block([numberInputWithAddonElement("sm", "bpm", (arg) => {
        dispatch(new Msg(4, arg));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "MaximumHeartRate"), Converters_optionalIntValue(model.Zones.MaximumHeartRate), autoCalculateButton)])])), react.createElement("div", {
        className: "mt-6",
    }, panel([header("Zones", "Zones for heart rate based training. It is recommended to auto-calculate them based on your FTP but you can edit or enter them manually here."), block([react.createElement("div", {
        className: "w-full",
    }, condensed(["Zone", ("From (" + heartRateUnits(context)) + ")", ("To (" + heartRateUnits(context)) + ")"], [row("Endurance", -1, model.Zones.Endurance, (arg_1) => {
        dispatch(new Msg(0, arg_1));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Endurance")), row("Moderate", model.Zones.Endurance, model.Zones.Moderate, (arg_2) => {
        dispatch(new Msg(1, arg_2));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Moderate")), row("Tempo", model.Zones.Moderate, model.Zones.Tempo, (arg_3) => {
        dispatch(new Msg(2, arg_3));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Tempo")), row("Threshold", model.Zones.Tempo, model.Zones.Threshold, (arg_4) => {
        dispatch(new Msg(3, arg_4));
    }, ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Threshold")), (fromValue_1 = model.Zones.Threshold, ofSeq(delay(() => append(singleton("Anaerobic"), delay(() => {
        let arg10_1;
        return append((fromValue_1 == null) ? singleton("") : singleton((arg10_1 = ((fromValue_1 + 1) | 0), toText(printf("%d"))(arg10_1))), delay(() => {
            let matchValue, bpm;
            return singleton((matchValue = [model.Zones.Threshold, model.Zones.MaximumHeartRate], (matchValue[0] != null) ? ((matchValue[1] != null) ? (bpm = (matchValue[1] | 0), toText(printf("%d"))(bpm)) : "") : ""));
        }));
    })))))]))]), react.createElement("div", {
        className: "flex flex-row justify-end mt-4",
    }, react.createElement("div", {}, primary("Save New Zones", () => {
        dispatch(new Msg(8));
    }, isSaveDisabled)))]))]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/HeartRateZones/Create/View.fs", 9);

