import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Item$reflection } from "./Types.fs.js";
import { record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ofArray, length, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { map, isEmpty, singleton, append, delay, filter, sortWith } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { Peaks_filterDurationsToKeyDurations, ascent, ascentUnits, speed, speedUnits, Peaks_filterToKeyDurations } from "../../Units.fs.js";
import { LapType } from "../../../../shared/Shared.Models.fs.js";
import * as react from "react";
import { Peaks_stillCalculatingPeaks, Peaks_ascentGraph, condensedPropValueTable, Peaks_speedGraph, zonesTable, Peaks_peakPowerGraph, heartRateConesTable, Peaks_peakHeartRateGraph } from "../../CommonRendering.fs.js";
import { panelSubTitle, subtleLabelNoneTruncateClass } from "../../Typography.fs.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { PowerZonesPage, HeartRateZonesPage, Page, href } from "../../Router.fs.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { ascentForDuration, speedForDuration, powerForDuration, heartRateForDuration, durationAsString, distinctDurations } from "./Helpers.fs.js";
import { checkBox } from "../../Form.fs.js";
import { summary } from "../../Grids.fs.js";

export class IRenderPeakAnalysisProps extends Record {
    constructor(Context, Activity, IsLoading) {
        super();
        this.Context = Context;
        this.Activity = Activity;
        this.IsLoading = IsLoading;
    }
}

export function IRenderPeakAnalysisProps$reflection() {
    return record_type("CardioActivity.Show.PeakAnalysis.IRenderPeakAnalysisProps", [], IRenderPeakAnalysisProps, () => [["Context", Context$reflection()], ["Activity", Item$reflection()], ["IsLoading", bool_type]]);
}

function renderHeartPeaks(activity, peakDurations, filterToKey) {
    let projection;
    const entireRouteHeartPeaks = ofSeq((projection = ((p_1) => p_1.PeakDurationSeconds), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), (filterToKey ? (Peaks_filterToKeyDurations) : ((source_1) => filter((f) => true, source_1)))(filter((p) => equalsSafe(p.Lap, new LapType(0)), activity.HeartPeaks)))));
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, ...ofSeq(delay(() => append(singleton(Peaks_peakHeartRateGraph(activity.HeartRateZones, entireRouteHeartPeaks)), delay(() => {
        const matchValue = activity.HeartRateZones;
        if (matchValue == null) {
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-center align-center content-center",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, "For improved analysis ", react.createElement("br", {}), react.createElement("a", keyValueList([href(new Page(5, new HeartRateZonesPage(0))), new HTMLAttr(65, "text-indigo-600")], 1), "set your heart zones"))));
        }
        else {
            const zones = matchValue;
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, react.createElement("div", {
                className: "mb-4 text-left",
            }, panelSubTitle("Peak Heart Rates")), heartRateConesTable(zones, false))));
        }
    }))))));
}

function renderPowerPeaks(activity, peakDurations, filterToKey, useWattsKg) {
    let projection;
    const entireRoutePowerPeaks = Array.from((projection = ((p_1) => p_1.PeakDurationSeconds), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), (filterToKey ? (Peaks_filterToKeyDurations) : ((source_1) => filter((f) => true, source_1)))(filter((p) => equalsSafe(p.Lap, new LapType(0)), activity.PowerPeaks)))));
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, ...ofSeq(delay(() => {
        let matchValue;
        return append(singleton(Peaks_peakPowerGraph(entireRoutePowerPeaks, activity.PowerZones, (matchValue = [useWattsKg, activity.Summary.WeightAtTimeOfRideKg], matchValue[0] ? ((matchValue[1] != null) ? matchValue[1] : (void 0)) : (void 0)))), delay(() => {
            const matchValue_1 = activity.PowerZones;
            if (matchValue_1 == null) {
                return singleton(react.createElement("div", {
                    className: "md:ml-4 flex flex-col justify-center align-center content-center",
                }, react.createElement("div", {
                    className: subtleLabelNoneTruncateClass + " text-center",
                    style: {
                        minWidth: 260,
                    },
                }, "For improved power analysis ", react.createElement("br", {}), react.createElement("a", keyValueList([href(new Page(6, new PowerZonesPage(0))), new HTMLAttr(65, "text-indigo-600")], 1), "set your power zones"))));
            }
            else {
                const zones = matchValue_1;
                return singleton(react.createElement("div", {
                    className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
                }, react.createElement("div", {
                    className: subtleLabelNoneTruncateClass + " text-center",
                    style: {
                        minWidth: 260,
                    },
                }, react.createElement("div", {
                    className: "mb-4 text-left",
                }, panelSubTitle("Peak Powers")), zonesTable(zones, false))));
            }
        }));
    }))));
}

function renderSpeedPeaks(context, activity, peakDurations, filterToKey) {
    let projection, arg20, arg10, arg20_1, arg10_1;
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, Peaks_speedGraph(ofSeq((projection = ((p_1) => p_1.PeakDurationSeconds), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), (filterToKey ? (Peaks_filterToKeyDurations) : ((source_1) => filter((f) => true, source_1)))(filter((p) => equalsSafe(p.Lap, new LapType(0)), activity.SpeedPeaks)))))), react.createElement("div", {
        className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
    }, react.createElement("div", {
        className: subtleLabelNoneTruncateClass + " text-center",
        style: {
            minWidth: 260,
        },
    }, react.createElement("div", {
        className: "mb-4 text-left",
    }, panelSubTitle("Speed")), condensedPropValueTable([["Max Speed", (arg20 = speedUnits(context), (arg10 = speed(context, activity.Summary.Metrics.MaximumSpeedMetersPerSecond), toText(printf("%s %s"))(arg10)(arg20)))], ["Avg Speed", (arg20_1 = speedUnits(context), (arg10_1 = speed(context, activity.Summary.Metrics.AverageSpeedMetersPerSecond), toText(printf("%s %s"))(arg10_1)(arg20_1)))]])))));
}

function renderAscentPeaks(context, activity, peakDurations, filterToKey) {
    let projection, arg20, arg10, arg20_1, arg10_1, arg20_2, arg10_2;
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, Peaks_ascentGraph(ofSeq((projection = ((p_1) => p_1.PeakDurationSeconds), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), (filterToKey ? (Peaks_filterToKeyDurations) : ((source_1) => filter((f) => true, source_1)))(filter((p) => equalsSafe(p.Lap, new LapType(0)), activity.AscentPeaks)))))), react.createElement("div", {
        className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
    }, react.createElement("div", {
        className: subtleLabelNoneTruncateClass + " text-center",
        style: {
            minWidth: 260,
        },
    }, react.createElement("div", {
        className: "mb-4 text-left",
    }, panelSubTitle("Ascent")), condensedPropValueTable([["Total Ascent", (arg20 = ascentUnits(context), (arg10 = ascent(context, activity.Summary.Metrics.ElevationGainedMeters), toText(printf("%s %s"))(arg10)(arg20)))], ["Min Altitude", (arg20_1 = ascentUnits(context), (arg10_1 = ascent(context, activity.Summary.Metrics.MinimumAltitudeMeters), toText(printf("%s %s"))(arg10_1)(arg20_1)))], ["Max Altitude", (arg20_2 = ascentUnits(context), (arg10_2 = ascent(context, activity.Summary.Metrics.MaximumAltitudeMeters), toText(printf("%s %s"))(arg10_2)(arg20_2)))]])))));
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const filterToKey = react.useState(false);
    const showWattsKg = react.useState(false);
    const activity = props.Activity;
    const peakDurations = ofSeq(((filterToKey[0]) ? (Peaks_filterDurationsToKeyDurations) : ((source) => filter((f) => true, source)))(distinctDurations(activity)));
    const hasPowerPeaks = !isEmpty(activity.PowerPeaks);
    const hasHeartPeaks = !isEmpty(activity.HeartPeaks);
    const hasSpeedPeaks = !isEmpty(activity.SpeedPeaks);
    const hasAscentPeaks = !isEmpty(activity.AscentPeaks);
    return react.createElement("div", {
        className: "mt-4",
    }, ...ofSeq(delay(() => ((length(peakDurations) === 0) ? singleton(react.createElement("p", {}, Peaks_stillCalculatingPeaks)) : append(singleton(react.createElement("div", {}, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "flex flex-row mb-4",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "mr-4",
    }, checkBox("Show only key peaks", filterToKey[0], (arg00) => {
        filterToKey[1](arg00);
    }))), delay(() => (hasPowerPeaks ? singleton(react.createElement("div", {
        className: "mr-4",
    }, checkBox("Show watts/kg", showWattsKg[0], (arg00_1) => {
        showWattsKg[1](arg00_1);
    }))) : singleton(react.createElement(react.Fragment, {}))))))))), delay(() => append(hasPowerPeaks ? singleton(renderPowerPeaks(activity, peakDurations, filterToKey[0], showWattsKg[0])) : singleton(react.createElement(react.Fragment, {})), delay(() => append(hasHeartPeaks ? singleton(renderHeartPeaks(activity, peakDurations, filterToKey[0])) : singleton(react.createElement(react.Fragment, {})), delay(() => append(hasSpeedPeaks ? singleton(renderSpeedPeaks(props.Context, activity, peakDurations, filterToKey[0])) : singleton(react.createElement(react.Fragment, {})), delay(() => append(hasAscentPeaks ? singleton(renderAscentPeaks(props.Context, activity, peakDurations, filterToKey[0])) : singleton(react.createElement(react.Fragment, {})), delay(() => {
        let arg10, arg10_1;
        return singleton(react.createElement("div", {
            className: "mt-4",
        }, summary(true, true, true, ofArray(["Duration", "Heart Rate (bpm)", "Power (watts)", (arg10 = speedUnits(props.Context), toText(printf("Speed (%s)"))(arg10)), (arg10_1 = ascentUnits(props.Context), toText(printf("Ascent (%s)"))(arg10_1))]), map((d) => ofSeq(delay(() => append(singleton(durationAsString(d)), delay(() => append(singleton(heartRateForDuration(d, new LapType(0), activity)), delay(() => append(hasPowerPeaks ? singleton(powerForDuration(d, new LapType(0), activity)) : singleton(react.createElement("span", {
            className: "italic",
        }, "No power recorded")), delay(() => append(hasSpeedPeaks ? singleton(speedForDuration(d, new LapType(0), activity)) : singleton(react.createElement("span", {
            className: "italic",
        }, "No speed recorded")), delay(() => (hasAscentPeaks ? singleton(ascentForDuration(d, new LapType(0), activity)) : singleton(react.createElement("span", {
            className: "italic",
        }, "No ascent recorded"))))))))))))), peakDurations))));
    })))))))))))))), delay(() => ((length(peakDurations) < 8) ? singleton(react.createElement("p", {
        className: "italic",
    }, "It looks like we\u0027re still calculating some of your performance peaks, please check back in a few minutes for the complete data.")) : singleton(react.createElement(react.Fragment, {})))))))));
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/PeakAnalysis.fs", 130);

