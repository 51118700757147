import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { record_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { mapIndexed, length } from "./.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { some } from "./.fable/fable-library.3.0.0/Option.js";
import { subtleLabelClass } from "./Typography.fs.js";

export class GroupedStatistic extends Record {
    constructor(Title, ValueColor, Value, UnitLabel) {
        super();
        this.Title = Title;
        this.ValueColor = ValueColor;
        this.Value = Value;
        this.UnitLabel = UnitLabel;
    }
}

export function GroupedStatistic$reflection() {
    return record_type("GroupedStatBar.GroupedStatistic", [], GroupedStatistic, () => [["Title", string_type], ["ValueColor", string_type], ["Value", string_type], ["UnitLabel", string_type]]);
}

export function view(isRoundedTop, isRoundedBottom, metrics) {
    const metricsLength = length(metrics) | 0;
    const last = (metricsLength - 1) | 0;
    const roundedTop = isRoundedTop ? "rounded-t-lg" : "";
    const roundedBottom = isRoundedBottom ? "rounded-b-lg" : "";
    return react.createElement("div", {
        className: toText(printf("bg-white %s %s"))(roundedTop)(roundedBottom),
    }, react.createElement("div", {
        className: toText(printf(" flex-grow grid grid-cols-1 grid-cols-%d"))(metricsLength),
    }, ...mapIndexed((i_2, s) => {
        const borderClass = (((i_2 === 0) ? some(void 0) : (void 0)) != null) ? "" : "border-l border-gray-200";
        return react.createElement("div", {
            className: borderClass,
        }, react.createElement("div", {
            className: "px-3 pt-1",
        }, react.createElement("div", {
            className: "flex items-center",
        }, react.createElement("div", {
            className: "ml-3 w-0 flex-1",
        }, react.createElement("dl", {}, react.createElement("dt", {
            className: subtleLabelClass,
        }, s.Title), react.createElement("dd", {
            className: "-mt-1 flex justify-between items-baseline",
        }, react.createElement("div", {
            className: toText(printf("flex items-baseline text-xl leading-8 font-semibold %s"))(s.ValueColor),
        }, s.Value, react.createElement("span", {
            className: "ml-2 text-sm leading-5 font-medium text-gray-500",
        }, s.UnitLabel))))))));
    }, metrics)));
}

