import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { HeartRateZonesPage, HeartRateZonesPage$reflection } from "../../Router.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "../Create/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Model extends Record {
    constructor(CurrentPage, CreateModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.CreateModel = CreateModel;
    }
}

export function Model$reflection() {
    return record_type("HeartRateZones.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", HeartRateZonesPage$reflection()], ["CreateModel", option_type(Model$reflection_1())]]);
}

export function Model_get_Empty() {
    return new Model(new HeartRateZonesPage(0), void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CreateMsg"];
    }
}

export function Msg$reflection() {
    return union_type("HeartRateZones.Dispatcher.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]]]);
}

