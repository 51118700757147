import { tryParse } from "../client/src/.fable/fable-library.3.0.0/Guid.js";
import { Union, FSharpRef } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { isGreaterThanOrEqualTo, hasMaxLengthOf, hasMinLengthOf, isNotEmpty, isNotEqualTo, ValidationItem, ValidationState } from "./SimpleValidation.fs.js";
import { ofArray, singleton } from "../client/src/.fable/fable-library.3.0.0/List.js";
import { int32_type, option_type, union_type, string_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";

export function isGuid(_arg1, value) {
    let outArg;
    if ((outArg = "00000000-0000-0000-0000-000000000000", [tryParse(value, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg])[0]) {
        return new ValidationState(1);
    }
    else {
        return new ValidationState(0, singleton(new ValidationItem("Value is not a GUID", "", "isGuid")));
    }
}

export class UserId__UserId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UserId"];
    }
}

export function UserId__UserId$reflection() {
    return union_type("Shared.Types.UserId._UserId", [], UserId__UserId, () => [[["Item", string_type]]]);
}

export function UserId_create(v) {
    return new UserId__UserId(0, v);
}

export function UserId_value(_arg1) {
    return _arg1.fields[0];
}

export class Id__Id extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Id"];
    }
}

export function Id__Id$reflection() {
    return union_type("Shared.Types.Id._Id", [], Id__Id, () => [[["Item", string_type]]]);
}

export function Id_create(v) {
    return new Id__Id(0, v);
}

export function Id_value(_arg1) {
    return _arg1.fields[0];
}

export function Id_validator() {
    let copyOfStruct;
    return ofArray([isNotEqualTo((copyOfStruct = "00000000-0000-0000-0000-000000000000", copyOfStruct)), (arg00$0040) => ((value) => isGuid(arg00$0040, value))]);
}

export class OptionalId__OptionalId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OptionalId"];
    }
}

export function OptionalId__OptionalId$reflection() {
    return union_type("Shared.Types.OptionalId._OptionalId", [], OptionalId__OptionalId, () => [[["Item", option_type(string_type)]]]);
}

export function OptionalId_create(v) {
    return new OptionalId__OptionalId(0, v);
}

export const OptionalId_none = new OptionalId__OptionalId(0, void 0);

export function OptionalId_value(_arg1) {
    return _arg1.fields[0];
}

export function OptionalId_validator() {
    let copyOfStruct;
    return ofArray([isNotEqualTo((copyOfStruct = "00000000-0000-0000-0000-000000000000", copyOfStruct)), (arg00$0040) => ((value) => isGuid(arg00$0040, value))]);
}

export class Name__Name extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Name"];
    }
}

export function Name__Name$reflection() {
    return union_type("Shared.Types.Name._Name", [], Name__Name, () => [[["Item", string_type]]]);
}

export function Name_create(v) {
    return new Name__Name(0, v);
}

export function Name_value(_arg1) {
    return _arg1.fields[0];
}

export function Name_validator() {
    let clo1, clo1_1;
    return ofArray([(arg00$0040) => ((value) => isNotEmpty(arg00$0040, value.split(""))), (clo1 = hasMinLengthOf(1), (arg10) => {
        const clo2 = clo1(arg10);
        return (arg20) => clo2(arg20.split(""));
    }), (clo1_1 = hasMaxLengthOf(64), (arg10_1) => {
        const clo2_1 = clo1_1(arg10_1);
        return (arg20_1) => clo2_1(arg20_1.split(""));
    })]);
}

export class Description__Description extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Description"];
    }
}

export function Description__Description$reflection() {
    return union_type("Shared.Types.Description._Description", [], Description__Description, () => [[["Item", string_type]]]);
}

export function Description_create(v) {
    return new Description__Description(0, v);
}

export function Description_value(_arg1) {
    return _arg1.fields[0];
}

export function Description_validator() {
    let clo1;
    return singleton((clo1 = hasMaxLengthOf(1024), (arg10) => {
        const clo2 = clo1(arg10);
        return clo2;
    }));
}

export class IndexPosition__IndexPosition extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IndexPosition"];
    }
}

export function IndexPosition__IndexPosition$reflection() {
    return union_type("Shared.Types.IndexPosition._IndexPosition", [], IndexPosition__IndexPosition, () => [[["Item", int32_type]]]);
}

export function IndexPosition_create(v) {
    return new IndexPosition__IndexPosition(0, v);
}

export function IndexPosition_value(_arg1) {
    return _arg1.fields[0];
}

export function IndexPosition_validator() {
    return singleton(isGreaterThanOrEqualTo(0));
}

export class Seconds__Seconds extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Seconds"];
    }
}

export function Seconds__Seconds$reflection() {
    return union_type("Shared.Types.Seconds._Seconds", [], Seconds__Seconds, () => [[["Item", int32_type]]]);
}

export function Seconds_create(v) {
    return new Seconds__Seconds(0, v);
}

export function Seconds_value(_arg1) {
    return _arg1.fields[0];
}

export function Seconds_validator() {
    return singleton(isGreaterThanOrEqualTo(30));
}

export class WeightInKg__WeightInKg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["WeightInKg"];
    }
}

export function WeightInKg__WeightInKg$reflection() {
    return union_type("Shared.Types.WeightInKg._WeightInKg", [], WeightInKg__WeightInKg, () => [[["Item", int32_type]]]);
}

export function WeightInKg_create(v) {
    return new WeightInKg__WeightInKg(0, v);
}

export function WeightInKg_value(_arg1) {
    return _arg1.fields[0];
}

export function WeightInKg_validator() {
    return singleton(isGreaterThanOrEqualTo(1));
}

export class OptionalWeightInKg__OptionalWeightInKg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OptionalWeightInKg"];
    }
}

export function OptionalWeightInKg__OptionalWeightInKg$reflection() {
    return union_type("Shared.Types.OptionalWeightInKg._OptionalWeightInKg", [], OptionalWeightInKg__OptionalWeightInKg, () => [[["Item", option_type(int32_type)]]]);
}

export function OptionalWeightInKg_create(v) {
    return new OptionalWeightInKg__OptionalWeightInKg(0, v);
}

export function OptionalWeightInKg_value(_arg1) {
    return _arg1.fields[0];
}

export function OptionalWeightInKg_validator() {
    return singleton(isGreaterThanOrEqualTo(1));
}

