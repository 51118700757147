import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Msg, Msg$reflection, Model$reflection } from "./Types.fs.js";
import { union_type, record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Context$reflection } from "../../Context.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { addDays, addMonths, date, now } from "../../.fable/fable-library.3.0.0/Date.js";
import { DateRange, CategoryIdIncludingAll } from "../../../../shared/Shared.Models.fs.js";
import { rangeNumber, map, singleton, append, delay, find } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { modalWrapper } from "../../CommonRendering.fs.js";
import { ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { dashboardTitle } from "../../Typography.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { ClassName, OnChange, Value, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { Converters_optionalIntValue, Converters_parseIntFromInput, numberInputWithAddonElement, radioButton } from "../../Form.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { categoryDropdown } from "../Helpers.fs.js";
import { plain } from "../../Buttons.fs.js";
import { op_UnaryNegation_Int32 } from "../../.fable/fable-library.3.0.0/Int32.js";

export class IRollingAnalysisModalProps extends Record {
    constructor(Model, Dispatch, Context) {
        super();
        this.Model = Model;
        this.Dispatch = Dispatch;
        this.Context = Context;
    }
}

export function IRollingAnalysisModalProps$reflection() {
    return record_type("Analysis.PeakPerformances.RollingAnalysisModal.IRollingAnalysisModalProps", [], IRollingAnalysisModalProps, () => [["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)], ["Context", Context$reflection()]]);
}

export class RollingAnalysisDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RollBackwards", "RollForwards"];
    }
}

export function RollingAnalysisDirection$reflection() {
    return union_type("Analysis.PeakPerformances.RollingAnalysisModal.RollingAnalysisDirection", [], RollingAnalysisDirection, () => [[], []]);
}

export class RollingAnalysisModalTemplate extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Rolling30Days", "Rolling60Days", "Rolling90Days", "Last12Months", "Last6Months", "Custom"];
    }
}

export function RollingAnalysisModalTemplate$reflection() {
    return union_type("Analysis.PeakPerformances.RollingAnalysisModal.RollingAnalysisModalTemplate", [], RollingAnalysisModalTemplate, () => [[], [], [], [], [], []]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    let copyOfStruct;
    const period = react.useState(30);
    const offset = react.useState(30);
    const datePointType = react.useState(new RollingAnalysisDirection(0));
    const datePoint = react.useState((copyOfStruct = now(), date(copyOfStruct)));
    const category = react.useState(new CategoryIdIncludingAll(0));
    const numberOfPeriods = react.useState(5);
    const dropdownValues = [[new RollingAnalysisModalTemplate(0), "Rolling 30 days"], [new RollingAnalysisModalTemplate(1), "Rolling 60 days"], [new RollingAnalysisModalTemplate(2), "Rolling 90 days"], [new RollingAnalysisModalTemplate(4), "Last 6 months"], [new RollingAnalysisModalTemplate(3), "Last 12 months"], [new RollingAnalysisModalTemplate(5), "Custom"]];
    const dropdownValue = react.useState(dropdownValues[0][0]);
    const valueFromText = (text) => find((v) => (v[1] === text), dropdownValues)[0];
    const isInvalidPeriod = () => {
        if (!equalsSafe(dropdownValue[0], new RollingAnalysisModalTemplate(5))) {
            return false;
        }
        else {
            const matchValue = period[0];
            if (matchValue == null) {
                return true;
            }
            else {
                return matchValue <= 0;
            }
        }
    };
    const isInvalidNumberOfPeriods = () => {
        if (!equalsSafe(dropdownValue[0], new RollingAnalysisModalTemplate(5))) {
            return false;
        }
        else {
            const matchValue_2 = numberOfPeriods[0];
            if (matchValue_2 == null) {
                return true;
            }
            else {
                const np = matchValue_2 | 0;
                if (np <= 0) {
                    return true;
                }
                else {
                    return np > 5;
                }
            }
        }
    };
    return modalWrapper((_arg3) => {
        props.Dispatch(new Msg(10));
    }, react.createElement("div", {}, react.createElement("div", {
        className: "min-h-full flex flex-column justify-around",
    }, react.createElement("div", {
        className: "w-full",
    }, ...ofSeq(delay(() => append(singleton(dashboardTitle("Configure rolling date analysis")), delay(() => {
        let value;
        return append(singleton(react.createElement("div", {
            className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-2",
        }, react.createElement("label", {
            className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
        }, "Window"), react.createElement("div", {
            className: "w-full shadow-sm sm:col-span-2",
        }, react.createElement("select", {
            value: (value = (dropdownValue[0]), find((v_1) => equalsSafe(v_1[0], value), dropdownValues)[1]),
            className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md",
            onChange: (ev) => {
                let copyOfStruct_1;
                const arg00 = valueFromText(Browser_Types_Event__Event_get_Value(ev));
                dropdownValue[1](arg00);
                const template = valueFromText(Browser_Types_Event__Event_get_Value(ev));
                if (template.tag === 5) {
                }
                else {
                    datePoint[1]((copyOfStruct_1 = now(), date(copyOfStruct_1)));
                    datePointType[1]((new RollingAnalysisDirection(0)));
                    numberOfPeriods[1](5);
                    switch (template.tag) {
                        case 0: {
                            period[1](30);
                            offset[1](30);
                            break;
                        }
                        case 1: {
                            period[1](60);
                            offset[1](60);
                            break;
                        }
                        case 2: {
                            period[1](90);
                            offset[1](90);
                            break;
                        }
                        default: {
                        }
                    }
                }
            },
        }, ...map((drt) => react.createElement("option", {
            value: drt[1],
        }, drt[1]), dropdownValues))))), delay(() => {
            let matchValue_1;
            return append(((dropdownValue[0]).tag === 5) ? singleton(react.createElement(react.Fragment, {}, react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-2",
            }, react.createElement("label", {
                className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
            }, "Apply from"), react.createElement("div", {
                className: "mt-1 sm:mt-0 sm:col-span-2",
            }, flatpickr(ofArray([Value(datePoint[0]), OnChange((arg00_1) => {
                datePoint[1](arg00_1);
            }), ClassName("form-input rounded-md block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5")])))), react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-1",
            }, react.createElement("div", {}), react.createElement("div", {
                className: "flex flex-row justify-start items-center sm:col-span-2",
            }, react.createElement("div", {}, radioButton("forwards", "start", equalsSafe(datePointType[0], new RollingAnalysisDirection(1)), () => {
                datePointType[1]((new RollingAnalysisDirection(1)));
            })), react.createElement("div", {
                className: "ml-4",
            }, radioButton("backwards", "end", equalsSafe(datePointType[0], new RollingAnalysisDirection(0)), () => {
                datePointType[1]((new RollingAnalysisDirection(0)));
            })))), react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3",
            }, react.createElement("label", {
                className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
            }, "Period"), react.createElement("div", {
                className: "mt-1 sm:mt-0 sm:col-span-2",
            }, numberInputWithAddonElement("md", "Period", (arg) => {
                const arg00_2 = Converters_parseIntFromInput(arg);
                period[1](arg00_2);
            }, isInvalidPeriod(), Converters_optionalIntValue(period[0]), react.createElement("span", {
                className: "inline-flex items-center px-3 border border-l-0 rounded-r-md border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
            }, "days")))), react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-2",
            }, react.createElement("label", {
                className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
            }, "Offset"), react.createElement("div", {
                className: "mt-1 sm:mt-0 sm:col-span-2",
            }, numberInputWithAddonElement("md", "Offset", (arg_1) => {
                const arg00_3 = Converters_parseIntFromInput(arg_1);
                offset[1](arg00_3);
            }, (!equalsSafe(dropdownValue[0], new RollingAnalysisModalTemplate(5))) ? false : (matchValue_1 = (offset[0]), (matchValue_1 == null) ? true : (matchValue_1 <= 0)), Converters_optionalIntValue(offset[0]), react.createElement("span", {
                className: "inline-flex items-center px-3 border border-l-0 rounded-r-md border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
            }, "days")))), react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3",
            }, react.createElement("label", {
                className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
            }, "No. of periods"), react.createElement("div", {
                className: "mt-1 sm:mt-0 sm:col-span-2",
            }, react.createElement("div", {
                className: toText(printf("rounded-md shadow-sm md")),
            }, react.createElement("input", {
                className: "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + (isInvalidNumberOfPeriods() ? " border-red-600 focus:border-red-400" : ""),
                value: Converters_optionalIntValue(numberOfPeriods[0]),
                onChange: (ev_1) => {
                    const arg00_4 = Converters_parseIntFromInput(Browser_Types_Event__Event_get_Value(ev_1));
                    numberOfPeriods[1](arg00_4);
                },
            })))))) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(react.createElement("div", {
                className: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-3",
            }, react.createElement("label", {
                className: "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
            }, "Activity Type"), react.createElement("div", {
                className: "mt-1 sm:mt-0 sm:col-span-2",
            }, react.createElement("div", {
                className: toText(printf("rounded-md shadow-sm md")),
            }, categoryDropdown(category[0], props.Context, (arg00_5) => {
                category[1](arg00_5);
            })))))));
        }));
    })))))), react.createElement("div", {
        className: "grid grid-cols-2 gap-4 mt-4",
    }, plain("Cancel", () => {
        props.Dispatch(new Msg(10));
    }, false), plain("Set Dates", () => {
        let tupledArg_1;
        const lastNMonths = (months) => {
            let tupledArg;
            props.Dispatch((tupledArg = [Array.from(map((offsetMonth) => {
                let startDate;
                let copyOfStruct_2 = now();
                startDate = addMonths(copyOfStruct_2, op_UnaryNegation_Int32(offsetMonth));
                return new DateRange(startDate, addMonths(startDate, 1));
            }, rangeNumber(1, 1, months))), Array.from(map((_arg1) => (category[0]), rangeNumber(1, 1, months)))], new Msg(11, tupledArg[0], tupledArg[1])));
        };
        const matchValue_3 = dropdownValue[0];
        switch (matchValue_3.tag) {
            case 0:
            case 1:
            case 2: {
                const matchValue_4 = [numberOfPeriods[0], period[0], offset[0]];
                let pattern_matching_result, np_1, o, p_2;
                if (matchValue_4[0] != null) {
                    if (matchValue_4[1] != null) {
                        if (matchValue_4[2] != null) {
                            pattern_matching_result = 0;
                            np_1 = matchValue_4[0];
                            o = matchValue_4[2];
                            p_2 = matchValue_4[1];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        props.Dispatch((tupledArg_1 = [Array.from(map((periodIndex) => {
                            let startDate_1;
                            const matchValue_5 = datePointType[0];
                            if (matchValue_5.tag === 1) {
                                let copyOfStruct_5 = datePoint[0];
                                startDate_1 = addDays(copyOfStruct_5, o * periodIndex);
                            }
                            else {
                                let copyOfStruct_4;
                                let copyOfStruct_3 = datePoint[0];
                                copyOfStruct_4 = addDays(copyOfStruct_3, op_UnaryNegation_Int32(p_2));
                                startDate_1 = addDays(copyOfStruct_4, op_UnaryNegation_Int32(o * periodIndex));
                            }
                            return new DateRange(startDate_1, addDays(startDate_1, p_2));
                        }, rangeNumber(0, 1, np_1 - 1))), Array.from(map((_arg2) => (category[0]), rangeNumber(0, 1, np_1 - 1)))], new Msg(11, tupledArg_1[0], tupledArg_1[1])));
                        break;
                    }
                    case 1: {
                        break;
                    }
                }
                break;
            }
            case 4: {
                lastNMonths(6);
                break;
            }
            case 3: {
                lastNMonths(12);
                break;
            }
            default: {
                const matchValue_4 = [numberOfPeriods[0], period[0], offset[0]];
                let pattern_matching_result_1, np_1, o, p_2;
                if (matchValue_4[0] != null) {
                    if (matchValue_4[1] != null) {
                        if (matchValue_4[2] != null) {
                            pattern_matching_result_1 = 0;
                            np_1 = matchValue_4[0];
                            o = matchValue_4[2];
                            p_2 = matchValue_4[1];
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        props.Dispatch((tupledArg_1 = [Array.from(map((periodIndex) => {
                            let startDate_1;
                            const matchValue_5 = datePointType[0];
                            if (matchValue_5.tag === 1) {
                                let copyOfStruct_5 = datePoint[0];
                                startDate_1 = addDays(copyOfStruct_5, o * periodIndex);
                            }
                            else {
                                let copyOfStruct_4;
                                let copyOfStruct_3 = datePoint[0];
                                copyOfStruct_4 = addDays(copyOfStruct_3, op_UnaryNegation_Int32(p_2));
                                startDate_1 = addDays(copyOfStruct_4, op_UnaryNegation_Int32(o * periodIndex));
                            }
                            return new DateRange(startDate_1, addDays(startDate_1, p_2));
                        }, rangeNumber(0, 1, np_1 - 1))), Array.from(map((_arg2) => (category[0]), rangeNumber(0, 1, np_1 - 1)))], new Msg(11, tupledArg_1[0], tupledArg_1[1])));
                        break;
                    }
                    case 1: {
                        break;
                    }
                }
            }
        }
    }, (isInvalidPeriod() ? true : isInvalidNumberOfPeriods()) ? true : isInvalidNumberOfPeriods()))));
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/PeakPerformances/RollingAnalysisModal.fs", 27);

