import { Record, Union } from "../../.fable/fable-library.3.0.0/Types.js";
import { tuple_type, class_type, bool_type, list_type, record_type, option_type, int32_type, union_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { newGuid } from "../../.fable/fable-library.3.0.0/Guid.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { ValidationState as ValidationState_1, ValidationState$reflection } from "../../../../shared/SimpleValidation.fs.js";

export class ManagedId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClientId", "ServerId"];
    }
}

export function ManagedId$reflection() {
    return union_type("Programme.Create.Types.ManagedId", [], ManagedId, () => [[["Item", string_type]], [["Item", string_type]]]);
}

export class Exercise extends Record {
    constructor(Id, Sets, Reps, LiftId, Weight) {
        super();
        this.Id = Id;
        this.Sets = Sets;
        this.Reps = Reps;
        this.LiftId = LiftId;
        this.Weight = Weight;
    }
}

export function Exercise$reflection() {
    return record_type("Programme.Create.Types.Exercise", [], Exercise, () => [["Id", ManagedId$reflection()], ["Sets", option_type(int32_type)], ["Reps", option_type(int32_type)], ["LiftId", string_type], ["Weight", option_type(int32_type)]]);
}

export function Exercise_Empty_Z721C83C5(defaultLiftId) {
    let copyOfStruct;
    return new Exercise(new ManagedId(0, (copyOfStruct = newGuid(), copyOfStruct)), 3, 10, defaultLiftId, 12);
}

export class Rest extends Record {
    constructor(Id, Seconds) {
        super();
        this.Id = Id;
        this.Seconds = Seconds;
    }
}

export function Rest$reflection() {
    return record_type("Programme.Create.Types.Rest", [], Rest, () => [["Id", ManagedId$reflection()], ["Seconds", option_type(int32_type)]]);
}

export function Rest_Empty() {
    let copyOfStruct;
    return new Rest(new ManagedId(0, (copyOfStruct = newGuid(), copyOfStruct)), 60);
}

export class Activity extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Exercise", "Rest"];
    }
}

export function Activity$reflection() {
    return union_type("Programme.Create.Types.Activity", [], Activity, () => [[["Item", Exercise$reflection()]], [["Item", Rest$reflection()]]]);
}

export function Activity__get_ActivityId(x) {
    if (x.tag === 1) {
        return x.fields[0].Id;
    }
    else {
        return x.fields[0].Id;
    }
}

export class Item extends Record {
    constructor(Id, Name, Description, Activities) {
        super();
        this.Id = Id;
        this.Name = Name;
        this.Description = Description;
        this.Activities = Activities;
    }
}

export function Item$reflection() {
    return record_type("Programme.Create.Types.Item", [], Item, () => [["Id", ManagedId$reflection()], ["Name", string_type], ["Description", string_type], ["Activities", list_type(Activity$reflection())]]);
}

export function Item_Empty_796E87A7(lift) {
    let copyOfStruct;
    return new Item(new ManagedId(0, (copyOfStruct = newGuid(), copyOfStruct)), "", "", singleton(new Activity(0, Exercise_Empty_Z721C83C5(Id_value(lift.Id)))));
}

export class Model extends Record {
    constructor(IsSaving, IsLoading, IsDirty, Item, ValidationState) {
        super();
        this.IsSaving = IsSaving;
        this.IsLoading = IsLoading;
        this.IsDirty = IsDirty;
        this.Item = Item;
        this.ValidationState = ValidationState;
    }
}

export function Model$reflection() {
    return record_type("Programme.Create.Types.Model", [], Model, () => [["IsSaving", bool_type], ["IsLoading", bool_type], ["IsDirty", bool_type], ["Item", Item$reflection()], ["ValidationState", ValidationState$reflection()]]);
}

export function Model_Empty_796E87A7(lift) {
    return new Model(true, false, false, Item_Empty_796E87A7(lift), new ValidationState_1(1));
}

export class SubmitProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitProgrammeRes$reflection() {
    return union_type("Programme.Create.Types.SubmitProgrammeRes", [], SubmitProgrammeRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class GetProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetProgrammeRes$reflection() {
    return union_type("Programme.Create.Types.GetProgrammeRes", [], GetProgrammeRes, () => [[["Item", Item$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeName", "ChangeDescription", "AddExercise", "ChangeExerciseLift", "ChangeExerciseWeight", "ChangeExerciseSets", "ChangeExerciseReps", "AddRest", "ChangeRestSeconds", "RemoveActivity", "MoveActivityUp", "MoveActivityDown", "SubmitProgramme", "SubmitProgrammeResult", "GetProgramme", "GetProgrammeResult"];
    }
}

export function Msg$reflection() {
    return union_type("Programme.Create.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", tuple_type(Exercise$reflection(), string_type)]], [["Item", tuple_type(Exercise$reflection(), string_type)]], [["Item", tuple_type(Exercise$reflection(), string_type)]], [["Item", tuple_type(Exercise$reflection(), string_type)]], [], [["Item", tuple_type(Rest$reflection(), string_type)]], [["Item", Activity$reflection()]], [["Item", Activity$reflection()]], [["Item", Activity$reflection()]], [], [["Item", SubmitProgrammeRes$reflection()]], [["Item", string_type]], [["Item", GetProgrammeRes$reflection()]]]);
}

