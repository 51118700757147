import { Union, Record } from "./.fable/fable-library.3.0.0/Types.js";
import { DashboardPage, Page, Page$reflection } from "./Router.fs.js";
import { Lookups$reflection, Context_get_Empty, Context$reflection } from "./Context.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "./PowerProgramme/Dispatcher/Types.fs.js";
import { union_type, class_type, record_type, list_type, bool_type, option_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "./Metrics/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_3, Model$reflection as Model$reflection_3 } from "./Help/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_4, Model$reflection as Model$reflection_4 } from "./Welcome/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_5, Model$reflection as Model$reflection_5 } from "./Analysis/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_6, Model$reflection as Model$reflection_6 } from "./HeartRateZones/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_7, Model$reflection as Model$reflection_7 } from "./PowerZones/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_8, Model$reflection as Model$reflection_8 } from "./WeeklyTrainingLog/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_9, Model$reflection as Model$reflection_9 } from "./CardioActivity/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_10, Model$reflection as Model$reflection_10 } from "./Dashboard/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_11, Model$reflection as Model$reflection_11 } from "./Programme/Dispatcher/Types.fs.js";
import { Msg$reflection as Msg$reflection_12, Model_get_Empty as Model_get_Empty_1, Model$reflection as Model$reflection_12 } from "./Modal/Manager.fs.js";
import { CartItem$reflection } from "./ComparisonCart/Manager.fs.js";
import { empty } from "./.fable/fable-library.3.0.0/List.js";
import { CardioSummary$reflection } from "../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(CurrentPage, Context, IntervalProgrammeDispatcher, MetricsDispatcher, HelpDispatcher, WelcomeDispatcher, AnalysisDispatcher, HeartRateZonesDispatcher, PowerZonesDispatcher, WeeklyTrainingLogDispatcher, CardioActivityDispatcher, DashboardDispatcher, ProgrammeDispatcher, IsNavigationDropdownOpen, IsGlobalLoading, ModalManager, ComparisonCart) {
        super();
        this.CurrentPage = CurrentPage;
        this.Context = Context;
        this.IntervalProgrammeDispatcher = IntervalProgrammeDispatcher;
        this.MetricsDispatcher = MetricsDispatcher;
        this.HelpDispatcher = HelpDispatcher;
        this.WelcomeDispatcher = WelcomeDispatcher;
        this.AnalysisDispatcher = AnalysisDispatcher;
        this.HeartRateZonesDispatcher = HeartRateZonesDispatcher;
        this.PowerZonesDispatcher = PowerZonesDispatcher;
        this.WeeklyTrainingLogDispatcher = WeeklyTrainingLogDispatcher;
        this.CardioActivityDispatcher = CardioActivityDispatcher;
        this.DashboardDispatcher = DashboardDispatcher;
        this.ProgrammeDispatcher = ProgrammeDispatcher;
        this.IsNavigationDropdownOpen = IsNavigationDropdownOpen;
        this.IsGlobalLoading = IsGlobalLoading;
        this.ModalManager = ModalManager;
        this.ComparisonCart = ComparisonCart;
    }
}

export function Model$reflection() {
    return record_type("ClientApp.Types.Model", [], Model, () => [["CurrentPage", Page$reflection()], ["Context", Context$reflection()], ["IntervalProgrammeDispatcher", option_type(Model$reflection_1())], ["MetricsDispatcher", option_type(Model$reflection_2())], ["HelpDispatcher", option_type(Model$reflection_3())], ["WelcomeDispatcher", option_type(Model$reflection_4())], ["AnalysisDispatcher", option_type(Model$reflection_5())], ["HeartRateZonesDispatcher", option_type(Model$reflection_6())], ["PowerZonesDispatcher", option_type(Model$reflection_7())], ["WeeklyTrainingLogDispatcher", option_type(Model$reflection_8())], ["CardioActivityDispatcher", option_type(Model$reflection_9())], ["DashboardDispatcher", option_type(Model$reflection_10())], ["ProgrammeDispatcher", option_type(Model$reflection_11())], ["IsNavigationDropdownOpen", bool_type], ["IsGlobalLoading", bool_type], ["ModalManager", Model$reflection_12()], ["ComparisonCart", list_type(CartItem$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(new Page(9, new DashboardPage(0)), Context_get_Empty(), void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, void 0, false, true, Model_get_Empty_1(), empty());
}

export class GetLookupsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetLookupsRes$reflection() {
    return union_type("ClientApp.Types.GetLookupsRes", [], GetLookupsRes, () => [[["Item", Lookups$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IntervalProgrammeDispatcherMsg", "MetricsDispatcherMsg", "HelpDispatcherMsg", "WelcomeDispatcherMsg", "AnalysisDispatcherMsg", "HeartRateZonesDispatcherMsg", "PowerZonesDispatcherMsg", "WeeklyTrainingLogDispatcherMsg", "CardioActivityDispatcherMsg", "DashboardDispatcherMsg", "ProgrammeDispatcherMsg", "Nothing", "SetNavigationBarState", "GetLookups", "GetLookupsResult", "ModalManagerMsg", "AddItemToComparisonCart", "RemoveItemFromComparisonCart", "ClearComparisonCart"];
    }
}

export function Msg$reflection() {
    return union_type("ClientApp.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", Msg$reflection_9()]], [["Item", Msg$reflection_10()]], [["Item", Msg$reflection_11()]], [], [["Item", bool_type]], [], [["Item", GetLookupsRes$reflection()]], [["Item", Msg$reflection_12()]], [["Item", CardioSummary$reflection()]], [["Item", CartItem$reflection()]], []]);
}

