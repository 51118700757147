import { GetProgrammeRes, Model, Msg, Model_Empty_Z191E29AA } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { getProgramme } from "./Api.fs.js";

export function init(id) {
    return [Model_Empty_Z191E29AA(id), Cmd_OfFunc_result(new Msg(0, id))];
}

export function update(context, msg, model) {
    if (msg.tag === 1) {
        const result = msg.fields[0];
        if (result.tag === 1) {
            console.error(some(result.fields[0]));
            return [new Model(false, model.Item), Cmd_none()];
        }
        else {
            return [new Model(false, result.fields[0]), Cmd_none()];
        }
    }
    else {
        return [new Model(true, model.Item), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, getProgramme, msg.fields[0], (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetProgrammeRes(1, arg))))];
    }
}

