import * as react from "react";
import { collect, filter, isEmpty, concat, exists, mapIndexed, singleton, delay, append, map, find } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { availablePeakTypes, cardioTypeFromString, metricTypeFromString, metricTypeAsLabel, dateRangeTypeLookup } from "../Types.fs.js";
import { Msg$reflection, Model$reflection, Msg } from "./Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { PeakPerformance, PeakPerformanceType, MetricType, CategoryIdIncludingAll } from "../../../../shared/Shared.Models.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { empty, singleton as singleton_1, ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { ClassName, OnChange, Value, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { panelTitle } from "../../Typography.fs.js";
import { renderPeakHistoryTable, activityTypeEditor, seriesIndicator } from "../Helpers.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { parse } from "../../.fable/fable-library.3.0.0/Int32.js";
import { shortDurationAsString } from "../../Units.fs.js";
import { Peaks_definedPeakDurations } from "../../../../shared/Shared.fs.js";
import { IconSize, trashIcon } from "../../Icons.fs.js";
import { plain } from "../../Buttons.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { analysisPage, tabs as tabs_1, panel } from "../../Layout.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { warningPanel } from "../../Notifications.fs.js";
import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { IRenderHistoryProps, view } from "../RenderHistory.fs.js";
import { checkBox } from "../../Form.fs.js";

function embeddedDateRangeTypeDropdown(model, dispatch) {
    const roundedClass = (model.DateRangeType.tag === 8) ? "rounded-r-none border-l" : "border-l";
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: find((tupledArg_1) => equalsSafe(tupledArg_1[0], model.DateRangeType), dateRangeTypeLookup)[1],
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let text;
            dispatch(new Msg(3, (text = Browser_Types_Event__Event_get_Value(ev), find((tupledArg) => (tupledArg[1] === text), dateRangeTypeLookup)[0])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), dateRangeTypeLookup)));
}

function embeddedCategoryDropdown(model, index, context, dispatch) {
    const roundedClass = (model.DateRangeType.tag === 8) ? "rounded-l-none rounded-r-none border-r" : "rounded-l-none rounded-r-none border-r";
    const lookupAsString = (category) => {
        if (category.tag === 1) {
            return category.fields[0];
        }
        else {
            return "all";
        }
    };
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: lookupAsString(model.Categories[index]),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let tupledArg, value;
            dispatch((tupledArg = [index, (value = Browser_Types_Event__Event_get_Value(ev), (value === "all") ? (new CategoryIdIncludingAll(0)) : (new CategoryIdIncludingAll(1, value)))], new Msg(7, tupledArg[0], tupledArg[1])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: lookupAsString(drt[0]),
    }, drt[1].toLocaleLowerCase()), Array.from(append([[new CategoryIdIncludingAll(0), "All"]], map((c) => [new CategoryIdIncludingAll(1, Id_value(c.Id)), c.DisplayText], context.Lookups.CardioCategories))))));
}

function dateRangeEditor(model, context, dispatch) {
    const datePickers = react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, ...ofSeq(delay(() => append(singleton(embeddedDateRangeTypeDropdown(model, dispatch)), delay(() => ((model.DateRangeType.tag === 8) ? append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "from")), delay(() => append(singleton(flatpickr(ofArray([Value(model.DateRange.StartDate), OnChange((arg) => {
        dispatch(new Msg(4, arg));
    }), ClassName("form-input rounded-none block  transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "to")), delay(() => singleton(flatpickr(ofArray([Value(model.DateRange.EndDate), OnChange((arg_1) => {
        dispatch(new Msg(5, arg_1));
    }), ClassName("form-input rounded-l-none block transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))))))))) : singleton(react.createElement(react.Fragment, {})))))))));
    return react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Date range"), react.createElement("div", {}, datePickers));
}

export function durationsEditor(model, context, dispatch) {
    return react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Durations"), react.createElement("div", {}, react.createElement("div", {}, ...Array.from(mapIndexed((index, duration) => react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "mt-1",
    }, seriesIndicator(index)), react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: toText(printf("%d"))(duration),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-l-md rounded-r-none",
        onChange: (ev) => {
            let tupledArg;
            dispatch((tupledArg = [index, parse(Browser_Types_Event__Event_get_Value(ev), 511, false, 32)], new Msg(6, tupledArg[0], tupledArg[1])));
        },
    }, ...map((d) => react.createElement("option", {
        value: toText(printf("%d"))(d),
    }, shortDurationAsString(d)), Peaks_definedPeakDurations))), react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "peaks for"), embeddedCategoryDropdown(model, index, context, dispatch), react.createElement("button", {
        className: "inline-flex items-center px-3 border border-l-0 rounded-r border-gray-300 bg-gray-50 sm:text-sm text-red-300 " + ((model.Durations.length === 1) ? "text-orange-300" : "text-red-700"),
        disabled: model.Durations.length === 1,
        onClick: (_arg1) => {
            dispatch(new Msg(1, index));
        },
    }, trashIcon(new IconSize(3))))), model.Durations))), react.createElement("div", {
        className: "mt-4",
    }, react.createElement("div", {
        className: "text-left flex justify-start mt-6",
    }, react.createElement("div", {
        className: "mr-2 flex",
        style: {
            width: 150,
        },
    }, plain("Add duration", () => {
        dispatch(new Msg(0));
    }, false))))));
}

export function compareWithMetricEditor(compareWithValue, onChange, isDisabled) {
    const asLabel = (compareWith) => {
        if (compareWith != null) {
            return metricTypeAsLabel(compareWith);
        }
        else {
            return "No comparison";
        }
    };
    return react.createElement("div", {}, panelTitle("Compare with"), react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: asLabel(compareWithValue),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md " + (isDisabled ? "bg-gray-200" : ""),
        onChange: (ev) => {
            let from;
            onChange((from = Browser_Types_Event__Event_get_Value(ev), (from === "No comparison") ? (void 0) : metricTypeFromString(from)));
        },
        disabled: isDisabled,
    }, ...map((drt) => react.createElement("option", {
        value: asLabel(drt),
    }, asLabel(drt)), [void 0, new MetricType(0), new MetricType(2), new MetricType(4)]))))));
}

export class IPeakHistoryProps extends Record {
    constructor(Context, Model, Dispatch) {
        super();
        this.Context = Context;
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function IPeakHistoryProps$reflection() {
    return record_type("Analysis.PeakHistory.View.IPeakHistoryProps", [], IPeakHistoryProps, () => [["Context", Context$reflection()], ["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

export function filterPanel(model, context, dispatch) {
    return panel([react.createElement("div", {
        className: "flex flex-row justify-start",
    }, activityTypeEditor(model.AnalysisCardioType, (arg_1) => {
        dispatch(new Msg(2, cardioTypeFromString(arg_1)));
    }, false), compareWithMetricEditor(model.CompareWith, (arg_2) => {
        dispatch(new Msg(8, arg_2));
    }, false)), dateRangeEditor(model, context, dispatch), react.createElement("div", {
        className: "mt-4",
    }, durationsEditor(model, context, dispatch))]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    let source2_3, source2_2, source2_1;
    const leftTools = react.createElement("div", {});
    const rightTools = react.createElement("div", {});
    const selectedTabIndex = react.useState(0);
    const showWattsKg = react.useState(false);
    const hasWeightForPower = exists((p) => {
        if (equalsSafe(p.PeakType, new PeakPerformanceType(1))) {
            return p.Weight != null;
        }
        else {
            return false;
        }
    }, concat(props.Model.Peaks));
    const renderTab = (peakType, divideByWeight) => {
        if (isEmpty(concat(props.Model.Peaks))) {
            return warningPanel("No data available", "There are no peaks available in those date ranges");
        }
        else {
            const useWeight = (divideByWeight ? hasWeightForPower : false) ? equalsSafe(peakType, new PeakPerformanceType(1)) : false;
            const mapper = useWeight ? ((source_2) => map((p_1) => (new PeakPerformance(p_1.StartDateTimeUtc, p_1.PeakDurationSeconds, p_1.PeakValue / defaultArg(p_1.Weight, 1), p_1.PeakType, p_1.Lap, p_1.CardioActivitySummaryId, p_1.Weight)), source_2)) : ((source_3) => map((x) => x, source_3));
            return view(new IRenderHistoryProps(props.Context, Array.from(map((peaks) => Array.from(mapper(filter((p_2) => equalsSafe(p_2.PeakType, peakType), peaks))), props.Model.Peaks)), props.Model.Summaries, peakType, renderPeakHistoryTable, 320, useWeight, (props.Model.CompareWith == null) ? (void 0) : props.Model.Metrics));
        }
    };
    const patternInput = availablePeakTypes(collect((x_1) => x_1, props.Model.Peaks));
    const tabPageMatches = Array.from((source2_3 = (source2_2 = (source2_1 = append(patternInput[3] ? singleton_1(renderTab(new PeakPerformanceType(3), false)) : empty(), []), append(patternInput[2] ? singleton_1(renderTab(new PeakPerformanceType(4), false)) : empty(), source2_1)), append(patternInput[1] ? singleton_1(renderTab(new PeakPerformanceType(0), false)) : empty(), source2_2)), append(patternInput[0] ? singleton_1(renderTab(new PeakPerformanceType(1), showWattsKg[0])) : empty(), source2_3)));
    const tabs = react.createElement("div", {
        className: "mt-6",
    }, panel([react.createElement("div", {
        className: "-mt-2",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "mb-4",
    }, tabs_1(patternInput[4], selectedTabIndex[0], (arg00) => {
        selectedTabIndex[1](arg00);
    }))), delay(() => {
        let matchValue_2;
        return append((matchValue_2 = [selectedTabIndex[0], hasWeightForPower], (matchValue_2[0] === 0) ? (matchValue_2[1] ? singleton(react.createElement("div", {
            className: "pb-4 flex flex-row justify-between items-center",
        }, checkBox("Show watts/kg", showWattsKg[0], (arg00_1) => {
            showWattsKg[1](arg00_1);
        }))) : singleton(react.createElement(react.Fragment, {}))) : singleton(react.createElement(react.Fragment, {}))), delay(() => singleton(((selectedTabIndex[0]) < tabPageMatches.length) ? tabPageMatches[selectedTabIndex[0]] : react.createElement(react.Fragment, {}))));
    })))))]));
    return analysisPage(props.Model.IsLoading, false, leftTools, rightTools, [filterPanel(props.Model, props.Context, props.Dispatch), tabs]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/PeakHistory/View.fs", 184);

