import { Union, Record, FSharpException } from "./.fable/fable-library.3.0.0/Types.js";
import { union_type, record_type, class_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { createAtom, equals } from "./.fable/fable-library.3.0.0/Util.js";
import { ProxyRequestException__get_Response, ProxyRequestException } from "./.fable/Fable.Remoting.Client.7.2.0/Types.fs.js";
import { IRollingAnalysisApi$reflection, IPowerProgrammeApi$reflection, ITrainingLoadApi$reflection, IApplicationUserApi$reflection, IDashboardApi$reflection, IZonesApi$reflection, IPeaksApi$reflection, ITrainingLogApi$reflection, ICalendarApi$reflection, ICardioActivityApi$reflection, IMetricApi$reflection, IWorkoutTemplateApi$reflection, ILookupApi$reflection, IAuthenticationApi$reflection } from "../../shared/Shared.Api.fs.js";
import { AccessToken$reflection } from "../../shared/Shared.Models.fs.js";
import { Remoting_buildProxy_Z15584635, RemotingModule_withAuthorizationHeader, RemotingModule_withRouteBuilder, RemotingModule_createApi, RemotingModule_withBaseUrl } from "./.fable/Fable.Remoting.Client.7.2.0/Remoting.fs.js";
import { Route_builder } from "../../shared/Shared.fs.js";
import { singleton } from "./.fable/fable-library.3.0.0/AsyncBuilder.js";

export class ApiException extends FSharpException {
    constructor() {
        super();
    }
}

export function ApiException$reflection() {
    return class_type("App.Server.ApiException", void 0, ApiException, class_type("System.Exception"));
}

function ApiException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof ApiException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

const environment = "dev";

export function isUnauthorizedError(exn) {
    if (exn instanceof ProxyRequestException) {
        if (ProxyRequestException__get_Response(exn).StatusCode === 401) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export class IStrengthPlusApi extends Record {
    constructor(authentication, lookups) {
        super();
        this.authentication = authentication;
        this.lookups = lookups;
    }
}

export function IStrengthPlusApi$reflection() {
    return record_type("App.Server.IStrengthPlusApi", [], IStrengthPlusApi, () => [["authentication", IAuthenticationApi$reflection()], ["lookups", ILookupApi$reflection()]]);
}

export class AppAuthenticationState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Authorized", "Unauthenticated"];
    }
}

export function AppAuthenticationState$reflection() {
    return union_type("App.Server.AppAuthenticationState", [], AppAuthenticationState, () => [[["Item", AccessToken$reflection()]], []]);
}

export function AppAuthenticationState__Token(x) {
    if (x.tag === 0) {
        return "Bearer " + x.fields[0].Token;
    }
    else {
        return "";
    }
}

export const accessToken = createAtom(new AppAuthenticationState(1));

export function userIsInOnboardingQueue() {
    if (accessToken().tag === 0) {
        return accessToken().fields[0].IsInQueue;
    }
    else {
        return false;
    }
}

export function isNewUser() {
    if (accessToken().tag === 0) {
        return accessToken().fields[0].IsNewUser;
    }
    else {
        return false;
    }
}

export function getBaseUri() {
    return "https://api-dev.forcyclistsbycyclists.com";
}

function createRemoteApi() {
    return RemotingModule_withBaseUrl("https://api-dev.forcyclistsbycyclists.com", RemotingModule_createApi());
}

function createAuthorizedApi() {
    let options;
    return RemotingModule_withRouteBuilder(Route_builder, (options = createRemoteApi(), RemotingModule_withAuthorizationHeader(AppAuthenticationState__Token(accessToken()), options)));
}

export const api = (() => {
    const lookups = Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, createRemoteApi()), {
        ResolveType: ILookupApi$reflection,
    });
    return new IStrengthPlusApi(Remoting_buildProxy_Z15584635(RemotingModule_withRouteBuilder(Route_builder, createRemoteApi()), {
        ResolveType: IAuthenticationApi$reflection,
    }), lookups);
})();

function createWorkoutTemplateApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IWorkoutTemplateApi$reflection,
    });
}

function createMetricApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IMetricApi$reflection,
    });
}

function createUploadApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: ICardioActivityApi$reflection,
    });
}

function createCalendarApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: ICalendarApi$reflection,
    });
}

function createTrainingLogApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: ITrainingLogApi$reflection,
    });
}

function createPeaksApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IPeaksApi$reflection,
    });
}

function createZonesApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IZonesApi$reflection,
    });
}

function createDashboardApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IDashboardApi$reflection,
    });
}

function createApplicationUserApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IApplicationUserApi$reflection,
    });
}

function createTrainingLoadApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: ITrainingLoadApi$reflection,
    });
}

function createPowerProgrammeApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IPowerProgrammeApi$reflection,
    });
}

function createRollingAnalysisApi() {
    return Remoting_buildProxy_Z15584635(createAuthorizedApi(), {
        ResolveType: IRollingAnalysisApi$reflection,
    });
}

export function workoutTemplate(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createWorkoutTemplateApi())));
}

export function metrics(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createMetricApi())));
}

export function cardioActivity(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createUploadApi())));
}

export function calendar(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createCalendarApi())));
}

export function trainingLog(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createTrainingLogApi())));
}

export function peaks(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createPeaksApi())));
}

export function zones(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createZonesApi())));
}

export function dashboard(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createDashboardApi())));
}

export function applicationUser(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createApplicationUserApi())));
}

export function trainingLoad(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createTrainingLoadApi())));
}

export function powerProgramme(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createPowerProgrammeApi())));
}

export function rollingAnalysis(f) {
    return singleton.Delay(() => singleton.ReturnFrom(f(createRollingAnalysisApi())));
}

