import * as react from "react";

export const view = react.createElement("div", {
    className: "sk-chase",
}, react.createElement("div", {
    className: "sk-chase-dot",
}), react.createElement("div", {
    className: "sk-chase-dot",
}), react.createElement("div", {
    className: "sk-chase-dot",
}), react.createElement("div", {
    className: "sk-chase-dot",
}), react.createElement("div", {
    className: "sk-chase-dot",
}), react.createElement("div", {
    className: "sk-chase-dot",
}));

