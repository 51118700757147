import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { peaks } from "../../Server.fs.js";
import { GetPeaksAndTargetsRes } from "./Types.fs.js";

export class UnableToLoadPeaksAndTargetsException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadPeaksAndTargetsException$reflection() {
    return class_type("Analysis.Competitiveness.Api.UnableToLoadPeaksAndTargetsException", void 0, UnableToLoadPeaksAndTargetsException, class_type("System.Exception"));
}

function UnableToLoadPeaksAndTargetsException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadPeaksAndTargetsException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getAnalysis(analysisDateRange, cardioType, context) {
    return singleton.Delay(() => singleton.Bind(peaks((api) => api.getCompetitiveness(analysisDateRange.DateRange, cardioType, analysisDateRange.CategoryId)), (_arg1) => {
        let tupledArg;
        const results = _arg1;
        return singleton.Return((results.tag === 0) ? (tupledArg = results.fields[0], new GetPeaksAndTargetsRes(0, tupledArg[0], tupledArg[1])) : (new GetPeaksAndTargetsRes(1, new UnableToLoadPeaksAndTargetsException())));
    }));
}

