import { pageSizeWeeks, GetPageRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { WeeklyTrainingLogPage_FromRoute, Page, modifyLocation } from "../../Router.fs.js";
import { item } from "../../.fable/fable-library.3.0.0/List.js";
import { getWeeklyTrainingLogs } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { append } from "../../.fable/fable-library.3.0.0/Seq.js";
import { addDays } from "../../.fable/fable-library.3.0.0/Date.js";

export function init() {
    const model = Model_get_Empty();
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(1))];
}

function setRoute(years, seletedYearIndex, selectedMonthIndex) {
    let arg10;
    modifyLocation(new Page(7, (arg10 = ((selectedMonthIndex + 1) | 0), WeeklyTrainingLogPage_FromRoute(item(seletedYearIndex, years), arg10))));
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 1: {
            return [new Model(model.Items, model.FirstRowStartDate, model.LastRowStartDate, model.Maximums, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getWeeklyTrainingLogs(model, context_1), context, (arg0) => (new Msg(2, arg0)), (arg) => (new Msg(2, new GetPageRes(1, arg))))];
        }
        case 2: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Items, model.FirstRowStartDate, model.LastRowStartDate, model.Maximums, false), Cmd_none()];
            }
            else {
                return [new Model(Array.from(append(model.Items, result.fields[0])), model.FirstRowStartDate, model.LastRowStartDate, result.fields[1], false), Cmd_none()];
            }
        }
        default: {
            if (model.IsLoading) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(model.Items, model.FirstRowStartDate, addDays(model.LastRowStartDate, -7 * pageSizeWeeks), model.Maximums, model.IsLoading), Cmd_OfFunc_result(new Msg(1))];
            }
        }
    }
}

