import { panel, toolbarPage } from "../../Layout.fs.js";
import { primary } from "../../Buttons.fs.js";
import { Msg } from "./Types.fs.js";
import * as react from "react";
import { bodyText } from "../../Typography.fs.js";
import { toggleSwitch } from "../../Form.fs.js";
import { view } from "../../TutorialVideos.fs.js";

export function root(context, model, dispatch) {
    return toolbarPage("Welcome!", void 0, model.IsSaving, false, [primary("Continue", () => {
        dispatch(new Msg(0));
    }, false)], [react.createElement("div", {}, panel([react.createElement("div", {}, bodyText("Firstly - massive thanks for joining us here at Performance for Cyclists and participating in our Early Access programme. We hope the software is useful to you and look forward to hearing your feedback. By signing up to the newsletter we\u0027ll keep you up to date with the latest site news.")), react.createElement("div", {
        className: "flex justify-start flex-row mt-4 py-4",
    }, react.createElement("div", {
        className: "text-sm font-normal text-indigo-500 mr-4",
    }, "Sign me up to the newsletter"), toggleSwitch(model.SignupToNewsletter, () => {
        dispatch(new Msg(1));
    })), react.createElement("div", {
        className: "my-4",
    }, bodyText("To help you get started we have a collection of video tutorials to show you round the system and how to accomplish common tasks. If you want to watch them later you can always find them again by clicking your profile icon at the top right.")), view({
        Message: "",
    })]))]);
}

