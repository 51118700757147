import * as react from "react";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { length, append, rangeNumber, mapIndexed, map } from "./.fable/fable-library.3.0.0/Seq.js";
import { bind, value } from "./.fable/fable-library.3.0.0/Option.js";
import { singleton, empty } from "./.fable/fable-library.3.0.0/List.js";
import { previousIcon, IconSize, nextIcon } from "./Icons.fs.js";
import { equals } from "./.fable/fable-library.3.0.0/Util.js";

function isEven(x) {
    return (x % 2) === 0;
}

function isOdd(x) {
    return !isEven(x);
}

export function selectableFlexibleSummary(isRoundedTop, isRoundedBottom, isShadow, onClick, columnHeaders, rows) {
    const roundingTop = isRoundedTop ? "rounded-t-lg" : "";
    const roundingBottom = isRoundedBottom ? "rounded-b-lg" : "";
    const shadow = isShadow ? "shadow" : "";
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: toText(printf("align-middle inline-block min-w-full %s overflow-hidden sm:%s sm:%s border-b border-gray-200"))(shadow)(roundingTop)(roundingBottom),
    }, react.createElement("table", {
        className: "min-w-full",
    }, (columnHeaders == null) ? react.createElement(react.Fragment, {}) : react.createElement("thead", {}, react.createElement("tr", {}, ...map((arg00) => react.createElement("th", {
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, arg00), value(columnHeaders)))), react.createElement("tbody", {}, ...mapIndexed((i, row) => {
        let baseClass, arg20;
        return react.createElement("tr", {
            onClick: (_arg1) => {
                if (onClick == null) {
                }
                else {
                    onClick(i);
                }
            },
            className: (baseClass = ((onClick == null) ? "" : "hover:bg-gray-300 cursor-pointer"), (arg20 = (isOdd(i) ? "bg-gray-50" : "bg-white"), toText(printf("%s %s"))(baseClass)(arg20))),
        }, ...map((v) => react.createElement("td", {
            className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
        }, v), row));
    }, rows))))));
}

export function selectableSummary(isRoundedTop, isRoundedBottom, isShadow, onClick, columnHeaders, rows) {
    return selectableFlexibleSummary(isRoundedTop, isRoundedBottom, isShadow, onClick, bind((headers) => map((s) => s, headers), columnHeaders), rows);
}

export function summary(isRoundedTop, isRoundedBottom, isShadow, columnHeaders, rows) {
    return selectableSummary(isRoundedTop, isRoundedBottom, isShadow, void 0, columnHeaders, rows);
}

export function condensed(columnHeaders, rows) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-100",
    }, react.createElement("table", {
        className: "min-w-full",
    }, react.createElement("thead", {}, react.createElement("tr", {}, ...map((name) => react.createElement("th", {
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-small text-gray-500 uppercase tracking-wider",
    }, name), columnHeaders))), react.createElement("tbody", {}, ...mapIndexed((i, row) => react.createElement("tr", {
        className: isOdd(i) ? "bg-gray-50" : "bg-white",
    }, ...map((v) => react.createElement("td", {
        className: "px-6 py-2 whitespace-no-wrap text-sm leading-5 font-small text-gray-900",
    }, v), row)), rows))))));
}

export function dropDownGrid(onClick, footer, rows) {
    return react.createElement("div", {
        className: "origin-top-right absolute right-0 z-1200",
    }, react.createElement("div", {
        className: "flex flex-col shadow sm:rounded-lg bg-white",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 ",
    }, react.createElement("div", {
        className: " align-middle inline-block min-w-full overflow-hidden sm:rounded-t-lg",
    }, react.createElement("table", {
        className: "min-w-full",
    }, react.createElement("tbody", {}, ...mapIndexed((i, row) => {
        let baseClass, arg20;
        return react.createElement("tr", {
            onClick: (_arg1) => {
                if (onClick == null) {
                }
                else {
                    onClick(i);
                }
            },
            className: (baseClass = ((onClick == null) ? "" : "hover:bg-gray-300 cursor-pointer"), (arg20 = (isOdd(i) ? "bg-gray-50" : "bg-white"), toText(printf("%s %s"))(baseClass)(arg20))),
        }, ...map((v) => react.createElement("td", {
            className: "px-2 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
        }, v), row));
    }, rows))))), footer));
}

export function pagination(pageNumber, numberOfPages, numberOfRows, pageSize, gotoPage) {
    let source2_3, source2_1, source2_5;
    const firstItemNumber = ((pageNumber * pageSize) + 1) | 0;
    const lastItemNumber = ((((firstItemNumber + pageSize) - 1) > numberOfRows) ? numberOfRows : ((firstItemNumber + pageSize) - 1)) | 0;
    const pageButton = (buttonPageNumber) => {
        let arg10, arg10_1;
        return react.createElement("button", {
            type: "button",
            ["aria-label"]: (arg10 = ((buttonPageNumber + 1) | 0), toText(printf("%d"))(arg10)),
            onClick: (_arg1) => {
                gotoPage(buttonPageNumber);
            },
            disabled: buttonPageNumber === pageNumber,
            className: "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",
        }, react.createElement("span", {
            className: (buttonPageNumber === pageNumber) ? "font-bold text-indigo-600" : "",
        }, (arg10_1 = ((buttonPageNumber + 1) | 0), toText(printf("%d"))(arg10_1))));
    };
    const breaker = react.createElement("span", {
        className: "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700",
    }, "...");
    const firstHalfBreakPoint = 5;
    const secondHalfBreakPoint = (numberOfPages - 5) | 0;
    const buttons = (numberOfPages <= 10) ? Array.from(map(pageButton, rangeNumber(0, 1, numberOfPages - 1))) : Array.from((source2_3 = append((pageNumber < firstHalfBreakPoint) ? empty() : singleton(breaker), (source2_1 = append((pageNumber > secondHalfBreakPoint) ? empty() : singleton(breaker), map(pageButton, rangeNumber((pageNumber > secondHalfBreakPoint) ? secondHalfBreakPoint : (numberOfPages - 3), 1, numberOfPages - 1))), append(((pageNumber < firstHalfBreakPoint) ? true : (pageNumber > secondHalfBreakPoint)) ? [] : Array.from(map(pageButton, rangeNumber(pageNumber - 1, 1, pageNumber + 1))), source2_1))), append(map(pageButton, rangeNumber(0, 1, (pageNumber < firstHalfBreakPoint) ? firstHalfBreakPoint : 2)), source2_3)));
    return react.createElement("div", {
        className: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between",
    }, react.createElement("p", {
        className: "text-sm leading-5 text-gray-700",
    }, "Showing ", react.createElement("span", {
        className: "font-medium",
    }, toText(printf("%d"))(firstItemNumber)), " to ", react.createElement("span", {
        className: "font-medium",
    }, toText(printf("%d"))(lastItemNumber)), " of ", react.createElement("span", {
        className: "font-medium",
    }, toText(printf("%d"))(numberOfRows)), " results"), (numberOfPages > 1) ? react.createElement("nav", {
        className: "relative z-0 inline-flex shadow-sm",
    }, ...(source2_5 = append(buttons, [react.createElement("button", {
        type: "button",
        ["aria-label"]: "Next",
        disabled: pageNumber === (numberOfPages - 1),
        onClick: (_arg2) => {
            gotoPage(pageNumber + 1);
        },
        className: "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150",
    }, nextIcon(new IconSize(2)))]), append([react.createElement("button", {
        type: "button",
        ["aria-label"]: "Previous",
        disabled: pageNumber === 0,
        onClick: (_arg3) => {
            gotoPage(pageNumber - 1);
        },
        className: "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150",
    }, previousIcon(new IconSize(2)))], source2_5))) : react.createElement(react.Fragment, {}));
}

export function sortableGrid(onRowClick, onSort, columns, currentSortColumn, rows, pageNumber, numberOfPages, numberOfRows, pageSize, gotoPage) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200",
    }, react.createElement("table", {
        className: "min-w-full",
    }, react.createElement("thead", {}, react.createElement("tr", {}, ...map((tupledArg_1) => {
        const sortColumn = tupledArg_1[2];
        return react.createElement("th", {
            onClick: (_arg1) => {
                onSort(sortColumn);
            },
            className: "cursor-pointer px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (equals(currentSortColumn, sortColumn) ? "font-extrabold text-indigo-600" : "text-gray-500"),
        }, tupledArg_1[0]);
    }, columns))), react.createElement("tbody", {}, ...mapIndexed((i, row) => {
        let baseClass, arg20;
        return react.createElement("tr", {
            onClick: (_arg5) => {
                if (onRowClick == null) {
                }
                else {
                    onRowClick(i);
                }
            },
            className: (baseClass = ((onRowClick == null) ? "" : "hover:bg-gray-300 cursor-pointer"), (arg20 = (isOdd(i) ? "bg-gray-50" : "bg-white"), toText(printf("%s %s"))(baseClass)(arg20))),
        }, ...map((tupledArg) => react.createElement("td", {
            className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
        }, tupledArg[1](row)), columns));
    }, rows)), react.createElement("tfoot", {}, react.createElement("tr", {}, react.createElement("td", {
        colSpan: length(columns),
    }, react.createElement("div", {
        className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6",
    }, pagination(pageNumber, numberOfPages, numberOfRows, pageSize, gotoPage)))))))));
}

export function scrollingSortableGrid(maxSize, onRowClick, onSort, columns, currentSortColumn, rows, pageNumber, numberOfPages, numberOfRows, pageSize, gotoPage) {
    return react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden border-b border-gray-200 overflow-y-auto",
        style: {
            maxHeight: maxSize,
        },
    }, react.createElement("table", {
        className: "w-full",
    }, react.createElement("thead", {
        className: "sticky top-0",
    }, react.createElement("tr", {
        className: "w-full",
    }, ...map((tupledArg_1) => {
        const sortColumn = tupledArg_1[2];
        return react.createElement("th", {
            onClick: (_arg1) => {
                onSort(sortColumn);
            },
            className: "sticky top-0 z-10 cursor-pointer px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider " + (equals(currentSortColumn, sortColumn) ? "font-extrabold text-indigo-600" : "text-gray-500"),
        }, tupledArg_1[0]);
    }, columns))), react.createElement("tbody", {
        className: "",
        style: {
            maxHeight: "50vh",
        },
    }, ...mapIndexed((i, row) => {
        let baseClass, arg20;
        return react.createElement("tr", {
            onClick: (_arg5) => {
                if (onRowClick == null) {
                }
                else {
                    onRowClick(i);
                }
            },
            className: (baseClass = ((onRowClick == null) ? "" : "hover:bg-gray-300 cursor-pointer"), (arg20 = (isOdd(i) ? "bg-gray-50" : "bg-white"), toText(printf("w-full %s %s"))(baseClass)(arg20))),
        }, ...map((tupledArg) => react.createElement("td", {
            className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
        }, tupledArg[1](row)), columns));
    }, rows)), react.createElement("tfoot", {
        className: "",
    }, react.createElement("tr", {}, react.createElement("td", {
        className: "sticky bottom-0",
        colSpan: length(columns),
    }, react.createElement("div", {
        className: "bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6",
    }, pagination(pageNumber, numberOfPages, numberOfRows, pageSize, gotoPage)))))))));
}

