import { Record } from "../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../Context.fs.js";
import { CardioSummary__get_CalculatedTitle, LapType, Metric$reflection, PeakPerformanceType$reflection, CardioSummary$reflection, PeakPerformance$reflection } from "../../../shared/Shared.Models.fs.js";
import { record_type, int32_type, option_type, lambda_type, class_type, bool_type, array_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { head, tryFind, mapIndexed, append, sortWith, tryHead, min as min_3, collect, max as max_3, filter, map, isEmpty } from "../.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { uncurry, curry, comparePrimitives, equalsSafe } from "../.fable/fable-library.3.0.0/Util.js";
import { addSeconds, toShortTimeString, toShortDateString, subtract, compare } from "../.fable/fable-library.3.0.0/Date.js";
import { totalSeconds } from "../.fable/fable-library.3.0.0/TimeSpan.js";
import { line, ITimeGraphProps, TimeGraphAxis, TimeGraphColor, TimeGraphDataPoint, TimeGraphTick } from "../TimeGraph.fs.js";
import { singleton, empty } from "../.fable/fable-library.3.0.0/List.js";
import { Series_Time_Z1F763A16 } from "../Graph.fs.js";
import { some, bind } from "../.fable/fable-library.3.0.0/Option.js";
import { metricValueAsString, metricTypeAsUnits, metricTypeAsLabel, peakTypeAsUnits, peakTypeAsLabel, seriesColors } from "./Types.fs.js";
import { GraphValueTransformer } from "../GraphShared.fs.js";
import { Id_value } from "../../../shared/Shared.Types.fs.js";
import { printf, toText } from "../.fable/fable-library.3.0.0/String.js";
import { PopoverButton, popoverButtonBar } from "../Buttons.fs.js";
import { addToComparisonCartIcon, IconSize, gotoIcon } from "../Icons.fs.js";
import { CardioActivityPage, Page, modifyLocation } from "../Router.fs.js";
import { warningPanel } from "../Notifications.fs.js";

export class IRenderHistoryProps extends Record {
    constructor(Context, PeakPerformances, Summaries, PeakType, RenderTable, Height, IsConvertedToWattsKg, Metrics) {
        super();
        this.Context = Context;
        this.PeakPerformances = PeakPerformances;
        this.Summaries = Summaries;
        this.PeakType = PeakType;
        this.RenderTable = RenderTable;
        this.Height = (Height | 0);
        this.IsConvertedToWattsKg = IsConvertedToWattsKg;
        this.Metrics = Metrics;
    }
}

export function IRenderHistoryProps$reflection() {
    return record_type("Analysis.RenderHistory.IRenderHistoryProps", [], IRenderHistoryProps, () => [["Context", Context$reflection()], ["PeakPerformances", array_type(array_type(PeakPerformance$reflection()))], ["Summaries", array_type(CardioSummary$reflection())], ["PeakType", PeakPerformanceType$reflection()], ["RenderTable", option_type(lambda_type(array_type(array_type(PeakPerformance$reflection())), lambda_type(PeakPerformanceType$reflection(), lambda_type(array_type(CardioSummary$reflection()), lambda_type(bool_type, class_type("Fable.React.ReactElement"))))))], ["Height", int32_type], ["IsConvertedToWattsKg", bool_type], ["Metrics", option_type(array_type(Metric$reflection()))]]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    let source2, matchValue_1, metrics, top, projection, tail, projection_1, projection_2;
    const peakPerformances = props.PeakPerformances;
    const context = props.Context;
    const summaries = props.Summaries;
    const peakType = props.PeakType;
    if (isEmpty(peakPerformances)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const peaksForLapAndPeakType = Array.from(map((routePeaks) => Array.from(filter((p) => {
            if (equalsSafe(p.PeakType, peakType)) {
                return equalsSafe(p.Lap, new LapType(0));
            }
            else {
                return false;
            }
        }, routePeaks)), peakPerformances));
        const latestTime = max_3(map((dp) => dp.StartDateTimeUtc, collect((x) => x, peaksForLapAndPeakType)), {
            Compare: compare,
        });
        const earliestTime = min_3(map((dp_1) => dp_1.StartDateTimeUtc, collect((x_2) => x_2, peaksForLapAndPeakType)), {
            Compare: compare,
        });
        const ticks = Array.from(map((t) => {
            let copyOfStruct;
            return new TimeGraphTick(~(~(copyOfStruct = subtract(t, earliestTime), totalSeconds(copyOfStruct))), toShortDateString(t));
        }, [earliestTime, latestTime]));
        const graphProps = new ITimeGraphProps(Array.from((source2 = (matchValue_1 = props.Metrics, (matchValue_1 == null) ? empty() : (metrics = matchValue_1, isEmpty(metrics) ? empty() : (top = tryHead((projection = ((m_1) => m_1.RecordedAtUtc), sortWith((x_4, y_2) => (-compare(projection(x_4), projection(y_2))), filter((m) => (compare(m.RecordedAtUtc, earliestTime) < 0), metrics)))), (tail = tryHead((projection_1 = ((m_3) => m_3.RecordedAtUtc), sortWith((x_5, y_3) => compare(projection_1(x_5), projection_1(y_3)), filter((m_2) => (compare(m_2.RecordedAtUtc, latestTime) > 0), metrics)))), singleton(Series_Time_Z1F763A16(Array.from((projection_2 = ((dp_2) => dp_2.TimeInSeconds), sortWith((x_6, y_4) => comparePrimitives(projection_2(x_6), projection_2(y_4)), map((m_5) => {
            let copyOfStruct_1;
            return new TimeGraphDataPoint(m_5.RecordedValue, ~(~(copyOfStruct_1 = subtract(m_5.RecordedAtUtc, earliestTime), totalSeconds(copyOfStruct_1))), true);
        }, filter((m_4) => {
            if (compare(m_4.RecordedAtUtc, earliestTime) >= 0) {
                return compare(m_4.RecordedAtUtc, latestTime) <= 0;
            }
            else {
                return false;
            }
        }, metrics))))), new TimeGraphColor(0, "#A0AEC0"), void 0, new TimeGraphAxis(1), new Int32Array([4, 2]), some(bind((t_1) => {
            let copyOfStruct_2;
            return new TimeGraphDataPoint(t_1.RecordedValue, ~(~(copyOfStruct_2 = subtract(t_1.RecordedAtUtc, earliestTime), totalSeconds(copyOfStruct_2))), false);
        }, top)), some(bind((t_2) => {
            let copyOfStruct_3;
            return new TimeGraphDataPoint(t_2.RecordedValue, ~(~(copyOfStruct_3 = subtract(t_2.RecordedAtUtc, earliestTime), totalSeconds(copyOfStruct_3))), false);
        }, tail)))))))), append(mapIndexed((index, seriesSource) => {
            let projection_3;
            return Series_Time_Z1F763A16(Array.from((projection_3 = ((dp_3) => dp_3.TimeInSeconds), sortWith((x_7, y_5) => comparePrimitives(projection_3(x_7), projection_3(y_5)), map((peak) => {
                let copyOfStruct_4;
                return new TimeGraphDataPoint(peak.PeakValue, ~(~(copyOfStruct_4 = subtract(peak.StartDateTimeUtc, earliestTime), totalSeconds(copyOfStruct_4))), true);
            }, seriesSource)))), new TimeGraphColor(0, seriesColors[index % seriesColors.length]));
        }, peaksForLapAndPeakType), source2))), ticks, bind((metrics_2) => bind((metric) => {
            const matchValue_6 = metric.MetricType;
            switch (matchValue_6.tag) {
                case 1:
                case 4: {
                    return (tupledArg) => [Math.floor(tupledArg[0] / 10) * 10, Math.ceil(tupledArg[1] / 10) * 10];
                }
                case 2:
                case 3: {
                    return (tupledArg_1) => [Math.floor(tupledArg_1[0] / 100) * 100, Math.ceil(tupledArg_1[1] / 100) * 100];
                }
                default: {
                    return (tupledArg) => [Math.floor(tupledArg[0] / 10) * 10, Math.ceil(tupledArg[1] / 10) * 10];
                }
            }
        }, tryHead(metrics_2)), props.Metrics), (tupledArg_2) => {
            const max_2 = tupledArg_2[1];
            const matchValue_7 = [peakType, props.IsConvertedToWattsKg];
            let pattern_matching_result;
            if (matchValue_7[0].tag === 1) {
                if (matchValue_7[1]) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else if (matchValue_7[0].tag === 3) {
                pattern_matching_result = 2;
            }
            else if (matchValue_7[0].tag === 4) {
                pattern_matching_result = 2;
            }
            else if (matchValue_7[0].tag === 0) {
                pattern_matching_result = 3;
            }
            else {
                pattern_matching_result = 0;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [0, Math.ceil(max_2)];
                }
                case 1: {
                    return [0, Math.ceil(max_2)];
                }
                case 2: {
                    return [0, Math.ceil(max_2 / 10) * 10];
                }
                case 3: {
                    return [Math.floor((tupledArg_2[0] - 5) / 5) * 5, Math.ceil(max_2 / 5) * 5];
                }
            }
        }, void 0, true, true, false, "#718096", "#CBD5E0", 0, false, void 0, new GraphValueTransformer(0), (series_1, seriesIndex_1, dataPoint_1) => {
            let arg20_2, arg20, arg20_1, arg10_1, arg20_3, arg10_3, arg20_4, arg10_4;
            if (seriesIndex_1 < peakPerformances.length) {
                const peak_2 = tryFind((p_3) => {
                    if (p_3.PeakValue === dataPoint_1.Value) {
                        return equalsSafe(p_3.PeakType, peakType);
                    }
                    else {
                        return false;
                    }
                }, peakPerformances[seriesIndex_1]);
                let summary_1;
                if (peak_2 == null) {
                    summary_1 = (void 0);
                }
                else {
                    const p_4 = peak_2;
                    summary_1 = tryFind((s_3) => (s_3.Id === Id_value(p_4.CardioActivitySummaryId)), summaries);
                }
                if (summary_1 == null) {
                    return [[peakTypeAsLabel(peakType), (arg20_2 = peakTypeAsUnits(peakType, context), toText(printf("%.0f %s"))(dataPoint_1.Value)(arg20_2))]];
                }
                else {
                    const s_4 = summary_1;
                    return [[peakTypeAsLabel(peakType), (arg20 = peakTypeAsUnits(peakType, context), toText(printf("%.0f %s"))(dataPoint_1.Value)(arg20))], ["Route", CardioSummary__get_CalculatedTitle(s_4)], ["Date", (arg20_1 = toShortTimeString(s_4.Metrics.RecordedAtUtc), (arg10_1 = toShortDateString(s_4.Metrics.RecordedAtUtc), toText(printf("%s %s"))(arg10_1)(arg20_1)))]];
                }
            }
            else {
                const matchValue_5 = props.Metrics;
                if (matchValue_5 == null) {
                    return new Array(0);
                }
                else {
                    const metricType = head(matchValue_5).MetricType;
                    const dateTime = addSeconds(earliestTime, dataPoint_1.TimeInSeconds);
                    return [[metricTypeAsLabel(metricType), (arg20_3 = metricTypeAsUnits(context, metricType), (arg10_3 = metricValueAsString(context, metricType)(dataPoint_1.Value), toText(printf("%s %s"))(arg10_3)(arg20_3)))], ["Date", (arg20_4 = toShortTimeString(dateTime), (arg10_4 = toShortDateString(dateTime), toText(printf("%s %s"))(arg10_4)(arg20_4)))]];
                }
            }
        }, (color, seriesIndex, dataPoint) => {
            if (seriesIndex < peakPerformances.length) {
                const peak_1 = tryFind((p_1) => {
                    if (p_1.PeakValue === dataPoint.Value) {
                        return equalsSafe(p_1.PeakType, peakType);
                    }
                    else {
                        return false;
                    }
                }, peakPerformances[seriesIndex]);
                let summary;
                if (peak_1 == null) {
                    summary = (void 0);
                }
                else {
                    const p_2 = peak_1;
                    summary = tryFind((s) => (s.Id === Id_value(p_2.CardioActivitySummaryId)), summaries);
                }
                return react.createElement("div", {}, popoverButtonBar(new PopoverButton(react.createElement("span", {
                    className: "flex justify-center text-gray-500",
                }, gotoIcon(new IconSize(4))), () => {
                    if (summary == null) {
                    }
                    else {
                        modifyLocation(new Page(8, new CardioActivityPage(1, summary.Id)));
                    }
                }, summary == null), new PopoverButton(react.createElement("span", {
                    className: "flex justify-center text-gray-500",
                }, addToComparisonCartIcon(new IconSize(4))), () => {
                    if (summary == null) {
                    }
                    else {
                        context.CartManager.addItem(summary);
                    }
                }, summary == null)));
            }
            else {
                return react.createElement(react.Fragment, {});
            }
        }, void 0);
        let peakTable;
        const matchValue_8 = props.RenderTable;
        peakTable = ((curry(4, matchValue_8) == null) ? react.createElement(react.Fragment, {}) : matchValue_8(peaksForLapAndPeakType, peakType, props.Summaries, props.IsConvertedToWattsKg));
        let noMetricsWarning;
        const matchValue_9 = props.Metrics;
        noMetricsWarning = ((matchValue_9 == null) ? react.createElement(react.Fragment, {}) : (isEmpty(matchValue_9) ? react.createElement("div", {
            className: "mt-6",
        }, warningPanel("No metrics available", "There are no metrics available for the selected comparison type")) : react.createElement(react.Fragment, {})));
        return react.createElement("div", {}, react.createElement("div", {
            style: {
                height: props.Height,
            },
        }, line(graphProps)), noMetricsWarning, react.createElement("div", {
            className: "mt-6",
        }, peakTable));
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/RenderHistory.fs", 21);

