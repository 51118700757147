import { Record, Union } from "./.fable/fable-library.3.0.0/Types.js";
import { CardioActivityPage, modifyLocation, href as href_2, AnalysisPage, MetricsPage_FromDefaultList, PowerProgrammePage_FromIndexDefault, WeeklyTrainingLogPage_FromToday, WeeklyTrainingLogPage_FromDefaultList, HelpPage, HeartRateZonesPage, PowerZonesPage, DashboardPage, Page, Page$reflection } from "./Router.fs.js";
import { record_type, list_type, lambda_type, unit_type, union_type, class_type, bool_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import * as react from "react";
import { singleton as singleton_1, ofSeq, item as item_1, empty, ofArray } from "./.fable/fable-library.3.0.0/List.js";
import { transparentOverlay } from "./Layout.fs.js";
import { singleton, isEmpty, delay, append, length, map } from "./.fable/fable-library.3.0.0/Seq.js";
import { emailAddress, avatarUrl, logout } from "./Auth.fs.js";
import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { HTMLAttr, DOMAttr } from "./.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { dropDownGrid } from "./Grids.fs.js";
import { Id_value } from "../../shared/Shared.Types.fs.js";
import { flatTopPrimary } from "./Buttons.fs.js";
import { comparisonCartIcon, userIcon, removeIcon, IconSize, iconForCardioType } from "./Icons.fs.js";
import { toShortDateString } from "./.fable/fable-library.3.0.0/Date.js";
import { Msg, Msg$reflection } from "./AppTypes.fs.js";
import { CartItem$reflection } from "./ComparisonCart/Manager.fs.js";
import { Context$reflection } from "./Context.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { uncurry, equalsSafe } from "./.fable/fable-library.3.0.0/Util.js";
import { Msg as Msg_1 } from "./Modal/Manager.fs.js";
import { userIsInOnboardingQueue } from "./Server.fs.js";

export class NavBarLinkType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InternalNavBarLink", "ExternalNavBarLink"];
    }
}

export function NavBarLinkType$reflection() {
    return union_type("ClientApp.Navigation.NavBarLinkType", [], NavBarLinkType, () => [[["Item1", Page$reflection()], ["Item2", string_type], ["Item3", bool_type]], [["Item1", class_type("Fable.React.ReactElement")], ["Item2", class_type("Fable.React.ReactElement")]]]);
}

const dropdownCommunityLink = react.createElement("a", {
    href: "https://community.forcyclistsbycyclists.com",
    target: "_blank",
    className: "cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
}, "Community");

const responsiveCommunityLink = react.createElement("a", {
    href: "https://community.forcyclistsbycyclists.com",
    target: "_blank",
    className: "cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-red-800 focus:outline-none focus:text-white focus:bg-red-800",
}, "Community");

export function profileOptions(userIsInQueue) {
    let tupledArg, tupledArg_1, tupledArg_2, tupledArg_3, tupledArg_4, tupledArg_5, tupledArg_6;
    if (userIsInQueue) {
        return ofArray([(tupledArg = [dropdownCommunityLink, responsiveCommunityLink], new NavBarLinkType(1, tupledArg[0], tupledArg[1])), (tupledArg_1 = [new Page(9, new DashboardPage(0)), "Sign out", true], new NavBarLinkType(0, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2]))]);
    }
    else {
        return ofArray([(tupledArg_2 = [new Page(6, new PowerZonesPage(0)), "Power Zones", false], new NavBarLinkType(0, tupledArg_2[0], tupledArg_2[1], tupledArg_2[2])), (tupledArg_3 = [new Page(5, new HeartRateZonesPage(0)), "Heart Rate Zones", false], new NavBarLinkType(0, tupledArg_3[0], tupledArg_3[1], tupledArg_3[2])), (tupledArg_4 = [dropdownCommunityLink, responsiveCommunityLink], new NavBarLinkType(1, tupledArg_4[0], tupledArg_4[1])), (tupledArg_5 = [new Page(2, new HelpPage(0)), "Help", false], new NavBarLinkType(0, tupledArg_5[0], tupledArg_5[1], tupledArg_5[2])), (tupledArg_6 = [new Page(9, new DashboardPage(0)), "Sign out", true], new NavBarLinkType(0, tupledArg_6[0], tupledArg_6[1], tupledArg_6[2]))]);
    }
}

export function navigationLinks(userIsInQueue) {
    if (userIsInQueue) {
        return empty();
    }
    else {
        return ofArray([[new Page(9, new DashboardPage(0)), "Dashboard"], [new Page(7, WeeklyTrainingLogPage_FromDefaultList()), "Activities"], [new Page(7, WeeklyTrainingLogPage_FromToday()), "Log"], [new Page(0, PowerProgrammePage_FromIndexDefault()), "Training"], [new Page(1, MetricsPage_FromDefaultList()), "Metrics"], [new Page(4, new AnalysisPage(2)), "Analysis"]]);
    }
}

function buildProfileButtonMenu(userIsInQueue, hide) {
    return react.createElement("div", {}, transparentOverlay(() => {
        hide();
    }), react.createElement("div", {}, react.createElement("div", {
        className: "origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-1200",
    }, react.createElement("div", {
        className: "py-1 rounded-md bg-white shadow-xs",
    }, ...map((linkType) => {
        if (linkType.tag === 1) {
            return linkType.fields[0];
        }
        else {
            const content = linkType.fields[1];
            if (linkType.fields[2]) {
                return react.createElement("a", {
                    onClick: (_arg1) => {
                        logout();
                    },
                    className: "cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                }, content);
            }
            else {
                return react.createElement("a", keyValueList([new DOMAttr(40, (_arg2) => {
                    hide();
                }), href_2(linkType.fields[0]), new HTMLAttr(65, "cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100")], 1), content);
            }
        }
    }, profileOptions(userIsInQueue))))));
}

function buildCartDropdown(cartItems, hide, remove) {
    return react.createElement("div", {}, transparentOverlay(() => {
        hide();
    }), react.createElement("div", {}, dropDownGrid((index) => {
        hide();
        modifyLocation(new Page(8, new CardioActivityPage(1, Id_value(item_1(index, cartItems).ActivityId))));
    }, flatTopPrimary("Analyse", () => {
        modifyLocation(new Page(4, new AnalysisPage(0)));
        hide();
    }, length(cartItems) < 2), map((item) => {
        let matchValue;
        const source2 = ofArray([iconForCardioType(item.ActivityType)(new IconSize(4)), react.createElement("span", {
            className: "text-gray-500",
        }, (matchValue = item.Title, (matchValue == null) ? "" : matchValue)), react.createElement("span", {
            className: "text-gray-500",
        }, toShortDateString(item.RecordedAtUtc))]);
        return append([react.createElement("button", {
            className: "text-gray-500",
            onClick: (ev) => {
                ev.stopPropagation();
                remove(item);
                hide();
            },
        }, removeIcon(new IconSize(4)))], source2);
    }, cartItems))));
}

const appIcon = react.createElement("svg", {
    className: "h-8 w-8 text-white fill-current",
    viewBox: "0 0 32 32",
}, react.createElement("g", {
    transform: "matrix(0.121234,0,0,0.121234,30.9766,16.3629)",
}, react.createElement("path", {
    d: "M0,-5.987C-10.255,-23.743 -27.931,-34.602 -46.854,-36.895L-21.757,6.581L-21.777,6.581C-16.75,15.308 -15.403,25.463 -18.017,35.196C-20.611,44.929 -26.865,53.053 -35.572,58.1C-44.3,63.127 -54.455,64.474 -64.188,61.86C-73.92,59.246 -82.044,53.013 -87.091,44.285C-95.284,30.104 -93.139,12.776 -83.062,0.969L-65.311,31.72C-62.981,35.752 -58.757,38.007 -54.414,38.007C-52.283,38.007 -50.121,37.464 -48.142,36.322C-42.13,32.85 -40.07,25.164 -43.541,19.153L-61.314,-11.637C-61.313,-11.637 -61.313,-11.638 -61.312,-11.638L-65.447,-18.796L-101.772,-81.722C-106.316,-89.592 -114.452,-94.29 -123.538,-94.291L-123.542,-94.291C-132.626,-94.291 -140.764,-89.594 -145.309,-81.725L-179.265,-22.919L-185.786,-11.638C-185.784,-11.637 -185.782,-11.637 -185.779,-11.636L-203.558,19.151C-207.029,25.162 -204.969,32.849 -198.958,36.321C-192.947,39.79 -185.263,37.731 -181.791,31.721L-164.039,0.977C-153.978,12.785 -151.836,30.107 -160.026,44.285C-165.074,53.013 -173.198,59.246 -182.931,61.86C-192.643,64.474 -202.818,63.127 -211.525,58.1C-229.543,47.703 -235.737,24.598 -225.34,6.581L-200.204,-36.955C-203.14,-36.613 -206.056,-36.05 -208.952,-35.266C-225.159,-30.922 -238.713,-20.526 -247.098,-5.987C-264.432,24.035 -254.116,62.544 -224.093,79.858C-214.421,85.448 -203.663,88.304 -192.784,88.304C-187.334,88.304 -181.825,87.6 -176.416,86.132C-160.208,81.788 -146.654,71.392 -138.249,56.853C-123.084,30.576 -129.116,-2.224 -151.004,-21.597L-123.542,-69.156L-96.092,-21.604C-118,-2.232 -124.033,30.573 -108.849,56.853C-100.464,71.392 -86.911,81.788 -70.703,86.132C-65.273,87.6 -59.784,88.304 -54.334,88.304C-43.435,88.304 -32.697,85.448 -23.004,79.858C-8.486,71.472 1.931,57.919 6.274,41.711C10.618,25.483 8.386,8.552 0,-5.987",
})));

const burgerMenuIcon = react.createElement("svg", {
    className: "h-6 w-6",
    stroke: "currentColor",
    fill: "none",
    viewBox: "0 0 24 24",
}, react.createElement("path", {
    [":class"]: "{\u0027hidden\u0027: open, \u0027inline-flex\u0027: !open }",
    className: "inline-flex",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M4 6h16M4 12h16M4 18h16",
}), react.createElement("path", {
    [":class"]: "{\u0027hidden\u0027: !open, \u0027inline-flex\u0027: open }",
    className: "hidden",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M6 18L18 6M6 6l12 12",
}));

export class NavBarProps extends Record {
    constructor(IsShowingMenu, Dispatch, CurrentPage, ComparisonCart, Context, UserIsInQueue) {
        super();
        this.IsShowingMenu = IsShowingMenu;
        this.Dispatch = Dispatch;
        this.CurrentPage = CurrentPage;
        this.ComparisonCart = ComparisonCart;
        this.Context = Context;
        this.UserIsInQueue = UserIsInQueue;
    }
}

export function NavBarProps$reflection() {
    return record_type("ClientApp.Navigation.NavBarProps", [], NavBarProps, () => [["IsShowingMenu", bool_type], ["Dispatch", lambda_type(Msg$reflection(), unit_type)], ["CurrentPage", Page$reflection()], ["ComparisonCart", list_type(CartItem$reflection())], ["Context", Context$reflection()], ["UserIsInQueue", bool_type]]);
}

export const navBarImpl = FunctionComponent_Of_Z5A158BBF((props) => {
    const isShowingResponsiveMenu = react.useState(false);
    const isShowingMenu = react.useState(false);
    const isShowingCart = react.useState(false);
    const userIsInQueue = props.UserIsInQueue;
    const link = "ml-4 px-3 py-2 rounded-md text-sm font-medium text-white hover:text-white hover:bg-red-800 focus:outline-none focus:text-white focus:bg-red-800";
    let avatar;
    const matchValue = avatarUrl();
    if (matchValue == null) {
        avatar = react.createElement("span", {
            className: "text-white",
        }, userIcon(new IconSize(3)));
    }
    else {
        const url = matchValue;
        avatar = react.createElement("img", {
            className: "h-8 w-8 rounded-full",
            src: url,
            alt: "",
        });
    }
    const cartButton = react.createElement("div", {}, ...ofSeq(delay(() => (isEmpty(props.ComparisonCart) ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement(react.Fragment, {}, ...ofSeq(delay(() => append(singleton(react.createElement("button", {
        onClick: (_arg1) => {
            isShowingCart[1](true);
        },
        className: "p-1 border-2 border-transparent text-white rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-800",
    }, comparisonCartIcon(new IconSize(3)))), delay(() => ((isShowingCart[0]) ? singleton(buildCartDropdown(props.ComparisonCart, () => {
        isShowingCart[1](false);
    }, props.Context.CartManager.removeItem)) : singleton(react.createElement(react.Fragment, {})))))))))))));
    let profileButton;
    const source2 = (isShowingMenu[0]) ? singleton_1(buildProfileButtonMenu(userIsInQueue, () => {
        isShowingMenu[1](false);
    })) : empty();
    profileButton = append([react.createElement("button", {
        onClick: (_arg2) => {
            isShowingMenu[1](true);
        },
        className: "max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid",
    }, avatar)], source2);
    return react.createElement("div", {}, react.createElement("nav", {
        className: "bg-red-600",
    }, react.createElement("div", {
        className: "mx-auto px-4 sm:px-6 lg:px-8",
    }, react.createElement("div", {
        className: "flex items-center justify-between h-16",
    }, react.createElement("div", {
        className: "flex items-center",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, appIcon), react.createElement("div", {
        className: "hidden md:block",
    }, react.createElement("div", {
        className: "ml-10 flex items-baseline",
    }, ...ofSeq(append(map((tupledArg) => {
        const href = tupledArg[0];
        const className = equalsSafe(props.CurrentPage, href) ? link : link;
        return react.createElement("a", keyValueList([href_2(href), new HTMLAttr(65, className)], 1), tupledArg[1]);
    }, navigationLinks(userIsInQueue)), []))))), react.createElement("div", {
        className: "hidden md:block",
    }, react.createElement("div", {
        className: "ml-4 flex items-center md:ml-6",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "ml-3 relative",
    }, cartButton)), delay(() => append(userIsInQueue ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("button", {
        onClick: (_arg3) => {
            props.Dispatch(new Msg(15, new Msg_1(0)));
        },
        className: "p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-800",
    }, react.createElement("svg", {
        width: 20,
        height: 20,
        viewbox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
    }, react.createElement("path", {
        ["fill-rule"]: "evenodd",
        ["clip-rule"]: "evenodd",
        d: "M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z",
        fill: "#ffffff",
    })))), delay(() => singleton(react.createElement("div", {
        className: "ml-3 relative",
    }, ...profileButton)))))))))), react.createElement("div", {
        className: "-mr-2 flex md:hidden",
    }, react.createElement("button", {
        onClick: (_arg4) => {
            isShowingResponsiveMenu[1]((!(isShowingResponsiveMenu[0])));
        },
        className: "inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-red-800 focus:outline-none focus:bg-red-800 focus:text-white",
    }, burgerMenuIcon)))), react.createElement("div", {
        className: "md:hidden" + ((isShowingResponsiveMenu[0]) ? "" : " hidden"),
    }, react.createElement("div", {
        className: "px-2 pt-2 pb-3 sm:px-3",
    }, ...map((tupledArg_1) => react.createElement("a", keyValueList([href_2(tupledArg_1[0]), new HTMLAttr(65, "block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:text-white focus:bg-red-800")], 1), tupledArg_1[1]), navigationLinks(userIsInQueue))), react.createElement("div", {
        className: "pt-4 pb-3 border-t border-white",
    }, react.createElement("div", {
        className: "flex items-center px-5",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, avatar), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("div", {
        className: "mt-1 text-sm font-medium leading-none text-white",
    }, emailAddress()))), react.createElement("div", {
        className: "mt-3 px-2",
    }, ...map((linkDefinition) => {
        if (linkDefinition.tag === 1) {
            return linkDefinition.fields[1];
        }
        else {
            const content_2 = linkDefinition.fields[1];
            if (linkDefinition.fields[2]) {
                return react.createElement("a", {
                    onClick: (_arg5) => {
                        logout();
                    },
                    className: "cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-red-800 focus:outline-none focus:text-white focus:bg-red-800",
                }, content_2);
            }
            else {
                return react.createElement("a", {
                    href: "#",
                    className: "cursor-pointer block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-red-800 focus:outline-none focus:text-white focus:bg-red-800",
                }, content_2);
            }
        }
    }, profileOptions(userIsInQueue)))))));
}, void 0, uncurry(2, void 0), void 0, "navBarImpl", "/home/runner/work/strengthPlus/strengthPlus/client/src/NavBar.fs", 174);

export function navBar(context, model, dispatch) {
    return navBarImpl(new NavBarProps(model.IsNavigationDropdownOpen, dispatch, model.CurrentPage, model.ComparisonCart, context, userIsInOnboardingQueue()));
}

