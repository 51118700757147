import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { string_type, union_type, record_type, float64_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import * as react from "react";
import { empty, append as append_1, ofArray, singleton as singleton_1, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { sumBy, sortWith, isEmpty, mapIndexed, fold, skip, max as max_1, map, min as min_1, singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { CSSProp, HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { uncurry, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { link, subtleLabel, italicLabel, label as label_1, dashboardTitle } from "../../Typography.fs.js";
import { Statistic, embeddedView } from "../../StatBar.fs.js";
import { roundedDistance, Peaks_filterToKeyDurations, calories, distanceUnits, trainingStress, durationFromSeconds, weightUnits, weight, distance, heartRateUnits, heartRate as heartRate_1, elapsedTimeFromSeconds } from "../../Units.fs.js";
import { iconForCardioType, strengthIcon, liftedWeightIcon, cyclingIcon, walkingIcon, swimmingIcon, runningIcon, distanceIcon, heartBeatIcon, IconSize, timerIcon } from "../../Icons.fs.js";
import { AnalysisPage, WeeklyTrainingLogPage_FromDefaultList, HeartRateZonesPage, PowerZonesPage, PowerProgrammePage, CardioActivityPage, Page, modifyLocation } from "../../Router.fs.js";
import { Programme__get_Length, ProgrammeId__AsString } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { visualTimeline } from "../../PowerProgramme/Common.fs.js";
import { plain } from "../../Buttons.fs.js";
import { Msg } from "./Types.fs.js";
import { page, spacedPanel, panel, noHorizontalPadPanel } from "../../Layout.fs.js";
import { selectableSummary } from "../../Grids.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { toString, compare, toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { heartRateConesTable, zonesTable, Peaks_peakHeartRateGraph, Peaks_peakPowerGraph } from "../../CommonRendering.fs.js";
import { bar, IBarGraphProps, XAxisConfiguration, GraphAnnotation, GraphColor, GraphDataPoint as GraphDataPoint_1 } from "../../CartesianGraph.fs.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { ITrainingLoadGraphLegendProps, legend, ITrainingLoadGraphProps, view } from "../../TrainingLoadGraph.fs.js";
import { chooser } from "../../PowerProgramme/Grid/View.fs.js";
import { warningPanelWithElement } from "../../Notifications.fs.js";
import { MetricType } from "../../../../shared/Shared.Models.fs.js";
import { userIsInOnboardingQueue } from "../../Server.fs.js";

export class PercentageGraphValue$1 extends Record {
    constructor(Value, Percentage) {
        super();
        this.Value = Value;
        this.Percentage = Percentage;
    }
}

export function PercentageGraphValue$1$reflection(gen0) {
    return record_type("Dashboard.Index.View.PercentageGraphValue`1", [gen0], PercentageGraphValue$1, () => [["Value", gen0], ["Percentage", float64_type]]);
}

export class Trend$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Worse", "Same", "Better"];
    }
}

export function Trend$1$reflection(gen0) {
    return union_type("Dashboard.Index.View.Trend`1", [gen0], Trend$1, () => [[["Item", PercentageGraphValue$1$reflection(gen0)]], [["Item", PercentageGraphValue$1$reflection(gen0)]], [["Item", PercentageGraphValue$1$reflection(gen0)]]]);
}

export function Trend$1__get_Value(x) {
    switch (x.tag) {
        case 1: {
            return x.fields[0].Value;
        }
        case 2: {
            return x.fields[0].Value;
        }
        default: {
            return x.fields[0].Value;
        }
    }
}

export class GraphDataPoint extends Record {
    constructor(label, value) {
        super();
        this.label = label;
        this.value = value;
    }
}

export function GraphDataPoint$reflection() {
    return record_type("Dashboard.Index.View.GraphDataPoint", [], GraphDataPoint, () => [["label", string_type], ["value", Trend$1$reflection(float64_type)]]);
}

export const graphValueLabelSize = "0.66em";

function graphBarOld(index, dataPoint) {
    const calcHeight = (value) => {
        const arg10 = (~(~value.Percentage)) | 0;
        return toText(printf("%d%%"))(arg10);
    };
    return react.createElement("div", {
        className: "flex-grow flex flex-col justify-end h-full" + ((index === 0) ? "" : " ml-1"),
    }, ...ofSeq(delay(() => {
        let arg10_1;
        return append(singleton(react.createElement("div", {
            className: "text-center text-xs",
            style: {
                fontSize: "0.66em",
            },
        }, (arg10_1 = Trend$1__get_Value(dataPoint.value), toText(printf("%.1f"))(arg10_1)))), delay(() => {
            let css_2, css_3, css_1;
            const matchValue_1 = dataPoint.value;
            switch (matchValue_1.tag) {
                case 1: {
                    return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-blue-600 rounded rounded-b-none"), (css_2 = singleton_1(new CSSProp(189, calcHeight(matchValue_1.fields[0]))), ["style", keyValueList(css_2, 1)])], 1)));
                }
                case 2: {
                    return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-green-600 rounded rounded-b-none"), (css_3 = singleton_1(new CSSProp(189, calcHeight(matchValue_1.fields[0]))), ["style", keyValueList(css_3, 1)])], 1)));
                }
                default: {
                    return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-red-600 rounded rounded-b-none"), (css_1 = singleton_1(new CSSProp(189, calcHeight(matchValue_1.fields[0]))), ["style", keyValueList(css_1, 1)])], 1)));
                }
            }
        }));
    })));
}

function graphBar(index, dataPoint) {
    const label = (value) => react.createElement("div", {
        className: "text-center text-xs",
        style: {
            position: "relative",
            top: "-1.25em",
            fontSize: graphValueLabelSize,
        },
    }, toText(printf("%.1f"))(value));
    const calcHeight = (value_1) => {
        const arg10_1 = (~(~value_1.Percentage)) | 0;
        return toText(printf("%d%%"))(arg10_1);
    };
    return react.createElement("div", {
        className: "flex-grow flex flex-col justify-end h-full" + ((index === 0) ? "" : " ml-1"),
    }, ...ofSeq(delay(() => {
        let css_2, css_3, css_1;
        const matchValue_1 = dataPoint.value;
        switch (matchValue_1.tag) {
            case 1: {
                const value_4 = matchValue_1.fields[0];
                return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-blue-600 rounded rounded-b-none"), (css_2 = ofArray([new CSSProp(261, 0.8), new CSSProp(189, calcHeight(value_4))]), ["style", keyValueList(css_2, 1)])], 1), label(value_4.Value)));
            }
            case 2: {
                const value_5 = matchValue_1.fields[0];
                return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-green-600 rounded rounded-b-none"), (css_3 = ofArray([new CSSProp(261, 0.8), new CSSProp(189, calcHeight(value_5))]), ["style", keyValueList(css_3, 1)])], 1), label(value_5.Value)));
            }
            default: {
                const value_3 = matchValue_1.fields[0];
                return singleton(react.createElement("div", keyValueList([new HTMLAttr(65, "bg-red-600 rounded rounded-b-none"), (css_1 = ofArray([new CSSProp(261, 0.8), new CSSProp(189, calcHeight(value_3))]), ["style", keyValueList(css_1, 1)])], 1), label(value_3.Value)));
            }
        }
    })));
}

function dashboardButton(title, onClick) {
    return react.createElement("button", {
        className: "w-full bg-transparent focus:outline-none hover:bg-blue-100 active:bg-blue-200",
        onClick: (ev) => {
            onClick();
        },
    }, react.createElement("div", {
        className: "flex flex-col items-center content-center py-6",
    }, react.createElement("svg", {
        className: "fill-current text-gray-700 mb-4",
        xmlns: "http://www.w3.org/2000/svg",
        width: 48,
        height: 48,
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z",
    })), title));
}

function smallDashboardAddButton(onClick) {
    return react.createElement("button", {
        className: "bg-transparent focus:outline-none hover:bg-blue-100 active:bg-blue-200",
        onClick: (ev) => {
            onClick();
        },
    }, react.createElement("div", {
        className: "flex flex-col items-center content-center py-0 px-0",
    }, react.createElement("svg", {
        className: "fill-current text-gray-700",
        xmlns: "http://www.w3.org/2000/svg",
        width: 24,
        height: 24,
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M384 240v32c0 6.6-5.4 12-12 12h-88v88c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-88h-88c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h88v-88c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v88h88c6.6 0 12 5.4 12 12zm120 16c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z",
    }))));
}

function smallDashboardMoreButton(onClick) {
    return react.createElement("button", {
        className: "bg-transparent focus:outline-none hover:bg-blue-100 active:bg-blue-200",
        onClick: (ev) => {
            onClick();
        },
    }, react.createElement("div", {
        className: "flex flex-col items-center content-center py-0 px-0",
    }, react.createElement("svg", {
        className: "fill-current text-gray-700",
        xmlns: "http://www.w3.org/2000/svg",
        width: 24,
        height: 24,
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zm448 0c0 110.5-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56s200 89.5 200 200zM266.9 126.1l121.4 121.4c4.7 4.7 4.7 12.3 0 17L266.9 385.9c-4.7 4.7-12.3 4.7-17 0l-19.6-19.6c-4.8-4.8-4.7-12.5.2-17.2l70.3-67.1H140c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h160.8l-70.3-67.1c-4.9-4.7-5-12.4-.2-17.2l19.6-19.6c4.7-4.7 12.3-4.7 17 0z",
    }))));
}

export class MetricGraphDataPoint extends Record {
    constructor(Value, Label) {
        super();
        this.Value = Value;
        this.Label = Label;
    }
}

export function MetricGraphDataPoint$reflection() {
    return record_type("Dashboard.Index.View.MetricGraphDataPoint", [], MetricGraphDataPoint, () => [["Value", float64_type], ["Label", string_type]]);
}

function metricGraph(title, onClick, dataPoints) {
    const min = min_1(map((dp) => dp.Value, dataPoints), {
        Compare: comparePrimitives,
    });
    const max = max_1(map((dp_1) => dp_1.Value, dataPoints), {
        Compare: comparePrimitives,
    });
    const roundedMin = Math.floor((min - 1) / 5) * 5;
    const range = max - roundedMin;
    const trimmedData = Array.from(skip(1, fold(uncurry(2, (tupledArg) => {
        const previous = tupledArg[0];
        return (dp_2) => {
            let value_1;
            return [dp_2.Value, append_1(tupledArg[1], singleton_1(new GraphDataPoint(dp_2.Label, ((dp_2.Value > previous) ? ((arg0) => (new Trend$1(0, arg0))) : ((dp_2.Value < previous) ? ((arg0_1) => (new Trend$1(2, arg0_1))) : ((arg0_2) => (new Trend$1(1, arg0_2)))))((value_1 = dp_2.Value, new PercentageGraphValue$1(value_1, ((value_1 - roundedMin) / range) * 100))))))];
        };
    }), [0, empty()], dataPoints)[1]));
    return react.createElement("div", {}, react.createElement("div", {
        className: "flex flex-row justify-between align-center mb-4",
    }, dashboardTitle(title), smallDashboardAddButton(onClick)), react.createElement("div", {
        className: "flex flex-row justify-between pt-2",
        style: {
            height: 100,
        },
    }, react.createElement("div", {
        className: "block flex-grow flex flex-row items-end",
    }, ...mapIndexed(graphBar, trimmedData))), react.createElement("div", {
        className: "flex flex-row justify-between",
    }, react.createElement("span", {
        className: "text-xs italic",
    }, "7 weeks ago"), react.createElement("span", {
        className: "text-xs italic",
    }, "This week")));
}

export function todayStatRow(titleOption, icon, stats, onClick) {
    return react.createElement("div", {
        className: "hover:bg-gray-100 cursor-pointer",
        onClick: onClick,
    }, ...ofSeq(delay(() => append((titleOption == null) ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("div", {
        className: "pl-4 sm:pl-6",
    }, label_1(titleOption))), delay(() => singleton(react.createElement("div", {
        className: "flex flex-row justify-start",
    }, react.createElement("div", {
        className: "px-4 py-5 sm:p-6 border-gray-100 border-r",
    }, react.createElement("div", {
        className: "flex items-start",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, icon))), react.createElement("div", {
        className: "flex-grow",
    }, embeddedView(false, stats)))))))));
}

export function todayCardioStatRow(dispatch, context, cardio) {
    let matchValue;
    const elapsedTime = new Statistic("Time", "text-green-600", elapsedTimeFromSeconds(context, cardio.Metrics.TimeSeconds), "", timerIcon(new IconSize(0)));
    let heartRate;
    const Value_1 = heartRate_1(context, cardio.Metrics.AverageHeartRate);
    const Icon_1 = heartBeatIcon(new IconSize(0));
    heartRate = (new Statistic("Heart Rate", "text-red-600", Value_1, heartRateUnits(context), Icon_1));
    const totalDistance = new Statistic("Distance", "text-blue-600", distance(context, cardio.Metrics.DistanceMeters), "km", distanceIcon(new IconSize(0)));
    return todayStatRow(void 0, (matchValue = cardio.ActivityType, (matchValue.tag === 0) ? (runningIcon) : ((matchValue.tag === 2) ? (swimmingIcon) : ((matchValue.tag === 3) ? (walkingIcon) : ((matchValue.tag === 4) ? (cyclingIcon) : ((matchValue.tag === 5) ? (cyclingIcon) : (cyclingIcon))))))(new IconSize(0)), ofArray([elapsedTime, heartRate, totalDistance]), (_arg1) => {
        modifyLocation(new Page(8, new CardioActivityPage(1, cardio.Id)));
    });
}

export function todayStrengthStatRow(dispatch, context, strength) {
    const elapsedTime = new Statistic("Time", "text-green-600", elapsedTimeFromSeconds(context, strength.TimeSeconds), "", timerIcon(new IconSize(0)));
    let heartRate;
    const Value_1 = heartRate_1(context, strength.AverageHeartRate);
    const Icon_1 = heartBeatIcon(new IconSize(0));
    heartRate = (new Statistic("Heart Rate", "text-red-600", Value_1, heartRateUnits(context), Icon_1));
    let totalWeight;
    const Value_2 = weight(context, strength.TotalWeightLifted);
    const Icon_2 = liftedWeightIcon(new IconSize(0));
    totalWeight = (new Statistic("Weight Lifted", "text-gray-600", Value_2, weightUnits(context), Icon_2));
    return todayStatRow(void 0, strengthIcon(new IconSize(0)), ofArray([elapsedTime, heartRate, totalWeight]), (value) => {
        void value;
    });
}

export function todayPowerStatRow(dispatch, context, plan) {
    return react.createElement("div", {
        className: "hover:bg-gray-100 cursor-pointer px-6",
        onClick: (_arg1) => {
            modifyLocation(new Page(0, new PowerProgrammePage(1, ProgrammeId__AsString(plan.Id))));
        },
    }, react.createElement("div", {
        className: "flex flex-row justify-between",
    }, react.createElement("div", {}, label_1(plan.Name), italicLabel(" (planned)")), react.createElement("div", {}, react.createElement("span", {
        className: "mr-4",
    }, subtleLabel(durationFromSeconds(Programme__get_Length(plan)))), react.createElement("span", {
        className: "text-sm leading-5 font-medium truncate text-orange-500",
    }, trainingStress(context, plan.TrainingStress)))), visualTimeline({
        Blocks: plan.Blocks,
        Height: 75,
    }));
}

export function dashboardPanel(title, content) {
    return react.createElement("div", {
        className: "",
    }, react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block shadow sm:rounded-lg min-w-full overflow-hidden border-b border-gray-200 bg-white pt-4",
    }, react.createElement("div", {
        className: "px-6 pb-4",
    }, dashboardTitle(title)), content))));
}

export function renderToday(dispatch, context, events) {
    return react.createElement("div", {
        className: "",
    }, react.createElement("div", {}, ...ofSeq(map((ev) => {
        const matchValue = ev.Activity;
        switch (matchValue.tag) {
            case 2: {
                return todayStrengthStatRow(dispatch, context, matchValue.fields[0]);
            }
            case 1: {
                return todayPowerStatRow(dispatch, context, matchValue.fields[0]);
            }
            default: {
                return todayCardioStatRow(dispatch, context, matchValue.fields[0]);
            }
        }
    }, events))), react.createElement("div", {
        className: "px-6 mb-4",
    }, react.createElement("div", {
        className: "text-center mt-4",
    }, react.createElement("div", {
        className: "grid grid-cols-2 gap-4 mt-3",
    }, plain("Plan another workout", () => {
        dispatch(new Msg(4));
    }, false), plain("Upload another workout", () => {
        context.ModalManager.openUploadModal();
    }, false)))));
}

function setPowerZones() {
    modifyLocation(new Page(6, new PowerZonesPage(0)));
}

function setHeartRateZones() {
    modifyLocation(new Page(5, new HeartRateZonesPage(0)));
}

function upcomingWorkouts(dispatch) {
    return dashboardPanel("Upcoming", react.createElement("div", {
        className: "text-center mb-4",
    }, react.createElement("p", {}, "You have no upcoming workouts planned"), react.createElement("div", {
        className: "grid grid-cols-4 gap-6 mt-3",
    }, react.createElement("div", {}), react.createElement("div", {
        className: "col-span-2",
    }, react.createElement("div", {
        className: "flex flex-row",
    }, plain("Plan a workout", () => {
        dispatch(new Msg(4));
    }, false))), react.createElement("div", {}))));
}

function recentWorkouts(model, context) {
    return noHorizontalPadPanel(ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "px-6 py-4 flex flex-row justify-between align-center",
    }, dashboardTitle("Recent workouts"), smallDashboardMoreButton(() => {
        modifyLocation(new Page(7, WeeklyTrainingLogPage_FromDefaultList()));
    }))), delay(() => {
        let arg10, projection;
        return isEmpty(model.RecentWorkouts) ? singleton(react.createElement("div", {
            className: "px-6 mb-4",
        }, react.createElement("div", {
            className: "text-center mt-4",
        }, react.createElement("p", {}, "You have no recent workouts")))) : singleton(selectableSummary(true, true, true, (index) => {
            const workout = model.RecentWorkouts[index];
            if (workout.WorkoutType.tag === 0) {
                modifyLocation(new Page(8, new CardioActivityPage(1, Id_value(workout.Id))));
            }
            else {
                console.log(some("No view page for actvitiy type"));
            }
        }, ofArray(["Date", "Type", "Duration", (arg10 = distanceUnits(context), toText(printf("Distance (%s)"))(arg10)), "Calories", "Heart Rate"]), map((w_1) => {
            let matchValue_2, matchValue_3;
            return ofArray([toShortDateString(w_1.RecordedAtUtc), (matchValue_2 = w_1.WorkoutType, (matchValue_2.tag === 1) ? strengthIcon(new IconSize(2)) : ((matchValue_2.tag === 2) ? heartBeatIcon(new IconSize(2)) : iconForCardioType(matchValue_2.fields[0])(new IconSize(2)))), (matchValue_3 = w_1.TimeSeconds, (matchValue_3 == null) ? "N/A" : durationFromSeconds(matchValue_3)), (w_1.DistanceMeters == null) ? "N/A" : distance(context, w_1.DistanceMeters), (w_1.Calories == null) ? "N/A" : calories(context, w_1.Calories), (w_1.AverageHeartRate == null) ? "N/A" : heartRate_1(context, w_1.AverageHeartRate)]);
        }, (projection = ((w) => w.RecordedAtUtc), sortWith((x, y) => (-compare(projection(x), projection(y))), model.RecentWorkouts)))));
    })))));
}

export function recentPowerPeaks(model, context) {
    return panel([react.createElement("div", {
        className: "flex flex-row justify-between align-center",
    }, dashboardTitle("Recent power peaks (last 90 days)"), smallDashboardMoreButton(() => {
        modifyLocation(new Page(4, new AnalysisPage(1)));
    })), react.createElement("div", {
        className: "mt-4",
    }, Peaks_peakPowerGraph(Array.from(Peaks_filterToKeyDurations(model.RecentPowerPeaks)), model.UserPowerZones, void 0))]);
}

export function recentHeartPeaks(model, context) {
    return panel([react.createElement("div", {
        className: "flex flex-row justify-between align-center",
    }, dashboardTitle("Recent heart rate peaks (last 90 days)"), smallDashboardMoreButton(() => {
        modifyLocation(new Page(4, new AnalysisPage(1)));
    })), react.createElement("div", {
        className: "mt-4",
    }, Peaks_peakHeartRateGraph(model.HeartRateZones, ofSeq(Peaks_filterToKeyDurations(model.RecentHeartPeaks))))]);
}

export function recentDistances(model, context) {
    let arg10;
    if (isEmpty(model.RecentDistances) ? true : (sumBy((d) => d.Value, skip(1, model.RecentDistances), {
        GetZero: () => 0,
        Add: (x, y) => (x + y),
    }) === 0)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const graphProps = new IBarGraphProps(Array.from(map((p) => (new GraphDataPoint_1(p.Value, "")), skip(model.RecentDistances.length - 7, model.RecentDistances))), new GraphColor(1, (dpi, v) => {
            if ((dpi === 0) ? true : (v <= 0)) {
                return "#E53E3E";
            }
            else {
                const previousValue = model.RecentDistances[dpi].Value;
                if (previousValue > v) {
                    return "#E53E3E";
                }
                else if (previousValue < v) {
                    return "#38A169";
                }
                else {
                    return "#ED8936";
                }
            }
        }), "#718096", new GraphAnnotation(0), void 0, true, false, new XAxisConfiguration(1, "7 weeks ago", "This week"), new GraphValueTransformer(1, (v_1) => roundedDistance(context, v_1)));
        return spacedPanel([react.createElement("div", {
            className: "text-center",
        }, react.createElement("div", {
            className: "flex flex-row justify-between align-center",
        }, dashboardTitle((arg10 = distanceUnits(context), toText(printf("Recent weekly distances (%s)"))(arg10)))), react.createElement("div", {
            style: {
                width: "100%",
                height: 150,
            },
        }, bar(graphProps)))]);
    }
}

function trainingLoad(load, context) {
    return spacedPanel([react.createElement("div", {
        className: "flex flex-row justify-between align-center",
    }, dashboardTitle("Fitness (last 90 days)"), smallDashboardMoreButton(() => {
        modifyLocation(new Page(4, new AnalysisPage(8)));
    })), react.createElement("div", {
        className: "mt-4",
    }, view(new ITrainingLoadGraphProps(context, load, 250, 5, false, true, true, false)), legend(new ITrainingLoadGraphLegendProps(false, true, true, false)))]);
}

function dashboardView(context, model, dispatch, rootDispatch) {
    return react.createElement("div", {}, page("Your dashboard", model.IsLoading, [], ofSeq(delay(() => append(model.ShowWorkoutChooser ? singleton(chooser({
        Context: context,
        IsSaving: model.IsSaving,
        OnChosen: (programmeId) => {
            dispatch(new Msg(0, programmeId));
        },
        OnHide: () => {
            dispatch(new Msg(5));
        },
    })) : singleton(react.createElement(react.Fragment, {})), delay(() => {
        let matchValue_1;
        return append((matchValue_1 = [model.TrainsWithPower, model.UserPowerZones], matchValue_1[0] ? ((matchValue_1[1] == null) ? singleton(react.createElement("div", {
            className: "mb-6",
        }, warningPanelWithElement("Set your FTP", react.createElement("span", {}, "When you\u0027re training with a power meter you can gain more insights into your performance by ", link("setting your FTP and power zones", new Page(6, new PowerZonesPage(0))), ".")))) : singleton(react.createElement(react.Fragment, {}))) : singleton(react.createElement(react.Fragment, {}))), delay(() => {
            let matchValue_3;
            return singleton(react.createElement("div", {
                className: "grid grid-cols-12 md:gap-6",
            }, react.createElement("div", {
                className: "md:col-span-8 col-span-12",
            }, dashboardPanel("Today", isEmpty(model.Today) ? react.createElement("div", {
                className: "text-center mb-4",
            }, react.createElement("p", {}, "You have no workouts recorded or planned for today"), react.createElement("div", {
                className: "grid grid-cols-2 gap-4 mt-3 px-6",
            }, plain("Plan a workout", () => {
                dispatch(new Msg(4));
            }, false), plain("Upload a workout", () => {
                context.ModalManager.openUploadModal();
            }, false))) : renderToday(dispatch, context, model.Today)), (matchValue_3 = model.TrainingLoad, (matchValue_3 == null) ? react.createElement(react.Fragment, {}) : react.createElement("div", {
                className: "mt-6",
            }, trainingLoad(matchValue_3, context))), react.createElement("div", {
                className: "mt-8",
            }, recentWorkouts(model, context)), isEmpty(model.RecentPowerPeaks) ? react.createElement(react.Fragment, {}) : react.createElement("div", {
                className: "mt-8",
            }, recentPowerPeaks(model, context)), isEmpty(model.RecentHeartPeaks) ? react.createElement(react.Fragment, {}) : react.createElement("div", {
                className: "mt-6",
            }, recentHeartPeaks(model, context))), react.createElement("div", {
                className: "md:col-span-4 col-span-12",
            }, react.createElement("div", {
                className: "mt-6 md:mt-0",
            }, spacedPanel([react.createElement("div", {}, ...ofSeq(delay(() => (isEmpty(model.RecentWeight) ? singleton(dashboardButton("Add your first weight", () => {
                context.ModalManager.openRecordMetricModal(new MetricType(0), 75);
            })) : singleton(metricGraph("Recent weight (kg)", () => {
                context.ModalManager.openRecordMetricModal(new MetricType(0), 75);
            }, map((a) => (new MetricGraphDataPoint(a.Average, toString(a.WeekBeginning, "yyyy-MM-dd"))), model.AggregateWeight)))))))])), recentDistances(model, context), spacedPanel([react.createElement("div", {
                className: "text-center",
            }, ...ofSeq(delay(() => {
                const matchValue_7 = model.UserPowerZones;
                if (matchValue_7 != null) {
                    const zones = matchValue_7;
                    return singleton(react.createElement("div", {}, react.createElement("div", {
                        className: "flex flex-row justify-between align-center mb-4",
                    }, dashboardTitle("Power Zones"), smallDashboardAddButton(() => {
                        setPowerZones();
                    })), zonesTable(zones, true)));
                }
                else {
                    return singleton(react.createElement("div", {
                        className: "mt-4",
                    }, dashboardButton("Setup power zones", () => {
                        setPowerZones();
                    })));
                }
            })))]), spacedPanel([react.createElement("div", {
                className: "text-center",
            }, ...ofSeq(delay(() => {
                const matchValue_8 = model.HeartRateZones;
                if (matchValue_8 != null) {
                    const zones_1 = matchValue_8;
                    return singleton(react.createElement("div", {}, react.createElement("div", {
                        className: "flex flex-row justify-between align-center mb-4",
                    }, dashboardTitle("Heart Rate Zones"), smallDashboardAddButton(() => {
                        setHeartRateZones();
                    })), heartRateConesTable(zones_1, true)));
                }
                else {
                    return singleton(react.createElement("div", {
                        className: "mt-4",
                    }, dashboardButton("Setup heart rate zones", () => {
                        setHeartRateZones();
                    })));
                }
            })))]))));
        }));
    }))))));
}

const onboardingMessage = react.createElement("div", {
    className: "m-6",
}, panel([react.createElement("div", {
    className: "max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8",
}, react.createElement("h2", {
    className: "text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10",
}, "Thanks for signing up!", react.createElement("br", {})), react.createElement("p", {
    className: "mt-8",
}, "We\u0027re slowly opening up to more cyclists to ensure a quality experience for all. We\u0027ll be in touch soon to confirm your access."), react.createElement("p", {
    className: "mt-2",
}, "In the meantime, now you\u0027re signed up, you can engage with our community over on our Discourse forum."), react.createElement("div", {
    className: "mt-8 flex justify-center",
}, react.createElement("div", {
    className: "inline-flex rounded-md shadow",
}, react.createElement("a", {
    href: "https://community.forcyclistsbycyclists.com",
    className: "inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out",
}, "Join the conversation"))))]));

export function root(context, model, dispatch, rootDispatch) {
    if (userIsInOnboardingQueue()) {
        return onboardingMessage;
    }
    else {
        return dashboardView(context, model, dispatch, rootDispatch);
    }
}

