import { Sort$1$reflection, CardioActivitySortColumn$reflection, CardioActivitySortColumn, Sort$1 } from "../../../../shared/Shared.Api.fs.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Page$1$reflection, CardioSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { union_type, class_type, record_type, bool_type, int32_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export function sortFromIndexes(sortOrderIndex, sortColumnIndex) {
    return ((sortOrderIndex === 0) ? ((arg0) => (new Sort$1(0, arg0))) : ((arg0_1) => (new Sort$1(1, arg0_1))))((sortColumnIndex === 0) ? (new CardioActivitySortColumn(0)) : ((sortColumnIndex === 1) ? (new CardioActivitySortColumn(1)) : ((sortColumnIndex === 2) ? (new CardioActivitySortColumn(2)) : ((sortColumnIndex === 3) ? (new CardioActivitySortColumn(3)) : ((sortColumnIndex === 4) ? (new CardioActivitySortColumn(4)) : ((sortColumnIndex === 5) ? (new CardioActivitySortColumn(5)) : ((sortColumnIndex === 6) ? (new CardioActivitySortColumn(6)) : ((sortColumnIndex === 7) ? (new CardioActivitySortColumn(7)) : ((sortColumnIndex === 8) ? (new CardioActivitySortColumn(8)) : ((sortColumnIndex === 9) ? (new CardioActivitySortColumn(9)) : (new CardioActivitySortColumn(7))))))))))));
}

export function sortColumnFromType(sort) {
    const sortColumn = (column) => {
        switch (column.tag) {
            case 1: {
                return 1;
            }
            case 2: {
                return 2;
            }
            case 3: {
                return 3;
            }
            case 4: {
                return 4;
            }
            case 5: {
                return 5;
            }
            case 6: {
                return 6;
            }
            case 7: {
                return 7;
            }
            case 8: {
                return 8;
            }
            case 9: {
                return 9;
            }
            default: {
                return 0;
            }
        }
    };
    if (sort.tag === 1) {
        return sortColumn(sort.fields[0]) | 0;
    }
    else {
        return sortColumn(sort.fields[0]) | 0;
    }
}

export function sortOrderFromType(sort) {
    if (sort.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export class Model extends Record {
    constructor(Items, Sort, Page, NumberOfPages, NumberOfRows, PageSize, IsLoading) {
        super();
        this.Items = Items;
        this.Sort = Sort;
        this.Page = (Page | 0);
        this.NumberOfPages = (NumberOfPages | 0);
        this.NumberOfRows = (NumberOfRows | 0);
        this.PageSize = (PageSize | 0);
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("WeeklyTrainingLog.List.Types.Model", [], Model, () => [["Items", array_type(CardioSummary$reflection())], ["Sort", Sort$1$reflection(CardioActivitySortColumn$reflection())], ["Page", int32_type], ["NumberOfPages", int32_type], ["NumberOfRows", int32_type], ["PageSize", int32_type], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    return new Model(new Array(0), new Sort$1(1, new CardioActivitySortColumn(7)), 0, 0, 0, 0, true);
}

export class GetPageRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPageRes$reflection() {
    return union_type("WeeklyTrainingLog.List.Types.GetPageRes", [], GetPageRes, () => [[["Item", Page$1$reflection(CardioSummary$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetPage", "GetPageResult", "GotoPage", "SortBy"];
    }
}

export function Msg$reflection() {
    return union_type("WeeklyTrainingLog.List.Types.Msg", [], Msg, () => [[], [["Item", GetPageRes$reflection()]], [["Item", int32_type]], [["Item", CardioActivitySortColumn$reflection()]]]);
}

