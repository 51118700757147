import { Record } from "../.fable/fable-library.3.0.0/Types.js";
import { Id_create, Id__Id$reflection } from "../../../shared/Shared.Types.fs.js";
import { bool_type, lambda_type, unit_type, record_type, class_type, float64_type, option_type, string_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { CardioSummary$reflection, CardioType$reflection } from "../../../shared/Shared.Models.fs.js";

export class CartItem extends Record {
    constructor(ActivityId, Title, Distance, RecordedAtUtc, ActivityType) {
        super();
        this.ActivityId = ActivityId;
        this.Title = Title;
        this.Distance = Distance;
        this.RecordedAtUtc = RecordedAtUtc;
        this.ActivityType = ActivityType;
    }
}

export function CartItem$reflection() {
    return record_type("ComparisonCart.Manager.CartItem", [], CartItem, () => [["ActivityId", Id__Id$reflection()], ["Title", option_type(string_type)], ["Distance", option_type(float64_type)], ["RecordedAtUtc", class_type("System.DateTime")], ["ActivityType", CardioType$reflection()]]);
}

export class ICartmanager extends Record {
    constructor(addItem, removeItem, isInCart, removeItemWithActivityId) {
        super();
        this.addItem = addItem;
        this.removeItem = removeItem;
        this.isInCart = isInCart;
        this.removeItemWithActivityId = removeItemWithActivityId;
    }
}

export function ICartmanager$reflection() {
    return record_type("ComparisonCart.Manager.ICartmanager", [], ICartmanager, () => [["addItem", lambda_type(CardioSummary$reflection(), unit_type)], ["removeItem", lambda_type(CartItem$reflection(), unit_type)], ["isInCart", lambda_type(CardioSummary$reflection(), bool_type)], ["removeItemWithActivityId", lambda_type(Id__Id$reflection(), unit_type)]]);
}

export function ICartmanager_get_NullManager() {
    return new ICartmanager((_arg1) => {
    }, (_arg2) => {
    }, (_arg3) => false, (_arg4) => {
    });
}

export function cartItemFromSummary(summary) {
    return new CartItem(Id_create(summary.Id), summary.Title, summary.Metrics.DistanceMeters, summary.Metrics.RecordedAtUtc, summary.ActivityType);
}

