import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, array_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { last120Days, DateRangeType, DateRangeType$reflection } from "../Types.fs.js";
import { TrainingLoadSummary$reflection, DateRange$reflection } from "../../../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(IsLoading, DateRangeType, DateRange, Load) {
        super();
        this.IsLoading = IsLoading;
        this.DateRangeType = DateRangeType;
        this.DateRange = DateRange;
        this.Load = Load;
    }
}

export function Model$reflection() {
    return record_type("Analysis.Fitness.Types.Model", [], Model, () => [["IsLoading", bool_type], ["DateRangeType", DateRangeType$reflection()], ["DateRange", DateRange$reflection()], ["Load", array_type(TrainingLoadSummary$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(false, new DateRangeType(3), last120Days(), new Array(0));
}

export class GetFitnessRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetFitnessRes$reflection() {
    return union_type("Analysis.Fitness.Types.GetFitnessRes", [], GetFitnessRes, () => [[["Item", array_type(TrainingLoadSummary$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDateRangeType", "ChangeStartDate", "ChangeEndDate", "GetFitness", "GetFitnessResult"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.Fitness.Types.Msg", [], Msg, () => [[["Item", DateRangeType$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [["Item", GetFitnessRes$reflection()]]]);
}

