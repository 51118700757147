
export function attachWindowEvent(f, node, eventType) {
    node.addEventListener(eventType, f);
    return {
        Dispose() {
            node.removeEventListener(eventType, f);
        },
    };
}

export function attachWindowEventWithDisposer(f, node, eventType, disposer) {
    node.addEventListener(eventType, f);
    return {
        Dispose() {
            disposer();
            node.removeEventListener(eventType, f);
        },
    };
}

