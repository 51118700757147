import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { PeakPerformanceType$reflection, AggregatePeakPerformance$reflection } from "../../../../shared/Shared.Models.fs.js";
import { record_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { sortWith, mapIndexed, maxBy, collect, max as max_1, filter, map, isEmpty } from "../../.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { uncurry, comparePrimitives, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { line, ITimeGraphProps, TimeGraphColor, TimeGraphDataPoint, TimeGraphTick } from "../../TimeGraph.fs.js";
import { peakTypeAsUnits, peakTypeAsLabel, seriesColors, possibleTicks } from "../Types.fs.js";
import { Series_Time_Z1F763A16 } from "../../Graph.fs.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { shortDurationAsString } from "../../Units.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { renderAggregatePeakPerformanceTable } from "../Helpers.fs.js";

export class IRenderAveragePerformancesProps extends Record {
    constructor(Context, Averages, PeakType) {
        super();
        this.Context = Context;
        this.Averages = Averages;
        this.PeakType = PeakType;
    }
}

export function IRenderAveragePerformancesProps$reflection() {
    return record_type("Analysis.PeakPerformances.RenderAverage.IRenderAveragePerformancesProps", [], IRenderAveragePerformancesProps, () => [["Context", Context$reflection()], ["Averages", array_type(array_type(AggregatePeakPerformance$reflection()))], ["PeakType", PeakPerformanceType$reflection()]]);
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const peakPerformances = props.Averages;
    const peakType = props.PeakType;
    if (isEmpty(peakPerformances)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const peaksForLapAndPeakType = Array.from(map((routePeaks) => Array.from(filter((p) => equalsSafe(p.PeakType, peakType), routePeaks)), peakPerformances));
        const largestTimeInSeconds = max_1(map((dp) => dp.PeakDurationInSeconds, collect((x) => x, peaksForLapAndPeakType)), {
            Compare: comparePrimitives,
        }) | 0;
        const ticks = Array.from(map((t_1) => (new TimeGraphTick(t_1, void 0)), filter((t) => (t <= largestTimeInSeconds), possibleTicks)));
        const largestTick = maxBy((t_2) => t_2.TimeInSeconds, ticks, {
            Compare: comparePrimitives,
        }).TimeInSeconds | 0;
        const props_2 = new ITimeGraphProps(Array.from(mapIndexed((index, seriesSource) => {
            let projection_1;
            return Series_Time_Z1F763A16(Array.from((projection_1 = ((dp_1) => dp_1.TimeInSeconds), sortWith((x_3, y_2) => comparePrimitives(projection_1(x_3), projection_1(y_2)), map((peak_1) => (new TimeGraphDataPoint(peak_1.PeakValue, peak_1.PeakDurationInSeconds, true)), filter((peak) => (peak.PeakDurationInSeconds <= largestTick), seriesSource))))), new TimeGraphColor(0, seriesColors[index % seriesColors.length]));
        }, peaksForLapAndPeakType)), ticks, void 0, (tupledArg) => {
            const max = tupledArg[1];
            switch (peakType.tag) {
                case 3:
                case 4:
                case 1: {
                    return [0, Math.ceil(max / 10) * 10];
                }
                case 0: {
                    return [Math.floor((tupledArg[0] - 5) / 5) * 5, Math.ceil(max / 5) * 5];
                }
                default: {
                    return [0, Math.ceil(max)];
                }
            }
        }, void 0, true, true, true, "#718096", "#CBD5E0", 0, false, void 0, new GraphValueTransformer(0), (series_1, seriesIndex, dataPoint) => {
            let arg20;
            return [["Peak duration", shortDurationAsString(dataPoint.TimeInSeconds)], [peakTypeAsLabel(peakType), (arg20 = peakTypeAsUnits(peakType, props.Context), toText(printf("%.0f %s"))(dataPoint.Value)(arg20))]];
        }, uncurry(3, void 0), void 0);
        const peakTable = renderAggregatePeakPerformanceTable(peaksForLapAndPeakType, peakType);
        return react.createElement("div", {}, react.createElement("div", {
            style: {
                height: 320,
            },
        }, line(props_2)), react.createElement("div", {
            className: "mt-6",
        }, peakTable));
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/PeakPerformances/RenderAverage.fs", 21);

