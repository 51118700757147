import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Description__Description$reflection, Name__Name$reflection, Id__Id$reflection } from "../../../../shared/Shared.Types.fs.js";
import { union_type, class_type, list_type, record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { empty } from "../../.fable/fable-library.3.0.0/List.js";

export class Item extends Record {
    constructor(Id, IsCreatedByCurrentUser, Name, Description) {
        super();
        this.Id = Id;
        this.IsCreatedByCurrentUser = IsCreatedByCurrentUser;
        this.Name = Name;
        this.Description = Description;
    }
}

export function Item$reflection() {
    return record_type("Programme.Index.Types.Item", [], Item, () => [["Id", Id__Id$reflection()], ["IsCreatedByCurrentUser", bool_type], ["Name", Name__Name$reflection()], ["Description", Description__Description$reflection()]]);
}

export class Model extends Record {
    constructor(Items, IsLoading) {
        super();
        this.Items = Items;
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("Programme.Index.Types.Model", [], Model, () => [["Items", list_type(Item$reflection())], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    return new Model(empty(), true);
}

export class GetProgrammesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetProgrammesRes$reflection() {
    return union_type("Programme.Index.Types.GetProgrammesRes", [], GetProgrammesRes, () => [[["Item", list_type(Item$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetProgrammes", "GetProgrammesResult"];
    }
}

export function Msg$reflection() {
    return union_type("Programme.Index.Types.Msg", [], Msg, () => [[], [["Item", GetProgrammesRes$reflection()]]]);
}

