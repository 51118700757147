import { SubmitUploadActivityRes, UploadActivityMsg, Model, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { uploadFile } from "./Api.fs.js";
import { successToast, warningToast, errorToast } from "../../Notifications.fs.js";
import { CardioActivityPage, Page, modifyLocation } from "../../Router.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init() {
    return [Model_get_Empty(), Cmd_none()];
}

function isValidTcx(content) {
    return true;
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 0: {
            return [new Model(model.Filename, void 0, model.IsSaving, model.ValidationError), Cmd_none(), false];
        }
        case 2: {
            const matchValue_1 = [model.Filename, model.File];
            let pattern_matching_result, file, filename_1;
            if (matchValue_1[0] != null) {
                if (matchValue_1[1] != null) {
                    pattern_matching_result = 0;
                    file = matchValue_1[1];
                    filename_1 = matchValue_1[0];
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return [new Model(model.Filename, model.File, true, model.ValidationError), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, (context_1) => uploadFile(filename_1, file, context_1), context, (arg0) => (new UploadActivityMsg(3, arg0)), (arg) => (new UploadActivityMsg(3, new SubmitUploadActivityRes(1, arg)))), false];
                }
                case 1: {
                    return [new Model(model.Filename, model.File, false, model.ValidationError), errorToast("Unable to save activity"), true];
                }
            }
        }
        case 3: {
            const result = msg.fields[0];
            switch (result.tag) {
                case 2: {
                    const value_1 = modifyLocation(new Page(8, new CardioActivityPage(1, result.fields[0])));
                    void undefined;
                    return [new Model(model.Filename, model.File, false, model.ValidationError), warningToast("The activity has already been uploaded, showing it now."), true];
                }
                case 1: {
                    console.error(some(result.fields[0]));
                    return [new Model(model.Filename, model.File, false, model.ValidationError), errorToast("Unable to save activity"), true];
                }
                default: {
                    const value = modifyLocation(new Page(8, new CardioActivityPage(1, result.fields[0])));
                    void undefined;
                    return [new Model(model.Filename, model.File, false, model.ValidationError), successToast("Your activity has been saved"), true];
                }
            }
        }
        default: {
            const content = msg.fields[1];
            if (isValidTcx(content)) {
                return [new Model(msg.fields[0], content, model.IsSaving, void 0), Cmd_OfFunc_result(new UploadActivityMsg(2)), false];
            }
            else {
                return [new Model(void 0, void 0, model.IsSaving, "Not a valid tcx file"), Cmd_none(), false];
            }
        }
    }
}

