import { day, month as month_1, year as year_1, create, date, dayOfWeek, addDays } from "../.fable/fable-library.3.0.0/Date.js";
import { rangeNumber, map, takeWhile, last } from "../.fable/fable-library.3.0.0/Seq.js";
import { ofArray } from "../.fable/fable-library.3.0.0/List.js";

export function startOfWeek(startDayOfWeek, dt) {
    let copyOfStruct = addDays(dt, -1 * ((7 + (dayOfWeek(dt) - startDayOfWeek)) % 7));
    return date(copyOfStruct);
}

export function endOfDay(dt) {
    return create(year_1(dt), month_1(dt), day(dt), 23, 59, 59);
}

export const mondayStartOfWeek = (dt) => startOfWeek(1, dt);

export function viewableDateRange(year, month) {
    let copyOfStruct_1;
    const calendarStartDate = mondayStartOfWeek(create(year, month, 1));
    return [calendarStartDate, endOfDay((copyOfStruct_1 = last(takeWhile((weekStartDate) => {
        let copyOfStruct;
        if (month_1(weekStartDate) <= month) {
            return true;
        }
        else {
            return (copyOfStruct = addDays(weekStartDate, 7), month_1(copyOfStruct)) <= month;
        }
    }, map((weekIndex) => addDays(calendarStartDate, 7 * weekIndex), rangeNumber(0, 1, 6)))), addDays(copyOfStruct_1, 6)))];
}

export const days = ofArray(["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);

export const months = ofArray(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]);

export const abbreviatedMonths = ofArray(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);

