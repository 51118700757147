import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { CartItem$reflection } from "../../ComparisonCart/Manager.fs.js";
import { union_type, class_type, record_type, bool_type, array_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { PeakPerformance$reflection, CardioSummary$reflection } from "../../../../shared/Shared.Models.fs.js";

export class Model extends Record {
    constructor(CartItems, Summaries, IsLoading, InsufficientCartItems, PeakPerformance) {
        super();
        this.CartItems = CartItems;
        this.Summaries = Summaries;
        this.IsLoading = IsLoading;
        this.InsufficientCartItems = InsufficientCartItems;
        this.PeakPerformance = PeakPerformance;
    }
}

export function Model$reflection() {
    return record_type("Analysis.CompareRides.Types.Model", [], Model, () => [["CartItems", array_type(CartItem$reflection())], ["Summaries", array_type(CardioSummary$reflection())], ["IsLoading", bool_type], ["InsufficientCartItems", bool_type], ["PeakPerformance", array_type(array_type(PeakPerformance$reflection()))]]);
}

export function Model_Empty_Z7898A6E4(cartItems) {
    return new Model(cartItems, new Array(0), true, false, new Array(0));
}

export class GetComparisonRideDataRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetComparisonRideDataRes$reflection() {
    return union_type("Analysis.CompareRides.Types.GetComparisonRideDataRes", [], GetComparisonRideDataRes, () => [[["Item1", array_type(CardioSummary$reflection())], ["Item2", array_type(array_type(PeakPerformance$reflection()))]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetComparisonRideData", "GetComparisonRideDataResult"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.CompareRides.Types.Msg", [], Msg, () => [[], [["Item", GetComparisonRideDataRes$reflection()]]]);
}

