import * as react from "react";
import { map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { CardioActivityPage, Page, href } from "../../Router.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { toString } from "../../.fable/fable-library.3.0.0/Types.js";

export function root(context, model, dispatch) {
    if (model.IsLoading) {
        return react.createElement("div", {}, "Loading...");
    }
    else {
        return react.createElement("div", {}, react.createElement("h1", {}, "CardioActivity View"), react.createElement("table", {}, react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("th", {}), react.createElement("th", {}), react.createElement("th", {}, "Id"), react.createElement("th", {}, "Value"))), react.createElement("tbody", {}, ...map((i) => react.createElement("tr", {}, react.createElement("td", {}, react.createElement("a", keyValueList([href(new Page(8, new CardioActivityPage(1, Id_value(i.Id))))], 1), "View")), react.createElement("td", {}, toString(i.Id)), react.createElement("td", {}, i.Title)), model.Items))));
    }
}

