import { addDays, today } from "../../.fable/fable-library.3.0.0/Date.js";
import { MetricType, Metric$reflection, MetricType$reflection, DateRange$reflection, DateRange } from "../../../../shared/Shared.Models.fs.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, array_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { DateRangeType, DateRangeType$reflection } from "../Types.fs.js";

export function last30Days() {
    let copyOfStruct;
    return new DateRange((copyOfStruct = today(), addDays(copyOfStruct, -30)), today());
}

export class Model extends Record {
    constructor(IsLoading, DateRangeType, DateRange, MetricType, Metrics) {
        super();
        this.IsLoading = IsLoading;
        this.DateRangeType = DateRangeType;
        this.DateRange = DateRange;
        this.MetricType = MetricType;
        this.Metrics = Metrics;
    }
}

export function Model$reflection() {
    return record_type("Analysis.MetricHistory.Types.Model", [], Model, () => [["IsLoading", bool_type], ["DateRangeType", DateRangeType$reflection()], ["DateRange", DateRange$reflection()], ["MetricType", MetricType$reflection()], ["Metrics", array_type(Metric$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(false, new DateRangeType(0), last30Days(), new MetricType(0), []);
}

export class GetMetricsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetMetricsRes$reflection() {
    return union_type("Analysis.MetricHistory.Types.GetMetricsRes", [], GetMetricsRes, () => [[["Item", array_type(Metric$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeAnalysisMetricType", "ChangeDateRangeType", "ChangeStartDate", "ChangeEndDate", "GetMetrics", "GetMetricsResult"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.MetricHistory.Types.Msg", [], Msg, () => [[["Item", MetricType$reflection()]], [["Item", DateRangeType$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [], [["Item", GetMetricsRes$reflection()]]]);
}

