import { length as length_1, takeWhile, empty as empty_1, rangeNumber, map, append as append_1, delay, fold } from "../client/src/.fable/fable-library.3.0.0/Seq.js";
import { FSharpResult$2 } from "../client/src/.fable/fable-library.3.0.0/Choice.js";
import { empty, singleton, append } from "../client/src/.fable/fable-library.3.0.0/List.js";
import { Record, FSharpException } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { record_type, int32_type, array_type, float64_type, class_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../client/src/.fable/fable-library.3.0.0/Util.js";

export function elevate(results) {
    return fold((result, resultInterval) => {
        if (result.tag === 0) {
            if (resultInterval.tag === 0) {
                return new FSharpResult$2(0, append(result.fields[0], singleton(resultInterval.fields[0])));
            }
            else {
                return new FSharpResult$2(1, "Error found");
            }
        }
        else {
            return new FSharpResult$2(1, result.fields[0]);
        }
    }, new FSharpResult$2(0, empty()), results);
}

export class RollingArray_MaximumSizeException extends FSharpException {
    constructor() {
        super();
    }
}

export function RollingArray_MaximumSizeException$reflection() {
    return class_type("Shared.Collections.RollingArray.MaximumSizeException", void 0, RollingArray_MaximumSizeException, class_type("System.Exception"));
}

function RollingArray_MaximumSizeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof RollingArray_MaximumSizeException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class RollingArray_IsEmptyException extends FSharpException {
    constructor() {
        super();
    }
}

export function RollingArray_IsEmptyException$reflection() {
    return class_type("Shared.Collections.RollingArray.IsEmptyException", void 0, RollingArray_IsEmptyException, class_type("System.Exception"));
}

function RollingArray_IsEmptyException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof RollingArray_IsEmptyException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

function RollingArray_valueAsCount(value) {
    if (value == null) {
        return 0;
    }
    else {
        return 1;
    }
}

export class RollingArray_RollingArray extends Record {
    constructor(buffer, preallocatedSize, length, start, nextPosition, total, count) {
        super();
        this.buffer = buffer;
        this.preallocatedSize = (preallocatedSize | 0);
        this.length = (length | 0);
        this.start = (start | 0);
        this.nextPosition = (nextPosition | 0);
        this.total = total;
        this.count = (count | 0);
    }
}

export function RollingArray_RollingArray$reflection() {
    return record_type("Shared.Collections.RollingArray.RollingArray", [], RollingArray_RollingArray, () => [["buffer", array_type(float64_type)], ["preallocatedSize", int32_type], ["length", int32_type], ["start", int32_type], ["nextPosition", int32_type], ["total", float64_type], ["count", int32_type]]);
}

export function RollingArray_create(size) {
    return new RollingArray_RollingArray(new Float64Array(size), size, 0, 0, 0, 0, 0);
}

function RollingArray_getTotalAndCount(items) {
    const arg = [0, 0];
    return fold((tupledArg, cs) => [tupledArg[0] + cs, tupledArg[1] + 1], [arg[0], arg[1]], items);
}

export function RollingArray_push(item, rollingArray) {
    if (rollingArray.length === rollingArray.preallocatedSize) {
        const exn = new RollingArray_MaximumSizeException();
        throw exn;
    }
    else {
        rollingArray.buffer[rollingArray.nextPosition] = item;
        return new RollingArray_RollingArray(rollingArray.buffer, rollingArray.preallocatedSize, rollingArray.length + 1, rollingArray.start, (rollingArray.nextPosition === (rollingArray.preallocatedSize - 1)) ? 0 : (rollingArray.nextPosition + 1), rollingArray.total + item, rollingArray.count + 1);
    }
}

export function RollingArray_removeHead(rollingArray) {
    if (rollingArray.length === 0) {
        const exn = new RollingArray_IsEmptyException();
        throw exn;
    }
    else {
        return new RollingArray_RollingArray(rollingArray.buffer, rollingArray.preallocatedSize, rollingArray.length - 1, ((rollingArray.start + 1) === rollingArray.preallocatedSize) ? 0 : (rollingArray.start + 1), rollingArray.nextPosition, rollingArray.total - rollingArray.buffer[rollingArray.start], rollingArray.count - 1);
    }
}

export function RollingArray_asSeq(rollingArray) {
    return delay(() => append_1(map((i) => rollingArray.buffer[i], rangeNumber(rollingArray.start, 1, ((rollingArray.start + rollingArray.length) > rollingArray.preallocatedSize) ? (rollingArray.preallocatedSize - 1) : ((rollingArray.start + rollingArray.length) - 1))), delay(() => {
        if ((rollingArray.start + rollingArray.length) >= rollingArray.preallocatedSize) {
            return map((i_1) => rollingArray.buffer[i_1], rangeNumber(0, 1, rollingArray.nextPosition - 1));
        }
        else {
            return empty_1();
        }
    })));
}

export function RollingArray_removeHeadWhile(predicate, rollingArray) {
    if (rollingArray.length === 0) {
        const exn = new RollingArray_IsEmptyException();
        throw exn;
    }
    else {
        const itemsToRemove = Float64Array.from(takeWhile(predicate, RollingArray_asSeq(rollingArray)));
        const toRemove = length_1(itemsToRemove) | 0;
        const patternInput = RollingArray_getTotalAndCount(itemsToRemove);
        return new RollingArray_RollingArray(rollingArray.buffer, rollingArray.preallocatedSize, rollingArray.length - toRemove, ((rollingArray.start + toRemove) >= rollingArray.preallocatedSize) ? (toRemove - (rollingArray.preallocatedSize - rollingArray.start)) : (rollingArray.start + toRemove), rollingArray.nextPosition, rollingArray.total - patternInput[0], rollingArray.count - patternInput[1]);
    }
}

export function RollingArray_first(rollingArray) {
    if (rollingArray.length === 0) {
        const exn = new RollingArray_IsEmptyException();
        throw exn;
    }
    else {
        return rollingArray.buffer[rollingArray.start];
    }
}

export function RollingArray_last(rollingArray) {
    if (rollingArray.length === 0) {
        const exn = new RollingArray_IsEmptyException();
        throw exn;
    }
    else {
        return rollingArray.buffer[((rollingArray.nextPosition - 1) < 0) ? (rollingArray.buffer.length - 1) : (rollingArray.nextPosition - 1)];
    }
}

