import { warningPanel } from "../../Notifications.fs.js";
import * as react from "react";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { CSSProp, HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { ofSeq, empty, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { selectableSummary } from "../../Grids.fs.js";
import { CardioActivityPage, Page, modifyLocation } from "../../Router.fs.js";
import { singleton as singleton_1, delay, exists, collect, sortWith, mapIndexed, maxBy, filter, isEmpty, findIndex, map, append } from "../../.fable/fable-library.3.0.0/Seq.js";
import { speedFloat, powerWeightUnits, shortDurationAsString, distance, ascent, elapsedTimeFromSeconds, power, normalisedPower, heartRate, distanceUnits, ascentUnits, normalisedPowerUnits, heartRateUnits } from "../../Units.fs.js";
import { uncurry, comparePrimitives, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { IconSize, iconForCardioType } from "../../Icons.fs.js";
import { PeakPerformanceType, LapType, PeakPerformance, CardioSummary__get_CalculatedTitle } from "../../../../shared/Shared.Models.fs.js";
import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { ITimeGraphProps, TimeGraphColor, TimeGraphDataPoint, line, TimeGraphTick } from "../../TimeGraph.fs.js";
import { availablePeakTypes, peakTypeAsUnits, peakTypeAsLabel, possibleTicks } from "../Types.fs.js";
import { Series_Time_Z1F763A16 } from "../../Graph.fs.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { renderPeakPerformanceTable } from "../Helpers.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { view } from "./RideData.fs.js";
import { analysisPage, tabs, panel } from "../../Layout.fs.js";
import { checkBox } from "../../Form.fs.js";

export const insufficientRides = warningPanel("No rides to compare", "Add one or two more rides to the comparison cart to run this analysis");

const seriesColors = ["#5A67D8", "#38A169", "#DD6B20", "#63B3ED", "#718096"];

export function seriesIndicator(seriesIndex) {
    let css, arg10;
    return react.createElement("div", keyValueList([new HTMLAttr(65, "mr-4 h-5 w-5 text-xs text-center text-white bg-indigo-600 rounded-full flex flex-col justify-center"), (css = singleton(new CSSProp(21, seriesColors[seriesIndex % seriesColors.length])), ["style", keyValueList(css, 1)])], 1), (arg10 = ((seriesIndex + 1) | 0), toText(printf("%d"))(arg10)));
}

export function rideSummaries(context, summaries) {
    let source2_5, source2_4, source2_2, source2_1, arg10, arg10_1, arg10_2, arg10_3, arg10_4;
    return react.createElement("div", {}, selectableSummary(true, true, true, (index) => {
        modifyLocation(new Page(8, new CardioActivityPage(1, summaries[index].Id)));
    }, append([""], (source2_5 = (source2_4 = append(["Elapsed Time"], (source2_2 = (source2_1 = append([(arg10 = heartRateUnits(context), toText(printf("Heart Rate (%s)"))(arg10))], []), append([(arg10_1 = normalisedPowerUnits(context), toText(printf("NP (%s)"))(arg10_1))], source2_1)), append([(arg10_2 = normalisedPowerUnits(context), toText(printf("Avg Pwr. (%s)"))(arg10_2))], source2_2))), append([(arg10_3 = ascentUnits(context), toText(printf("Ascent (%s)"))(arg10_3))], source2_4)), append([(arg10_4 = distanceUnits(context), toText(printf("Distance (%s)"))(arg10_4))], source2_5))), map((l) => {
        let value, value_2, value_4, value_6, value_8;
        let source2_13;
        let source2_12;
        let source2_11;
        let source2_10;
        let source2_9;
        const source2_8 = append((value = singleton(heartRate(context, l.Metrics.AverageHeartRate)), (l.Metrics.AverageHeartRate == null) ? singleton("N/A") : value), []);
        source2_9 = append((value_2 = singleton(normalisedPower(context, l.Metrics.NormalisedPower)), (l.Metrics.NormalisedPower == null) ? singleton("N/A") : value_2), source2_8);
        source2_10 = append((value_4 = singleton(power(context, l.Metrics.AveragePower)), (l.Metrics.AveragePower == null) ? singleton("N/A") : value_4), source2_9);
        source2_11 = append([elapsedTimeFromSeconds(context, l.Metrics.TimeSeconds)], source2_10);
        source2_12 = append((value_6 = singleton(ascent(context, l.Metrics.ElevationGainedMeters)), (l.Metrics.ElevationGainedMeters == null) ? singleton("N/A") : value_6), source2_11);
        source2_13 = append((value_8 = singleton(distance(context, l.Metrics.DistanceMeters)), (l.Metrics.DistanceMeters == null) ? singleton("N/A") : value_8), source2_12);
        return append([react.createElement("div", {
            className: "flex flex-row justify-start",
        }, seriesIndicator(findIndex((s_11) => equalsSafe(s_11, l), summaries)), react.createElement("div", {
            className: "mr-4",
        }, iconForCardioType(l.ActivityType)(new IconSize(3))), CardioSummary__get_CalculatedTitle(l))], source2_13);
    }, summaries)));
}

export function renderPeakPerformances(context, peakPerformances, peakType, summaries, showWattsKg) {
    if (isEmpty(peakPerformances)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const powerMapper = showWattsKg ? ((source_1) => map((p) => (new PeakPerformance(p.StartDateTimeUtc, p.PeakDurationSeconds, p.PeakValue / defaultArg(p.Weight, 1), p.PeakType, p.Lap, p.CardioActivitySummaryId, p.Weight)), source_1)) : ((source_2) => map((x) => x, source_2));
        const peaksForLapAndPeakType = Array.from(filter((routePeaks_1) => (routePeaks_1.length > 0), map((routePeaks) => Array.from(filter((p_1) => {
            if (equalsSafe(p_1.PeakType, peakType)) {
                return equalsSafe(p_1.Lap, new LapType(0));
            }
            else {
                return false;
            }
        }, routePeaks)), peakPerformances)));
        if (isEmpty(peaksForLapAndPeakType)) {
            return react.createElement(react.Fragment, {});
        }
        else {
            const largestTimeInSeconds = maxBy((dp_1) => dp_1.PeakDurationSeconds, map((s) => maxBy((dp) => dp.PeakDurationSeconds, s, {
                Compare: comparePrimitives,
            }), peaksForLapAndPeakType), {
                Compare: comparePrimitives,
            }).PeakDurationSeconds | 0;
            const ticks = Array.from(map((t_1) => (new TimeGraphTick(t_1, void 0)), filter((t) => (t <= largestTimeInSeconds), possibleTicks)));
            const largestTick = maxBy((t_2) => t_2.TimeInSeconds, ticks, {
                Compare: comparePrimitives,
            }).TimeInSeconds | 0;
            return react.createElement("div", {}, react.createElement("div", {
                style: {
                    height: 320,
                },
            }, line(new ITimeGraphProps(Array.from(mapIndexed((index, seriesSource) => {
                let projection_3;
                return Series_Time_Z1F763A16(Array.from((projection_3 = ((dp_2) => dp_2.TimeInSeconds), sortWith((x_4, y_3) => comparePrimitives(projection_3(x_4), projection_3(y_3)), map((peak_1) => (new TimeGraphDataPoint(peak_1.PeakValue, peak_1.PeakDurationSeconds, true)), powerMapper(filter((peak) => (peak.PeakDurationSeconds <= largestTick), seriesSource)))))), new TimeGraphColor(0, seriesColors[index % seriesColors.length]));
            }, peaksForLapAndPeakType)), ticks, void 0, (tupledArg) => {
                const max = tupledArg[1];
                const matchValue_2 = [peakType, showWattsKg];
                let pattern_matching_result;
                if (matchValue_2[0].tag === 2) {
                    pattern_matching_result = 1;
                }
                else if (matchValue_2[0].tag === 1) {
                    if (matchValue_2[1]) {
                        pattern_matching_result = 1;
                    }
                    else {
                        pattern_matching_result = 2;
                    }
                }
                else if (matchValue_2[0].tag === 4) {
                    pattern_matching_result = 2;
                }
                else if (matchValue_2[0].tag === 3) {
                    pattern_matching_result = 2;
                }
                else {
                    pattern_matching_result = 0;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        return [Math.floor((tupledArg[0] - 5) / 5) * 5, Math.ceil(max / 5) * 5];
                    }
                    case 1: {
                        return [0, Math.ceil(max)];
                    }
                    case 2: {
                        return [0, Math.ceil(max / 10) * 10];
                    }
                }
            }, void 0, true, true, true, "#718096", "#CBD5E0", 0, false, void 0, new GraphValueTransformer(0), (series_1, seriesIndex, dataPoint) => {
                let arg20, arg20_1;
                return [["Peak duration", shortDurationAsString(dataPoint.TimeInSeconds)], [peakTypeAsLabel(peakType), showWattsKg ? (arg20 = powerWeightUnits(context), toText(printf("%.2f %s"))(dataPoint.Value)(arg20)) : (arg20_1 = peakTypeAsUnits(peakType, context), toText(printf("%.0f %s"))(dataPoint.Value)(arg20_1))]];
            }, uncurry(3, void 0), void 0))), react.createElement("div", {
                className: "mt-6",
            }, renderPeakPerformanceTable(peaksForLapAndPeakType, peakType, context, summaries)));
        }
    }
}

export const analysis = FunctionComponent_Of_Z5A158BBF((props) => {
    const context = props.Context;
    const model = props.Model;
    const selectedTabIndex = react.useState(0);
    const showWattsKg = react.useState(false);
    const collectedPeaks = collect((x) => x, model.PeakPerformance);
    const patternInput = availablePeakTypes(collectedPeaks);
    const hasSpeed = patternInput[3];
    const hasPower = patternInput[0];
    const hasHeart = patternInput[1];
    const hasAscent = patternInput[2];
    const hasWeightForPower = exists((p) => {
        if (equalsSafe(p.PeakType, new PeakPerformanceType(1))) {
            return p.Weight != null;
        }
        else {
            return false;
        }
    }, collectedPeaks);
    const tabLabels = Array.from(append(hasPower ? singleton("Peak Power") : empty(), append(hasHeart ? singleton("Peak Heart Rate") : empty(), append(hasSpeed ? singleton("Peak Speed") : empty(), append(hasAscent ? singleton("Peak Ascent") : empty(), ["Ride Data"])))));
    const renderers = Array.from(append(hasPower ? singleton(() => renderPeakPerformances(context, model.PeakPerformance, new PeakPerformanceType(1), model.Summaries, showWattsKg[0])) : empty(), append(hasHeart ? singleton(() => renderPeakPerformances(context, model.PeakPerformance, new PeakPerformanceType(0), model.Summaries, false)) : empty(), append(hasSpeed ? singleton(() => renderPeakPerformances(context, Array.from(map((set$) => Array.from(map((p_1) => (new PeakPerformance(p_1.StartDateTimeUtc, p_1.PeakDurationSeconds, speedFloat(context, p_1.PeakValue), p_1.PeakType, p_1.Lap, p_1.CardioActivitySummaryId, p_1.Weight)), set$)), model.PeakPerformance)), new PeakPerformanceType(3), model.Summaries, false)) : empty(), append(hasAscent ? singleton(() => renderPeakPerformances(context, model.PeakPerformance, new PeakPerformanceType(4), model.Summaries, false)) : empty(), [() => react.createElement("div", {}, view(context, model.Summaries, seriesIndicator))])))));
    return react.createElement(react.Fragment, {}, rideSummaries(context, model.Summaries), react.createElement("div", {
        className: "mt-6",
    }, panel([react.createElement("div", {
        className: "-mt-2",
    }, ...ofSeq(delay(() => append(singleton_1(react.createElement("div", {
        className: "mb-4",
    }, tabs(tabLabels, selectedTabIndex[0], (arg00) => {
        selectedTabIndex[1](arg00);
    }))), delay(() => {
        let matchValue;
        return append((matchValue = [selectedTabIndex[0], hasWeightForPower], (matchValue[0] === 0) ? (matchValue[1] ? singleton_1(react.createElement("div", {
            className: "pb-4 flex flex-row justify-between items-center",
        }, checkBox("Show watts/kg", showWattsKg[0], (arg00_1) => {
            showWattsKg[1](arg00_1);
        }))) : singleton_1(react.createElement(react.Fragment, {}))) : singleton_1(react.createElement(react.Fragment, {}))), delay(() => singleton_1(renderers[selectedTabIndex[0]]())));
    })))))])));
}, void 0, uncurry(2, void 0), void 0, "analysis", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/CompareRides/View.fs", 176);

export function root(context, model, dispatch) {
    return analysisPage(model.IsLoading, true, react.createElement("div", {}), react.createElement("div", {}), ofSeq(delay(() => (model.InsufficientCartItems ? singleton_1(insufficientRides) : singleton_1(analysis({
        Context: context,
        Dispatch: dispatch,
        Model: model,
    }))))));
}

