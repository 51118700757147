import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { DeleteMetricRes, addModalMetricTypes, AddMetricRes, GetMetricsRes, metricTypeFilters } from "./Types.fs.js";
import { metrics } from "../../Server.fs.js";

export class UnableToGetMetricsPageException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToGetMetricsPageException$reflection() {
    return class_type("Metrics.Index.Api.UnableToGetMetricsPageException", void 0, UnableToGetMetricsPageException, class_type("System.Exception"));
}

function UnableToGetMetricsPageException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToGetMetricsPageException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class UnableToAddMetricException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToAddMetricException$reflection() {
    return class_type("Metrics.Index.Api.UnableToAddMetricException", void 0, UnableToAddMetricException, class_type("System.Exception"));
}

function UnableToAddMetricException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToAddMetricException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class UnableToDeleteMetricException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToDeleteMetricException$reflection() {
    return class_type("Metrics.Index.Api.UnableToDeleteMetricException", void 0, UnableToDeleteMetricException, class_type("System.Exception"));
}

function UnableToDeleteMetricException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToDeleteMetricException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getPage(model, context) {
    return singleton.Delay(() => {
        const metricType = metricTypeFilters[model.MetricTypeFilterIndex][0];
        return singleton.Bind(metrics((api) => api.getPage(metricType, model.Page, model.Sort)), (_arg1) => {
            const apiResponse = _arg1;
            return singleton.Return((apiResponse.tag === 0) ? (new GetMetricsRes(0, apiResponse.fields[0])) : (new GetMetricsRes(1, new UnableToGetMetricsPageException())));
        });
    });
}

export function addMetric(model, context) {
    return singleton.Delay(() => {
        let matchValue, value;
        return singleton.ReturnFrom((matchValue = model.AddModalValue, (matchValue == null) ? singleton.Delay(() => singleton.Return(new AddMetricRes(1, new UnableToAddMetricException()))) : (value = matchValue, singleton.Delay(() => singleton.Bind(metrics((api) => api.create(addModalMetricTypes[model.AddModalMetricTypeIndex][0], model.AddModalDateTime, value)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new AddMetricRes(0)) : (new AddMetricRes(1, new UnableToAddMetricException()))))))));
    });
}

export function deleteMetric(model, context) {
    return singleton.Delay(() => {
        let matchValue, metric;
        return singleton.ReturnFrom((matchValue = model.DeleteModalMetric, (matchValue == null) ? singleton.Delay(() => singleton.Return(new DeleteMetricRes(1, new UnableToDeleteMetricException()))) : (metric = matchValue, singleton.Delay(() => singleton.Bind(metrics((api) => api.delete(metric.Id)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new DeleteMetricRes(0)) : (new DeleteMetricRes(1, new UnableToAddMetricException()))))))));
    });
}

