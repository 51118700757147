import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { UpdateCategoryRes, DeleteCardioActivityRes, GetActivityIdRes, GetCardioActivityRes } from "./Types.fs.js";
import { zones as zones_1, peaks as peaks_1, cardioActivity } from "../../Server.fs.js";
import { CardioActivityGranularity } from "../../../../shared/Shared.Api.fs.js";
import { ofSeq, empty } from "../../.fable/fable-library.3.0.0/List.js";
import { map, filter } from "../../.fable/fable-library.3.0.0/Seq.js";
import { speedFloat } from "../../Units.fs.js";
import { PeakPerformance } from "../../../../shared/Shared.Models.fs.js";

export class UnableToGetActivityException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToGetActivityException$reflection() {
    return class_type("CardioActivity.Show.Api.UnableToGetActivityException", void 0, UnableToGetActivityException, class_type("System.Exception"));
}

function UnableToGetActivityException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToGetActivityException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class UnableToDeleteActivityException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToDeleteActivityException$reflection() {
    return class_type("CardioActivity.Show.Api.UnableToDeleteActivityException", void 0, UnableToDeleteActivityException, class_type("System.Exception"));
}

function UnableToDeleteActivityException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToDeleteActivityException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class UnableToUpdateCategoryException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToUpdateCategoryException$reflection() {
    return class_type("CardioActivity.Show.Api.UnableToUpdateCategoryException", void 0, UnableToUpdateCategoryException, class_type("System.Exception"));
}

function UnableToUpdateCategoryException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToUpdateCategoryException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

function processGetActivityResponse(context, activityId, response) {
    return singleton.Delay(() => {
        let summary;
        const asyncError = singleton.Delay(() => singleton.Return(new GetCardioActivityRes(2, new UnableToGetActivityException())));
        return singleton.ReturnFrom((response.tag === 0) ? (summary = response.fields[0], singleton.Delay(() => singleton.Bind(summary.HasRoute ? singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getRoute(activityId, new CardioActivityGranularity(1))), (_arg1) => {
            let exn;
            const routeResponse = _arg1;
            return singleton.Return((routeResponse.tag === 0) ? routeResponse.fields[0] : (exn = (new UnableToGetActivityException()), (() => {
                throw exn;
            })()));
        })) : singleton.Delay(() => singleton.Return(empty())), (_arg2) => singleton.Bind(peaks_1((api_1) => api_1.getForCardioActivity(activityId)), (_arg3) => {
            const peaksResponse = _arg3;
            let patternInput;
            if (peaksResponse.tag === 0) {
                const peaks = peaksResponse.fields[0];
                const powerPeaks = ofSeq(filter((p) => {
                    if (p.PeakType.tag === 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, peaks));
                patternInput = [ofSeq(filter((p_1) => {
                    if (p_1.PeakType.tag === 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, peaks)), powerPeaks, ofSeq(map((p_3) => (new PeakPerformance(p_3.StartDateTimeUtc, p_3.PeakDurationSeconds, speedFloat(context, p_3.PeakValue), p_3.PeakType, p_3.Lap, p_3.CardioActivitySummaryId, p_3.Weight)), filter((p_2) => {
                    if (p_2.PeakType.tag === 3) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, peaks))), ofSeq(filter((p_4) => {
                    if (p_4.PeakType.tag === 4) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, peaks))];
            }
            else {
                const exn_1 = new UnableToGetActivityException();
                throw exn_1;
            }
            return singleton.Bind(zones_1((api_2) => api_2.getPowerZonesActiveOnDate(summary.Metrics.RecordedAtUtc)), (_arg4) => {
                const zonesResponse = _arg4;
                let zones;
                switch (zonesResponse.tag) {
                    case 1: {
                        zones = (void 0);
                        break;
                    }
                    case 0: {
                        zones = zonesResponse.fields[0];
                        break;
                    }
                    default: {
                        const exn_2 = new UnableToGetActivityException();
                        throw exn_2;
                    }
                }
                return singleton.Bind(zones_1((api_3) => api_3.getHeartZonesActiveOnDate(summary.Metrics.RecordedAtUtc)), (_arg5) => {
                    const hrZonesResponse = _arg5;
                    let hrZones;
                    switch (hrZonesResponse.tag) {
                        case 1: {
                            hrZones = (void 0);
                            break;
                        }
                        case 0: {
                            hrZones = hrZonesResponse.fields[0];
                            break;
                        }
                        default: {
                            const exn_3 = new UnableToGetActivityException();
                            throw exn_3;
                        }
                    }
                    return singleton.Bind(zones_1((api_4) => api_4.getTimeInZones(activityId)), (_arg6) => {
                        const timeInZonesResponse = _arg6;
                        return singleton.Return(new GetCardioActivityRes(0, [summary, _arg2, patternInput[0], patternInput[1], patternInput[2], patternInput[3], zones, hrZones, (timeInZonesResponse.tag === 0) ? timeInZonesResponse.fields[0] : (void 0)]));
                    });
                });
            });
        })))) : ((response.tag === 1) ? singleton.Delay(() => singleton.Return(new GetCardioActivityRes(1))) : asyncError));
    });
}

export function getNextActivityId(context, activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getNext(activityId)), (_arg1) => {
        const response = _arg1;
        return singleton.Return((response.tag === 0) ? (new GetActivityIdRes(0, response.fields[0].Id)) : (new GetActivityIdRes(1, new UnableToGetActivityException())));
    }));
}

export function getPreviousActivityId(context, activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getPrevious(activityId)), (_arg1) => {
        const response = _arg1;
        return singleton.Return((response.tag === 0) ? (new GetActivityIdRes(0, response.fields[0].Id)) : (new GetActivityIdRes(1, new UnableToGetActivityException())));
    }));
}

export function getNextCardioActivity(context, activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getNext(activityId)), (_arg1) => singleton.ReturnFrom(processGetActivityResponse(context, activityId, _arg1))));
}

export function getPreviousCardioActivity(context, activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.getPrevious(activityId)), (_arg1) => singleton.ReturnFrom(processGetActivityResponse(context, activityId, _arg1))));
}

export function getCardioActivity(context, activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.get(activityId)), (_arg1) => singleton.ReturnFrom(processGetActivityResponse(context, activityId, _arg1))));
}

export function deleteCardioActivity(activityId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.delete(activityId)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new DeleteCardioActivityRes(0)) : (new DeleteCardioActivityRes(1, new UnableToDeleteActivityException())))));
}

export function updateCategory(activityId, categoryId) {
    return singleton.Delay(() => singleton.Bind(cardioActivity((api) => api.updateCategory(activityId, categoryId)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new UpdateCategoryRes(0)) : (new UpdateCategoryRes(1, new UnableToUpdateCategoryException())))));
}

