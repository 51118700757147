import * as react from "react";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { ProgrammePage, Page, href } from "../../Router.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";

export function root(context, model, dispatch) {
    let arg10;
    if (model.IsLoading) {
        return react.createElement("div", {}, "Loading");
    }
    else {
        return react.createElement("div", {}, react.createElement("h1", {}, "Product View"), react.createElement("p", {}, react.createElement("a", keyValueList([href(new Page(10, new ProgrammePage(0)))], 1), "Back")), react.createElement("table", {}, react.createElement("tbody", {}, react.createElement("tr", {}, react.createElement("th", {}, "Id"), react.createElement("td", {}, (arg10 = Id_value(model.Item.Id), toText(printf("%s"))(arg10)))), react.createElement("tr", {}, react.createElement("th", {}, "Value"), react.createElement("td", {}, model.Item.AProperty)))));
    }
}

