import { GetComparisonRideDataRes, Model, Msg, Model_Empty_Z7898A6E4 } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { getAnalysis } from "./Api.fs.js";

export function init(cartItems) {
    return [Model_Empty_Z7898A6E4(cartItems), Cmd_OfFunc_result(new Msg(0))];
}

export function update(context, msg, model) {
    let activityIds;
    if (msg.tag === 1) {
        const result = msg.fields[0];
        if (result.tag === 1) {
            console.error(some(result.fields[0]));
            return [new Model(model.CartItems, model.Summaries, false, model.InsufficientCartItems, model.PeakPerformance), Cmd_none()];
        }
        else {
            return [new Model(model.CartItems, result.fields[0], false, model.InsufficientCartItems, result.fields[1]), Cmd_none()];
        }
    }
    else if (model.CartItems.length < 2) {
        return [new Model(model.CartItems, model.Summaries, false, true, model.PeakPerformance), Cmd_none()];
    }
    else {
        return [new Model(model.CartItems, model.Summaries, true, false, model.PeakPerformance), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, (activityIds = ofSeq(map((c) => c.ActivityId, model.CartItems)), (context_1) => getAnalysis(activityIds, context_1)), context, (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetComparisonRideDataRes(1, arg))))];
    }
}

