import { Union, Record } from "./.fable/fable-library.3.0.0/Types.js";
import { bool_type, option_type, tuple_type, array_type, list_type, class_type, union_type, lambda_type, int32_type, record_type, string_type, float64_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { GraphValueTransformer$reflection } from "./GraphShared.fs.js";
import { isEmpty, head, singleton, append, delay, mapIndexed, minBy, maxBy } from "./.fable/fable-library.3.0.0/Seq.js";
import { uncurry, min, comparePrimitives } from "./.fable/fable-library.3.0.0/Util.js";
import { join, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { singleton as singleton_1, ofArray, ofSeq, empty } from "./.fable/fable-library.3.0.0/List.js";
import * as react from "react";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { attachWindowEventWithDisposer } from "./BrowserHelpers.fs.js";
import { some } from "./.fable/fable-library.3.0.0/Option.js";

export class GraphDataPoint extends Record {
    constructor(Value, Label) {
        super();
        this.Value = Value;
        this.Label = Label;
    }
}

export function GraphDataPoint$reflection() {
    return record_type("CartesianGraph.GraphDataPoint", [], GraphDataPoint, () => [["Value", float64_type], ["Label", string_type]]);
}

export class GraphColor extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ConstantColor", "CalculatedColor"];
    }
}

export function GraphColor$reflection() {
    return union_type("CartesianGraph.GraphColor", [], GraphColor, () => [[["Item", string_type]], [["Item", lambda_type(int32_type, lambda_type(float64_type, string_type))]]]);
}

export class GraphAnnotationHelpers extends Record {
    constructor(CalcX, CalcY, Width, Height) {
        super();
        this.CalcX = CalcX;
        this.CalcY = CalcY;
        this.Width = (Width | 0);
        this.Height = (Height | 0);
    }
}

export function GraphAnnotationHelpers$reflection() {
    return record_type("CartesianGraph.GraphAnnotationHelpers", [], GraphAnnotationHelpers, () => [["CalcX", lambda_type(int32_type, int32_type)], ["CalcY", lambda_type(float64_type, int32_type)], ["Width", int32_type], ["Height", int32_type]]);
}

export class GraphAnnotation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["NoAnnotation", "AnnotateWith"];
    }
}

export function GraphAnnotation$reflection() {
    return union_type("CartesianGraph.GraphAnnotation", [], GraphAnnotation, () => [[], [["Item", lambda_type(GraphAnnotationHelpers$reflection(), list_type(class_type("Fable.React.ReactElement")))]]]);
}

export class XAxisConfiguration extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["XAxisLabels", "XAxisStartEndLabels"];
    }
}

export function XAxisConfiguration$reflection() {
    return union_type("CartesianGraph.XAxisConfiguration", [], XAxisConfiguration, () => [[], [["Item1", string_type], ["Item2", string_type]]]);
}

export class IBarGraphProps extends Record {
    constructor(Values, Color, LegendTextColor, Annotation, ValueRange, ShowValues, ShowYAxis, XAxis, ValueTransformer) {
        super();
        this.Values = Values;
        this.Color = Color;
        this.LegendTextColor = LegendTextColor;
        this.Annotation = Annotation;
        this.ValueRange = ValueRange;
        this.ShowValues = ShowValues;
        this.ShowYAxis = ShowYAxis;
        this.XAxis = XAxis;
        this.ValueTransformer = ValueTransformer;
    }
}

export function IBarGraphProps$reflection() {
    return record_type("CartesianGraph.IBarGraphProps", [], IBarGraphProps, () => [["Values", array_type(GraphDataPoint$reflection())], ["Color", GraphColor$reflection()], ["LegendTextColor", string_type], ["Annotation", GraphAnnotation$reflection()], ["ValueRange", option_type(lambda_type(tuple_type(float64_type, float64_type), tuple_type(float64_type, float64_type)))], ["ShowValues", bool_type], ["ShowYAxis", bool_type], ["XAxis", XAxisConfiguration$reflection()], ["ValueTransformer", GraphValueTransformer$reflection()]]);
}

const xAxisLabelVerticalSpace = 2;

const defaultTextHeight = 10;

const defaultTextWidth = 10;

function getColor(props, dataPointIndex, dataPoint) {
    const matchValue = props.Color;
    if (matchValue.tag === 1) {
        return matchValue.fields[0](dataPointIndex, dataPoint.Value);
    }
    else {
        return matchValue.fields[0];
    }
}

function calculateConstraints(props, width, height, dataPoints, clipRange) {
    const spaceForValueText = (props.ShowValues ? defaultTextHeight : 0) | 0;
    const heightAllowingForLegend = ((((height - defaultTextHeight) - (~(~(defaultTextHeight / 2)))) - spaceForValueText) - xAxisLabelVerticalSpace) | 0;
    const dataPointMaxValue = maxBy((ldp) => ldp.Value, dataPoints, {
        Compare: comparePrimitives,
    }).Value;
    const dataPointMinValue = minBy((ldp_1) => ldp_1.Value, dataPoints, {
        Compare: comparePrimitives,
    }).Value;
    let patternInput;
    const matchValue = props.ValueRange;
    patternInput = ((matchValue != null) ? matchValue([dataPointMinValue, dataPointMaxValue]) : [0, dataPointMaxValue]);
    const minValue = patternInput[0];
    const maxValue = patternInput[1];
    let percentage;
    const rangeValue = maxValue - minValue;
    percentage = ((value) => ((value - minValue) / rangeValue));
    const calcY = (value_1) => ((((heightAllowingForLegend - (~(~(percentage(value_1) * heightAllowingForLegend)))) + (~(~(defaultTextHeight / 2)))) + spaceForValueText) - xAxisLabelVerticalSpace);
    const leftAxisWidth = (props.ShowYAxis ? (toText(printf("%.0f"))(maxValue).length * defaultTextWidth) : 0) | 0;
    const xPixelScale = (~(~Math.floor((width - leftAxisWidth) / dataPoints.length))) | 0;
    return [(dataPointIndex) => ((dataPointIndex * xPixelScale) + leftAxisWidth), calcY, (value_6) => {
        const calcedTop = calcY(value_6) | 0;
        return (calcY(minValue) - calcedTop) | 0;
    }, percentage, xPixelScale, maxValue, minValue, leftAxisWidth, heightAllowingForLegend];
}

function renderBarGraph(width, height, props) {
    let matchValue_5;
    const dataPoints = props.Values;
    const patternInput = calculateConstraints(props, width, height, dataPoints, false);
    const xPixelScale = patternInput[4] | 0;
    const minValue = patternInput[6];
    const maxValue = patternInput[5];
    const calcY = patternInput[1];
    const calcX = patternInput[0];
    const viewBoxString = toText(printf("0 0 %d %d"))(width)(height);
    let annotationSvg;
    const matchValue = props.Annotation;
    annotationSvg = ((matchValue.tag === 1) ? matchValue.fields[0](new GraphAnnotationHelpers(calcX, calcY, width, height)) : empty());
    let valueOutput;
    const matchValue_1 = props.ValueTransformer;
    valueOutput = ((matchValue_1.tag === 1) ? matchValue_1.fields[0] : ((v) => toText(printf("%.0f"))(v)));
    const strokeHalf = (~(~(2 / 2))) | 0;
    const dataPointSvg = mapIndexed((dpi, dp) => {
        const top = calcY(dp.Value) | 0;
        const heightForBar = patternInput[2](dp.Value) | 0;
        const bBottom = calcY(minValue) | 0;
        const bLeft = calcX(dpi) | 0;
        const bWidth = (xPixelScale - 2) | 0;
        const qValue = min(comparePrimitives, heightForBar, 5) | 0;
        const topleft = toText(printf("q0,-%d %d,-%d"))(qValue)(qValue)(qValue);
        const topRight = toText(printf("q%d,0 %d,%d"))(qValue)(qValue)(qValue);
        return react.createElement("g", {}, ...ofSeq(delay(() => {
            let matchValue_2, arg70, arg50, arg30_2;
            return append((matchValue_2 = (heightForBar <= 2), matchValue_2 ? singleton(react.createElement("line", {
                x1: bLeft,
                y1: bBottom - 1,
                x2: bLeft + bWidth,
                y2: bBottom - 1,
                stroke: getColor(props, dpi, dp),
            })) : singleton(react.createElement(react.Fragment, {}, react.createElement("path", {
                fill: getColor(props, dpi, dp),
                fillOpacity: 0.8,
                d: (arg70 = ((heightForBar - qValue) | 0), (arg50 = ((bWidth - (qValue * 2)) | 0), (arg30_2 = ((heightForBar - qValue) | 0), toText(printf("M%d,%d v-%d %s h%d %s v%d h-%d z"))(bLeft)(bBottom)(arg30_2)(topleft)(arg50)(topRight)(arg70)(bWidth)))),
            })))), delay(() => append((props.XAxis.tag === 0) ? singleton(react.createElement("text", {
                x: calcX(dpi) + (~(~((xPixelScale - 2) / 2))),
                y: height - xAxisLabelVerticalSpace,
                fill: props.LegendTextColor,
                textAnchor: "middle",
            }, dp.Label)) : singleton(react.createElement(react.Fragment, {})), delay(() => (props.ShowValues ? singleton(react.createElement("text", {
                x: calcX(dpi) + (~(~((xPixelScale - 2) / 2))),
                y: (top - xAxisLabelVerticalSpace) - 1,
                fill: props.LegendTextColor,
                textAnchor: "middle",
                dominantBaseline: "baseline",
            }, valueOutput(dp.Value))) : singleton(react.createElement(react.Fragment, {})))))));
        })));
    }, dataPoints);
    return [react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        className: "text-xs",
    }, ...append((matchValue_5 = props.XAxis, (matchValue_5.tag === 1) ? ofArray([react.createElement("text", {
        x: calcX(0),
        y: height - xAxisLabelVerticalSpace,
        fill: props.LegendTextColor,
        textAnchor: "start",
        className: "italic",
    }, matchValue_5.fields[0]), react.createElement("text", {
        x: calcX(dataPoints.length),
        y: height - xAxisLabelVerticalSpace,
        fill: props.LegendTextColor,
        textAnchor: "end",
        className: "italic",
    }, matchValue_5.fields[1])]) : singleton_1(react.createElement(react.Fragment, {}))), append(props.ShowYAxis ? ofArray([react.createElement("text", {
        x: calcX(0) - 3,
        y: height - defaultTextHeight,
        fill: props.LegendTextColor,
        textAnchor: "end",
        alignmentBaseline: "bottom",
    }, valueOutput(minValue)), react.createElement("text", {
        x: calcX(0) - 3,
        y: calcY(maxValue),
        fill: props.LegendTextColor,
        textAnchor: "end",
        alignmentBaseline: "central",
    }, valueOutput(maxValue)), react.createElement("text", {
        x: calcX(0) - 3,
        y: calcY(maxValue / 2),
        fill: props.LegendTextColor,
        textAnchor: "end",
        alignmentBaseline: "central",
    }, valueOutput(minValue + ((maxValue - minValue) / 2)))]) : empty(), append(dataPointSvg, annotationSvg)))), minValue, maxValue];
}

function renderLineGraph(width, height, props) {
    const dataPoints = props.Values;
    const patternInput = calculateConstraints(props, width, height, dataPoints, false);
    const viewBoxString = toText(printf("0 0 %d %d"))(width)(height);
    const fullPath = join("", mapIndexed((i, dp) => {
        const operation = (i === 0) ? "M" : "L";
        const lineHeightValue = patternInput[1](dp.Value) | 0;
        const px = (i * patternInput[4]) | 0;
        return toText(printf("%s%d %d "))(operation)(px)(lineHeightValue);
    }, dataPoints));
    return [react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        height: height,
    }, react.createElement("path", {
        d: fullPath,
        fill: "transparent",
        stroke: getColor(props, 0, head(dataPoints)),
    })), patternInput[6], patternInput[5]];
}

export function graph(renderer) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        let matchValue_1, patternInput;
        const containerWidth = react.useState(0);
        const containerHeight = react.useState(0);
        const containerRef = react.useRef(void 0);
        react.useEffect(() => {
        const disp = (() => {
            let timeoutId = void 0;
            const setWidth = () => {
                const matchValue = containerRef.current;
                if (matchValue == null) {
                }
                else {
                    const element = matchValue;
                    const width = (~(~element.clientWidth)) | 0;
                    const height = (~(~element.clientHeight)) | 0;
                    if (timeoutId == null) {
                    }
                    else {
                        window.clearTimeout(timeoutId);
                    }
                    timeoutId = window.setTimeout((_arg1) => {
                        timeoutId = (void 0);
                        containerWidth[1](((s) => width));
                        containerHeight[1](((s_1) => height));
                    }, 150, empty());
                }
            };
            setWidth();
            return attachWindowEventWithDisposer((_arg2) => {
                setWidth();
            }, window, "resize", () => {
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
            });
        })();
        return () => disp.Dispose();
        }, []);
        return react.createElement("div", {
            className: "h-full",
            ref: containerRef,
        }, (matchValue_1 = containerRef.current, (matchValue_1 == null) ? [react.createElement(react.Fragment, {}), void 0, void 0] : (isEmpty(props.Values) ? [react.createElement(react.Fragment, {}), void 0, void 0] : ((((containerWidth[0]) === 0) ? true : ((containerHeight[0]) === 0)) ? [react.createElement(react.Fragment, {}), void 0, void 0] : (patternInput = renderer(containerWidth[0], containerHeight[0], props), [patternInput[0], some(patternInput[1]), some(patternInput[2])]))))[0]);
    }, void 0, uncurry(2, void 0), void 0, "graph", "/home/runner/work/strengthPlus/strengthPlus/client/src/CartesianGraph.fs", 241);
}

export const line = graph(renderLineGraph);

export const bar = graph(renderBarGraph);

