import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { AnalysisPage, AnalysisPage$reflection } from "../../Router.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "../CompareRides/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "../PeakPerformances/Types.fs.js";
import { Msg$reflection as Msg$reflection_3, Model$reflection as Model$reflection_3 } from "../PeakHistory/Types.fs.js";
import { Msg$reflection as Msg$reflection_4, Model$reflection as Model$reflection_4 } from "../Competitiveness/Types.fs.js";
import { Msg$reflection as Msg$reflection_5, Model$reflection as Model$reflection_5 } from "../MetricHistory/Types.fs.js";
import { Msg$reflection as Msg$reflection_6, Model$reflection as Model$reflection_6 } from "../Fitness/Types.fs.js";
import { Msg$reflection as Msg$reflection_7, Model$reflection as Model$reflection_7 } from "../RollingAggregates/Types.fs.js";

export class Model extends Record {
    constructor(CurrentPage, IndexModel, PeakPerformancesModel, PeakHistoryModel, CompetitivenessModel, MetricHistoryModel, FitnessModel, RollingAggregatesModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.IndexModel = IndexModel;
        this.PeakPerformancesModel = PeakPerformancesModel;
        this.PeakHistoryModel = PeakHistoryModel;
        this.CompetitivenessModel = CompetitivenessModel;
        this.MetricHistoryModel = MetricHistoryModel;
        this.FitnessModel = FitnessModel;
        this.RollingAggregatesModel = RollingAggregatesModel;
    }
}

export function Model$reflection() {
    return record_type("Analysis.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", AnalysisPage$reflection()], ["IndexModel", option_type(Model$reflection_1())], ["PeakPerformancesModel", option_type(Model$reflection_2())], ["PeakHistoryModel", option_type(Model$reflection_3())], ["CompetitivenessModel", option_type(Model$reflection_4())], ["MetricHistoryModel", option_type(Model$reflection_5())], ["FitnessModel", option_type(Model$reflection_6())], ["RollingAggregatesModel", option_type(Model$reflection_7())]]);
}

export function Model_get_Empty() {
    return new Model(new AnalysisPage(2), void 0, void 0, void 0, void 0, void 0, void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IndexMsg", "PeakPerformancesMsg", "PeakHistoryMsg", "CompetitivenessMsg", "MetricHistoryMsg", "FitnessMsg", "RollingAggregatesMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.Dispatcher.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [["Item", Msg$reflection_7()]]]);
}

