import { DeleteRes, GetIntervalProgrammeRes, Model, Msg, Model_Empty_1505 } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { ProgrammeId_CreateFromString_Z721C83C5 } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { successToast, errorToast } from "../../Notifications.fs.js";
import { PowerProgrammePage_FromIndexDefault, Page, modifyLocation } from "../../Router.fs.js";
import { delete$, getIntervalProgramme } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(programmeIdString) {
    return [Model_Empty_1505((x) => x), Cmd_OfFunc_result(new Msg(5, ProgrammeId_CreateFromString_Z721C83C5(programmeIdString)))];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 4: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.IsLoading, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, false, false), errorToast("Unable to delete workout")];
            }
            else {
                modifyLocation(new Page(0, PowerProgrammePage_FromIndexDefault()));
                return [model, successToast("Workout deleted")];
            }
        }
        case 1: {
            return [new Model(model.IsLoading, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, true, model.IsDeleting), Cmd_none()];
        }
        case 2: {
            return [new Model(model.IsLoading, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, false, model.IsDeleting), Cmd_none()];
        }
        case 0: {
            return [new Model(model.IsLoading, model.Programme, model.Summary, !model.UseFtp, model.UserPowerZones, model.ShowDeleteModal, model.IsDeleting), Cmd_none()];
        }
        case 5: {
            return [new Model(true, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, model.ShowDeleteModal, model.IsDeleting), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, getIntervalProgramme, msg.fields[0], (arg0_4) => (new Msg(6, arg0_4)), (arg_2) => (new Msg(6, new GetIntervalProgrammeRes(1, arg_2))))];
        }
        case 6: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 1) {
                console.error(some(result_1.fields[0]));
                return [new Model(false, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, model.ShowDeleteModal, model.IsDeleting), Cmd_none()];
            }
            else {
                return [new Model(false, result_1.fields[0], result_1.fields[1], true, result_1.fields[2], model.ShowDeleteModal, model.IsDeleting), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.Programme, model.Summary, model.UseFtp, model.UserPowerZones, model.ShowDeleteModal, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, delete$, msg.fields[0], (arg0) => (new Msg(4, arg0)), (arg) => (new Msg(4, new DeleteRes(1, arg))))];
        }
    }
}

