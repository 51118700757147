import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Id__Id$reflection } from "../../../../shared/Shared.Types.fs.js";
import { union_type, class_type, bool_type, record_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Item extends Record {
    constructor(Id, AProperty) {
        super();
        this.Id = Id;
        this.AProperty = AProperty;
    }
}

export function Item$reflection() {
    return record_type("Programme.Update.Types.Item", [], Item, () => [["Id", Id__Id$reflection()], ["AProperty", string_type]]);
}

export class Model extends Record {
    constructor(IsLoading, IsSaving, Item) {
        super();
        this.IsLoading = IsLoading;
        this.IsSaving = IsSaving;
        this.Item = Item;
    }
}

export function Model$reflection() {
    return record_type("Programme.Update.Types.Model", [], Model, () => [["IsLoading", bool_type], ["IsSaving", bool_type], ["Item", Item$reflection()]]);
}

export function Model_Empty_Z191E29AA(id) {
    return new Model(true, false, new Item(id, ""));
}

export class GetProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetProgrammeRes$reflection() {
    return union_type("Programme.Update.Types.GetProgrammeRes", [], GetProgrammeRes, () => [[["Item", Item$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class SubmitProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitProgrammeRes$reflection() {
    return union_type("Programme.Update.Types.SubmitProgrammeRes", [], SubmitProgrammeRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetProgramme", "GetProgrammeResult", "ChangeAProperty", "SubmitProgramme", "SubmitProgrammeResult"];
    }
}

export function Msg$reflection() {
    return union_type("Programme.Update.Types.Msg", [], Msg, () => [[["Item", Id__Id$reflection()]], [["Item", GetProgrammeRes$reflection()]], [["Item", string_type]], [], [["Item", SubmitProgrammeRes$reflection()]]]);
}

