import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Model extends Record {
    constructor(IsSaving, SignupToNewsletter) {
        super();
        this.IsSaving = IsSaving;
        this.SignupToNewsletter = SignupToNewsletter;
    }
}

export function Model$reflection() {
    return record_type("Welcome.Show.Types.Model", [], Model, () => [["IsSaving", bool_type], ["SignupToNewsletter", bool_type]]);
}

export function Model_Empty_1505(id) {
    return new Model(false, false);
}

export class RemoveNewUserStatusRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function RemoveNewUserStatusRes$reflection() {
    return union_type("Welcome.Show.Types.RemoveNewUserStatusRes", [], RemoveNewUserStatusRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MoveOn", "ToggleNewsLetter", "RemoveNewUserStatus", "RemoveNewUserStatusResult"];
    }
}

export function Msg$reflection() {
    return union_type("Welcome.Show.Types.Msg", [], Msg, () => [[], [], [], [["Item", RemoveNewUserStatusRes$reflection()]]]);
}

