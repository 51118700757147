import { skip, filter, append, fold, head, sortWith, isEmpty, rangeNumber, delay, tryFind, reverse, length, sum, average, map } from "../../.fable/fable-library.3.0.0/Seq.js";
import { GetDashboardsRes, PlanWorkoutTodayRes, Model, Msg, Model_get_Empty, numberOfAggregateWeeks, AggregateMetric } from "./Types.fs.js";
import { groupBy } from "../../.fable/fable-library.3.0.0/Map.js";
import { System_DateTime__DateTime_WeekBeginningMonday } from "../../../../shared/Shared.fs.js";
import { compare, addDays, today as today_1, equals, date } from "../../.fable/fable-library.3.0.0/Date.js";
import { uncurry, dateHash } from "../../.fable/fable-library.3.0.0/Util.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { getDashboards, planWorkoutToday } from "./Api.fs.js";
import { errorToast, successToast } from "../../Notifications.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

function aggregateMetrics(metrics) {
    return Array.from(map((tupledArg) => {
        const metrics_1 = tupledArg[1];
        return new AggregateMetric(tupledArg[0], average(map((m_1) => m_1.RecordedValue, metrics_1), {
            GetZero: () => 0,
            Add: (x_2, y_1) => (x_2 + y_1),
            DivideByInt: (x_1, i) => (x_1 / i),
        }), sum(map((m_2) => m_2.RecordedValue, metrics_1), {
            GetZero: () => 0,
            Add: (x_3, y_2) => (x_3 + y_2),
        }), length(metrics_1));
    }, Array.from(groupBy((m) => System_DateTime__DateTime_WeekBeginningMonday(date(m.RecordedAtUtc)), metrics, {
        Equals: equals,
        GetHashCode: dateHash,
    }))));
}

function emptyAggregate(weekBeginning) {
    return new AggregateMetric(weekBeginning, 0, 0, 0);
}

function zeroPad(aggregates) {
    return reverse(map((w_1) => {
        const matchValue = tryFind((a) => equals(a.WeekBeginning, w_1), aggregates);
        if (matchValue == null) {
            return new AggregateMetric(w_1, 0, 0, 0);
        }
        else {
            return matchValue;
        }
    }, delay(() => map((w) => {
        let copyOfStruct = System_DateTime__DateTime_WeekBeginningMonday(today_1());
        return addDays(copyOfStruct, -7 * w);
    }, rangeNumber(0, 1, 7)))));
}

function pad(aggregates) {
    let projection_1, arg, projection;
    const last8weeks = Array.from(reverse(delay(() => map((w) => {
        let copyOfStruct = System_DateTime__DateTime_WeekBeginningMonday(today_1());
        return addDays(copyOfStruct, -7 * w);
    }, rangeNumber(0, 1, numberOfAggregateWeeks - 1)))));
    if (isEmpty(aggregates)) {
        return Array.from(map(emptyAggregate, last8weeks));
    }
    else {
        return Array.from((projection_1 = ((p) => p.WeekBeginning), sortWith((x_1, y_1) => compare(projection_1(x_1), projection_1(y_1)), (arg = [head(Array.from((projection = ((a) => a.WeekBeginning), sortWith((x, y) => compare(projection(x), projection(y)), aggregates)))), []], fold(uncurry(2, (tupledArg) => {
            const currentPad = tupledArg[0];
            const resultList = tupledArg[1];
            return (w_1) => {
                const matchValue_1 = tryFind((a_1) => equals(a_1.WeekBeginning, w_1), aggregates);
                if (matchValue_1 == null) {
                    return [currentPad, append(resultList, [new AggregateMetric(w_1, currentPad.Average, currentPad.Sum, currentPad.Count)])];
                }
                else {
                    const aggregate = matchValue_1;
                    return [aggregate, append(resultList, [aggregate])];
                }
            };
        }), [arg[0], arg[1]], last8weeks))[1])));
    }
}

export function calculateAggregates(metrics) {
    return pad(aggregateMetrics(metrics));
}

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(2))];
}

export function update(context, msg, model) {
    let RecentRestingHeartRate, AggregateWeight, RecentHeartPeaks;
    switch (msg.tag) {
        case 5: {
            return [new Model(model.RecentWeight, model.RecentRestingHeartRate, model.IsLoading, model.IsSaving, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, false), Cmd_none()];
        }
        case 0: {
            return [new Model(model.RecentWeight, model.RecentRestingHeartRate, model.IsLoading, true, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, model.ShowWorkoutChooser), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => planWorkoutToday(msg.fields[0], context_1), context, (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new PlanWorkoutTodayRes(1, arg))))];
        }
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 0) {
                return [new Model(model.RecentWeight, model.RecentRestingHeartRate, model.IsLoading, false, model.AggregateWeight, Array.from(result.fields[0]), model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, false), successToast("Workout planned")];
            }
            else {
                return [new Model(model.RecentWeight, model.RecentRestingHeartRate, model.IsLoading, false, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, false), errorToast("Error planning workout")];
            }
        }
        case 2: {
            return [new Model(model.RecentWeight, model.RecentRestingHeartRate, true, model.IsSaving, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, model.ShowWorkoutChooser), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, getDashboards, context, (arg0_3) => (new Msg(3, arg0_3)), (arg_2) => (new Msg(3, new GetDashboardsRes(1, arg_2))))];
        }
        case 3: {
            const result_1 = msg.fields[0];
            if (result_1.tag === 1) {
                console.error(some(result_1.fields[0]));
                return [new Model(model.RecentWeight, model.RecentRestingHeartRate, false, model.IsSaving, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, model.ShowWorkoutChooser), Cmd_none()];
            }
            else {
                const dashboard = result_1.fields[0];
                return [(RecentRestingHeartRate = (new Array(0)), (AggregateWeight = Array.from(calculateAggregates(dashboard.Weight)), (RecentHeartPeaks = Array.from(filter((p) => {
                    if (p.PeakType.tag === 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, dashboard.RecentMaxPeaks)), new Model(dashboard.Weight, RecentRestingHeartRate, false, model.IsSaving, AggregateWeight, dashboard.Today, dashboard.PowerZones, dashboard.HeartRateZones, dashboard.RecentWorkouts, Array.from(filter((p_1) => {
                    if (p_1.PeakType.tag === 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, dashboard.RecentMaxPeaks)), RecentHeartPeaks, Array.from(skip(dashboard.RecentDistances.length - 8, dashboard.RecentDistances)), dashboard.TrainingLoad, dashboard.TrainsWithPower, model.ShowWorkoutChooser)))), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.RecentWeight, model.RecentRestingHeartRate, model.IsLoading, model.IsSaving, model.AggregateWeight, model.Today, model.UserPowerZones, model.HeartRateZones, model.RecentWorkouts, model.RecentPowerPeaks, model.RecentHeartPeaks, model.RecentDistances, model.TrainingLoad, model.TrainsWithPower, true), Cmd_none()];
        }
    }
}

