import { ofArray, ofSeq, empty, singleton, length } from "../../.fable/fable-library.3.0.0/List.js";
import { href, ProgrammePage, Page, modifyLocation } from "../../Router.fs.js";
import * as react from "react";
import { panel, HeaderButton, page } from "../../Layout.fs.js";
import { map, singleton as singleton_1, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { summary } from "../../Grids.fs.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { Description_value, Name_value, Id_value } from "../../../../shared/Shared.Types.fs.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { primary } from "../../Buttons.fs.js";

export function root(context, model, dispatch) {
    const haveProgrammes = length(model.Items) > 0;
    const createNewProgram = () => {
        modifyLocation(new Page(10, new ProgrammePage(2)));
    };
    return react.createElement("div", {}, page("Programmes", model.IsLoading, haveProgrammes ? singleton(new HeaderButton(0, "+ New", createNewProgram, false)) : empty(), ofSeq(delay(() => (haveProgrammes ? singleton_1(summary(true, true, true, ofArray(["", "Name", "Description"]), map((r) => ofArray([react.createElement("a", keyValueList([href(new Page(10, new ProgrammePage(3, Id_value(r.Id)))), new HTMLAttr(64, "text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline")], 1), "Edit"), Name_value(r.Name), Description_value(r.Description)]), model.Items))) : singleton_1(panel([react.createElement("div", {}, react.createElement("p", {}, "You don\u0027t have any workout programmes, get started by creating one."), react.createElement("div", {
        className: "text-center mt-4",
    }, primary("Create your first programme", createNewProgram, false)))])))))));
}

