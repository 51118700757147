import { GetCardioActivityRes, GetActivityIdRes, UpdateCategoryRes, Item as Item_3, DeleteCardioActivityRes, Model, Msg, Model_Empty_1505 } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { getCardioActivity, getPreviousActivityId, getNextActivityId, updateCategory, deleteCardioActivity } from "./Api.fs.js";
import { warningToast, successToast, errorToast } from "../../Notifications.fs.js";
import { CardioActivityPage, DashboardPage, Page, modifyLocation } from "../../Router.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { CardioSummary } from "../../../../shared/Shared.Models.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(id) {
    return [Model_Empty_1505(id), Cmd_OfFunc_result(new Msg(0, id))];
}

export function update(context, msg, model) {
    let inputRecord, inputRecord_1;
    switch (msg.tag) {
        case 4: {
            return [new Model(model.IsLoading, true, model.IsDeleting, model.Item), Cmd_none()];
        }
        case 5: {
            return [new Model(model.IsLoading, false, model.IsDeleting, model.Item), Cmd_none()];
        }
        case 6: {
            return [new Model(model.IsLoading, model.IsDeleteModalShowing, true, model.Item), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, deleteCardioActivity, msg.fields[0], (arg0_3) => (new Msg(7, arg0_3)), (arg_2) => (new Msg(7, new DeleteCardioActivityRes(1, arg_2))))];
        }
        case 7: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.IsLoading, model.IsDeleteModalShowing, false, model.Item), errorToast("Unable to delete the activity.")];
            }
            else {
                modifyLocation(new Page(9, new DashboardPage(0)));
                return [new Model(model.IsLoading, model.IsDeleteModalShowing, false, model.Item), successToast("The activity has been deleted.")];
            }
        }
        case 8: {
            const categoryId = msg.fields[1];
            return [new Model(model.IsLoading, model.IsDeleteModalShowing, model.IsDeleting, (inputRecord = model.Item, new Item_3((inputRecord_1 = model.Item.Summary, new CardioSummary(inputRecord_1.Id, inputRecord_1.FileId, inputRecord_1.UploadedAtUtc, inputRecord_1.HasRoute, inputRecord_1.ActivityType, inputRecord_1.Metrics, Id_value(categoryId), inputRecord_1.Title, inputRecord_1.WeightAtTimeOfRideKg)), inputRecord.Laps, inputRecord.HeartPeaks, inputRecord.PowerPeaks, inputRecord.SpeedPeaks, inputRecord.AscentPeaks, inputRecord.PowerZones, inputRecord.HeartRateZones, inputRecord.TimeInZones))), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, (tupledArg) => updateCategory(tupledArg[0], tupledArg[1]), [msg.fields[0], categoryId], (arg0_7) => (new Msg(9, arg0_7)), (arg_4) => (new Msg(9, new UpdateCategoryRes(1, arg_4))))];
        }
        case 9: {
            if (msg.fields[0].tag === 1) {
                return [model, errorToast("Unable to update the activity category.")];
            }
            else {
                return [model, successToast("Category updated.")];
            }
        }
        case 3: {
            return [new Model(true, model.IsDeleteModalShowing, model.IsDeleting, model.Item), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, (activityId_4) => getNextActivityId(context, activityId_4), msg.fields[0], (arg0_10) => (new Msg(10, arg0_10)), (arg_6) => (new Msg(10, new GetActivityIdRes(1, arg_6))))];
        }
        case 2: {
            return [new Model(true, model.IsDeleteModalShowing, model.IsDeleting, model.Item), Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, (activityId_5) => getPreviousActivityId(context, activityId_5), msg.fields[0], (arg0_13) => (new Msg(10, arg0_13)), (arg_8) => (new Msg(10, new GetActivityIdRes(1, arg_8))))];
        }
        case 10: {
            const result = msg.fields[0];
            if (result.tag === 0) {
                modifyLocation(new Page(8, new CardioActivityPage(1, result.fields[0])));
            }
            return [model, Cmd_none()];
        }
        case 1: {
            const result_1 = msg.fields[0];
            switch (result_1.tag) {
                case 1: {
                    return [new Model(false, model.IsDeleteModalShowing, model.IsDeleting, model.Item), warningToast("No more activities")];
                }
                case 2: {
                    console.error(some(result_1.fields[0]));
                    return [new Model(false, model.IsDeleteModalShowing, model.IsDeleting, void 0), Cmd_none()];
                }
                default: {
                    return [new Model(false, model.IsDeleteModalShowing, model.IsDeleting, new Item_3(result_1.fields[0][0], result_1.fields[0][1], result_1.fields[0][2], result_1.fields[0][3], result_1.fields[0][4], result_1.fields[0][5], result_1.fields[0][6], result_1.fields[0][7], result_1.fields[0][8])), Cmd_none()];
                }
            }
        }
        default: {
            return [new Model(true, model.IsDeleteModalShowing, model.IsDeleting, model.Item), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (activityId) => getCardioActivity(context, activityId), msg.fields[0], (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetCardioActivityRes(2, arg))))];
        }
    }
}

