import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { visualTimeline, warning, lengthAsFriendlyString } from "../Common.fs.js";
import { singleton, append as append_1, delay, fold } from "../../.fable/fable-library.3.0.0/Seq.js";
import { append } from "../../.fable/fable-library.3.0.0/Array.js";
import * as react from "react";
import { downloadIcon, editIcon, IconSize, trashIcon } from "../../Icons.fs.js";
import { userId as userId_1, sessionId as sessionId_1 } from "../../Auth.fs.js";
import { ProgrammeId__AsString } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { getBaseUri } from "../../Server.fs.js";
import { page, panel, createHeaderLinkButton, HeaderButton, createHeaderButton, toolbarPage } from "../../Layout.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { Msg } from "./Types.fs.js";
import { PowerProgrammePage, Page, modifyLocation } from "../../Router.fs.js";
import { subtleLabel } from "../../Typography.fs.js";
import { toggleSwitch } from "../../Form.fs.js";
import { view } from "./ConfirmDeleteModal.fs.js";

export function intervalToInstruction(context, useFtp, userPowerZones, interval) {
    let powerPart;
    const matchValue = [userPowerZones, useFtp];
    let pattern_matching_result, upz;
    if (matchValue[0] != null) {
        if (matchValue[1]) {
            pattern_matching_result = 0;
            upz = matchValue[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const ftp = upz.CurrentFtp;
            const matchValue_1 = interval.Power;
            if (matchValue_1.tag === 1) {
                const arg10_1 = (matchValue_1.fields[0] / 100) * ftp;
                powerPart = toText(printf("%.0f watts"))(arg10_1);
            }
            else {
                const range = matchValue_1.fields[0];
                const arg20 = (range.Max / 100) * ftp;
                const arg10 = (range.Min / 100) * ftp;
                powerPart = toText(printf("%.0f to %.0f watts"))(arg10)(arg20);
            }
            break;
        }
        case 1: {
            const matchValue_2 = interval.Power;
            if (matchValue_2.tag === 1) {
                powerPart = toText(printf("%d%% of your FTP"))(matchValue_2.fields[0]);
            }
            else {
                const range_1 = matchValue_2.fields[0];
                powerPart = toText(printf("%d%% to %d%% of your FTP"))(range_1.Min)(range_1.Max);
            }
            break;
        }
    }
    const duration = lengthAsFriendlyString(context, interval.Length);
    return toText(printf("Hold %s for %s"))(powerPart)(duration);
}

export function toInstructions(context, useFtp, upz, programme) {
    let arg_1;
    return (arg_1 = [new Array(0), 0], fold((tupledArg, block) => {
        let matchValue_2, repeats, arg;
        const blockIndex = (tupledArg[1] + 1) | 0;
        let name_1;
        const matchValue = block.Name;
        name_1 = ((matchValue == null) ? toText(printf("Set %d"))(blockIndex) : matchValue);
        const blockLineClass = (blockIndex === 1) ? "font-bold" : "font-bold mt-2";
        return [append(tupledArg[0], [react.createElement("div", {
            className: blockLineClass,
        }, toText(printf("%d. %s"))(blockIndex)(name_1)), (matchValue_2 = block.Repeats, (matchValue_2 == null) ? react.createElement(react.Fragment, {}) : (repeats = (matchValue_2 | 0), react.createElement("div", {
            className: "",
        }, toText(printf("Repeat %d times"))(repeats)))), react.createElement("div", {}, react.createElement("ul", {
            className: "list-decimal ml-8",
            style: {
                listStyleType: "lower-alpha",
            },
        }, ...(arg = [new Array(0), 0], fold((tupledArg_1, interval) => {
            const intervalIndex = (tupledArg_1[1] + 1) | 0;
            return [append(tupledArg_1[0], [react.createElement("li", {}, intervalToInstruction(context, useFtp, upz, interval))]), intervalIndex];
        }, [arg[0], arg[1]], block.Intervals))[0]))]), blockIndex];
    }, [arg_1[0], arg_1[1]], programme.Blocks))[0];
}

export function root(context, model, dispatch) {
    const matchValue = [model.Programme, model.Summary];
    let pattern_matching_result, programme, summary;
    if (matchValue[0] != null) {
        if (matchValue[1] != null) {
            pattern_matching_result = 0;
            programme = matchValue[0];
            summary = matchValue[1];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const deleteButtonContent = react.createElement("div", {
                className: "flex flex-row justify-start items-center text-red-700",
            }, react.createElement("div", {
                className: "mr-2",
            }, trashIcon(new IconSize(3))), "Delete");
            const editButtonContent = react.createElement("div", {
                className: "flex flex-row justify-start items-center",
            }, react.createElement("div", {
                className: "mr-2",
            }, editIcon(new IconSize(3))), "Edit");
            const downloadButtonContent = react.createElement("div", {
                className: "flex flex-row justify-start items-center",
            }, react.createElement("div", {
                className: "mr-2",
            }, downloadIcon(new IconSize(3))), "Download");
            let url;
            const matchValue_1 = [sessionId_1(), userId_1()];
            let pattern_matching_result_1, sessionId, userId;
            const copyOfStruct = matchValue_1[0];
            if (copyOfStruct.tag === 0) {
                const copyOfStruct_1 = matchValue_1[1];
                if (copyOfStruct_1.tag === 0) {
                    pattern_matching_result_1 = 0;
                    sessionId = copyOfStruct.fields[0];
                    userId = copyOfStruct_1.fields[0];
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const arg40 = ProgrammeId__AsString(programme.Id);
                    const arg10 = getBaseUri();
                    url = toText(printf("%s/workout/download/%s/%s/%s/%s.fit"))(arg10)(userId)(sessionId)(arg40)(programme.Name);
                    break;
                }
                case 1: {
                    url = "";
                    break;
                }
            }
            return toolbarPage(programme.Name, void 0, model.IsLoading, false, ofSeq(delay(() => {
                let tupledArg;
                return append_1(summary.CreatedByUser ? singleton(react.createElement("div", {
                    className: "mr-3",
                }, createHeaderButton(model.IsLoading, (tupledArg = [deleteButtonContent, () => {
                    dispatch(new Msg(1));
                }, false], new HeaderButton(1, tupledArg[0], tupledArg[1], tupledArg[2]))))) : singleton(react.createElement(react.Fragment, {})), delay(() => {
                    let tupledArg_1;
                    return append_1(summary.CreatedByUser ? singleton(react.createElement("div", {
                        className: "mr-3",
                    }, createHeaderButton(model.IsLoading, (tupledArg_1 = [editButtonContent, () => {
                        modifyLocation(new Page(0, new PowerProgrammePage(3, ProgrammeId__AsString(summary.Id))));
                    }, false], new HeaderButton(1, tupledArg_1[0], tupledArg_1[1], tupledArg_1[2]))))) : singleton(react.createElement(react.Fragment, {})), delay(() => singleton(createHeaderLinkButton(model.IsLoading, downloadButtonContent, url, false))));
                }));
            })), ofSeq(delay(() => append_1(singleton(warning), delay(() => append_1((model.UserPowerZones == null) ? singleton(react.createElement(react.Fragment, {})) : singleton(react.createElement("div", {
                className: "flex flex-row justify-end items-center mb-4 -mt-2",
            }, react.createElement("div", {
                className: "mr-2",
            }, subtleLabel("Show instructions using your FTP")), toggleSwitch(model.UseFtp, () => {
                dispatch(new Msg(0));
            }))), delay(() => append_1(singleton(panel([visualTimeline({
                Blocks: programme.Blocks,
                Height: 150,
            }), react.createElement("div", {
                className: "mt-4",
            }, ...toInstructions(context, model.UseFtp, model.UserPowerZones, programme))])), delay(() => singleton(view(model, dispatch)))))))))));
        }
        case 1: {
            return page("Loading...", false, [], []);
        }
    }
}

