import { Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { update as update_1, init as init_1 } from "../CompareRides/State.fs.js";
import { Cmd_none, Cmd_map } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_3, init as init_2 } from "../PeakHistory/State.fs.js";
import { update as update_4, init as init_3 } from "../Competitiveness/State.fs.js";
import { update as update_5, init as init_4 } from "../MetricHistory/State.fs.js";
import { update as update_7, initWithAggregate } from "../RollingAggregates/State.fs.js";
import { Aggregate } from "../RollingAggregates/Types.fs.js";
import { update as update_6, init as init_5 } from "../Fitness/State.fs.js";
import { update as update_2, init as init_6 } from "../PeakPerformances/State.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(page, cartItems) {
    let model;
    const inputRecord = Model_get_Empty();
    model = (new Model(page, inputRecord.IndexModel, inputRecord.PeakPerformancesModel, inputRecord.PeakHistoryModel, inputRecord.CompetitivenessModel, inputRecord.MetricHistoryModel, inputRecord.FitnessModel, inputRecord.RollingAggregatesModel));
    switch (page.tag) {
        case 0: {
            const patternInput_1 = init_1(cartItems);
            return [new Model(model.CurrentPage, patternInput_1[0], model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_1) => (new Msg(0, arg0_1)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = init_2();
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, patternInput_2[0], model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_2) => (new Msg(2, arg0_2)), patternInput_2[1])];
        }
        case 6: {
            const patternInput_3 = init_3();
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, patternInput_3[0], model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_3[1])];
        }
        case 7: {
            const patternInput_4 = init_4();
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, patternInput_4[0], model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_4) => (new Msg(4, arg0_4)), patternInput_4[1])];
        }
        case 4: {
            const patternInput_5 = initWithAggregate(new Aggregate(0));
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, patternInput_5[0]), Cmd_map((arg0_5) => (new Msg(6, arg0_5)), patternInput_5[1])];
        }
        case 5: {
            const patternInput_6 = initWithAggregate(new Aggregate(1));
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, patternInput_6[0]), Cmd_map((arg0_6) => (new Msg(6, arg0_6)), patternInput_6[1])];
        }
        case 2:
        case 8: {
            const patternInput_7 = init_5();
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, patternInput_7[0], model.RollingAggregatesModel), Cmd_map((arg0_7) => (new Msg(5, arg0_7)), patternInput_7[1])];
        }
        default: {
            const patternInput = init_6();
            return [new Model(model.CurrentPage, model.IndexModel, patternInput[0], model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0) => (new Msg(1, arg0)), patternInput[1])];
        }
    }
}

export function update(context, msg, model) {
    const matchValue = [msg, model];
    let pattern_matching_result, extractedModel, msg_1, extractedModel_1, msg_2, extractedModel_2, msg_3, extractedModel_3, msg_4, extractedModel_4, msg_5, extractedModel_5, msg_6, extractedModel_6, msg_7;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].PeakPerformancesModel != null) {
            pattern_matching_result = 1;
            extractedModel_1 = matchValue[1].PeakPerformancesModel;
            msg_2 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].PeakHistoryModel != null) {
            pattern_matching_result = 2;
            extractedModel_2 = matchValue[1].PeakHistoryModel;
            msg_3 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].CompetitivenessModel != null) {
            pattern_matching_result = 3;
            extractedModel_3 = matchValue[1].CompetitivenessModel;
            msg_4 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].MetricHistoryModel != null) {
            pattern_matching_result = 4;
            extractedModel_4 = matchValue[1].MetricHistoryModel;
            msg_5 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].FitnessModel != null) {
            pattern_matching_result = 5;
            extractedModel_5 = matchValue[1].FitnessModel;
            msg_6 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].RollingAggregatesModel != null) {
            pattern_matching_result = 6;
            extractedModel_6 = matchValue[1].RollingAggregatesModel;
            msg_7 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
        }
    }
    else if (matchValue[1].IndexModel != null) {
        pattern_matching_result = 0;
        extractedModel = matchValue[1].IndexModel;
        msg_1 = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 7;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(context, msg_1, extractedModel);
            return [new Model(model.CurrentPage, patternInput[0], model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(context, msg_2, extractedModel_1);
            return [new Model(model.CurrentPage, model.IndexModel, patternInput_1[0], model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_1) => (new Msg(1, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            const patternInput_2 = update_3(context, msg_3, extractedModel_2);
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, patternInput_2[0], model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_2) => (new Msg(2, arg0_2)), patternInput_2[1])];
        }
        case 3: {
            const patternInput_3 = update_4(context, msg_4, extractedModel_3);
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, patternInput_3[0], model.MetricHistoryModel, model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_3[1])];
        }
        case 4: {
            const patternInput_4 = update_5(context, msg_5, extractedModel_4);
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, patternInput_4[0], model.FitnessModel, model.RollingAggregatesModel), Cmd_map((arg0_4) => (new Msg(4, arg0_4)), patternInput_4[1])];
        }
        case 5: {
            const patternInput_5 = update_6(context, msg_6, extractedModel_5);
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, patternInput_5[0], model.RollingAggregatesModel), Cmd_map((arg0_5) => (new Msg(5, arg0_5)), patternInput_5[1])];
        }
        case 6: {
            const patternInput_6 = update_7(context, msg_7, extractedModel_6);
            return [new Model(model.CurrentPage, model.IndexModel, model.PeakPerformancesModel, model.PeakHistoryModel, model.CompetitivenessModel, model.MetricHistoryModel, model.FitnessModel, patternInput_6[0]), Cmd_map((arg0_6) => (new Msg(6, arg0_6)), patternInput_6[1])];
        }
        case 7: {
            console.log(some("[Analysis.Dispatcher.State] Discarded message"));
            return [model, Cmd_none()];
        }
    }
}

