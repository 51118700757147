import { Union, Record } from "./.fable/fable-library.3.0.0/Types.js";
import { class_type, tuple_type, array_type, lambda_type, union_type, option_type, string_type, record_type, bool_type, int32_type, float64_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { GraphValueTransformer$reflection } from "./GraphShared.fs.js";
import { singleton, delay, skipWhile, truncate, tryHead, last, skip, fold, empty as empty_1, concat, sortWith, collect, filter, rangeNumber, map, mapIndexed, append, maxBy, minBy, isEmpty, exists } from "./.fable/fable-library.3.0.0/Seq.js";
import { uncurry, curry, numberHash, round, max, min, comparePrimitives, equalsSafe } from "./.fable/fable-library.3.0.0/Util.js";
import { join, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import * as react from "react";
import { shortDurationAsString } from "./Units.fs.js";
import { empty, ofArray, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { groupBy } from "./.fable/fable-library.3.0.0/Map.js";
import { CSSProp } from "./.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { keyValueList } from "./.fable/fable-library.3.0.0/MapUtil.js";
import { tinyContentClass, tinyLabel } from "./Typography.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { attachWindowEventWithDisposer } from "./BrowserHelpers.fs.js";

export class TimeGraphDataPoint extends Record {
    constructor(Value, TimeInSeconds, RenderPointCircle) {
        super();
        this.Value = Value;
        this.TimeInSeconds = (TimeInSeconds | 0);
        this.RenderPointCircle = RenderPointCircle;
    }
}

export function TimeGraphDataPoint$reflection() {
    return record_type("TimeGraph.TimeGraphDataPoint", [], TimeGraphDataPoint, () => [["Value", float64_type], ["TimeInSeconds", int32_type], ["RenderPointCircle", bool_type]]);
}

export class TimeGraphTick extends Record {
    constructor(TimeInSeconds, Label) {
        super();
        this.TimeInSeconds = (TimeInSeconds | 0);
        this.Label = Label;
    }
}

export function TimeGraphTick$reflection() {
    return record_type("TimeGraph.TimeGraphTick", [], TimeGraphTick, () => [["TimeInSeconds", int32_type], ["Label", option_type(string_type)]]);
}

export class TimeGraphAxis extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LeftTimeGraphAxis", "RightTimeGraphAxis"];
    }
}

export function TimeGraphAxis$reflection() {
    return union_type("TimeGraph.TimeGraphAxis", [], TimeGraphAxis, () => [[], []]);
}

export class TimeGraphAnnotationHelpers extends Record {
    constructor(CalcX, CalcY, Width, Height) {
        super();
        this.CalcX = CalcX;
        this.CalcY = CalcY;
        this.Width = (Width | 0);
        this.Height = (Height | 0);
    }
}

export function TimeGraphAnnotationHelpers$reflection() {
    return record_type("TimeGraph.TimeGraphAnnotationHelpers", [], TimeGraphAnnotationHelpers, () => [["CalcX", lambda_type(int32_type, int32_type)], ["CalcY", lambda_type(float64_type, int32_type)], ["Width", int32_type], ["Height", int32_type]]);
}

export class TimeGraphSeries extends Record {
    constructor(DataPoints, Color, BackgroundColor, Axis, DashArray, StrokeWidth, Prefix, Postfix, ShowLine) {
        super();
        this.DataPoints = DataPoints;
        this.Color = Color;
        this.BackgroundColor = BackgroundColor;
        this.Axis = Axis;
        this.DashArray = DashArray;
        this.StrokeWidth = StrokeWidth;
        this.Prefix = Prefix;
        this.Postfix = Postfix;
        this.ShowLine = ShowLine;
    }
}

export function TimeGraphSeries$reflection() {
    return record_type("TimeGraph.TimeGraphSeries", [], TimeGraphSeries, () => [["DataPoints", array_type(TimeGraphDataPoint$reflection())], ["Color", TimeGraphColor$reflection()], ["BackgroundColor", option_type(string_type)], ["Axis", TimeGraphAxis$reflection()], ["DashArray", option_type(array_type(int32_type))], ["StrokeWidth", float64_type], ["Prefix", option_type(TimeGraphDataPoint$reflection())], ["Postfix", option_type(TimeGraphDataPoint$reflection())], ["ShowLine", bool_type]]);
}

export class TimeGraphColor extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TimeGraphConstantColor"];
    }
}

export function TimeGraphColor$reflection() {
    return union_type("TimeGraph.TimeGraphColor", [], TimeGraphColor, () => [[["Item", string_type]]]);
}

export class TimeGraphMouseOverDataPoint extends Record {
    constructor(SeriesIndex, DataPointIndex, DataPoint) {
        super();
        this.SeriesIndex = (SeriesIndex | 0);
        this.DataPointIndex = (DataPointIndex | 0);
        this.DataPoint = DataPoint;
    }
}

export function TimeGraphMouseOverDataPoint$reflection() {
    return record_type("TimeGraph.TimeGraphMouseOverDataPoint", [], TimeGraphMouseOverDataPoint, () => [["SeriesIndex", int32_type], ["DataPointIndex", int32_type], ["DataPoint", TimeGraphDataPoint$reflection()]]);
}

export class ITimeGraphProps extends Record {
    constructor(Series, XAxisTicks, RightAxisValueRange, LeftAxisValueRange, XAxisValueRange, ShowYAxis, ShowXAxis, LogarithmicX, LegendTextColor, GridColor, GridLines, UseMouseOver, RightAxisColor, YAxisValueTransformer, PopoverRows, PopoverAnnotation, MouseOverPopoverRows) {
        super();
        this.Series = Series;
        this.XAxisTicks = XAxisTicks;
        this.RightAxisValueRange = RightAxisValueRange;
        this.LeftAxisValueRange = LeftAxisValueRange;
        this.XAxisValueRange = XAxisValueRange;
        this.ShowYAxis = ShowYAxis;
        this.ShowXAxis = ShowXAxis;
        this.LogarithmicX = LogarithmicX;
        this.LegendTextColor = LegendTextColor;
        this.GridColor = GridColor;
        this.GridLines = (GridLines | 0);
        this.UseMouseOver = UseMouseOver;
        this.RightAxisColor = RightAxisColor;
        this.YAxisValueTransformer = YAxisValueTransformer;
        this.PopoverRows = PopoverRows;
        this.PopoverAnnotation = PopoverAnnotation;
        this.MouseOverPopoverRows = MouseOverPopoverRows;
    }
}

export function ITimeGraphProps$reflection() {
    return record_type("TimeGraph.ITimeGraphProps", [], ITimeGraphProps, () => [["Series", array_type(TimeGraphSeries$reflection())], ["XAxisTicks", array_type(TimeGraphTick$reflection())], ["RightAxisValueRange", option_type(lambda_type(tuple_type(float64_type, float64_type), tuple_type(float64_type, float64_type)))], ["LeftAxisValueRange", option_type(lambda_type(tuple_type(float64_type, float64_type), tuple_type(float64_type, float64_type)))], ["XAxisValueRange", option_type(lambda_type(tuple_type(int32_type, int32_type), tuple_type(int32_type, int32_type)))], ["ShowYAxis", bool_type], ["ShowXAxis", bool_type], ["LogarithmicX", bool_type], ["LegendTextColor", string_type], ["GridColor", string_type], ["GridLines", int32_type], ["UseMouseOver", bool_type], ["RightAxisColor", option_type(string_type)], ["YAxisValueTransformer", GraphValueTransformer$reflection()], ["PopoverRows", lambda_type(TimeGraphSeries$reflection(), lambda_type(int32_type, lambda_type(TimeGraphDataPoint$reflection(), array_type(tuple_type(string_type, string_type)))))], ["PopoverAnnotation", option_type(lambda_type(string_type, lambda_type(int32_type, lambda_type(TimeGraphDataPoint$reflection(), class_type("Fable.React.ReactElement")))))], ["MouseOverPopoverRows", option_type(lambda_type(array_type(TimeGraphMouseOverDataPoint$reflection()), array_type(tuple_type(string_type, class_type("Fable.React.ReactElement")))))]]);
}

const xAxisLabelVerticalSpace = 2;

const defaultTextHeight = 15;

const defaultTextWidth = 10;

const tickHeight = 3;

const dataPointRadius = 3;

const clickableDataPointRadius = 2 * dataPointRadius;

export class TimeGraphAxisCalculator extends Record {
    constructor(CalcY, MinValue, MaxValue) {
        super();
        this.CalcY = CalcY;
        this.MinValue = MinValue;
        this.MaxValue = MaxValue;
    }
}

export function TimeGraphAxisCalculator$reflection() {
    return record_type("TimeGraph.TimeGraphAxisCalculator", [], TimeGraphAxisCalculator, () => [["CalcY", lambda_type(float64_type, int32_type)], ["MinValue", float64_type], ["MaxValue", float64_type]]);
}

const defaultAxisCalculator = new TimeGraphAxisCalculator((_arg1) => 0, 0, 0);

export class TimeGraphCalculators extends Record {
    constructor(CalcX, LeftAxis, RightAxis, MinTime, MaxTime) {
        super();
        this.CalcX = CalcX;
        this.LeftAxis = LeftAxis;
        this.RightAxis = RightAxis;
        this.MinTime = (MinTime | 0);
        this.MaxTime = (MaxTime | 0);
    }
}

export function TimeGraphCalculators$reflection() {
    return record_type("TimeGraph.TimeGraphCalculators", [], TimeGraphCalculators, () => [["CalcX", lambda_type(int32_type, int32_type)], ["LeftAxis", option_type(TimeGraphAxisCalculator$reflection())], ["RightAxis", option_type(TimeGraphAxisCalculator$reflection())], ["MinTime", int32_type], ["MaxTime", int32_type]]);
}

export function calculateConstraints(width, height, props, leftAxisDataPoints, rightAxisDataPoints) {
    let logBase, range;
    const heightAllowingForLegend = ((height - defaultTextHeight) - (~(~(defaultTextHeight / 2)))) | 0;
    const hasLeftAxis = exists((s) => equalsSafe(s.Axis, new TimeGraphAxis(0)), props.Series);
    const hasRightAxis = exists((s_1) => equalsSafe(s_1.Axis, new TimeGraphAxis(1)), props.Series);
    const leftDataPointMinValue = isEmpty(leftAxisDataPoints) ? 0 : minBy((ldp) => ldp.Value, leftAxisDataPoints, {
        Compare: comparePrimitives,
    }).Value;
    const leftDataPointMaxValue = isEmpty(leftAxisDataPoints) ? 0 : maxBy((ldp_1) => ldp_1.Value, leftAxisDataPoints, {
        Compare: comparePrimitives,
    }).Value;
    let patternInput;
    const matchValue_2 = props.LeftAxisValueRange;
    patternInput = ((matchValue_2 != null) ? matchValue_2([leftDataPointMinValue, leftDataPointMaxValue]) : [min(comparePrimitives, 0, leftDataPointMinValue), leftDataPointMaxValue]);
    const leftAxisMinValue = patternInput[0];
    const leftAxisMaxValue = patternInput[1];
    let leftPercentage;
    const rangeValue = leftAxisMaxValue - leftAxisMinValue;
    leftPercentage = ((value) => ((value - leftAxisMinValue) / rangeValue));
    const leftAxisWidth = ((props.ShowYAxis ? hasLeftAxis : false) ? max(comparePrimitives, toText(printf("%.0f"))(leftAxisMaxValue).length * defaultTextWidth, toText(printf("%.0f"))(leftAxisMinValue).length * defaultTextWidth) : 0) | 0;
    const rightDataPointMinValue = isEmpty(rightAxisDataPoints) ? 0 : minBy((ldp_2) => ldp_2.Value, rightAxisDataPoints, {
        Compare: comparePrimitives,
    }).Value;
    const rightDataPointMaxValue = isEmpty(rightAxisDataPoints) ? 0 : maxBy((ldp_3) => ldp_3.Value, rightAxisDataPoints, {
        Compare: comparePrimitives,
    }).Value;
    let patternInput_1;
    const matchValue_6 = props.RightAxisValueRange;
    patternInput_1 = ((matchValue_6 != null) ? matchValue_6([rightDataPointMinValue, rightDataPointMaxValue]) : [min(comparePrimitives, 0, rightDataPointMinValue), rightDataPointMaxValue]);
    const rightAxisMinValue = patternInput_1[0];
    const rightAxisMaxValue = patternInput_1[1];
    let rightPercentage;
    const rangeValue_1 = rightAxisMaxValue - rightAxisMinValue;
    rightPercentage = ((value_4) => ((value_4 - rightAxisMinValue) / rangeValue_1));
    const rightAxisWidth = ((props.ShowYAxis ? hasRightAxis : false) ? max(comparePrimitives, toText(printf("%.0f"))(rightAxisMaxValue).length * defaultTextWidth, toText(printf("%.0f"))(rightAxisMinValue).length * defaultTextWidth) : 0) | 0;
    const allDataPoints = Array.from(append(rightAxisDataPoints, leftAxisDataPoints));
    const dataPointMinTime = minBy((ldp_4) => ldp_4.TimeInSeconds, allDataPoints, {
        Compare: comparePrimitives,
    }).TimeInSeconds | 0;
    const dataPointMaxTime = maxBy((ldp_5) => ldp_5.TimeInSeconds, allDataPoints, {
        Compare: comparePrimitives,
    }).TimeInSeconds | 0;
    const xAxisWidth = ((width - (hasLeftAxis ? leftAxisWidth : 0)) - (hasRightAxis ? rightAxisWidth : 0)) | 0;
    const xAxisLeft = leftAxisWidth | 0;
    return new TimeGraphCalculators(props.LogarithmicX ? (logBase = (dataPointMaxTime - dataPointMinTime), (timeInSeconds) => {
        const valueAdjustedForMinValue = timeInSeconds - dataPointMinTime;
        return (~(~round((xAxisWidth * ((valueAdjustedForMinValue <= 0) ? 0 : (Math.log(valueAdjustedForMinValue) / Math.log(logBase)))) + xAxisLeft))) | 0;
    }) : (range = (dataPointMaxTime - dataPointMinTime), (timeInSeconds_1) => (~(~round((xAxisWidth * ((timeInSeconds_1 - dataPointMinTime) / range)) + xAxisLeft)))), hasLeftAxis ? (new TimeGraphAxisCalculator((value_1) => ((heightAllowingForLegend - (~(~(leftPercentage(value_1) * heightAllowingForLegend)))) + (~(~(defaultTextHeight / 2)))), leftAxisMinValue, leftAxisMaxValue)) : (void 0), hasRightAxis ? (new TimeGraphAxisCalculator((value_5) => ((heightAllowingForLegend - (~(~(rightPercentage(value_5) * heightAllowingForLegend)))) + (~(~(defaultTextHeight / 2)))), rightAxisMinValue, rightAxisMaxValue)) : (void 0), dataPointMinTime, dataPointMaxTime);
}

export function renderXAxis(calcX, calcY, minValue, maxValue, yMinValue, yMaxValue, width, height, props) {
    const source2_1 = mapIndexed((index, t_1) => {
        let tick, matchValue;
        return react.createElement("text", {
            x: calcX(t_1.TimeInSeconds),
            y: calcY(yMinValue) + tickHeight,
            fill: props.LegendTextColor,
            textAnchor: (index === (props.XAxisTicks.length - 1)) ? "end" : ((index === 0) ? "start" : "middle"),
            dominantBaseline: "hanging",
        }, (tick = t_1, (matchValue = tick.Label, (matchValue == null) ? shortDurationAsString(tick.TimeInSeconds) : matchValue)));
    }, props.XAxisTicks);
    return append(props.ShowXAxis ? Array.from(append([react.createElement("line", {
        x1: calcX(minValue),
        y1: calcY(yMinValue),
        x2: calcX(maxValue),
        y2: calcY(yMinValue),
        stroke: props.LegendTextColor,
    })], [])) : (new Array(0)), source2_1);
}

export function renderYAxis(calcX, calcY, minValue, maxValue, maxXValue, width, height, props, axisType) {
    let axisX, midPointText_1, matchValue_5, midPointText, matchValue_4;
    let valueOutput;
    const matchValue = props.YAxisValueTransformer;
    valueOutput = ((matchValue.tag === 1) ? matchValue.fields[0] : ((v) => toText(printf("%.0f"))(v)));
    const hasLeftAxis = exists((s) => equalsSafe(s.Axis, new TimeGraphAxis(0)), props.Series);
    const hasRightAxis = exists((s_1) => equalsSafe(s_1.Axis, new TimeGraphAxis(1)), props.Series);
    let axisColor;
    if (axisType.tag === 1) {
        const matchValue_1 = props.RightAxisColor;
        axisColor = ((matchValue_1 == null) ? props.LegendTextColor : matchValue_1);
    }
    else {
        axisColor = props.LegendTextColor;
    }
    const drawGridLines = (hasLeftAxis ? equalsSafe(axisType, new TimeGraphAxis(0)) : false) ? true : (hasRightAxis ? (!hasLeftAxis) : false);
    if (props.GridLines === 0) {
        return ofSeq(props.ShowYAxis ? ((axisType.tag === 1) ? (axisX = (calcX(maxXValue) | 0), (midPointText_1 = (matchValue_5 = [maxValue > 0, minValue < 0], matchValue_5[0] ? (matchValue_5[1] ? react.createElement("text", {
            x: calcX(maxXValue) + 3,
            y: calcY(0),
            fill: axisColor,
            textAnchor: "start",
            alignmentBaseline: "central",
        }, valueOutput(0)) : react.createElement("text", {
            x: calcX(maxXValue) + 3,
            y: calcY(minValue + ((maxValue - minValue) / 2)),
            fill: axisColor,
            textAnchor: "start",
            alignmentBaseline: "central",
        }, valueOutput(minValue + ((maxValue - minValue) / 2)))) : react.createElement("text", {
            x: calcX(maxXValue) + 3,
            y: calcY(minValue + ((maxValue - minValue) / 2)),
            fill: axisColor,
            textAnchor: "start",
            alignmentBaseline: "central",
        }, valueOutput(minValue + ((maxValue - minValue) / 2)))), ofArray([react.createElement("line", {
            x1: axisX,
            y1: calcY(maxValue),
            x2: axisX,
            y2: calcY(minValue),
            stroke: axisColor,
        }), react.createElement("text", {
            x: calcX(maxXValue) + 3,
            y: height - defaultTextHeight,
            fill: axisColor,
            textAnchor: "start",
            alignmentBaseline: "bottom",
        }, valueOutput(minValue)), react.createElement("text", {
            x: calcX(maxXValue) + 3,
            y: calcY(maxValue) - 3,
            fill: axisColor,
            textAnchor: "start",
            dominantBaseline: "hanging",
        }, valueOutput(maxValue)), midPointText_1]))) : (midPointText = (matchValue_4 = [maxValue > 0, minValue < 0], matchValue_4[0] ? (matchValue_4[1] ? react.createElement("text", {
            x: calcX(0) - 3,
            y: calcY(0),
            fill: axisColor,
            textAnchor: "end",
            alignmentBaseline: "central",
        }, valueOutput(0)) : react.createElement("text", {
            x: calcX(0) - 3,
            y: calcY(minValue + ((maxValue - minValue) / 2)),
            fill: axisColor,
            textAnchor: "end",
            alignmentBaseline: "central",
        }, valueOutput(minValue + ((maxValue - minValue) / 2)))) : react.createElement("text", {
            x: calcX(0) - 3,
            y: calcY(minValue + ((maxValue - minValue) / 2)),
            fill: axisColor,
            textAnchor: "end",
            alignmentBaseline: "central",
        }, valueOutput(minValue + ((maxValue - minValue) / 2)))), ofArray([react.createElement("line", {
            x1: calcX(0),
            y1: calcY(maxValue),
            x2: calcX(0),
            y2: calcY(minValue),
            stroke: axisColor,
        }), react.createElement("text", {
            x: calcX(0) - 3,
            y: height - defaultTextHeight,
            fill: axisColor,
            textAnchor: "end",
            alignmentBaseline: "bottom",
        }, valueOutput(minValue)), react.createElement("text", {
            x: calcX(0) - 3,
            y: calcY(maxValue) - 3,
            fill: axisColor,
            textAnchor: "end",
            dominantBaseline: "hanging",
        }, valueOutput(maxValue)), midPointText]))) : empty());
    }
    else {
        const diff = (maxValue - minValue) / props.GridLines;
        const gridLines = drawGridLines ? Array.from(map((g) => react.createElement("line", {
            x1: calcX(0),
            y1: calcY(minValue + (g * diff)),
            x2: calcX(maxXValue),
            y2: calcY(minValue + (g * diff)),
            stroke: props.GridColor,
            strokeWidth: 0.5,
        }), rangeNumber(props.ShowXAxis ? 1 : 0, 1, props.GridLines))) : [];
        const patternInput = (axisType.tag === 1) ? [calcX(maxXValue) + 3, "start"] : [calcX(0) - 3, "end"];
        return ofSeq(append(map((g_1) => {
            const yValue = minValue + (diff * g_1);
            return react.createElement("text", {
                x: patternInput[0],
                y: calcY(yValue),
                fill: axisColor,
                textAnchor: patternInput[1],
                dominantBaseline: "central",
            }, valueOutput(yValue));
        }, rangeNumber(0, 1, props.GridLines)), gridLines));
    }
}

export class TimeGraphDataPointPopover extends Record {
    constructor(Series, SeriesIndex, Color, DataPoint, X, Y) {
        super();
        this.Series = Series;
        this.SeriesIndex = (SeriesIndex | 0);
        this.Color = Color;
        this.DataPoint = DataPoint;
        this.X = X;
        this.Y = Y;
    }
}

export function TimeGraphDataPointPopover$reflection() {
    return record_type("TimeGraph.TimeGraphDataPointPopover", [], TimeGraphDataPointPopover, () => [["Series", TimeGraphSeries$reflection()], ["SeriesIndex", int32_type], ["Color", string_type], ["DataPoint", TimeGraphDataPoint$reflection()], ["X", float64_type], ["Y", float64_type]]);
}

export class InternalTypes_MouseOverPopover extends Record {
    constructor(X, Y, DataPoints) {
        super();
        this.X = X;
        this.Y = Y;
        this.DataPoints = DataPoints;
    }
}

export function InternalTypes_MouseOverPopover$reflection() {
    return record_type("TimeGraph.InternalTypes.MouseOverPopover", [], InternalTypes_MouseOverPopover, () => [["X", float64_type], ["Y", float64_type], ["DataPoints", array_type(TimeGraphMouseOverDataPoint$reflection())]]);
}

function renderer(width, height, showDataPoint, hideDataPoint, showMouseOver, hideMouseOver, props) {
    let projection_1, source2_8, graphClipX, graphClipY, graphWidth, graphHeight;
    const viewBoxString = toText(printf("0 0 %d %d"))(width)(height);
    const leftAxisSeries = Array.from(filter((s) => equalsSafe(s.Axis, new TimeGraphAxis(0)), props.Series));
    const rightAxisSeries = Array.from(filter((s_1) => equalsSafe(s_1.Axis, new TimeGraphAxis(1)), props.Series));
    const calculators = calculateConstraints(width, height, props, Array.from(collect((s_2) => s_2.DataPoints, leftAxisSeries)), Array.from(collect((s_3) => s_3.DataPoints, rightAxisSeries)));
    const calcX = calculators.CalcX;
    const xMinValue = calculators.MinTime | 0;
    const xMaxValue = calculators.MaxTime | 0;
    const distinctTimeIndexes = Array.from((projection_1 = ((g) => g[0]), sortWith((x_1, y_1) => comparePrimitives(projection_1(x_1), projection_1(y_1)), groupBy((dp_1) => dp_1.DataPoint.TimeInSeconds, Array.from(concat(mapIndexed((si, s_4) => mapIndexed((dpi, dp) => (new TimeGraphMouseOverDataPoint(si, dpi, dp)), s_4.DataPoints), props.Series))), {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    }))));
    const createCircles = (thisX, thisY, theSeries, seriesIndex, dataPoint) => {
        const color = theSeries.Color.fields[0];
        return [react.createElement("circle", {
            className: "cursor-pointer",
            cx: thisX,
            cy: thisY,
            r: dataPointRadius,
            stroke: color,
            fill: color,
            onClick: (ev) => {
                ev.stopPropagation();
                showDataPoint(new TimeGraphDataPointPopover(theSeries, seriesIndex, color, dataPoint, thisX, thisY));
            },
        }), react.createElement("circle", {
            className: "cursor-pointer",
            cx: thisX,
            cy: thisY,
            r: clickableDataPointRadius,
            stroke: "rgba(0,0,0,0)",
            fill: "rgba(0,0,0,0)",
            onClick: (ev_1) => {
                ev_1.stopPropagation();
                showDataPoint(new TimeGraphDataPointPopover(theSeries, seriesIndex, color, dataPoint, thisX, thisY));
            },
        })];
    };
    const svgLines = Array.from(concat(mapIndexed((seriesIndex_1, theSeries_1) => {
        let source2_1, matchValue_5, matchValue_6, arg40, arg30, arg20_1, arg10_1, arg20_2, arg10_2, projection_2, matchValue_9, c_1, matchValue_12, dashArrayString;
        if (isEmpty(theSeries_1.DataPoints)) {
            return empty_1();
        }
        else {
            let patternInput;
            const matchValue_4 = [theSeries_1.Axis, calculators.LeftAxis, calculators.RightAxis];
            let pattern_matching_result;
            if (matchValue_4[0].tag === 1) {
                if (matchValue_4[2] != null) {
                    pattern_matching_result = 1;
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else if (matchValue_4[1] != null) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 2;
            }
            switch (pattern_matching_result) {
                case 0: {
                    const leftAxis = matchValue_4[1];
                    patternInput = [leftAxis.CalcY, leftAxis.MinValue, leftAxis.MaxValue];
                    break;
                }
                case 1: {
                    const rightAxis = matchValue_4[2];
                    patternInput = [rightAxis.CalcY, rightAxis.MinValue, rightAxis.MaxValue];
                    break;
                }
                case 2: {
                    patternInput = [(_arg1) => 0, 0, 0];
                    break;
                }
            }
            const yMinValue = patternInput[1];
            const calcY = patternInput[0];
            const dataPoints = Array.from((source2_1 = append(theSeries_1.DataPoints, (matchValue_5 = theSeries_1.Postfix, (matchValue_5 == null) ? (new Array(0)) : [matchValue_5])), append((matchValue_6 = theSeries_1.Prefix, (matchValue_6 == null) ? (new Array(0)) : [matchValue_6]), source2_1)));
            const patternInput_1 = dataPoints[0].RenderPointCircle ? createCircles(calcX(dataPoints[0].TimeInSeconds), calcY(dataPoints[0].Value), theSeries_1, seriesIndex_1, dataPoints[0]) : [react.createElement(react.Fragment, {}), react.createElement(react.Fragment, {})];
            const patternInput_3 = fold((tupledArg, dataPoint_1) => {
                const thisX_1 = calcX(dataPoint_1.TimeInSeconds) | 0;
                const thisY_1 = calcY(dataPoint_1.Value) | 0;
                const patternInput_2 = dataPoint_1.RenderPointCircle ? createCircles(thisX_1, thisY_1, theSeries_1, seriesIndex_1, dataPoint_1) : [react.createElement(react.Fragment, {}), react.createElement(react.Fragment, {})];
                return [append(tupledArg[0], [patternInput_2[0], patternInput_2[1]]), toText(printf("%s L %d %d"))(tupledArg[1])(thisX_1)(thisY_1), toText(printf("%s L %d %d"))(tupledArg[2])(thisX_1)(thisY_1)];
            }, [[patternInput_1[0], patternInput_1[1]], (arg40 = (calcY(dataPoints[0].Value) | 0), (arg30 = (calcX(dataPoints[0].TimeInSeconds) | 0), (arg20_1 = (calcY(yMinValue) | 0), (arg10_1 = (calcX(dataPoints[0].TimeInSeconds) | 0), toText(printf("M %d %d L %d %d"))(arg10_1)(arg20_1)(arg30)(arg40))))), (arg20_2 = (calcY(dataPoints[0].Value) | 0), (arg10_2 = (calcX(dataPoints[0].TimeInSeconds) | 0), toText(printf("M %d %d"))(arg10_2)(arg20_2)))], skip(1, (projection_2 = ((dp_2) => dp_2.TimeInSeconds), sortWith((x_3, y_3) => comparePrimitives(projection_2(x_3), projection_2(y_3)), dataPoints))));
            const linePath = patternInput_3[2];
            let areaPath_1;
            const arg30_3 = calcY(yMinValue) | 0;
            const arg20_5 = calcX(last(dataPoints).TimeInSeconds) | 0;
            areaPath_1 = toText(printf("%s %d %d"))(patternInput_3[1])(arg20_5)(arg30_3);
            const seriesLineColor = theSeries_1.Color.fields[0];
            return append([(matchValue_9 = theSeries_1.BackgroundColor, (matchValue_9 == null) ? react.createElement(react.Fragment, {}) : (c_1 = matchValue_9, react.createElement("path", {
                d: areaPath_1,
                fill: c_1,
                clipPath: "url(#graphInnerClip)",
            }))), theSeries_1.ShowLine ? (matchValue_12 = theSeries_1.DashArray, (matchValue_12 == null) ? react.createElement("path", {
                d: linePath,
                fill: "transparent",
                stroke: seriesLineColor,
                strokeWidth: theSeries_1.StrokeWidth,
                clipPath: "url(#graphInnerClip)",
                strokeLinecap: "round",
            }) : (dashArrayString = join("", collect((v) => toText(printf("%d "))(v), matchValue_12)), react.createElement("path", {
                d: linePath,
                fill: "transparent",
                stroke: seriesLineColor,
                strokeWidth: theSeries_1.StrokeWidth,
                clipPath: "url(#graphInnerClip)",
                strokeLinecap: "round",
                strokeDasharray: dashArrayString,
            }))) : react.createElement(react.Fragment, {})], patternInput_3[0]);
        }
    }, Array.from(props.Series))));
    let leftAxis_1;
    const matchValue_13 = calculators.LeftAxis;
    if (matchValue_13 == null) {
        leftAxis_1 = empty();
    }
    else {
        const leftCalculators = matchValue_13;
        leftAxis_1 = renderYAxis(calcX, leftCalculators.CalcY, leftCalculators.MinValue, leftCalculators.MaxValue, xMaxValue, width, height, props, new TimeGraphAxis(0));
    }
    let rightAxis_1;
    const matchValue_14 = calculators.RightAxis;
    if (matchValue_14 == null) {
        rightAxis_1 = empty();
    }
    else {
        const rightCalculators = matchValue_14;
        rightAxis_1 = renderYAxis(calcX, rightCalculators.CalcY, rightCalculators.MinValue, rightCalculators.MaxValue, xMaxValue, width, height, props, new TimeGraphAxis(1));
    }
    let primaryAxis;
    const matchValue_15 = calculators.LeftAxis;
    if (matchValue_15 == null) {
        const matchValue_16 = calculators.RightAxis;
        primaryAxis = ((matchValue_16 == null) ? defaultAxisCalculator : matchValue_16);
    }
    else {
        primaryAxis = matchValue_15;
    }
    return react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        className: "text-xs",
        onClick: (_arg2) => {
            hideDataPoint();
        },
        onMouseLeave: (_arg3) => {
            if (props.UseMouseOver) {
                hideMouseOver();
            }
        },
        onMouseMove: (e) => {
            const boundingRect = e.currentTarget.getBoundingClientRect();
            if (props.UseMouseOver) {
                const y_2 = e.clientY - boundingRect.top;
                const x_2 = e.clientX - boundingRect.left;
                const minX = calcX(calculators.MinTime);
                const maxX = calcX(calculators.MaxTime);
                if ((x_2 >= minX) ? (x_2 <= maxX) : false) {
                    const timeAtPointer = (~(~(((calculators.MaxTime - calculators.MinTime) / (maxX - minX)) * (x_2 - minX)))) | 0;
                    const matchValue_1 = tryHead(Array.from(truncate(2, skipWhile((g_1) => (g_1[0] < timeAtPointer), distinctTimeIndexes))));
                    if (matchValue_1 == null) {
                    }
                    else {
                        showMouseOver(new InternalTypes_MouseOverPopover(x_2, y_2, Array.from(matchValue_1[1])));
                    }
                }
                else {
                    hideDataPoint();
                }
            }
        },
    }, ...(source2_8 = append(renderXAxis(calcX, primaryAxis.CalcY, xMinValue, xMaxValue, primaryAxis.MinValue, primaryAxis.MaxValue, width, height, props), append(rightAxis_1, append(leftAxis_1, append(svgLines, [])))), append([react.createElement("defs", {}, (graphClipX = (calcX(xMinValue) | 0), (graphClipY = (primaryAxis.CalcY(primaryAxis.MaxValue) | 0), (graphWidth = ((calcX(xMaxValue) - calcX(xMinValue)) | 0), (graphHeight = ((primaryAxis.CalcY(primaryAxis.MinValue) - primaryAxis.CalcY(primaryAxis.MaxValue)) | 0), react.createElement("clipPath", {
        id: "graphInnerClip",
    }, react.createElement("rect", {
        x: graphClipX,
        y: graphClipY,
        width: graphWidth,
        height: graphHeight,
    })))))))], source2_8)));
}

export function renderPopOver(props, container, popOver) {
    const boundingRect = container.getBoundingClientRect();
    const x = popOver.X;
    const xProp = (x > (boundingRect.width / 2)) ? (new CSSProp(298, boundingRect.width - x)) : (new CSSProp(208, ~(~x)));
    const propertyValuePairs = props.PopoverRows(popOver.Series, popOver.SeriesIndex, popOver.DataPoint);
    return react.createElement("div", {
        className: "absolute z-40 rounded border shadow bg-white pointer-events-none",
        style: keyValueList([xProp, new CSSProp(365, popOver.Y)], 1),
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "py-1 px-2",
    }, ...map((tupledArg) => react.createElement("div", {
        className: "flex flex-row justify-between w-full items-center",
    }, tinyLabel(tupledArg[0]), react.createElement("span", {
        className: tinyContentClass + " ml-4",
        style: {
            color: popOver.Color,
        },
    }, react.createElement("span", {}, tupledArg[1]))), propertyValuePairs))), delay(() => {
        const matchValue_1 = props.PopoverAnnotation;
        return (curry(3, matchValue_1) == null) ? singleton(react.createElement(react.Fragment, {})) : singleton(matchValue_1(popOver.Color, popOver.SeriesIndex, popOver.DataPoint));
    })))));
}

export function renderMouseOverPopover(props, container, popOver) {
    const boundingRect = container.getBoundingClientRect();
    const x = popOver.X;
    const xProp = (x > (boundingRect.width / 2)) ? (new CSSProp(298, boundingRect.width - x)) : (new CSSProp(208, ~(~x)));
    const matchValue_1 = props.MouseOverPopoverRows;
    if (matchValue_1 == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        return react.createElement("div", {
            className: "absolute z-40 rounded border shadow bg-white pointer-events-none",
            style: keyValueList([xProp, new CSSProp(365, popOver.Y)], 1),
        }, react.createElement("div", {
            className: "py-1 px-2 grid grid-cols-2",
        }, ...collect((tupledArg) => ofArray([tinyLabel(tupledArg[0]), react.createElement("span", {
            className: tinyContentClass + " ml-4 text-right",
        }, react.createElement("span", {}, tupledArg[1]))]), matchValue_1(popOver.DataPoints))));
    }
}

export const line = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_3, matchValue_4;
    if (isEmpty(collect((s) => s.DataPoints, props.Series))) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const containerWidth = react.useState(0);
        const containerHeight = react.useState(0);
        const dataPointPopover = react.useState(void 0);
        const mouseOverPopover = react.useState(void 0);
        const containerRef = react.useRef(void 0);
        react.useEffect(() => {
        const disp = (() => {
            let timeoutId = void 0;
            const setWidth = () => {
                const matchValue_1 = containerRef.current;
                if (matchValue_1 == null) {
                }
                else {
                    const element = matchValue_1;
                    const width = (~(~element.clientWidth)) | 0;
                    const height = (~(~element.clientHeight)) | 0;
                    dataPointPopover[1]((void 0));
                    if (timeoutId == null) {
                    }
                    else {
                        window.clearTimeout(timeoutId);
                    }
                    timeoutId = window.setTimeout((_arg1) => {
                        timeoutId = (void 0);
                        containerWidth[1](((s_1) => width));
                        containerHeight[1](((s_2) => height));
                    }, 150, empty());
                }
            };
            setWidth();
            return attachWindowEventWithDisposer((_arg2) => {
                setWidth();
            }, window, "resize", () => {
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
            });
        })();
        return () => disp.Dispose();
        }, []);
        let patternInput;
        const matchValue_2 = containerRef.current;
        if (matchValue_2 == null) {
            patternInput = [react.createElement(react.Fragment, {}), react.createElement(react.Fragment, {})];
        }
        else {
            const container = matchValue_2;
            patternInput = [renderer(containerWidth[0], containerHeight[0], (arg) => {
                dataPointPopover[1](arg);
            }, () => {
                dataPointPopover[1]((void 0));
            }, (arg_1) => {
                mouseOverPopover[1](arg_1);
            }, () => {
                mouseOverPopover[1]((void 0));
            }, props), (matchValue_3 = (dataPointPopover[0]), (matchValue_3 == null) ? (matchValue_4 = (mouseOverPopover[0]), (matchValue_4 == null) ? react.createElement(react.Fragment, {}) : renderMouseOverPopover(props, container, matchValue_4)) : renderPopOver(props, container, matchValue_3))];
        }
        return react.createElement("div", {
            className: "h-full relative",
            ref: containerRef,
        }, patternInput[1], patternInput[0]);
    }
}, void 0, uncurry(2, void 0), void 0, "line", "/home/runner/work/strengthPlus/strengthPlus/client/src/TimeGraph.fs", 612);

