import { Union } from "./.fable/fable-library.3.0.0/Types.js";
import { union_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import * as react from "react";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";

export class IconSize extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LargeIcon", "MediumIcon", "SmallIcon", "TinyIcon", "ExtraTinyIcon"];
    }
}

export function IconSize$reflection() {
    return union_type("Icons.IconSize", [], IconSize, () => [[], [], [], [], []]);
}

function classFromIconSize(iconSize) {
    switch (iconSize.tag) {
        case 1: {
            return "h-10 w-10";
        }
        case 2: {
            return "h-6 w-6";
        }
        case 3: {
            return "h-5 w-5";
        }
        case 4: {
            return "h-4 w-4";
        }
        default: {
            return "h-12 w-12";
        }
    }
}

export function heartBeatIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-red-600 fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M624 232H480c-9.3 0-17.76 5.37-21.72 13.79l-35.78 76.04-79.28-303.89C340.47 7.36 330.91 0 320 0c-.19 0-.41 0-.62.02-11.12.28-20.62 8.2-22.88 19.12l-75.84 366.52-37.53-136.03c-2.87-10.41-12.35-17.62-23.15-17.62H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h125.72l59.16 214.38A23.974 23.974 0 0 0 224 512c.25 0 .53 0 .78-.02 11.09-.36 20.47-8.27 22.72-19.12l75.19-363.44 70.09 268.64c2.53 9.77 10.94 16.91 21 17.83 10.5 1.14 19.62-4.53 23.94-13.67L495.22 280H624c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z",
    }));
}

export function powerIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-orange-500 fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M632 224h-24v-72c0-30.9-25.1-56-56-56h-32c-2.7 0-5.4.4-8 .8V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v136h-96V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v8.8c-2.6-.4-5.3-.8-8-.8H88c-30.9 0-56 25.1-56 56v72H8c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h24v72c0 30.9 25.1 56 56 56h32c2.7 0 5.4-.4 8-.8v8.8c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56V288h96v136c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56v-8.8c2.6.4 5.3.8 8 .8h32c30.9 0 56-25.1 56-56v-72h24c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM120 368H88c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208c0 4.4-3.6 8-8 8zm104 56c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm240 0c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm96-64c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208z",
    }));
}

export function distanceIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-blue-600 fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M424 336h-96c-22.1 0-40-17.9-40-40s17.9-40 40-40h88s96-107 96-160-43-96-96-96-96 43-96 96c0 29.8 30.3 76.7 56.9 112H328c-48.5 0-88 39.5-88 88s39.5 88 88 88h96c22.1 0 40 17.9 40 40s-17.9 40-40 40H135.1c26.6-35.3 56.9-82.2 56.9-112 0-53-43-96-96-96S0 299 0 352s96 160 96 160h328c48.5 0 88-39.5 88-88s-39.5-88-88-88zM368 96c0-26.5 21.5-48 48-48s48 21.5 48 47.9c-.5 13.4-20.8 48.2-48 84.4-27.2-36.2-47.5-70.9-48-84.3zM96 436.3c-27.2-36.2-47.5-70.9-48-84.3 0-26.5 21.5-48 48-48s48 21.5 48 47.9c-.5 13.4-20.8 48.2-48 84.4zM96 336c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM432 96c0-8.8-7.2-16-16-16s-16 7.2-16 16 7.2 16 16 16 16-7.2 16-16z",
    }));
}

export function timerIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-green-600 fill-current"))(arg10)),
        viewBox: "0 0 448 512",
    }, react.createElement("path", {
        d: "M393.9 184l22.6-22.6c4.7-4.7 4.7-12.3 0-17l-17-17c-4.7-4.7-12.3-4.7-17 0l-20.7 20.7c-31.1-27.5-70.4-45.9-113.8-50.8V48h28c6.6 0 12-5.4 12-12V12c0-6.6-5.4-12-12-12H172c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h28v49.4C96.4 109.3 16 197.2 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-44.7-14.1-86.1-38.1-120zM224 464c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160zm12-112h-24c-6.6 0-12-5.4-12-12V204c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v136c0 6.6-5.4 12-12 12z",
    }));
}

export function elevationIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-gray-600 fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M635.73 406.91l-194.04-297.6C435.9 100.44 425.95 96 416 96c-9.95 0-19.9 4.44-25.69 13.31l-52 79.76-70.79-110.55C261.32 68.84 250.66 64 240 64s-21.32 4.84-27.52 14.52L4.58 403.18C-7.99 422.81 6.81 448 30.92 448h580.22c22.5 0 36.32-23.09 24.59-41.09zM63.61 400L240 124.55 416.39 400H63.61zm409.78 0L366.71 233.4 416 157.8 573.92 400H473.39z",
    }));
}

export function speedIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-yellow-400 fill-current"))(arg10)),
        viewBox: "0 0 576 512",
    }, react.createElement("path", {
        d: "M381.06 193.27l-75.76 97.4c-5.54-1.56-11.27-2.67-17.3-2.67-35.35 0-64 28.65-64 64 0 11.72 3.38 22.55 8.88 32h110.25c5.5-9.45 8.88-20.28 8.88-32 0-11.67-3.36-22.46-8.81-31.88l75.75-97.39c8.16-10.47 6.25-25.55-4.19-33.67-10.57-8.15-25.6-6.23-33.7 4.21zM288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm212.27 400H75.73C57.56 397.63 48 359.12 48 320 48 187.66 155.66 80 288 80s240 107.66 240 240c0 39.12-9.56 77.63-27.73 112z",
    }));
}

export function strengthIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-gray-600 fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M632 224h-24v-72c0-30.9-25.1-56-56-56h-32c-2.7 0-5.4.4-8 .8V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v136h-96V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v8.8c-2.6-.4-5.3-.8-8-.8H88c-30.9 0-56 25.1-56 56v72H8c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h24v72c0 30.9 25.1 56 56 56h32c2.7 0 5.4-.4 8-.8v8.8c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56V288h96v136c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56v-8.8c2.6.4 5.3.8 8 .8h32c30.9 0 56-25.1 56-56v-72h24c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM120 368H88c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208c0 4.4-3.6 8-8 8zm104 56c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm240 0c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm96-64c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208z",
    }));
}

export function liftedWeightIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-gray-600 fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M510.28 445.86l-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-72.41c6.2-12.05 10.04-25.51 10.04-40 0-48.6-39.4-88-88-88s-88 39.4-88 88c0 14.49 3.83 27.95 10.04 40h-72.41c-14.43 0-27.08 10.54-30.87 25.72L1.72 445.86C-6.61 479.17 16.38 512 48.03 512h415.95c31.64 0 54.63-32.83 46.3-66.14zM216 88c0-22.06 17.94-40 40-40s40 17.94 40 40c0 22.05-17.94 40-40 40s-40-17.95-40-40zm246.72 376H49.28c-.7-.96-1.81-3.23-1-6.5L118.66 176h274.68l70.38 281.5c.81 3.27-.3 5.54-1 6.5z",
    }));
}

export function gotoIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zm448 0c0 110.5-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56s200 89.5 200 200zM266.9 126.1l121.4 121.4c4.7 4.7 4.7 12.3 0 17L266.9 385.9c-4.7 4.7-12.3 4.7-17 0l-19.6-19.6c-4.8-4.8-4.7-12.5.2-17.2l70.3-67.1H140c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h160.8l-70.3-67.1c-4.9-4.7-5-12.4-.2-17.2l19.6-19.6c4.7-4.7 12.3-4.7 17 0z",
    }));
}

export function cyclingIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-green-600 fill-current"))(arg10)),
        xmlns: "http://www.w3.org/2000/svg",
        stroke: "currentColor",
        fill: "currentColor",
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zm384-208a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zM401 210.73a24 24 0 0 0 15 5.27h64a24 24 0 0 0 0-48h-55.59L351 109.27a24 24 0 0 0-30.62.51l-104 89.11a32 32 0 0 0 3.06 50.94l76.53 51V416a24 24 0 0 0 48 0V288a24 24 0 0 0-10.69-20l-50.11-33.4 71.29-61.1z",
    }));
}

export function runningIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-green-600 fill-current"))(arg10)),
        xmlns: "http://www.w3.org/2000/svg",
        stroke: "currentColor",
        fill: "currentColor",
        viewBox: "0 0 416 512",
    }, react.createElement("path", {
        d: "M126.16 316.86l-19.85 46.28c-1.27 2.95-4.14 4.86-7.35 4.86H24.01C10.76 368 0 378.75 0 392s10.76 24 24.01 24h74.95c22.43 0 42.65-13.31 51.5-33.94l13.55-31.6-9.56-5.77c-11.88-7.18-21.22-16.87-28.29-27.83zM272.15 96c26.52 0 48.03-21.49 48.03-48s-21.5-48-48.03-48-48.03 21.49-48.03 48 21.51 48 48.03 48zm119.91 144.56l-48.4-.17c-3.53-.02-6.6-2.3-7.61-5.66l-13.95-45.92c-9.19-30.19-34.02-53.27-64.82-60.23l-78.25-17.7c-25.73-5.86-52.45.08-73.26 16.22L57.4 164.46c-10.49 8.09-12.43 23.17-4.31 33.66 8.08 10.5 23.23 12.41 33.68 4.31l48.39-37.36c9.46-7.33 21.68-9.92 33.3-7.38l14.88 3.37-35.3 87.35c-10.35 25.62-.69 54.59 22.98 68.91l83.78 50.58a8.004 8.004 0 0 1 3.55 9.05l-33.3 104.47c-3.64 12.75 3.74 26.03 16.49 29.67 2.2.62 4.42.92 6.61.92 10.44 0 20.06-6.86 23.08-17.41l33.3-104.47c6.93-24.25-3.31-50.28-24.9-63.33l-51.85-31.3 41.94-104.8c2.72 3.64 5.06 7.59 6.42 12.07l13.96 45.94c7.21 23.66 28.67 39.61 53.41 39.69l48.4.17h.08c13.23 0 23.97-10.69 24.01-23.92.05-13.26-10.66-24.04-23.94-24.09z",
    }));
}

export function walkingIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-green-600 fill-current"))(arg10)),
        xmlns: "http://www.w3.org/2000/svg",
        stroke: "currentColor",
        fill: "currentColor",
        viewBox: "0 0 320 512",
    }, react.createElement("path", {
        d: "M94.8 347.8s-.1-.1-.1-.2l-20.4 51c-2 5-5 9.6-8.9 13.4L7 470.5c-9.4 9.4-9.4 24.6 0 33.9 4.7 4.7 10.8 7 16.9 7s12.3-2.3 16.9-7l58.4-58.5c8.5-8.5 15-18.5 19.4-29.5l13.5-33.7-36.2-33.5-1.1-1.4zM207.8 96c26.5 0 47.9-21.5 47.9-48S234.2 0 207.8 0c-26.5 0-47.9 21.5-47.9 48s21.4 48 47.9 48zm104.7 174.9L283.2 242c-.9-.9-1.6-2-2-3.2L268.3 200c-14.4-43.1-54.4-72-99.8-72-34.8 0-53 8.8-95.7 26-20.9 8.4-37.9 24.1-48.2 44.8l-14.4 31.1c-13.3 28.7 30.1 49.1 43.5 20.2l14-30.4c4.8-9.6 12.9-17 22.8-21 21.7-8.7 33.1-13.5 44.3-17.1L115 260.8c-4.7 18.9.3 38.8 14.9 54.6l79 73c5.9 5.5 10 12.5 11.8 20.4l19.5 84.8c2.6 11.5 14.4 21.2 28.7 18 12.9-3 20.9-15.9 17.9-28.8l-19.5-84.7c-4-17.3-13-32.8-26-44.9l-53.8-49.6 26.2-104.8c7.4 9.7 7.5 12.6 21.8 55.4 2.8 8.3 7.5 15.9 13.8 22.2l29.3 29c22.3 21.5 56.7-11.9 33.9-34.5z",
    }));
}

export function swimmingIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s text-green-600 fill-current"))(arg10)),
        style: {
            transform: "scaleX(-1)",
        },
        xmlns: "http://www.w3.org/2000/svg",
        stroke: "currentColor",
        fill: "currentColor",
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M185.53 323.38c1.34 1.12 2.77 1.99 4.08 3.2 3.53 3.26 15.27 9.42 34.39 9.42 4.81 0 9.14-.39 13.01-1.03l25.79-36.11c14.89-20.87 31.42-40.34 48.71-59.11L442.1 331.2c3.89-1.63 6.91-3.34 8.3-4.62 11.97-11.04 25.32-18.17 39.21-21.08L346.12 205.02c20.66-19.12 42.3-37.27 65.59-53.58 9.31-6.52 21.1-8.89 33.09-6.41l102.38 18.08c13.32 2.28 26.04-6.33 28.43-19.37 2.39-13.05-6.44-25.52-19.76-27.87L454.26 97.94c-24.67-5.16-50.55.08-70.98 14.44-92.89 65.05-147.56 140.71-160.58 158.95l-37.17 52.05zM624 368h-16c-26.04 0-45.8-8.42-56.09-17.9-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C461.8 359.58 442.04 368 416 368s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C269.8 359.58 250.04 368 224 368s-45.8-8.42-56.09-17.9c-8.9-8.21-19.66-14.1-31.77-14.1h-16.3c-12.11 0-22.87 5.89-31.77 14.1C77.8 359.58 58.04 368 32 368H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h16c38.62 0 72.72-12.19 96-31.84 23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84s72.72-12.19 96-31.84c23.28 19.66 57.38 31.84 96 31.84h16c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-496-80c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zm0-144c26.47 0 48 21.53 48 48s-21.53 48-48 48-48-21.53-48-48 21.53-48 48-48z",
    }));
}

export function userIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 448 512",
    }, react.createElement("path", {
        d: "M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z",
    }));
}

export function removeIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z",
    }));
}

export function trashIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 448 512",
    }, react.createElement("path", {
        d: "M432 80h-82.4l-34-56.7A48 48 0 0 0 274.4 0H173.6a48 48 0 0 0-41.2 23.3L98.4 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16l21.2 339a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM173.6 48h100.8l19.2 32H154.4zm173.3 416H101.11l-21-336h287.8z",
    }));
}

export function addToComparisonCartIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 384 512",
    }, react.createElement("path", {
        d: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm256-172v24c0 6.6-5.4 12-12 12h-76v76c0 6.6-5.4 12-12 12h-24c-6.6 0-12-5.4-12-12v-76H92c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h76v-76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v76h76c6.6 0 12 5.4 12 12z",
    }));
}

export function removeFromComparisonCartIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 384 512",
    }, react.createElement("path", {
        d: "M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm44-136c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H92z",
    }));
}

export function comparisonCartIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M117.65 277.65c6.25 6.25 16.38 6.25 22.63 0L192 225.94l84.69 84.69c6.25 6.25 16.38 6.25 22.63 0L409.54 200.4l29.49 29.5c15.12 15.12 40.97 4.41 40.97-16.97V112c0-8.84-7.16-16-16-16H363.07c-21.38 0-32.09 25.85-16.97 40.97l29.5 29.49-87.6 87.6-84.69-84.69c-6.25-6.25-16.38-6.25-22.63 0l-74.34 74.34c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z",
    }));
}

export function expandedIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 448 512",
    }, react.createElement("path", {
        d: "M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z",
    }));
}

export function expandIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 256 512",
    }, react.createElement("path", {
        d: "M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z",
    }));
}

export function previousIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
        clipRule: "evenodd",
    }));
}

export function nextIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
        clipRule: "evenodd",
    }));
}

export function addIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 384 512",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z",
        clipRule: "evenodd",
    }));
}

export function downloadIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 576 512",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M528 288h-92.1l46.1-46.1c30.1-30.1 8.8-81.9-33.9-81.9h-64V48c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v112h-64c-42.6 0-64.2 51.7-33.9 81.9l46.1 46.1H48c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48zm-400-80h112V48h96v160h112L288 368 128 208zm400 256H48V336h140.1l65.9 65.9c18.8 18.8 49.1 18.7 67.9 0l65.9-65.9H528v128zm-88-64c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24z",
        clipRule: "evenodd",
    }));
}

export function editIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 576 512",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z",
        clipRule: "evenodd",
    }));
}

export function historyIcon(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z",
        clipRule: "evenodd",
    }));
}

export function Analysis_peakPerformances(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M634.92 462.7l-288-448C341.03 5.54 330.89 0 320 0s-21.03 5.54-26.92 14.7l-288 448a32.001 32.001 0 0 0-1.17 32.64A32.004 32.004 0 0 0 32 512h576c11.71 0 22.48-6.39 28.09-16.67a31.983 31.983 0 0 0-1.17-32.63zM61.31 464l131.77-204.98L256 321.94 329.94 248h109.9L578.7 464H61.31zM320 61.59L408.98 200h-98.92L256 254.06l-36.36-36.36L320 61.59z",
    }));
}

export function Analysis_trainingLoad(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 576 512",
    }, react.createElement("path", {
        d: "M544 0H32C14.33 0 0 14.33 0 32v448c0 17.67 14.33 32 32 32h512c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32zm-16 464H48V288h158.69l26.53 79.59A24.044 24.044 0 0 0 255.69 384h.31c10.09 0 19.09-6.3 22.56-15.8l42.5-116.91 8.67 21.63A23.993 23.993 0 0 0 352 287.99h112c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-95.75l-25.97-64.91c-3.69-9.23-13.41-15.42-22.66-15.09-9.97.16-18.78 6.44-22.19 15.8L257 287.06l-10.22-30.66c-3.27-9.8-12.44-16.41-22.76-16.41H48V48h480v416z",
    }));
}

export function Analysis_fitness(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M624 232H480c-9.3 0-17.76 5.37-21.72 13.79l-35.78 76.04-79.28-303.89C340.47 7.36 330.91 0 320 0c-.19 0-.41 0-.62.02-11.12.28-20.62 8.2-22.88 19.12l-75.84 366.52-37.53-136.03c-2.87-10.41-12.35-17.62-23.15-17.62H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h125.72l59.16 214.38A23.974 23.974 0 0 0 224 512c.25 0 .53 0 .78-.02 11.09-.36 20.47-8.27 22.72-19.12l75.19-363.44 70.09 268.64c2.53 9.77 10.94 16.91 21 17.83 10.5 1.14 19.62-4.53 23.94-13.67L495.22 280H624c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z",
    }));
}

export function Analysis_peakHistory(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M504 255.532c.252 136.64-111.182 248.372-247.822 248.468-64.014.045-122.373-24.163-166.394-63.942-5.097-4.606-5.3-12.543-.443-17.4l16.96-16.96c4.529-4.529 11.776-4.659 16.555-.395C158.208 436.843 204.848 456 256 456c110.549 0 200-89.468 200-200 0-110.549-89.468-200-200-200-55.52 0-105.708 22.574-141.923 59.043l49.091 48.413c7.641 7.535 2.305 20.544-8.426 20.544H26.412c-6.627 0-12-5.373-12-12V45.443c0-10.651 12.843-16.023 20.426-8.544l45.097 44.474C124.866 36.067 187.15 8 256 8c136.811 0 247.747 110.781 248 247.532zm-167.058 90.173l14.116-19.409c3.898-5.36 2.713-12.865-2.647-16.763L280 259.778V116c0-6.627-5.373-12-12-12h-24c-6.627 0-12 5.373-12 12v168.222l88.179 64.13c5.36 3.897 12.865 2.712 16.763-2.647z",
    }));
}

export function Analysis_metricHistory(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 512 512",
    }, react.createElement("path", {
        d: "M510.28 445.86l-73.03-292.13c-3.8-15.19-16.44-25.72-30.87-25.72h-72.41c6.2-12.05 10.04-25.51 10.04-40 0-48.6-39.4-88-88-88s-88 39.4-88 88c0 14.49 3.83 27.95 10.04 40h-72.41c-14.43 0-27.08 10.54-30.87 25.72L1.72 445.86C-6.61 479.17 16.38 512 48.03 512h415.95c31.64 0 54.63-32.83 46.3-66.14zM216 88c0-22.06 17.94-40 40-40s40 17.94 40 40c0 22.05-17.94 40-40 40s-40-17.95-40-40zm246.72 376H49.28c-.7-.96-1.81-3.23-1-6.5L118.66 176h274.68l70.38 281.5c.81 3.27-.3 5.54-1 6.5z",
    }));
}

export function Analysis_compareActivities(iconSize) {
    let arg10;
    return react.createElement("svg", {
        className: (arg10 = classFromIconSize(iconSize), toText(printf("%s fill-current"))(arg10)),
        viewBox: "0 0 640 512",
    }, react.createElement("path", {
        d: "M256 336h-.02c0-16.18 1.34-8.73-85.05-181.51-8.83-17.65-25.89-26.49-42.95-26.49-17.04 0-34.08 8.82-42.92 26.49C-2.06 328.75.02 320.33.02 336H0c0 44.18 57.31 80 128 80s128-35.82 128-80zM83.24 265.13c11.4-22.65 26.02-51.69 44.46-89.1.03-.01.13-.03.29-.03l.02-.04c19.82 39.64 35.03 69.81 46.7 92.96 11.28 22.38 19.7 39.12 25.55 51.08H55.83c6.2-12.68 15.24-30.69 27.41-54.87zM528 464H344V155.93c27.42-8.67 48.59-31.36 54.39-59.93H528c8.84 0 16-7.16 16-16V64c0-8.84-7.16-16-16-16H393.25C380.89 19.77 352.79 0 320 0s-60.89 19.77-73.25 48H112c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h129.61c5.8 28.57 26.97 51.26 54.39 59.93V464H112c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM320 112c-17.64 0-32-14.36-32-32s14.36-32 32-32 32 14.36 32 32-14.36 32-32 32zm319.98 224c0-16.18 1.34-8.73-85.05-181.51-8.83-17.65-25.89-26.49-42.95-26.49-17.04 0-34.08 8.82-42.92 26.49-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zm-200.15-16c6.19-12.68 15.23-30.69 27.4-54.87 11.4-22.65 26.02-51.69 44.46-89.1.03-.01.13-.03.29-.03l.02-.04c19.82 39.64 35.03 69.81 46.7 92.96 11.28 22.38 19.7 39.12 25.55 51.08H439.83z",
    }));
}

export function iconForCardioType(cardioType) {
    switch (cardioType.tag) {
        case 0: {
            return runningIcon;
        }
        case 3: {
            return walkingIcon;
        }
        case 2: {
            return swimmingIcon;
        }
        case 4: {
            return cyclingIcon;
        }
        case 5: {
            return cyclingIcon;
        }
        default: {
            return cyclingIcon;
        }
    }
}

