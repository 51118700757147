import { Model, Model_get_Empty } from "./Types.fs.js";
import { update as update_1, init as init_1 } from "../Show/State.fs.js";
import { Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(page) {
    return [new Model((new Model(page, Model_get_Empty().ShowModel)).CurrentPage, init_1()[0]), Cmd_none()];
}

export function update(context, msg, model) {
    const matchValue = [msg, model];
    if (matchValue[1].ShowModel != null) {
        return [new Model(model.CurrentPage, update_1(context, msg, matchValue[1].ShowModel)[0]), Cmd_none()];
    }
    else {
        console.log(some("[Help.Dispatcher.State] Discarded message"));
        return [model, Cmd_none()];
    }
}

