import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { concat, map, mapIndexed } from "../../.fable/fable-library.3.0.0/Seq.js";
import { ApiResponse$1 } from "../../../../shared/Shared.Api.fs.js";
import { peaks as peaks_1, metrics as metrics_1 } from "../../Server.fs.js";
import { convertSpeedsToLocalUnits } from "../Helpers.fs.js";
import { GetPeaksRes } from "./Types.fs.js";

export class UnableToLoadPeaksException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadPeaksException$reflection() {
    return class_type("Analysis.PeakHistory.Api.UnableToLoadPeaksException", void 0, UnableToLoadPeaksException, class_type("System.Exception"));
}

function UnableToLoadPeaksException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadPeaksException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getAnalysis(dateRange, durations, compareWith, categoryIds, lapType, analysisCardioType, context) {
    return singleton.Delay(() => {
        let metricType;
        const durationAndCategoryIds = Array.from(mapIndexed((index, duration) => [duration, categoryIds[index]], durations));
        return singleton.Bind((compareWith == null) ? singleton.Delay(() => singleton.Return(new ApiResponse$1(0, []))) : (metricType = compareWith, metrics_1((api) => api.getForDateRange(metricType, dateRange, true))), (_arg1) => singleton.Bind(peaks_1((api_1) => api_1.getHistories(dateRange, analysisCardioType, durationAndCategoryIds, lapType)), (_arg2) => {
            let matchValue, peaks, summaries, tupledArg;
            return singleton.Return((matchValue = [_arg2, _arg1], (matchValue[0].tag === 0) ? ((matchValue[1].tag === 0) ? (peaks = Array.from(map((tuple) => tuple[0], matchValue[0].fields[0])), (summaries = Array.from(concat(map((tuple_1) => tuple_1[1], matchValue[0].fields[0]))), (tupledArg = [Array.from(map((p) => Array.from(convertSpeedsToLocalUnits(context, p)), peaks)), summaries, matchValue[1].fields[0]], new GetPeaksRes(0, tupledArg[0], tupledArg[1], tupledArg[2])))) : (new GetPeaksRes(1, new UnableToLoadPeaksException()))) : (new GetPeaksRes(1, new UnableToLoadPeaksException()))));
        }));
    });
}

