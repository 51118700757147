import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Item$reflection } from "./Types.fs.js";
import { record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { GraphValueTransformer } from "../../GraphShared.fs.js";
import { durationFromSeconds } from "../../Units.fs.js";
import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { isEmpty, map, singleton, append, delay, filter, sortWith } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import * as react from "react";
import { IBarGraphProps, XAxisConfiguration, GraphAnnotation, GraphColor, GraphDataPoint, bar } from "../../CartesianGraph.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { Peaks_noHeartAndPowerMessage, Peaks_stillCalculatingZones, heartRateConesTable, Peaks_colorForBpm, zonesTable, Peaks_colorForPowerZone } from "../../CommonRendering.fs.js";
import { panelSubTitle, subtleLabelNoneTruncateClass } from "../../Typography.fs.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { PowerZonesPage, Page, href } from "../../Router.fs.js";
import { HTMLAttr } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";

export class IRenderTimeInZonesnalysisProps extends Record {
    constructor(Context, Activity, IsLoading) {
        super();
        this.Context = Context;
        this.Activity = Activity;
        this.IsLoading = IsLoading;
    }
}

export function IRenderTimeInZonesnalysisProps$reflection() {
    return record_type("CardioActivity.Show.TimeInZonesAnalysis.IRenderTimeInZonesnalysisProps", [], IRenderTimeInZonesnalysisProps, () => [["Context", Context$reflection()], ["Activity", Item$reflection()], ["IsLoading", bool_type]]);
}

const outputSecondAsTimeTransformation = new GraphValueTransformer(1, durationFromSeconds);

function renderTimeInPower(timeInPowerZones, powerZones) {
    let projection;
    const entireRouteTimes = ofSeq((projection = ((p_1) => p_1.MinValue), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), filter((p) => {
        if (p.Lap.tag === 0) {
            return true;
        }
        else {
            return false;
        }
    }, timeInPowerZones))));
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p_2) => (new GraphDataPoint(p_2.TimeInZoneSeconds, toText(printf("%s"))(p_2.Name))), entireRouteTimes)), new GraphColor(1, (_arg2, power) => Peaks_colorForPowerZone(powerZones, _arg2, power)), "#718096", new GraphAnnotation(0), void 0, true, false, new XAxisConfiguration(0), outputSecondAsTimeTransformation)))), delay(() => {
        if (powerZones == null) {
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-center align-center content-center",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, "For improved power analysis ", react.createElement("a", keyValueList([href(new Page(6, new PowerZonesPage(0))), new HTMLAttr(65, "text-indigo-600")], 1), "set your power zones"))));
        }
        else {
            const zones = powerZones;
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, react.createElement("div", {
                className: "mb-4 text-left",
            }, panelSubTitle("Peak Powers")), zonesTable(zones, false))));
        }
    }))))));
}

function renderTimeInHeart(timeInHeartZones, heartRateZones) {
    let projection;
    const entireRouteTimes = ofSeq((projection = ((p_1) => p_1.MinValue), sortWith((x, y) => comparePrimitives(projection(x), projection(y)), filter((p) => {
        if (p.Lap.tag === 0) {
            return true;
        }
        else {
            return false;
        }
    }, timeInHeartZones))));
    return react.createElement("div", {
        className: "mb-4",
    }, react.createElement("div", {
        className: "md:flex md:flex-row",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "flex-grow",
        style: {
            minHeight: 200,
            height: 250,
        },
    }, bar(new IBarGraphProps(Array.from(map((p_2) => (new GraphDataPoint(p_2.TimeInZoneSeconds, toText(printf("%s"))(p_2.Name))), entireRouteTimes)), new GraphColor(1, (_arg2, bpm) => Peaks_colorForBpm(heartRateZones, _arg2, bpm)), "#718096", new GraphAnnotation(0), void 0, true, false, new XAxisConfiguration(0), outputSecondAsTimeTransformation)))), delay(() => {
        if (heartRateZones == null) {
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-center align-center content-center",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, "For improved power analysis ", react.createElement("a", keyValueList([href(new Page(6, new PowerZonesPage(0))), new HTMLAttr(65, "text-indigo-600")], 1), "set your power zones"))));
        }
        else {
            const zones = heartRateZones;
            return singleton(react.createElement("div", {
                className: "md:ml-4 flex flex-col justify-start align-center content-center mt-4 md:mt-0",
            }, react.createElement("div", {
                className: subtleLabelNoneTruncateClass + " text-center",
                style: {
                    minWidth: 260,
                },
            }, react.createElement("div", {
                className: "mb-4 text-left",
            }, panelSubTitle("Peak Powers")), heartRateConesTable(zones, false))));
        }
    }))))));
}

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const warningMessage = ((props.Activity.Summary.Metrics.AverageHeartRate != null) ? true : (props.Activity.Summary.Metrics.AveragePower != null)) ? Peaks_stillCalculatingZones : Peaks_noHeartAndPowerMessage;
    const matchValue = props.Activity.TimeInZones;
    if (matchValue == null) {
        return react.createElement("div", {}, warningMessage);
    }
    else {
        const tiz = matchValue;
        const matchValue_1 = [!isEmpty(tiz.Power), !isEmpty(tiz.Heart)];
        return matchValue_1[0] ? (matchValue_1[1] ? react.createElement("div", {}, renderTimeInPower(tiz.Power, props.Activity.PowerZones), renderTimeInHeart(tiz.Heart, props.Activity.HeartRateZones)) : react.createElement("div", {}, renderTimeInPower(tiz.Power, props.Activity.PowerZones))) : (matchValue_1[1] ? react.createElement("div", {}, renderTimeInHeart(tiz.Heart, props.Activity.HeartRateZones)) : react.createElement("div", {}, warningMessage));
    }
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/TimeInZonesAnalysis.fs", 99);

