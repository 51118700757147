import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { viewableDateRange } from "../Helpers.fs.js";
import { item } from "../../.fable/fable-library.3.0.0/List.js";
import { ApiException, trainingLog } from "../../Server.fs.js";
import { GetWeeklyTrainingLogsRes } from "./Types.fs.js";

export function getWeeklyTrainingLogs(model, context) {
    return singleton.Delay(() => {
        const patternInput = viewableDateRange(item(model.SelectedYearIndex, model.Years), model.SelectedMonthIndex + 1);
        return singleton.Bind(trainingLog((api) => api.getBetween(patternInput[0], patternInput[1])), (_arg1) => {
            const response = _arg1;
            return singleton.Return((response.tag === 0) ? (new GetWeeklyTrainingLogsRes(0, response.fields[0])) : (new GetWeeklyTrainingLogsRes(1, new ApiException())));
        });
    });
}

