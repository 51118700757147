import * as react from "react";
import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { record_type, bool_type, lambda_type, unit_type, class_type } from "./.fable/fable-library.3.0.0/Reflection.js";

export function primary(body, onClick, disabled) {
    const style = disabled ? "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-300 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function flatTopPrimary(body, onClick, disabled) {
    const style = disabled ? "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-b-md text-white bg-blue-300 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-b-md text-white bg-blue-500 hover:bg-blue-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function popoverButton(body, onClick, disabled) {
    const style = disabled ? "flex-grow items-center px-4 py-2 border border-transparent w-full leading-5 font-medium text-xs text-white bg-gray-300 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "flex-grow items-center px-4 py-2 border border-transparent w-full leading-5 font-medium text-xs text-blue-600 bg-gray-100 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export class PopoverButton extends Record {
    constructor(Body, OnClick, Disabled) {
        super();
        this.Body = Body;
        this.OnClick = OnClick;
        this.Disabled = Disabled;
    }
}

export function PopoverButton$reflection() {
    return record_type("Buttons.PopoverButton", [], PopoverButton, () => [["Body", class_type("Fable.React.ReactElement")], ["OnClick", lambda_type(unit_type, unit_type)], ["Disabled", bool_type]]);
}

export function popoverButtonBar(left, right) {
    const style = (disabled) => {
        if (disabled) {
            return "flex-grow items-center text-center px-2 py-1 border border-transparent w-full leading-5 font-medium text-xs text-white bg-gray-300 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
        }
        else {
            return "flex-grow items-center text-center px-2 py-1 border border-transparent w-full leading-5 font-medium text-xs text-blue-600 bg-gray-100 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
        }
    };
    return react.createElement("div", {
        className: "flex-grow w-full grid grid-cols-2",
    }, react.createElement("button", {
        type: "button",
        className: style(left.Disabled),
        onClick: (_arg1) => {
            left.OnClick();
        },
        disabled: left.Disabled,
    }, left.Body), react.createElement("button", {
        type: "button",
        className: style(right.Disabled),
        onClick: (_arg2) => {
            right.OnClick();
        },
        disabled: right.Disabled,
    }, right.Body));
}

export function warning(body, onClick, disabled) {
    const style = disabled ? "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function plain(body, onClick, disabled) {
    const style = disabled ? "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-300 bg-gray-100 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "flex-grow items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-600 bg-gray-100 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function plainLeft(body, onClick, disabled) {
    const style = disabled ? "items-center px-4 py-2 border bg-gray-200 text-sm leading-5 font-medium rounded-md rounded-r-none text-blue-300 border-gray-200 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "items-center px-4 py-2 border bg-white text-sm leading-5 font-medium rounded-md rounded-r-none text-blue-600 border-gray-200 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function plainMiddle(body, onClick, disabled) {
    const style = disabled ? "items-center px-4 py-2 border border-l-0 bg-gray-200 text-sm leading-5 font-medium text-blue-300 border-gray-200 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "items-center px-4 py-2 border border-l-0 bg-white text-sm leading-5 font-medium text-blue-600 border-gray-200 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

export function plainRight(body, onClick, disabled) {
    const style = disabled ? "items-center px-4 py-2 border border-l-0 bg-gray-200 text-sm leading-5 font-medium rounded-md rounded-l-none text-blue-300 border-gray-200 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" : "items-center px-4 py-2 border border-l-0 bg-white text-sm leading-5 font-medium rounded-md rounded-l-none text-blue-600 border-gray-200 hover:bg-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out";
    return react.createElement("button", {
        type: "button",
        className: style,
        onClick: (_arg1) => {
            onClick();
        },
        disabled: disabled,
    }, body);
}

