import * as leaflet from "../../../node_modules/leaflet/dist/leaflet.css";
import * as react from "react";
import { Map as Map$, Polyline, TileLayer } from "react-leaflet";
import { append as append_1, singleton, empty, length, item, ofSeq, ofArray } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton as singleton_1, delay, tryFind, isEmpty, collect, fold, tryLast, append, filter, map as map_1, mapIndexed } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, max, comparePrimitives, min } from "../../.fable/fable-library.3.0.0/Util.js";
import { MapProps, MapProps_Style_7213C43C } from "../../ReactLeaflet.fs.js";
import { CSSProp } from "../../.fable/Fable.React.7.2.0/Fable.React.Props.fs.js";
import { keyValueList } from "../../.fable/fable-library.3.0.0/MapUtil.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { summary } from "../../Grids.fs.js";
import { power, powerUnits, speedUnits, speed, distance, ascent, elapsedTimeFromSeconds, normalisedPower, heartRate, distanceUnits, ascentUnits, normalisedPowerUnits, heartRateUnits } from "../../Units.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { Model$reflection, Msg$reflection, Item$reflection } from "./Types.fs.js";
import { lambda_type, unit_type, record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { speedIcon, heartBeatIcon, timerIcon, elevationIcon, IconSize, distanceIcon } from "../../Icons.fs.js";
import { view, Statistic } from "../../StatBar.fs.js";
import { view as view_1, GroupedStatistic } from "../../GroupedStatBar.fs.js";
import { ICardioSegmentGraphProps, view as view_2, createSampledLapsFast } from "../../CardioSegmentGraph.fs.js";
import { defaultArg, bind } from "../../.fable/fable-library.3.0.0/Option.js";
import { warningPanelWithElement } from "../../Notifications.fs.js";
import { link } from "../../Typography.fs.js";
import { PowerZonesPage, Page } from "../../Router.fs.js";
import { page, toolbarPage, tabsWithPages, panel, spacedTightPanel } from "../../Layout.fs.js";
import { IRenderPeakAnalysisProps, view as view_3 } from "./PeakAnalysis.fs.js";
import { IRenderTimeInZonesnalysisProps, view as view_4 } from "./TimeInZonesAnalysis.fs.js";
import { IRenderVersusAnalysisProps, view as view_5 } from "./VersusAnalysis.fs.js";
import { view as view_6 } from "./SummaryData.fs.js";
import { view as view_7, IToolbarProps } from "./Toolbar.fs.js";
import { CardioSummary__get_Sport, CardioSummary__get_CalculatedTitle } from "../../../../shared/Shared.Models.fs.js";
import { toShortDateString } from "../../.fable/fable-library.3.0.0/Date.js";
import { view as view_8 } from "./ConfirmDeleteModal.fs.js";


export const tile = react.createElement(TileLayer, {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "\u0026copy; \u003ca href=\u0026quot;http://osm.org/copyright\u0026quot;\u003eOpenStreetMap\u003c/a\u003e contributors",
});

export function route(laps) {
    const colours = ofArray(["#E53E3E", "#DD6B20", "#319795", "#38A169", "#5A67D8", "#805AD5", "#3182CE", "#D53F8C"]);
    return ofSeq(mapIndexed((i, l) => {
        let possibleTail;
        const colour = item(i % length(colours), colours);
        const coords = Array.from(map_1((dp_2) => [dp_2.Position.Latitude, dp_2.Position.Longitude], filter((dp_1) => (dp_1.Position != null), append((i > 0) ? (possibleTail = tryLast(filter((dp) => (dp.Position != null), item(i - 1, laps).DataPoints)), (possibleTail == null) ? empty() : singleton(possibleTail)) : empty(), l.DataPoints))));
        return react.createElement(Polyline, {
            positions: coords,
            color: colour,
            weight: 5,
        });
    }, laps));
}

export function buildMap(activity) {
    let matchValue, position;
    const routeElements = activity.Summary.HasRoute ? route(activity.Laps) : empty();
    const patternInput = fold((tupledArg, p) => [min(comparePrimitives, tupledArg[0], p.Latitude), max(comparePrimitives, tupledArg[1], p.Latitude), min(comparePrimitives, tupledArg[2], p.Longitude), max(comparePrimitives, tupledArg[3], p.Longitude)], [1000, -1000, 1000, -1000], map_1((dp_1) => dp_1.Position, filter((dp) => (dp.Position != null), collect((l) => l.DataPoints, activity.Laps))));
    const bounds = [[patternInput[0], patternInput[2]], [patternInput[1], patternInput[3]]];
    const props = ofArray([MapProps_Style_7213C43C(ofArray([new CSSProp(189, 475), new CSSProp(253, 200), new CSSProp(395, "100%")])), new MapProps(26, false), new MapProps(34, bounds), (matchValue = activity.Summary.Metrics.Center, (matchValue == null) ? (new MapProps(36, [52.1386, -0.4668])) : (position = matchValue, new MapProps(36, [position.Latitude, position.Longitude])))]);
    return react.createElement(Map$, keyValueList(props, 1), ...append_1(routeElements, singleton(tile)));
}

export function renderLapsTable(context, activity, dispatch) {
    let source2_5, source2_4, source2_2, value, arg10_1, value_2, arg10_2, value_4, arg10_3, value_6, arg10_4;
    const laps = append([["Entire Activity", activity.Summary.Metrics]], mapIndexed((i, v) => {
        let arg10;
        return [(arg10 = ((i + 1) | 0), toText(printf("Lap %d"))(arg10)), v.Metrics];
    }, activity.Laps));
    return summary(true, true, true, append([""], (source2_5 = (source2_4 = append(["Elapsed Time"], (source2_2 = append((value = singleton((arg10_1 = heartRateUnits(context), toText(printf("Heart Rate (%s)"))(arg10_1))), (activity.Summary.Metrics.AverageHeartRate == null) ? empty() : value), []), append((value_2 = singleton((arg10_2 = normalisedPowerUnits(context), toText(printf("Normalised Power (%s)"))(arg10_2))), (activity.Summary.Metrics.NormalisedPower == null) ? empty() : value_2), source2_2))), append((value_4 = singleton((arg10_3 = ascentUnits(context), toText(printf("Ascent (%s)"))(arg10_3))), (activity.Summary.Metrics.ElevationGainedMeters == null) ? empty() : value_4), source2_4)), append((value_6 = singleton((arg10_4 = distanceUnits(context), toText(printf("Distance (%s)"))(arg10_4))), (activity.Summary.Metrics.DistanceMeters == null) ? empty() : value_6), source2_5))), map_1((tupledArg) => {
        let value_8, value_10, value_12, value_14;
        const l = tupledArg[1];
        let source2_12;
        let source2_11;
        let source2_10;
        let source2_9;
        const source2_8 = append((value_8 = singleton(heartRate(context, l.AverageHeartRate)), (activity.Summary.Metrics.AverageHeartRate == null) ? empty() : value_8), []);
        source2_9 = append((value_10 = singleton(normalisedPower(context, l.NormalisedPower)), (activity.Summary.Metrics.NormalisedPower == null) ? empty() : value_10), source2_8);
        source2_10 = append([elapsedTimeFromSeconds(context, l.TimeSeconds)], source2_9);
        source2_11 = append((value_12 = singleton(ascent(context, l.ElevationGainedMeters)), (activity.Summary.Metrics.ElevationGainedMeters == null) ? empty() : value_12), source2_10);
        source2_12 = append((value_14 = singleton(distance(context, l.DistanceMeters)), (activity.Summary.Metrics.DistanceMeters == null) ? empty() : value_14), source2_11);
        return append([react.createElement("div", {}, tupledArg[0])], source2_12);
    }, laps));
}

export class IRenderActivityProps extends Record {
    constructor(Context, Activity, IsLoading) {
        super();
        this.Context = Context;
        this.Activity = Activity;
        this.IsLoading = IsLoading;
    }
}

export function IRenderActivityProps$reflection() {
    return record_type("CardioActivity.Show.View.IRenderActivityProps", [], IRenderActivityProps, () => [["Context", Context$reflection()], ["Activity", Item$reflection()], ["IsLoading", bool_type]]);
}

export function renderActivity(dispatch) {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        let i, i_1, source2_4, source2_3, source2_2, source2_1, source2_9, source2_8, source2_7, source2_6;
        const context = props.Context;
        const activity = props.Activity;
        const mapHook = react.useMemo(() => (activity.Summary.HasRoute ? buildMap(activity) : react.createElement(react.Fragment, {})), []);
        let distanceStat;
        const Value = distance(context, activity.Summary.Metrics.DistanceMeters);
        const Icon = distanceIcon(new IconSize(0));
        distanceStat = (new Statistic("Total Distance", "text-blue-600", Value, distanceUnits(context), Icon));
        let altitudeStat;
        const Value_1 = ascent(context, activity.Summary.Metrics.ElevationGainedMeters);
        const Icon_1 = elevationIcon(new IconSize(0));
        altitudeStat = (new Statistic("Total Ascent", "text-gray-600", Value_1, ascentUnits(context), Icon_1));
        const timeStat = new Statistic("Elapsed Time", "text-green-600", elapsedTimeFromSeconds(context, activity.Summary.Metrics.TimeSeconds), "", timerIcon(new IconSize(0)));
        let heartRateStat;
        const Value_3 = heartRate(context, activity.Summary.Metrics.AverageHeartRate);
        const Icon_3 = heartBeatIcon(new IconSize(0));
        heartRateStat = (new Statistic("Heart Rate", "text-red-600", Value_3, heartRateUnits(context), Icon_3));
        let speedStat;
        const Value_4 = speed(context, activity.Summary.Metrics.AverageSpeedMetersPerSecond);
        const Icon_4 = speedIcon(new IconSize(0));
        speedStat = (new Statistic("Average Speed", "text-yellow-400", Value_4, speedUnits(context), Icon_4));
        const normalisedPowerStat = new GroupedStatistic("Normalised Power", "text-orange-500", normalisedPower(context, activity.Summary.Metrics.NormalisedPower), powerUnits(context));
        const averagePowerStat = new GroupedStatistic("Average Power", "text-orange-500", normalisedPower(context, activity.Summary.Metrics.AveragePower), powerUnits(context));
        const ftpStat = new GroupedStatistic("FTP", "text-orange-500", power(context, activity.Summary.Metrics.FtpAtTimeOfRide), powerUnits(context));
        let intensityStat;
        const intensity = activity.Summary.Metrics.IntensityFactor;
        intensityStat = (new GroupedStatistic("Intensity", "text-orange-500", (intensity == null) ? "N/A" : (i = intensity, toText(printf("%.2f"))(i)), ""));
        let trainingStressStat;
        const tss = activity.Summary.Metrics.TrainingStressScore;
        trainingStressStat = (new GroupedStatistic("Training Stress", "text-orange-500", (tss == null) ? "N/A" : (i_1 = tss, toText(printf("%.0f"))(i_1)), ""));
        const speedDataPoints = createSampledLapsFast(1216, (s) => bind((v) => (v * 3.6), s.SpeedMetersPerSecond), activity.Laps);
        const hasSpeed = !isEmpty(speedDataPoints);
        const heartRateDataPoints = createSampledLapsFast(1216, (s_1) => bind((arg) => arg, s_1.HeartRate), activity.Laps);
        const cadenceDataPoints = createSampledLapsFast(1216, (s_2) => bind((arg_1) => arg_1, defaultArg(s_2.Cadence, 0)), activity.Laps);
        const powerDataPoints = createSampledLapsFast(1216, (s_3) => defaultArg(s_3.Power, 0), activity.Laps);
        const altitudeDataPoints = createSampledLapsFast(1216, (s_4) => s_4.AltitudeMeters, activity.Laps);
        const stats = Array.from((source2_4 = (source2_3 = (source2_2 = (source2_1 = append((activity.Summary.Metrics.AverageHeartRate == null) ? empty() : singleton(heartRateStat), []), append((activity.Summary.Metrics.AverageSpeedMetersPerSecond == null) ? empty() : singleton(speedStat), source2_1)), append((activity.Summary.Metrics.ElevationGainedMeters == null) ? empty() : singleton(altitudeStat), source2_2)), append((activity.Summary.Metrics.DistanceMeters == null) ? empty() : singleton(distanceStat), source2_3)), append((activity.Summary.Metrics.TimeSeconds == null) ? empty() : singleton(timeStat), source2_4)));
        const powerStats = Array.from((source2_9 = (source2_8 = (source2_7 = (source2_6 = append((activity.Summary.Metrics.TrainingStressScore == null) ? empty() : singleton(trainingStressStat), []), append((activity.Summary.Metrics.IntensityFactor == null) ? empty() : singleton(intensityStat), source2_6)), append((activity.Summary.Metrics.NormalisedPower == null) ? empty() : singleton(normalisedPowerStat), source2_7)), append((activity.Summary.Metrics.AveragePower == null) ? empty() : singleton(averagePowerStat), source2_8)), append((activity.Summary.Metrics.FtpAtTimeOfRide == null) ? empty() : singleton(ftpStat), source2_9)));
        const hasGeo = tryFind((dp) => (dp.Position != null), collect((l) => l.DataPoints, activity.Laps)) != null;
        return react.createElement("div", {}, ...ofSeq(delay(() => {
            let matchValue_11;
            return append((matchValue_11 = [activity.Summary.Metrics.NormalisedPower, activity.Summary.Metrics.FtpAtTimeOfRide], (matchValue_11[0] != null) ? ((matchValue_11[1] == null) ? singleton_1(react.createElement("div", {
                className: "mb-6",
            }, warningPanelWithElement("Set your FTP", react.createElement("span", {}, "When you\u0027re training with a power meter you can gain more insights into your performance by ", link("setting your FTP and power zones", new Page(6, new PowerZonesPage(0))), ".")))) : singleton_1(react.createElement(react.Fragment, {}))) : singleton_1(react.createElement(react.Fragment, {}))), delay(() => append(singleton_1(react.createElement("div", {
                className: "shadow mb-6 rounded-lg",
            }, react.createElement("div", {
                className: "hidden lg:block",
            }, view(true, false, false, stats)), react.createElement("div", {
                className: "border-t border-gray-200",
            }, view_1(false, true, powerStats)))), delay(() => singleton_1(react.createElement("div", {
                className: "grid grid-cols-12 md:gap-6",
            }, react.createElement("div", {
                className: "col-span-12",
            }, ...ofSeq(delay(() => append(hasGeo ? singleton_1(spacedTightPanel([react.createElement("div", {}, mapHook)])) : singleton_1(react.createElement(react.Fragment, {})), delay(() => append(singleton_1(react.createElement("div", {
                className: "mb-6",
            }, renderLapsTable(context, activity, dispatch))), delay(() => singleton_1(react.createElement("div", {
                className: "mb-6",
            }, panel([react.createElement("div", {
                className: "mb-4",
            }, tabsWithPages(["Analysis", "Peaks", "Zones", "Versus", "Data"], [() => react.createElement("div", {}, ...ofSeq(delay(() => {
                let value_22, noValue_18;
                return append(singleton_1((value_22 = view_2(new ICardioSegmentGraphProps(altitudeDataPoints, "#718096", "#CBD5E0", false, "Altitude")), (noValue_18 = react.createElement(react.Fragment, {}), (activity.Summary.Metrics.ElevationGainedMeters == null) ? noValue_18 : value_22))), delay(() => append(hasSpeed ? singleton_1(view_2(new ICardioSegmentGraphProps(speedDataPoints, "#3182CE", "#63B3ED", false, "Speed"))) : singleton_1(react.createElement(react.Fragment, {})), delay(() => {
                    let value_24, noValue_20;
                    return append(singleton_1((value_24 = view_2(new ICardioSegmentGraphProps(heartRateDataPoints, "#E53E3E", "#FC8181", true, "Heart Rate")), (noValue_20 = react.createElement(react.Fragment, {}), (activity.Summary.Metrics.AverageHeartRate == null) ? noValue_20 : value_24))), delay(() => {
                        let value_26, noValue_22;
                        return append(singleton_1((value_26 = view_2(new ICardioSegmentGraphProps(powerDataPoints, "#DD6B20", "#F6AD55", true, "Power")), (noValue_22 = react.createElement(react.Fragment, {}), (activity.Summary.Metrics.AveragePower == null) ? noValue_22 : value_26))), delay(() => {
                            let value_28, noValue_24;
                            return singleton_1((value_28 = view_2(new ICardioSegmentGraphProps(cadenceDataPoints, "#38A169", "#68D391", true, "Cadence")), (noValue_24 = react.createElement(react.Fragment, {}), (activity.Summary.Metrics.AverageCadence == null) ? noValue_24 : value_28)));
                        }));
                    }));
                }))));
            }))), () => view_3(new IRenderPeakAnalysisProps(props.Context, props.Activity, props.IsLoading)), () => view_4(new IRenderTimeInZonesnalysisProps(props.Context, props.Activity, props.IsLoading)), () => view_5(new IRenderVersusAnalysisProps(props.Context, props.Activity, props.IsLoading)), () => view_6(context, activity.Summary)])())]))))))))))))))));
        })));
    }, void 0, uncurry(2, void 0), void 0, "renderActivity", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/View.fs", 168);
}

export class ICardioActivityShowProps extends Record {
    constructor(Dispatch, Model, Context) {
        super();
        this.Dispatch = Dispatch;
        this.Model = Model;
        this.Context = Context;
    }
}

export function ICardioActivityShowProps$reflection() {
    return record_type("CardioActivity.Show.View.ICardioActivityShowProps", [], ICardioActivityShowProps, () => [["Dispatch", lambda_type(Msg$reflection(), unit_type)], ["Model", Model$reflection()], ["Context", Context$reflection()]]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    const model = props.Model;
    let isInCart;
    const matchValue = model.Item;
    isInCart = ((matchValue == null) ? false : props.Context.CartManager.isInCart(matchValue.Summary));
    let patternInput;
    if (model.IsLoading) {
        patternInput = [react.createElement(react.Fragment, {}), "", void 0];
    }
    else {
        const matchValue_2 = model.Item;
        if (matchValue_2 == null) {
            patternInput = [react.createElement(react.Fragment, {}), "", void 0];
        }
        else {
            const activity_1 = matchValue_2;
            const toolbarProps = new IToolbarProps(activity_1.Summary.Id, activity_1.Summary, props.Context.Lookups.CardioCategories, props.Context, props.Dispatch, activity_1.Summary.CategoryId);
            const calculatedTitle = CardioSummary__get_CalculatedTitle(activity_1.Summary);
            const calculatedSubTitle = bind((_arg1) => {
                let arg20, arg10;
                return arg20 = toShortDateString(activity_1.Summary.Metrics.RecordedAtUtc), (arg10 = CardioSummary__get_Sport(activity_1.Summary), toText(printf("%s on %s"))(arg10)(arg20));
            }, activity_1.Summary.Title);
            patternInput = [view_7()(toolbarProps), calculatedTitle, calculatedSubTitle];
        }
    }
    return toolbarPage(model.IsLoading ? "Activity loading" : patternInput[1], patternInput[2], model.IsLoading, true, [patternInput[0]], [react.useMemo(() => {
        const matchValue_3 = model.Item;
        if (matchValue_3 == null) {
            return page("Activity Loading", model.IsLoading, [], []);
        }
        else {
            const activity_2 = matchValue_3;
            return react.createElement(react.Fragment, {}, renderActivity(props.Dispatch)(new IRenderActivityProps(props.Context, activity_2, model.IsLoading)));
        }
    }, [model.IsLoading]), model.IsDeleteModalShowing ? view_8(model, props.Dispatch) : react.createElement(react.Fragment, {})]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/View.fs", 375);

