import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { string_type, union_type, class_type, bool_type, record_type, option_type, int32_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ValidationState, ValidationState$reflection } from "../../../../shared/SimpleValidation.fs.js";

export class Item extends Record {
    constructor(Endurance, Moderate, Tempo, Threshold, MaximumHeartRate) {
        super();
        this.Endurance = Endurance;
        this.Moderate = Moderate;
        this.Tempo = Tempo;
        this.Threshold = Threshold;
        this.MaximumHeartRate = MaximumHeartRate;
    }
}

export function Item$reflection() {
    return record_type("HeartRateZones.Create.Types.Item", [], Item, () => [["Endurance", option_type(int32_type)], ["Moderate", option_type(int32_type)], ["Tempo", option_type(int32_type)], ["Threshold", option_type(int32_type)], ["MaximumHeartRate", option_type(int32_type)]]);
}

export function Item_get_Empty() {
    return new Item(void 0, void 0, void 0, void 0, void 0);
}

export class Model extends Record {
    constructor(Zones, IsLoading, IsSaving, IsDirty, ValidationState) {
        super();
        this.Zones = Zones;
        this.IsLoading = IsLoading;
        this.IsSaving = IsSaving;
        this.IsDirty = IsDirty;
        this.ValidationState = ValidationState;
    }
}

export function Model$reflection() {
    return record_type("HeartRateZones.Create.Types.Model", [], Model, () => [["Zones", Item$reflection()], ["IsLoading", bool_type], ["IsSaving", bool_type], ["IsDirty", bool_type], ["ValidationState", ValidationState$reflection()]]);
}

export function Model_get_Empty() {
    return new Model(Item_get_Empty(), true, false, false, new ValidationState(1));
}

export class GetHeartRateZonessRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetHeartRateZonessRes$reflection() {
    return union_type("HeartRateZones.Create.Types.GetHeartRateZonessRes", [], GetHeartRateZonessRes, () => [[["Item", Item$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export class SubmitHeartRateZonesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitHeartRateZonesRes$reflection() {
    return union_type("HeartRateZones.Create.Types.SubmitHeartRateZonesRes", [], SubmitHeartRateZonesRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeEndurance", "ChangeModerate", "ChangeTempo", "ChangeThreshold", "ChangeMaximumHeartRate", "AutoCalculate", "GetHeartRateZoness", "GetHeartRateZonessResult", "SubmitHeartRateZones", "SubmitHeartRateZonesResult"];
    }
}

export function Msg$reflection() {
    return union_type("HeartRateZones.Create.Types.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [], [["Item", GetHeartRateZonessRes$reflection()]], [], [["Item", SubmitHeartRateZonesRes$reflection()]]]);
}

