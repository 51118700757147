import { defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { DashboardPage, Page, currentRoute } from "../../Router.fs.js";

export function isCreate() {
    const matchedPage = defaultArg(currentRoute(), new Page(9, new DashboardPage(0)));
    if (matchedPage.tag === 10) {
        if (matchedPage.fields[0].tag === 2) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

