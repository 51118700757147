import * as react from "react";
import { minBy, maxBy, isEmpty, singleton, append, delay, map, find } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { dateRangeTypeLookup } from "../Types.fs.js";
import { Msg$reflection, Model$reflection, Msg } from "./Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { ClassName, OnChange as OnChange_1, Value as Value_1, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { panelTitle } from "../../Typography.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { addSeconds, toShortDateString, subtract, compare } from "../../.fable/fable-library.3.0.0/Date.js";
import { totalSeconds } from "../../.fable/fable-library.3.0.0/TimeSpan.js";
import { TimeGraphAxis, TimeGraphColor, TimeGraphDataPoint, TimeGraphTick } from "../../TimeGraph.fs.js";
import { Graph_Time_7CAA9E5B, Series_Time_Z1F763A16 } from "../../Graph.fs.js";
import { sampleSizeIsValid, getAggregatePropertyFromLabel, getAggregateLabel, getAggregateValue } from "./State.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { panel, analysisPage } from "../../Layout.fs.js";
import { unstyledIntInput } from "../../Form.fs.js";
import { warningPanel } from "../../Notifications.fs.js";

function embeddedDateRangeTypeDropdown(model, dispatch) {
    const roundedClass = (model.DateRangeType.tag === 8) ? "rounded-r-none border-l" : "border-l";
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: find((tupledArg_1) => equalsSafe(tupledArg_1[0], model.DateRangeType), dateRangeTypeLookup)[1],
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let text;
            dispatch(new Msg(0, (text = Browser_Types_Event__Event_get_Value(ev), find((tupledArg) => (tupledArg[1] === text), dateRangeTypeLookup)[0])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), dateRangeTypeLookup)));
}

function dateRangeEditor(model, context, dispatch) {
    const datePickers = react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, ...ofSeq(delay(() => append(singleton(embeddedDateRangeTypeDropdown(model, dispatch)), delay(() => ((model.DateRangeType.tag === 8) ? append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "from")), delay(() => append(singleton(flatpickr(ofArray([Value_1(model.DateRange.StartDate), OnChange_1((arg) => {
        dispatch(new Msg(1, arg));
    }), ClassName("form-input rounded-none block  transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "to")), delay(() => singleton(flatpickr(ofArray([Value_1(model.DateRange.EndDate), OnChange_1((arg_1) => {
        dispatch(new Msg(2, arg_1));
    }), ClassName("form-input rounded-l-none block transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))))))))) : singleton(react.createElement(react.Fragment, {})))))))));
    return react.createElement("div", {}, panelTitle("Date range"), react.createElement("div", {}, datePickers));
}

export class IRollingAggregateProps extends Record {
    constructor(Context, Model, Dispatch) {
        super();
        this.Context = Context;
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function IRollingAggregateProps$reflection() {
    return record_type("Analysis.RollingAggregates.View.IRollingAggregateProps", [], IRollingAggregateProps, () => [["Context", Context$reflection()], ["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

export const graph = FunctionComponent_Of_Z5A158BBF((props) => {
    let matchValue_1, right, matchValue_2;
    if (isEmpty(props.Model.Aggregates)) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const latestTime = maxBy((l) => l.DateOfResult, props.Model.Aggregates, {
            Compare: compare,
        }).DateOfResult;
        const earliestTime = minBy((l_1) => l_1.DateOfResult, props.Model.Aggregates, {
            Compare: compare,
        }).DateOfResult;
        const ticks = Array.from(map((t) => {
            let copyOfStruct;
            return new TimeGraphTick(~(~(copyOfStruct = subtract(t, earliestTime), totalSeconds(copyOfStruct))), toShortDateString(t));
        }, [earliestTime, latestTime]));
        const roundedRange = (aggregateType_1) => {
            if (aggregateType_1.tag === 2) {
                const roundTo5Mins = 5 * 60;
                return (tupledArg) => [Math.floor(tupledArg[0] / roundTo5Mins) * roundTo5Mins, Math.ceil(tupledArg[1] / roundTo5Mins) * roundTo5Mins];
            }
            else {
                return (tupledArg_1) => [Math.floor(tupledArg_1[0] / 10) * 10, Math.ceil(tupledArg_1[1] / 10) * 10];
            }
        };
        const leftSeries = Series_Time_Z1F763A16(Array.from(map((l_2) => {
            let copyOfStruct_2;
            return new TimeGraphDataPoint(getAggregateValue(props.Model.Left, props.Context, l_2), ~(~(copyOfStruct_2 = subtract(l_2.DateOfResult, earliestTime), totalSeconds(copyOfStruct_2))), false);
        }, props.Model.Aggregates)), new TimeGraphColor(0, "#E53E3E"), void 0, new TimeGraphAxis(0), void 0, void 0, void 0, 2, true);
        return react.createElement("div", {
            style: {
                height: 480,
            },
        }, Graph_Time_7CAA9E5B((matchValue_1 = props.Model.Right, (matchValue_1 != null) ? (right = matchValue_1, [leftSeries, Series_Time_Z1F763A16(Array.from(map((l_3) => {
            let copyOfStruct_3;
            return new TimeGraphDataPoint(getAggregateValue(right, props.Context, l_3), ~(~(copyOfStruct_3 = subtract(l_3.DateOfResult, earliestTime), totalSeconds(copyOfStruct_3))), false);
        }, props.Model.Aggregates)), new TimeGraphColor(0, "#3182CE"), void 0, new TimeGraphAxis(1), void 0, void 0, void 0, 2, true)]) : [leftSeries]), ticks, (matchValue_2 = props.Model.Right, (matchValue_2 == null) ? ((x_2) => x_2) : roundedRange(matchValue_2)), roundedRange(props.Model.Left), void 0, void 0, void 0, void 0, void 0, void 0, "#3182CE", void 0, uncurry(3, void 0), void 0, (series, seriesIndex, dataPoint) => {
            let arg10_1, copyOfStruct_1;
            return [[getAggregateLabel(props.Model.Left), toText(printf("%.0f"))(0)], ["Date", (arg10_1 = (copyOfStruct_1 = addSeconds(earliestTime, dataPoint.TimeInSeconds), toShortDateString(copyOfStruct_1)), toText(printf("%s"))(arg10_1))]];
        }));
    }
}, void 0, uncurry(2, void 0), void 0, "graph", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/RollingAggregates/View.fs", 69);

export function leftAxisEditor(model, dispatch) {
    const isDisabled = model.IsLoading;
    return react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: getAggregateLabel(model.Left),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md " + (isDisabled ? "bg-gray-200" : ""),
        onChange: (ev) => {
            dispatch(new Msg(3, getAggregatePropertyFromLabel(Browser_Types_Event__Event_get_Value(ev))));
        },
        disabled: isDisabled,
    }, ...map((drt) => react.createElement("option", {
        value: getAggregateLabel(drt),
    }, getAggregateLabel(drt)), model.AggregateProperties)))));
}

export function rightAxisEditor(model, dispatch) {
    let values;
    const source2 = map((drt) => react.createElement("option", {
        value: getAggregateLabel(drt),
    }, getAggregateLabel(drt)), model.AggregateProperties);
    values = append([react.createElement("option", {
        value: "",
    }, "None")], source2);
    let selectedValue;
    const matchValue = model.Right;
    selectedValue = ((matchValue == null) ? "" : getAggregateLabel(matchValue));
    const isDisabled = model.IsLoading;
    return react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4",
    }, react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: selectedValue,
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md " + (isDisabled ? "bg-gray-200" : ""),
        onChange: (ev) => {
            let matchValue_1;
            dispatch(new Msg(4, (matchValue_1 = Browser_Types_Event__Event_get_Value(ev), (matchValue_1 === "") ? (void 0) : getAggregatePropertyFromLabel(matchValue_1))));
        },
        disabled: isDisabled,
    }, ...values))));
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    const leftTools = react.createElement("div", {});
    const rightTools = react.createElement("div", {});
    const sampleSizeStyle = sampleSizeIsValid(props.Model.SampleSize) ? "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-r-none" : "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-red-600 focus:border-red-400 rounded-r-none";
    return analysisPage(props.Model.IsLoading, false, leftTools, rightTools, [panel([dateRangeEditor(props.Model, props.Context, props.Dispatch), react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Show"), react.createElement("div", {
        className: "flex flex-row justify-start",
    }, leftAxisEditor(props.Model, props.Dispatch), rightAxisEditor(props.Model, props.Dispatch))), react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Sample Size"), react.createElement("div", {
        className: "flex flex-row justify-start mt-2",
    }, react.createElement("div", {}, unstyledIntInput({
        Class: sampleSizeStyle,
        IsDisabled: props.Model.IsLoading,
        OnBlur: () => {
            props.Dispatch(new Msg(5));
        },
        OnChange: (intOption) => {
            props.Dispatch(new Msg(7, intOption));
        },
        Value: props.Model.SampleSize,
    })), react.createElement("div", {
        className: "inline-flex items-center px-3 rounded rounded-l-none border border-l-0 border-gray-300 bg-gray-50 text-gray-500",
    }, "days")))]), react.createElement("div", {
        className: "mt-6",
    }, panel([isEmpty(props.Model.Aggregates) ? warningPanel("No data available", "There is no training load data available") : react.createElement("div", {}, graph({
        Context: props.Context,
        Model: props.Model,
    }))]))]);
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/RollingAggregates/View.fs", 184);

