import { ValidationItem, isGreaterThan, validateRequired, createValidatorFor, ValidationState as ValidationState_1 } from "../../../../shared/SimpleValidation.fs.js";
import { empty, ofSeq, ofArray, singleton } from "../../.fable/fable-library.3.0.0/List.js";
import { isEmpty, append } from "../../.fable/fable-library.3.0.0/Seq.js";
import { compare } from "../../.fable/fable-library.3.0.0/Util.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { SubmitPowerZonesRes, GetPowerZonessRes, Item, Msg, Model_get_Empty, Model } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { utcNow } from "../../.fable/fable-library.3.0.0/Date.js";
import { some, bind, defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { UserPowerZones } from "../../../../shared/Shared.Models.fs.js";
import { Converters_parseIntFromInput } from "../../Form.fs.js";
import { savePowerZones, getPowerZoness } from "./Api.fs.js";
import { successToast, errorToast } from "../../Notifications.fs.js";
import { DashboardPage, Page, modifyLocation } from "../../Router.fs.js";

function crossValidate(zones) {
    return new ValidationState_1(1);
}

const propertyValidator = createValidatorFor(ofArray([validateRequired("CurrentFTP", (v) => v.CurrentFTP, singleton(isGreaterThan(0))), validateRequired("ActiveRecovery", (v_1) => v_1.ActiveRecovery, singleton(isGreaterThan(0))), validateRequired("Endurance", (v_2) => v_2.Endurance, singleton(isGreaterThan(0))), validateRequired("Tempo", (v_3) => v_3.Tempo, singleton(isGreaterThan(0))), validateRequired("LactateThreshold", (v_4) => v_4.LactateThreshold, singleton(isGreaterThan(0))), validateRequired("VO2Max", (v_5) => v_5.VO2Max, singleton(isGreaterThan(0))), validateRequired("AnaerobicCapacity", (v_6) => v_6.AnaerobicCapacity, singleton(isGreaterThan(0))), validateRequired("Neuromuscular", (v_7) => v_7.Neuromuscular, singleton(isGreaterThan(0)))]));

function crossValidations(item) {
    let source2_5, source2_4, source2_3, source2_2, source2_1;
    const errors = ofSeq((source2_5 = (source2_4 = (source2_3 = (source2_2 = (source2_1 = append((compare(item.Endurance, item.ActiveRecovery) < 0) ? singleton(new ValidationItem(toText(printf("Endurance must be greater than active recovery")), "Endurance", "Endurance\u003eActiveRecovery")) : empty(), []), append((compare(item.Tempo, item.Endurance) < 0) ? singleton(new ValidationItem(toText(printf("Tempo must be greater than endurance")), "Tempo", "Tempo\u003eEndurance")) : empty(), source2_1)), append((compare(item.LactateThreshold, item.Tempo) < 0) ? singleton(new ValidationItem(toText(printf("Lactate threshold must be greater than tempo")), "LactateThreshold", "LactateThreshold\u003eTempo")) : empty(), source2_2)), append((compare(item.VO2Max, item.LactateThreshold) < 0) ? singleton(new ValidationItem(toText(printf("VO2 max must be greater than lactate threshold")), "VO2Max", "VO2Max\u003eLactateThreshold")) : empty(), source2_3)), append((compare(item.AnaerobicCapacity, item.VO2Max) < 0) ? singleton(new ValidationItem(toText(printf("Anaerobic capacity must be greater than VO2 max")), "AnaerobicCapacity", "AnaerobicCapacity\u003eVO2Max")) : empty(), source2_4)), append((compare(item.Neuromuscular, item.AnaerobicCapacity) < 0) ? singleton(new ValidationItem(toText(printf("Neuromuscular must be greater than anaerobic capacity")), "Neuromuscular", "Neuromuscular\u003eAnaerobicCapacity")) : empty(), source2_5)));
    if (isEmpty(errors)) {
        return new ValidationState_1(1);
    }
    else {
        return new ValidationState_1(0, errors);
    }
}

function zonesValidator(item) {
    const propertyResult = propertyValidator(item);
    if (propertyResult.tag === 0) {
        return propertyResult;
    }
    else {
        return crossValidations(item);
    }
}

function validate(model) {
    return new Model(model.Zones, model.IsLoading, model.IsSaving, model.IsDirty, zonesValidator(model.Zones));
}

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(9))];
}

export function toApiModel(zones) {
    return new UserPowerZones(utcNow(), defaultArg(zones.CurrentFTP, 0), defaultArg(zones.ActiveRecovery, 0), defaultArg(zones.Endurance, 0), defaultArg(zones.Tempo, 0), defaultArg(zones.LactateThreshold, 0), defaultArg(zones.VO2Max, 0), defaultArg(zones.AnaerobicCapacity, 0), defaultArg(zones.Neuromuscular, 0));
}

export function update(context, msg, model) {
    let inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord;
    switch (msg.tag) {
        case 0: {
            return [validate(new Model((inputRecord_1 = model.Zones, new Item(Converters_parseIntFromInput(msg.fields[0]), inputRecord_1.Endurance, inputRecord_1.Tempo, inputRecord_1.LactateThreshold, inputRecord_1.VO2Max, inputRecord_1.AnaerobicCapacity, inputRecord_1.Neuromuscular, inputRecord_1.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 5: {
            return [validate(new Model((inputRecord_2 = model.Zones, new Item(inputRecord_2.ActiveRecovery, inputRecord_2.Endurance, inputRecord_2.Tempo, inputRecord_2.LactateThreshold, inputRecord_2.VO2Max, Converters_parseIntFromInput(msg.fields[0]), inputRecord_2.Neuromuscular, inputRecord_2.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 1: {
            return [validate(new Model((inputRecord_3 = model.Zones, new Item(inputRecord_3.ActiveRecovery, Converters_parseIntFromInput(msg.fields[0]), inputRecord_3.Tempo, inputRecord_3.LactateThreshold, inputRecord_3.VO2Max, inputRecord_3.AnaerobicCapacity, inputRecord_3.Neuromuscular, inputRecord_3.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 3: {
            return [validate(new Model((inputRecord_4 = model.Zones, new Item(inputRecord_4.ActiveRecovery, inputRecord_4.Endurance, inputRecord_4.Tempo, Converters_parseIntFromInput(msg.fields[0]), inputRecord_4.VO2Max, inputRecord_4.AnaerobicCapacity, inputRecord_4.Neuromuscular, inputRecord_4.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 6: {
            return [validate(new Model((inputRecord_5 = model.Zones, new Item(inputRecord_5.ActiveRecovery, inputRecord_5.Endurance, inputRecord_5.Tempo, inputRecord_5.LactateThreshold, inputRecord_5.VO2Max, inputRecord_5.AnaerobicCapacity, Converters_parseIntFromInput(msg.fields[0]), inputRecord_5.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 2: {
            return [validate(new Model((inputRecord_6 = model.Zones, new Item(inputRecord_6.ActiveRecovery, inputRecord_6.Endurance, Converters_parseIntFromInput(msg.fields[0]), inputRecord_6.LactateThreshold, inputRecord_6.VO2Max, inputRecord_6.AnaerobicCapacity, inputRecord_6.Neuromuscular, inputRecord_6.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 4: {
            return [validate(new Model((inputRecord_7 = model.Zones, new Item(inputRecord_7.ActiveRecovery, inputRecord_7.Endurance, inputRecord_7.Tempo, inputRecord_7.LactateThreshold, Converters_parseIntFromInput(msg.fields[0]), inputRecord_7.AnaerobicCapacity, inputRecord_7.Neuromuscular, inputRecord_7.CurrentFTP)), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 8: {
            const percentageOfFtp = (percent) => bind((ftp) => (~(~((ftp * percent) / 100))), model.Zones.CurrentFTP);
            return [validate(new Model(new Item(percentageOfFtp(55), percentageOfFtp(75), percentageOfFtp(90), percentageOfFtp(105), percentageOfFtp(120), percentageOfFtp(150), 5000, model.Zones.CurrentFTP), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
        case 9: {
            return [new Model(model.Zones, true, model.IsSaving, model.IsDirty, model.ValidationState), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, getPowerZoness, context, (arg0_1) => (new Msg(10, arg0_1)), (arg) => (new Msg(10, new GetPowerZonessRes(1, arg))))];
        }
        case 10: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Zones, false, model.IsSaving, model.IsDirty, model.ValidationState), Cmd_none()];
            }
            else {
                return [validate(new Model(result.fields[0], false, model.IsSaving, model.IsDirty, model.ValidationState)), Cmd_none()];
            }
        }
        case 11: {
            return [new Model(model.Zones, model.IsLoading, true, model.IsDirty, model.ValidationState), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, savePowerZones, toApiModel(model.Zones), (arg0_4) => (new Msg(12, arg0_4)), (arg_2) => (new Msg(12, new SubmitPowerZonesRes(1, arg_2))))];
        }
        case 12: {
            if (msg.fields[0].tag === 1) {
                return [new Model(model.Zones, model.IsLoading, false, model.IsDirty, model.ValidationState), errorToast("Unable to save power zones")];
            }
            else {
                modifyLocation(new Page(9, new DashboardPage(0)));
                return [new Model(model.Zones, model.IsLoading, false, false, model.ValidationState), successToast("Your power zones have been saved")];
            }
        }
        default: {
            return [validate(new Model((inputRecord = model.Zones, new Item(inputRecord.ActiveRecovery, inputRecord.Endurance, inputRecord.Tempo, inputRecord.LactateThreshold, inputRecord.VO2Max, inputRecord.AnaerobicCapacity, inputRecord.Neuromuscular, Converters_parseIntFromInput(msg.fields[0]))), model.IsLoading, model.IsSaving, true, model.ValidationState)), Cmd_none()];
        }
    }
}

