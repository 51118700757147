import { Record, Union } from "../.fable/fable-library.3.0.0/Types.js";
import { lambda_type, unit_type, float64_type, record_type, union_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { Model_Empty, RecordMetricModalMsg$reflection, Model$reflection as Model$reflection_1 } from "./RecordMetricModal/Types.fs.js";
import { Model_get_Empty as Model_get_Empty_1, UploadActivityMsg$reflection, Model$reflection as Model$reflection_2 } from "./UploadActivityModal/Types.fs.js";
import { MetricType$reflection } from "../../../shared/Shared.Models.fs.js";
import { update as update_1 } from "./UploadActivityModal/State.fs.js";
import { Cmd_none, Cmd_map } from "../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_2 } from "./RecordMetricModal/State.fs.js";
import { modalWrapper } from "../CommonRendering.fs.js";
import { root } from "./RecordMetricModal/View.fs.js";
import { root as root_1 } from "./UploadActivityModal/View.fs.js";
import * as react from "react";

export class ModalVisibility$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Visible", "Hidden"];
    }
}

export function ModalVisibility$1$reflection(gen0) {
    return union_type("Modal.Manager.ModalVisibility`1", [gen0], ModalVisibility$1, () => [[["Item", gen0]], []]);
}

export class Model extends Record {
    constructor(RecordMetricModal, UploadActivityModal) {
        super();
        this.RecordMetricModal = RecordMetricModal;
        this.UploadActivityModal = UploadActivityModal;
    }
}

export function Model$reflection() {
    return record_type("Modal.Manager.Model", [], Model, () => [["RecordMetricModal", ModalVisibility$1$reflection(Model$reflection_1())], ["UploadActivityModal", ModalVisibility$1$reflection(Model$reflection_2())]]);
}

export function Model_get_Empty() {
    return new Model(new ModalVisibility$1(1), new ModalVisibility$1(1));
}

export class IModalManager extends Record {
    constructor(openRecordMetricModal, openUploadModal) {
        super();
        this.openRecordMetricModal = openRecordMetricModal;
        this.openUploadModal = openUploadModal;
    }
}

export function IModalManager$reflection() {
    return record_type("Modal.Manager.IModalManager", [], IModalManager, () => [["openRecordMetricModal", lambda_type(MetricType$reflection(), lambda_type(float64_type, unit_type))], ["openUploadModal", lambda_type(unit_type, unit_type)]]);
}

export function IModalManager_get_NullManager() {
    return new IModalManager((_arg2, _arg1) => {
    }, () => {
    });
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowUploadActivityModal", "ShowRecordMetricModal", "HideModal", "RecordMetricModalMsg", "UploadActivityModalMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Modal.Manager.Msg", [], Msg, () => [[], [["Item1", MetricType$reflection()], ["Item2", float64_type]], [], [["Item", RecordMetricModalMsg$reflection()]], [["Item", UploadActivityMsg$reflection()]]]);
}

export function update(context, msg, model) {
    const matchValue = [msg, model];
    let pattern_matching_result, extractedModel, subMsg, extractedModel_1, subMsg_1, initialValue, metricType;
    if (matchValue[0].tag === 3) {
        if (matchValue[1].RecordMetricModal.tag === 0) {
            pattern_matching_result = 1;
            extractedModel_1 = matchValue[1].RecordMetricModal.fields[0];
            subMsg_1 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else if (matchValue[0].tag === 1) {
        pattern_matching_result = 2;
        initialValue = matchValue[0].fields[1];
        metricType = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 0) {
        pattern_matching_result = 3;
    }
    else if (matchValue[0].tag === 2) {
        pattern_matching_result = 4;
    }
    else if (matchValue[1].UploadActivityModal.tag === 0) {
        pattern_matching_result = 0;
        extractedModel = matchValue[1].UploadActivityModal.fields[0];
        subMsg = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 5;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(context, subMsg, extractedModel);
            return [new Model(model.RecordMetricModal, patternInput[2] ? (new ModalVisibility$1(1)) : (new ModalVisibility$1(0, patternInput[0]))), Cmd_map((arg0_1) => (new Msg(4, arg0_1)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(context, subMsg_1, extractedModel_1);
            return [new Model(patternInput_1[2] ? (new ModalVisibility$1(1)) : (new ModalVisibility$1(0, patternInput_1[0])), model.UploadActivityModal), Cmd_map((arg0_3) => (new Msg(3, arg0_3)), patternInput_1[1])];
        }
        case 2: {
            return [new Model(new ModalVisibility$1(0, Model_Empty(metricType, initialValue)), model.UploadActivityModal), Cmd_none()];
        }
        case 3: {
            return [new Model(model.RecordMetricModal, new ModalVisibility$1(0, Model_get_Empty_1())), Cmd_none()];
        }
        case 4: {
            return [Model_get_Empty(), Cmd_none()];
        }
        case 5: {
            return [model, Cmd_none()];
        }
    }
}

export function view(context, model, dispatch) {
    if (model.RecordMetricModal.tag === 0) {
        return modalWrapper((_arg1) => {
            dispatch(new Msg(2));
        }, root(context, model.RecordMetricModal.fields[0], (arg) => {
            dispatch(new Msg(3, arg));
        }));
    }
    else if (model.UploadActivityModal.tag === 0) {
        return modalWrapper((_arg2) => {
            dispatch(new Msg(2));
        }, root_1(model.UploadActivityModal.fields[0], (arg_1) => {
            dispatch(new Msg(4, arg_1));
        }));
    }
    else {
        return react.createElement(react.Fragment, {});
    }
}

