import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { ApiException, workoutTemplate } from "../../Server.fs.js";
import { Item, GetProgrammesRes } from "./Types.fs.js";
import { map } from "../../.fable/fable-library.3.0.0/List.js";

export function getProgrammes(context) {
    return singleton.Delay(() => singleton.Bind(workoutTemplate((f) => f.getAll()), (_arg1) => {
        const response = _arg1;
        return (response.tag === 0) ? singleton.Return(new GetProgrammesRes(0, map((i) => (new Item(i.Id, i.IsCreatedByCurrentUser, i.Name, i.Description)), response.fields[0]))) : singleton.Return(new GetProgrammesRes(1, new ApiException()));
    }));
}

