import { ITrainingLogProps, root as root_1 } from "../Index/View.fs.js";
import { Msg } from "./Types.fs.js";
import { IBubbleTrainingLogProps, root as root_2 } from "../Bubble/View.fs.js";
import { root as root_3 } from "../List/View.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import * as react from "react";

export function root(context, model, dispatch) {
    let pattern_matching_result, extractedModel, extractedModel_1, extractedModel_2;
    if (model.CurrentPage.tag === 0) {
        if (model.BubbleModel != null) {
            pattern_matching_result = 1;
            extractedModel_1 = model.BubbleModel;
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (model.CurrentPage.tag === 1) {
        if (model.ListModel != null) {
            pattern_matching_result = 2;
            extractedModel_2 = model.ListModel;
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (model.IndexModel != null) {
        pattern_matching_result = 0;
        extractedModel = model.IndexModel;
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            return root_1(new ITrainingLogProps(context, (arg) => {
                dispatch(new Msg(0, arg));
            }, extractedModel));
        }
        case 1: {
            return root_2(new IBubbleTrainingLogProps(context, (arg_1) => {
                dispatch(new Msg(2, arg_1));
            }, extractedModel_1));
        }
        case 2: {
            return root_3(extractedModel_2, context, (arg_2) => {
                dispatch(new Msg(1, arg_2));
            });
        }
        case 3: {
            console.log(some("[WeeklyTrainingLog.Dispatcher.View] Page not found"));
            return react.createElement("div", {}, "Page not found");
        }
    }
}

