import { Model_Empty_1505 } from "./Types.fs.js";
import { Cmd_none } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";

export function init(id) {
    return [Model_Empty_1505(id), Cmd_none()];
}

export function update(context, msg, model) {
    return [model, Cmd_none()];
}

