import { addDays, today } from "../../.fable/fable-library.3.0.0/Date.js";
import { RollingAggregate$reflection, DateRange$reflection, DateRange as DateRange_1 } from "../../../../shared/Shared.Models.fs.js";
import { Record, Union } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type, record_type, array_type, option_type, int32_type, bool_type, union_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { DateRangeType as DateRangeType_1, DateRangeType$reflection } from "../Types.fs.js";

export function last90Days() {
    let copyOfStruct;
    return new DateRange_1((copyOfStruct = today(), addDays(copyOfStruct, -90)), today());
}

export class Aggregate extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Average", "Sum"];
    }
}

export function Aggregate$reflection() {
    return union_type("Analysis.RollingAggregates.Types.Aggregate", [], Aggregate, () => [[], []]);
}

export class AggregateProperty extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TrainingStress", "DistanceMeters", "TimeSeconds", "AverageHeartRate", "MaximumHeartRate", "Calories", "AverageCadence", "MaximumCadence", "AverageSpeedMetersPerSecond", "MaximumSpeedMetersPerSecond", "AveragePower", "MaximumPower", "NormalisedPower", "ElevationGainedMeters"];
    }
}

export function AggregateProperty$reflection() {
    return union_type("Analysis.RollingAggregates.Types.AggregateProperty", [], AggregateProperty, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Model extends Record {
    constructor(IsLoading, DateRangeType, DateRange, Aggregate, SampleSize, Aggregates, AggregateProperties, Left, Right) {
        super();
        this.IsLoading = IsLoading;
        this.DateRangeType = DateRangeType;
        this.DateRange = DateRange;
        this.Aggregate = Aggregate;
        this.SampleSize = SampleSize;
        this.Aggregates = Aggregates;
        this.AggregateProperties = AggregateProperties;
        this.Left = Left;
        this.Right = Right;
    }
}

export function Model$reflection() {
    return record_type("Analysis.RollingAggregates.Types.Model", [], Model, () => [["IsLoading", bool_type], ["DateRangeType", DateRangeType$reflection()], ["DateRange", DateRange$reflection()], ["Aggregate", Aggregate$reflection()], ["SampleSize", option_type(int32_type)], ["Aggregates", array_type(RollingAggregate$reflection())], ["AggregateProperties", array_type(AggregateProperty$reflection())], ["Left", AggregateProperty$reflection()], ["Right", option_type(AggregateProperty$reflection())]]);
}

export function Model_get_Empty() {
    return new Model(false, new DateRangeType_1(2), last90Days(), new Aggregate(0), 7, new Array(0), new Array(0), new AggregateProperty(0), void 0);
}

export class GetAggregatesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetAggregatesRes$reflection() {
    return union_type("Analysis.RollingAggregates.Types.GetAggregatesRes", [], GetAggregatesRes, () => [[["Item", array_type(RollingAggregate$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeDateRangeType", "ChangeStartDate", "ChangeEndDate", "ChangeLeft", "ChangeRight", "GetAggregates", "GetAggregatesResult", "ChangeSampleSize"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.RollingAggregates.Types.Msg", [], Msg, () => [[["Item", DateRangeType$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", class_type("System.DateTime")]], [["Item", AggregateProperty$reflection()]], [["Item", option_type(AggregateProperty$reflection())]], [], [["Item", GetAggregatesRes$reflection()]], [["Item", option_type(int32_type)]]]);
}

