import { ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { tryFind, append, map, sortWith } from "../../.fable/fable-library.3.0.0/Seq.js";
import { equalsSafe, numberHash, comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { distinct } from "../../.fable/fable-library.3.0.0/Set.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";

export function distinctDurations(activity) {
    let projection;
    return ofSeq((projection = ((x_1) => x_1), sortWith((x_2, y_1) => comparePrimitives(projection(x_2), projection(y_1)), distinct(map((p) => p.PeakDurationSeconds, append(activity.PowerPeaks, activity.HeartPeaks)), {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    }))));
}

export function durationAsString(duration) {
    if (duration >= 60) {
        const arg20 = ((~(~(duration / 60))) > 1) ? "s" : "";
        const arg10 = (~(~(duration / 60))) | 0;
        return toText(printf("%d minute%s"))(arg10)(arg20);
    }
    else {
        return toText(printf("%d seconds"))(duration);
    }
}

export function powerForDuration(duration, lap, activity) {
    const peak = tryFind((p) => {
        if (p.PeakDurationSeconds === duration) {
            return equalsSafe(p.Lap, lap);
        }
        else {
            return false;
        }
    }, activity.PowerPeaks);
    if (peak == null) {
        return "";
    }
    else {
        const watts = peak;
        return toText(printf("%.0f"))(watts.PeakValue);
    }
}

export function speedForDuration(duration, lap, activity) {
    const peak = tryFind((p) => {
        if (p.PeakDurationSeconds === duration) {
            return equalsSafe(p.Lap, lap);
        }
        else {
            return false;
        }
    }, activity.SpeedPeaks);
    if (peak == null) {
        return "";
    }
    else {
        const speed = peak;
        return toText(printf("%.1f"))(speed.PeakValue);
    }
}

export function ascentForDuration(duration, lap, activity) {
    const peak = tryFind((p) => {
        if (p.PeakDurationSeconds === duration) {
            return equalsSafe(p.Lap, lap);
        }
        else {
            return false;
        }
    }, activity.AscentPeaks);
    if (peak == null) {
        return "";
    }
    else {
        const speed = peak;
        return toText(printf("%.1f"))(speed.PeakValue);
    }
}

export function heartRateForDuration(duration, lap, activity) {
    const peak = tryFind((p) => {
        if (p.PeakDurationSeconds === duration) {
            return equalsSafe(p.Lap, lap);
        }
        else {
            return false;
        }
    }, activity.HeartPeaks);
    if (peak == null) {
        return "";
    }
    else {
        const bpm = peak;
        return toText(printf("%.0f"))(bpm.PeakValue);
    }
}

