import { Toastr_success, Toastr_warning, Toastr_message, Toastr_position, Toastr_timeout, Toastr_withProgressBar, Toastr_hideEasing, Toastr_showCloseButton, Toastr_error } from "./.fable/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import * as react from "react";

export function errorToast(message) {
    return Toastr_error(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_withProgressBar(Toastr_timeout(3000, Toastr_position("toast-top-right", Toastr_message(message)))))));
}

export function warningToast(message) {
    return Toastr_warning(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_withProgressBar(Toastr_timeout(3000, Toastr_position("toast-top-right", Toastr_message(message)))))));
}

export function successToast(message) {
    return Toastr_success(Toastr_showCloseButton(Toastr_hideEasing("swing", Toastr_withProgressBar(Toastr_timeout(3000, Toastr_position("toast-top-right", Toastr_message(message)))))));
}

export function warningPanel(title, message) {
    return react.createElement("div", {
        className: "rounded-md bg-yellow-50 p-4",
    }, react.createElement("div", {
        className: "flex",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, react.createElement("svg", {
        className: "h-5 w-5 text-yellow-400",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
        clipRule: "evenodd",
    }))), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("h3", {
        className: "text-sm leading-5 font-medium text-yellow-800",
    }, title), react.createElement("div", {
        className: "mt-2 text-sm leading-5 text-yellow-700",
    }, react.createElement("p", {}, message)))));
}

export function errorPanel(title, message) {
    return react.createElement("div", {
        className: "rounded-md bg-red-50 p-4",
    }, react.createElement("div", {
        className: "flex",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, react.createElement("svg", {
        className: "h-5 w-5 text-red-400",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
        clipRule: "evenodd",
    }))), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("h3", {
        className: "text-sm leading-5 font-medium text-red-800",
    }, title), react.createElement("div", {
        className: "mt-2 text-sm leading-5 text-red-800",
    }, react.createElement("p", {}, message)))));
}

export function warningPanelWithElement(title, element) {
    return react.createElement("div", {
        className: "rounded-md bg-yellow-50 p-4",
    }, react.createElement("div", {
        className: "flex",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, react.createElement("svg", {
        className: "h-5 w-5 text-yellow-400",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
        clipRule: "evenodd",
    }))), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("h3", {
        className: "text-sm leading-5 font-medium text-yellow-800",
    }, title), react.createElement("div", {
        className: "mt-2 text-sm leading-5 text-yellow-700",
    }, react.createElement("p", {}, element)))));
}

export function infoPanel(title, message) {
    return react.createElement("div", {
        className: "rounded-md bg-blue-50 p-4",
    }, react.createElement("div", {
        className: "flex",
    }, react.createElement("div", {
        className: "flex-shrink-0",
    }, react.createElement("svg", {
        className: "h-5 w-5 text-blue-400",
        fill: "currentColor",
        viewbox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
        clipRule: "evenodd",
    }))), react.createElement("div", {
        className: "ml-3",
    }, react.createElement("h3", {
        className: "text-sm leading-5 font-medium text-blue-800",
    }, title), react.createElement("div", {
        className: "mt-2 text-sm leading-5 text-blue-700",
    }, react.createElement("p", {}, message)))));
}

