import { GetPeaksAndTargetsRes, Model, Msg, Model_get_Empty } from "./Types.fs.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { tryFindIndex, max } from "../../.fable/fable-library.3.0.0/Seq.js";
import { comparePrimitives } from "../../.fable/fable-library.3.0.0/Util.js";
import { Peaks_definedPeakDurations } from "../../../../shared/Shared.fs.js";
import { item, length } from "../../.fable/fable-library.3.0.0/List.js";
import { getAnalysis } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init() {
    return [Model_get_Empty(), Cmd_OfFunc_result(new Msg(2))];
}

export function getNextDuration(existingDurations) {
    const maxDuration = max(existingDurations, {
        Compare: comparePrimitives,
    }) | 0;
    const possibleExistingIndex = tryFindIndex((d) => (d === maxDuration), Peaks_definedPeakDurations);
    if (possibleExistingIndex == null) {
        return (5 * 60) | 0;
    }
    else {
        const index = possibleExistingIndex | 0;
        if ((index + 1) < (length(Peaks_definedPeakDurations) - 1)) {
            return item(index + 1, Peaks_definedPeakDurations) | 0;
        }
        else {
            return (5 * 60) | 0;
        }
    }
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 1: {
            return [new Model(model.IsLoading, msg.fields[0], model.AnalysisCardioType, model.Targets, model.Peaks), Cmd_OfFunc_result(new Msg(2))];
        }
        case 2: {
            return [new Model(true, model.AnalysisDateRange, model.AnalysisCardioType, model.Targets, model.Peaks), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getAnalysis(model.AnalysisDateRange, model.AnalysisCardioType, context_1), context, (arg0) => (new Msg(3, arg0)), (arg) => (new Msg(3, new GetPeaksAndTargetsRes(1, arg))))];
        }
        case 3: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.AnalysisDateRange, model.AnalysisCardioType, model.Targets, model.Peaks), Cmd_none()];
            }
            else {
                return [new Model(false, model.AnalysisDateRange, model.AnalysisCardioType, result.fields[1], result.fields[0]), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.AnalysisDateRange, msg.fields[0], model.Targets, model.Peaks), Cmd_OfFunc_result(new Msg(2))];
        }
    }
}

