import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { TrainingLogSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { union_type, class_type, record_type, bool_type, int32_type, list_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { ofArray, empty } from "../../.fable/fable-library.3.0.0/List.js";
import { year, month, today } from "../../.fable/fable-library.3.0.0/Date.js";

export class Model extends Record {
    constructor(Items, SelectedMonthIndex, SelectedYearIndex, Years, IsLoading) {
        super();
        this.Items = Items;
        this.SelectedMonthIndex = (SelectedMonthIndex | 0);
        this.SelectedYearIndex = (SelectedYearIndex | 0);
        this.Years = Years;
        this.IsLoading = IsLoading;
    }
}

export function Model$reflection() {
    return record_type("WeeklyTrainingLog.Index.Types.Model", [], Model, () => [["Items", list_type(TrainingLogSummary$reflection())], ["SelectedMonthIndex", int32_type], ["SelectedYearIndex", int32_type], ["Years", list_type(int32_type)], ["IsLoading", bool_type]]);
}

export function Model_get_Empty() {
    let copyOfStruct, copyOfStruct_1, copyOfStruct_2, copyOfStruct_3;
    return new Model(empty(), (copyOfStruct = today(), month(copyOfStruct)) - 1, 1, ofArray([(copyOfStruct_1 = today(), year(copyOfStruct_1)) - 1, (copyOfStruct_2 = today(), year(copyOfStruct_2)), (copyOfStruct_3 = today(), year(copyOfStruct_3)) + 1]), true);
}

export class GetWeeklyTrainingLogsRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetWeeklyTrainingLogsRes$reflection() {
    return union_type("WeeklyTrainingLog.Index.Types.GetWeeklyTrainingLogsRes", [], GetWeeklyTrainingLogsRes, () => [[["Item", list_type(TrainingLogSummary$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetWeeklyTrainingLogs", "GetWeeklyTrainingLogsResult", "SetSelectedMonthIndex", "SetSelectedYearIndex", "GotoPreviousMonth", "GotoNextMonth"];
    }
}

export function Msg$reflection() {
    return union_type("WeeklyTrainingLog.Index.Types.Msg", [], Msg, () => [[], [["Item", GetWeeklyTrainingLogsRes$reflection()]], [["Item", int32_type]], [["Item", int32_type]], [], []]);
}

