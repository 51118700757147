import * as react from "react";
import { exists, collect, isEmpty, singleton, delay, mapIndexed, concat, append, map, find } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, partialApply, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { availablePeakTypes, cardioTypeFromString, dateRangeTypeLookup } from "../Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { Msg$reflection, Model$reflection, Msg } from "./Types.fs.js";
import { PeakPerformanceType, CategoryIdIncludingAll } from "../../../../shared/Shared.Models.fs.js";
import { Id_value } from "../../../../shared/Shared.Types.fs.js";
import { renderPeakPerformanceTable, activityTypeEditor, seriesIndicator } from "../Helpers.fs.js";
import { empty, singleton as singleton_1, ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { ClassName, OnChange, Value, flatpickr } from "../../.fable/Fable.React.Flatpickr.3.0.0/Flatpickr.fs.js";
import { IconSize, trashIcon } from "../../Icons.fs.js";
import { analysisPage, tabs as tabs_1, panel } from "../../Layout.fs.js";
import { panelTitle } from "../../Typography.fs.js";
import { plain } from "../../Buttons.fs.js";
import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { Context$reflection } from "../../Context.fs.js";
import { record_type, lambda_type, unit_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { warningPanel } from "../../Notifications.fs.js";
import { IRenderPeakPerformancesProps, view } from "../RenderMax.fs.js";
import { IRenderAveragePerformancesProps, view as view_1 } from "./RenderAverage.fs.js";
import { checkBox, radioButton } from "../../Form.fs.js";
import { IRollingAnalysisModalProps, view as view_2 } from "./RollingAnalysisModal.fs.js";

function embeddedDateRangeTypeDropdown(model, index, dispatch) {
    let ltype;
    const roundedClass = (model.DateRangeTypes[index].tag === 8) ? "rounded-r-none border-l" : "rounded-r-none border-l";
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: (ltype = model.DateRangeTypes[index], find((tupledArg_1) => equalsSafe(tupledArg_1[0], ltype), dateRangeTypeLookup)[1]),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let tupledArg_2, text;
            dispatch((tupledArg_2 = [index, (text = Browser_Types_Event__Event_get_Value(ev), find((tupledArg) => (tupledArg[1] === text), dateRangeTypeLookup)[0])], new Msg(4, tupledArg_2[0], tupledArg_2[1])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), dateRangeTypeLookup)));
}

function embeddedCategoryDropdown(model, context, index, dispatch) {
    const roundedClass = (model.DateRangeTypes[index].tag === 8) ? "rounded-l-none rounded-r-none border-r" : "rounded-l-none rounded-r-none border-r";
    const lookupAsString = (category) => {
        if (category.tag === 1) {
            return category.fields[0];
        }
        else {
            return "all";
        }
    };
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: lookupAsString(model.Categories[index]),
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 " + roundedClass,
        onChange: (ev) => {
            let tupledArg, value;
            dispatch((tupledArg = [index, (value = Browser_Types_Event__Event_get_Value(ev), (value === "all") ? (new CategoryIdIncludingAll(0)) : (new CategoryIdIncludingAll(1, value)))], new Msg(5, tupledArg[0], tupledArg[1])));
        },
    }, ...map((drt) => react.createElement("option", {
        value: lookupAsString(drt[0]),
    }, drt[1]), Array.from(append([[new CategoryIdIncludingAll(0), "All"]], map((c) => [new CategoryIdIncludingAll(1, Id_value(c.Id)), c.DisplayText], context.Lookups.CardioCategories))))));
}

function dateRangeEditors(model, context, dispatch) {
    const datePickers = Array.from(concat(mapIndexed((index, dr) => singleton_1(react.createElement("div", {
        className: "flex flex-row justify-start items-center mt-2",
    }, react.createElement("div", {
        className: "mt-1",
    }, seriesIndicator(index)), react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4 mt-1",
    }, ...ofSeq(delay(() => append(singleton(embeddedDateRangeTypeDropdown(model, index, dispatch)), delay(() => append((model.DateRangeTypes[index].tag === 8) ? append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "from")), delay(() => append(singleton(flatpickr(ofArray([Value(dr.StartDate), OnChange((newValue) => {
        let tupledArg;
        dispatch((tupledArg = [index, newValue], new Msg(2, tupledArg[0], tupledArg[1])));
    }), ClassName("form-input rounded-none block  transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "to")), delay(() => singleton(flatpickr(ofArray([Value(dr.EndDate), OnChange((newValue_1) => {
        let tupledArg_1;
        dispatch((tupledArg_1 = [index, newValue_1], new Msg(3, tupledArg_1[0], tupledArg_1[1])));
    }), ClassName("form-input rounded-l-none block transition duration-150 ease-in-out sm:text-sm sm:leading-5")]))))))))) : singleton(react.createElement(react.Fragment, {})), delay(() => append(singleton(react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "for")), delay(() => append(singleton(embeddedCategoryDropdown(model, context, index, dispatch)), delay(() => singleton(react.createElement("button", {
        className: "inline-flex items-center px-3 border border-l-0 rounded-r border-gray-300 bg-gray-50 sm:text-sm text-red-300 " + ((model.Ranges.length === 1) ? "text-orange-300" : "text-red-700"),
        disabled: model.Ranges.length === 1,
        onClick: (_arg1) => {
            dispatch(new Msg(6, index));
        },
    }, trashIcon(new IconSize(3))))))))))))))))), model.Ranges)));
    return panel([activityTypeEditor(model.AnalysisCardioType, (arg_1) => {
        dispatch(new Msg(0, cardioTypeFromString(arg_1)));
    }, false), react.createElement("div", {
        className: "mt-4",
    }, panelTitle("Date ranges")), react.createElement("div", {
        className: "mt-2",
    }, ...append(datePickers, [react.createElement("div", {
        className: "text-left flex justify-start mt-6",
    }, react.createElement("div", {
        className: "mr-2 flex",
        style: {
            width: 150,
        },
    }, plain("Add Date Range", () => {
        dispatch(new Msg(1));
    }, false)), react.createElement("div", {
        className: "ml-2 flex",
        style: {
            width: 150,
        },
    }, plain("Rolling Analysis", () => {
        dispatch(new Msg(9));
    }, false)))]))]);
}

export class IPeakPerformancesProps extends Record {
    constructor(Context, Model, Dispatch) {
        super();
        this.Context = Context;
        this.Model = Model;
        this.Dispatch = Dispatch;
    }
}

export function IPeakPerformancesProps$reflection() {
    return record_type("Analysis.PeakPerformances.View.IPeakPerformancesProps", [], IPeakPerformancesProps, () => [["Context", Context$reflection()], ["Model", Model$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)]]);
}

export const root = FunctionComponent_Of_Z5A158BBF((props) => {
    let source2_3, source2_2, source2_1;
    const leftTools = react.createElement("div", {});
    const rightTools = react.createElement("div", {});
    const selectedTabIndex = react.useState(0);
    const isMaximum = react.useState(true);
    const showWattsKg = react.useState(false);
    const maxPeaksTab = (peakType) => {
        if (isEmpty(concat(props.Model.MaxPeaks))) {
            return warningPanel("No data available", "There are no peaks available in those date ranges");
        }
        else {
            return view(new IRenderPeakPerformancesProps(props.Context, Array.from(map((source_1) => Array.from(source_1), props.Model.MaxPeaks)), props.Model.MaxSummaries, peakType, (peaks, peakType_1) => renderPeakPerformanceTable(peaks, peakType_1, props.Context, props.Model.MaxSummaries)));
        }
    };
    const collectedPeaks = collect((x) => x, props.Model.MaxPeaks);
    const patternInput = availablePeakTypes(collectedPeaks);
    const hasWattsKgPeak = exists((f) => equalsSafe(f.PeakType, new PeakPerformanceType(2)), collectedPeaks);
    const peakRenderer = (peakType_3, isMax) => {
        if (isMax) {
            return maxPeaksTab(peakType_3);
        }
        else if (isEmpty(concat(props.Model.AvgPeaks))) {
            return warningPanel("No data available", "There are no peaks available in those date ranges");
        }
        else {
            return view_1(new IRenderAveragePerformancesProps(props.Context, Array.from(map((source_5) => Array.from(source_5), props.Model.AvgPeaks)), peakType_3));
        }
    };
    const tabPageMatches = Array.from((source2_3 = (source2_2 = (source2_1 = append(patternInput[3] ? singleton_1(partialApply(1, peakRenderer, [new PeakPerformanceType(3)])) : empty(), []), append(patternInput[2] ? singleton_1(partialApply(1, peakRenderer, [new PeakPerformanceType(4)])) : empty(), source2_1)), append(patternInput[1] ? singleton_1(partialApply(1, peakRenderer, [new PeakPerformanceType(0)])) : empty(), source2_2)), append(patternInput[0] ? singleton_1(partialApply(1, peakRenderer, [new PeakPerformanceType(1)])) : empty(), source2_3)));
    const tabs = (tabPageMatches.length > 0) ? react.createElement("div", {
        className: "mt-6",
    }, panel([react.createElement("div", {
        className: "-mt-2",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "mb-4",
    }, tabs_1(patternInput[4], selectedTabIndex[0], (arg00) => {
        selectedTabIndex[1](arg00);
    }))), delay(() => append(singleton(react.createElement("div", {
        className: "pb-4 flex flex-row justify-between items-center",
    }, ...ofSeq(delay(() => append(singleton(react.createElement("div", {
        className: "flex flex-row justify-start",
    }, react.createElement("div", {}, radioButton("Maximum", "maximum", isMaximum[0], () => {
        isMaximum[1](true);
    })), react.createElement("div", {
        className: "ml-4",
    }, radioButton("Average", "average", !(isMaximum[0]), () => {
        isMaximum[1](false);
    })))), delay(() => {
        const matchValue_3 = [selectedTabIndex[0], isMaximum[0], hasWattsKgPeak];
        let pattern_matching_result;
        if (matchValue_3[0] === 0) {
            if (matchValue_3[1]) {
                if (matchValue_3[2]) {
                    pattern_matching_result = 0;
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return singleton(react.createElement("div", {}, checkBox("Show watts/kg", showWattsKg[0], (arg00_1) => {
                    showWattsKg[1](arg00_1);
                })));
            }
            case 1: {
                return singleton(react.createElement(react.Fragment, {}));
            }
        }
    })))))), delay(() => {
        const matchValue_4 = [selectedTabIndex[0], isMaximum[0], showWattsKg[0]];
        let pattern_matching_result_1;
        if (matchValue_4[0] === 0) {
            if (matchValue_4[1]) {
                if (matchValue_4[2]) {
                    pattern_matching_result_1 = 0;
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                return singleton(maxPeaksTab(new PeakPerformanceType(2)));
            }
            case 1: {
                return singleton(((selectedTabIndex[0]) < tabPageMatches.length) ? tabPageMatches[selectedTabIndex[0]](isMaximum[0]) : react.createElement(react.Fragment, {}));
            }
        }
    })))))))])) : react.createElement(react.Fragment, {});
    return analysisPage(props.Model.IsLoading, false, leftTools, rightTools, ofSeq(delay(() => append(props.Model.ShowRollingAnalysisModal ? singleton(view_2(new IRollingAnalysisModalProps(props.Model, props.Dispatch, props.Context))) : singleton(react.createElement(react.Fragment, {})), delay(() => append(singleton(dateRangeEditors(props.Model, props.Context, props.Dispatch)), delay(() => singleton(tabs))))))));
}, void 0, uncurry(2, void 0), void 0, "root", "/home/runner/work/strengthPlus/strengthPlus/client/src/Analysis/PeakPerformances/View.fs", 114);

