import { Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { update as update_2, init as init_1 } from "../Bubble/State.fs.js";
import { Cmd_none, Cmd_map } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_3, init as init_2 } from "../List/State.fs.js";
import { update as update_1, init as init_3 } from "../Index/State.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(page) {
    let model;
    const inputRecord = Model_get_Empty();
    model = (new Model(page, inputRecord.IndexModel, inputRecord.ListModel, inputRecord.BubbleModel));
    switch (page.tag) {
        case 0: {
            const patternInput_1 = init_1();
            return [new Model(model.CurrentPage, model.IndexModel, model.ListModel, patternInput_1[0]), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
        }
        case 1: {
            const patternInput_2 = init_2(page.fields[0][0], page.fields[0][1], page.fields[0][2]);
            return [new Model(model.CurrentPage, model.IndexModel, patternInput_2[0], model.BubbleModel), Cmd_map((arg0_2) => (new Msg(1, arg0_2)), patternInput_2[1])];
        }
        default: {
            const patternInput = init_3(page.fields[0][0], page.fields[0][1]);
            return [new Model(model.CurrentPage, patternInput[0], model.ListModel, model.BubbleModel), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
        }
    }
}

export function update(context, msg, model) {
    const matchValue = [msg, model];
    let pattern_matching_result, extractedModel, msg_1, extractedModel_1, msg_2, extractedModel_2, msg_3;
    if (matchValue[0].tag === 2) {
        if (matchValue[1].BubbleModel != null) {
            pattern_matching_result = 1;
            extractedModel_1 = matchValue[1].BubbleModel;
            msg_2 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[0].tag === 1) {
        if (matchValue[1].ListModel != null) {
            pattern_matching_result = 2;
            extractedModel_2 = matchValue[1].ListModel;
            msg_3 = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[1].IndexModel != null) {
        pattern_matching_result = 0;
        extractedModel = matchValue[1].IndexModel;
        msg_1 = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            const patternInput = update_1(context, msg_1, extractedModel);
            return [new Model(model.CurrentPage, patternInput[0], model.ListModel, model.BubbleModel), Cmd_map((arg0) => (new Msg(0, arg0)), patternInput[1])];
        }
        case 1: {
            const patternInput_1 = update_2(context, msg_2, extractedModel_1);
            return [new Model(model.CurrentPage, model.IndexModel, model.ListModel, patternInput_1[0]), Cmd_map((arg0_1) => (new Msg(2, arg0_1)), patternInput_1[1])];
        }
        case 2: {
            const patternInput_2 = update_3(context, msg_3, extractedModel_2);
            return [new Model(model.CurrentPage, model.IndexModel, patternInput_2[0], model.BubbleModel), Cmd_map((arg0_2) => (new Msg(1, arg0_2)), patternInput_2[1])];
        }
        case 3: {
            console.log(some("[WeeklyTrainingLog.Dispatcher.State] Discarded message"));
            return [model, Cmd_none()];
        }
    }
}

