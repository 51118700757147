import { addDays, today } from "../../.fable/fable-library.3.0.0/Date.js";
import { CardioType, CategoryIdIncludingAll, CardioType$reflection, CardioSummary$reflection, PeakPerformance$reflection, AggregatePeakPerformance$reflection, CategoryIdIncludingAll$reflection, DateRange$reflection, DateRange } from "../../../../shared/Shared.Models.fs.js";
import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { int32_type, union_type, class_type, record_type, array_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { DateRangeType, DateRangeType$reflection } from "../Types.fs.js";

export function last30Days() {
    let copyOfStruct;
    return new DateRange((copyOfStruct = today(), addDays(copyOfStruct, -30)), today());
}

export class Model extends Record {
    constructor(IsLoading, DateRangeTypes, Ranges, Categories, AvgPeaks, MaxPeaks, MaxSummaries, AnalysisCardioType, ShowRollingAnalysisModal) {
        super();
        this.IsLoading = IsLoading;
        this.DateRangeTypes = DateRangeTypes;
        this.Ranges = Ranges;
        this.Categories = Categories;
        this.AvgPeaks = AvgPeaks;
        this.MaxPeaks = MaxPeaks;
        this.MaxSummaries = MaxSummaries;
        this.AnalysisCardioType = AnalysisCardioType;
        this.ShowRollingAnalysisModal = ShowRollingAnalysisModal;
    }
}

export function Model$reflection() {
    return record_type("Analysis.PeakPerformances.Types.Model", [], Model, () => [["IsLoading", bool_type], ["DateRangeTypes", array_type(DateRangeType$reflection())], ["Ranges", array_type(DateRange$reflection())], ["Categories", array_type(CategoryIdIncludingAll$reflection())], ["AvgPeaks", array_type(array_type(AggregatePeakPerformance$reflection()))], ["MaxPeaks", array_type(array_type(PeakPerformance$reflection()))], ["MaxSummaries", array_type(CardioSummary$reflection())], ["AnalysisCardioType", CardioType$reflection()], ["ShowRollingAnalysisModal", bool_type]]);
}

export function Model_Empty_1505(cartItems) {
    return new Model(false, [new DateRangeType(0)], [last30Days()], [new CategoryIdIncludingAll(0)], new Array(0), new Array(0), new Array(0), new CardioType(1), false);
}

export class GetPeaksRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetPeaksRes$reflection() {
    return union_type("Analysis.PeakPerformances.Types.GetPeaksRes", [], GetPeaksRes, () => [[["Item1", array_type(array_type(AggregatePeakPerformance$reflection()))], ["Item2", array_type(array_type(PeakPerformance$reflection()))], ["Item3", array_type(CardioSummary$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ChangeAnalysisCardioType", "AddDateRange", "ChangeStartDate", "ChangeEndDate", "ChangeDateRangeType", "ChangeCategory", "RemoveDateRange", "GetPeaks", "GetPeaksResult", "ShowRollingAnalysisModal", "HideRollingAnalysisModal", "SetDateRanges"];
    }
}

export function Msg$reflection() {
    return union_type("Analysis.PeakPerformances.Types.Msg", [], Msg, () => [[["Item", CardioType$reflection()]], [], [["Item1", int32_type], ["Item2", class_type("System.DateTime")]], [["Item1", int32_type], ["Item2", class_type("System.DateTime")]], [["Item1", int32_type], ["Item2", DateRangeType$reflection()]], [["Item1", int32_type], ["Item2", CategoryIdIncludingAll$reflection()]], [["Item", int32_type]], [], [["Item", GetPeaksRes$reflection()]], [], [], [["Item1", array_type(DateRange$reflection())], ["Item2", array_type(CategoryIdIncludingAll$reflection())]]]);
}

