import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { record_type, class_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { mapIndexed, length } from "./.fable/fable-library.3.0.0/Seq.js";
import * as react from "react";
import { length as length_1, ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { some } from "./.fable/fable-library.3.0.0/Option.js";
import { subtleLabelClass } from "./Typography.fs.js";

export class Statistic extends Record {
    constructor(Title, ValueColor, Value, UnitLabel, Icon) {
        super();
        this.Title = Title;
        this.ValueColor = ValueColor;
        this.Value = Value;
        this.UnitLabel = UnitLabel;
        this.Icon = Icon;
    }
}

export function Statistic$reflection() {
    return record_type("StatBar.Statistic", [], Statistic, () => [["Title", string_type], ["ValueColor", string_type], ["Value", string_type], ["UnitLabel", string_type], ["Icon", class_type("Fable.React.ReactElement")]]);
}

export function view(isRoundedTop, isRoundedBottom, isShadow, metrics) {
    let arg20, arg10_1;
    const valueStyle = (stat) => toText(printf("flex items-baseline text-2xl leading-8 font-semibold %s"))(stat.ValueColor);
    const metricsLength = length(metrics) | 0;
    const last = (metricsLength - 1) | 0;
    const shadow = isShadow ? "shadow" : "";
    return react.createElement("div", {
        className: (arg20 = (isRoundedBottom ? "rounded-b-lg" : ""), (arg10_1 = (isRoundedTop ? "rounded-t-lg" : ""), toText(printf("grid grid-cols-1 %s %s bg-white overflow-hidden %s md:grid-cols-2 lg:grid-cols-%d"))(arg10_1)(arg20)(shadow)(metricsLength))),
    }, ...ofSeq(mapIndexed((i_2, s) => {
        if (((i_2 === 0) ? some(void 0) : (void 0)) != null) {
            return react.createElement("div", {}, react.createElement("div", {
                className: "p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:block lg:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
        else if (((i_2 === last) ? some(void 0) : (void 0)) != null) {
            return react.createElement("div", {
                className: "border-t border-gray-200 lg:border-t-0 md:border-l",
            }, react.createElement("div", {
                className: "px-4 py-5 sm:p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:block lg:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
        else {
            return react.createElement("div", {
                className: "border-gray-200 md:border-l",
            }, react.createElement("div", {
                className: "px-4 py-5 sm:p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:block lg:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
    }, metrics)));
}

export function embeddedView(isRounded, metrics) {
    let arg20, arg10_1;
    const valueStyle = (stat) => toText(printf("flex items-baseline text-2xl leading-8 font-semibold %s"))(stat.ValueColor);
    const last = (length_1(metrics) - 1) | 0;
    return react.createElement("div", {
        className: (arg20 = (length_1(metrics) | 0), (arg10_1 = (isRounded ? "rounded-lg" : ""), toText(printf("grid grid-cols-1 %s overflow-hidden md:grid-cols-%d"))(arg10_1)(arg20))),
    }, ...ofSeq(mapIndexed((i_2, s) => {
        if (((i_2 === 0) ? some(void 0) : (void 0)) != null) {
            return react.createElement("div", {}, react.createElement("div", {
                className: "px-4 py-5 sm:p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0 md:hidden lg:block",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
        else if (((i_2 === last) ? some(void 0) : (void 0)) != null) {
            return react.createElement("div", {}, react.createElement("div", {
                className: "px-4 py-5 sm:p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0 md:hidden lg:block",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
        else {
            return react.createElement("div", {}, react.createElement("div", {
                className: "px-4 py-5 sm:p-6",
            }, react.createElement("div", {
                className: "flex items-center",
            }, react.createElement("div", {
                className: "flex-shrink-0 md:hidden lg:block",
            }, s.Icon), react.createElement("div", {
                className: "ml-5 w-0 flex-1",
            }, react.createElement("dl", {}, react.createElement("dt", {
                className: subtleLabelClass,
            }, s.Title), react.createElement("dd", {
                className: "mt-1 flex justify-between items-baseline md:flex",
            }, react.createElement("div", {
                className: valueStyle(s),
            }, s.Value, react.createElement("span", {
                className: "ml-2 text-sm leading-5 font-medium text-gray-500",
            }, s.UnitLabel))))))));
        }
    }, metrics)));
}

