import { Msg } from "./AppTypes.fs.js";
import { view as view_1, IModalManager, Msg as Msg_1 } from "./Modal/Manager.fs.js";
import { singleton, append, delay, tryFind } from "./.fable/fable-library.3.0.0/Seq.js";
import { Id_value } from "../../shared/Shared.Types.fs.js";
import { equalsSafe } from "./.fable/fable-library.3.0.0/Util.js";
import { ICartmanager } from "./ComparisonCart/Manager.fs.js";
import { Context } from "./Context.fs.js";
import * as react from "react";
import { ofSeq } from "./.fable/fable-library.3.0.0/List.js";
import { navBar } from "./NavBar.fs.js";
import { page } from "./Layout.fs.js";
import { root as root_1 } from "./Metrics/Dispatcher/View.fs.js";
import { root as root_2 } from "./Welcome/Dispatcher/View.fs.js";
import { root as root_3 } from "./Help/Dispatcher/View.fs.js";
import { root as root_4 } from "./Analysis/Dispatcher/View.fs.js";
import { root as root_5 } from "./HeartRateZones/Dispatcher/View.fs.js";
import { root as root_6 } from "./PowerZones/Dispatcher/View.fs.js";
import { root as root_7 } from "./WeeklyTrainingLog/Dispatcher/View.fs.js";
import { root as root_8 } from "./CardioActivity/Dispatcher/View.fs.js";
import { root as root_9 } from "./Dashboard/Dispatcher/View.fs.js";
import { root as root_10 } from "./Programme/Dispatcher/View.fs.js";
import { view } from "./Unauthorized.fs.js";
import { root as root_11 } from "./PowerProgramme/Dispatcher/View.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactSynchronous } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { pageParser } from "./Router.fs.js";
import { update, init, urlUpdate as urlUpdate_1 } from "./AppState.fs.js";
import { startImmediate } from "./.fable/fable-library.3.0.0/Async.js";
import { singleton as singleton_1 } from "./.fable/fable-library.3.0.0/AsyncBuilder.js";
import { requireAuthentication, getAccessTokenOnStartup } from "./Auth.fs.js";

function renderPage(model, dispatch) {
    const contextWithManagerSupport = new Context(model.Context.Lookups, new IModalManager((metricType, initialValue) => {
        dispatch(new Msg(15, new Msg_1(1, metricType, initialValue)));
    }, () => {
        dispatch(new Msg(15, new Msg_1(0)));
    }), new ICartmanager((cardioSummary) => {
        dispatch(new Msg(16, cardioSummary));
    }, (cartItem) => {
        dispatch(new Msg(17, cartItem));
    }, (cardioSummary_1) => {
        const matchValue = tryFind((i) => (Id_value(i.ActivityId) === cardioSummary_1.Id), model.ComparisonCart);
        if (matchValue == null) {
            return false;
        }
        else {
            return true;
        }
    }, (activityId) => {
        const cartItem_1 = tryFind((c) => equalsSafe(c.ActivityId, activityId), model.ComparisonCart);
        if (cartItem_1 == null) {
        }
        else {
            dispatch(new Msg(17, cartItem_1));
        }
    }));
    return react.createElement(react.Fragment, {}, ...ofSeq(delay(() => append(singleton(navBar(contextWithManagerSupport, model, dispatch)), delay(() => {
        let extractedModel_1, extractedModel_2, extractedModel_3, extractedModel_4, extractedModel_5, extractedModel_6, extractedModel_7, extractedModel_8, extractedModel_9, extractedModel_10, extractedModel;
        return model.IsGlobalLoading ? singleton(page("Loading application", true, [], [])) : ((model.CurrentPage.tag === 1) ? ((model.MetricsDispatcher != null) ? (extractedModel_1 = model.MetricsDispatcher, singleton(root_1(model.Context, extractedModel_1, (arg_1) => {
            dispatch(new Msg(1, arg_1));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 3) ? ((model.WelcomeDispatcher != null) ? (extractedModel_2 = model.WelcomeDispatcher, singleton(root_2(model.Context, extractedModel_2, (arg_2) => {
            dispatch(new Msg(3, arg_2));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 2) ? ((model.HelpDispatcher != null) ? (extractedModel_3 = model.HelpDispatcher, singleton(root_3(model.Context, extractedModel_3, (arg_3) => {
            dispatch(new Msg(2, arg_3));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 4) ? ((model.AnalysisDispatcher != null) ? (extractedModel_4 = model.AnalysisDispatcher, singleton(root_4(contextWithManagerSupport, extractedModel_4, (arg_4) => {
            dispatch(new Msg(4, arg_4));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 5) ? ((model.HeartRateZonesDispatcher != null) ? (extractedModel_5 = model.HeartRateZonesDispatcher, singleton(root_5(contextWithManagerSupport, extractedModel_5, (arg_5) => {
            dispatch(new Msg(5, arg_5));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 6) ? ((model.PowerZonesDispatcher != null) ? (extractedModel_6 = model.PowerZonesDispatcher, singleton(root_6(contextWithManagerSupport, extractedModel_6, (arg_6) => {
            dispatch(new Msg(6, arg_6));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 7) ? ((model.WeeklyTrainingLogDispatcher != null) ? (extractedModel_7 = model.WeeklyTrainingLogDispatcher, singleton(root_7(contextWithManagerSupport, extractedModel_7, (arg_7) => {
            dispatch(new Msg(7, arg_7));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 8) ? ((model.CardioActivityDispatcher != null) ? (extractedModel_8 = model.CardioActivityDispatcher, singleton(root_8(contextWithManagerSupport, extractedModel_8, (arg_8) => {
            dispatch(new Msg(8, arg_8));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 9) ? ((model.DashboardDispatcher != null) ? (extractedModel_9 = model.DashboardDispatcher, singleton(root_9(contextWithManagerSupport, extractedModel_9, (arg_9) => {
            dispatch(new Msg(9, arg_9));
        }, dispatch))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 10) ? ((model.ProgrammeDispatcher != null) ? (extractedModel_10 = model.ProgrammeDispatcher, singleton(root_10(contextWithManagerSupport, extractedModel_10, (arg_10) => {
            dispatch(new Msg(10, arg_10));
        }))) : singleton(react.createElement("div", {}, "page not found"))) : ((model.CurrentPage.tag === 11) ? singleton(view) : ((model.IntervalProgrammeDispatcher != null) ? (extractedModel = model.IntervalProgrammeDispatcher, singleton(root_11(extractedModel, model.Context, (arg) => {
            dispatch(new Msg(0, arg));
        }))) : singleton(react.createElement("div", {}, "page not found"))))))))))))));
    })))));
}

function root(model, dispatch) {
    return react.createElement("div", {}, renderPage(model, dispatch), view_1(model.Context, model.ModalManager, (arg) => {
        dispatch(new Msg(15, arg));
    }));
}

export function runProgram() {
    ProgramModule_run(Program_withReactSynchronous("elmish-app", ProgramModule_toNavigable((location) => parseHash(pageParser, location), urlUpdate_1, ProgramModule_mkProgram(init, update, root))));
}

startImmediate(singleton_1.Delay(() => singleton_1.Bind(getAccessTokenOnStartup(), (_arg1) => {
    if (_arg1) {
        runProgram();
        return singleton_1.Zero();
    }
    else {
        requireAuthentication();
        return singleton_1.Zero();
    }
})));

