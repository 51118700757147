import { Record } from "./.fable/fable-library.3.0.0/Types.js";
import { option_type, int32_type, record_type, string_type, array_type, float64_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { uncurry, comparePrimitives, min } from "./.fable/fable-library.3.0.0/Util.js";
import * as react from "react";
import { rangeNumber, mapIndexed, append } from "./.fable/fable-library.3.0.0/Seq.js";
import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import { empty } from "./.fable/fable-library.3.0.0/List.js";
import { attachWindowEventWithDisposer } from "./BrowserHelpers.fs.js";

export class SpiderChartSeries extends Record {
    constructor(LevelThresholds, Label) {
        super();
        this.LevelThresholds = LevelThresholds;
        this.Label = Label;
    }
}

export function SpiderChartSeries$reflection() {
    return record_type("SpiderChart.SpiderChartSeries", [], SpiderChartSeries, () => [["LevelThresholds", array_type(float64_type)], ["Label", string_type]]);
}

export class ISpiderChartProps extends Record {
    constructor(Series, LevelLabels, Values, GridColor) {
        super();
        this.Series = Series;
        this.LevelLabels = LevelLabels;
        this.Values = Values;
        this.GridColor = GridColor;
    }
}

export function ISpiderChartProps$reflection() {
    return record_type("SpiderChart.ISpiderChartProps", [], ISpiderChartProps, () => [["Series", array_type(SpiderChartSeries$reflection())], ["LevelLabels", array_type(string_type)], ["Values", array_type(float64_type)], ["GridColor", string_type]]);
}

export class TimeGraphDataPoint extends Record {
    constructor(Value, TimeInSeconds) {
        super();
        this.Value = Value;
        this.TimeInSeconds = (TimeInSeconds | 0);
    }
}

export function TimeGraphDataPoint$reflection() {
    return record_type("SpiderChart.TimeGraphDataPoint", [], TimeGraphDataPoint, () => [["Value", float64_type], ["TimeInSeconds", int32_type]]);
}

export class TimeGraphTick extends Record {
    constructor(TimeInSeconds, Label) {
        super();
        this.TimeInSeconds = (TimeInSeconds | 0);
        this.Label = Label;
    }
}

export function TimeGraphTick$reflection() {
    return record_type("SpiderChart.TimeGraphTick", [], TimeGraphTick, () => [["TimeInSeconds", int32_type], ["Label", option_type(string_type)]]);
}

function renderer(width, height, props) {
    const viewBoxString = toText(printf("0 0 %.0f %.0f"))(width)(height);
    const outerRadius = min(comparePrimitives, width, height) / 2;
    const innerRadius = 30;
    const degreesToRadians = (degrees) => ((degrees * 3.141592653589793) / 180);
    const spacingInDegrees = degreesToRadians(360 / props.Series.length);
    const start = (spacingInDegrees - degreesToRadians(90)) - (spacingInDegrees / 2);
    const cx = width / 2;
    const cy = height / 2;
    return react.createElement("svg", {
        version: "1.1",
        viewBox: viewBoxString,
        className: "text-xs",
        onClick: (_arg1) => {
        },
    }, ...append(Array.from(mapIndexed((si, s) => {
        const angle = start + (spacingInDegrees * si);
        return react.createElement("line", {
            x1: cx + (innerRadius * Math.cos(angle)),
            y1: cy + (innerRadius * Math.sin(angle)),
            x2: cx + (outerRadius * Math.cos(angle)),
            y2: cy + (outerRadius * Math.sin(angle)),
            stroke: props.GridColor,
        });
    }, rangeNumber(0, 1, props.Series.length - 1))), []));
}

export const chart = FunctionComponent_Of_Z5A158BBF((props) => {
    const containerWidth = react.useState(0);
    const containerHeight = react.useState(0);
    const dataPointPopover = react.useState(void 0);
    const containerRef = react.useRef(void 0);
    react.useEffect(() => {
    const disp = (() => {
        let timeoutId = void 0;
        const setWidth = () => {
            const matchValue = containerRef.current;
            if (matchValue == null) {
            }
            else {
                const element = matchValue;
                const width = (~(~element.clientWidth)) | 0;
                const height = (~(~element.clientHeight)) | 0;
                dataPointPopover[1]((void 0));
                if (timeoutId == null) {
                }
                else {
                    window.clearTimeout(timeoutId);
                }
                timeoutId = window.setTimeout((_arg1) => {
                    timeoutId = (void 0);
                    containerWidth[1](((s) => width));
                    containerHeight[1](((s_1) => height));
                }, 150, empty());
            }
        };
        setWidth();
        return attachWindowEventWithDisposer((_arg2) => {
            setWidth();
        }, window, "resize", () => {
            if (timeoutId == null) {
            }
            else {
                window.clearTimeout(timeoutId);
            }
        });
    })();
    return () => disp.Dispose();
    }, []);
    let patternInput;
    const matchValue_1 = containerRef.current;
    if (matchValue_1 == null) {
        patternInput = [react.createElement(react.Fragment, {}), react.createElement(react.Fragment, {})];
    }
    else {
        const container = matchValue_1;
        patternInput = [renderer(containerWidth[0], containerHeight[0], props), react.createElement(react.Fragment, {})];
    }
    return react.createElement("div", {
        className: "h-full relative",
        ref: containerRef,
    }, patternInput[1], patternInput[0]);
}, void 0, uncurry(2, void 0), void 0, "chart", "/home/runner/work/strengthPlus/strengthPlus/client/src/SpiderChart.fs", 69);

