import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { HelpPage, HelpPage$reflection } from "../../Router.fs.js";
import { Model$reflection as Model$reflection_1 } from "../Show/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Model extends Record {
    constructor(CurrentPage, ShowModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.ShowModel = ShowModel;
    }
}

export function Model$reflection() {
    return record_type("Help.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", HelpPage$reflection()], ["ShowModel", option_type(Model$reflection_1())]]);
}

export function Model_get_Empty() {
    return new Model(new HelpPage(0), void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ShowMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Help.Dispatcher.Types.Msg", [], Msg, () => [[]]);
}

