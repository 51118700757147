import { FunctionComponent_Of_Z5A158BBF } from "./.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { map, find, head } from "./.fable/fable-library.3.0.0/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { uncurry } from "./.fable/fable-library.3.0.0/Util.js";

export const view = FunctionComponent_Of_Z5A158BBF((props) => {
    const videos = [[422111515, "How to upload from Garmin Connect"], [422117893, "How to upload from a Garmin Device"]];
    const video = react.useState(head(videos)[1]);
    const videoCode = find((v) => (v[1] === (video[0])), videos)[0] | 0;
    return react.createElement("div", {}, react.createElement("div", {
        className: "shadow-sm",
        style: {
            width: 640,
        },
    }, react.createElement("select", {
        value: video[0],
        className: "form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md border-l",
        onChange: (ev) => {
            const arg00 = Browser_Types_Event__Event_get_Value(ev);
            video[1](arg00);
        },
    }, ...map((drt) => react.createElement("option", {
        value: drt[1],
    }, drt[1]), videos))), react.createElement("div", {
        className: "mt-2",
    }, react.createElement("iframe", {
        src: toText(printf("https://player.vimeo.com/video/%d"))(videoCode),
        width: 640,
        height: 360,
        frameBorder: 0,
        allowFullScreen: true,
    })));
}, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/TutorialVideos.fs", 12);

