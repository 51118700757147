import * as react from "react";
import { Msg } from "./Types.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../../.fable/Fable.React.7.2.0/Fable.React.Extensions.fs.js";
import { length, map } from "../../.fable/fable-library.3.0.0/List.js";
import { Name_value, Id_value } from "../../../../shared/Shared.Types.fs.js";
import { ValidationState__IsValid, ValidationState__IsPropertyInError_Z721C83C5 } from "../../../../shared/SimpleValidation.fs.js";
import { printf, toText } from "../../.fable/fable-library.3.0.0/String.js";
import { mediumTextArea, smallTextInput, block, header, Converters_optionalIntValue } from "../../Form.fs.js";
import { mapIndexed } from "../../.fable/fable-library.3.0.0/Seq.js";
import { primary } from "../../Buttons.fs.js";
import { isCreate } from "./Helpers.fs.js";
import { panel, page } from "../../Layout.fs.js";

function liftsDropDown2(lifts, exercise, dispatch) {
    return react.createElement("div", {
        className: "shadow-sm",
    }, react.createElement("select", {
        value: exercise.LiftId,
        className: "form-select w-full rounded-none border-l-0 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
        onChange: (ev) => {
            dispatch(new Msg(3, [exercise, Browser_Types_Event__Event_get_Value(ev)]));
        },
    }, ...map((lift) => react.createElement("option", {
        value: Id_value(lift.Id),
    }, Name_value(lift.Name)), lifts)));
}

function exerciseInput(validationState, lifts, exercise, exerciseIndex, dispatch) {
    const repsValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Activities.[%d].Strength.Reps"))(exerciseIndex)) ? "form-input flex-1 block w-15 px-3 py-2 rounded-none sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input flex-1 block w-15 px-3 py-2 rounded-none sm:text-sm sm:leading-5";
    const setsValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Activities.[%d].Strength.Sets"))(exerciseIndex)) ? "form-input flex-1 block w-15 px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input flex-1 block w-15 px-3 py-2 rounded-none rounded-l-md sm:text-sm sm:leading-5";
    const weightValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Activities.[%d].Strength.Weight"))(exerciseIndex)) ? "form-input flex-1 block w-15 px-3 py-2 rounded-none sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input flex-1 block w-15 px-3 py-2 rounded-none sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "flex rounded-md shadow-sm mr-4 mt-1",
    }, react.createElement("input", {
        className: setsValidationStyle,
        value: Converters_optionalIntValue(exercise.Sets),
        onChange: (ev) => {
            const value = dispatch(new Msg(5, [exercise, Browser_Types_Event__Event_get_Value(ev)]));
            void undefined;
        },
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "sets of"), react.createElement("input", {
        className: repsValidationStyle,
        value: Converters_optionalIntValue(exercise.Reps),
        onChange: (ev_1) => {
            dispatch(new Msg(6, [exercise, Browser_Types_Event__Event_get_Value(ev_1)]));
        },
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "reps of"), liftsDropDown2(lifts, exercise, dispatch), react.createElement("span", {
        className: "inline-flex items-center px-3 border-l-0 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "weighing"), react.createElement("input", {
        className: weightValidationStyle,
        value: Converters_optionalIntValue(exercise.Weight),
        onChange: (nv) => {
            const value_1 = dispatch(new Msg(4, [exercise, Browser_Types_Event__Event_get_Value(nv)]));
            void undefined;
        },
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 w-15 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "kg"));
}

function restInput(validationState, rest, restIndex, dispatch) {
    const restValidationStyle = ValidationState__IsPropertyInError_Z721C83C5(validationState, toText(printf("Activities.[%d].Rest.Seconds"))(restIndex)) ? "form-input flex-1 block w-20 px-3 py-2 rounded-none sm:text-sm sm:leading-5 border-red-600 focus:border-red-600" : "form-input flex-1 block w-20 px-3 py-2 rounded-none sm:text-sm sm:leading-5";
    return react.createElement("div", {
        className: "flex rounded-md shadow-sm mt-1",
    }, react.createElement("span", {
        className: "inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "rest of"), react.createElement("input", {
        className: restValidationStyle,
        value: Converters_optionalIntValue(rest.Seconds),
        onChange: (nv) => {
            const value = dispatch(new Msg(8, [rest, Browser_Types_Event__Event_get_Value(nv)]));
            void value;
        },
    }), react.createElement("span", {
        className: "inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm",
    }, "seconds"));
}

function rowButtons(numberOfActivities, activity, index, dispatch) {
    let upButtonStyle;
    const matchValue = index > 0;
    upButtonStyle = (matchValue ? " bg-white hover:text-gray-400 active:bg-gray-100" : " bg-gray-200");
    let downButtonStyle;
    const matchValue_1 = index === (numberOfActivities - 1);
    downButtonStyle = (matchValue_1 ? " bg-gray-200" : " bg-white hover:text-gray-400 active:bg-gray-100");
    return react.createElement("span", {
        className: "-mt-1 mr-4 inline-flex shadow-sm",
    }, react.createElement("button", {
        type: "button",
        className: "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm leading-5 font-medium text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-500 transition ease-in-out duration-150" + upButtonStyle,
        onClick: (_arg1) => {
            const value = dispatch(new Msg(10, activity));
            void value;
        },
        disabled: index === 0,
    }, react.createElement("svg", {
        className: "h-5 w-5",
        fill: "currentColor",
        viewBox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M14.7071 12.7071C14.3166 13.0976 13.6834 13.0976 13.2929 12.7071L10 9.41421L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071C4.90237 12.3166 4.90237 11.6834 5.29289 11.2929L9.29289 7.29289C9.68342 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071Z",
        clipRule: "evenodd",
    }))), react.createElement("button", {
        type: "button",
        className: "-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm leading-5 font-medium text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-500 transition ease-in-out duration-150" + downButtonStyle,
        onClick: (_arg2) => {
            const value_1 = dispatch(new Msg(11, activity));
            void value_1;
        },
        disabled: numberOfActivities <= 1,
    }, react.createElement("svg", {
        className: "h-5 w-5",
        fill: "currentColor",
        viewBox: "0 0 20 20",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z",
        clipRule: "evenodd",
    }))), react.createElement("button", {
        type: "button",
        className: "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150",
        onClick: (_arg3) => {
            const value_2 = dispatch(new Msg(9, activity));
            void value_2;
        },
    }, react.createElement("svg", {
        className: "h-5 w-5 mr-1",
        fill: "none",
        viewBox: "0 0 24 24",
    }, react.createElement("path", {
        fillRule: "evenodd",
        d: "M17.6,8l-0.7,9.7c-0.1,0.8-0.8,1.5-1.6,1.5H8.7c-0.8,0-1.5-0.6-1.6-1.5L6.4,8 M10.4,11.2V16 M13.6,11.2V16 M14.4,8V5.6c0-0.4-0.4-0.8-0.8-0.8h-3.2c-0.4,0-0.8,0.4-0.8,0.8V8 M5.6,8h12.8",
        stroke: "#9B2C2C",
        strokeWidth: "2",
        height: "100%",
        width: "100%",
    }))));
}

const exerciseIcon = react.createElement("div", {
    className: "flex-shrink-0 mr-4",
}, react.createElement("svg", {
    className: "h-6 w-6 text-gray-600 fill-current",
    viewBox: "0 0 640 512",
}, react.createElement("path", {
    d: "M632 224h-24v-72c0-30.9-25.1-56-56-56h-32c-2.7 0-5.4.4-8 .8V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v136h-96V88c0-30.9-25.1-56-56-56h-32c-30.9 0-56 25.1-56 56v8.8c-2.6-.4-5.3-.8-8-.8H88c-30.9 0-56 25.1-56 56v72H8c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h24v72c0 30.9 25.1 56 56 56h32c2.7 0 5.4-.4 8-.8v8.8c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56V288h96v136c0 30.9 25.1 56 56 56h32c30.9 0 56-25.1 56-56v-8.8c2.6.4 5.3.8 8 .8h32c30.9 0 56-25.1 56-56v-72h24c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM120 368H88c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208c0 4.4-3.6 8-8 8zm104 56c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm240 0c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V88c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v336zm96-64c0 4.4-3.6 8-8 8h-32c-4.4 0-8-3.6-8-8V152c0-4.4 3.6-8 8-8h32c4.4 0 8 3.6 8 8v208z",
})));

const stopwatchIcon = react.createElement("div", {
    className: "flex-shrink-0 mr-4",
}, react.createElement("svg", {
    className: "h-6 w-6 text-gray-600 fill-current",
    viewBox: "0 0 640 512",
}, react.createElement("path", {
    d: "M393.9 184l22.6-22.6c4.7-4.7 4.7-12.3 0-17l-17-17c-4.7-4.7-12.3-4.7-17 0l-20.7 20.7c-31.1-27.5-70.4-45.9-113.8-50.8V48h28c6.6 0 12-5.4 12-12V12c0-6.6-5.4-12-12-12H172c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h28v49.4C96.4 109.3 16 197.2 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-44.7-14.1-86.1-38.1-120zM224 464c-88.4 0-160-71.6-160-160s71.6-160 160-160 160 71.6 160 160-71.6 160-160 160zm12-112h-24c-6.6 0-12-5.4-12-12V204c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v136c0 6.6-5.4 12-12 12z",
})));

function activityTable(context, model, dispatch, saveButtonTitle, isSaveDisabled) {
    const numberOfActivities = length(model.Item.Activities) | 0;
    const rowStyle = "flex flex-row items-center mb-2";
    return react.createElement("div", {
        className: "flex flex-col my-8",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white px-6 py-4",
    }, header("Activities", "Add the exercises you want to do as part of your workout, to keep things intense remember to include timed rests."), react.createElement("div", {
        className: "mt-4",
    }, ...mapIndexed((activityIndex, activity) => {
        if (activity.tag === 1) {
            return react.createElement("div", {
                className: rowStyle,
            }, stopwatchIcon, rowButtons(numberOfActivities, activity, activityIndex, dispatch), react.createElement("div", {
                className: "flex flex-row mb-2",
            }, restInput(model.ValidationState, activity.fields[0], activityIndex, dispatch)));
        }
        else {
            return react.createElement("div", {
                className: rowStyle,
            }, exerciseIcon, rowButtons(numberOfActivities, activity, activityIndex, dispatch), react.createElement("div", {
                className: "flex flex-row mb-2",
            }, exerciseInput(model.ValidationState, context.Lookups.Lifts, activity.fields[0], activityIndex, dispatch)));
        }
    }, model.Item.Activities)), react.createElement("div", {
        className: "flex flex-row justify-between",
    }, react.createElement("div", {
        className: "flex flex-row mt-4",
    }, react.createElement("button", {
        className: "bg-transparent hover:bg-blue-500 text-blue-700  hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mr-4",
        onClick: (_arg1) => {
            const value = dispatch(new Msg(2));
            void undefined;
        },
    }, "Add Exercise"), react.createElement("button", {
        className: "bg-transparent hover:bg-blue-500 text-blue-700  hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded",
        onClick: (_arg2) => {
            const value_1 = dispatch(new Msg(7));
            void undefined;
        },
    }, "Add Rest")), react.createElement("div", {
        className: "flex flex-row mt-4",
    }, primary(saveButtonTitle, () => {
        dispatch(new Msg(12));
    }, isSaveDisabled))))));
}

export function root(context, model, dispatch) {
    const isSaveDisabled = !(ValidationState__IsValid(model.ValidationState) ? model.IsDirty : false);
    const buttonTitle = isCreate() ? "Save New Programme" : "Save Programme";
    return react.createElement("div", {}, page(isCreate() ? "Create Programme" : "Edit Programme", false, [], [panel([header("Programme", "Settings and details about the programme"), block([smallTextInput("Name")((arg) => {
        dispatch(new Msg(0, arg));
    })(ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Name"))(model.Item.Name), mediumTextArea("Description")((arg_1) => {
        dispatch(new Msg(1, arg_1));
    })(ValidationState__IsPropertyInError_Z721C83C5(model.ValidationState, "Description"))(model.Item.Description)])]), activityTable(context, model, dispatch, buttonTitle, isSaveDisabled)]));
}

