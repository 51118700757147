import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { union_type, class_type, record_type, bool_type, array_type, uint8_type, option_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Model extends Record {
    constructor(Filename, File, IsSaving, ValidationError) {
        super();
        this.Filename = Filename;
        this.File = File;
        this.IsSaving = IsSaving;
        this.ValidationError = ValidationError;
    }
}

export function Model$reflection() {
    return record_type("Modal.UploadActivity.Types.Model", [], Model, () => [["Filename", option_type(string_type)], ["File", option_type(array_type(uint8_type))], ["IsSaving", bool_type], ["ValidationError", option_type(string_type)]]);
}

export function Model_get_Empty() {
    return new Model(void 0, void 0, false, void 0);
}

export class SubmitUploadActivityRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error", "AlreadyUploaded"];
    }
}

export function SubmitUploadActivityRes$reflection() {
    return union_type("Modal.UploadActivity.Types.SubmitUploadActivityRes", [], SubmitUploadActivityRes, () => [[["Item", string_type]], [["Item", class_type("System.Exception")]], [["Item", string_type]]]);
}

export class UploadActivityMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClearFile", "SetFile", "SubmitUploadActivity", "SubmitUploadActivityResult"];
    }
}

export function UploadActivityMsg$reflection() {
    return union_type("Modal.UploadActivity.Types.UploadActivityMsg", [], UploadActivityMsg, () => [[], [["Item1", string_type], ["Item2", array_type(uint8_type)]], [], [["Item", SubmitUploadActivityRes$reflection()]]]);
}

