import { HttpResponse, HttpRequest, RequestBody, HttpMethod as HttpMethod_1 } from "./Types.fs.js";
import { empty } from "../fable-library.3.0.0/List.js";
import { fromContinuations } from "../fable-library.3.0.0/Async.js";
import { getEnumerator } from "../fable-library.3.0.0/Seq.js";
import { some } from "../fable-library.3.0.0/Option.js";
import { InternalUtilities_toUInt8Array } from "./Extensions.fs.js";

const defaultRequestConfig = new HttpRequest(new HttpMethod_1(0), "/", empty(), new RequestBody(0), false);

export function get$(url) {
    return new HttpRequest(new HttpMethod_1(0), url, defaultRequestConfig.Headers, defaultRequestConfig.RequestBody, defaultRequestConfig.WithCredentials);
}

export function post(url) {
    return new HttpRequest(new HttpMethod_1(1), url, defaultRequestConfig.Headers, defaultRequestConfig.RequestBody, defaultRequestConfig.WithCredentials);
}

export function request(method, url) {
    return new HttpRequest(method, url, defaultRequestConfig.Headers, defaultRequestConfig.RequestBody, defaultRequestConfig.WithCredentials);
}

export function withHeaders(headers, req) {
    return new HttpRequest(req.HttpMethod, req.Url, headers, req.RequestBody, req.WithCredentials);
}

export function withCredentials(withCredentials_1, req) {
    return new HttpRequest(req.HttpMethod, req.Url, req.Headers, req.RequestBody, withCredentials_1);
}

export function withBody(body, req) {
    return new HttpRequest(req.HttpMethod, req.Url, req.Headers, body, req.WithCredentials);
}

export function send(req) {
    return fromContinuations((tupledArg) => {
        const xhr = new XMLHttpRequest();
        if (req.HttpMethod.tag === 1) {
            xhr.open("POST", req.Url);
        }
        else {
            xhr.open("GET", req.Url);
        }
        const enumerator = getEnumerator(req.Headers);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                xhr.setRequestHeader(forLoopVar[0], forLoopVar[1]);
            }
        }
        finally {
            enumerator.Dispose();
        }
        xhr.withCredentials = true;
        xhr.onreadystatechange = (() => {
            if (xhr.readyState === 4) {
                tupledArg[0](new HttpResponse(xhr.status, xhr.responseText));
            }
            else {
                void undefined;
            }
        });
        const matchValue_4 = req.RequestBody;
        switch (matchValue_4.tag) {
            case 1: {
                xhr.send(some(matchValue_4.fields[0]));
                break;
            }
            case 2: {
                xhr.send(some(InternalUtilities_toUInt8Array(matchValue_4.fields[0])));
                break;
            }
            default: {
                xhr.send();
            }
        }
    });
}

export function sendAndReadBinary(req) {
    return fromContinuations((tupledArg) => {
        const xhr = new XMLHttpRequest();
        if (req.HttpMethod.tag === 1) {
            xhr.open("POST", req.Url);
        }
        else {
            xhr.open("GET", req.Url);
        }
        xhr.responseType = "arraybuffer";
        const enumerator = getEnumerator(req.Headers);
        try {
            while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                const forLoopVar = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]();
                xhr.setRequestHeader(forLoopVar[0], forLoopVar[1]);
            }
        }
        finally {
            enumerator.Dispose();
        }
        xhr.withCredentials = req.WithCredentials;
        xhr.onreadystatechange = (() => {
            if (xhr.readyState === 4) {
                tupledArg[0]([new Uint8Array(xhr.response), xhr.status]);
            }
            else {
                void undefined;
            }
        });
        const matchValue_4 = req.RequestBody;
        switch (matchValue_4.tag) {
            case 1: {
                xhr.send(some(matchValue_4.fields[0]));
                break;
            }
            case 2: {
                xhr.send(some(InternalUtilities_toUInt8Array(matchValue_4.fields[0])));
                break;
            }
            default: {
                xhr.send();
            }
        }
    });
}

