import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { record_type, lambda_type, unit_type, list_type, string_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { CardioActivityCategory$reflection, CardioSummary$reflection } from "../../../../shared/Shared.Models.fs.js";
import { Context$reflection } from "../../Context.fs.js";
import { Msg, Msg$reflection } from "./Types.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../../.fable/Fable.React.7.2.0/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { singleton, delay, map, findIndex } from "../../.fable/fable-library.3.0.0/Seq.js";
import { uncurry, equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { Id_create } from "../../../../shared/Shared.Types.fs.js";
import { toolbarButton, roundedDropdown, prevNextButtons } from "../../Form.fs.js";
import { ofSeq, item } from "../../.fable/fable-library.3.0.0/List.js";
import { trashIcon, addToComparisonCartIcon, IconSize, removeFromComparisonCartIcon } from "../../Icons.fs.js";

export class IToolbarProps extends Record {
    constructor(ActivityId, ActivitySummary, Categories, Context, Dispatch, InitialCategoryId) {
        super();
        this.ActivityId = ActivityId;
        this.ActivitySummary = ActivitySummary;
        this.Categories = Categories;
        this.Context = Context;
        this.Dispatch = Dispatch;
        this.InitialCategoryId = InitialCategoryId;
    }
}

export function IToolbarProps$reflection() {
    return record_type("CardioActivity.Show.Toolbar.IToolbarProps", [], IToolbarProps, () => [["ActivityId", string_type], ["ActivitySummary", CardioSummary$reflection()], ["Categories", list_type(CardioActivityCategory$reflection())], ["Context", Context$reflection()], ["Dispatch", lambda_type(Msg$reflection(), unit_type)], ["InitialCategoryId", string_type]]);
}

export function view() {
    return FunctionComponent_Of_Z5A158BBF((props) => {
        const dispatch = props.Dispatch;
        const selectedCategoryIndex = react.useState(findIndex((c) => equalsSafe(c.Id, Id_create(props.InitialCategoryId)), props.Categories));
        return react.createElement("div", {
            className: "flex flex-row",
        }, react.createElement("div", {
            className: "mr-4",
        }, prevNextButtons(false, false, () => {
            dispatch(new Msg(2, Id_create(props.ActivityId)));
        }, () => {
            dispatch(new Msg(3, Id_create(props.ActivityId)));
        })), react.createElement("div", {
            className: "mr-4",
        }, roundedDropdown(map((c_1) => c_1.DisplayText, props.Categories), selectedCategoryIndex[0], (index_1, _arg1) => {
            let tupledArg;
            selectedCategoryIndex[1](index_1);
            const categoryId = item(index_1, props.Categories).Id;
            dispatch((tupledArg = [Id_create(props.ActivityId), categoryId], new Msg(8, tupledArg[0], tupledArg[1])));
        })()), react.createElement("div", {
            className: "mr-4",
        }, ...ofSeq(delay(() => (props.Context.CartManager.isInCart(props.ActivitySummary) ? singleton(toolbarButton(removeFromComparisonCartIcon(new IconSize(3)), false, () => {
            dispatch(new Msg(4));
        })) : singleton(toolbarButton(addToComparisonCartIcon(new IconSize(3)), false, () => {
            props.Context.CartManager.addItem(props.ActivitySummary);
        })))))), toolbarButton(trashIcon(new IconSize(3)), false, () => {
            dispatch(new Msg(4));
        }));
    }, void 0, uncurry(2, void 0), void 0, "view", "/home/runner/work/strengthPlus/strengthPlus/client/src/CardioActivity/Show/Toolbar.fs", 25);
}

