import * as react from "react";
import { value as value_15 } from "../../.fable/fable-library.3.0.0/Option.js";
import { ofArray, ofSeq } from "../../.fable/fable-library.3.0.0/List.js";
import { singleton, append, delay } from "../../.fable/fable-library.3.0.0/Seq.js";
import { cadenceUnits, cadence, heartRateUnits, heartRate, powerUnits, power, ascentUnits, ascent, speedUnits, speed, distanceUnits, distance, weightUnits, weight, caloriesUnits, calories, optionalDurationFromSeconds } from "../../Units.fs.js";
import { OptionHelpers_optionalFloatFromInt } from "../../../../shared/Shared.fs.js";

export function view(context, summary) {
    const metrics = summary.Metrics;
    const headerRow = (title, content) => react.createElement(react.Fragment, {}, react.createElement("thead", {}, react.createElement("tr", {}, react.createElement("th", {
        colSpan: 2,
        className: "px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
    }, title))), react.createElement("tbody", {}, ...content));
    const propertyRow = (label, value, transformer, units) => {
        if (value == null) {
            return react.createElement(react.Fragment, {});
        }
        else {
            const v = value_15(value);
            const formattedValue = transformer(context, value);
            return react.createElement("tr", {}, react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, label), react.createElement("td", {
                className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
            }, formattedValue, " ", units(context)));
        }
    };
    return react.createElement("div", {}, react.createElement("div", {
        className: "flex flex-col",
    }, react.createElement("div", {
        className: "-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8",
    }, react.createElement("div", {
        className: "align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200",
    }, react.createElement("table", {
        className: "min-w-full",
    }, ...ofSeq(delay(() => {
        let arg10;
        return append(singleton((arg10 = ofSeq(delay(() => append(singleton(propertyRow("Duration", metrics.TimeSeconds, (_arg1, v_1) => optionalDurationFromSeconds(OptionHelpers_optionalFloatFromInt(v_1)), (_arg2) => "")), delay(() => append(singleton(propertyRow("Calories", metrics.Calories, calories, caloriesUnits)), delay(() => {
            const matchValue = summary.WeightAtTimeOfRideKg;
            if (matchValue == null) {
                return singleton(react.createElement(react.Fragment, {}));
            }
            else {
                const w = matchValue;
                return singleton(react.createElement("tr", {}, react.createElement("td", {
                    className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
                }, "Weight"), react.createElement("td", {
                    className: "px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900",
                }, weight(context, w), " ", weightUnits(context))));
            }
        })))))), headerRow("Miscellaneous", arg10))), delay(() => {
            let arg10_1;
            return append(singleton((arg10_1 = ofArray([propertyRow("Distance", metrics.DistanceMeters, distance, distanceUnits), propertyRow("Average Speed", metrics.AverageSpeedMetersPerSecond, speed, speedUnits), propertyRow("Maximum Speed", metrics.MaximumSpeedMetersPerSecond, speed, speedUnits)]), headerRow("Distance and speed", arg10_1))), delay(() => {
                let arg10_2;
                return append((metrics.MaximumAltitudeMeters == null) ? singleton(react.createElement(react.Fragment, {})) : singleton((arg10_2 = ofArray([propertyRow("Elevation Gained", metrics.ElevationGainedMeters, ascent, ascentUnits), propertyRow("Maximum Altitude", metrics.MaximumAltitudeMeters, ascent, ascentUnits), propertyRow("Minimum Altitude", metrics.MinimumAltitudeMeters, ascent, ascentUnits)]), headerRow("Altitude", arg10_2))), delay(() => {
                    let arg10_3;
                    return append((metrics.AveragePower == null) ? singleton(react.createElement(react.Fragment, {})) : singleton((arg10_3 = ofArray([propertyRow("Normalised Power", metrics.NormalisedPower, power, powerUnits), propertyRow("Average Power", metrics.AveragePower, power, powerUnits), propertyRow("Maximum Power", metrics.MaximumPower, power, powerUnits)]), headerRow("Power", arg10_3))), delay(() => {
                        let arg10_4;
                        return append((metrics.AverageHeartRate == null) ? singleton(react.createElement(react.Fragment, {})) : singleton((arg10_4 = ofArray([propertyRow("Average Heart Rate", metrics.AverageHeartRate, heartRate, heartRateUnits), propertyRow("Maximum Heart Rate", metrics.MaximumHeartRate, heartRate, heartRateUnits)]), headerRow("Heart Rate", arg10_4))), delay(() => {
                            let arg10_5;
                            return (metrics.AverageCadence == null) ? singleton(react.createElement(react.Fragment, {})) : singleton((arg10_5 = ofArray([propertyRow("Average Cadence", metrics.AverageCadence, cadence, cadenceUnits), propertyRow("Maximum Cadence", metrics.MaximumCadence, cadence, cadenceUnits)]), headerRow("Cadence", arg10_5)));
                        }));
                    }));
                }));
            }));
        }));
    })))))));
}

