import { elevationFloat, speedFloat, distanceFloat } from "../../Units.fs.js";
import { GetAggregatesRes, Msg, Model, Model_get_Empty, AggregateProperty } from "./Types.fs.js";
import { defaultArg, some } from "../../.fable/fable-library.3.0.0/Option.js";
import { Cmd_none, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { DateRange as DateRange_3 } from "../../../../shared/Shared.Models.fs.js";
import { dateRangeFromType } from "../Types.fs.js";
import { getAnalysis } from "./Api.fs.js";

export function getAggregateValue(aggregateType, context, record) {
    switch (aggregateType.tag) {
        case 1: {
            return distanceFloat(context, record.DistanceMeters);
        }
        case 2: {
            return record.TimeSeconds;
        }
        case 3: {
            return record.AverageHeartRate;
        }
        case 4: {
            return record.MaximumHeartRate;
        }
        case 5: {
            return record.Calories;
        }
        case 6: {
            return record.AverageCadence;
        }
        case 7: {
            return record.MaximumCadence;
        }
        case 8: {
            return speedFloat(context, record.AverageSpeedMetersPerSecond);
        }
        case 9: {
            return speedFloat(context, record.MaximumSpeedMetersPerSecond);
        }
        case 10: {
            return record.AveragePower;
        }
        case 11: {
            return record.MaximumPower;
        }
        case 12: {
            return record.NormalisedPower;
        }
        case 13: {
            return elevationFloat(context, record.ElevationGainedMeters);
        }
        default: {
            return record.TrainingStress;
        }
    }
}

export function getAggregateLabel(aggregateType) {
    switch (aggregateType.tag) {
        case 1: {
            return "Distance";
        }
        case 2: {
            return "Time";
        }
        case 3: {
            return "Average Heart Rate";
        }
        case 4: {
            return "Maximum Heart Rate";
        }
        case 5: {
            return "Calories";
        }
        case 6: {
            return "Average Cadence";
        }
        case 7: {
            return "Maximum Cadence";
        }
        case 8: {
            return "Average Speed";
        }
        case 9: {
            return "Maximum Speed";
        }
        case 10: {
            return "Average Power";
        }
        case 11: {
            return "Maximum Power";
        }
        case 12: {
            return "Normalised Power";
        }
        case 13: {
            return "Elevation Gained";
        }
        default: {
            return "Training Stress";
        }
    }
}

export function getAggregatePropertyFromLabel(label) {
    switch (label) {
        case "Training Stress": {
            return new AggregateProperty(0);
        }
        case "Distance": {
            return new AggregateProperty(1);
        }
        case "Time": {
            return new AggregateProperty(2);
        }
        case "Average Heart Rate": {
            return new AggregateProperty(3);
        }
        case "Maximum Heart Rate": {
            return new AggregateProperty(4);
        }
        case "Calories": {
            return new AggregateProperty(5);
        }
        case "Average Cadence": {
            return new AggregateProperty(6);
        }
        case "Maximum Cadence": {
            return new AggregateProperty(7);
        }
        case "Average Speed": {
            return new AggregateProperty(8);
        }
        case "Maximum Speed": {
            return new AggregateProperty(9);
        }
        case "Average Power": {
            return new AggregateProperty(10);
        }
        case "Maximum Power": {
            return new AggregateProperty(11);
        }
        case "Normalised Power": {
            return new AggregateProperty(12);
        }
        case "Elevation Gained": {
            return new AggregateProperty(13);
        }
        default: {
            return new AggregateProperty(0);
        }
    }
}

export function getAggregateProperties(aggregate) {
    if (aggregate.tag === 1) {
        return [new AggregateProperty(0), new AggregateProperty(1), new AggregateProperty(2), new AggregateProperty(5), new AggregateProperty(13)];
    }
    else {
        return [new AggregateProperty(0), new AggregateProperty(1), new AggregateProperty(2), new AggregateProperty(3), new AggregateProperty(4), new AggregateProperty(5), new AggregateProperty(6), new AggregateProperty(7), new AggregateProperty(8), new AggregateProperty(9), new AggregateProperty(10), new AggregateProperty(11), new AggregateProperty(12), new AggregateProperty(13)];
    }
}

export function sampleSizeIsValid(sampleSize) {
    if (sampleSize == null) {
        return false;
    }
    else {
        const v = sampleSize | 0;
        if (v > 0) {
            return v <= 180;
        }
        else {
            return false;
        }
    }
}

export function initWithAggregate(aggregate) {
    let inputRecord;
    console.log(some("AGGREGATE INIT"));
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.IsLoading, inputRecord.DateRangeType, inputRecord.DateRange, aggregate, inputRecord.SampleSize, inputRecord.Aggregates, getAggregateProperties(aggregate), inputRecord.Left, inputRecord.Right)), Cmd_OfFunc_result(new Msg(5))];
}

export function update(context, msg, model) {
    let sampleSize_2;
    console.log(some("AGGREGATE UPDATE"));
    switch (msg.tag) {
        case 1: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(msg.fields[0], model.DateRange.EndDate), model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_OfFunc_result(new Msg(5))];
        }
        case 2: {
            return [new Model(model.IsLoading, model.DateRangeType, new DateRange_3(model.DateRange.StartDate, msg.fields[0]), model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_OfFunc_result(new Msg(5))];
        }
        case 3: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, msg.fields[0], model.Right), Cmd_OfFunc_result(new Msg(5))];
        }
        case 4: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, msg.fields[0]), Cmd_OfFunc_result(new Msg(5))];
        }
        case 0: {
            const newValue = msg.fields[0];
            return [new Model(model.IsLoading, newValue, dateRangeFromType(newValue), model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_OfFunc_result(new Msg(5))];
        }
        case 5: {
            if (sampleSizeIsValid(model.SampleSize)) {
                return [new Model(true, model.DateRangeType, model.DateRange, model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, (sampleSize_2 = (defaultArg(model.SampleSize, 7) | 0), (context_1) => getAnalysis(model.DateRange, model.Aggregate, sampleSize_2, context_1)), context, (arg0) => (new Msg(6, arg0)), (arg) => (new Msg(6, new GetAggregatesRes(1, arg))))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 6: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.DateRangeType, model.DateRange, model.Aggregate, model.SampleSize, model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_none()];
            }
            else {
                return [new Model(false, model.DateRangeType, model.DateRange, model.Aggregate, model.SampleSize, result.fields[0], model.AggregateProperties, model.Left, model.Right), Cmd_none()];
            }
        }
        default: {
            return [new Model(model.IsLoading, model.DateRangeType, model.DateRange, model.Aggregate, msg.fields[0], model.Aggregates, model.AggregateProperties, model.Left, model.Right), Cmd_none()];
        }
    }
}

