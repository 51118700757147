import { GetIntervalProgrammesRes, sortColumnFromType, sortOrderFromType, Msg, Model, sortFromIndexes, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { equalsSafe } from "../../.fable/fable-library.3.0.0/Util.js";
import { Sort$1__WithNewColumn_1505, Sort$1__get_InvertSortOrder, Sort$1__get_SortColumn } from "../../../../shared/Shared.Api.fs.js";
import { PowerProgrammePage_FromIndexRoute, Page as Page_1, modifyLocation } from "../../Router.fs.js";
import { getIntervalProgrammes } from "./Api.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";

export function init(sortColumnIndex, sortOrderIndex, pageNumber) {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.Items, pageNumber, inputRecord.NumberOfPages, inputRecord.NumberOfRows, inputRecord.PageSize, sortFromIndexes(sortOrderIndex, sortColumnIndex), inputRecord.IsLoading)), Cmd_OfFunc_result(new Msg(2))];
}

export function update(context, msg, model) {
    let arg10_1, arg10;
    switch (msg.tag) {
        case 0: {
            const sortColumn = msg.fields[0];
            const newSort = equalsSafe(sortColumn, Sort$1__get_SortColumn(model.SortOrder)) ? Sort$1__get_InvertSortOrder(model.SortOrder) : Sort$1__WithNewColumn_1505(model.SortOrder, sortColumn);
            modifyLocation(new Page_1(0, (arg10_1 = (sortOrderFromType(newSort) | 0), PowerProgrammePage_FromIndexRoute(sortColumnFromType(newSort), arg10_1, model.Page))));
            return [model, Cmd_none()];
        }
        case 2: {
            return [new Model(model.Items, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.SortOrder, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getIntervalProgrammes(model.SortOrder, model.Page, context_1), context, (arg0_2) => (new Msg(3, arg0_2)), (arg) => (new Msg(3, new GetIntervalProgrammesRes(1, arg))))];
        }
        case 3: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(model.Items, model.Page, model.NumberOfPages, model.NumberOfRows, model.PageSize, model.SortOrder, false), Cmd_none()];
            }
            else {
                const page = result.fields[0];
                return [new Model(page.Items, page.PageNumber, page.NumberOfPages, page.NumberOfRows, page.PageSize, model.SortOrder, false), Cmd_none()];
            }
        }
        default: {
            modifyLocation(new Page_1(0, (arg10 = (sortOrderFromType(model.SortOrder) | 0), PowerProgrammePage_FromIndexRoute(sortColumnFromType(model.SortOrder), arg10, msg.fields[0]))));
            return [model, Cmd_none()];
        }
    }
}

