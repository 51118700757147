import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { trainingLoad } from "../../Server.fs.js";
import { GetFitnessRes } from "./Types.fs.js";

export class UnableToLoadTrainingLoadException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToLoadTrainingLoadException$reflection() {
    return class_type("Analysis.Fitness.Api.UnableToLoadTrainingLoadException", void 0, UnableToLoadTrainingLoadException, class_type("System.Exception"));
}

function UnableToLoadTrainingLoadException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToLoadTrainingLoadException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getAnalysis(dateRange, context) {
    return singleton.Delay(() => singleton.Bind(trainingLoad((api) => api.getForRange(dateRange)), (_arg1) => {
        const apiResult = _arg1;
        return singleton.Return((apiResult.tag === 0) ? (new GetFitnessRes(0, apiResult.fields[0])) : (new GetFitnessRes(1, new UnableToLoadTrainingLoadException())));
    }));
}

