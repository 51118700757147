import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { zones, powerProgramme } from "../../Server.fs.js";
import { DeleteRes, GetIntervalProgrammeRes } from "./Types.fs.js";

export class UnableToGetProgrammeException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToGetProgrammeException$reflection() {
    return class_type("PowerProgramme.Show.Api.UnableToGetProgrammeException", void 0, UnableToGetProgrammeException, class_type("System.Exception"));
}

function UnableToGetProgrammeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToGetProgrammeException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export class UnableToDeleteProgrammeException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToDeleteProgrammeException$reflection() {
    return class_type("PowerProgramme.Show.Api.UnableToDeleteProgrammeException", void 0, UnableToDeleteProgrammeException, class_type("System.Exception"));
}

function UnableToDeleteProgrammeException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToDeleteProgrammeException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getIntervalProgramme(programmeId) {
    return singleton.Delay(() => singleton.Bind(powerProgramme((api) => api.get(programmeId)), (_arg1) => {
        const result = _arg1;
        return singleton.Bind(zones((api_1) => api_1.getPowerZones()), (_arg2) => {
            let tupledArg;
            const upzResponse = _arg2;
            return singleton.Return((result.tag === 0) ? (tupledArg = [result.fields[0][0], result.fields[0][1], (upzResponse.tag === 0) ? upzResponse.fields[0] : (void 0)], new GetIntervalProgrammeRes(0, tupledArg[0], tupledArg[1], tupledArg[2])) : (new GetIntervalProgrammeRes(1, new UnableToGetProgrammeException())));
        });
    }));
}

export function delete$(programmeId) {
    return singleton.Delay(() => singleton.Bind(powerProgramme((api) => api.delete(programmeId)), (_arg1) => singleton.Return((_arg1.tag === 0) ? (new DeleteRes(0)) : (new DeleteRes(1, new UnableToDeleteProgrammeException())))));
}

