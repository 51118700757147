import { append, exists } from "../.fable/fable-library.3.0.0/Seq.js";
import { equalsSafe } from "../.fable/fable-library.3.0.0/Util.js";
import { CardioType, MetricType, CategoryIdIncludingAll, CategoryIdIncludingAll$reflection, DateRange$reflection, DateRange, PeakPerformanceType } from "../../../shared/Shared.Models.fs.js";
import { ofArray, empty, singleton } from "../.fable/fable-library.3.0.0/List.js";
import { year, now, create, addYears, addDays, today } from "../.fable/fable-library.3.0.0/Date.js";
import { Record, Union } from "../.fable/fable-library.3.0.0/Types.js";
import { record_type, union_type } from "../.fable/fable-library.3.0.0/Reflection.js";
import { powerUnits, powerWeightUnits, speedUnits, ascentUnits, calories, weight, heartRateUnits, caloriesUnits, weightUnits } from "../Units.fs.js";
import { printf, toText } from "../.fable/fable-library.3.0.0/String.js";

export function availablePeakTypes(peaks) {
    const hasPower = exists((f) => equalsSafe(f.PeakType, new PeakPerformanceType(1)), peaks);
    const hasHeart = exists((f_1) => equalsSafe(f_1.PeakType, new PeakPerformanceType(0)), peaks);
    const hasAscent = exists((f_2) => equalsSafe(f_2.PeakType, new PeakPerformanceType(4)), peaks);
    const hasSpeed = exists((f_3) => equalsSafe(f_3.PeakType, new PeakPerformanceType(3)), peaks);
    return [hasPower, hasHeart, hasAscent, hasSpeed, Array.from(append(hasPower ? singleton("Power") : empty(), append(hasHeart ? singleton("Heart") : empty(), append(hasAscent ? singleton("Ascent") : empty(), append(hasSpeed ? singleton("Speed") : empty(), [])))))];
}

export const possibleTicks = ofArray([2, 5, 10, 20, 60, 2 * 60, 5 * 60, 10 * 60, 20 * 60, 60 * 60, (2 * 60) * 60, (3 * 60) * 60]);

export const seriesColors = ["#5A67D8", "#38A169", "#DD6B20", "#63B3ED", "#718096"];

export function last30Days() {
    let copyOfStruct;
    return new DateRange((copyOfStruct = today(), addDays(copyOfStruct, -30)), today());
}

export function last120Days() {
    let copyOfStruct;
    return new DateRange((copyOfStruct = today(), addDays(copyOfStruct, -120)), today());
}

export class DateRangeType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Last30Days", "Last60Days", "Last90Days", "Last120Days", "Last180Days", "LastYear", "YearToDate", "PreviousYear", "Range"];
    }
}

export function DateRangeType$reflection() {
    return union_type("Analysis.Types.DateRangeType", [], DateRangeType, () => [[], [], [], [], [], [], [], [], []]);
}

export const dateRangeTypeLookup = [[new DateRangeType(0), "Last 30 days"], [new DateRangeType(1), "Last 60 days"], [new DateRangeType(2), "Last 90 days"], [new DateRangeType(3), "Last 120 days"], [new DateRangeType(4), "Last 180 days"], [new DateRangeType(5), "Last year"], [new DateRangeType(6), "Year to date"], [new DateRangeType(7), "Previous year"], [new DateRangeType(8), "Specified date range"]];

export class AnalysisDateRange extends Record {
    constructor(DateRangeType, DateRange, CategoryId) {
        super();
        this.DateRangeType = DateRangeType;
        this.DateRange = DateRange;
        this.CategoryId = CategoryId;
    }
}

export function AnalysisDateRange$reflection() {
    return record_type("Analysis.Types.AnalysisDateRange", [], AnalysisDateRange, () => [["DateRangeType", DateRangeType$reflection()], ["DateRange", DateRange$reflection()], ["CategoryId", CategoryIdIncludingAll$reflection()]]);
}

export function AnalysisDateRange_get_Empty() {
    return new AnalysisDateRange(new DateRangeType(0), last30Days(), new CategoryIdIncludingAll(0));
}

export function metricTypeAsLabel(metricType) {
    switch (metricType.tag) {
        case 1: {
            return "Target weight";
        }
        case 2: {
            return "Calories";
        }
        case 3: {
            return "Target calories";
        }
        case 4: {
            return "Resting heart rate";
        }
        default: {
            return "Weight";
        }
    }
}

export function metricTypeFromString(text) {
    switch (text) {
        case "Weight": {
            return new MetricType(0);
        }
        case "Target weight": {
            return new MetricType(1);
        }
        case "Calories": {
            return new MetricType(2);
        }
        case "Target calories": {
            return new MetricType(3);
        }
        case "Resting heart rate": {
            return new MetricType(4);
        }
        default: {
            return new MetricType(0);
        }
    }
}

export function metricTypeAsUnits(context, metricType) {
    switch (metricType.tag) {
        case 1: {
            return weightUnits(context);
        }
        case 2: {
            return caloriesUnits(context);
        }
        case 3: {
            return caloriesUnits(context);
        }
        case 4: {
            return heartRateUnits(context);
        }
        default: {
            return weightUnits(context);
        }
    }
}

export function metricValueAsString(context, metricType) {
    switch (metricType.tag) {
        case 1: {
            return (f_1) => weight(context, f_1);
        }
        case 2: {
            return (f_2) => calories(context, ~(~f_2));
        }
        case 3: {
            return (f_3) => calories(context, ~(~f_3));
        }
        case 4: {
            return (arg_1) => heartRateUnits(~(~arg_1));
        }
        default: {
            return (f) => weight(context, f);
        }
    }
}

export function cardioTypeAsLabel(cardioType) {
    switch (cardioType.tag) {
        case 1: {
            return "Cycling";
        }
        case 2: {
            return "Swimming";
        }
        case 3: {
            return "Walking";
        }
        case 4: {
            return "Mixed";
        }
        case 5: {
            return "Unknown";
        }
        default: {
            return "Running";
        }
    }
}

export function cardioTypeFromString(text) {
    switch (text) {
        case "Running": {
            return new CardioType(0);
        }
        case "Cycling": {
            return new CardioType(1);
        }
        case "Swimming": {
            return new CardioType(2);
        }
        case "Walking": {
            return new CardioType(3);
        }
        case "Mixed": {
            return new CardioType(4);
        }
        default: {
            return new CardioType(5);
        }
    }
}

export function peakTypeAsLabel(peakType) {
    switch (peakType.tag) {
        case 2: {
            return "Power (w/kg)";
        }
        case 0: {
            return "Heart rate";
        }
        case 4: {
            return "Ascent";
        }
        case 3: {
            return "Speed";
        }
        default: {
            return "Power";
        }
    }
}

export function peakTypeAsUnits(peakType, context) {
    switch (peakType.tag) {
        case 0: {
            return heartRateUnits(context);
        }
        case 4: {
            return ascentUnits(context);
        }
        case 3: {
            return speedUnits(context);
        }
        case 2: {
            return powerWeightUnits(context);
        }
        default: {
            return powerUnits(context);
        }
    }
}

export function formattedPeakValue(peak) {
    if (peak.PeakType.tag === 2) {
        return toText(printf("%.2f"))(peak.PeakValue);
    }
    else {
        return toText(printf("%.0f"))(peak.PeakValue);
    }
}

export function dateRangeFromType(dateRangeType) {
    let copyOfStruct_2, copyOfStruct_3, copyOfStruct_4, copyOfStruct_5, copyOfStruct_6, copyOfStruct_7, copyOfStruct_8, copyOfStruct_9, copyOfStruct_10, copyOfStruct_11, copyOfStruct_12, copyOfStruct_13, copyOfStruct_14, copyOfStruct_15, copyOfStruct_16, copyOfStruct, copyOfStruct_1;
    switch (dateRangeType.tag) {
        case 1: {
            return new DateRange((copyOfStruct_2 = today(), addDays(copyOfStruct_2, -60)), (copyOfStruct_3 = today(), addDays(copyOfStruct_3, 1)));
        }
        case 2: {
            return new DateRange((copyOfStruct_4 = today(), addDays(copyOfStruct_4, -90)), (copyOfStruct_5 = today(), addDays(copyOfStruct_5, 1)));
        }
        case 3: {
            return new DateRange((copyOfStruct_6 = today(), addDays(copyOfStruct_6, -120)), (copyOfStruct_7 = today(), addDays(copyOfStruct_7, 1)));
        }
        case 4: {
            return new DateRange((copyOfStruct_8 = today(), addDays(copyOfStruct_8, -180)), (copyOfStruct_9 = today(), addDays(copyOfStruct_9, 1)));
        }
        case 5: {
            return new DateRange((copyOfStruct_10 = today(), addYears(copyOfStruct_10, -1)), (copyOfStruct_11 = today(), addDays(copyOfStruct_11, 1)));
        }
        case 6: {
            return new DateRange(create((copyOfStruct_12 = now(), year(copyOfStruct_12)), 1, 1), (copyOfStruct_13 = today(), addDays(copyOfStruct_13, 1)));
        }
        case 7: {
            return new DateRange(create((copyOfStruct_14 = now(), year(copyOfStruct_14)) - 1, 1, 1), create((copyOfStruct_15 = now(), year(copyOfStruct_15)) - 1, 12, 31));
        }
        case 8: {
            return new DateRange((copyOfStruct_16 = today(), addDays(copyOfStruct_16, -30)), today());
        }
        default: {
            return new DateRange((copyOfStruct = today(), addDays(copyOfStruct, -30)), (copyOfStruct_1 = today(), addDays(copyOfStruct_1, 1)));
        }
    }
}

