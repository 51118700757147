import { min, max, isEmpty, map, filter, foldBack } from "../client/src/.fable/fable-library.3.0.0/Seq.js";
import { singleton } from "../client/src/.fable/fable-library.3.0.0/AsyncBuilder.js";
import { ofArray, empty, cons } from "../client/src/.fable/fable-library.3.0.0/List.js";
import { FSharpException } from "../client/src/.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../client/src/.fable/fable-library.3.0.0/Reflection.js";
import { compare, equals } from "../client/src/.fable/fable-library.3.0.0/Util.js";
import { printf, toText } from "../client/src/.fable/fable-library.3.0.0/String.js";
import { bind, some, value as value_3 } from "../client/src/.fable/fable-library.3.0.0/Option.js";
import { dayOfWeek, addDays } from "../client/src/.fable/fable-library.3.0.0/Date.js";

export function Async_asyncSequential(s) {
    return foldBack((current, accumulator) => singleton.Bind(current, (head) => singleton.Bind(accumulator, (tail) => singleton.Return(cons(head, tail)))), s, singleton.Return(empty()));
}

export const Peaks_definedPeakDurations = ofArray([2, 5, 10, 20, 30, 60, 2 * 60, 3 * 60, 4 * 60, 5 * 60, 10 * 60, 15 * 60, 20 * 60, 30 * 60, 45 * 60, 60 * 60, 90 * 60, 120 * 60]);

export class UnexpectedNoContentException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnexpectedNoContentException$reflection() {
    return class_type("Shared.UnexpectedNoContentException", void 0, UnexpectedNoContentException, class_type("System.Exception"));
}

function UnexpectedNoContentException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnexpectedNoContentException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function Route_builder(typeName, methodName) {
    return toText(printf("/api/%s/%s"))(typeName)(methodName);
}

export function OptionHelpers_optionalAggregate(aggregate, optionalValue, values) {
    const filteredValues = filter((v) => (v != null), map(optionalValue, values));
    const matchValue = isEmpty(filteredValues);
    if (matchValue) {
        return void 0;
    }
    else {
        return some(aggregate(map(value_3, filteredValues)));
    }
}

export function OptionHelpers_optionalMax(optionvalValue, values) {
    return OptionHelpers_optionalAggregate((source) => max(source, {
        Compare: compare,
    }), optionvalValue, values);
}

export function OptionHelpers_optionalMin(optionalValue, values) {
    return OptionHelpers_optionalAggregate((source) => min(source, {
        Compare: compare,
    }), optionalValue, values);
}

export function OptionHelpers_optionalIntFromFloat(value) {
    return bind((f) => (~(~f)), value);
}

export function OptionHelpers_optionalFloatFromInt(value) {
    return bind((f) => f, value);
}

export function System_DateTime__DateTime_WeekBeginningMonday(x) {
    return addDays(x, -1 * ((7 + (dayOfWeek(x) - 1)) % 7));
}

