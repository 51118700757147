import { FSharpException } from "../../.fable/fable-library.3.0.0/Types.js";
import { class_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { equals } from "../../.fable/fable-library.3.0.0/Util.js";
import { singleton } from "../../.fable/fable-library.3.0.0/AsyncBuilder.js";
import { zones } from "../../Server.fs.js";
import { GetHistoryRes } from "./Types.fs.js";

export class UnableToGetPageException extends FSharpException {
    constructor() {
        super();
    }
}

export function UnableToGetPageException$reflection() {
    return class_type("PowerZones.History.Api.UnableToGetPageException", void 0, UnableToGetPageException, class_type("System.Exception"));
}

function UnableToGetPageException__Equals_229D3F39(this$, obj) {
    if (!equals(this$, null)) {
        if (!equals(obj, null)) {
            if (obj instanceof UnableToGetPageException) {
                return 1;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else if (!equals(obj, null)) {
        return false;
    }
    else {
        return true;
    }
}

export function getHistory(pageNumber, context) {
    return singleton.Delay(() => singleton.Bind(zones((api) => api.getPowerZonesHistory(pageNumber)), (_arg1) => {
        const apiResponse = _arg1;
        return singleton.Return((apiResponse.tag === 0) ? (new GetHistoryRes(0, apiResponse.fields[0])) : (new GetHistoryRes(1, new UnableToGetPageException())));
    }));
}

