import { fromContinuations } from "../fable-library.3.0.0/Async.js";
import { toFail, printf, toText, trimEnd } from "../fable-library.3.0.0/String.js";
import { isGenericType, getFunctionElements, isFunction, getGenerics } from "../fable-library.3.0.0/Reflection.js";
import { head, take, equalsWith, last } from "../fable-library.3.0.0/Array.js";
import { compareSafe } from "../fable-library.3.0.0/Util.js";
import { singleton } from "../fable-library.3.0.0/AsyncBuilder.js";
import { ofSeq } from "../fable-library.3.0.0/List.js";
import { empty, singleton as singleton_1, append, delay } from "../fable-library.3.0.0/Seq.js";
import { ProxyRequestException_$ctor_76BC5104, HttpResponse, RequestBody } from "./Types.fs.js";
import { Convert_fromJsonAs, Convert_arrayLike, Convert_serialize } from "../Fable.SimpleJson.3.17.0/Json.Converter.fs.js";
import { TypeInfo } from "../Fable.SimpleJson.3.17.0/TypeInfo.fs.js";
import { send, get$, post, withBody, withHeaders, sendAndReadBinary } from "./Http.fs.js";
import { SimpleJson_parseNative } from "../Fable.SimpleJson.3.17.0/SimpleJson.fs.js";
import { Reader_$ctor_6C95DA22, Reader__Read_24524716 } from "../Fable.Remoting.MsgPack.1.6.2/Read.fs.js";

export function Blob_readBlobAsText(blob) {
    return fromContinuations((tupledArg) => {
        const reader = new FileReader();
        reader.onload = ((_arg1) => {
            if (reader.readyState === 2) {
                tupledArg[0](reader.result);
            }
        });
        reader.readAsText(blob);
    });
}

export function Proxy_combineRouteWithBaseUrl(route, baseUrl) {
    if (baseUrl != null) {
        const arg10 = trimEnd(baseUrl, "/");
        return toText(printf("%s%s"))(arg10)(route);
    }
    else {
        return route;
    }
}

export function Proxy_extractAsyncArg(asyncType) {
    return getGenerics(asyncType)[0];
}

export function Proxy_isByteArray(_arg1) {
    if (_arg1.tag === 25) {
        if (_arg1.fields[0]().tag === 12) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function Proxy_isAsyncOfByteArray(_arg1) {
    if (_arg1.tag === 20) {
        const matchValue = _arg1.fields[0]();
        if (matchValue.tag === 25) {
            if (matchValue.fields[0]().tag === 12) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function Proxy_proxyFetch(options, typeName, func, fieldType) {
    let funcArgs;
    const matchValue = func.FieldType;
    switch (matchValue.tag) {
        case 20: {
            funcArgs = [func.FieldType];
            break;
        }
        case 21: {
            funcArgs = [func.FieldType];
            break;
        }
        case 32: {
            funcArgs = matchValue.fields[0]();
            break;
        }
        default: {
            funcArgs = toFail(printf("Field %s does not have a valid definiton"))(func.FieldName);
        }
    }
    const argumentCount = (funcArgs.length - 1) | 0;
    const returnTypeAsync = last(funcArgs);
    const returnType = (returnTypeAsync.tag === 20) ? returnTypeAsync.fields[0]() : ((returnTypeAsync.tag === 21) ? returnTypeAsync.fields[0]() : toFail(printf("Expected field %s to have a return type of Async\u003c\u0027t\u003e"))(func.FieldName));
    const readAsBinary = Proxy_isAsyncOfByteArray(returnTypeAsync);
    let binaryInput;
    const matchValue_1 = func.FieldType;
    if (matchValue_1.tag === 32) {
        const matchValue_2 = matchValue_1.fields[0]();
        if ((!equalsWith(compareSafe, matchValue_2, null)) ? (matchValue_2.length === 2) : false) {
            const output = matchValue_2[1];
            binaryInput = Proxy_isByteArray(matchValue_2[0]);
        }
        else {
            binaryInput = false;
        }
    }
    else {
        binaryInput = false;
    }
    const url = Proxy_combineRouteWithBaseUrl(options.RouteBuilder(typeName, func.FieldName), options.BaseUrl);
    const funcNeedParameters = ((!equalsWith(compareSafe, funcArgs, null)) ? (funcArgs.length === 1) : false) ? ((funcArgs[0].tag === 20) ? false : ((funcArgs[0].tag === 21) ? false : true)) : (((!equalsWith(compareSafe, funcArgs, null)) ? (funcArgs.length === 2) : false) ? ((funcArgs[0].tag === 0) ? ((funcArgs[1].tag === 20) ? false : true) : true) : true);
    return (arg0) => ((arg1) => ((arg2) => ((arg3) => ((arg4) => ((arg5) => ((arg6) => ((arg7) => {
        const inputArguments = funcNeedParameters ? take(argumentCount, [arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7]) : [];
        const inputArgumentTypes = take(argumentCount, funcArgs);
        const contentType = binaryInput ? "application/octet-stream" : "application/json; charset=utf8";
        return singleton.Delay(() => {
            const headers = ofSeq(delay(() => append(singleton_1(["Content-Type", contentType]), delay(() => append(singleton_1(["x-remoting-proxy", "true"]), delay(() => append(options.CustomHeaders, delay(() => {
                const matchValue_3 = options.Authorization;
                if (matchValue_3 == null) {
                    return empty();
                }
                else {
                    return singleton_1(["Authorization", matchValue_3]);
                }
            }))))))));
            let requestBody;
            if (binaryInput) {
                requestBody = (new RequestBody(2, arg0));
            }
            else {
                const matchValue_4 = inputArgumentTypes.length | 0;
                let pattern_matching_result;
                if (matchValue_4 === 1) {
                    if (!Convert_arrayLike(inputArgumentTypes[0])) {
                        pattern_matching_result = 0;
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
                switch (pattern_matching_result) {
                    case 0: {
                        requestBody = (new RequestBody(1, Convert_serialize(inputArguments[0], new TypeInfo(27, () => inputArgumentTypes))));
                        break;
                    }
                    case 1: {
                        requestBody = ((matchValue_4 === 1) ? (new RequestBody(1, Convert_serialize([inputArguments[0]], new TypeInfo(25, () => inputArgumentTypes[0])))) : (new RequestBody(1, Convert_serialize(inputArguments, new TypeInfo(27, () => inputArgumentTypes)))));
                        break;
                    }
                }
            }
            return (options.ResponseSerialization.tag === 0) ? (readAsBinary ? singleton.Bind(funcNeedParameters ? sendAndReadBinary(withHeaders(headers, withBody(requestBody, post(url)))) : sendAndReadBinary(withHeaders(headers, get$(url))), (_arg4) => {
                const statusCode_1 = _arg4[1] | 0;
                const response_3 = _arg4[0];
                switch (statusCode_1) {
                    case 200: {
                        return singleton.Return(response_3);
                    }
                    case 500: {
                        return singleton.Bind(Blob_readBlobAsText(new Blob([response_3.buffer], { type: 'text/plain' })), (_arg5) => {
                            const response_4 = new HttpResponse(statusCode_1, _arg5);
                            return singleton.ReturnFrom((() => {
                                throw ProxyRequestException_$ctor_76BC5104(response_4, toText(printf("Internal server error (500) while making request to %s"))(url), response_4.ResponseBody);
                            })());
                        });
                    }
                    default: {
                        return singleton.Bind(Blob_readBlobAsText(new Blob([response_3.buffer], { type: 'text/plain' })), (_arg6) => {
                            const response_5 = new HttpResponse(statusCode_1, _arg6);
                            return singleton.ReturnFrom((() => {
                                throw ProxyRequestException_$ctor_76BC5104(response_5, toText(printf("Http error (%d) while making request to %s"))(statusCode_1)(url), response_5.ResponseBody);
                            })());
                        });
                    }
                }
            }) : singleton.Bind(funcNeedParameters ? send(withHeaders(headers, withBody(requestBody, post(url)))) : send(withHeaders(headers, get$(url))), (_arg7) => {
                const response_6 = _arg7;
                const matchValue_6 = response_6.StatusCode | 0;
                switch (matchValue_6) {
                    case 200: {
                        return singleton.Return(Convert_fromJsonAs(SimpleJson_parseNative(response_6.ResponseBody), returnType));
                    }
                    case 500: {
                        return singleton.ReturnFrom((() => {
                            throw ProxyRequestException_$ctor_76BC5104(response_6, toText(printf("Internal server error (500) while making request to %s"))(url), response_6.ResponseBody);
                        })());
                    }
                    default: {
                        return singleton.ReturnFrom((() => {
                            throw ProxyRequestException_$ctor_76BC5104(response_6, toText(printf("Http error (%d) from server occured while making request to %s"))(matchValue_6)(url), response_6.ResponseBody);
                        })());
                    }
                }
            })) : singleton.Bind(funcNeedParameters ? sendAndReadBinary(withHeaders(headers, withBody(requestBody, post(url)))) : sendAndReadBinary(withHeaders(headers, get$(url))), (_arg1_1) => {
                const statusCode = _arg1_1[1] | 0;
                const response = _arg1_1[0];
                switch (statusCode) {
                    case 200: {
                        const getReturnType = (typ_mut) => {
                            getReturnType:
                            while (true) {
                                const typ = typ_mut;
                                if (isFunction(typ)) {
                                    typ_mut = getFunctionElements(typ)[1];
                                    continue getReturnType;
                                }
                                else if (isGenericType(typ)) {
                                    return head(getGenerics(typ));
                                }
                                else {
                                    return typ;
                                }
                                break;
                            }
                        };
                        return singleton.Return(Reader__Read_24524716(Reader_$ctor_6C95DA22(response), getReturnType(fieldType)));
                    }
                    case 500: {
                        return singleton.Bind(Blob_readBlobAsText(new Blob([response.buffer], { type: 'text/plain' })), (_arg2_1) => {
                            const response_1 = new HttpResponse(statusCode, _arg2_1);
                            return singleton.ReturnFrom((() => {
                                throw ProxyRequestException_$ctor_76BC5104(response_1, toText(printf("Internal server error (500) while making request to %s"))(url), response_1.ResponseBody);
                            })());
                        });
                    }
                    default: {
                        return singleton.Bind(Blob_readBlobAsText(new Blob([response.buffer], { type: 'text/plain' })), (_arg3_1) => {
                            const response_2 = new HttpResponse(statusCode, _arg3_1);
                            return singleton.ReturnFrom((() => {
                                throw ProxyRequestException_$ctor_76BC5104(response_2, toText(printf("Http error (%d) while making request to %s"))(statusCode)(url), response_2.ResponseBody);
                            })());
                        });
                    }
                }
            });
        });
    })))))));
}

