import { bind, defaultArg } from "../../.fable/fable-library.3.0.0/Option.js";
import { Block, Interval, Length, IntervalType, MinMax$1 } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { map } from "../../.fable/fable-library.3.0.0/Array.js";

export function toMinMax(from) {
    return new MinMax$1(defaultArg(from.Min, 0), defaultArg(from.Max, 0));
}

export function toInterval(from) {
    return new Interval(from.IntervalId, from.Name, new IntervalType(0, toMinMax(from.Power)), new Length(0, from.LengthInSeconds), bind(toMinMax, from.Cadence));
}

export function toBlock(from) {
    return new Block(from.BlockId, from.Name, map(toInterval, from.Intervals), from.DoesRepeat ? from.Repeats : (void 0));
}

