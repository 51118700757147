import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { string_type, union_type, class_type, record_type, int32_type, option_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { UserPowerZones$reflection } from "../../../../shared/Shared.Models.fs.js";
import { Types_EditableInterval$reflection, Types_EditableBlock$reflection, Types_EditableProgramme_FromProgramme_41BF7BF3, Types_EditableProgramme$reflection } from "../Common.fs.js";
import { ProgrammeId$reflection, Programme$reflection, Programme_get_Empty, BlockId$reflection } from "../../../../shared/Shared.PowerProgramme.fs.js";
import { ValidationState, ValidationState$reflection } from "../../../../shared/SimpleValidation.fs.js";

export class Model extends Record {
    constructor(IsSaving, IsLoading, UserPowerZones, Programme, MovingBlockId, SelectedBlockId, ValidationState, CalculatedTss) {
        super();
        this.IsSaving = IsSaving;
        this.IsLoading = IsLoading;
        this.UserPowerZones = UserPowerZones;
        this.Programme = Programme;
        this.MovingBlockId = MovingBlockId;
        this.SelectedBlockId = SelectedBlockId;
        this.ValidationState = ValidationState;
        this.CalculatedTss = (CalculatedTss | 0);
    }
}

export function Model$reflection() {
    return record_type("PowerProgramme.Create.Types.Model", [], Model, () => [["IsSaving", bool_type], ["IsLoading", bool_type], ["UserPowerZones", option_type(UserPowerZones$reflection())], ["Programme", Types_EditableProgramme$reflection()], ["MovingBlockId", option_type(BlockId$reflection())], ["SelectedBlockId", option_type(BlockId$reflection())], ["ValidationState", ValidationState$reflection()], ["CalculatedTss", int32_type]]);
}

export function Model_get_Empty() {
    return new Model(false, false, void 0, Types_EditableProgramme_FromProgramme_41BF7BF3(Programme_get_Empty()), void 0, void 0, new ValidationState(1), 0);
}

export class SubmitIntervalProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function SubmitIntervalProgrammeRes$reflection() {
    return union_type("PowerProgramme.Create.Types.SubmitIntervalProgrammeRes", [], SubmitIntervalProgrammeRes, () => [[], [["Item", class_type("System.Exception")]]]);
}

export class GetUserPowerZonesRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetUserPowerZonesRes$reflection() {
    return union_type("PowerProgramme.Create.Types.GetUserPowerZonesRes", [], GetUserPowerZonesRes, () => [[["Item", option_type(UserPowerZones$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class GetProgrammeRes extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Success", "Error"];
    }
}

export function GetProgrammeRes$reflection() {
    return union_type("PowerProgramme.Create.Types.GetProgrammeRes", [], GetProgrammeRes, () => [[["Item1", Programme$reflection()], ["Item2", option_type(UserPowerZones$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GetProgramme", "GetProgrammeResult", "GetUserPowerZones", "GetUserPowerZonesResult", "SubmitIntervalProgramme", "SubmitIntervalProgrammeResult", "InsertBlockAtIndex", "MoveBlock", "BeginDragPaletteBlock", "EndDragPaletteBlock", "SelectBlock", "ChangeBlockName", "ToggleDoesRepeat", "ChangeProgrammeTitle", "ChangeBlockRepeats", "ChangeIntervalDuration", "ChangeIntervalMinPower", "ChangeIntervalMaxPower", "AddInterval", "DeleteInterval", "UpdateCalculatedTrainingStress", "AddPaletteBlock", "RemoveBlock"];
    }
}

export function Msg$reflection() {
    return union_type("PowerProgramme.Create.Types.Msg", [], Msg, () => [[["Item", ProgrammeId$reflection()]], [["Item", GetProgrammeRes$reflection()]], [], [["Item", GetUserPowerZonesRes$reflection()]], [], [["Item", SubmitIntervalProgrammeRes$reflection()]], [["Item1", int32_type], ["Item2", Types_EditableBlock$reflection()]], [["Item1", int32_type], ["Item2", int32_type]], [["Item", Types_EditableBlock$reflection()]], [["Item", bool_type]], [["Item", Types_EditableBlock$reflection()]], [["Item1", Types_EditableBlock$reflection()], ["Item2", string_type]], [["Item", Types_EditableBlock$reflection()]], [["Item", option_type(string_type)]], [["Item1", Types_EditableBlock$reflection()], ["Item2", option_type(int32_type)]], [["Item1", Types_EditableBlock$reflection()], ["Item2", Types_EditableInterval$reflection()], ["Item3", string_type]], [["Item1", Types_EditableBlock$reflection()], ["Item2", Types_EditableInterval$reflection()], ["Item3", option_type(int32_type)]], [["Item1", Types_EditableBlock$reflection()], ["Item2", Types_EditableInterval$reflection()], ["Item3", option_type(int32_type)]], [["Item", Types_EditableBlock$reflection()]], [["Item1", Types_EditableBlock$reflection()], ["Item2", Types_EditableInterval$reflection()]], [], [["Item", Types_EditableBlock$reflection()]], [["Item", Types_EditableBlock$reflection()]]]);
}

