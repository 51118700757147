import { GetHistoryRes, Msg, Model, Model_get_Empty } from "./Types.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_none, Cmd_OfFunc_result } from "../../.fable/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import { PowerZonesPage_FromHistoryRoute_Z524259A4, Page as Page_1, modifyLocation } from "../../Router.fs.js";
import { getHistory } from "./Api.fs.js";

export function init(pageNumber) {
    let inputRecord;
    return [(inputRecord = Model_get_Empty(), new Model(inputRecord.IsLoading, pageNumber, inputRecord.Page)), Cmd_OfFunc_result(new Msg(0))];
}

export function update(context, msg, model) {
    switch (msg.tag) {
        case 1: {
            const result = msg.fields[0];
            if (result.tag === 1) {
                console.error(some(result.fields[0]));
                return [new Model(false, model.PageNumber, model.Page), Cmd_none()];
            }
            else {
                return [new Model(false, model.PageNumber, result.fields[0]), Cmd_none()];
            }
        }
        case 2: {
            modifyLocation(new Page_1(6, PowerZonesPage_FromHistoryRoute_Z524259A4(msg.fields[0])));
            return [model, Cmd_none()];
        }
        default: {
            return [new Model(true, model.PageNumber, model.Page), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (context_1) => getHistory(model.PageNumber, context_1), context, (arg0) => (new Msg(1, arg0)), (arg) => (new Msg(1, new GetHistoryRes(1, arg))))];
        }
    }
}

