import * as react from "react";
import { modalWrapper } from "../../CommonRendering.fs.js";
import { Msg } from "./Types.fs.js";
import { IconSize, trashIcon } from "../../Icons.fs.js";
import { view as view_1 } from "../../Spinner.fs.js";
import { warning, plain } from "../../Buttons.fs.js";
import { Id_create } from "../../../../shared/Shared.Types.fs.js";

export function view(model, dispatch) {
    const matchValue = model.Item;
    if (matchValue == null) {
        return react.createElement(react.Fragment, {});
    }
    else {
        const activity = matchValue;
        const spinnerStyle = model.IsDeleting ? "flex flex-row justify-center items-center" : "hidden flex flex-row justify-center items-center";
        return modalWrapper((_arg1) => {
            dispatch(new Msg(5));
        }, react.createElement("div", {}, react.createElement("div", {
            className: "mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100",
        }, react.createElement("span", {
            className: "text-red-600",
        }, trashIcon(new IconSize(2)))), react.createElement("div", {
            className: "min-h-full flex flex-column justify-around",
        }, react.createElement("div", {
            className: spinnerStyle,
        }, view_1), react.createElement("div", {
            className: model.IsDeleting ? "w-full hidden" : "w-full",
        }, react.createElement("label", {
            className: "mt-4 block text-sm font-medium leading-5 text-gray-700",
        }, "Are you sure you want to delete this activity?"))), react.createElement("div", {
            className: "grid grid-cols-2 gap-4 mt-4",
        }, plain("No", () => {
            dispatch(new Msg(5));
        }, false), warning("Yes, delete it", () => {
            dispatch(new Msg(6, Id_create(activity.Summary.Id)));
        }, false))));
    }
}

