import { root as root_1 } from "../Create/View.fs.js";
import { Msg } from "./Types.fs.js";
import { some } from "../../.fable/fable-library.3.0.0/Option.js";
import * as react from "react";

export function root(context, model, dispatch) {
    if (model.CreateModel != null) {
        return root_1({
            Context: context,
            Dispatch: (arg) => {
                dispatch(new Msg(0, arg));
            },
            Model: model.CreateModel,
        });
    }
    else {
        console.log(some("[HeartRateZones.Dispatcher.View] Page not found"));
        return react.createElement("div", {}, "Page not found");
    }
}

