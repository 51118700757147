import { Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { record_type, bool_type } from "../../.fable/fable-library.3.0.0/Reflection.js";

export class Model extends Record {
    constructor(IsSaving) {
        super();
        this.IsSaving = IsSaving;
    }
}

export function Model$reflection() {
    return record_type("Help.Show.Types.Model", [], Model, () => [["IsSaving", bool_type]]);
}

export function Model_Empty_1505(id) {
    return new Model(false);
}

