import { Union, Record } from "../../.fable/fable-library.3.0.0/Types.js";
import { WeeklyTrainingLogPage_FromToday, WeeklyTrainingLogPage$reflection } from "../../Router.fs.js";
import { Msg$reflection as Msg$reflection_1, Model$reflection as Model$reflection_1 } from "../Index/Types.fs.js";
import { union_type, record_type, option_type } from "../../.fable/fable-library.3.0.0/Reflection.js";
import { Msg$reflection as Msg$reflection_2, Model$reflection as Model$reflection_2 } from "../List/Types.fs.js";
import { Msg$reflection as Msg$reflection_3, Model$reflection as Model$reflection_3 } from "../Bubble/Types.fs.js";

export class Model extends Record {
    constructor(CurrentPage, IndexModel, ListModel, BubbleModel) {
        super();
        this.CurrentPage = CurrentPage;
        this.IndexModel = IndexModel;
        this.ListModel = ListModel;
        this.BubbleModel = BubbleModel;
    }
}

export function Model$reflection() {
    return record_type("WeeklyTrainingLog.Dispatcher.Types.Model", [], Model, () => [["CurrentPage", WeeklyTrainingLogPage$reflection()], ["IndexModel", option_type(Model$reflection_1())], ["ListModel", option_type(Model$reflection_2())], ["BubbleModel", option_type(Model$reflection_3())]]);
}

export function Model_get_Empty() {
    return new Model(WeeklyTrainingLogPage_FromToday(), void 0, void 0, void 0);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["IndexMsg", "ListMsg", "BubbleMsg"];
    }
}

export function Msg$reflection() {
    return union_type("WeeklyTrainingLog.Dispatcher.Types.Msg", [], Msg, () => [[["Item", Msg$reflection_1()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]]]);
}

